/**
 * Calculates the cumulative probabilities above and below a given level.
 *
 * @param probabilityCurve - The sorted probability curve from `calculateInterdayProbabilityCurve`.
 * @param level - The numeric level to calculate probabilities for.
 * @returns An object with the cumulative probabilities above and below the specified level.
 */
export function partialIntegral(
	probabilityCurve: Record<string, number>,
	level: number
): { above: number; below: number } {
	let above = 0;
	let below = 0;

	// Iterate over the probability bins
	Object.entries(probabilityCurve).forEach(([range, probability]) => {
		// Extract the numeric start of the range
		const rangeStart = parseFloat(range.split(' to ')[0]);
		const rangeEnd = parseFloat(range.split(' to ')[1]);

		// Check if the range is above or below the level
		if (rangeEnd <= level) {
			below += probability; // Entire bin is below the level
		} else if (rangeStart >= level) {
			above += probability; // Entire bin is above the level
		} else {
			// Split the probability if the level intersects the bin
			const rangeWidth = rangeEnd - rangeStart;
			const belowRatio = (level - rangeStart) / rangeWidth;
			const aboveRatio = (rangeEnd - level) / rangeWidth;

			below += probability * belowRatio;
			above += probability * aboveRatio;
		}
	});

	return { above, below };
}
