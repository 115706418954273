import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PriceHistoryRequest} from "../../../models/PriceHistoryRequest";
import {PlotlyService} from "../../../services/plotly.service";
import {SchwabAuthService} from "../../../services/schwab-auth.service";
import {SchwabDataService} from "../../../services/schwab-data.service";
import {CandleList} from "../../../models/CandleList";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { calculateInterdayProbabilityCurve } from '../../../shared/Functions/CalculateInterdayProbabilityCurve'
import { calculateInterdayProbabilityCurveNoSkip } from '../../../shared/Functions/CalculateInterdayProbabilityCurveNoSkip'
import { ToastModule } from 'primeng/toast';
import {MessageService} from "primeng/api";
import {HttpErrorResponse} from "@angular/common/http";
import {partialIntegral} from "../../../shared/Functions/PartialIntegral";
import {integralSplitPoint} from "../../../shared/Functions/IntegralSplitPoint";

@Component({
  selector: 'app-probability-surface',
  templateUrl: './probability-surface.component.html',
  styleUrl: './probability-surface.component.scss'
})
export class ProbabilitySurfaceComponent implements OnInit {
	constructor(private plot: PlotlyService,
				private cdRef: ChangeDetectorRef,
				private schwabAuth: SchwabAuthService,
				private schwabData: SchwabDataService,
				private fb: FormBuilder,
				private messageService: MessageService) {
		this.priceHistoryForm = this.fb.group({
			symbol: ['SPY', Validators.required],
			periodType: ['year'],
			period: [3],
			frequencyType: ['daily'],
			frequency: [1],
			startDate: [null],
			endDate: [null],
			needExtendedHoursData: [false],
			needPreviousClose: [false],
		});
		this.dataDisplayForm = this.fb.group({
			binSize: [0.1, Validators.required],
			testLevel: [4],
			splitPointPercentage: [95],
			showRawData: [false],
		})
	}

	priceHistoryForm: FormGroup;
	dataDisplayForm: FormGroup;


	periodTypes: string[] = ['day', 'month', 'year', 'ytd'];
	frequencyTypes: string[] = ['minute', 'daily', 'weekly', 'monthly'];
	binSizes: number[] = [0.01, 0.1, 1, 2];

	priceHistoryData: CandleList | undefined;

	showRawDataBool: boolean = false;

	splitProb: { above: number; below: number } = { above: 0, below: 0 };
	splitPoint: string = '';

	title: string = 'Interday Probability Curve';
	graphData: any;
	graphLayout: any;

	ngOnInit() {
		// Subscribe to Bin Size value changes
		this.dataDisplayForm.get('binSize')?.valueChanges.subscribe((value) => {
			this.onBinSizeChange(value);
		});
		this.dataDisplayForm.get('showRawData')?.valueChanges.subscribe((value) => {
			this.onRawDataChange(value);
		});
		this.dataDisplayForm.get('testLevel')?.valueChanges.subscribe((value) => {
			this.onBinSizeChange(this.dataDisplayForm.get('binSize')?.value);
		});
		this.dataDisplayForm.get('splitPointPercentage')?.valueChanges.subscribe((value) => {
			this.onBinSizeChange(this.dataDisplayForm.get('binSize')?.value);
		});
	}

	submitForm() {
		if (this.priceHistoryForm.valid) {
			const requestParams: PriceHistoryRequest = this.priceHistoryForm.value;
			console.log('Form Submitted:', requestParams);
			this.schwabData.getPriceHistory(requestParams).subscribe(
				(response) => {
					this.priceHistoryData = response;
					this.calcMaxMove(this.dataDisplayForm.get('binSize')?.value);
				},
				(error: HttpErrorResponse) => {
					console.error('Error getting test data', error);
					this.showErrorToast(error.error.errors[0].detail);
				}
			)
		} else {
			console.log('Form is invalid');
			this.showErrorToast('Form is Invalid');
		}
	}

	resetForm() {
		this.priceHistoryForm.reset();
	}

	onBinSizeChange(value: number): void {
		this.calcMaxMove(value);
	}

	onRawDataChange(value: boolean): void {
		this.showRawDataBool = value;
	}

	showErrorToast(msg: string) {
		this.messageService.add({ severity: 'error', summary: 'Error', detail: msg, key: 'br', life: 10000 });
	}

	totalProb: number = 0;

	calcMaxMove(binSize: number) {

		// Use the imported function
		const probabilityCurve = calculateInterdayProbabilityCurveNoSkip(this.priceHistoryData!, binSize);

		// Extract data for Plotly
		const xValues = Object.keys(probabilityCurve); // Bin ranges
		const yValues = Object.values(probabilityCurve); // Probabilities

		this.totalProb = yValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

		this.splitPoint = integralSplitPoint(probabilityCurve, this.dataDisplayForm.get('splitPointPercentage')?.value / 100, binSize);

		// Define the graph data and layout for Plotly
		this.graphData = [
			{
				x: xValues,
				y: yValues,
				type: 'bar',
				marker: { color: 'rgb(58, 200, 225)' },
			},
		];

		// Define the vertical line as a shape
		const verticalLine = {
			type: 'line',
			x0: this.splitPoint,
			x1: this.splitPoint,
			y0: 0,
			y1: Math.max(...yValues), // Line height matches the max y-value
			line: {
				color: 'red',
				width: 2,
				dash: 'dash',
			},
		};

		this.graphLayout = {
			title: 'Interday Probability Curve of Price Movement',
			xaxis: { title: 'Price Movement Range (%)' },
			yaxis: { title: 'Probability' },
			barmode: 'group',
			shapes: [verticalLine], // Add the vertical line here
		};

		this.splitProb = partialIntegral(probabilityCurve, this.dataDisplayForm.get('testLevel')?.value);
	}
}
