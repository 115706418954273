import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(public layoutService: LayoutService) { }

    ngOnInit() {
        this.model = [
            {
                label: 'Home',
                items: [
                    { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/'] },
					{ label: 'Schwab Authentication', icon: 'pi pi-fw pi-globe', routerLink: ['/auth'] },
				]
            },
            {
                label: 'Charting',
                items: [
					{ label: 'Probability Surface', icon: 'pi pi-fw pi-percentage', routerLink: ['/probability-surface'], badge: 'NEW' },
					{ label: 'Price-Volume Surface', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/volume-price-surface'], badge: 'NEW' },
                    //{ label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: ['https://www.primefaces.org/primeblocks-ng'], target: '_blank' },
                ]
            }
        ];
    }
}
