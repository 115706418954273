import { Injectable } from '@angular/core';
import {surfaceData} from "../models/surfaceData";
declare let Plotly: any;

@Injectable({
  providedIn: 'root'
})
export class PlotlyService {

  constructor() { }

  plotLine(title: string, plotDiv: string, x:number[], y:number[]){
    let trace = {
      x: x,
      y: y,
      type: 'scatter'
    };

    let layout = {
      title:title
    };

    Plotly.newPlot(plotDiv, [trace], layout);
  }

  plotSurface(title: string, plotDiv: string, data: surfaceData){
    let trace = {
      x: data.x,
      y: data.y,
      z: data.z,
      type: 'surface'
    };

    let layout = {
      title:title
    };

    Plotly.newPlot(plotDiv, [trace], layout);
  }

  // plotSurface(title: string, plotDiv: string, x:number[], y:number[]){
  //   this.parseSurfaceData();
  //   let trace = {
  //     x: x,
  //     y: y,
  //     type: 'scatter'
  //   };
  //
  //   let layout = {
  //     title:title
  //   };
  //
  //   Plotly.newPlot(plotDiv, [trace], layout);
  // }

  private parseSurfaceData(){

  }
}
