import { HttpInterceptorFn } from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { SchwabAuthService } from '../../services/schwab-auth.service';
import {from} from "rxjs";

export const schwabAuthInterceptorInterceptor: HttpInterceptorFn = (req, next) => {
	const authService = inject(SchwabAuthService);

	// Only intercept requests to test.com
	if (!req.url.includes('https://api.schwabapi.com/') || req.url.includes('https://api.schwabapi.com/v1/oauth')) {
		return next(req); // Pass through unmodified
	}

	// Check if token is expired
	if (!authService.isTokenExpired()) {
		// Add Bearer token if it's valid
		const token = authService.getAccessTokenFromStorage();
		const clonedRequest = req.clone({
			setHeaders: { Authorization: `Bearer ${token}` },
		});
		return next(clonedRequest);
	}

	// Refresh the token if expired and retry the request
	return from(authService.refreshAccessToken().toPromise()).pipe(
		switchMap((response) => {
			authService.handleTokenResponse(response);
			console.log('Access Token Received:', response.access_token);
			const token = authService.getAccessTokenFromStorage();
			const clonedRequest = req.clone({
				setHeaders: { Authorization: `Bearer ${token}` },
			});
			return next(clonedRequest);
		})
	);
};
