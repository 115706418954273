/**
 * Finds the value at which the cumulative probability below the input percentage is achieved.
 *
 * @param probabilityCurve - A sorted object where keys are price movement ranges and values are probabilities.
 * @param percentage - The decimal percentage for the split (e.g., 0.95 for 95%).
 * @param binSize - The size of each bin for grouping price movements.
 * @returns The full bin range where the cumulative probability falls below the input percentage.
 */
export function integralSplitPoint(
	probabilityCurve: Record<string, number>,
	percentage: number,
	binSize: number // Pass bin size to calculate the full range
): string {
	if (percentage < 0 || percentage > 1) {
		throw new Error('Percentage must be between 0 and 1.');
	}

	// Parse the probabilityCurve to create an array of bins with range start, range end, and bin label
	const bins = Object.keys(probabilityCurve).map((bin) => {
		const [rangeStartStr, rangeEndStr] = bin.split(' to ');
		const rangeStart = parseFloat(rangeStartStr);
		const rangeEnd = parseFloat(rangeEndStr);
		return { rangeStart, rangeEnd, probability: probabilityCurve[bin], binLabel: bin };
	});

	// Sort bins by rangeStart (ascending order)
	bins.sort((a, b) => a.rangeStart - b.rangeStart);

	let cumulativeProbability = 0;

	// Iterate through bins to find the point where cumulative probability falls below or equals the input percentage
	for (const bin of bins) {
		// If the next bin exceeds the desired percentage, return the current bin's label
		if (cumulativeProbability + bin.probability > percentage) {
			return bin.binLabel;
		}
		cumulativeProbability += bin.probability;
	}

	// If the percentage is not reached, return the highest bin label
	return bins[bins.length - 1].binLabel;
}
