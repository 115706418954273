import { Component, OnInit } from '@angular/core';
import { SchwabAuthService} from "../../services/schwab-auth.service";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss'
})
export class AuthComponent implements OnInit {
	constructor(
		private schwabAuthService: SchwabAuthService,
		private route: ActivatedRoute
	){}

	//Redirect the user to Schwab's OAuth authorization page
	login(){
		window.location.href = this.schwabAuthService.getAuthUrl();
	}

	refresh(){
		this.schwabAuthService.refreshAccessToken().subscribe(
			(response) => {
				this.schwabAuthService.handleTokenResponse(response);
				console.log('Access Token Received:', response.access_token);
				//Optionally, navigate to another page after successful Login
			},
			(error) => {
				console.error('Error exchanging code for token', error);
			}
		);
	}

	logout(){
		this.schwabAuthService.logout();
	}

	//Handle the authorization code from the redirect
	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			const code = params['code'];
			if (code) {
				//Exchange the authorization code for an access token
				this.schwabAuthService.getAccessToken(code).subscribe(
					(response) => {
						this.schwabAuthService.handleTokenResponse(response);
						console.log('Access Token Received:', response.access_token);
						//Optionally, navigate to another page after successful Login
					},
					(error) => {
						console.error('Error exchanging code for token', error);
					}
				);
			}
		});
	}

}
