{
    "candles": [
		{
			"open": 523.83,
			"high": 523.84,
			"low": 523.5509,
			"close": 523.59,
			"volume": 569848,
			"datetime": 1711978200000
		},
		{
			"open": 523.58,
			"high": 523.63,
			"low": 523.45,
			"close": 523.61,
			"volume": 221035,
			"datetime": 1711978260000
		},
		{
			"open": 523.61,
			"high": 523.86,
			"low": 523.59,
			"close": 523.86,
			"volume": 153898,
			"datetime": 1711978320000
		},
		{
			"open": 523.86,
			"high": 524,
			"low": 523.81,
			"close": 523.91,
			"volume": 161719,
			"datetime": 1711978380000
		},
		{
			"open": 523.9,
			"high": 524.11,
			"low": 523.89,
			"close": 524.0113,
			"volume": 153346,
			"datetime": 1711978440000
		},
		{
			"open": 524.01,
			"high": 524.2725,
			"low": 524.01,
			"close": 524.04,
			"volume": 231709,
			"datetime": 1711978500000
		},
		{
			"open": 524.03,
			"high": 524.11,
			"low": 523.75,
			"close": 523.81,
			"volume": 156028,
			"datetime": 1711978560000
		},
		{
			"open": 523.81,
			"high": 524.06,
			"low": 523.8001,
			"close": 524,
			"volume": 187562,
			"datetime": 1711978620000
		},
		{
			"open": 524,
			"high": 524.239,
			"low": 524,
			"close": 524.16,
			"volume": 132096,
			"datetime": 1711978680000
		},
		{
			"open": 524.155,
			"high": 524.23,
			"low": 524.08,
			"close": 524.2001,
			"volume": 113873,
			"datetime": 1711978740000
		},
		{
			"open": 524.22,
			"high": 524.34,
			"low": 524.15,
			"close": 524.22,
			"volume": 173310,
			"datetime": 1711978800000
		},
		{
			"open": 524.23,
			"high": 524.3,
			"low": 524.2,
			"close": 524.26,
			"volume": 128652,
			"datetime": 1711978860000
		},
		{
			"open": 524.25,
			"high": 524.36,
			"low": 524.23,
			"close": 524.245,
			"volume": 105745,
			"datetime": 1711978920000
		},
		{
			"open": 524.245,
			"high": 524.245,
			"low": 524.12,
			"close": 524.18,
			"volume": 63564,
			"datetime": 1711978980000
		},
		{
			"open": 524.18,
			"high": 524.2294,
			"low": 524.1401,
			"close": 524.205,
			"volume": 62558,
			"datetime": 1711979040000
		},
		{
			"open": 524.21,
			"high": 524.38,
			"low": 524.21,
			"close": 524.345,
			"volume": 140616,
			"datetime": 1711979100000
		},
		{
			"open": 524.345,
			"high": 524.345,
			"low": 524.13,
			"close": 524.27,
			"volume": 144090,
			"datetime": 1711979160000
		},
		{
			"open": 524.29,
			"high": 524.31,
			"low": 524.06,
			"close": 524.12,
			"volume": 90500,
			"datetime": 1711979220000
		},
		{
			"open": 524.11,
			"high": 524.11,
			"low": 523.97,
			"close": 524.04,
			"volume": 100019,
			"datetime": 1711979280000
		},
		{
			"open": 524.025,
			"high": 524.03,
			"low": 523.855,
			"close": 524,
			"volume": 76139,
			"datetime": 1711979340000
		},
		{
			"open": 523.99,
			"high": 524.09,
			"low": 523.985,
			"close": 524.0441,
			"volume": 65196,
			"datetime": 1711979400000
		},
		{
			"open": 524.06,
			"high": 524.09,
			"low": 523.99,
			"close": 524.07,
			"volume": 48976,
			"datetime": 1711979460000
		},
		{
			"open": 524.08,
			"high": 524.08,
			"low": 523.93,
			"close": 523.97,
			"volume": 42390,
			"datetime": 1711979520000
		},
		{
			"open": 523.96,
			"high": 524.05,
			"low": 523.9101,
			"close": 523.948,
			"volume": 49553,
			"datetime": 1711979580000
		},
		{
			"open": 523.95,
			"high": 524.1099,
			"low": 523.9499,
			"close": 524.04,
			"volume": 46402,
			"datetime": 1711979640000
		},
		{
			"open": 524.04,
			"high": 524.1993,
			"low": 524.04,
			"close": 524.12,
			"volume": 66023,
			"datetime": 1711979700000
		},
		{
			"open": 524.14,
			"high": 524.23,
			"low": 524.12,
			"close": 524.2104,
			"volume": 47513,
			"datetime": 1711979760000
		},
		{
			"open": 524.21,
			"high": 524.23,
			"low": 524.14,
			"close": 524.18,
			"volume": 53288,
			"datetime": 1711979820000
		},
		{
			"open": 524.18,
			"high": 524.195,
			"low": 524.129,
			"close": 524.145,
			"volume": 35289,
			"datetime": 1711979880000
		},
		{
			"open": 524.15,
			"high": 524.17,
			"low": 524.015,
			"close": 524.095,
			"volume": 49988,
			"datetime": 1711979940000
		},
		{
			"open": 524.09,
			"high": 524.16,
			"low": 523.45,
			"close": 524.02,
			"volume": 272290,
			"datetime": 1711980000000
		},
		{
			"open": 524.03,
			"high": 524.18,
			"low": 523.96,
			"close": 524.13,
			"volume": 82908,
			"datetime": 1711980060000
		},
		{
			"open": 524.15,
			"high": 524.16,
			"low": 523.6,
			"close": 523.62,
			"volume": 156430,
			"datetime": 1711980120000
		},
		{
			"open": 523.63,
			"high": 523.757,
			"low": 523.58,
			"close": 523.72,
			"volume": 147974,
			"datetime": 1711980180000
		},
		{
			"open": 523.72,
			"high": 523.72,
			"low": 523.13,
			"close": 523.22,
			"volume": 310553,
			"datetime": 1711980240000
		},
		{
			"open": 523.22,
			"high": 523.51,
			"low": 523.19,
			"close": 523.4599,
			"volume": 194358,
			"datetime": 1711980300000
		},
		{
			"open": 523.46,
			"high": 523.64,
			"low": 523.42,
			"close": 523.605,
			"volume": 102279,
			"datetime": 1711980360000
		},
		{
			"open": 523.6,
			"high": 523.68,
			"low": 523.5,
			"close": 523.58,
			"volume": 108196,
			"datetime": 1711980420000
		},
		{
			"open": 523.57,
			"high": 523.805,
			"low": 523.55,
			"close": 523.785,
			"volume": 144267,
			"datetime": 1711980480000
		},
		{
			"open": 523.8,
			"high": 523.83,
			"low": 523.68,
			"close": 523.745,
			"volume": 92919,
			"datetime": 1711980540000
		},
		{
			"open": 523.74,
			"high": 523.87,
			"low": 523.707,
			"close": 523.845,
			"volume": 155553,
			"datetime": 1711980600000
		},
		{
			"open": 523.855,
			"high": 523.87,
			"low": 523.7252,
			"close": 523.8597,
			"volume": 77268,
			"datetime": 1711980660000
		},
		{
			"open": 523.86,
			"high": 523.87,
			"low": 523.71,
			"close": 523.775,
			"volume": 48920,
			"datetime": 1711980720000
		},
		{
			"open": 523.7801,
			"high": 523.96,
			"low": 523.71,
			"close": 523.93,
			"volume": 114553,
			"datetime": 1711980780000
		},
		{
			"open": 523.94,
			"high": 523.94,
			"low": 523.825,
			"close": 523.885,
			"volume": 71410,
			"datetime": 1711980840000
		},
		{
			"open": 523.88,
			"high": 523.91,
			"low": 523.695,
			"close": 523.73,
			"volume": 84878,
			"datetime": 1711980900000
		},
		{
			"open": 523.74,
			"high": 523.77,
			"low": 523.6,
			"close": 523.6501,
			"volume": 77226,
			"datetime": 1711980960000
		},
		{
			"open": 523.65,
			"high": 523.67,
			"low": 523.505,
			"close": 523.51,
			"volume": 85721,
			"datetime": 1711981020000
		},
		{
			"open": 523.51,
			"high": 523.56,
			"low": 523.1,
			"close": 523.1501,
			"volume": 157275,
			"datetime": 1711981080000
		},
		{
			"open": 523.1561,
			"high": 523.1561,
			"low": 522.9,
			"close": 522.9601,
			"volume": 239637,
			"datetime": 1711981140000
		},
		{
			"open": 522.96,
			"high": 523.135,
			"low": 522.83,
			"close": 523.045,
			"volume": 255221,
			"datetime": 1711981200000
		},
		{
			"open": 523.05,
			"high": 523.18,
			"low": 523.02,
			"close": 523.11,
			"volume": 127014,
			"datetime": 1711981260000
		},
		{
			"open": 523.09,
			"high": 523.115,
			"low": 522.96,
			"close": 523.0203,
			"volume": 160693,
			"datetime": 1711981320000
		},
		{
			"open": 523.02,
			"high": 523.08,
			"low": 522.98,
			"close": 523.06,
			"volume": 73935,
			"datetime": 1711981380000
		},
		{
			"open": 523.06,
			"high": 523.2691,
			"low": 523.045,
			"close": 523.205,
			"volume": 109321,
			"datetime": 1711981440000
		},
		{
			"open": 523.22,
			"high": 523.23,
			"low": 523.1101,
			"close": 523.17,
			"volume": 87066,
			"datetime": 1711981500000
		},
		{
			"open": 523.18,
			"high": 523.19,
			"low": 523.03,
			"close": 523.1,
			"volume": 85817,
			"datetime": 1711981560000
		},
		{
			"open": 523.1,
			"high": 523.16,
			"low": 523.055,
			"close": 523.07,
			"volume": 75319,
			"datetime": 1711981620000
		},
		{
			"open": 523.08,
			"high": 523.1512,
			"low": 522.98,
			"close": 523.13,
			"volume": 62290,
			"datetime": 1711981680000
		},
		{
			"open": 523.12,
			"high": 523.13,
			"low": 522.965,
			"close": 522.99,
			"volume": 44946,
			"datetime": 1711981740000
		},
		{
			"open": 522.985,
			"high": 523.025,
			"low": 522.9015,
			"close": 522.955,
			"volume": 91172,
			"datetime": 1711981800000
		},
		{
			"open": 522.95,
			"high": 523.1,
			"low": 522.91,
			"close": 522.96,
			"volume": 78522,
			"datetime": 1711981860000
		},
		{
			"open": 522.97,
			"high": 523.025,
			"low": 522.915,
			"close": 522.92,
			"volume": 65155,
			"datetime": 1711981920000
		},
		{
			"open": 522.92,
			"high": 522.92,
			"low": 522.6003,
			"close": 522.855,
			"volume": 152635,
			"datetime": 1711981980000
		},
		{
			"open": 522.86,
			"high": 522.89,
			"low": 522.73,
			"close": 522.8588,
			"volume": 64290,
			"datetime": 1711982040000
		},
		{
			"open": 522.855,
			"high": 522.855,
			"low": 522.64,
			"close": 522.72,
			"volume": 79011,
			"datetime": 1711982100000
		},
		{
			"open": 522.73,
			"high": 522.89,
			"low": 522.69,
			"close": 522.86,
			"volume": 61502,
			"datetime": 1711982160000
		},
		{
			"open": 522.86,
			"high": 522.86,
			"low": 522.68,
			"close": 522.7001,
			"volume": 57899,
			"datetime": 1711982220000
		},
		{
			"open": 522.7,
			"high": 522.74,
			"low": 522.41,
			"close": 522.42,
			"volume": 198030,
			"datetime": 1711982280000
		},
		{
			"open": 522.4173,
			"high": 522.45,
			"low": 522.08,
			"close": 522.33,
			"volume": 243044,
			"datetime": 1711982340000
		},
		{
			"open": 522.33,
			"high": 522.42,
			"low": 522.23,
			"close": 522.42,
			"volume": 149560,
			"datetime": 1711982400000
		},
		{
			"open": 522.43,
			"high": 522.49,
			"low": 522.3301,
			"close": 522.415,
			"volume": 127815,
			"datetime": 1711982460000
		},
		{
			"open": 522.41,
			"high": 522.56,
			"low": 522.4,
			"close": 522.53,
			"volume": 61154,
			"datetime": 1711982520000
		},
		{
			"open": 522.54,
			"high": 522.54,
			"low": 522.46,
			"close": 522.4695,
			"volume": 76551,
			"datetime": 1711982580000
		},
		{
			"open": 522.46,
			"high": 522.645,
			"low": 522.43,
			"close": 522.5651,
			"volume": 136484,
			"datetime": 1711982640000
		},
		{
			"open": 522.5699,
			"high": 522.57,
			"low": 522.41,
			"close": 522.45,
			"volume": 85197,
			"datetime": 1711982700000
		},
		{
			"open": 522.43,
			"high": 522.4999,
			"low": 522.3501,
			"close": 522.36,
			"volume": 105351,
			"datetime": 1711982760000
		},
		{
			"open": 522.36,
			"high": 522.49,
			"low": 522.35,
			"close": 522.47,
			"volume": 39994,
			"datetime": 1711982820000
		},
		{
			"open": 522.45,
			"high": 522.48,
			"low": 522.345,
			"close": 522.4,
			"volume": 39515,
			"datetime": 1711982880000
		},
		{
			"open": 522.4,
			"high": 522.5,
			"low": 522.35,
			"close": 522.5,
			"volume": 66698,
			"datetime": 1711982940000
		},
		{
			"open": 522.49,
			"high": 522.5,
			"low": 522.36,
			"close": 522.405,
			"volume": 57008,
			"datetime": 1711983000000
		},
		{
			"open": 522.4,
			"high": 522.4499,
			"low": 522.23,
			"close": 522.235,
			"volume": 67622,
			"datetime": 1711983060000
		},
		{
			"open": 522.24,
			"high": 522.3,
			"low": 522.13,
			"close": 522.2601,
			"volume": 99580,
			"datetime": 1711983120000
		},
		{
			"open": 522.2799,
			"high": 522.385,
			"low": 522.2367,
			"close": 522.2367,
			"volume": 54376,
			"datetime": 1711983180000
		},
		{
			"open": 522.235,
			"high": 522.27,
			"low": 522.2,
			"close": 522.225,
			"volume": 40862,
			"datetime": 1711983240000
		},
		{
			"open": 522.2301,
			"high": 522.2899,
			"low": 522.12,
			"close": 522.205,
			"volume": 64547,
			"datetime": 1711983300000
		},
		{
			"open": 522.2,
			"high": 522.39,
			"low": 522.2,
			"close": 522.36,
			"volume": 78772,
			"datetime": 1711983360000
		},
		{
			"open": 522.36,
			"high": 522.42,
			"low": 522.29,
			"close": 522.34,
			"volume": 82505,
			"datetime": 1711983420000
		},
		{
			"open": 522.34,
			"high": 522.35,
			"low": 522.2501,
			"close": 522.305,
			"volume": 74840,
			"datetime": 1711983480000
		},
		{
			"open": 522.32,
			"high": 522.36,
			"low": 522.3,
			"close": 522.315,
			"volume": 41405,
			"datetime": 1711983540000
		},
		{
			"open": 522.31,
			"high": 522.3575,
			"low": 522.26,
			"close": 522.29,
			"volume": 81548,
			"datetime": 1711983600000
		},
		{
			"open": 522.285,
			"high": 522.325,
			"low": 522.1701,
			"close": 522.26,
			"volume": 45406,
			"datetime": 1711983660000
		},
		{
			"open": 522.2504,
			"high": 522.26,
			"low": 522.09,
			"close": 522.24,
			"volume": 87641,
			"datetime": 1711983720000
		},
		{
			"open": 522.24,
			"high": 522.35,
			"low": 522.18,
			"close": 522.29,
			"volume": 72494,
			"datetime": 1711983780000
		},
		{
			"open": 522.3,
			"high": 522.33,
			"low": 522.2402,
			"close": 522.28,
			"volume": 77686,
			"datetime": 1711983840000
		},
		{
			"open": 522.29,
			"high": 522.315,
			"low": 522.14,
			"close": 522.16,
			"volume": 86926,
			"datetime": 1711983900000
		},
		{
			"open": 522.16,
			"high": 522.25,
			"low": 522.025,
			"close": 522.245,
			"volume": 145711,
			"datetime": 1711983960000
		},
		{
			"open": 522.249,
			"high": 522.42,
			"low": 522.23,
			"close": 522.34,
			"volume": 87265,
			"datetime": 1711984020000
		},
		{
			"open": 522.34,
			"high": 522.36,
			"low": 522.17,
			"close": 522.21,
			"volume": 69045,
			"datetime": 1711984080000
		},
		{
			"open": 522.21,
			"high": 522.2199,
			"low": 522.09,
			"close": 522.145,
			"volume": 52847,
			"datetime": 1711984140000
		},
		{
			"open": 522.12,
			"high": 522.14,
			"low": 521.82,
			"close": 522.0101,
			"volume": 147329,
			"datetime": 1711984200000
		},
		{
			"open": 522.015,
			"high": 522.16,
			"low": 522.015,
			"close": 522.04,
			"volume": 78859,
			"datetime": 1711984260000
		},
		{
			"open": 522.05,
			"high": 522.13,
			"low": 522.0202,
			"close": 522.085,
			"volume": 88058,
			"datetime": 1711984320000
		},
		{
			"open": 522.08,
			"high": 522.08,
			"low": 521.88,
			"close": 521.95,
			"volume": 82544,
			"datetime": 1711984380000
		},
		{
			"open": 521.95,
			"high": 522.05,
			"low": 521.92,
			"close": 521.95,
			"volume": 55562,
			"datetime": 1711984440000
		},
		{
			"open": 521.94,
			"high": 521.94,
			"low": 521.73,
			"close": 521.7393,
			"volume": 83833,
			"datetime": 1711984500000
		},
		{
			"open": 521.73,
			"high": 521.79,
			"low": 521.45,
			"close": 521.61,
			"volume": 274069,
			"datetime": 1711984560000
		},
		{
			"open": 521.61,
			"high": 521.64,
			"low": 521.53,
			"close": 521.6,
			"volume": 88013,
			"datetime": 1711984620000
		},
		{
			"open": 521.6,
			"high": 521.66,
			"low": 521.5,
			"close": 521.51,
			"volume": 74092,
			"datetime": 1711984680000
		},
		{
			"open": 521.51,
			"high": 521.6372,
			"low": 521.46,
			"close": 521.605,
			"volume": 83420,
			"datetime": 1711984740000
		},
		{
			"open": 521.6,
			"high": 521.61,
			"low": 521.48,
			"close": 521.48,
			"volume": 86820,
			"datetime": 1711984800000
		},
		{
			"open": 521.4898,
			"high": 521.515,
			"low": 521.36,
			"close": 521.42,
			"volume": 107444,
			"datetime": 1711984860000
		},
		{
			"open": 521.43,
			"high": 521.6,
			"low": 521.43,
			"close": 521.59,
			"volume": 95751,
			"datetime": 1711984920000
		},
		{
			"open": 521.59,
			"high": 522.03,
			"low": 521.58,
			"close": 521.9701,
			"volume": 235036,
			"datetime": 1711984980000
		},
		{
			"open": 521.9793,
			"high": 521.99,
			"low": 521.795,
			"close": 521.815,
			"volume": 143847,
			"datetime": 1711985040000
		},
		{
			"open": 521.825,
			"high": 521.95,
			"low": 521.81,
			"close": 521.93,
			"volume": 72987,
			"datetime": 1711985100000
		},
		{
			"open": 521.9425,
			"high": 522,
			"low": 521.92,
			"close": 521.92,
			"volume": 62841,
			"datetime": 1711985160000
		},
		{
			"open": 521.92,
			"high": 522.11,
			"low": 521.9,
			"close": 522.1001,
			"volume": 111243,
			"datetime": 1711985220000
		},
		{
			"open": 522.1,
			"high": 522.1,
			"low": 521.96,
			"close": 521.97,
			"volume": 59240,
			"datetime": 1711985280000
		},
		{
			"open": 521.97,
			"high": 522.0967,
			"low": 521.93,
			"close": 522.0799,
			"volume": 104761,
			"datetime": 1711985340000
		},
		{
			"open": 522.07,
			"high": 522.07,
			"low": 521.96,
			"close": 521.99,
			"volume": 68723,
			"datetime": 1711985400000
		},
		{
			"open": 521.98,
			"high": 522.085,
			"low": 521.98,
			"close": 521.9908,
			"volume": 66542,
			"datetime": 1711985460000
		},
		{
			"open": 522,
			"high": 522.045,
			"low": 521.91,
			"close": 521.92,
			"volume": 68813,
			"datetime": 1711985520000
		},
		{
			"open": 521.91,
			"high": 521.91,
			"low": 521.8124,
			"close": 521.88,
			"volume": 60505,
			"datetime": 1711985580000
		},
		{
			"open": 521.88,
			"high": 521.9,
			"low": 521.7902,
			"close": 521.8,
			"volume": 44147,
			"datetime": 1711985640000
		},
		{
			"open": 521.8,
			"high": 521.935,
			"low": 521.795,
			"close": 521.935,
			"volume": 41892,
			"datetime": 1711985700000
		},
		{
			"open": 521.94,
			"high": 522.065,
			"low": 521.93,
			"close": 521.93,
			"volume": 101047,
			"datetime": 1711985760000
		},
		{
			"open": 521.925,
			"high": 521.925,
			"low": 521.79,
			"close": 521.81,
			"volume": 100696,
			"datetime": 1711985820000
		},
		{
			"open": 521.82,
			"high": 521.8501,
			"low": 521.745,
			"close": 521.77,
			"volume": 28696,
			"datetime": 1711985880000
		},
		{
			"open": 521.77,
			"high": 521.83,
			"low": 521.66,
			"close": 521.6801,
			"volume": 67907,
			"datetime": 1711985940000
		},
		{
			"open": 521.68,
			"high": 521.735,
			"low": 521.64,
			"close": 521.67,
			"volume": 52936,
			"datetime": 1711986000000
		},
		{
			"open": 521.65,
			"high": 521.715,
			"low": 521.63,
			"close": 521.7,
			"volume": 48246,
			"datetime": 1711986060000
		},
		{
			"open": 521.71,
			"high": 521.725,
			"low": 521.6,
			"close": 521.63,
			"volume": 45049,
			"datetime": 1711986120000
		},
		{
			"open": 521.62,
			"high": 521.774,
			"low": 521.62,
			"close": 521.74,
			"volume": 39107,
			"datetime": 1711986180000
		},
		{
			"open": 521.73,
			"high": 521.86,
			"low": 521.71,
			"close": 521.855,
			"volume": 55336,
			"datetime": 1711986240000
		},
		{
			"open": 521.86,
			"high": 521.866,
			"low": 521.675,
			"close": 521.71,
			"volume": 46650,
			"datetime": 1711986300000
		},
		{
			"open": 521.71,
			"high": 521.8197,
			"low": 521.52,
			"close": 521.59,
			"volume": 124266,
			"datetime": 1711986360000
		},
		{
			"open": 521.59,
			"high": 521.7,
			"low": 521.555,
			"close": 521.7,
			"volume": 81662,
			"datetime": 1711986420000
		},
		{
			"open": 521.695,
			"high": 521.825,
			"low": 521.67,
			"close": 521.7992,
			"volume": 87414,
			"datetime": 1711986480000
		},
		{
			"open": 521.78,
			"high": 521.915,
			"low": 521.78,
			"close": 521.895,
			"volume": 46196,
			"datetime": 1711986540000
		},
		{
			"open": 521.9,
			"high": 521.915,
			"low": 521.68,
			"close": 521.71,
			"volume": 94756,
			"datetime": 1711986600000
		},
		{
			"open": 521.7,
			"high": 521.73,
			"low": 521.59,
			"close": 521.61,
			"volume": 65772,
			"datetime": 1711986660000
		},
		{
			"open": 521.61,
			"high": 521.625,
			"low": 521.5003,
			"close": 521.521,
			"volume": 64744,
			"datetime": 1711986720000
		},
		{
			"open": 521.5201,
			"high": 521.56,
			"low": 521.3,
			"close": 521.3305,
			"volume": 75838,
			"datetime": 1711986780000
		},
		{
			"open": 521.335,
			"high": 521.38,
			"low": 521.14,
			"close": 521.254,
			"volume": 115635,
			"datetime": 1711986840000
		},
		{
			"open": 521.25,
			"high": 521.4181,
			"low": 521.215,
			"close": 521.2498,
			"volume": 98497,
			"datetime": 1711986900000
		},
		{
			"open": 521.25,
			"high": 521.34,
			"low": 521.216,
			"close": 521.255,
			"volume": 64384,
			"datetime": 1711986960000
		},
		{
			"open": 521.25,
			"high": 521.29,
			"low": 521.19,
			"close": 521.2597,
			"volume": 61488,
			"datetime": 1711987020000
		},
		{
			"open": 521.25,
			"high": 521.295,
			"low": 521.1799,
			"close": 521.25,
			"volume": 58120,
			"datetime": 1711987080000
		},
		{
			"open": 521.24,
			"high": 521.2499,
			"low": 521.07,
			"close": 521.1088,
			"volume": 85189,
			"datetime": 1711987140000
		},
		{
			"open": 521.1,
			"high": 521.12,
			"low": 520.97,
			"close": 521.04,
			"volume": 148083,
			"datetime": 1711987200000
		},
		{
			"open": 521.05,
			"high": 521.16,
			"low": 521.03,
			"close": 521.13,
			"volume": 123298,
			"datetime": 1711987260000
		},
		{
			"open": 521.1321,
			"high": 521.37,
			"low": 521.1321,
			"close": 521.3399,
			"volume": 103460,
			"datetime": 1711987320000
		},
		{
			"open": 521.33,
			"high": 521.39,
			"low": 521.28,
			"close": 521.375,
			"volume": 82863,
			"datetime": 1711987380000
		},
		{
			"open": 521.375,
			"high": 521.6,
			"low": 521.29,
			"close": 521.595,
			"volume": 109624,
			"datetime": 1711987440000
		},
		{
			"open": 521.59,
			"high": 521.665,
			"low": 521.54,
			"close": 521.645,
			"volume": 96774,
			"datetime": 1711987500000
		},
		{
			"open": 521.64,
			"high": 521.68,
			"low": 521.61,
			"close": 521.62,
			"volume": 62414,
			"datetime": 1711987560000
		},
		{
			"open": 521.625,
			"high": 521.71,
			"low": 521.625,
			"close": 521.655,
			"volume": 103918,
			"datetime": 1711987620000
		},
		{
			"open": 521.66,
			"high": 521.8447,
			"low": 521.64,
			"close": 521.825,
			"volume": 73577,
			"datetime": 1711987680000
		},
		{
			"open": 521.82,
			"high": 521.83,
			"low": 521.68,
			"close": 521.71,
			"volume": 53350,
			"datetime": 1711987740000
		},
		{
			"open": 521.7095,
			"high": 521.81,
			"low": 521.67,
			"close": 521.685,
			"volume": 62098,
			"datetime": 1711987800000
		},
		{
			"open": 521.69,
			"high": 521.755,
			"low": 521.6,
			"close": 521.715,
			"volume": 60209,
			"datetime": 1711987860000
		},
		{
			"open": 521.71,
			"high": 521.81,
			"low": 521.71,
			"close": 521.776,
			"volume": 44203,
			"datetime": 1711987920000
		},
		{
			"open": 521.78,
			"high": 521.83,
			"low": 521.7,
			"close": 521.76,
			"volume": 120541,
			"datetime": 1711987980000
		},
		{
			"open": 521.78,
			"high": 521.85,
			"low": 521.7767,
			"close": 521.805,
			"volume": 43294,
			"datetime": 1711988040000
		},
		{
			"open": 521.8,
			"high": 521.925,
			"low": 521.79,
			"close": 521.84,
			"volume": 67276,
			"datetime": 1711988100000
		},
		{
			"open": 521.835,
			"high": 521.9,
			"low": 521.7701,
			"close": 521.885,
			"volume": 47109,
			"datetime": 1711988160000
		},
		{
			"open": 521.885,
			"high": 521.959,
			"low": 521.835,
			"close": 521.9199,
			"volume": 72652,
			"datetime": 1711988220000
		},
		{
			"open": 521.92,
			"high": 521.9396,
			"low": 521.795,
			"close": 521.855,
			"volume": 50662,
			"datetime": 1711988280000
		},
		{
			"open": 521.845,
			"high": 521.855,
			"low": 521.765,
			"close": 521.8,
			"volume": 48641,
			"datetime": 1711988340000
		},
		{
			"open": 521.81,
			"high": 521.87,
			"low": 521.755,
			"close": 521.8201,
			"volume": 33539,
			"datetime": 1711988400000
		},
		{
			"open": 521.82,
			"high": 521.82,
			"low": 521.66,
			"close": 521.78,
			"volume": 62252,
			"datetime": 1711988460000
		},
		{
			"open": 521.762,
			"high": 521.82,
			"low": 521.725,
			"close": 521.815,
			"volume": 43518,
			"datetime": 1711988520000
		},
		{
			"open": 521.814,
			"high": 521.885,
			"low": 521.8,
			"close": 521.85,
			"volume": 52230,
			"datetime": 1711988580000
		},
		{
			"open": 521.8418,
			"high": 521.845,
			"low": 521.7431,
			"close": 521.83,
			"volume": 27993,
			"datetime": 1711988640000
		},
		{
			"open": 521.8199,
			"high": 521.9051,
			"low": 521.79,
			"close": 521.81,
			"volume": 54152,
			"datetime": 1711988700000
		},
		{
			"open": 521.8169,
			"high": 521.919,
			"low": 521.79,
			"close": 521.885,
			"volume": 780112,
			"datetime": 1711988760000
		},
		{
			"open": 521.8981,
			"high": 522.0082,
			"low": 521.87,
			"close": 521.97,
			"volume": 61489,
			"datetime": 1711988820000
		},
		{
			"open": 521.96,
			"high": 522.05,
			"low": 521.945,
			"close": 521.97,
			"volume": 82034,
			"datetime": 1711988880000
		},
		{
			"open": 521.976,
			"high": 522.01,
			"low": 521.91,
			"close": 521.99,
			"volume": 47024,
			"datetime": 1711988940000
		},
		{
			"open": 522,
			"high": 522.14,
			"low": 522,
			"close": 522.11,
			"volume": 82488,
			"datetime": 1711989000000
		},
		{
			"open": 522.11,
			"high": 522.17,
			"low": 522.085,
			"close": 522.11,
			"volume": 55653,
			"datetime": 1711989060000
		},
		{
			"open": 522.11,
			"high": 522.11,
			"low": 522.02,
			"close": 522.04,
			"volume": 31741,
			"datetime": 1711989120000
		},
		{
			"open": 522.0337,
			"high": 522.15,
			"low": 522.03,
			"close": 522.09,
			"volume": 31854,
			"datetime": 1711989180000
		},
		{
			"open": 522.09,
			"high": 522.14,
			"low": 522.05,
			"close": 522.115,
			"volume": 38788,
			"datetime": 1711989240000
		},
		{
			"open": 522.12,
			"high": 522.125,
			"low": 522.015,
			"close": 522.105,
			"volume": 27929,
			"datetime": 1711989300000
		},
		{
			"open": 522.105,
			"high": 522.2,
			"low": 522.09,
			"close": 522.09,
			"volume": 43530,
			"datetime": 1711989360000
		},
		{
			"open": 522.086,
			"high": 522.09,
			"low": 522.03,
			"close": 522.042,
			"volume": 38208,
			"datetime": 1711989420000
		},
		{
			"open": 522.04,
			"high": 522.0401,
			"low": 521.96,
			"close": 522.015,
			"volume": 54643,
			"datetime": 1711989480000
		},
		{
			"open": 521.99,
			"high": 522.1,
			"low": 521.99,
			"close": 522.09,
			"volume": 50714,
			"datetime": 1711989540000
		},
		{
			"open": 522.095,
			"high": 522.175,
			"low": 522.0912,
			"close": 522.16,
			"volume": 45023,
			"datetime": 1711989600000
		},
		{
			"open": 522.15,
			"high": 522.22,
			"low": 522.11,
			"close": 522.12,
			"volume": 37788,
			"datetime": 1711989660000
		},
		{
			"open": 522.1299,
			"high": 522.165,
			"low": 522.11,
			"close": 522.165,
			"volume": 23716,
			"datetime": 1711989720000
		},
		{
			"open": 522.17,
			"high": 522.22,
			"low": 522.15,
			"close": 522.19,
			"volume": 31156,
			"datetime": 1711989780000
		},
		{
			"open": 522.19,
			"high": 522.215,
			"low": 522.14,
			"close": 522.17,
			"volume": 36225,
			"datetime": 1711989840000
		},
		{
			"open": 522.17,
			"high": 522.225,
			"low": 522.17,
			"close": 522.2097,
			"volume": 34404,
			"datetime": 1711989900000
		},
		{
			"open": 522.2001,
			"high": 522.2109,
			"low": 522.07,
			"close": 522.114,
			"volume": 36310,
			"datetime": 1711989960000
		},
		{
			"open": 522.11,
			"high": 522.135,
			"low": 522.075,
			"close": 522.125,
			"volume": 28995,
			"datetime": 1711990020000
		},
		{
			"open": 522.12,
			"high": 522.1213,
			"low": 521.98,
			"close": 522.0186,
			"volume": 53207,
			"datetime": 1711990080000
		},
		{
			"open": 522.0191,
			"high": 522.1282,
			"low": 522.01,
			"close": 522.115,
			"volume": 53432,
			"datetime": 1711990140000
		},
		{
			"open": 522.11,
			"high": 522.18,
			"low": 522.1,
			"close": 522.17,
			"volume": 21709,
			"datetime": 1711990200000
		},
		{
			"open": 522.16,
			"high": 522.21,
			"low": 522.14,
			"close": 522.15,
			"volume": 51049,
			"datetime": 1711990260000
		},
		{
			"open": 522.145,
			"high": 522.16,
			"low": 522.13,
			"close": 522.135,
			"volume": 24148,
			"datetime": 1711990320000
		},
		{
			"open": 522.135,
			"high": 522.145,
			"low": 522.1,
			"close": 522.115,
			"volume": 27717,
			"datetime": 1711990380000
		},
		{
			"open": 522.12,
			"high": 522.175,
			"low": 522.05,
			"close": 522.0616,
			"volume": 36739,
			"datetime": 1711990440000
		},
		{
			"open": 522.06,
			"high": 522.0688,
			"low": 521.94,
			"close": 521.9901,
			"volume": 31389,
			"datetime": 1711990500000
		},
		{
			"open": 521.995,
			"high": 522.005,
			"low": 521.945,
			"close": 521.9699,
			"volume": 27616,
			"datetime": 1711990560000
		},
		{
			"open": 521.96,
			"high": 522.017,
			"low": 521.94,
			"close": 522,
			"volume": 24525,
			"datetime": 1711990620000
		},
		{
			"open": 522,
			"high": 522.0199,
			"low": 521.934,
			"close": 521.98,
			"volume": 53690,
			"datetime": 1711990680000
		},
		{
			"open": 521.98,
			"high": 521.99,
			"low": 521.89,
			"close": 521.905,
			"volume": 40135,
			"datetime": 1711990740000
		},
		{
			"open": 521.91,
			"high": 521.91,
			"low": 521.804,
			"close": 521.8999,
			"volume": 64117,
			"datetime": 1711990800000
		},
		{
			"open": 521.9,
			"high": 521.91,
			"low": 521.76,
			"close": 521.78,
			"volume": 52360,
			"datetime": 1711990860000
		},
		{
			"open": 521.79,
			"high": 521.9,
			"low": 521.7596,
			"close": 521.85,
			"volume": 47913,
			"datetime": 1711990920000
		},
		{
			"open": 521.8599,
			"high": 521.8599,
			"low": 521.7402,
			"close": 521.805,
			"volume": 26347,
			"datetime": 1711990980000
		},
		{
			"open": 521.805,
			"high": 521.805,
			"low": 521.7132,
			"close": 521.765,
			"volume": 29895,
			"datetime": 1711991040000
		},
		{
			"open": 521.77,
			"high": 521.865,
			"low": 521.765,
			"close": 521.84,
			"volume": 38369,
			"datetime": 1711991100000
		},
		{
			"open": 521.84,
			"high": 521.84,
			"low": 521.69,
			"close": 521.71,
			"volume": 35808,
			"datetime": 1711991160000
		},
		{
			"open": 521.71,
			"high": 521.715,
			"low": 521.57,
			"close": 521.6425,
			"volume": 101161,
			"datetime": 1711991220000
		},
		{
			"open": 521.64,
			"high": 521.736,
			"low": 521.6,
			"close": 521.72,
			"volume": 44084,
			"datetime": 1711991280000
		},
		{
			"open": 521.71,
			"high": 521.74,
			"low": 521.6403,
			"close": 521.69,
			"volume": 35871,
			"datetime": 1711991340000
		},
		{
			"open": 521.69,
			"high": 521.705,
			"low": 521.6,
			"close": 521.61,
			"volume": 25170,
			"datetime": 1711991400000
		},
		{
			"open": 521.62,
			"high": 521.64,
			"low": 521.56,
			"close": 521.635,
			"volume": 53971,
			"datetime": 1711991460000
		},
		{
			"open": 521.63,
			"high": 521.78,
			"low": 521.63,
			"close": 521.74,
			"volume": 61005,
			"datetime": 1711991520000
		},
		{
			"open": 521.7401,
			"high": 521.8,
			"low": 521.67,
			"close": 521.69,
			"volume": 56314,
			"datetime": 1711991580000
		},
		{
			"open": 521.69,
			"high": 521.745,
			"low": 521.66,
			"close": 521.7,
			"volume": 25927,
			"datetime": 1711991640000
		},
		{
			"open": 521.7,
			"high": 521.76,
			"low": 521.652,
			"close": 521.69,
			"volume": 29558,
			"datetime": 1711991700000
		},
		{
			"open": 521.6733,
			"high": 521.68,
			"low": 521.54,
			"close": 521.5701,
			"volume": 35432,
			"datetime": 1711991760000
		},
		{
			"open": 521.5709,
			"high": 521.62,
			"low": 521.53,
			"close": 521.57,
			"volume": 67539,
			"datetime": 1711991820000
		},
		{
			"open": 521.56,
			"high": 521.585,
			"low": 521.515,
			"close": 521.58,
			"volume": 25156,
			"datetime": 1711991880000
		},
		{
			"open": 521.57,
			"high": 521.57,
			"low": 521.46,
			"close": 521.4615,
			"volume": 43068,
			"datetime": 1711991940000
		},
		{
			"open": 521.48,
			"high": 521.48,
			"low": 521.25,
			"close": 521.3499,
			"volume": 75858,
			"datetime": 1711992000000
		},
		{
			"open": 521.345,
			"high": 521.36,
			"low": 521.24,
			"close": 521.27,
			"volume": 46333,
			"datetime": 1711992060000
		},
		{
			"open": 521.26,
			"high": 521.305,
			"low": 521.2,
			"close": 521.21,
			"volume": 30696,
			"datetime": 1711992120000
		},
		{
			"open": 521.215,
			"high": 521.27,
			"low": 521.18,
			"close": 521.225,
			"volume": 53569,
			"datetime": 1711992180000
		},
		{
			"open": 521.22,
			"high": 521.2601,
			"low": 521.075,
			"close": 521.09,
			"volume": 63840,
			"datetime": 1711992240000
		},
		{
			"open": 521.09,
			"high": 521.1999,
			"low": 521.06,
			"close": 521.1899,
			"volume": 80869,
			"datetime": 1711992300000
		},
		{
			"open": 521.19,
			"high": 521.35,
			"low": 521.1899,
			"close": 521.31,
			"volume": 80333,
			"datetime": 1711992360000
		},
		{
			"open": 521.31,
			"high": 521.56,
			"low": 521.3098,
			"close": 521.52,
			"volume": 71524,
			"datetime": 1711992420000
		},
		{
			"open": 521.5222,
			"high": 521.59,
			"low": 521.481,
			"close": 521.59,
			"volume": 105700,
			"datetime": 1711992480000
		},
		{
			"open": 521.58,
			"high": 521.63,
			"low": 521.5001,
			"close": 521.61,
			"volume": 63008,
			"datetime": 1711992540000
		},
		{
			"open": 521.62,
			"high": 521.66,
			"low": 521.59,
			"close": 521.66,
			"volume": 30122,
			"datetime": 1711992600000
		},
		{
			"open": 521.66,
			"high": 521.665,
			"low": 521.52,
			"close": 521.5466,
			"volume": 31889,
			"datetime": 1711992660000
		},
		{
			"open": 521.5268,
			"high": 521.56,
			"low": 521.42,
			"close": 521.4301,
			"volume": 32476,
			"datetime": 1711992720000
		},
		{
			"open": 521.445,
			"high": 521.5,
			"low": 521.38,
			"close": 521.445,
			"volume": 51309,
			"datetime": 1711992780000
		},
		{
			"open": 521.44,
			"high": 521.51,
			"low": 521.385,
			"close": 521.405,
			"volume": 27390,
			"datetime": 1711992840000
		},
		{
			"open": 521.415,
			"high": 521.47,
			"low": 521.394,
			"close": 521.42,
			"volume": 14602,
			"datetime": 1711992900000
		},
		{
			"open": 521.43,
			"high": 521.58,
			"low": 521.42,
			"close": 521.55,
			"volume": 26365,
			"datetime": 1711992960000
		},
		{
			"open": 521.5501,
			"high": 521.69,
			"low": 521.54,
			"close": 521.645,
			"volume": 59064,
			"datetime": 1711993020000
		},
		{
			"open": 521.6499,
			"high": 521.66,
			"low": 521.57,
			"close": 521.615,
			"volume": 37904,
			"datetime": 1711993080000
		},
		{
			"open": 521.605,
			"high": 521.62,
			"low": 521.49,
			"close": 521.505,
			"volume": 34786,
			"datetime": 1711993140000
		},
		{
			"open": 521.505,
			"high": 521.615,
			"low": 521.5,
			"close": 521.6,
			"volume": 40800,
			"datetime": 1711993200000
		},
		{
			"open": 521.6,
			"high": 521.6,
			"low": 521.48,
			"close": 521.56,
			"volume": 28876,
			"datetime": 1711993260000
		},
		{
			"open": 521.57,
			"high": 521.6512,
			"low": 521.565,
			"close": 521.57,
			"volume": 24575,
			"datetime": 1711993320000
		},
		{
			"open": 521.55,
			"high": 521.715,
			"low": 521.54,
			"close": 521.69,
			"volume": 40057,
			"datetime": 1711993380000
		},
		{
			"open": 521.7,
			"high": 521.87,
			"low": 521.7,
			"close": 521.8087,
			"volume": 112928,
			"datetime": 1711993440000
		},
		{
			"open": 521.81,
			"high": 521.83,
			"low": 521.6531,
			"close": 521.665,
			"volume": 65069,
			"datetime": 1711993500000
		},
		{
			"open": 521.665,
			"high": 521.8,
			"low": 521.665,
			"close": 521.75,
			"volume": 38053,
			"datetime": 1711993560000
		},
		{
			"open": 521.76,
			"high": 521.77,
			"low": 521.69,
			"close": 521.695,
			"volume": 26899,
			"datetime": 1711993620000
		},
		{
			"open": 521.69,
			"high": 521.715,
			"low": 521.645,
			"close": 521.705,
			"volume": 30959,
			"datetime": 1711993680000
		},
		{
			"open": 521.7097,
			"high": 521.7497,
			"low": 521.6817,
			"close": 521.7088,
			"volume": 26422,
			"datetime": 1711993740000
		},
		{
			"open": 521.705,
			"high": 521.79,
			"low": 521.65,
			"close": 521.71,
			"volume": 57724,
			"datetime": 1711993800000
		},
		{
			"open": 521.7,
			"high": 521.71,
			"low": 521.57,
			"close": 521.59,
			"volume": 49526,
			"datetime": 1711993860000
		},
		{
			"open": 521.6,
			"high": 521.62,
			"low": 521.45,
			"close": 521.46,
			"volume": 62651,
			"datetime": 1711993920000
		},
		{
			"open": 521.44,
			"high": 521.5661,
			"low": 521.39,
			"close": 521.51,
			"volume": 61240,
			"datetime": 1711993980000
		},
		{
			"open": 521.51,
			"high": 521.61,
			"low": 521.506,
			"close": 521.61,
			"volume": 41296,
			"datetime": 1711994040000
		},
		{
			"open": 521.64,
			"high": 521.73,
			"low": 521.62,
			"close": 521.645,
			"volume": 43158,
			"datetime": 1711994100000
		},
		{
			"open": 521.65,
			"high": 521.735,
			"low": 521.65,
			"close": 521.725,
			"volume": 37549,
			"datetime": 1711994160000
		},
		{
			"open": 521.73,
			"high": 521.75,
			"low": 521.65,
			"close": 521.65,
			"volume": 21998,
			"datetime": 1711994220000
		},
		{
			"open": 521.63,
			"high": 521.67,
			"low": 521.57,
			"close": 521.61,
			"volume": 25746,
			"datetime": 1711994280000
		},
		{
			"open": 521.6,
			"high": 521.74,
			"low": 521.59,
			"close": 521.74,
			"volume": 38054,
			"datetime": 1711994340000
		},
		{
			"open": 521.73,
			"high": 521.775,
			"low": 521.7,
			"close": 521.715,
			"volume": 31834,
			"datetime": 1711994400000
		},
		{
			"open": 521.72,
			"high": 521.79,
			"low": 521.68,
			"close": 521.73,
			"volume": 39047,
			"datetime": 1711994460000
		},
		{
			"open": 521.7599,
			"high": 521.78,
			"low": 521.73,
			"close": 521.73,
			"volume": 19003,
			"datetime": 1711994520000
		},
		{
			"open": 521.73,
			"high": 521.745,
			"low": 521.66,
			"close": 521.7,
			"volume": 20817,
			"datetime": 1711994580000
		},
		{
			"open": 521.7,
			"high": 521.8165,
			"low": 521.7,
			"close": 521.806,
			"volume": 34334,
			"datetime": 1711994640000
		},
		{
			"open": 521.8012,
			"high": 521.81,
			"low": 521.69,
			"close": 521.7996,
			"volume": 108250,
			"datetime": 1711994700000
		},
		{
			"open": 521.8,
			"high": 521.8,
			"low": 521.6975,
			"close": 521.71,
			"volume": 42209,
			"datetime": 1711994760000
		},
		{
			"open": 521.7,
			"high": 521.725,
			"low": 521.65,
			"close": 521.69,
			"volume": 62510,
			"datetime": 1711994820000
		},
		{
			"open": 521.685,
			"high": 521.765,
			"low": 521.685,
			"close": 521.715,
			"volume": 26943,
			"datetime": 1711994880000
		},
		{
			"open": 521.7099,
			"high": 521.76,
			"low": 521.675,
			"close": 521.722,
			"volume": 22366,
			"datetime": 1711994940000
		},
		{
			"open": 521.72,
			"high": 521.74,
			"low": 521.63,
			"close": 521.63,
			"volume": 36739,
			"datetime": 1711995000000
		},
		{
			"open": 521.61,
			"high": 521.64,
			"low": 521.57,
			"close": 521.58,
			"volume": 44545,
			"datetime": 1711995060000
		},
		{
			"open": 521.58,
			"high": 521.655,
			"low": 521.51,
			"close": 521.53,
			"volume": 52188,
			"datetime": 1711995120000
		},
		{
			"open": 521.53,
			"high": 521.595,
			"low": 521.47,
			"close": 521.58,
			"volume": 39321,
			"datetime": 1711995180000
		},
		{
			"open": 521.58,
			"high": 521.65,
			"low": 521.545,
			"close": 521.58,
			"volume": 54489,
			"datetime": 1711995240000
		},
		{
			"open": 521.58,
			"high": 521.71,
			"low": 521.54,
			"close": 521.68,
			"volume": 47645,
			"datetime": 1711995300000
		},
		{
			"open": 521.67,
			"high": 521.72,
			"low": 521.6501,
			"close": 521.68,
			"volume": 33648,
			"datetime": 1711995360000
		},
		{
			"open": 521.66,
			"high": 521.79,
			"low": 521.65,
			"close": 521.7499,
			"volume": 51975,
			"datetime": 1711995420000
		},
		{
			"open": 521.75,
			"high": 521.84,
			"low": 521.705,
			"close": 521.79,
			"volume": 57305,
			"datetime": 1711995480000
		},
		{
			"open": 521.79,
			"high": 521.845,
			"low": 521.77,
			"close": 521.84,
			"volume": 45232,
			"datetime": 1711995540000
		},
		{
			"open": 521.85,
			"high": 521.9325,
			"low": 521.78,
			"close": 521.8001,
			"volume": 77810,
			"datetime": 1711995600000
		},
		{
			"open": 521.8,
			"high": 521.81,
			"low": 521.74,
			"close": 521.8099,
			"volume": 45607,
			"datetime": 1711995660000
		},
		{
			"open": 521.82,
			"high": 522.0302,
			"low": 521.82,
			"close": 521.975,
			"volume": 93828,
			"datetime": 1711995720000
		},
		{
			"open": 521.97,
			"high": 522.01,
			"low": 521.94,
			"close": 521.9505,
			"volume": 35420,
			"datetime": 1711995780000
		},
		{
			"open": 521.955,
			"high": 522.01,
			"low": 521.92,
			"close": 522,
			"volume": 20492,
			"datetime": 1711995840000
		},
		{
			"open": 522,
			"high": 522.05,
			"low": 521.98,
			"close": 521.99,
			"volume": 36036,
			"datetime": 1711995900000
		},
		{
			"open": 521.99,
			"high": 522.01,
			"low": 521.9197,
			"close": 521.97,
			"volume": 35414,
			"datetime": 1711995960000
		},
		{
			"open": 521.98,
			"high": 522.03,
			"low": 521.94,
			"close": 521.96,
			"volume": 66050,
			"datetime": 1711996020000
		},
		{
			"open": 521.95,
			"high": 521.985,
			"low": 521.83,
			"close": 521.8586,
			"volume": 34855,
			"datetime": 1711996080000
		},
		{
			"open": 521.85,
			"high": 521.9,
			"low": 521.82,
			"close": 521.8712,
			"volume": 40714,
			"datetime": 1711996140000
		},
		{
			"open": 521.87,
			"high": 521.98,
			"low": 521.855,
			"close": 521.95,
			"volume": 30303,
			"datetime": 1711996200000
		},
		{
			"open": 521.95,
			"high": 522.0197,
			"low": 521.95,
			"close": 521.96,
			"volume": 50197,
			"datetime": 1711996260000
		},
		{
			"open": 521.9621,
			"high": 521.98,
			"low": 521.87,
			"close": 521.89,
			"volume": 23219,
			"datetime": 1711996320000
		},
		{
			"open": 521.9095,
			"high": 521.94,
			"low": 521.89,
			"close": 521.93,
			"volume": 18385,
			"datetime": 1711996380000
		},
		{
			"open": 521.9399,
			"high": 522.005,
			"low": 521.9,
			"close": 521.995,
			"volume": 24107,
			"datetime": 1711996440000
		},
		{
			"open": 522,
			"high": 522,
			"low": 521.92,
			"close": 521.93,
			"volume": 26936,
			"datetime": 1711996500000
		},
		{
			"open": 521.93,
			"high": 521.9787,
			"low": 521.92,
			"close": 521.95,
			"volume": 26179,
			"datetime": 1711996560000
		},
		{
			"open": 521.965,
			"high": 522.02,
			"low": 521.95,
			"close": 521.96,
			"volume": 41395,
			"datetime": 1711996620000
		},
		{
			"open": 521.96,
			"high": 522.07,
			"low": 521.96,
			"close": 522.045,
			"volume": 33788,
			"datetime": 1711996680000
		},
		{
			"open": 522.04,
			"high": 522.095,
			"low": 522.02,
			"close": 522.07,
			"volume": 32890,
			"datetime": 1711996740000
		},
		{
			"open": 522.07,
			"high": 522.145,
			"low": 522.07,
			"close": 522.12,
			"volume": 66359,
			"datetime": 1711996800000
		},
		{
			"open": 522.12,
			"high": 522.12,
			"low": 521.985,
			"close": 522.02,
			"volume": 49525,
			"datetime": 1711996860000
		},
		{
			"open": 522.02,
			"high": 522.045,
			"low": 521.93,
			"close": 521.995,
			"volume": 54206,
			"datetime": 1711996920000
		},
		{
			"open": 521.995,
			"high": 522.11,
			"low": 521.98,
			"close": 522.1,
			"volume": 38188,
			"datetime": 1711996980000
		},
		{
			"open": 522.09,
			"high": 522.09,
			"low": 522.01,
			"close": 522.049,
			"volume": 33399,
			"datetime": 1711997040000
		},
		{
			"open": 522.0401,
			"high": 522.075,
			"low": 522,
			"close": 522.02,
			"volume": 26225,
			"datetime": 1711997100000
		},
		{
			"open": 522.02,
			"high": 522.06,
			"low": 522,
			"close": 522.02,
			"volume": 22628,
			"datetime": 1711997160000
		},
		{
			"open": 522.02,
			"high": 522.055,
			"low": 521.965,
			"close": 521.965,
			"volume": 32839,
			"datetime": 1711997220000
		},
		{
			"open": 521.97,
			"high": 522.045,
			"low": 521.95,
			"close": 522.01,
			"volume": 40855,
			"datetime": 1711997280000
		},
		{
			"open": 522.02,
			"high": 522.07,
			"low": 522,
			"close": 522.005,
			"volume": 28861,
			"datetime": 1711997340000
		},
		{
			"open": 522.01,
			"high": 522.04,
			"low": 521.93,
			"close": 522.04,
			"volume": 69677,
			"datetime": 1711997400000
		},
		{
			"open": 522.034,
			"high": 522.08,
			"low": 522.03,
			"close": 522.06,
			"volume": 32092,
			"datetime": 1711997460000
		},
		{
			"open": 522.06,
			"high": 522.12,
			"low": 522.05,
			"close": 522.09,
			"volume": 33108,
			"datetime": 1711997520000
		},
		{
			"open": 522.09,
			"high": 522.15,
			"low": 522.0199,
			"close": 522.04,
			"volume": 66771,
			"datetime": 1711997580000
		},
		{
			"open": 522.04,
			"high": 522.04,
			"low": 521.95,
			"close": 522.02,
			"volume": 29737,
			"datetime": 1711997640000
		},
		{
			"open": 522.02,
			"high": 522.1094,
			"low": 522.02,
			"close": 522.085,
			"volume": 33919,
			"datetime": 1711997700000
		},
		{
			"open": 522.08,
			"high": 522.085,
			"low": 521.92,
			"close": 521.92,
			"volume": 66657,
			"datetime": 1711997760000
		},
		{
			"open": 521.91,
			"high": 521.95,
			"low": 521.84,
			"close": 521.87,
			"volume": 95778,
			"datetime": 1711997820000
		},
		{
			"open": 521.88,
			"high": 521.895,
			"low": 521.74,
			"close": 521.775,
			"volume": 63530,
			"datetime": 1711997880000
		},
		{
			"open": 521.7701,
			"high": 521.87,
			"low": 521.72,
			"close": 521.86,
			"volume": 59846,
			"datetime": 1711997940000
		},
		{
			"open": 521.86,
			"high": 521.869,
			"low": 521.68,
			"close": 521.75,
			"volume": 98144,
			"datetime": 1711998000000
		},
		{
			"open": 521.755,
			"high": 521.815,
			"low": 521.72,
			"close": 521.81,
			"volume": 79491,
			"datetime": 1711998060000
		},
		{
			"open": 521.8,
			"high": 521.845,
			"low": 521.7615,
			"close": 521.775,
			"volume": 55087,
			"datetime": 1711998120000
		},
		{
			"open": 521.78,
			"high": 521.89,
			"low": 521.765,
			"close": 521.885,
			"volume": 72823,
			"datetime": 1711998180000
		},
		{
			"open": 521.89,
			"high": 522.03,
			"low": 521.88,
			"close": 522.024,
			"volume": 92814,
			"datetime": 1711998240000
		},
		{
			"open": 522.02,
			"high": 522.205,
			"low": 522.02,
			"close": 522.1977,
			"volume": 83700,
			"datetime": 1711998300000
		},
		{
			"open": 522.2,
			"high": 522.34,
			"low": 522.185,
			"close": 522.255,
			"volume": 87358,
			"datetime": 1711998360000
		},
		{
			"open": 522.26,
			"high": 522.32,
			"low": 522.13,
			"close": 522.16,
			"volume": 65398,
			"datetime": 1711998420000
		},
		{
			"open": 522.16,
			"high": 522.175,
			"low": 522.08,
			"close": 522.17,
			"volume": 38646,
			"datetime": 1711998480000
		},
		{
			"open": 522.18,
			"high": 522.238,
			"low": 522.15,
			"close": 522.18,
			"volume": 62838,
			"datetime": 1711998540000
		},
		{
			"open": 522.1888,
			"high": 522.26,
			"low": 522.11,
			"close": 522.1199,
			"volume": 74198,
			"datetime": 1711998600000
		},
		{
			"open": 522.115,
			"high": 522.12,
			"low": 521.97,
			"close": 521.99,
			"volume": 90158,
			"datetime": 1711998660000
		},
		{
			"open": 521.9973,
			"high": 522.03,
			"low": 521.98,
			"close": 522.01,
			"volume": 52666,
			"datetime": 1711998720000
		},
		{
			"open": 522.015,
			"high": 522.055,
			"low": 521.99,
			"close": 522.055,
			"volume": 25001,
			"datetime": 1711998780000
		},
		{
			"open": 522.06,
			"high": 522.08,
			"low": 522.015,
			"close": 522.047,
			"volume": 31297,
			"datetime": 1711998840000
		},
		{
			"open": 522.05,
			"high": 522.095,
			"low": 522,
			"close": 522.08,
			"volume": 56419,
			"datetime": 1711998900000
		},
		{
			"open": 522.075,
			"high": 522.1688,
			"low": 522.075,
			"close": 522.1609,
			"volume": 61528,
			"datetime": 1711998960000
		},
		{
			"open": 522.17,
			"high": 522.195,
			"low": 522.1274,
			"close": 522.1274,
			"volume": 40821,
			"datetime": 1711999020000
		},
		{
			"open": 522.12,
			"high": 522.14,
			"low": 521.87,
			"close": 521.9201,
			"volume": 134819,
			"datetime": 1711999080000
		},
		{
			"open": 521.92,
			"high": 521.93,
			"low": 521.835,
			"close": 521.925,
			"volume": 54770,
			"datetime": 1711999140000
		},
		{
			"open": 521.92,
			"high": 522.1197,
			"low": 521.91,
			"close": 522.1,
			"volume": 74050,
			"datetime": 1711999200000
		},
		{
			"open": 522.1,
			"high": 522.14,
			"low": 522.08,
			"close": 522.08,
			"volume": 41487,
			"datetime": 1711999260000
		},
		{
			"open": 522.075,
			"high": 522.12,
			"low": 521.96,
			"close": 521.965,
			"volume": 40834,
			"datetime": 1711999320000
		},
		{
			"open": 521.96,
			"high": 521.98,
			"low": 521.835,
			"close": 521.86,
			"volume": 42264,
			"datetime": 1711999380000
		},
		{
			"open": 521.86,
			"high": 522,
			"low": 521.85,
			"close": 521.975,
			"volume": 52264,
			"datetime": 1711999440000
		},
		{
			"open": 521.975,
			"high": 522.04,
			"low": 521.91,
			"close": 521.93,
			"volume": 76875,
			"datetime": 1711999500000
		},
		{
			"open": 521.93,
			"high": 521.95,
			"low": 521.76,
			"close": 521.8,
			"volume": 94690,
			"datetime": 1711999560000
		},
		{
			"open": 521.795,
			"high": 521.88,
			"low": 521.76,
			"close": 521.775,
			"volume": 86045,
			"datetime": 1711999620000
		},
		{
			"open": 521.7795,
			"high": 521.8499,
			"low": 521.73,
			"close": 521.78,
			"volume": 76225,
			"datetime": 1711999680000
		},
		{
			"open": 521.78,
			"high": 521.9,
			"low": 521.73,
			"close": 521.87,
			"volume": 45778,
			"datetime": 1711999740000
		},
		{
			"open": 521.875,
			"high": 521.9,
			"low": 521.795,
			"close": 521.865,
			"volume": 111893,
			"datetime": 1711999800000
		},
		{
			"open": 521.87,
			"high": 522.06,
			"low": 521.865,
			"close": 522.02,
			"volume": 130332,
			"datetime": 1711999860000
		},
		{
			"open": 522.02,
			"high": 522.145,
			"low": 521.974,
			"close": 522.1199,
			"volume": 54008,
			"datetime": 1711999920000
		},
		{
			"open": 522.115,
			"high": 522.2,
			"low": 522.08,
			"close": 522.08,
			"volume": 73451,
			"datetime": 1711999980000
		},
		{
			"open": 522.08,
			"high": 522.17,
			"low": 522.08,
			"close": 522.115,
			"volume": 73143,
			"datetime": 1712000040000
		},
		{
			"open": 522.12,
			"high": 522.15,
			"low": 521.79,
			"close": 521.79,
			"volume": 98152,
			"datetime": 1712000100000
		},
		{
			"open": 521.795,
			"high": 521.84,
			"low": 521.61,
			"close": 521.66,
			"volume": 138183,
			"datetime": 1712000160000
		},
		{
			"open": 521.67,
			"high": 521.85,
			"low": 521.6699,
			"close": 521.845,
			"volume": 70066,
			"datetime": 1712000220000
		},
		{
			"open": 521.845,
			"high": 521.87,
			"low": 521.69,
			"close": 521.7489,
			"volume": 76343,
			"datetime": 1712000280000
		},
		{
			"open": 521.7401,
			"high": 521.79,
			"low": 521.69,
			"close": 521.6912,
			"volume": 62575,
			"datetime": 1712000340000
		},
		{
			"open": 521.7,
			"high": 521.8691,
			"low": 521.69,
			"close": 521.8,
			"volume": 77937,
			"datetime": 1712000400000
		},
		{
			"open": 521.8,
			"high": 522.015,
			"low": 521.775,
			"close": 521.985,
			"volume": 71712,
			"datetime": 1712000460000
		},
		{
			"open": 521.985,
			"high": 522.15,
			"low": 521.98,
			"close": 522.15,
			"volume": 97059,
			"datetime": 1712000520000
		},
		{
			"open": 522.145,
			"high": 522.185,
			"low": 522.11,
			"close": 522.14,
			"volume": 93136,
			"datetime": 1712000580000
		},
		{
			"open": 522.145,
			"high": 522.185,
			"low": 522.095,
			"close": 522.125,
			"volume": 110292,
			"datetime": 1712000640000
		},
		{
			"open": 522.13,
			"high": 522.2,
			"low": 522.1,
			"close": 522.15,
			"volume": 199252,
			"datetime": 1712000700000
		},
		{
			"open": 522.16,
			"high": 522.16,
			"low": 521.96,
			"close": 521.975,
			"volume": 182331,
			"datetime": 1712000760000
		},
		{
			"open": 521.97,
			"high": 522.04,
			"low": 521.91,
			"close": 521.98,
			"volume": 155790,
			"datetime": 1712000820000
		},
		{
			"open": 521.98,
			"high": 522.01,
			"low": 521.895,
			"close": 521.95,
			"volume": 154967,
			"datetime": 1712000880000
		},
		{
			"open": 521.95,
			"high": 522.02,
			"low": 521.88,
			"close": 521.89,
			"volume": 119230,
			"datetime": 1712000940000
		},
		{
			"open": 521.9,
			"high": 522.005,
			"low": 521.79,
			"close": 522.005,
			"volume": 218244,
			"datetime": 1712001000000
		},
		{
			"open": 522.005,
			"high": 522.19,
			"low": 521.965,
			"close": 521.985,
			"volume": 292985,
			"datetime": 1712001060000
		},
		{
			"open": 521.99,
			"high": 522.06,
			"low": 521.885,
			"close": 522.035,
			"volume": 175011,
			"datetime": 1712001120000
		},
		{
			"open": 522.05,
			"high": 522.16,
			"low": 522.05,
			"close": 522.115,
			"volume": 112947,
			"datetime": 1712001180000
		},
		{
			"open": 522.12,
			"high": 522.5,
			"low": 522.12,
			"close": 522.475,
			"volume": 246276,
			"datetime": 1712001240000
		},
		{
			"open": 522.47,
			"high": 522.8,
			"low": 522.47,
			"close": 522.5311,
			"volume": 438541,
			"datetime": 1712001300000
		},
		{
			"open": 522.54,
			"high": 522.63,
			"low": 522.45,
			"close": 522.52,
			"volume": 242799,
			"datetime": 1712001360000
		},
		{
			"open": 522.525,
			"high": 522.61,
			"low": 522.445,
			"close": 522.47,
			"volume": 225480,
			"datetime": 1712001420000
		},
		{
			"open": 522.48,
			"high": 522.53,
			"low": 522.26,
			"close": 522.3,
			"volume": 734242,
			"datetime": 1712001480000
		},
		{
			"open": 522.3,
			"high": 522.54,
			"low": 522.14,
			"close": 522.22,
			"volume": 1668897,
			"datetime": 1712001540000
		},
		{
			"open": 518.25,
			"high": 518.46,
			"low": 518.08,
			"close": 518.42,
			"volume": 814995,
			"datetime": 1712064600000
		},
		{
			"open": 518.42,
			"high": 518.42,
			"low": 518.11,
			"close": 518.27,
			"volume": 330064,
			"datetime": 1712064660000
		},
		{
			"open": 518.2701,
			"high": 518.32,
			"low": 518.16,
			"close": 518.185,
			"volume": 277949,
			"datetime": 1712064720000
		},
		{
			"open": 518.19,
			"high": 518.2203,
			"low": 517.95,
			"close": 517.98,
			"volume": 233038,
			"datetime": 1712064780000
		},
		{
			"open": 517.98,
			"high": 518.08,
			"low": 517.89,
			"close": 517.95,
			"volume": 304910,
			"datetime": 1712064840000
		},
		{
			"open": 517.925,
			"high": 517.93,
			"low": 517.77,
			"close": 517.85,
			"volume": 278869,
			"datetime": 1712064900000
		},
		{
			"open": 517.86,
			"high": 517.97,
			"low": 517.82,
			"close": 517.85,
			"volume": 211553,
			"datetime": 1712064960000
		},
		{
			"open": 517.85,
			"high": 517.87,
			"low": 517.65,
			"close": 517.65,
			"volume": 248946,
			"datetime": 1712065020000
		},
		{
			"open": 517.65,
			"high": 517.66,
			"low": 517.47,
			"close": 517.56,
			"volume": 257145,
			"datetime": 1712065080000
		},
		{
			"open": 517.58,
			"high": 517.62,
			"low": 517.46,
			"close": 517.5294,
			"volume": 175069,
			"datetime": 1712065140000
		},
		{
			"open": 517.51,
			"high": 517.52,
			"low": 517.135,
			"close": 517.2776,
			"volume": 308164,
			"datetime": 1712065200000
		},
		{
			"open": 517.27,
			"high": 517.38,
			"low": 517.185,
			"close": 517.27,
			"volume": 242623,
			"datetime": 1712065260000
		},
		{
			"open": 517.28,
			"high": 517.33,
			"low": 517.2199,
			"close": 517.28,
			"volume": 177263,
			"datetime": 1712065320000
		},
		{
			"open": 517.275,
			"high": 517.37,
			"low": 517.1917,
			"close": 517.37,
			"volume": 306747,
			"datetime": 1712065380000
		},
		{
			"open": 517.36,
			"high": 517.56,
			"low": 517.28,
			"close": 517.47,
			"volume": 209342,
			"datetime": 1712065440000
		},
		{
			"open": 517.47,
			"high": 517.5,
			"low": 517.315,
			"close": 517.36,
			"volume": 174532,
			"datetime": 1712065500000
		},
		{
			"open": 517.36,
			"high": 517.415,
			"low": 517.29,
			"close": 517.35,
			"volume": 136862,
			"datetime": 1712065560000
		},
		{
			"open": 517.35,
			"high": 517.35,
			"low": 517.2125,
			"close": 517.34,
			"volume": 208846,
			"datetime": 1712065620000
		},
		{
			"open": 517.34,
			"high": 517.35,
			"low": 517.25,
			"close": 517.26,
			"volume": 194752,
			"datetime": 1712065680000
		},
		{
			"open": 517.26,
			"high": 517.37,
			"low": 517.2,
			"close": 517.2386,
			"volume": 150094,
			"datetime": 1712065740000
		},
		{
			"open": 517.23,
			"high": 517.295,
			"low": 517.17,
			"close": 517.21,
			"volume": 171886,
			"datetime": 1712065800000
		},
		{
			"open": 517.205,
			"high": 517.265,
			"low": 517.04,
			"close": 517.14,
			"volume": 234688,
			"datetime": 1712065860000
		},
		{
			"open": 517.1312,
			"high": 517.22,
			"low": 516.85,
			"close": 517.2,
			"volume": 285225,
			"datetime": 1712065920000
		},
		{
			"open": 517.2,
			"high": 517.3,
			"low": 517.05,
			"close": 517.2701,
			"volume": 143247,
			"datetime": 1712065980000
		},
		{
			"open": 517.27,
			"high": 517.42,
			"low": 517.2276,
			"close": 517.37,
			"volume": 219891,
			"datetime": 1712066040000
		},
		{
			"open": 517.375,
			"high": 517.47,
			"low": 517.35,
			"close": 517.35,
			"volume": 186682,
			"datetime": 1712066100000
		},
		{
			"open": 517.36,
			"high": 517.36,
			"low": 517.0799,
			"close": 517.0799,
			"volume": 118908,
			"datetime": 1712066160000
		},
		{
			"open": 517.08,
			"high": 517.3,
			"low": 517.05,
			"close": 517.155,
			"volume": 121458,
			"datetime": 1712066220000
		},
		{
			"open": 517.13,
			"high": 517.38,
			"low": 517.13,
			"close": 517.33,
			"volume": 114019,
			"datetime": 1712066280000
		},
		{
			"open": 517.33,
			"high": 517.62,
			"low": 517.3,
			"close": 517.6,
			"volume": 148675,
			"datetime": 1712066340000
		},
		{
			"open": 517.61,
			"high": 517.65,
			"low": 516.96,
			"close": 517.415,
			"volume": 365354,
			"datetime": 1712066400000
		},
		{
			"open": 517.415,
			"high": 517.43,
			"low": 517.24,
			"close": 517.37,
			"volume": 191847,
			"datetime": 1712066460000
		},
		{
			"open": 517.38,
			"high": 517.84,
			"low": 517.21,
			"close": 517.786,
			"volume": 202689,
			"datetime": 1712066520000
		},
		{
			"open": 517.79,
			"high": 517.96,
			"low": 517.75,
			"close": 517.9,
			"volume": 249009,
			"datetime": 1712066580000
		},
		{
			"open": 517.9,
			"high": 518.01,
			"low": 517.69,
			"close": 517.99,
			"volume": 210159,
			"datetime": 1712066640000
		},
		{
			"open": 517.98,
			"high": 517.99,
			"low": 517.561,
			"close": 517.6001,
			"volume": 183449,
			"datetime": 1712066700000
		},
		{
			"open": 517.6,
			"high": 517.66,
			"low": 517.36,
			"close": 517.4351,
			"volume": 121180,
			"datetime": 1712066760000
		},
		{
			"open": 517.435,
			"high": 517.435,
			"low": 517.205,
			"close": 517.25,
			"volume": 144004,
			"datetime": 1712066820000
		},
		{
			"open": 517.26,
			"high": 517.46,
			"low": 517.25,
			"close": 517.28,
			"volume": 177221,
			"datetime": 1712066880000
		},
		{
			"open": 517.26,
			"high": 517.34,
			"low": 517.11,
			"close": 517.1899,
			"volume": 182120,
			"datetime": 1712066940000
		},
		{
			"open": 517.18,
			"high": 517.2513,
			"low": 516.96,
			"close": 516.98,
			"volume": 142161,
			"datetime": 1712067000000
		},
		{
			"open": 516.975,
			"high": 517.0182,
			"low": 516.761,
			"close": 516.91,
			"volume": 254312,
			"datetime": 1712067060000
		},
		{
			"open": 516.91,
			"high": 516.91,
			"low": 516.48,
			"close": 516.609,
			"volume": 371570,
			"datetime": 1712067120000
		},
		{
			"open": 516.61,
			"high": 516.72,
			"low": 516.57,
			"close": 516.585,
			"volume": 97982,
			"datetime": 1712067180000
		},
		{
			"open": 516.58,
			"high": 516.63,
			"low": 516.535,
			"close": 516.6,
			"volume": 93921,
			"datetime": 1712067240000
		},
		{
			"open": 516.62,
			"high": 516.7999,
			"low": 516.62,
			"close": 516.631,
			"volume": 142242,
			"datetime": 1712067300000
		},
		{
			"open": 516.636,
			"high": 516.92,
			"low": 516.63,
			"close": 516.7617,
			"volume": 212743,
			"datetime": 1712067360000
		},
		{
			"open": 516.76,
			"high": 516.82,
			"low": 516.652,
			"close": 516.76,
			"volume": 170733,
			"datetime": 1712067420000
		},
		{
			"open": 516.77,
			"high": 516.82,
			"low": 516.52,
			"close": 516.63,
			"volume": 228818,
			"datetime": 1712067480000
		},
		{
			"open": 516.63,
			"high": 516.8687,
			"low": 516.63,
			"close": 516.83,
			"volume": 142389,
			"datetime": 1712067540000
		},
		{
			"open": 516.8201,
			"high": 516.9699,
			"low": 516.8,
			"close": 516.88,
			"volume": 207791,
			"datetime": 1712067600000
		},
		{
			"open": 516.88,
			"high": 517.175,
			"low": 516.88,
			"close": 517.15,
			"volume": 247476,
			"datetime": 1712067660000
		},
		{
			"open": 517.155,
			"high": 517.35,
			"low": 517.09,
			"close": 517.13,
			"volume": 196912,
			"datetime": 1712067720000
		},
		{
			"open": 517.12,
			"high": 517.565,
			"low": 517.06,
			"close": 517.51,
			"volume": 242798,
			"datetime": 1712067780000
		},
		{
			"open": 517.5,
			"high": 517.51,
			"low": 517.31,
			"close": 517.33,
			"volume": 189137,
			"datetime": 1712067840000
		},
		{
			"open": 517.32,
			"high": 517.59,
			"low": 517.28,
			"close": 517.364,
			"volume": 162933,
			"datetime": 1712067900000
		},
		{
			"open": 517.37,
			"high": 517.42,
			"low": 517.32,
			"close": 517.4,
			"volume": 103162,
			"datetime": 1712067960000
		},
		{
			"open": 517.4022,
			"high": 517.515,
			"low": 517.24,
			"close": 517.2599,
			"volume": 140340,
			"datetime": 1712068020000
		},
		{
			"open": 517.25,
			"high": 517.2599,
			"low": 517.03,
			"close": 517.2097,
			"volume": 177973,
			"datetime": 1712068080000
		},
		{
			"open": 517.22,
			"high": 517.3599,
			"low": 517.155,
			"close": 517.17,
			"volume": 81605,
			"datetime": 1712068140000
		},
		{
			"open": 517.17,
			"high": 517.185,
			"low": 517.0387,
			"close": 517.12,
			"volume": 115922,
			"datetime": 1712068200000
		},
		{
			"open": 517.11,
			"high": 517.34,
			"low": 517.11,
			"close": 517.26,
			"volume": 107407,
			"datetime": 1712068260000
		},
		{
			"open": 517.27,
			"high": 517.42,
			"low": 517.19,
			"close": 517.36,
			"volume": 152055,
			"datetime": 1712068320000
		},
		{
			"open": 517.35,
			"high": 517.41,
			"low": 517.03,
			"close": 517.03,
			"volume": 131749,
			"datetime": 1712068380000
		},
		{
			"open": 517.04,
			"high": 517.1001,
			"low": 516.94,
			"close": 517.07,
			"volume": 136465,
			"datetime": 1712068440000
		},
		{
			"open": 517.0699,
			"high": 517.26,
			"low": 517.05,
			"close": 517.25,
			"volume": 85659,
			"datetime": 1712068500000
		},
		{
			"open": 517.25,
			"high": 517.375,
			"low": 517.25,
			"close": 517.36,
			"volume": 107087,
			"datetime": 1712068560000
		},
		{
			"open": 517.35,
			"high": 517.42,
			"low": 517.32,
			"close": 517.34,
			"volume": 111708,
			"datetime": 1712068620000
		},
		{
			"open": 517.3499,
			"high": 517.48,
			"low": 517.34,
			"close": 517.47,
			"volume": 126773,
			"datetime": 1712068680000
		},
		{
			"open": 517.47,
			"high": 517.47,
			"low": 517.285,
			"close": 517.33,
			"volume": 83068,
			"datetime": 1712068740000
		},
		{
			"open": 517.33,
			"high": 517.505,
			"low": 517.32,
			"close": 517.43,
			"volume": 60461,
			"datetime": 1712068800000
		},
		{
			"open": 517.43,
			"high": 517.45,
			"low": 517.22,
			"close": 517.25,
			"volume": 64152,
			"datetime": 1712068860000
		},
		{
			"open": 517.25,
			"high": 517.35,
			"low": 517.22,
			"close": 517.35,
			"volume": 86864,
			"datetime": 1712068920000
		},
		{
			"open": 517.35,
			"high": 517.63,
			"low": 517.35,
			"close": 517.57,
			"volume": 125325,
			"datetime": 1712068980000
		},
		{
			"open": 517.57,
			"high": 517.575,
			"low": 517.36,
			"close": 517.41,
			"volume": 69207,
			"datetime": 1712069040000
		},
		{
			"open": 517.41,
			"high": 517.46,
			"low": 517.28,
			"close": 517.435,
			"volume": 153002,
			"datetime": 1712069100000
		},
		{
			"open": 517.4214,
			"high": 517.5501,
			"low": 517.41,
			"close": 517.425,
			"volume": 65308,
			"datetime": 1712069160000
		},
		{
			"open": 517.43,
			"high": 517.4499,
			"low": 517.23,
			"close": 517.24,
			"volume": 71691,
			"datetime": 1712069220000
		},
		{
			"open": 517.24,
			"high": 517.28,
			"low": 517.01,
			"close": 517.13,
			"volume": 121061,
			"datetime": 1712069280000
		},
		{
			"open": 517.12,
			"high": 517.18,
			"low": 517.005,
			"close": 517.08,
			"volume": 116463,
			"datetime": 1712069340000
		},
		{
			"open": 517.08,
			"high": 517.16,
			"low": 517.04,
			"close": 517.085,
			"volume": 135668,
			"datetime": 1712069400000
		},
		{
			"open": 517.085,
			"high": 517.24,
			"low": 517.08,
			"close": 517.15,
			"volume": 90282,
			"datetime": 1712069460000
		},
		{
			"open": 517.15,
			"high": 517.235,
			"low": 517.0601,
			"close": 517.085,
			"volume": 75285,
			"datetime": 1712069520000
		},
		{
			"open": 517.09,
			"high": 517.15,
			"low": 517.03,
			"close": 517.07,
			"volume": 51003,
			"datetime": 1712069580000
		},
		{
			"open": 517.07,
			"high": 517.1,
			"low": 517.02,
			"close": 517.07,
			"volume": 60269,
			"datetime": 1712069640000
		},
		{
			"open": 517.07,
			"high": 517.09,
			"low": 517.01,
			"close": 517.04,
			"volume": 67138,
			"datetime": 1712069700000
		},
		{
			"open": 517.04,
			"high": 517.05,
			"low": 516.915,
			"close": 516.96,
			"volume": 102965,
			"datetime": 1712069760000
		},
		{
			"open": 516.96,
			"high": 517.03,
			"low": 516.9,
			"close": 516.92,
			"volume": 106012,
			"datetime": 1712069820000
		},
		{
			"open": 516.93,
			"high": 516.985,
			"low": 516.87,
			"close": 516.8899,
			"volume": 108161,
			"datetime": 1712069880000
		},
		{
			"open": 516.88,
			"high": 517,
			"low": 516.7613,
			"close": 516.965,
			"volume": 90920,
			"datetime": 1712069940000
		},
		{
			"open": 516.96,
			"high": 517.08,
			"low": 516.86,
			"close": 517.06,
			"volume": 115192,
			"datetime": 1712070000000
		},
		{
			"open": 517.07,
			"high": 517.15,
			"low": 517.02,
			"close": 517.04,
			"volume": 89261,
			"datetime": 1712070060000
		},
		{
			"open": 517.04,
			"high": 517.045,
			"low": 516.92,
			"close": 517.04,
			"volume": 55674,
			"datetime": 1712070120000
		},
		{
			"open": 517.035,
			"high": 517.09,
			"low": 516.98,
			"close": 516.98,
			"volume": 51451,
			"datetime": 1712070180000
		},
		{
			"open": 516.97,
			"high": 517,
			"low": 516.92,
			"close": 516.9807,
			"volume": 46283,
			"datetime": 1712070240000
		},
		{
			"open": 516.98,
			"high": 517.07,
			"low": 516.92,
			"close": 517.06,
			"volume": 70233,
			"datetime": 1712070300000
		},
		{
			"open": 517.06,
			"high": 517.22,
			"low": 517.05,
			"close": 517.184,
			"volume": 101934,
			"datetime": 1712070360000
		},
		{
			"open": 517.18,
			"high": 517.28,
			"low": 517.14,
			"close": 517.26,
			"volume": 70818,
			"datetime": 1712070420000
		},
		{
			"open": 517.25,
			"high": 517.325,
			"low": 517.14,
			"close": 517.28,
			"volume": 107904,
			"datetime": 1712070480000
		},
		{
			"open": 517.28,
			"high": 517.34,
			"low": 517.23,
			"close": 517.23,
			"volume": 82864,
			"datetime": 1712070540000
		},
		{
			"open": 517.22,
			"high": 517.5101,
			"low": 517.22,
			"close": 517.48,
			"volume": 102283,
			"datetime": 1712070600000
		},
		{
			"open": 517.46,
			"high": 517.56,
			"low": 517.44,
			"close": 517.52,
			"volume": 102989,
			"datetime": 1712070660000
		},
		{
			"open": 517.52,
			"high": 517.57,
			"low": 517.42,
			"close": 517.49,
			"volume": 73821,
			"datetime": 1712070720000
		},
		{
			"open": 517.5,
			"high": 517.51,
			"low": 517.37,
			"close": 517.46,
			"volume": 47434,
			"datetime": 1712070780000
		},
		{
			"open": 517.46,
			"high": 517.66,
			"low": 517.46,
			"close": 517.65,
			"volume": 87297,
			"datetime": 1712070840000
		},
		{
			"open": 517.65,
			"high": 517.72,
			"low": 517.57,
			"close": 517.615,
			"volume": 139687,
			"datetime": 1712070900000
		},
		{
			"open": 517.61,
			"high": 517.6599,
			"low": 517.53,
			"close": 517.595,
			"volume": 75147,
			"datetime": 1712070960000
		},
		{
			"open": 517.59,
			"high": 517.62,
			"low": 517.5101,
			"close": 517.62,
			"volume": 63032,
			"datetime": 1712071020000
		},
		{
			"open": 517.62,
			"high": 517.77,
			"low": 517.555,
			"close": 517.7501,
			"volume": 94918,
			"datetime": 1712071080000
		},
		{
			"open": 517.7501,
			"high": 517.8031,
			"low": 517.7,
			"close": 517.7904,
			"volume": 63052,
			"datetime": 1712071140000
		},
		{
			"open": 517.79,
			"high": 517.82,
			"low": 517.73,
			"close": 517.755,
			"volume": 65312,
			"datetime": 1712071200000
		},
		{
			"open": 517.76,
			"high": 517.8,
			"low": 517.68,
			"close": 517.76,
			"volume": 75473,
			"datetime": 1712071260000
		},
		{
			"open": 517.77,
			"high": 517.81,
			"low": 517.715,
			"close": 517.73,
			"volume": 47932,
			"datetime": 1712071320000
		},
		{
			"open": 517.73,
			"high": 517.75,
			"low": 517.64,
			"close": 517.67,
			"volume": 44235,
			"datetime": 1712071380000
		},
		{
			"open": 517.67,
			"high": 517.695,
			"low": 517.54,
			"close": 517.59,
			"volume": 89812,
			"datetime": 1712071440000
		},
		{
			"open": 517.5991,
			"high": 517.6276,
			"low": 517.52,
			"close": 517.6,
			"volume": 73667,
			"datetime": 1712071500000
		},
		{
			"open": 517.6,
			"high": 517.615,
			"low": 517.49,
			"close": 517.5,
			"volume": 48538,
			"datetime": 1712071560000
		},
		{
			"open": 517.505,
			"high": 517.65,
			"low": 517.505,
			"close": 517.611,
			"volume": 56506,
			"datetime": 1712071620000
		},
		{
			"open": 517.62,
			"high": 517.66,
			"low": 517.54,
			"close": 517.63,
			"volume": 72094,
			"datetime": 1712071680000
		},
		{
			"open": 517.64,
			"high": 517.69,
			"low": 517.54,
			"close": 517.62,
			"volume": 46672,
			"datetime": 1712071740000
		},
		{
			"open": 517.625,
			"high": 517.65,
			"low": 517.55,
			"close": 517.61,
			"volume": 56940,
			"datetime": 1712071800000
		},
		{
			"open": 517.59,
			"high": 517.68,
			"low": 517.55,
			"close": 517.65,
			"volume": 46917,
			"datetime": 1712071860000
		},
		{
			"open": 517.65,
			"high": 517.78,
			"low": 517.61,
			"close": 517.64,
			"volume": 83762,
			"datetime": 1712071920000
		},
		{
			"open": 517.65,
			"high": 517.71,
			"low": 517.62,
			"close": 517.675,
			"volume": 50153,
			"datetime": 1712071980000
		},
		{
			"open": 517.66,
			"high": 517.79,
			"low": 517.65,
			"close": 517.655,
			"volume": 60723,
			"datetime": 1712072040000
		},
		{
			"open": 517.645,
			"high": 517.855,
			"low": 517.645,
			"close": 517.83,
			"volume": 73144,
			"datetime": 1712072100000
		},
		{
			"open": 517.82,
			"high": 517.855,
			"low": 517.765,
			"close": 517.79,
			"volume": 82821,
			"datetime": 1712072160000
		},
		{
			"open": 517.79,
			"high": 517.865,
			"low": 517.675,
			"close": 517.68,
			"volume": 65302,
			"datetime": 1712072220000
		},
		{
			"open": 517.69,
			"high": 517.7,
			"low": 517.5,
			"close": 517.5,
			"volume": 78757,
			"datetime": 1712072280000
		},
		{
			"open": 517.5061,
			"high": 517.56,
			"low": 517.405,
			"close": 517.425,
			"volume": 148041,
			"datetime": 1712072340000
		},
		{
			"open": 517.43,
			"high": 517.46,
			"low": 517.385,
			"close": 517.46,
			"volume": 44330,
			"datetime": 1712072400000
		},
		{
			"open": 517.45,
			"high": 517.5,
			"low": 517.413,
			"close": 517.46,
			"volume": 29247,
			"datetime": 1712072460000
		},
		{
			"open": 517.46,
			"high": 517.58,
			"low": 517.435,
			"close": 517.54,
			"volume": 43336,
			"datetime": 1712072520000
		},
		{
			"open": 517.53,
			"high": 517.635,
			"low": 517.5,
			"close": 517.5799,
			"volume": 48947,
			"datetime": 1712072580000
		},
		{
			"open": 517.57,
			"high": 517.6176,
			"low": 517.38,
			"close": 517.41,
			"volume": 65080,
			"datetime": 1712072640000
		},
		{
			"open": 517.42,
			"high": 517.46,
			"low": 517.36,
			"close": 517.43,
			"volume": 47215,
			"datetime": 1712072700000
		},
		{
			"open": 517.425,
			"high": 517.43,
			"low": 517.31,
			"close": 517.365,
			"volume": 44584,
			"datetime": 1712072760000
		},
		{
			"open": 517.37,
			"high": 517.39,
			"low": 517.33,
			"close": 517.34,
			"volume": 29504,
			"datetime": 1712072820000
		},
		{
			"open": 517.34,
			"high": 517.385,
			"low": 517.293,
			"close": 517.37,
			"volume": 24716,
			"datetime": 1712072880000
		},
		{
			"open": 517.37,
			"high": 517.37,
			"low": 517.2,
			"close": 517.2,
			"volume": 58664,
			"datetime": 1712072940000
		},
		{
			"open": 517.2024,
			"high": 517.2476,
			"low": 517.03,
			"close": 517.095,
			"volume": 99040,
			"datetime": 1712073000000
		},
		{
			"open": 517.1,
			"high": 517.19,
			"low": 517.085,
			"close": 517.19,
			"volume": 115055,
			"datetime": 1712073060000
		},
		{
			"open": 517.1901,
			"high": 517.23,
			"low": 517.116,
			"close": 517.15,
			"volume": 76340,
			"datetime": 1712073120000
		},
		{
			"open": 517.145,
			"high": 517.16,
			"low": 517.01,
			"close": 517.08,
			"volume": 62116,
			"datetime": 1712073180000
		},
		{
			"open": 517.07,
			"high": 517.095,
			"low": 516.99,
			"close": 517.0213,
			"volume": 64646,
			"datetime": 1712073240000
		},
		{
			"open": 517.02,
			"high": 517.22,
			"low": 517,
			"close": 517.215,
			"volume": 89566,
			"datetime": 1712073300000
		},
		{
			"open": 517.22,
			"high": 517.41,
			"low": 517.18,
			"close": 517.365,
			"volume": 180590,
			"datetime": 1712073360000
		},
		{
			"open": 517.365,
			"high": 517.365,
			"low": 517.1901,
			"close": 517.235,
			"volume": 77434,
			"datetime": 1712073420000
		},
		{
			"open": 517.235,
			"high": 517.279,
			"low": 517.11,
			"close": 517.13,
			"volume": 65720,
			"datetime": 1712073480000
		},
		{
			"open": 517.14,
			"high": 517.17,
			"low": 517.07,
			"close": 517.1276,
			"volume": 37352,
			"datetime": 1712073540000
		},
		{
			"open": 517.125,
			"high": 517.1799,
			"low": 517.09,
			"close": 517.165,
			"volume": 47574,
			"datetime": 1712073600000
		},
		{
			"open": 517.16,
			"high": 517.22,
			"low": 517.09,
			"close": 517.18,
			"volume": 67564,
			"datetime": 1712073660000
		},
		{
			"open": 517.17,
			"high": 517.18,
			"low": 517.06,
			"close": 517.17,
			"volume": 61371,
			"datetime": 1712073720000
		},
		{
			"open": 517.16,
			"high": 517.16,
			"low": 516.98,
			"close": 517.025,
			"volume": 115599,
			"datetime": 1712073780000
		},
		{
			"open": 517.03,
			"high": 517.279,
			"low": 517.01,
			"close": 517.23,
			"volume": 60387,
			"datetime": 1712073840000
		},
		{
			"open": 517.22,
			"high": 517.2697,
			"low": 517.155,
			"close": 517.16,
			"volume": 53755,
			"datetime": 1712073900000
		},
		{
			"open": 517.16,
			"high": 517.29,
			"low": 517.1094,
			"close": 517.16,
			"volume": 137179,
			"datetime": 1712073960000
		},
		{
			"open": 517.17,
			"high": 517.19,
			"low": 517.09,
			"close": 517.09,
			"volume": 38660,
			"datetime": 1712074020000
		},
		{
			"open": 517.09,
			"high": 517.12,
			"low": 516.98,
			"close": 517.09,
			"volume": 72816,
			"datetime": 1712074080000
		},
		{
			"open": 517.09,
			"high": 517.165,
			"low": 517.025,
			"close": 517.165,
			"volume": 59016,
			"datetime": 1712074140000
		},
		{
			"open": 517.16,
			"high": 517.235,
			"low": 517.08,
			"close": 517.0899,
			"volume": 56713,
			"datetime": 1712074200000
		},
		{
			"open": 517.085,
			"high": 517.1,
			"low": 516.97,
			"close": 517.005,
			"volume": 69148,
			"datetime": 1712074260000
		},
		{
			"open": 516.995,
			"high": 517.08,
			"low": 516.98,
			"close": 517.0101,
			"volume": 37910,
			"datetime": 1712074320000
		},
		{
			"open": 517.02,
			"high": 517.07,
			"low": 516.975,
			"close": 516.975,
			"volume": 41569,
			"datetime": 1712074380000
		},
		{
			"open": 516.975,
			"high": 517.02,
			"low": 516.9162,
			"close": 516.97,
			"volume": 68192,
			"datetime": 1712074440000
		},
		{
			"open": 516.97,
			"high": 517.08,
			"low": 516.9199,
			"close": 517.08,
			"volume": 47079,
			"datetime": 1712074500000
		},
		{
			"open": 517.08,
			"high": 517.14,
			"low": 516.9924,
			"close": 517.01,
			"volume": 67519,
			"datetime": 1712074560000
		},
		{
			"open": 517.02,
			"high": 517.04,
			"low": 516.925,
			"close": 516.94,
			"volume": 54357,
			"datetime": 1712074620000
		},
		{
			"open": 516.95,
			"high": 516.98,
			"low": 516.92,
			"close": 516.95,
			"volume": 42076,
			"datetime": 1712074680000
		},
		{
			"open": 516.955,
			"high": 517.025,
			"low": 516.94,
			"close": 517.02,
			"volume": 33223,
			"datetime": 1712074740000
		},
		{
			"open": 517.02,
			"high": 517.05,
			"low": 516.9,
			"close": 516.96,
			"volume": 90301,
			"datetime": 1712074800000
		},
		{
			"open": 516.96,
			"high": 517.075,
			"low": 516.96,
			"close": 517,
			"volume": 33855,
			"datetime": 1712074860000
		},
		{
			"open": 517.01,
			"high": 517.24,
			"low": 517.01,
			"close": 517.21,
			"volume": 67463,
			"datetime": 1712074920000
		},
		{
			"open": 517.22,
			"high": 517.335,
			"low": 517.21,
			"close": 517.32,
			"volume": 151438,
			"datetime": 1712074980000
		},
		{
			"open": 517.3101,
			"high": 517.365,
			"low": 517.21,
			"close": 517.22,
			"volume": 56335,
			"datetime": 1712075040000
		},
		{
			"open": 517.215,
			"high": 517.29,
			"low": 517.2,
			"close": 517.29,
			"volume": 71319,
			"datetime": 1712075100000
		},
		{
			"open": 517.28,
			"high": 517.43,
			"low": 517.26,
			"close": 517.39,
			"volume": 78004,
			"datetime": 1712075160000
		},
		{
			"open": 517.39,
			"high": 517.44,
			"low": 517.32,
			"close": 517.365,
			"volume": 125150,
			"datetime": 1712075220000
		},
		{
			"open": 517.37,
			"high": 517.43,
			"low": 517.31,
			"close": 517.32,
			"volume": 81136,
			"datetime": 1712075280000
		},
		{
			"open": 517.3176,
			"high": 517.465,
			"low": 517.29,
			"close": 517.435,
			"volume": 109976,
			"datetime": 1712075340000
		},
		{
			"open": 517.44,
			"high": 517.45,
			"low": 517.3401,
			"close": 517.42,
			"volume": 109031,
			"datetime": 1712075400000
		},
		{
			"open": 517.425,
			"high": 517.465,
			"low": 517.39,
			"close": 517.45,
			"volume": 62618,
			"datetime": 1712075460000
		},
		{
			"open": 517.44,
			"high": 517.44,
			"low": 517.305,
			"close": 517.415,
			"volume": 105012,
			"datetime": 1712075520000
		},
		{
			"open": 517.41,
			"high": 517.47,
			"low": 517.39,
			"close": 517.42,
			"volume": 60260,
			"datetime": 1712075580000
		},
		{
			"open": 517.415,
			"high": 517.47,
			"low": 517.41,
			"close": 517.47,
			"volume": 40392,
			"datetime": 1712075640000
		},
		{
			"open": 517.46,
			"high": 517.535,
			"low": 517.4351,
			"close": 517.5102,
			"volume": 79731,
			"datetime": 1712075700000
		},
		{
			"open": 517.52,
			"high": 517.58,
			"low": 517.5,
			"close": 517.52,
			"volume": 87001,
			"datetime": 1712075760000
		},
		{
			"open": 517.515,
			"high": 517.65,
			"low": 517.49,
			"close": 517.619,
			"volume": 67879,
			"datetime": 1712075820000
		},
		{
			"open": 517.62,
			"high": 517.63,
			"low": 517.55,
			"close": 517.6,
			"volume": 79388,
			"datetime": 1712075880000
		},
		{
			"open": 517.59,
			"high": 517.6,
			"low": 517.5513,
			"close": 517.57,
			"volume": 20895,
			"datetime": 1712075940000
		},
		{
			"open": 517.58,
			"high": 517.66,
			"low": 517.58,
			"close": 517.6,
			"volume": 49944,
			"datetime": 1712076000000
		},
		{
			"open": 517.61,
			"high": 517.66,
			"low": 517.56,
			"close": 517.58,
			"volume": 92100,
			"datetime": 1712076060000
		},
		{
			"open": 517.585,
			"high": 517.66,
			"low": 517.585,
			"close": 517.61,
			"volume": 35826,
			"datetime": 1712076120000
		},
		{
			"open": 517.61,
			"high": 517.63,
			"low": 517.52,
			"close": 517.5551,
			"volume": 56042,
			"datetime": 1712076180000
		},
		{
			"open": 517.56,
			"high": 517.58,
			"low": 517.43,
			"close": 517.46,
			"volume": 73434,
			"datetime": 1712076240000
		},
		{
			"open": 517.465,
			"high": 517.51,
			"low": 517.42,
			"close": 517.49,
			"volume": 46942,
			"datetime": 1712076300000
		},
		{
			"open": 517.49,
			"high": 517.6,
			"low": 517.47,
			"close": 517.6,
			"volume": 42650,
			"datetime": 1712076360000
		},
		{
			"open": 517.6,
			"high": 517.63,
			"low": 517.47,
			"close": 517.5,
			"volume": 45486,
			"datetime": 1712076420000
		},
		{
			"open": 517.5005,
			"high": 517.66,
			"low": 517.5,
			"close": 517.6501,
			"volume": 46062,
			"datetime": 1712076480000
		},
		{
			"open": 517.66,
			"high": 518,
			"low": 517.655,
			"close": 517.9,
			"volume": 265112,
			"datetime": 1712076540000
		},
		{
			"open": 517.9,
			"high": 517.9,
			"low": 517.7681,
			"close": 517.835,
			"volume": 122629,
			"datetime": 1712076600000
		},
		{
			"open": 517.84,
			"high": 517.865,
			"low": 517.735,
			"close": 517.765,
			"volume": 49140,
			"datetime": 1712076660000
		},
		{
			"open": 517.77,
			"high": 517.78,
			"low": 517.69,
			"close": 517.77,
			"volume": 52758,
			"datetime": 1712076720000
		},
		{
			"open": 517.77,
			"high": 517.78,
			"low": 517.695,
			"close": 517.74,
			"volume": 36968,
			"datetime": 1712076780000
		},
		{
			"open": 517.7499,
			"high": 517.83,
			"low": 517.6999,
			"close": 517.8176,
			"volume": 36808,
			"datetime": 1712076840000
		},
		{
			"open": 517.82,
			"high": 517.925,
			"low": 517.81,
			"close": 517.895,
			"volume": 59740,
			"datetime": 1712076900000
		},
		{
			"open": 517.9,
			"high": 517.956,
			"low": 517.795,
			"close": 517.82,
			"volume": 79898,
			"datetime": 1712076960000
		},
		{
			"open": 517.83,
			"high": 517.95,
			"low": 517.83,
			"close": 517.945,
			"volume": 58218,
			"datetime": 1712077020000
		},
		{
			"open": 517.945,
			"high": 517.949,
			"low": 517.85,
			"close": 517.86,
			"volume": 56428,
			"datetime": 1712077080000
		},
		{
			"open": 517.87,
			"high": 517.99,
			"low": 517.87,
			"close": 517.97,
			"volume": 52862,
			"datetime": 1712077140000
		},
		{
			"open": 517.98,
			"high": 518.035,
			"low": 517.94,
			"close": 517.98,
			"volume": 117066,
			"datetime": 1712077200000
		},
		{
			"open": 517.98,
			"high": 518.05,
			"low": 517.96,
			"close": 518.018,
			"volume": 83355,
			"datetime": 1712077260000
		},
		{
			"open": 518.015,
			"high": 518.09,
			"low": 517.99,
			"close": 518.084,
			"volume": 55354,
			"datetime": 1712077320000
		},
		{
			"open": 518.085,
			"high": 518.11,
			"low": 517.995,
			"close": 518.065,
			"volume": 107063,
			"datetime": 1712077380000
		},
		{
			"open": 518.06,
			"high": 518.12,
			"low": 518.04,
			"close": 518.06,
			"volume": 91637,
			"datetime": 1712077440000
		},
		{
			"open": 518.06,
			"high": 518.1,
			"low": 517.96,
			"close": 517.985,
			"volume": 66743,
			"datetime": 1712077500000
		},
		{
			"open": 517.975,
			"high": 518.0201,
			"low": 517.92,
			"close": 518.01,
			"volume": 57133,
			"datetime": 1712077560000
		},
		{
			"open": 518.01,
			"high": 518.0411,
			"low": 517.95,
			"close": 518.03,
			"volume": 43957,
			"datetime": 1712077620000
		},
		{
			"open": 518.035,
			"high": 518.1,
			"low": 517.99,
			"close": 518.015,
			"volume": 105145,
			"datetime": 1712077680000
		},
		{
			"open": 518.01,
			"high": 518.01,
			"low": 517.87,
			"close": 517.9,
			"volume": 107730,
			"datetime": 1712077740000
		},
		{
			"open": 517.9094,
			"high": 517.92,
			"low": 517.84,
			"close": 517.91,
			"volume": 68513,
			"datetime": 1712077800000
		},
		{
			"open": 517.905,
			"high": 517.935,
			"low": 517.79,
			"close": 517.82,
			"volume": 62950,
			"datetime": 1712077860000
		},
		{
			"open": 517.81,
			"high": 517.885,
			"low": 517.77,
			"close": 517.805,
			"volume": 60544,
			"datetime": 1712077920000
		},
		{
			"open": 517.81,
			"high": 517.8105,
			"low": 517.625,
			"close": 517.64,
			"volume": 74075,
			"datetime": 1712077980000
		},
		{
			"open": 517.65,
			"high": 517.71,
			"low": 517.635,
			"close": 517.6591,
			"volume": 48472,
			"datetime": 1712078040000
		},
		{
			"open": 517.655,
			"high": 517.75,
			"low": 517.635,
			"close": 517.71,
			"volume": 71218,
			"datetime": 1712078100000
		},
		{
			"open": 517.71,
			"high": 517.7969,
			"low": 517.695,
			"close": 517.735,
			"volume": 68171,
			"datetime": 1712078160000
		},
		{
			"open": 517.735,
			"high": 517.735,
			"low": 517.56,
			"close": 517.565,
			"volume": 85654,
			"datetime": 1712078220000
		},
		{
			"open": 517.57,
			"high": 517.62,
			"low": 517.53,
			"close": 517.6,
			"volume": 75640,
			"datetime": 1712078280000
		},
		{
			"open": 517.595,
			"high": 517.595,
			"low": 517.5113,
			"close": 517.5252,
			"volume": 47636,
			"datetime": 1712078340000
		},
		{
			"open": 517.52,
			"high": 517.6,
			"low": 517.515,
			"close": 517.5399,
			"volume": 62801,
			"datetime": 1712078400000
		},
		{
			"open": 517.53,
			"high": 517.59,
			"low": 517.48,
			"close": 517.5188,
			"volume": 40992,
			"datetime": 1712078460000
		},
		{
			"open": 517.51,
			"high": 517.685,
			"low": 517.495,
			"close": 517.625,
			"volume": 74881,
			"datetime": 1712078520000
		},
		{
			"open": 517.625,
			"high": 517.7,
			"low": 517.54,
			"close": 517.56,
			"volume": 69637,
			"datetime": 1712078580000
		},
		{
			"open": 517.57,
			"high": 517.62,
			"low": 517.57,
			"close": 517.619,
			"volume": 16314,
			"datetime": 1712078640000
		},
		{
			"open": 517.62,
			"high": 517.68,
			"low": 517.5899,
			"close": 517.6699,
			"volume": 37059,
			"datetime": 1712078700000
		},
		{
			"open": 517.66,
			"high": 517.66,
			"low": 517.55,
			"close": 517.5601,
			"volume": 29206,
			"datetime": 1712078760000
		},
		{
			"open": 517.58,
			"high": 517.6,
			"low": 517.54,
			"close": 517.6,
			"volume": 23320,
			"datetime": 1712078820000
		},
		{
			"open": 517.605,
			"high": 517.69,
			"low": 517.58,
			"close": 517.66,
			"volume": 30228,
			"datetime": 1712078880000
		},
		{
			"open": 517.655,
			"high": 517.73,
			"low": 517.655,
			"close": 517.67,
			"volume": 38140,
			"datetime": 1712078940000
		},
		{
			"open": 517.67,
			"high": 517.69,
			"low": 517.551,
			"close": 517.59,
			"volume": 59481,
			"datetime": 1712079000000
		},
		{
			"open": 517.59,
			"high": 517.73,
			"low": 517.59,
			"close": 517.64,
			"volume": 80450,
			"datetime": 1712079060000
		},
		{
			"open": 517.635,
			"high": 517.7,
			"low": 517.59,
			"close": 517.64,
			"volume": 47605,
			"datetime": 1712079120000
		},
		{
			"open": 517.64,
			"high": 517.66,
			"low": 517.57,
			"close": 517.62,
			"volume": 50841,
			"datetime": 1712079180000
		},
		{
			"open": 517.62,
			"high": 517.6699,
			"low": 517.56,
			"close": 517.58,
			"volume": 56409,
			"datetime": 1712079240000
		},
		{
			"open": 517.575,
			"high": 517.6675,
			"low": 517.56,
			"close": 517.61,
			"volume": 73295,
			"datetime": 1712079300000
		},
		{
			"open": 517.61,
			"high": 517.7,
			"low": 517.6,
			"close": 517.655,
			"volume": 43537,
			"datetime": 1712079360000
		},
		{
			"open": 517.64,
			"high": 517.68,
			"low": 517.59,
			"close": 517.5987,
			"volume": 35199,
			"datetime": 1712079420000
		},
		{
			"open": 517.595,
			"high": 517.61,
			"low": 517.41,
			"close": 517.463,
			"volume": 90688,
			"datetime": 1712079480000
		},
		{
			"open": 517.4697,
			"high": 517.6,
			"low": 517.455,
			"close": 517.56,
			"volume": 43283,
			"datetime": 1712079540000
		},
		{
			"open": 517.566,
			"high": 517.58,
			"low": 517.53,
			"close": 517.5376,
			"volume": 26748,
			"datetime": 1712079600000
		},
		{
			"open": 517.54,
			"high": 517.57,
			"low": 517.4699,
			"close": 517.486,
			"volume": 39109,
			"datetime": 1712079660000
		},
		{
			"open": 517.48,
			"high": 517.5276,
			"low": 517.45,
			"close": 517.48,
			"volume": 72820,
			"datetime": 1712079720000
		},
		{
			"open": 517.49,
			"high": 517.52,
			"low": 517.46,
			"close": 517.5099,
			"volume": 73817,
			"datetime": 1712079780000
		},
		{
			"open": 517.505,
			"high": 517.625,
			"low": 517.35,
			"close": 517.56,
			"volume": 231877,
			"datetime": 1712079840000
		},
		{
			"open": 517.55,
			"high": 517.69,
			"low": 517.53,
			"close": 517.6624,
			"volume": 75635,
			"datetime": 1712079900000
		},
		{
			"open": 517.67,
			"high": 517.7399,
			"low": 517.6,
			"close": 517.7,
			"volume": 56095,
			"datetime": 1712079960000
		},
		{
			"open": 517.69,
			"high": 517.76,
			"low": 517.625,
			"close": 517.73,
			"volume": 62871,
			"datetime": 1712080020000
		},
		{
			"open": 517.74,
			"high": 517.81,
			"low": 517.7001,
			"close": 517.81,
			"volume": 68342,
			"datetime": 1712080080000
		},
		{
			"open": 517.8076,
			"high": 517.87,
			"low": 517.8036,
			"close": 517.865,
			"volume": 59239,
			"datetime": 1712080140000
		},
		{
			"open": 517.87,
			"high": 517.94,
			"low": 517.835,
			"close": 517.865,
			"volume": 68909,
			"datetime": 1712080200000
		},
		{
			"open": 517.86,
			"high": 517.9,
			"low": 517.725,
			"close": 517.78,
			"volume": 85256,
			"datetime": 1712080260000
		},
		{
			"open": 517.78,
			"high": 517.8,
			"low": 517.72,
			"close": 517.78,
			"volume": 58663,
			"datetime": 1712080320000
		},
		{
			"open": 517.775,
			"high": 517.7851,
			"low": 517.665,
			"close": 517.68,
			"volume": 76303,
			"datetime": 1712080380000
		},
		{
			"open": 517.68,
			"high": 517.785,
			"low": 517.66,
			"close": 517.745,
			"volume": 71691,
			"datetime": 1712080440000
		},
		{
			"open": 517.74,
			"high": 517.825,
			"low": 517.695,
			"close": 517.815,
			"volume": 132399,
			"datetime": 1712080500000
		},
		{
			"open": 517.815,
			"high": 517.85,
			"low": 517.715,
			"close": 517.74,
			"volume": 84161,
			"datetime": 1712080560000
		},
		{
			"open": 517.75,
			"high": 517.85,
			"low": 517.74,
			"close": 517.81,
			"volume": 67638,
			"datetime": 1712080620000
		},
		{
			"open": 517.82,
			"high": 517.91,
			"low": 517.82,
			"close": 517.8399,
			"volume": 56097,
			"datetime": 1712080680000
		},
		{
			"open": 517.84,
			"high": 517.8691,
			"low": 517.72,
			"close": 517.75,
			"volume": 76835,
			"datetime": 1712080740000
		},
		{
			"open": 517.76,
			"high": 517.85,
			"low": 517.75,
			"close": 517.85,
			"volume": 70367,
			"datetime": 1712080800000
		},
		{
			"open": 517.84,
			"high": 517.85,
			"low": 517.74,
			"close": 517.76,
			"volume": 60469,
			"datetime": 1712080860000
		},
		{
			"open": 517.7599,
			"high": 517.81,
			"low": 517.67,
			"close": 517.69,
			"volume": 51372,
			"datetime": 1712080920000
		},
		{
			"open": 517.68,
			"high": 517.7687,
			"low": 517.67,
			"close": 517.7397,
			"volume": 66734,
			"datetime": 1712080980000
		},
		{
			"open": 517.73,
			"high": 517.77,
			"low": 517.68,
			"close": 517.76,
			"volume": 39236,
			"datetime": 1712081040000
		},
		{
			"open": 517.77,
			"high": 517.81,
			"low": 517.66,
			"close": 517.68,
			"volume": 33288,
			"datetime": 1712081100000
		},
		{
			"open": 517.68,
			"high": 517.78,
			"low": 517.675,
			"close": 517.75,
			"volume": 24966,
			"datetime": 1712081160000
		},
		{
			"open": 517.76,
			"high": 517.875,
			"low": 517.73,
			"close": 517.85,
			"volume": 76741,
			"datetime": 1712081220000
		},
		{
			"open": 517.85,
			"high": 517.97,
			"low": 517.85,
			"close": 517.91,
			"volume": 137097,
			"datetime": 1712081280000
		},
		{
			"open": 517.91,
			"high": 517.97,
			"low": 517.9,
			"close": 517.9587,
			"volume": 60568,
			"datetime": 1712081340000
		},
		{
			"open": 517.96,
			"high": 518.01,
			"low": 517.915,
			"close": 518.01,
			"volume": 46962,
			"datetime": 1712081400000
		},
		{
			"open": 518.01,
			"high": 518.015,
			"low": 517.936,
			"close": 517.936,
			"volume": 66828,
			"datetime": 1712081460000
		},
		{
			"open": 517.94,
			"high": 517.97,
			"low": 517.8,
			"close": 517.82,
			"volume": 56545,
			"datetime": 1712081520000
		},
		{
			"open": 517.8298,
			"high": 517.896,
			"low": 517.8298,
			"close": 517.84,
			"volume": 41551,
			"datetime": 1712081580000
		},
		{
			"open": 517.84,
			"high": 517.94,
			"low": 517.82,
			"close": 517.86,
			"volume": 49617,
			"datetime": 1712081640000
		},
		{
			"open": 517.86,
			"high": 517.92,
			"low": 517.855,
			"close": 517.9,
			"volume": 27539,
			"datetime": 1712081700000
		},
		{
			"open": 517.91,
			"high": 517.92,
			"low": 517.845,
			"close": 517.86,
			"volume": 32899,
			"datetime": 1712081760000
		},
		{
			"open": 517.86,
			"high": 517.926,
			"low": 517.86,
			"close": 517.9001,
			"volume": 25340,
			"datetime": 1712081820000
		},
		{
			"open": 517.9,
			"high": 517.9191,
			"low": 517.855,
			"close": 517.88,
			"volume": 21215,
			"datetime": 1712081880000
		},
		{
			"open": 517.87,
			"high": 518.02,
			"low": 517.8625,
			"close": 518.015,
			"volume": 49899,
			"datetime": 1712081940000
		},
		{
			"open": 518.02,
			"high": 518.08,
			"low": 518,
			"close": 518.06,
			"volume": 73427,
			"datetime": 1712082000000
		},
		{
			"open": 518.065,
			"high": 518.12,
			"low": 518.02,
			"close": 518.05,
			"volume": 82468,
			"datetime": 1712082060000
		},
		{
			"open": 518.045,
			"high": 518.09,
			"low": 517.92,
			"close": 517.945,
			"volume": 51875,
			"datetime": 1712082120000
		},
		{
			"open": 517.9499,
			"high": 517.98,
			"low": 517.82,
			"close": 517.83,
			"volume": 105628,
			"datetime": 1712082180000
		},
		{
			"open": 517.83,
			"high": 517.89,
			"low": 517.82,
			"close": 517.88,
			"volume": 44700,
			"datetime": 1712082240000
		},
		{
			"open": 517.88,
			"high": 517.94,
			"low": 517.855,
			"close": 517.91,
			"volume": 28973,
			"datetime": 1712082300000
		},
		{
			"open": 517.91,
			"high": 517.95,
			"low": 517.91,
			"close": 517.93,
			"volume": 33279,
			"datetime": 1712082360000
		},
		{
			"open": 517.94,
			"high": 518.0181,
			"low": 517.905,
			"close": 518.0181,
			"volume": 82274,
			"datetime": 1712082420000
		},
		{
			"open": 518.01,
			"high": 518.07,
			"low": 517.995,
			"close": 518.05,
			"volume": 53346,
			"datetime": 1712082480000
		},
		{
			"open": 518.05,
			"high": 518.06,
			"low": 517.89,
			"close": 517.905,
			"volume": 62372,
			"datetime": 1712082540000
		},
		{
			"open": 517.91,
			"high": 518,
			"low": 517.89,
			"close": 518,
			"volume": 62196,
			"datetime": 1712082600000
		},
		{
			"open": 518.01,
			"high": 518.06,
			"low": 517.975,
			"close": 518.04,
			"volume": 67323,
			"datetime": 1712082660000
		},
		{
			"open": 518.04,
			"high": 518.07,
			"low": 517.995,
			"close": 518,
			"volume": 51117,
			"datetime": 1712082720000
		},
		{
			"open": 518,
			"high": 518.0876,
			"low": 518,
			"close": 518.06,
			"volume": 37209,
			"datetime": 1712082780000
		},
		{
			"open": 518.07,
			"high": 518.1,
			"low": 518.01,
			"close": 518.01,
			"volume": 48318,
			"datetime": 1712082840000
		},
		{
			"open": 518.005,
			"high": 518.02,
			"low": 517.91,
			"close": 517.94,
			"volume": 60236,
			"datetime": 1712082900000
		},
		{
			"open": 517.94,
			"high": 517.97,
			"low": 517.8,
			"close": 517.8,
			"volume": 81841,
			"datetime": 1712082960000
		},
		{
			"open": 517.805,
			"high": 517.825,
			"low": 517.73,
			"close": 517.815,
			"volume": 104860,
			"datetime": 1712083020000
		},
		{
			"open": 517.82,
			"high": 517.91,
			"low": 517.82,
			"close": 517.84,
			"volume": 43819,
			"datetime": 1712083080000
		},
		{
			"open": 517.84,
			"high": 517.94,
			"low": 517.8313,
			"close": 517.88,
			"volume": 37311,
			"datetime": 1712083140000
		},
		{
			"open": 517.8824,
			"high": 517.96,
			"low": 517.875,
			"close": 517.96,
			"volume": 41658,
			"datetime": 1712083200000
		},
		{
			"open": 517.96,
			"high": 518.14,
			"low": 517.955,
			"close": 518.07,
			"volume": 122511,
			"datetime": 1712083260000
		},
		{
			"open": 518.08,
			"high": 518.13,
			"low": 518.04,
			"close": 518.08,
			"volume": 72490,
			"datetime": 1712083320000
		},
		{
			"open": 518.0799,
			"high": 518.11,
			"low": 518.0301,
			"close": 518.11,
			"volume": 37935,
			"datetime": 1712083380000
		},
		{
			"open": 518.105,
			"high": 518.105,
			"low": 517.994,
			"close": 518.04,
			"volume": 71741,
			"datetime": 1712083440000
		},
		{
			"open": 518.04,
			"high": 518.045,
			"low": 517.8101,
			"close": 517.8101,
			"volume": 58888,
			"datetime": 1712083500000
		},
		{
			"open": 517.815,
			"high": 517.815,
			"low": 517.64,
			"close": 517.8,
			"volume": 147082,
			"datetime": 1712083560000
		},
		{
			"open": 517.8,
			"high": 517.8003,
			"low": 517.671,
			"close": 517.745,
			"volume": 65765,
			"datetime": 1712083620000
		},
		{
			"open": 517.745,
			"high": 517.84,
			"low": 517.73,
			"close": 517.8099,
			"volume": 69225,
			"datetime": 1712083680000
		},
		{
			"open": 517.805,
			"high": 517.85,
			"low": 517.75,
			"close": 517.78,
			"volume": 58507,
			"datetime": 1712083740000
		},
		{
			"open": 517.7851,
			"high": 517.94,
			"low": 517.77,
			"close": 517.91,
			"volume": 48382,
			"datetime": 1712083800000
		},
		{
			"open": 517.9,
			"high": 517.97,
			"low": 517.9,
			"close": 517.93,
			"volume": 36243,
			"datetime": 1712083860000
		},
		{
			"open": 517.93,
			"high": 518.02,
			"low": 517.93,
			"close": 517.98,
			"volume": 53059,
			"datetime": 1712083920000
		},
		{
			"open": 517.99,
			"high": 518.02,
			"low": 517.92,
			"close": 517.975,
			"volume": 47543,
			"datetime": 1712083980000
		},
		{
			"open": 517.98,
			"high": 517.99,
			"low": 517.87,
			"close": 517.87,
			"volume": 130252,
			"datetime": 1712084040000
		},
		{
			"open": 517.8701,
			"high": 517.97,
			"low": 517.86,
			"close": 517.945,
			"volume": 91877,
			"datetime": 1712084100000
		},
		{
			"open": 517.94,
			"high": 517.95,
			"low": 517.8,
			"close": 517.82,
			"volume": 78533,
			"datetime": 1712084160000
		},
		{
			"open": 517.81,
			"high": 517.8899,
			"low": 517.79,
			"close": 517.8,
			"volume": 152472,
			"datetime": 1712084220000
		},
		{
			"open": 517.79,
			"high": 517.81,
			"low": 517.731,
			"close": 517.78,
			"volume": 38030,
			"datetime": 1712084280000
		},
		{
			"open": 517.775,
			"high": 517.8,
			"low": 517.745,
			"close": 517.79,
			"volume": 32849,
			"datetime": 1712084340000
		},
		{
			"open": 517.79,
			"high": 517.855,
			"low": 517.745,
			"close": 517.7487,
			"volume": 76252,
			"datetime": 1712084400000
		},
		{
			"open": 517.74,
			"high": 517.97,
			"low": 517.73,
			"close": 517.94,
			"volume": 68038,
			"datetime": 1712084460000
		},
		{
			"open": 517.9476,
			"high": 517.96,
			"low": 517.87,
			"close": 517.944,
			"volume": 39307,
			"datetime": 1712084520000
		},
		{
			"open": 517.95,
			"high": 518.08,
			"low": 517.935,
			"close": 518.055,
			"volume": 86307,
			"datetime": 1712084580000
		},
		{
			"open": 518.06,
			"high": 518.18,
			"low": 518.0401,
			"close": 518.17,
			"volume": 329487,
			"datetime": 1712084640000
		},
		{
			"open": 518.16,
			"high": 518.59,
			"low": 518.1432,
			"close": 518.5,
			"volume": 357681,
			"datetime": 1712084700000
		},
		{
			"open": 518.5,
			"high": 518.55,
			"low": 518.385,
			"close": 518.4699,
			"volume": 114157,
			"datetime": 1712084760000
		},
		{
			"open": 518.47,
			"high": 518.47,
			"low": 518.355,
			"close": 518.39,
			"volume": 53802,
			"datetime": 1712084820000
		},
		{
			"open": 518.39,
			"high": 518.5899,
			"low": 518.39,
			"close": 518.4488,
			"volume": 125517,
			"datetime": 1712084880000
		},
		{
			"open": 518.44,
			"high": 518.44,
			"low": 518.32,
			"close": 518.35,
			"volume": 84293,
			"datetime": 1712084940000
		},
		{
			"open": 518.35,
			"high": 518.45,
			"low": 518.35,
			"close": 518.44,
			"volume": 75361,
			"datetime": 1712085000000
		},
		{
			"open": 518.44,
			"high": 518.47,
			"low": 518.375,
			"close": 518.4099,
			"volume": 72901,
			"datetime": 1712085060000
		},
		{
			"open": 518.4,
			"high": 518.4599,
			"low": 518.28,
			"close": 518.28,
			"volume": 104381,
			"datetime": 1712085120000
		},
		{
			"open": 518.28,
			"high": 518.305,
			"low": 518.1,
			"close": 518.13,
			"volume": 125878,
			"datetime": 1712085180000
		},
		{
			"open": 518.13,
			"high": 518.13,
			"low": 517.9703,
			"close": 517.98,
			"volume": 130326,
			"datetime": 1712085240000
		},
		{
			"open": 517.98,
			"high": 518.11,
			"low": 517.921,
			"close": 518.0599,
			"volume": 117165,
			"datetime": 1712085300000
		},
		{
			"open": 518.06,
			"high": 518.13,
			"low": 517.95,
			"close": 518.03,
			"volume": 122420,
			"datetime": 1712085360000
		},
		{
			"open": 518.02,
			"high": 518.0899,
			"low": 517.925,
			"close": 517.96,
			"volume": 87822,
			"datetime": 1712085420000
		},
		{
			"open": 517.96,
			"high": 518.01,
			"low": 517.9001,
			"close": 517.99,
			"volume": 81941,
			"datetime": 1712085480000
		},
		{
			"open": 517.985,
			"high": 518.01,
			"low": 517.87,
			"close": 517.91,
			"volume": 78686,
			"datetime": 1712085540000
		},
		{
			"open": 517.91,
			"high": 518.18,
			"low": 517.89,
			"close": 518.15,
			"volume": 122313,
			"datetime": 1712085600000
		},
		{
			"open": 518.16,
			"high": 518.215,
			"low": 518.1,
			"close": 518.13,
			"volume": 69301,
			"datetime": 1712085660000
		},
		{
			"open": 518.1292,
			"high": 518.17,
			"low": 518.02,
			"close": 518.07,
			"volume": 68364,
			"datetime": 1712085720000
		},
		{
			"open": 518.09,
			"high": 518.215,
			"low": 518.09,
			"close": 518.2,
			"volume": 85863,
			"datetime": 1712085780000
		},
		{
			"open": 518.21,
			"high": 518.37,
			"low": 518.205,
			"close": 518.35,
			"volume": 91239,
			"datetime": 1712085840000
		},
		{
			"open": 518.36,
			"high": 518.47,
			"low": 518.3499,
			"close": 518.42,
			"volume": 193118,
			"datetime": 1712085900000
		},
		{
			"open": 518.42,
			"high": 518.52,
			"low": 518.415,
			"close": 518.47,
			"volume": 156901,
			"datetime": 1712085960000
		},
		{
			"open": 518.46,
			"high": 518.57,
			"low": 518.44,
			"close": 518.48,
			"volume": 111445,
			"datetime": 1712086020000
		},
		{
			"open": 518.48,
			"high": 518.55,
			"low": 518.39,
			"close": 518.45,
			"volume": 114954,
			"datetime": 1712086080000
		},
		{
			"open": 518.46,
			"high": 518.5,
			"low": 518.38,
			"close": 518.43,
			"volume": 82743,
			"datetime": 1712086140000
		},
		{
			"open": 518.44,
			"high": 518.49,
			"low": 518.3,
			"close": 518.35,
			"volume": 111001,
			"datetime": 1712086200000
		},
		{
			"open": 518.35,
			"high": 518.375,
			"low": 518.28,
			"close": 518.29,
			"volume": 99549,
			"datetime": 1712086260000
		},
		{
			"open": 518.31,
			"high": 518.58,
			"low": 518.285,
			"close": 518.575,
			"volume": 129327,
			"datetime": 1712086320000
		},
		{
			"open": 518.59,
			"high": 518.74,
			"low": 518.58,
			"close": 518.65,
			"volume": 235950,
			"datetime": 1712086380000
		},
		{
			"open": 518.66,
			"high": 518.69,
			"low": 518.605,
			"close": 518.65,
			"volume": 156620,
			"datetime": 1712086440000
		},
		{
			"open": 518.66,
			"high": 518.83,
			"low": 518.62,
			"close": 518.76,
			"volume": 227180,
			"datetime": 1712086500000
		},
		{
			"open": 518.765,
			"high": 518.86,
			"low": 518.735,
			"close": 518.8,
			"volume": 200822,
			"datetime": 1712086560000
		},
		{
			"open": 518.81,
			"high": 518.81,
			"low": 518.67,
			"close": 518.685,
			"volume": 153608,
			"datetime": 1712086620000
		},
		{
			"open": 518.685,
			"high": 518.745,
			"low": 518.67,
			"close": 518.72,
			"volume": 126496,
			"datetime": 1712086680000
		},
		{
			"open": 518.73,
			"high": 518.8,
			"low": 518.72,
			"close": 518.75,
			"volume": 120596,
			"datetime": 1712086740000
		},
		{
			"open": 518.76,
			"high": 518.85,
			"low": 518.75,
			"close": 518.82,
			"volume": 189852,
			"datetime": 1712086800000
		},
		{
			"open": 518.82,
			"high": 518.825,
			"low": 518.5799,
			"close": 518.62,
			"volume": 216289,
			"datetime": 1712086860000
		},
		{
			"open": 518.62,
			"high": 518.67,
			"low": 518.58,
			"close": 518.5883,
			"volume": 239710,
			"datetime": 1712086920000
		},
		{
			"open": 518.5899,
			"high": 518.73,
			"low": 518.575,
			"close": 518.7299,
			"volume": 171805,
			"datetime": 1712086980000
		},
		{
			"open": 518.725,
			"high": 518.8,
			"low": 518.69,
			"close": 518.71,
			"volume": 133209,
			"datetime": 1712087040000
		},
		{
			"open": 518.71,
			"high": 518.71,
			"low": 518.53,
			"close": 518.625,
			"volume": 165586,
			"datetime": 1712087100000
		},
		{
			"open": 518.625,
			"high": 518.76,
			"low": 518.6,
			"close": 518.7599,
			"volume": 200056,
			"datetime": 1712087160000
		},
		{
			"open": 518.755,
			"high": 518.79,
			"low": 518.68,
			"close": 518.685,
			"volume": 207206,
			"datetime": 1712087220000
		},
		{
			"open": 518.69,
			"high": 518.82,
			"low": 518.61,
			"close": 518.815,
			"volume": 193397,
			"datetime": 1712087280000
		},
		{
			"open": 518.815,
			"high": 518.97,
			"low": 518.815,
			"close": 518.97,
			"volume": 215031,
			"datetime": 1712087340000
		},
		{
			"open": 518.98,
			"high": 518.98,
			"low": 518.66,
			"close": 518.67,
			"volume": 535069,
			"datetime": 1712087400000
		},
		{
			"open": 518.68,
			"high": 518.965,
			"low": 518.63,
			"close": 518.92,
			"volume": 479136,
			"datetime": 1712087460000
		},
		{
			"open": 518.91,
			"high": 518.935,
			"low": 518.6,
			"close": 518.6299,
			"volume": 518462,
			"datetime": 1712087520000
		},
		{
			"open": 518.62,
			"high": 518.66,
			"low": 518.54,
			"close": 518.57,
			"volume": 431348,
			"datetime": 1712087580000
		},
		{
			"open": 518.56,
			"high": 518.88,
			"low": 518.56,
			"close": 518.81,
			"volume": 524142,
			"datetime": 1712087640000
		},
		{
			"open": 518.82,
			"high": 518.86,
			"low": 518.65,
			"close": 518.755,
			"volume": 618864,
			"datetime": 1712087700000
		},
		{
			"open": 518.74,
			"high": 518.82,
			"low": 518.62,
			"close": 518.68,
			"volume": 548443,
			"datetime": 1712087760000
		},
		{
			"open": 518.675,
			"high": 518.795,
			"low": 518.655,
			"close": 518.72,
			"volume": 640414,
			"datetime": 1712087820000
		},
		{
			"open": 518.72,
			"high": 518.75,
			"low": 518.63,
			"close": 518.72,
			"volume": 1317079,
			"datetime": 1712087880000
		},
		{
			"open": 518.72,
			"high": 518.92,
			"low": 518.67,
			"close": 518.85,
			"volume": 3619560,
			"datetime": 1712087940000
		},
		{
			"open": 517.72,
			"high": 518.02,
			"low": 517.665,
			"close": 517.735,
			"volume": 460685,
			"datetime": 1712151000000
		},
		{
			"open": 517.75,
			"high": 518.03,
			"low": 517.72,
			"close": 518.02,
			"volume": 226618,
			"datetime": 1712151060000
		},
		{
			"open": 518.02,
			"high": 518.34,
			"low": 517.98,
			"close": 518.24,
			"volume": 151006,
			"datetime": 1712151120000
		},
		{
			"open": 518.23,
			"high": 518.26,
			"low": 518.02,
			"close": 518.12,
			"volume": 182867,
			"datetime": 1712151180000
		},
		{
			"open": 518.135,
			"high": 518.3003,
			"low": 518.12,
			"close": 518.21,
			"volume": 127789,
			"datetime": 1712151240000
		},
		{
			"open": 518.21,
			"high": 518.29,
			"low": 518.16,
			"close": 518.215,
			"volume": 145494,
			"datetime": 1712151300000
		},
		{
			"open": 518.215,
			"high": 518.49,
			"low": 518.21,
			"close": 518.41,
			"volume": 177151,
			"datetime": 1712151360000
		},
		{
			"open": 518.4,
			"high": 518.4787,
			"low": 518.29,
			"close": 518.4687,
			"volume": 146829,
			"datetime": 1712151420000
		},
		{
			"open": 518.48,
			"high": 518.52,
			"low": 518.31,
			"close": 518.31,
			"volume": 113337,
			"datetime": 1712151480000
		},
		{
			"open": 518.33,
			"high": 518.51,
			"low": 518.275,
			"close": 518.468,
			"volume": 108151,
			"datetime": 1712151540000
		},
		{
			"open": 518.46,
			"high": 518.58,
			"low": 518.43,
			"close": 518.48,
			"volume": 160899,
			"datetime": 1712151600000
		},
		{
			"open": 518.47,
			"high": 518.53,
			"low": 518.31,
			"close": 518.415,
			"volume": 95568,
			"datetime": 1712151660000
		},
		{
			"open": 518.41,
			"high": 518.42,
			"low": 518.235,
			"close": 518.38,
			"volume": 91306,
			"datetime": 1712151720000
		},
		{
			"open": 518.39,
			"high": 518.45,
			"low": 518.3298,
			"close": 518.39,
			"volume": 83332,
			"datetime": 1712151780000
		},
		{
			"open": 518.38,
			"high": 518.475,
			"low": 518.3,
			"close": 518.315,
			"volume": 72155,
			"datetime": 1712151840000
		},
		{
			"open": 518.31,
			"high": 518.64,
			"low": 518.28,
			"close": 518.51,
			"volume": 144940,
			"datetime": 1712151900000
		},
		{
			"open": 518.51,
			"high": 518.57,
			"low": 518.405,
			"close": 518.4601,
			"volume": 95288,
			"datetime": 1712151960000
		},
		{
			"open": 518.465,
			"high": 518.58,
			"low": 518.39,
			"close": 518.53,
			"volume": 74429,
			"datetime": 1712152020000
		},
		{
			"open": 518.53,
			"high": 518.62,
			"low": 518.41,
			"close": 518.44,
			"volume": 91574,
			"datetime": 1712152080000
		},
		{
			"open": 518.44,
			"high": 518.46,
			"low": 518.24,
			"close": 518.38,
			"volume": 74119,
			"datetime": 1712152140000
		},
		{
			"open": 518.39,
			"high": 518.49,
			"low": 518.36,
			"close": 518.4,
			"volume": 75826,
			"datetime": 1712152200000
		},
		{
			"open": 518.4,
			"high": 518.53,
			"low": 518.38,
			"close": 518.5199,
			"volume": 68180,
			"datetime": 1712152260000
		},
		{
			"open": 518.52,
			"high": 518.535,
			"low": 518.41,
			"close": 518.47,
			"volume": 67064,
			"datetime": 1712152320000
		},
		{
			"open": 518.445,
			"high": 518.54,
			"low": 518.44,
			"close": 518.45,
			"volume": 47896,
			"datetime": 1712152380000
		},
		{
			"open": 518.44,
			"high": 518.58,
			"low": 518.435,
			"close": 518.57,
			"volume": 58546,
			"datetime": 1712152440000
		},
		{
			"open": 518.56,
			"high": 518.61,
			"low": 518.38,
			"close": 518.39,
			"volume": 125609,
			"datetime": 1712152500000
		},
		{
			"open": 518.39,
			"high": 518.41,
			"low": 518.335,
			"close": 518.395,
			"volume": 57247,
			"datetime": 1712152560000
		},
		{
			"open": 518.39,
			"high": 518.44,
			"low": 518.34,
			"close": 518.345,
			"volume": 75443,
			"datetime": 1712152620000
		},
		{
			"open": 518.34,
			"high": 518.365,
			"low": 518.225,
			"close": 518.275,
			"volume": 95792,
			"datetime": 1712152680000
		},
		{
			"open": 518.275,
			"high": 518.415,
			"low": 518.21,
			"close": 518.39,
			"volume": 91489,
			"datetime": 1712152740000
		},
		{
			"open": 518.45,
			"high": 519.53,
			"low": 518.42,
			"close": 519.38,
			"volume": 669738,
			"datetime": 1712152800000
		},
		{
			"open": 519.41,
			"high": 519.72,
			"low": 519.41,
			"close": 519.71,
			"volume": 306643,
			"datetime": 1712152860000
		},
		{
			"open": 519.72,
			"high": 520.14,
			"low": 519.62,
			"close": 520.07,
			"volume": 452222,
			"datetime": 1712152920000
		},
		{
			"open": 520.07,
			"high": 520.155,
			"low": 519.95,
			"close": 520.01,
			"volume": 276652,
			"datetime": 1712152980000
		},
		{
			"open": 520.0101,
			"high": 520.02,
			"low": 519.81,
			"close": 519.97,
			"volume": 200960,
			"datetime": 1712153040000
		},
		{
			"open": 519.98,
			"high": 520.13,
			"low": 519.97,
			"close": 520.085,
			"volume": 170210,
			"datetime": 1712153100000
		},
		{
			"open": 520.08,
			"high": 520.27,
			"low": 520.05,
			"close": 520.2,
			"volume": 278080,
			"datetime": 1712153160000
		},
		{
			"open": 520.22,
			"high": 520.31,
			"low": 520.06,
			"close": 520.13,
			"volume": 170272,
			"datetime": 1712153220000
		},
		{
			"open": 520.13,
			"high": 520.15,
			"low": 519.85,
			"close": 519.88,
			"volume": 210971,
			"datetime": 1712153280000
		},
		{
			"open": 519.89,
			"high": 519.89,
			"low": 519.47,
			"close": 519.54,
			"volume": 260847,
			"datetime": 1712153340000
		},
		{
			"open": 519.55,
			"high": 519.615,
			"low": 519.48,
			"close": 519.5501,
			"volume": 162783,
			"datetime": 1712153400000
		},
		{
			"open": 519.55,
			"high": 519.8,
			"low": 519.48,
			"close": 519.78,
			"volume": 119327,
			"datetime": 1712153460000
		},
		{
			"open": 519.77,
			"high": 519.91,
			"low": 519.67,
			"close": 519.9,
			"volume": 103714,
			"datetime": 1712153520000
		},
		{
			"open": 519.9,
			"high": 520,
			"low": 519.87,
			"close": 519.9801,
			"volume": 110085,
			"datetime": 1712153580000
		},
		{
			"open": 519.99,
			"high": 520.25,
			"low": 519.94,
			"close": 520.225,
			"volume": 118533,
			"datetime": 1712153640000
		},
		{
			"open": 520.23,
			"high": 520.505,
			"low": 520.23,
			"close": 520.39,
			"volume": 262571,
			"datetime": 1712153700000
		},
		{
			"open": 520.3913,
			"high": 520.42,
			"low": 520.11,
			"close": 520.12,
			"volume": 138502,
			"datetime": 1712153760000
		},
		{
			"open": 520.13,
			"high": 520.28,
			"low": 520.0495,
			"close": 520.205,
			"volume": 123083,
			"datetime": 1712153820000
		},
		{
			"open": 520.2,
			"high": 520.368,
			"low": 520.18,
			"close": 520.33,
			"volume": 108146,
			"datetime": 1712153880000
		},
		{
			"open": 520.33,
			"high": 520.4498,
			"low": 520.26,
			"close": 520.43,
			"volume": 91167,
			"datetime": 1712153940000
		},
		{
			"open": 520.43,
			"high": 520.4999,
			"low": 520.34,
			"close": 520.38,
			"volume": 99563,
			"datetime": 1712154000000
		},
		{
			"open": 520.3777,
			"high": 520.4588,
			"low": 520.275,
			"close": 520.38,
			"volume": 129646,
			"datetime": 1712154060000
		},
		{
			"open": 520.38,
			"high": 520.435,
			"low": 520.32,
			"close": 520.37,
			"volume": 92236,
			"datetime": 1712154120000
		},
		{
			"open": 520.355,
			"high": 520.42,
			"low": 520.275,
			"close": 520.4,
			"volume": 90044,
			"datetime": 1712154180000
		},
		{
			"open": 520.4,
			"high": 520.51,
			"low": 520.35,
			"close": 520.375,
			"volume": 150734,
			"datetime": 1712154240000
		},
		{
			"open": 520.37,
			"high": 520.46,
			"low": 520.32,
			"close": 520.4,
			"volume": 88270,
			"datetime": 1712154300000
		},
		{
			"open": 520.405,
			"high": 520.63,
			"low": 520.39,
			"close": 520.6,
			"volume": 116069,
			"datetime": 1712154360000
		},
		{
			"open": 520.59,
			"high": 520.59,
			"low": 520.46,
			"close": 520.52,
			"volume": 86539,
			"datetime": 1712154420000
		},
		{
			"open": 520.52,
			"high": 520.56,
			"low": 520.43,
			"close": 520.515,
			"volume": 183610,
			"datetime": 1712154480000
		},
		{
			"open": 520.525,
			"high": 520.54,
			"low": 520.37,
			"close": 520.4,
			"volume": 91987,
			"datetime": 1712154540000
		},
		{
			"open": 520.41,
			"high": 520.66,
			"low": 520.38,
			"close": 520.5,
			"volume": 106050,
			"datetime": 1712154600000
		},
		{
			"open": 520.505,
			"high": 520.6,
			"low": 520.42,
			"close": 520.5799,
			"volume": 101106,
			"datetime": 1712154660000
		},
		{
			"open": 520.58,
			"high": 520.69,
			"low": 520.5,
			"close": 520.5093,
			"volume": 114556,
			"datetime": 1712154720000
		},
		{
			"open": 520.5,
			"high": 520.54,
			"low": 520.34,
			"close": 520.5113,
			"volume": 110482,
			"datetime": 1712154780000
		},
		{
			"open": 520.52,
			"high": 520.5924,
			"low": 520.43,
			"close": 520.46,
			"volume": 86304,
			"datetime": 1712154840000
		},
		{
			"open": 520.45,
			"high": 520.6,
			"low": 520.44,
			"close": 520.6,
			"volume": 88888,
			"datetime": 1712154900000
		},
		{
			"open": 520.59,
			"high": 520.62,
			"low": 520.535,
			"close": 520.55,
			"volume": 65880,
			"datetime": 1712154960000
		},
		{
			"open": 520.55,
			"high": 520.555,
			"low": 520.36,
			"close": 520.41,
			"volume": 77260,
			"datetime": 1712155020000
		},
		{
			"open": 520.42,
			"high": 520.44,
			"low": 520.28,
			"close": 520.33,
			"volume": 140800,
			"datetime": 1712155080000
		},
		{
			"open": 520.34,
			"high": 520.405,
			"low": 520.3,
			"close": 520.39,
			"volume": 106682,
			"datetime": 1712155140000
		},
		{
			"open": 520.38,
			"high": 520.42,
			"low": 520.26,
			"close": 520.37,
			"volume": 73272,
			"datetime": 1712155200000
		},
		{
			"open": 520.38,
			"high": 520.4901,
			"low": 520.285,
			"close": 520.295,
			"volume": 68021,
			"datetime": 1712155260000
		},
		{
			"open": 520.29,
			"high": 520.38,
			"low": 520.1701,
			"close": 520.345,
			"volume": 80543,
			"datetime": 1712155320000
		},
		{
			"open": 520.34,
			"high": 520.51,
			"low": 520.33,
			"close": 520.48,
			"volume": 54662,
			"datetime": 1712155380000
		},
		{
			"open": 520.47,
			"high": 520.505,
			"low": 520.44,
			"close": 520.4801,
			"volume": 51080,
			"datetime": 1712155440000
		},
		{
			"open": 520.485,
			"high": 520.52,
			"low": 520.44,
			"close": 520.485,
			"volume": 55284,
			"datetime": 1712155500000
		},
		{
			"open": 520.48,
			"high": 520.585,
			"low": 520.385,
			"close": 520.4351,
			"volume": 87688,
			"datetime": 1712155560000
		},
		{
			"open": 520.42,
			"high": 520.57,
			"low": 520.4,
			"close": 520.49,
			"volume": 60003,
			"datetime": 1712155620000
		},
		{
			"open": 520.505,
			"high": 520.615,
			"low": 520.5,
			"close": 520.61,
			"volume": 69175,
			"datetime": 1712155680000
		},
		{
			"open": 520.61,
			"high": 520.614,
			"low": 520.46,
			"close": 520.525,
			"volume": 88460,
			"datetime": 1712155740000
		},
		{
			"open": 520.52,
			"high": 520.685,
			"low": 520.515,
			"close": 520.635,
			"volume": 78007,
			"datetime": 1712155800000
		},
		{
			"open": 520.64,
			"high": 520.68,
			"low": 520.57,
			"close": 520.641,
			"volume": 75629,
			"datetime": 1712155860000
		},
		{
			"open": 520.65,
			"high": 520.74,
			"low": 520.64,
			"close": 520.6613,
			"volume": 71521,
			"datetime": 1712155920000
		},
		{
			"open": 520.66,
			"high": 520.75,
			"low": 520.61,
			"close": 520.7303,
			"volume": 63494,
			"datetime": 1712155980000
		},
		{
			"open": 520.7301,
			"high": 520.84,
			"low": 520.63,
			"close": 520.664,
			"volume": 101528,
			"datetime": 1712156040000
		},
		{
			"open": 520.66,
			"high": 520.785,
			"low": 520.66,
			"close": 520.7408,
			"volume": 49455,
			"datetime": 1712156100000
		},
		{
			"open": 520.735,
			"high": 520.81,
			"low": 520.69,
			"close": 520.76,
			"volume": 88100,
			"datetime": 1712156160000
		},
		{
			"open": 520.76,
			"high": 520.79,
			"low": 520.54,
			"close": 520.55,
			"volume": 112277,
			"datetime": 1712156220000
		},
		{
			"open": 520.565,
			"high": 520.73,
			"low": 520.56,
			"close": 520.721,
			"volume": 72390,
			"datetime": 1712156280000
		},
		{
			"open": 520.73,
			"high": 520.75,
			"low": 520.66,
			"close": 520.68,
			"volume": 38739,
			"datetime": 1712156340000
		},
		{
			"open": 520.67,
			"high": 520.8,
			"low": 520.67,
			"close": 520.77,
			"volume": 114955,
			"datetime": 1712156400000
		},
		{
			"open": 520.78,
			"high": 520.78,
			"low": 520.6,
			"close": 520.6619,
			"volume": 70720,
			"datetime": 1712156460000
		},
		{
			"open": 520.659,
			"high": 520.734,
			"low": 520.6182,
			"close": 520.734,
			"volume": 42059,
			"datetime": 1712156520000
		},
		{
			"open": 520.738,
			"high": 520.76,
			"low": 520.665,
			"close": 520.74,
			"volume": 38617,
			"datetime": 1712156580000
		},
		{
			"open": 520.735,
			"high": 520.81,
			"low": 520.7,
			"close": 520.77,
			"volume": 49368,
			"datetime": 1712156640000
		},
		{
			"open": 520.76,
			"high": 520.81,
			"low": 520.75,
			"close": 520.805,
			"volume": 48045,
			"datetime": 1712156700000
		},
		{
			"open": 520.82,
			"high": 520.88,
			"low": 520.755,
			"close": 520.8299,
			"volume": 74857,
			"datetime": 1712156760000
		},
		{
			"open": 520.82,
			"high": 520.835,
			"low": 520.745,
			"close": 520.75,
			"volume": 51293,
			"datetime": 1712156820000
		},
		{
			"open": 520.75,
			"high": 520.8597,
			"low": 520.725,
			"close": 520.8,
			"volume": 41541,
			"datetime": 1712156880000
		},
		{
			"open": 520.8,
			"high": 520.87,
			"low": 520.79,
			"close": 520.84,
			"volume": 53091,
			"datetime": 1712156940000
		},
		{
			"open": 520.84,
			"high": 520.9,
			"low": 520.8,
			"close": 520.87,
			"volume": 56197,
			"datetime": 1712157000000
		},
		{
			"open": 520.87,
			"high": 520.95,
			"low": 520.87,
			"close": 520.93,
			"volume": 107340,
			"datetime": 1712157060000
		},
		{
			"open": 520.92,
			"high": 520.94,
			"low": 520.8301,
			"close": 520.8345,
			"volume": 71254,
			"datetime": 1712157120000
		},
		{
			"open": 520.84,
			"high": 520.9,
			"low": 520.82,
			"close": 520.9,
			"volume": 57429,
			"datetime": 1712157180000
		},
		{
			"open": 520.8913,
			"high": 520.9001,
			"low": 520.83,
			"close": 520.87,
			"volume": 38311,
			"datetime": 1712157240000
		},
		{
			"open": 520.8683,
			"high": 520.91,
			"low": 520.82,
			"close": 520.8304,
			"volume": 59770,
			"datetime": 1712157300000
		},
		{
			"open": 520.83,
			"high": 520.83,
			"low": 520.75,
			"close": 520.75,
			"volume": 71726,
			"datetime": 1712157360000
		},
		{
			"open": 520.768,
			"high": 520.768,
			"low": 520.64,
			"close": 520.65,
			"volume": 99513,
			"datetime": 1712157420000
		},
		{
			"open": 520.65,
			"high": 520.75,
			"low": 520.65,
			"close": 520.74,
			"volume": 67516,
			"datetime": 1712157480000
		},
		{
			"open": 520.7404,
			"high": 520.825,
			"low": 520.7404,
			"close": 520.8,
			"volume": 51324,
			"datetime": 1712157540000
		},
		{
			"open": 520.8,
			"high": 520.8568,
			"low": 520.7799,
			"close": 520.815,
			"volume": 46404,
			"datetime": 1712157600000
		},
		{
			"open": 520.825,
			"high": 520.905,
			"low": 520.825,
			"close": 520.895,
			"volume": 42956,
			"datetime": 1712157660000
		},
		{
			"open": 520.9,
			"high": 520.94,
			"low": 520.815,
			"close": 520.869,
			"volume": 63357,
			"datetime": 1712157720000
		},
		{
			"open": 520.87,
			"high": 520.9,
			"low": 520.85,
			"close": 520.86,
			"volume": 52680,
			"datetime": 1712157780000
		},
		{
			"open": 520.87,
			"high": 520.9,
			"low": 520.8299,
			"close": 520.9,
			"volume": 51424,
			"datetime": 1712157840000
		},
		{
			"open": 520.8922,
			"high": 520.9,
			"low": 520.83,
			"close": 520.855,
			"volume": 29972,
			"datetime": 1712157900000
		},
		{
			"open": 520.85,
			"high": 520.89,
			"low": 520.82,
			"close": 520.865,
			"volume": 39449,
			"datetime": 1712157960000
		},
		{
			"open": 520.86,
			"high": 520.91,
			"low": 520.81,
			"close": 520.82,
			"volume": 45337,
			"datetime": 1712158020000
		},
		{
			"open": 520.8401,
			"high": 520.87,
			"low": 520.83,
			"close": 520.86,
			"volume": 29269,
			"datetime": 1712158080000
		},
		{
			"open": 520.86,
			"high": 520.8685,
			"low": 520.805,
			"close": 520.81,
			"volume": 29823,
			"datetime": 1712158140000
		},
		{
			"open": 520.83,
			"high": 520.925,
			"low": 520.82,
			"close": 520.9,
			"volume": 68032,
			"datetime": 1712158200000
		},
		{
			"open": 520.89,
			"high": 520.93,
			"low": 520.8,
			"close": 520.85,
			"volume": 57468,
			"datetime": 1712158260000
		},
		{
			"open": 520.855,
			"high": 520.875,
			"low": 520.8,
			"close": 520.8,
			"volume": 41296,
			"datetime": 1712158320000
		},
		{
			"open": 520.805,
			"high": 520.835,
			"low": 520.77,
			"close": 520.79,
			"volume": 52141,
			"datetime": 1712158380000
		},
		{
			"open": 520.8,
			"high": 520.8001,
			"low": 520.69,
			"close": 520.74,
			"volume": 64232,
			"datetime": 1712158440000
		},
		{
			"open": 520.7501,
			"high": 520.795,
			"low": 520.73,
			"close": 520.785,
			"volume": 80739,
			"datetime": 1712158500000
		},
		{
			"open": 520.79,
			"high": 520.9,
			"low": 520.78,
			"close": 520.89,
			"volume": 77296,
			"datetime": 1712158560000
		},
		{
			"open": 520.89,
			"high": 520.89,
			"low": 520.81,
			"close": 520.83,
			"volume": 33463,
			"datetime": 1712158620000
		},
		{
			"open": 520.83,
			"high": 520.8599,
			"low": 520.73,
			"close": 520.75,
			"volume": 35720,
			"datetime": 1712158680000
		},
		{
			"open": 520.75,
			"high": 520.8,
			"low": 520.74,
			"close": 520.755,
			"volume": 38381,
			"datetime": 1712158740000
		},
		{
			"open": 520.75,
			"high": 520.82,
			"low": 520.74,
			"close": 520.78,
			"volume": 38010,
			"datetime": 1712158800000
		},
		{
			"open": 520.78,
			"high": 520.795,
			"low": 520.7499,
			"close": 520.765,
			"volume": 37823,
			"datetime": 1712158860000
		},
		{
			"open": 520.76,
			"high": 520.92,
			"low": 520.76,
			"close": 520.9,
			"volume": 53478,
			"datetime": 1712158920000
		},
		{
			"open": 520.9,
			"high": 520.92,
			"low": 520.85,
			"close": 520.86,
			"volume": 36909,
			"datetime": 1712158980000
		},
		{
			"open": 520.86,
			"high": 520.87,
			"low": 520.74,
			"close": 520.76,
			"volume": 45826,
			"datetime": 1712159040000
		},
		{
			"open": 520.75,
			"high": 520.7601,
			"low": 520.65,
			"close": 520.66,
			"volume": 60713,
			"datetime": 1712159100000
		},
		{
			"open": 520.6599,
			"high": 520.7401,
			"low": 520.645,
			"close": 520.7401,
			"volume": 38605,
			"datetime": 1712159160000
		},
		{
			"open": 520.745,
			"high": 520.745,
			"low": 520.58,
			"close": 520.62,
			"volume": 67833,
			"datetime": 1712159220000
		},
		{
			"open": 520.62,
			"high": 520.68,
			"low": 520.6,
			"close": 520.62,
			"volume": 36000,
			"datetime": 1712159280000
		},
		{
			"open": 520.62,
			"high": 520.68,
			"low": 520.56,
			"close": 520.665,
			"volume": 60978,
			"datetime": 1712159340000
		},
		{
			"open": 520.6624,
			"high": 520.68,
			"low": 520.46,
			"close": 520.485,
			"volume": 109971,
			"datetime": 1712159400000
		},
		{
			"open": 520.48,
			"high": 520.52,
			"low": 520.33,
			"close": 520.3499,
			"volume": 120485,
			"datetime": 1712159460000
		},
		{
			"open": 520.35,
			"high": 520.46,
			"low": 520.345,
			"close": 520.3594,
			"volume": 95780,
			"datetime": 1712159520000
		},
		{
			"open": 520.345,
			"high": 520.405,
			"low": 520.31,
			"close": 520.37,
			"volume": 103179,
			"datetime": 1712159580000
		},
		{
			"open": 520.375,
			"high": 520.379,
			"low": 520.2296,
			"close": 520.305,
			"volume": 91157,
			"datetime": 1712159640000
		},
		{
			"open": 520.29,
			"high": 520.34,
			"low": 520.11,
			"close": 520.13,
			"volume": 99163,
			"datetime": 1712159700000
		},
		{
			"open": 520.1298,
			"high": 520.2318,
			"low": 520.1,
			"close": 520.22,
			"volume": 101854,
			"datetime": 1712159760000
		},
		{
			"open": 520.22,
			"high": 520.3,
			"low": 520.19,
			"close": 520.24,
			"volume": 60270,
			"datetime": 1712159820000
		},
		{
			"open": 520.24,
			"high": 520.27,
			"low": 520.17,
			"close": 520.2,
			"volume": 79142,
			"datetime": 1712159880000
		},
		{
			"open": 520.21,
			"high": 520.26,
			"low": 520.1806,
			"close": 520.1901,
			"volume": 57683,
			"datetime": 1712159940000
		},
		{
			"open": 520.18,
			"high": 520.21,
			"low": 519.97,
			"close": 520.01,
			"volume": 155067,
			"datetime": 1712160000000
		},
		{
			"open": 520.01,
			"high": 520.06,
			"low": 519.86,
			"close": 520.04,
			"volume": 190850,
			"datetime": 1712160060000
		},
		{
			"open": 520.05,
			"high": 520.05,
			"low": 519.925,
			"close": 519.995,
			"volume": 88727,
			"datetime": 1712160120000
		},
		{
			"open": 520,
			"high": 520.06,
			"low": 519.845,
			"close": 519.89,
			"volume": 106314,
			"datetime": 1712160180000
		},
		{
			"open": 519.89,
			"high": 519.96,
			"low": 519.8697,
			"close": 519.9219,
			"volume": 128132,
			"datetime": 1712160240000
		},
		{
			"open": 519.9296,
			"high": 519.99,
			"low": 519.9,
			"close": 519.95,
			"volume": 92794,
			"datetime": 1712160300000
		},
		{
			"open": 519.95,
			"high": 520.04,
			"low": 519.9,
			"close": 519.975,
			"volume": 103019,
			"datetime": 1712160360000
		},
		{
			"open": 519.97,
			"high": 520.055,
			"low": 519.93,
			"close": 520.01,
			"volume": 123665,
			"datetime": 1712160420000
		},
		{
			"open": 520.01,
			"high": 520.01,
			"low": 519.9,
			"close": 519.9002,
			"volume": 70706,
			"datetime": 1712160480000
		},
		{
			"open": 519.9,
			"high": 520.1002,
			"low": 519.87,
			"close": 520.08,
			"volume": 83447,
			"datetime": 1712160540000
		},
		{
			"open": 520.08,
			"high": 520.3,
			"low": 519.72,
			"close": 519.97,
			"volume": 346371,
			"datetime": 1712160600000
		},
		{
			"open": 519.97,
			"high": 520.13,
			"low": 519.93,
			"close": 520.01,
			"volume": 121753,
			"datetime": 1712160660000
		},
		{
			"open": 520.01,
			"high": 520.01,
			"low": 519.7533,
			"close": 519.93,
			"volume": 122482,
			"datetime": 1712160720000
		},
		{
			"open": 519.9381,
			"high": 520.09,
			"low": 519.87,
			"close": 519.89,
			"volume": 101662,
			"datetime": 1712160780000
		},
		{
			"open": 519.89,
			"high": 520.1,
			"low": 519.89,
			"close": 520.1,
			"volume": 60568,
			"datetime": 1712160840000
		},
		{
			"open": 520.11,
			"high": 520.11,
			"low": 519.8821,
			"close": 519.93,
			"volume": 80685,
			"datetime": 1712160900000
		},
		{
			"open": 519.93,
			"high": 520,
			"low": 519.89,
			"close": 519.9957,
			"volume": 78295,
			"datetime": 1712160960000
		},
		{
			"open": 519.998,
			"high": 520.1,
			"low": 519.99,
			"close": 520.055,
			"volume": 148346,
			"datetime": 1712161020000
		},
		{
			"open": 520.05,
			"high": 520.17,
			"low": 520.05,
			"close": 520.12,
			"volume": 100415,
			"datetime": 1712161080000
		},
		{
			"open": 520.13,
			"high": 520.37,
			"low": 520.13,
			"close": 520.3599,
			"volume": 108276,
			"datetime": 1712161140000
		},
		{
			"open": 520.355,
			"high": 520.46,
			"low": 520.33,
			"close": 520.41,
			"volume": 104690,
			"datetime": 1712161200000
		},
		{
			"open": 520.42,
			"high": 520.435,
			"low": 520.31,
			"close": 520.375,
			"volume": 46709,
			"datetime": 1712161260000
		},
		{
			"open": 520.385,
			"high": 520.46,
			"low": 520.34,
			"close": 520.43,
			"volume": 62696,
			"datetime": 1712161320000
		},
		{
			"open": 520.43,
			"high": 520.52,
			"low": 520.31,
			"close": 520.51,
			"volume": 97296,
			"datetime": 1712161380000
		},
		{
			"open": 520.5,
			"high": 520.62,
			"low": 520.49,
			"close": 520.56,
			"volume": 91333,
			"datetime": 1712161440000
		},
		{
			"open": 520.55,
			"high": 520.58,
			"low": 520.38,
			"close": 520.42,
			"volume": 82948,
			"datetime": 1712161500000
		},
		{
			"open": 520.425,
			"high": 520.459,
			"low": 520.345,
			"close": 520.4105,
			"volume": 58166,
			"datetime": 1712161560000
		},
		{
			"open": 520.41,
			"high": 520.55,
			"low": 520.385,
			"close": 520.55,
			"volume": 79943,
			"datetime": 1712161620000
		},
		{
			"open": 520.55,
			"high": 520.59,
			"low": 520.52,
			"close": 520.565,
			"volume": 75256,
			"datetime": 1712161680000
		},
		{
			"open": 520.56,
			"high": 520.65,
			"low": 520.52,
			"close": 520.645,
			"volume": 114524,
			"datetime": 1712161740000
		},
		{
			"open": 520.64,
			"high": 520.68,
			"low": 520.57,
			"close": 520.58,
			"volume": 85023,
			"datetime": 1712161800000
		},
		{
			"open": 520.58,
			"high": 520.78,
			"low": 520.57,
			"close": 520.69,
			"volume": 118519,
			"datetime": 1712161860000
		},
		{
			"open": 520.6902,
			"high": 520.8,
			"low": 520.67,
			"close": 520.79,
			"volume": 64078,
			"datetime": 1712161920000
		},
		{
			"open": 520.79,
			"high": 520.845,
			"low": 520.75,
			"close": 520.78,
			"volume": 83173,
			"datetime": 1712161980000
		},
		{
			"open": 520.77,
			"high": 520.785,
			"low": 520.68,
			"close": 520.705,
			"volume": 48826,
			"datetime": 1712162040000
		},
		{
			"open": 520.7099,
			"high": 520.75,
			"low": 520.64,
			"close": 520.69,
			"volume": 77233,
			"datetime": 1712162100000
		},
		{
			"open": 520.69,
			"high": 520.72,
			"low": 520.54,
			"close": 520.56,
			"volume": 114662,
			"datetime": 1712162160000
		},
		{
			"open": 520.56,
			"high": 520.58,
			"low": 520.14,
			"close": 520.1601,
			"volume": 210560,
			"datetime": 1712162220000
		},
		{
			"open": 520.16,
			"high": 520.17,
			"low": 519.39,
			"close": 519.47,
			"volume": 402701,
			"datetime": 1712162280000
		},
		{
			"open": 519.49,
			"high": 519.82,
			"low": 519.41,
			"close": 519.5,
			"volume": 171987,
			"datetime": 1712162340000
		},
		{
			"open": 519.51,
			"high": 520.175,
			"low": 519.51,
			"close": 520.125,
			"volume": 165899,
			"datetime": 1712162400000
		},
		{
			"open": 520.13,
			"high": 520.435,
			"low": 520.13,
			"close": 520.22,
			"volume": 112374,
			"datetime": 1712162460000
		},
		{
			"open": 520.26,
			"high": 520.38,
			"low": 520.12,
			"close": 520.36,
			"volume": 126364,
			"datetime": 1712162520000
		},
		{
			"open": 520.36,
			"high": 520.37,
			"low": 520.24,
			"close": 520.3,
			"volume": 72871,
			"datetime": 1712162580000
		},
		{
			"open": 520.3,
			"high": 520.37,
			"low": 520.22,
			"close": 520.3052,
			"volume": 78327,
			"datetime": 1712162640000
		},
		{
			"open": 520.3,
			"high": 520.43,
			"low": 520.23,
			"close": 520.375,
			"volume": 79015,
			"datetime": 1712162700000
		},
		{
			"open": 520.38,
			"high": 520.3876,
			"low": 520.14,
			"close": 520.19,
			"volume": 62456,
			"datetime": 1712162760000
		},
		{
			"open": 520.21,
			"high": 520.3813,
			"low": 520.21,
			"close": 520.31,
			"volume": 39746,
			"datetime": 1712162820000
		},
		{
			"open": 520.32,
			"high": 520.35,
			"low": 520.26,
			"close": 520.32,
			"volume": 28535,
			"datetime": 1712162880000
		},
		{
			"open": 520.32,
			"high": 520.409,
			"low": 520.22,
			"close": 520.3017,
			"volume": 48516,
			"datetime": 1712162940000
		},
		{
			"open": 520.3,
			"high": 520.4298,
			"low": 520.25,
			"close": 520.42,
			"volume": 40568,
			"datetime": 1712163000000
		},
		{
			"open": 520.437,
			"high": 520.5283,
			"low": 520.36,
			"close": 520.43,
			"volume": 75887,
			"datetime": 1712163060000
		},
		{
			"open": 520.44,
			"high": 520.52,
			"low": 520.42,
			"close": 520.47,
			"volume": 64182,
			"datetime": 1712163120000
		},
		{
			"open": 520.48,
			"high": 520.7087,
			"low": 520.48,
			"close": 520.56,
			"volume": 111535,
			"datetime": 1712163180000
		},
		{
			"open": 520.56,
			"high": 520.5903,
			"low": 520.43,
			"close": 520.54,
			"volume": 110106,
			"datetime": 1712163240000
		},
		{
			"open": 520.53,
			"high": 520.62,
			"low": 520.5,
			"close": 520.6,
			"volume": 52034,
			"datetime": 1712163300000
		},
		{
			"open": 520.59,
			"high": 520.595,
			"low": 520.475,
			"close": 520.57,
			"volume": 33514,
			"datetime": 1712163360000
		},
		{
			"open": 520.56,
			"high": 520.6024,
			"low": 520.44,
			"close": 520.525,
			"volume": 68472,
			"datetime": 1712163420000
		},
		{
			"open": 520.54,
			"high": 520.57,
			"low": 520.466,
			"close": 520.47,
			"volume": 43218,
			"datetime": 1712163480000
		},
		{
			"open": 520.466,
			"high": 520.53,
			"low": 520.31,
			"close": 520.31,
			"volume": 52749,
			"datetime": 1712163540000
		},
		{
			"open": 520.32,
			"high": 520.585,
			"low": 520.32,
			"close": 520.56,
			"volume": 67001,
			"datetime": 1712163600000
		},
		{
			"open": 520.5275,
			"high": 520.58,
			"low": 520.415,
			"close": 520.46,
			"volume": 54143,
			"datetime": 1712163660000
		},
		{
			"open": 520.46,
			"high": 520.51,
			"low": 520.32,
			"close": 520.35,
			"volume": 38916,
			"datetime": 1712163720000
		},
		{
			"open": 520.37,
			"high": 520.49,
			"low": 520.3,
			"close": 520.475,
			"volume": 55961,
			"datetime": 1712163780000
		},
		{
			"open": 520.48,
			"high": 520.515,
			"low": 520.41,
			"close": 520.43,
			"volume": 38113,
			"datetime": 1712163840000
		},
		{
			"open": 520.42,
			"high": 520.46,
			"low": 520.305,
			"close": 520.35,
			"volume": 56092,
			"datetime": 1712163900000
		},
		{
			"open": 520.36,
			"high": 520.5,
			"low": 520.3,
			"close": 520.5,
			"volume": 54718,
			"datetime": 1712163960000
		},
		{
			"open": 520.49,
			"high": 520.49,
			"low": 520.36,
			"close": 520.37,
			"volume": 47643,
			"datetime": 1712164020000
		},
		{
			"open": 520.39,
			"high": 520.45,
			"low": 520.36,
			"close": 520.4,
			"volume": 21383,
			"datetime": 1712164080000
		},
		{
			"open": 520.405,
			"high": 520.55,
			"low": 520.4,
			"close": 520.48,
			"volume": 37166,
			"datetime": 1712164140000
		},
		{
			"open": 520.49,
			"high": 520.61,
			"low": 520.49,
			"close": 520.575,
			"volume": 49619,
			"datetime": 1712164200000
		},
		{
			"open": 520.575,
			"high": 520.6,
			"low": 520.535,
			"close": 520.57,
			"volume": 75906,
			"datetime": 1712164260000
		},
		{
			"open": 520.57,
			"high": 520.575,
			"low": 520.45,
			"close": 520.48,
			"volume": 45512,
			"datetime": 1712164320000
		},
		{
			"open": 520.48,
			"high": 520.56,
			"low": 520.48,
			"close": 520.495,
			"volume": 27577,
			"datetime": 1712164380000
		},
		{
			"open": 520.49,
			"high": 520.55,
			"low": 520.4799,
			"close": 520.49,
			"volume": 41388,
			"datetime": 1712164440000
		},
		{
			"open": 520.5,
			"high": 520.53,
			"low": 520.42,
			"close": 520.4501,
			"volume": 38468,
			"datetime": 1712164500000
		},
		{
			"open": 520.4593,
			"high": 520.5231,
			"low": 520.38,
			"close": 520.5,
			"volume": 52524,
			"datetime": 1712164560000
		},
		{
			"open": 520.49,
			"high": 520.55,
			"low": 520.395,
			"close": 520.43,
			"volume": 34860,
			"datetime": 1712164620000
		},
		{
			"open": 520.41,
			"high": 520.42,
			"low": 520.32,
			"close": 520.3899,
			"volume": 61413,
			"datetime": 1712164680000
		},
		{
			"open": 520.4,
			"high": 520.52,
			"low": 520.4,
			"close": 520.505,
			"volume": 42721,
			"datetime": 1712164740000
		},
		{
			"open": 520.5,
			"high": 520.52,
			"low": 520.39,
			"close": 520.39,
			"volume": 37002,
			"datetime": 1712164800000
		},
		{
			"open": 520.4,
			"high": 520.56,
			"low": 520.37,
			"close": 520.52,
			"volume": 41039,
			"datetime": 1712164860000
		},
		{
			"open": 520.515,
			"high": 520.58,
			"low": 520.48,
			"close": 520.56,
			"volume": 69109,
			"datetime": 1712164920000
		},
		{
			"open": 520.56,
			"high": 520.66,
			"low": 520.5451,
			"close": 520.6,
			"volume": 65536,
			"datetime": 1712164980000
		},
		{
			"open": 520.59,
			"high": 520.61,
			"low": 520.47,
			"close": 520.52,
			"volume": 54808,
			"datetime": 1712165040000
		},
		{
			"open": 520.53,
			"high": 520.67,
			"low": 520.53,
			"close": 520.64,
			"volume": 50403,
			"datetime": 1712165100000
		},
		{
			"open": 520.6302,
			"high": 520.655,
			"low": 520.575,
			"close": 520.63,
			"volume": 62409,
			"datetime": 1712165160000
		},
		{
			"open": 520.62,
			"high": 520.67,
			"low": 520.58,
			"close": 520.61,
			"volume": 52837,
			"datetime": 1712165220000
		},
		{
			"open": 520.61,
			"high": 520.68,
			"low": 520.6,
			"close": 520.61,
			"volume": 78519,
			"datetime": 1712165280000
		},
		{
			"open": 520.61,
			"high": 520.63,
			"low": 520.57,
			"close": 520.62,
			"volume": 25774,
			"datetime": 1712165340000
		},
		{
			"open": 520.63,
			"high": 520.7,
			"low": 520.625,
			"close": 520.6563,
			"volume": 49547,
			"datetime": 1712165400000
		},
		{
			"open": 520.65,
			"high": 520.655,
			"low": 520.54,
			"close": 520.55,
			"volume": 86557,
			"datetime": 1712165460000
		},
		{
			"open": 520.5502,
			"high": 520.6,
			"low": 520.42,
			"close": 520.56,
			"volume": 50944,
			"datetime": 1712165520000
		},
		{
			"open": 520.55,
			"high": 520.63,
			"low": 520.5109,
			"close": 520.59,
			"volume": 28682,
			"datetime": 1712165580000
		},
		{
			"open": 520.59,
			"high": 520.63,
			"low": 520.5602,
			"close": 520.599,
			"volume": 20838,
			"datetime": 1712165640000
		},
		{
			"open": 520.59,
			"high": 520.59,
			"low": 520.48,
			"close": 520.56,
			"volume": 30617,
			"datetime": 1712165700000
		},
		{
			"open": 520.5397,
			"high": 520.585,
			"low": 520.52,
			"close": 520.57,
			"volume": 42130,
			"datetime": 1712165760000
		},
		{
			"open": 520.57,
			"high": 520.62,
			"low": 520.525,
			"close": 520.57,
			"volume": 21346,
			"datetime": 1712165820000
		},
		{
			"open": 520.575,
			"high": 520.575,
			"low": 520.405,
			"close": 520.465,
			"volume": 44306,
			"datetime": 1712165880000
		},
		{
			"open": 520.47,
			"high": 520.485,
			"low": 520.39,
			"close": 520.41,
			"volume": 30468,
			"datetime": 1712165940000
		},
		{
			"open": 520.41,
			"high": 520.5475,
			"low": 520.41,
			"close": 520.5417,
			"volume": 35708,
			"datetime": 1712166000000
		},
		{
			"open": 520.55,
			"high": 520.635,
			"low": 520.52,
			"close": 520.62,
			"volume": 41530,
			"datetime": 1712166060000
		},
		{
			"open": 520.63,
			"high": 520.645,
			"low": 520.575,
			"close": 520.595,
			"volume": 52381,
			"datetime": 1712166120000
		},
		{
			"open": 520.6,
			"high": 520.635,
			"low": 520.5387,
			"close": 520.56,
			"volume": 35099,
			"datetime": 1712166180000
		},
		{
			"open": 520.57,
			"high": 520.61,
			"low": 520.525,
			"close": 520.525,
			"volume": 27305,
			"datetime": 1712166240000
		},
		{
			"open": 520.54,
			"high": 520.56,
			"low": 520.48,
			"close": 520.54,
			"volume": 55479,
			"datetime": 1712166300000
		},
		{
			"open": 520.54,
			"high": 520.63,
			"low": 520.54,
			"close": 520.59,
			"volume": 40141,
			"datetime": 1712166360000
		},
		{
			"open": 520.6,
			"high": 520.66,
			"low": 520.56,
			"close": 520.62,
			"volume": 38250,
			"datetime": 1712166420000
		},
		{
			"open": 520.63,
			"high": 520.725,
			"low": 520.63,
			"close": 520.69,
			"volume": 46487,
			"datetime": 1712166480000
		},
		{
			"open": 520.68,
			"high": 520.705,
			"low": 520.65,
			"close": 520.66,
			"volume": 22009,
			"datetime": 1712166540000
		},
		{
			"open": 520.66,
			"high": 520.71,
			"low": 520.64,
			"close": 520.645,
			"volume": 35995,
			"datetime": 1712166600000
		},
		{
			"open": 520.645,
			"high": 520.76,
			"low": 520.64,
			"close": 520.73,
			"volume": 42777,
			"datetime": 1712166660000
		},
		{
			"open": 520.72,
			"high": 520.8,
			"low": 520.64,
			"close": 520.66,
			"volume": 64923,
			"datetime": 1712166720000
		},
		{
			"open": 520.66,
			"high": 520.715,
			"low": 520.63,
			"close": 520.675,
			"volume": 35318,
			"datetime": 1712166780000
		},
		{
			"open": 520.675,
			"high": 520.69,
			"low": 520.6201,
			"close": 520.67,
			"volume": 32103,
			"datetime": 1712166840000
		},
		{
			"open": 520.66,
			"high": 520.69,
			"low": 520.63,
			"close": 520.64,
			"volume": 31769,
			"datetime": 1712166900000
		},
		{
			"open": 520.63,
			"high": 520.6699,
			"low": 520.61,
			"close": 520.6385,
			"volume": 27580,
			"datetime": 1712166960000
		},
		{
			"open": 520.6396,
			"high": 520.645,
			"low": 520.51,
			"close": 520.54,
			"volume": 44460,
			"datetime": 1712167020000
		},
		{
			"open": 520.5493,
			"high": 520.55,
			"low": 520.46,
			"close": 520.49,
			"volume": 41184,
			"datetime": 1712167080000
		},
		{
			"open": 520.4992,
			"high": 520.582,
			"low": 520.485,
			"close": 520.55,
			"volume": 37918,
			"datetime": 1712167140000
		},
		{
			"open": 520.56,
			"high": 520.565,
			"low": 520.445,
			"close": 520.49,
			"volume": 69748,
			"datetime": 1712167200000
		},
		{
			"open": 520.495,
			"high": 520.545,
			"low": 520.49,
			"close": 520.51,
			"volume": 37671,
			"datetime": 1712167260000
		},
		{
			"open": 520.505,
			"high": 520.57,
			"low": 520.455,
			"close": 520.565,
			"volume": 47154,
			"datetime": 1712167320000
		},
		{
			"open": 520.56,
			"high": 520.6,
			"low": 520.51,
			"close": 520.5917,
			"volume": 28797,
			"datetime": 1712167380000
		},
		{
			"open": 520.595,
			"high": 520.595,
			"low": 520.54,
			"close": 520.545,
			"volume": 37762,
			"datetime": 1712167440000
		},
		{
			"open": 520.55,
			"high": 520.5771,
			"low": 520.51,
			"close": 520.565,
			"volume": 33151,
			"datetime": 1712167500000
		},
		{
			"open": 520.57,
			"high": 520.685,
			"low": 520.56,
			"close": 520.67,
			"volume": 60481,
			"datetime": 1712167560000
		},
		{
			"open": 520.66,
			"high": 520.715,
			"low": 520.63,
			"close": 520.71,
			"volume": 36145,
			"datetime": 1712167620000
		},
		{
			"open": 520.715,
			"high": 520.8,
			"low": 520.695,
			"close": 520.77,
			"volume": 152460,
			"datetime": 1712167680000
		},
		{
			"open": 520.76,
			"high": 520.77,
			"low": 520.69,
			"close": 520.69,
			"volume": 66214,
			"datetime": 1712167740000
		},
		{
			"open": 520.7,
			"high": 520.75,
			"low": 520.69,
			"close": 520.725,
			"volume": 50375,
			"datetime": 1712167800000
		},
		{
			"open": 520.72,
			"high": 520.79,
			"low": 520.68,
			"close": 520.69,
			"volume": 46823,
			"datetime": 1712167860000
		},
		{
			"open": 520.7,
			"high": 520.74,
			"low": 520.6601,
			"close": 520.6601,
			"volume": 45653,
			"datetime": 1712167920000
		},
		{
			"open": 520.67,
			"high": 520.675,
			"low": 520.62,
			"close": 520.64,
			"volume": 54903,
			"datetime": 1712167980000
		},
		{
			"open": 520.64,
			"high": 520.64,
			"low": 520.53,
			"close": 520.55,
			"volume": 76353,
			"datetime": 1712168040000
		},
		{
			"open": 520.55,
			"high": 520.625,
			"low": 520.55,
			"close": 520.58,
			"volume": 58207,
			"datetime": 1712168100000
		},
		{
			"open": 520.5724,
			"high": 520.5724,
			"low": 520.44,
			"close": 520.445,
			"volume": 96569,
			"datetime": 1712168160000
		},
		{
			"open": 520.445,
			"high": 520.47,
			"low": 520.3,
			"close": 520.3399,
			"volume": 92937,
			"datetime": 1712168220000
		},
		{
			"open": 520.34,
			"high": 520.36,
			"low": 520.25,
			"close": 520.35,
			"volume": 62480,
			"datetime": 1712168280000
		},
		{
			"open": 520.3476,
			"high": 520.48,
			"low": 520.33,
			"close": 520.45,
			"volume": 51749,
			"datetime": 1712168340000
		},
		{
			"open": 520.45,
			"high": 520.51,
			"low": 520.45,
			"close": 520.495,
			"volume": 46639,
			"datetime": 1712168400000
		},
		{
			"open": 520.49,
			"high": 520.5099,
			"low": 520.44,
			"close": 520.44,
			"volume": 52957,
			"datetime": 1712168460000
		},
		{
			"open": 520.44,
			"high": 520.4484,
			"low": 520.35,
			"close": 520.37,
			"volume": 43452,
			"datetime": 1712168520000
		},
		{
			"open": 520.3681,
			"high": 520.39,
			"low": 520.34,
			"close": 520.3699,
			"volume": 31229,
			"datetime": 1712168580000
		},
		{
			"open": 520.36,
			"high": 520.47,
			"low": 520.355,
			"close": 520.44,
			"volume": 55863,
			"datetime": 1712168640000
		},
		{
			"open": 520.445,
			"high": 520.45,
			"low": 520.33,
			"close": 520.34,
			"volume": 50842,
			"datetime": 1712168700000
		},
		{
			"open": 520.355,
			"high": 520.378,
			"low": 520.235,
			"close": 520.28,
			"volume": 70273,
			"datetime": 1712168760000
		},
		{
			"open": 520.27,
			"high": 520.28,
			"low": 520.15,
			"close": 520.2,
			"volume": 50896,
			"datetime": 1712168820000
		},
		{
			"open": 520.1976,
			"high": 520.28,
			"low": 520.192,
			"close": 520.247,
			"volume": 60187,
			"datetime": 1712168880000
		},
		{
			"open": 520.25,
			"high": 520.28,
			"low": 520.1987,
			"close": 520.205,
			"volume": 39241,
			"datetime": 1712168940000
		},
		{
			"open": 520.19,
			"high": 520.225,
			"low": 520.16,
			"close": 520.175,
			"volume": 70756,
			"datetime": 1712169000000
		},
		{
			"open": 520.165,
			"high": 520.17,
			"low": 519.96,
			"close": 520.1,
			"volume": 281772,
			"datetime": 1712169060000
		},
		{
			"open": 520.1,
			"high": 520.16,
			"low": 519.975,
			"close": 519.9988,
			"volume": 86944,
			"datetime": 1712169120000
		},
		{
			"open": 519.99,
			"high": 520.15,
			"low": 519.99,
			"close": 520.12,
			"volume": 98829,
			"datetime": 1712169180000
		},
		{
			"open": 520.11,
			"high": 520.235,
			"low": 520.09,
			"close": 520.21,
			"volume": 67726,
			"datetime": 1712169240000
		},
		{
			"open": 520.21,
			"high": 520.295,
			"low": 520.21,
			"close": 520.23,
			"volume": 76647,
			"datetime": 1712169300000
		},
		{
			"open": 520.26,
			"high": 520.28,
			"low": 520.21,
			"close": 520.2701,
			"volume": 41933,
			"datetime": 1712169360000
		},
		{
			"open": 520.28,
			"high": 520.355,
			"low": 520.26,
			"close": 520.31,
			"volume": 54950,
			"datetime": 1712169420000
		},
		{
			"open": 520.3151,
			"high": 520.3784,
			"low": 520.28,
			"close": 520.295,
			"volume": 30703,
			"datetime": 1712169480000
		},
		{
			"open": 520.295,
			"high": 520.32,
			"low": 520.2611,
			"close": 520.3,
			"volume": 54936,
			"datetime": 1712169540000
		},
		{
			"open": 520.3,
			"high": 520.41,
			"low": 520.3,
			"close": 520.41,
			"volume": 32060,
			"datetime": 1712169600000
		},
		{
			"open": 520.4,
			"high": 520.425,
			"low": 520.34,
			"close": 520.36,
			"volume": 42354,
			"datetime": 1712169660000
		},
		{
			"open": 520.35,
			"high": 520.39,
			"low": 520.34,
			"close": 520.35,
			"volume": 32656,
			"datetime": 1712169720000
		},
		{
			"open": 520.35,
			"high": 520.35,
			"low": 520.21,
			"close": 520.22,
			"volume": 54916,
			"datetime": 1712169780000
		},
		{
			"open": 520.22,
			"high": 520.29,
			"low": 520.175,
			"close": 520.285,
			"volume": 48762,
			"datetime": 1712169840000
		},
		{
			"open": 520.28,
			"high": 520.29,
			"low": 520.15,
			"close": 520.2,
			"volume": 25953,
			"datetime": 1712169900000
		},
		{
			"open": 520.19,
			"high": 520.19,
			"low": 520.09,
			"close": 520.105,
			"volume": 67749,
			"datetime": 1712169960000
		},
		{
			"open": 520.11,
			"high": 520.215,
			"low": 520.1,
			"close": 520.1824,
			"volume": 47407,
			"datetime": 1712170020000
		},
		{
			"open": 520.18,
			"high": 520.22,
			"low": 520.12,
			"close": 520.12,
			"volume": 37987,
			"datetime": 1712170080000
		},
		{
			"open": 520.13,
			"high": 520.13,
			"low": 520.04,
			"close": 520.046,
			"volume": 38980,
			"datetime": 1712170140000
		},
		{
			"open": 520.06,
			"high": 520.15,
			"low": 520.06,
			"close": 520.15,
			"volume": 78092,
			"datetime": 1712170200000
		},
		{
			"open": 520.15,
			"high": 520.1777,
			"low": 520.045,
			"close": 520.06,
			"volume": 52554,
			"datetime": 1712170260000
		},
		{
			"open": 520.04,
			"high": 520.08,
			"low": 519.8497,
			"close": 519.91,
			"volume": 100732,
			"datetime": 1712170320000
		},
		{
			"open": 519.9,
			"high": 520.04,
			"low": 519.88,
			"close": 520.02,
			"volume": 83937,
			"datetime": 1712170380000
		},
		{
			"open": 520.0199,
			"high": 520.1,
			"low": 519.9982,
			"close": 520.07,
			"volume": 48831,
			"datetime": 1712170440000
		},
		{
			"open": 520.07,
			"high": 520.18,
			"low": 520.06,
			"close": 520.08,
			"volume": 85191,
			"datetime": 1712170500000
		},
		{
			"open": 520.085,
			"high": 520.085,
			"low": 519.92,
			"close": 519.96,
			"volume": 91125,
			"datetime": 1712170560000
		},
		{
			"open": 519.97,
			"high": 520.05,
			"low": 519.945,
			"close": 520.025,
			"volume": 27663,
			"datetime": 1712170620000
		},
		{
			"open": 520.01,
			"high": 520.01,
			"low": 519.86,
			"close": 519.9,
			"volume": 51785,
			"datetime": 1712170680000
		},
		{
			"open": 519.89,
			"high": 519.999,
			"low": 519.88,
			"close": 519.96,
			"volume": 60506,
			"datetime": 1712170740000
		},
		{
			"open": 519.95,
			"high": 519.96,
			"low": 519.72,
			"close": 519.81,
			"volume": 87449,
			"datetime": 1712170800000
		},
		{
			"open": 519.8,
			"high": 519.885,
			"low": 519.75,
			"close": 519.85,
			"volume": 59538,
			"datetime": 1712170860000
		},
		{
			"open": 519.85,
			"high": 519.96,
			"low": 519.83,
			"close": 519.927,
			"volume": 63842,
			"datetime": 1712170920000
		},
		{
			"open": 519.92,
			"high": 519.9701,
			"low": 519.86,
			"close": 519.89,
			"volume": 55370,
			"datetime": 1712170980000
		},
		{
			"open": 519.89,
			"high": 519.93,
			"low": 519.83,
			"close": 519.8801,
			"volume": 45096,
			"datetime": 1712171040000
		},
		{
			"open": 519.88,
			"high": 519.9,
			"low": 519.85,
			"close": 519.9,
			"volume": 41872,
			"datetime": 1712171100000
		},
		{
			"open": 519.895,
			"high": 520.03,
			"low": 519.8697,
			"close": 519.94,
			"volume": 113929,
			"datetime": 1712171160000
		},
		{
			"open": 519.93,
			"high": 519.96,
			"low": 519.8499,
			"close": 519.895,
			"volume": 42830,
			"datetime": 1712171220000
		},
		{
			"open": 519.89,
			"high": 519.89,
			"low": 519.825,
			"close": 519.87,
			"volume": 44343,
			"datetime": 1712171280000
		},
		{
			"open": 519.87,
			"high": 519.87,
			"low": 519.77,
			"close": 519.845,
			"volume": 60166,
			"datetime": 1712171340000
		},
		{
			"open": 519.85,
			"high": 519.875,
			"low": 519.79,
			"close": 519.81,
			"volume": 56759,
			"datetime": 1712171400000
		},
		{
			"open": 519.81,
			"high": 519.815,
			"low": 519.61,
			"close": 519.65,
			"volume": 129011,
			"datetime": 1712171460000
		},
		{
			"open": 519.65,
			"high": 519.66,
			"low": 519.2,
			"close": 519.4399,
			"volume": 226200,
			"datetime": 1712171520000
		},
		{
			"open": 519.43,
			"high": 519.43,
			"low": 519.31,
			"close": 519.36,
			"volume": 110426,
			"datetime": 1712171580000
		},
		{
			"open": 519.36,
			"high": 519.37,
			"low": 518.57,
			"close": 518.73,
			"volume": 431112,
			"datetime": 1712171640000
		},
		{
			"open": 518.73,
			"high": 518.73,
			"low": 517.77,
			"close": 518.66,
			"volume": 814140,
			"datetime": 1712171700000
		},
		{
			"open": 518.66,
			"high": 518.89,
			"low": 518.58,
			"close": 518.76,
			"volume": 281209,
			"datetime": 1712171760000
		},
		{
			"open": 518.76,
			"high": 519,
			"low": 518.59,
			"close": 518.6989,
			"volume": 195542,
			"datetime": 1712171820000
		},
		{
			"open": 518.69,
			"high": 518.79,
			"low": 518.49,
			"close": 518.75,
			"volume": 151772,
			"datetime": 1712171880000
		},
		{
			"open": 518.74,
			"high": 518.79,
			"low": 518.61,
			"close": 518.66,
			"volume": 149916,
			"datetime": 1712171940000
		},
		{
			"open": 518.66,
			"high": 518.81,
			"low": 518.58,
			"close": 518.76,
			"volume": 119100,
			"datetime": 1712172000000
		},
		{
			"open": 518.76,
			"high": 518.79,
			"low": 518.65,
			"close": 518.72,
			"volume": 87430,
			"datetime": 1712172060000
		},
		{
			"open": 518.72,
			"high": 518.73,
			"low": 518.57,
			"close": 518.6,
			"volume": 95679,
			"datetime": 1712172120000
		},
		{
			"open": 518.59,
			"high": 518.6,
			"low": 518.51,
			"close": 518.52,
			"volume": 82233,
			"datetime": 1712172180000
		},
		{
			"open": 518.525,
			"high": 518.54,
			"low": 518.21,
			"close": 518.29,
			"volume": 161622,
			"datetime": 1712172240000
		},
		{
			"open": 518.3,
			"high": 518.38,
			"low": 518.16,
			"close": 518.356,
			"volume": 131434,
			"datetime": 1712172300000
		},
		{
			"open": 518.35,
			"high": 518.5699,
			"low": 518.35,
			"close": 518.46,
			"volume": 161075,
			"datetime": 1712172360000
		},
		{
			"open": 518.45,
			"high": 518.5397,
			"low": 518.37,
			"close": 518.485,
			"volume": 79524,
			"datetime": 1712172420000
		},
		{
			"open": 518.49,
			"high": 518.65,
			"low": 518.475,
			"close": 518.52,
			"volume": 150655,
			"datetime": 1712172480000
		},
		{
			"open": 518.5,
			"high": 518.56,
			"low": 518.37,
			"close": 518.53,
			"volume": 85344,
			"datetime": 1712172540000
		},
		{
			"open": 518.55,
			"high": 518.65,
			"low": 518.28,
			"close": 518.6251,
			"volume": 162284,
			"datetime": 1712172600000
		},
		{
			"open": 518.63,
			"high": 518.82,
			"low": 518.54,
			"close": 518.75,
			"volume": 130631,
			"datetime": 1712172660000
		},
		{
			"open": 518.74,
			"high": 518.85,
			"low": 518.715,
			"close": 518.83,
			"volume": 178644,
			"datetime": 1712172720000
		},
		{
			"open": 518.82,
			"high": 518.845,
			"low": 518.67,
			"close": 518.7,
			"volume": 133903,
			"datetime": 1712172780000
		},
		{
			"open": 518.68,
			"high": 518.91,
			"low": 518.62,
			"close": 518.9,
			"volume": 126946,
			"datetime": 1712172840000
		},
		{
			"open": 518.89,
			"high": 519.26,
			"low": 518.89,
			"close": 519.15,
			"volume": 271542,
			"datetime": 1712172900000
		},
		{
			"open": 519.16,
			"high": 519.23,
			"low": 519.08,
			"close": 519.18,
			"volume": 177617,
			"datetime": 1712172960000
		},
		{
			"open": 519.17,
			"high": 519.32,
			"low": 519.14,
			"close": 519.32,
			"volume": 154899,
			"datetime": 1712173020000
		},
		{
			"open": 519.325,
			"high": 519.46,
			"low": 519.275,
			"close": 519.365,
			"volume": 158285,
			"datetime": 1712173080000
		},
		{
			"open": 519.37,
			"high": 519.395,
			"low": 519.17,
			"close": 519.295,
			"volume": 190411,
			"datetime": 1712173140000
		},
		{
			"open": 519.2876,
			"high": 519.325,
			"low": 519.16,
			"close": 519.17,
			"volume": 135899,
			"datetime": 1712173200000
		},
		{
			"open": 519.18,
			"high": 519.3747,
			"low": 519.13,
			"close": 519.2951,
			"volume": 106803,
			"datetime": 1712173260000
		},
		{
			"open": 519.315,
			"high": 519.3299,
			"low": 519.18,
			"close": 519.285,
			"volume": 92999,
			"datetime": 1712173320000
		},
		{
			"open": 519.28,
			"high": 519.48,
			"low": 519.28,
			"close": 519.43,
			"volume": 121482,
			"datetime": 1712173380000
		},
		{
			"open": 519.44,
			"high": 519.465,
			"low": 519.32,
			"close": 519.38,
			"volume": 112770,
			"datetime": 1712173440000
		},
		{
			"open": 519.39,
			"high": 519.51,
			"low": 519.385,
			"close": 519.505,
			"volume": 141266,
			"datetime": 1712173500000
		},
		{
			"open": 519.507,
			"high": 519.62,
			"low": 519.5,
			"close": 519.5788,
			"volume": 164719,
			"datetime": 1712173560000
		},
		{
			"open": 519.57,
			"high": 519.65,
			"low": 519.53,
			"close": 519.61,
			"volume": 153833,
			"datetime": 1712173620000
		},
		{
			"open": 519.6,
			"high": 519.7,
			"low": 519.55,
			"close": 519.59,
			"volume": 202378,
			"datetime": 1712173680000
		},
		{
			"open": 519.58,
			"high": 519.77,
			"low": 519.58,
			"close": 519.75,
			"volume": 158190,
			"datetime": 1712173740000
		},
		{
			"open": 519.74,
			"high": 519.77,
			"low": 519.5,
			"close": 519.64,
			"volume": 287069,
			"datetime": 1712173800000
		},
		{
			"open": 519.64,
			"high": 519.68,
			"low": 519.37,
			"close": 519.43,
			"volume": 250328,
			"datetime": 1712173860000
		},
		{
			"open": 519.4387,
			"high": 519.52,
			"low": 519.38,
			"close": 519.5,
			"volume": 183047,
			"datetime": 1712173920000
		},
		{
			"open": 519.5,
			"high": 519.7,
			"low": 519.5,
			"close": 519.59,
			"volume": 173537,
			"datetime": 1712173980000
		},
		{
			"open": 519.605,
			"high": 519.67,
			"low": 519.4,
			"close": 519.475,
			"volume": 311972,
			"datetime": 1712174040000
		},
		{
			"open": 519.47,
			"high": 519.47,
			"low": 519.08,
			"close": 519.085,
			"volume": 515553,
			"datetime": 1712174100000
		},
		{
			"open": 519.09,
			"high": 519.26,
			"low": 519.035,
			"close": 519.14,
			"volume": 443825,
			"datetime": 1712174160000
		},
		{
			"open": 519.13,
			"high": 519.3,
			"low": 519.12,
			"close": 519.22,
			"volume": 480368,
			"datetime": 1712174220000
		},
		{
			"open": 519.21,
			"high": 519.42,
			"low": 519.195,
			"close": 519.37,
			"volume": 979606,
			"datetime": 1712174280000
		},
		{
			"open": 519.37,
			"high": 519.6,
			"low": 519.27,
			"close": 519.36,
			"volume": 2760756,
			"datetime": 1712174340000
		},
		{
			"open": 523.52,
			"high": 523.7,
			"low": 523.31,
			"close": 523.32,
			"volume": 1087391,
			"datetime": 1712237400000
		},
		{
			"open": 523.31,
			"high": 523.42,
			"low": 523.22,
			"close": 523.2796,
			"volume": 324039,
			"datetime": 1712237460000
		},
		{
			"open": 523.27,
			"high": 523.38,
			"low": 523.23,
			"close": 523.25,
			"volume": 222276,
			"datetime": 1712237520000
		},
		{
			"open": 523.26,
			"high": 523.38,
			"low": 523.24,
			"close": 523.37,
			"volume": 394843,
			"datetime": 1712237580000
		},
		{
			"open": 523.38,
			"high": 523.5399,
			"low": 523.37,
			"close": 523.53,
			"volume": 195775,
			"datetime": 1712237640000
		},
		{
			"open": 523.53,
			"high": 523.64,
			"low": 523.52,
			"close": 523.525,
			"volume": 266174,
			"datetime": 1712237700000
		},
		{
			"open": 523.53,
			"high": 523.57,
			"low": 523.41,
			"close": 523.4399,
			"volume": 241913,
			"datetime": 1712237760000
		},
		{
			"open": 523.435,
			"high": 523.52,
			"low": 523.26,
			"close": 523.29,
			"volume": 162577,
			"datetime": 1712237820000
		},
		{
			"open": 523.29,
			"high": 523.31,
			"low": 523.15,
			"close": 523.23,
			"volume": 197331,
			"datetime": 1712237880000
		},
		{
			"open": 523.25,
			"high": 523.25,
			"low": 522.94,
			"close": 523.0599,
			"volume": 201560,
			"datetime": 1712237940000
		},
		{
			"open": 523.06,
			"high": 523.16,
			"low": 523.01,
			"close": 523.15,
			"volume": 175634,
			"datetime": 1712238000000
		},
		{
			"open": 523.15,
			"high": 523.25,
			"low": 523.085,
			"close": 523.18,
			"volume": 137221,
			"datetime": 1712238060000
		},
		{
			"open": 523.18,
			"high": 523.26,
			"low": 523.105,
			"close": 523.23,
			"volume": 132325,
			"datetime": 1712238120000
		},
		{
			"open": 523.22,
			"high": 523.35,
			"low": 523.2,
			"close": 523.2199,
			"volume": 108979,
			"datetime": 1712238180000
		},
		{
			"open": 523.21,
			"high": 523.2322,
			"low": 523.08,
			"close": 523.14,
			"volume": 114815,
			"datetime": 1712238240000
		},
		{
			"open": 523.14,
			"high": 523.26,
			"low": 523.105,
			"close": 523.18,
			"volume": 139267,
			"datetime": 1712238300000
		},
		{
			"open": 523.185,
			"high": 523.24,
			"low": 523.0101,
			"close": 523.05,
			"volume": 115710,
			"datetime": 1712238360000
		},
		{
			"open": 523.059,
			"high": 523.1199,
			"low": 522.98,
			"close": 523.09,
			"volume": 133822,
			"datetime": 1712238420000
		},
		{
			"open": 523.1,
			"high": 523.2,
			"low": 522.99,
			"close": 522.99,
			"volume": 133962,
			"datetime": 1712238480000
		},
		{
			"open": 522.99,
			"high": 523.16,
			"low": 522.99,
			"close": 523.16,
			"volume": 93998,
			"datetime": 1712238540000
		},
		{
			"open": 523.16,
			"high": 523.2,
			"low": 522.99,
			"close": 523.0501,
			"volume": 101573,
			"datetime": 1712238600000
		},
		{
			"open": 523.06,
			"high": 523.09,
			"low": 522.77,
			"close": 522.8,
			"volume": 157088,
			"datetime": 1712238660000
		},
		{
			"open": 522.7999,
			"high": 522.865,
			"low": 522.735,
			"close": 522.82,
			"volume": 141575,
			"datetime": 1712238720000
		},
		{
			"open": 522.82,
			"high": 522.93,
			"low": 522.81,
			"close": 522.8902,
			"volume": 102362,
			"datetime": 1712238780000
		},
		{
			"open": 522.885,
			"high": 522.89,
			"low": 522.76,
			"close": 522.8,
			"volume": 70421,
			"datetime": 1712238840000
		},
		{
			"open": 522.81,
			"high": 522.86,
			"low": 522.7,
			"close": 522.8,
			"volume": 98019,
			"datetime": 1712238900000
		},
		{
			"open": 522.815,
			"high": 522.86,
			"low": 522.74,
			"close": 522.86,
			"volume": 68913,
			"datetime": 1712238960000
		},
		{
			"open": 522.855,
			"high": 522.855,
			"low": 522.58,
			"close": 522.63,
			"volume": 143070,
			"datetime": 1712239020000
		},
		{
			"open": 522.63,
			"high": 522.76,
			"low": 522.6,
			"close": 522.6957,
			"volume": 101098,
			"datetime": 1712239080000
		},
		{
			"open": 522.69,
			"high": 522.87,
			"low": 522.68,
			"close": 522.84,
			"volume": 116420,
			"datetime": 1712239140000
		},
		{
			"open": 522.84,
			"high": 523,
			"low": 522.82,
			"close": 522.9412,
			"volume": 153736,
			"datetime": 1712239200000
		},
		{
			"open": 522.95,
			"high": 523.04,
			"low": 522.81,
			"close": 522.89,
			"volume": 99205,
			"datetime": 1712239260000
		},
		{
			"open": 522.88,
			"high": 522.97,
			"low": 522.858,
			"close": 522.97,
			"volume": 54006,
			"datetime": 1712239320000
		},
		{
			"open": 522.97,
			"high": 523,
			"low": 522.88,
			"close": 522.89,
			"volume": 70772,
			"datetime": 1712239380000
		},
		{
			"open": 522.8989,
			"high": 522.955,
			"low": 522.87,
			"close": 522.88,
			"volume": 50453,
			"datetime": 1712239440000
		},
		{
			"open": 522.89,
			"high": 522.98,
			"low": 522.84,
			"close": 522.84,
			"volume": 61392,
			"datetime": 1712239500000
		},
		{
			"open": 522.845,
			"high": 522.885,
			"low": 522.745,
			"close": 522.885,
			"volume": 85944,
			"datetime": 1712239560000
		},
		{
			"open": 522.88,
			"high": 522.93,
			"low": 522.817,
			"close": 522.87,
			"volume": 65156,
			"datetime": 1712239620000
		},
		{
			"open": 522.88,
			"high": 522.955,
			"low": 522.875,
			"close": 522.94,
			"volume": 44173,
			"datetime": 1712239680000
		},
		{
			"open": 522.9387,
			"high": 522.99,
			"low": 522.92,
			"close": 522.92,
			"volume": 47888,
			"datetime": 1712239740000
		},
		{
			"open": 522.93,
			"high": 522.995,
			"low": 522.8301,
			"close": 522.835,
			"volume": 85089,
			"datetime": 1712239800000
		},
		{
			"open": 522.8301,
			"high": 522.95,
			"low": 522.775,
			"close": 522.9424,
			"volume": 78604,
			"datetime": 1712239860000
		},
		{
			"open": 522.95,
			"high": 523.04,
			"low": 522.945,
			"close": 523,
			"volume": 91828,
			"datetime": 1712239920000
		},
		{
			"open": 523,
			"high": 523,
			"low": 522.855,
			"close": 522.91,
			"volume": 86620,
			"datetime": 1712239980000
		},
		{
			"open": 522.91,
			"high": 522.92,
			"low": 522.82,
			"close": 522.905,
			"volume": 31763,
			"datetime": 1712240040000
		},
		{
			"open": 522.905,
			"high": 522.99,
			"low": 522.905,
			"close": 522.94,
			"volume": 67420,
			"datetime": 1712240100000
		},
		{
			"open": 522.93,
			"high": 522.97,
			"low": 522.82,
			"close": 522.88,
			"volume": 41782,
			"datetime": 1712240160000
		},
		{
			"open": 522.88,
			"high": 522.93,
			"low": 522.86,
			"close": 522.895,
			"volume": 48734,
			"datetime": 1712240220000
		},
		{
			"open": 522.9,
			"high": 523.04,
			"low": 522.87,
			"close": 522.88,
			"volume": 56224,
			"datetime": 1712240280000
		},
		{
			"open": 522.88,
			"high": 522.91,
			"low": 522.74,
			"close": 522.8,
			"volume": 67823,
			"datetime": 1712240340000
		},
		{
			"open": 522.81,
			"high": 522.88,
			"low": 522.755,
			"close": 522.83,
			"volume": 122043,
			"datetime": 1712240400000
		},
		{
			"open": 522.82,
			"high": 522.86,
			"low": 522.78,
			"close": 522.86,
			"volume": 34721,
			"datetime": 1712240460000
		},
		{
			"open": 522.85,
			"high": 522.89,
			"low": 522.725,
			"close": 522.74,
			"volume": 73843,
			"datetime": 1712240520000
		},
		{
			"open": 522.73,
			"high": 522.7399,
			"low": 522.47,
			"close": 522.61,
			"volume": 146263,
			"datetime": 1712240580000
		},
		{
			"open": 522.6,
			"high": 522.67,
			"low": 522.53,
			"close": 522.63,
			"volume": 99723,
			"datetime": 1712240640000
		},
		{
			"open": 522.64,
			"high": 522.66,
			"low": 522.58,
			"close": 522.65,
			"volume": 65936,
			"datetime": 1712240700000
		},
		{
			"open": 522.6599,
			"high": 522.77,
			"low": 522.6114,
			"close": 522.77,
			"volume": 85203,
			"datetime": 1712240760000
		},
		{
			"open": 522.76,
			"high": 522.83,
			"low": 522.69,
			"close": 522.805,
			"volume": 63352,
			"datetime": 1712240820000
		},
		{
			"open": 522.81,
			"high": 523,
			"low": 522.8,
			"close": 522.98,
			"volume": 85251,
			"datetime": 1712240880000
		},
		{
			"open": 522.98,
			"high": 522.99,
			"low": 522.89,
			"close": 522.92,
			"volume": 83311,
			"datetime": 1712240940000
		},
		{
			"open": 522.92,
			"high": 522.95,
			"low": 522.88,
			"close": 522.94,
			"volume": 58317,
			"datetime": 1712241000000
		},
		{
			"open": 522.94,
			"high": 522.95,
			"low": 522.84,
			"close": 522.88,
			"volume": 57397,
			"datetime": 1712241060000
		},
		{
			"open": 522.87,
			"high": 522.91,
			"low": 522.83,
			"close": 522.88,
			"volume": 45279,
			"datetime": 1712241120000
		},
		{
			"open": 522.88,
			"high": 522.9,
			"low": 522.74,
			"close": 522.75,
			"volume": 46253,
			"datetime": 1712241180000
		},
		{
			"open": 522.74,
			"high": 522.79,
			"low": 522.62,
			"close": 522.66,
			"volume": 83540,
			"datetime": 1712241240000
		},
		{
			"open": 522.66,
			"high": 522.77,
			"low": 522.63,
			"close": 522.73,
			"volume": 65941,
			"datetime": 1712241300000
		},
		{
			"open": 522.73,
			"high": 522.7503,
			"low": 522.61,
			"close": 522.64,
			"volume": 91460,
			"datetime": 1712241360000
		},
		{
			"open": 522.635,
			"high": 522.696,
			"low": 522.63,
			"close": 522.67,
			"volume": 61083,
			"datetime": 1712241420000
		},
		{
			"open": 522.67,
			"high": 522.7169,
			"low": 522.64,
			"close": 522.68,
			"volume": 49625,
			"datetime": 1712241480000
		},
		{
			"open": 522.68,
			"high": 522.82,
			"low": 522.67,
			"close": 522.785,
			"volume": 69848,
			"datetime": 1712241540000
		},
		{
			"open": 522.78,
			"high": 522.8101,
			"low": 522.735,
			"close": 522.8101,
			"volume": 52240,
			"datetime": 1712241600000
		},
		{
			"open": 522.81,
			"high": 522.94,
			"low": 522.8,
			"close": 522.915,
			"volume": 73583,
			"datetime": 1712241660000
		},
		{
			"open": 522.91,
			"high": 522.91,
			"low": 522.785,
			"close": 522.83,
			"volume": 58516,
			"datetime": 1712241720000
		},
		{
			"open": 522.83,
			"high": 522.92,
			"low": 522.83,
			"close": 522.92,
			"volume": 47576,
			"datetime": 1712241780000
		},
		{
			"open": 522.93,
			"high": 522.9604,
			"low": 522.89,
			"close": 522.92,
			"volume": 46140,
			"datetime": 1712241840000
		},
		{
			"open": 522.905,
			"high": 522.965,
			"low": 522.8424,
			"close": 522.845,
			"volume": 52206,
			"datetime": 1712241900000
		},
		{
			"open": 522.845,
			"high": 522.92,
			"low": 522.825,
			"close": 522.84,
			"volume": 39079,
			"datetime": 1712241960000
		},
		{
			"open": 522.84,
			"high": 522.89,
			"low": 522.82,
			"close": 522.86,
			"volume": 30659,
			"datetime": 1712242020000
		},
		{
			"open": 522.86,
			"high": 522.89,
			"low": 522.815,
			"close": 522.83,
			"volume": 41179,
			"datetime": 1712242080000
		},
		{
			"open": 522.85,
			"high": 522.91,
			"low": 522.82,
			"close": 522.885,
			"volume": 34112,
			"datetime": 1712242140000
		},
		{
			"open": 522.89,
			"high": 522.89,
			"low": 522.7,
			"close": 522.71,
			"volume": 61630,
			"datetime": 1712242200000
		},
		{
			"open": 522.7,
			"high": 522.76,
			"low": 522.67,
			"close": 522.7,
			"volume": 48938,
			"datetime": 1712242260000
		},
		{
			"open": 522.7,
			"high": 522.825,
			"low": 522.7,
			"close": 522.75,
			"volume": 33527,
			"datetime": 1712242320000
		},
		{
			"open": 522.75,
			"high": 522.81,
			"low": 522.71,
			"close": 522.735,
			"volume": 38748,
			"datetime": 1712242380000
		},
		{
			"open": 522.73,
			"high": 522.78,
			"low": 522.671,
			"close": 522.705,
			"volume": 48264,
			"datetime": 1712242440000
		},
		{
			"open": 522.715,
			"high": 522.76,
			"low": 522.695,
			"close": 522.7,
			"volume": 39960,
			"datetime": 1712242500000
		},
		{
			"open": 522.71,
			"high": 522.82,
			"low": 522.7,
			"close": 522.71,
			"volume": 109259,
			"datetime": 1712242560000
		},
		{
			"open": 522.71,
			"high": 522.855,
			"low": 522.685,
			"close": 522.83,
			"volume": 56968,
			"datetime": 1712242620000
		},
		{
			"open": 522.82,
			"high": 522.86,
			"low": 522.72,
			"close": 522.73,
			"volume": 62486,
			"datetime": 1712242680000
		},
		{
			"open": 522.73,
			"high": 522.77,
			"low": 522.72,
			"close": 522.73,
			"volume": 32581,
			"datetime": 1712242740000
		},
		{
			"open": 522.73,
			"high": 522.83,
			"low": 522.73,
			"close": 522.8,
			"volume": 72399,
			"datetime": 1712242800000
		},
		{
			"open": 522.805,
			"high": 522.87,
			"low": 522.79,
			"close": 522.795,
			"volume": 45499,
			"datetime": 1712242860000
		},
		{
			"open": 522.81,
			"high": 522.85,
			"low": 522.78,
			"close": 522.81,
			"volume": 45591,
			"datetime": 1712242920000
		},
		{
			"open": 522.8,
			"high": 522.82,
			"low": 522.705,
			"close": 522.76,
			"volume": 34896,
			"datetime": 1712242980000
		},
		{
			"open": 522.76,
			"high": 522.76,
			"low": 522.7,
			"close": 522.71,
			"volume": 43435,
			"datetime": 1712243040000
		},
		{
			"open": 522.7,
			"high": 522.72,
			"low": 522.63,
			"close": 522.685,
			"volume": 21980,
			"datetime": 1712243100000
		},
		{
			"open": 522.69,
			"high": 522.79,
			"low": 522.68,
			"close": 522.76,
			"volume": 54537,
			"datetime": 1712243160000
		},
		{
			"open": 522.76,
			"high": 522.79,
			"low": 522.7,
			"close": 522.725,
			"volume": 28230,
			"datetime": 1712243220000
		},
		{
			"open": 522.73,
			"high": 522.74,
			"low": 522.61,
			"close": 522.6216,
			"volume": 36909,
			"datetime": 1712243280000
		},
		{
			"open": 522.6293,
			"high": 522.6293,
			"low": 522.535,
			"close": 522.61,
			"volume": 68726,
			"datetime": 1712243340000
		},
		{
			"open": 522.61,
			"high": 522.71,
			"low": 522.57,
			"close": 522.69,
			"volume": 49650,
			"datetime": 1712243400000
		},
		{
			"open": 522.68,
			"high": 522.68,
			"low": 522.61,
			"close": 522.64,
			"volume": 34409,
			"datetime": 1712243460000
		},
		{
			"open": 522.62,
			"high": 522.62,
			"low": 522.38,
			"close": 522.4,
			"volume": 141765,
			"datetime": 1712243520000
		},
		{
			"open": 522.4,
			"high": 522.52,
			"low": 522.36,
			"close": 522.48,
			"volume": 126207,
			"datetime": 1712243580000
		},
		{
			"open": 522.47,
			"high": 522.51,
			"low": 522.425,
			"close": 522.46,
			"volume": 38514,
			"datetime": 1712243640000
		},
		{
			"open": 522.45,
			"high": 522.55,
			"low": 522.43,
			"close": 522.49,
			"volume": 50059,
			"datetime": 1712243700000
		},
		{
			"open": 522.5,
			"high": 522.545,
			"low": 522.47,
			"close": 522.48,
			"volume": 111955,
			"datetime": 1712243760000
		},
		{
			"open": 522.485,
			"high": 522.52,
			"low": 522.43,
			"close": 522.46,
			"volume": 40812,
			"datetime": 1712243820000
		},
		{
			"open": 522.47,
			"high": 522.51,
			"low": 522.425,
			"close": 522.5,
			"volume": 30932,
			"datetime": 1712243880000
		},
		{
			"open": 522.48,
			"high": 522.485,
			"low": 522.42,
			"close": 522.42,
			"volume": 39414,
			"datetime": 1712243940000
		},
		{
			"open": 522.4213,
			"high": 522.4664,
			"low": 522.4,
			"close": 522.4581,
			"volume": 76822,
			"datetime": 1712244000000
		},
		{
			"open": 522.46,
			"high": 522.52,
			"low": 522.41,
			"close": 522.47,
			"volume": 56382,
			"datetime": 1712244060000
		},
		{
			"open": 522.47,
			"high": 522.5,
			"low": 522.4471,
			"close": 522.48,
			"volume": 39747,
			"datetime": 1712244120000
		},
		{
			"open": 522.47,
			"high": 522.56,
			"low": 522.47,
			"close": 522.55,
			"volume": 39269,
			"datetime": 1712244180000
		},
		{
			"open": 522.5502,
			"high": 522.67,
			"low": 522.54,
			"close": 522.6215,
			"volume": 87466,
			"datetime": 1712244240000
		},
		{
			"open": 522.63,
			"high": 522.645,
			"low": 522.52,
			"close": 522.59,
			"volume": 51481,
			"datetime": 1712244300000
		},
		{
			"open": 522.59,
			"high": 522.66,
			"low": 522.565,
			"close": 522.62,
			"volume": 39540,
			"datetime": 1712244360000
		},
		{
			"open": 522.625,
			"high": 522.65,
			"low": 522.61,
			"close": 522.635,
			"volume": 39700,
			"datetime": 1712244420000
		},
		{
			"open": 522.64,
			"high": 522.67,
			"low": 522.5824,
			"close": 522.66,
			"volume": 47134,
			"datetime": 1712244480000
		},
		{
			"open": 522.66,
			"high": 522.76,
			"low": 522.66,
			"close": 522.75,
			"volume": 48647,
			"datetime": 1712244540000
		},
		{
			"open": 522.77,
			"high": 522.86,
			"low": 522.755,
			"close": 522.8,
			"volume": 76906,
			"datetime": 1712244600000
		},
		{
			"open": 522.805,
			"high": 522.815,
			"low": 522.73,
			"close": 522.755,
			"volume": 57454,
			"datetime": 1712244660000
		},
		{
			"open": 522.755,
			"high": 522.78,
			"low": 522.68,
			"close": 522.77,
			"volume": 60094,
			"datetime": 1712244720000
		},
		{
			"open": 522.77,
			"high": 522.86,
			"low": 522.77,
			"close": 522.77,
			"volume": 62360,
			"datetime": 1712244780000
		},
		{
			"open": 522.77,
			"high": 522.825,
			"low": 522.74,
			"close": 522.8,
			"volume": 64948,
			"datetime": 1712244840000
		},
		{
			"open": 522.805,
			"high": 522.9,
			"low": 522.78,
			"close": 522.885,
			"volume": 79662,
			"datetime": 1712244900000
		},
		{
			"open": 522.8801,
			"high": 522.9,
			"low": 522.83,
			"close": 522.835,
			"volume": 47181,
			"datetime": 1712244960000
		},
		{
			"open": 522.835,
			"high": 522.845,
			"low": 522.801,
			"close": 522.82,
			"volume": 30227,
			"datetime": 1712245020000
		},
		{
			"open": 522.825,
			"high": 522.905,
			"low": 522.7701,
			"close": 522.89,
			"volume": 50038,
			"datetime": 1712245080000
		},
		{
			"open": 522.89,
			"high": 522.9,
			"low": 522.86,
			"close": 522.8721,
			"volume": 46599,
			"datetime": 1712245140000
		},
		{
			"open": 522.88,
			"high": 522.95,
			"low": 522.88,
			"close": 522.91,
			"volume": 43562,
			"datetime": 1712245200000
		},
		{
			"open": 522.915,
			"high": 523.07,
			"low": 522.91,
			"close": 523,
			"volume": 104852,
			"datetime": 1712245260000
		},
		{
			"open": 523,
			"high": 523.079,
			"low": 522.981,
			"close": 523.05,
			"volume": 70571,
			"datetime": 1712245320000
		},
		{
			"open": 523.04,
			"high": 523.09,
			"low": 523,
			"close": 523.05,
			"volume": 45096,
			"datetime": 1712245380000
		},
		{
			"open": 523.06,
			"high": 523.06,
			"low": 523,
			"close": 523.06,
			"volume": 41167,
			"datetime": 1712245440000
		},
		{
			"open": 523.06,
			"high": 523.1097,
			"low": 523.035,
			"close": 523.06,
			"volume": 39545,
			"datetime": 1712245500000
		},
		{
			"open": 523.06,
			"high": 523.13,
			"low": 523.03,
			"close": 523.045,
			"volume": 52336,
			"datetime": 1712245560000
		},
		{
			"open": 523.045,
			"high": 523.1,
			"low": 523.045,
			"close": 523.07,
			"volume": 26245,
			"datetime": 1712245620000
		},
		{
			"open": 523.08,
			"high": 523.08,
			"low": 522.94,
			"close": 523,
			"volume": 78200,
			"datetime": 1712245680000
		},
		{
			"open": 523,
			"high": 523.03,
			"low": 522.985,
			"close": 523.02,
			"volume": 40183,
			"datetime": 1712245740000
		},
		{
			"open": 523.03,
			"high": 523.1299,
			"low": 523.025,
			"close": 523.1201,
			"volume": 101543,
			"datetime": 1712245800000
		},
		{
			"open": 523.1212,
			"high": 523.2,
			"low": 523.101,
			"close": 523.1812,
			"volume": 63317,
			"datetime": 1712245860000
		},
		{
			"open": 523.19,
			"high": 523.22,
			"low": 523.16,
			"close": 523.1698,
			"volume": 37651,
			"datetime": 1712245920000
		},
		{
			"open": 523.18,
			"high": 523.2886,
			"low": 523.18,
			"close": 523.265,
			"volume": 63690,
			"datetime": 1712245980000
		},
		{
			"open": 523.255,
			"high": 523.285,
			"low": 523.205,
			"close": 523.22,
			"volume": 42170,
			"datetime": 1712246040000
		},
		{
			"open": 523.2201,
			"high": 523.2402,
			"low": 523.19,
			"close": 523.1902,
			"volume": 37626,
			"datetime": 1712246100000
		},
		{
			"open": 523.181,
			"high": 523.275,
			"low": 523.15,
			"close": 523.24,
			"volume": 66691,
			"datetime": 1712246160000
		},
		{
			"open": 523.24,
			"high": 523.275,
			"low": 523.22,
			"close": 523.265,
			"volume": 31194,
			"datetime": 1712246220000
		},
		{
			"open": 523.27,
			"high": 523.3,
			"low": 523.26,
			"close": 523.3,
			"volume": 40438,
			"datetime": 1712246280000
		},
		{
			"open": 523.3,
			"high": 523.31,
			"low": 523.18,
			"close": 523.26,
			"volume": 102465,
			"datetime": 1712246340000
		},
		{
			"open": 523.27,
			"high": 523.29,
			"low": 523.18,
			"close": 523.2,
			"volume": 85161,
			"datetime": 1712246400000
		},
		{
			"open": 523.19,
			"high": 523.285,
			"low": 523.18,
			"close": 523.285,
			"volume": 42610,
			"datetime": 1712246460000
		},
		{
			"open": 523.29,
			"high": 523.32,
			"low": 523.2,
			"close": 523.24,
			"volume": 60252,
			"datetime": 1712246520000
		},
		{
			"open": 523.24,
			"high": 523.315,
			"low": 523.24,
			"close": 523.28,
			"volume": 34719,
			"datetime": 1712246580000
		},
		{
			"open": 523.29,
			"high": 523.43,
			"low": 523.285,
			"close": 523.43,
			"volume": 94350,
			"datetime": 1712246640000
		},
		{
			"open": 523.425,
			"high": 523.637,
			"low": 523.42,
			"close": 523.56,
			"volume": 252846,
			"datetime": 1712246700000
		},
		{
			"open": 523.56,
			"high": 523.6,
			"low": 523.54,
			"close": 523.55,
			"volume": 119475,
			"datetime": 1712246760000
		},
		{
			"open": 523.5401,
			"high": 523.62,
			"low": 523.52,
			"close": 523.62,
			"volume": 101810,
			"datetime": 1712246820000
		},
		{
			"open": 523.63,
			"high": 523.69,
			"low": 523.6,
			"close": 523.665,
			"volume": 82987,
			"datetime": 1712246880000
		},
		{
			"open": 523.67,
			"high": 523.8674,
			"low": 523.665,
			"close": 523.775,
			"volume": 316032,
			"datetime": 1712246940000
		},
		{
			"open": 523.78,
			"high": 523.85,
			"low": 523.71,
			"close": 523.72,
			"volume": 115735,
			"datetime": 1712247000000
		},
		{
			"open": 523.71,
			"high": 523.7401,
			"low": 523.655,
			"close": 523.6703,
			"volume": 153128,
			"datetime": 1712247060000
		},
		{
			"open": 523.675,
			"high": 523.68,
			"low": 523.515,
			"close": 523.56,
			"volume": 119934,
			"datetime": 1712247120000
		},
		{
			"open": 523.56,
			"high": 523.67,
			"low": 523.555,
			"close": 523.64,
			"volume": 51755,
			"datetime": 1712247180000
		},
		{
			"open": 523.6302,
			"high": 523.67,
			"low": 523.565,
			"close": 523.58,
			"volume": 42054,
			"datetime": 1712247240000
		},
		{
			"open": 523.58,
			"high": 523.62,
			"low": 523.53,
			"close": 523.53,
			"volume": 36375,
			"datetime": 1712247300000
		},
		{
			"open": 523.548,
			"high": 523.65,
			"low": 523.548,
			"close": 523.5902,
			"volume": 68921,
			"datetime": 1712247360000
		},
		{
			"open": 523.59,
			"high": 523.64,
			"low": 523.58,
			"close": 523.595,
			"volume": 42160,
			"datetime": 1712247420000
		},
		{
			"open": 523.59,
			"high": 523.59,
			"low": 523.5,
			"close": 523.55,
			"volume": 40313,
			"datetime": 1712247480000
		},
		{
			"open": 523.5401,
			"high": 523.56,
			"low": 523.48,
			"close": 523.48,
			"volume": 53631,
			"datetime": 1712247540000
		},
		{
			"open": 523.49,
			"high": 523.53,
			"low": 523.41,
			"close": 523.43,
			"volume": 91418,
			"datetime": 1712247600000
		},
		{
			"open": 523.44,
			"high": 523.52,
			"low": 523.41,
			"close": 523.49,
			"volume": 96955,
			"datetime": 1712247660000
		},
		{
			"open": 523.49,
			"high": 523.6,
			"low": 523.49,
			"close": 523.57,
			"volume": 47909,
			"datetime": 1712247720000
		},
		{
			"open": 523.57,
			"high": 523.57,
			"low": 523.52,
			"close": 523.5201,
			"volume": 26728,
			"datetime": 1712247780000
		},
		{
			"open": 523.54,
			"high": 523.57,
			"low": 523.485,
			"close": 523.49,
			"volume": 28507,
			"datetime": 1712247840000
		},
		{
			"open": 523.49,
			"high": 523.52,
			"low": 523.48,
			"close": 523.5079,
			"volume": 43803,
			"datetime": 1712247900000
		},
		{
			"open": 523.5,
			"high": 523.51,
			"low": 523.45,
			"close": 523.465,
			"volume": 55263,
			"datetime": 1712247960000
		},
		{
			"open": 523.475,
			"high": 523.5199,
			"low": 523.465,
			"close": 523.485,
			"volume": 32759,
			"datetime": 1712248020000
		},
		{
			"open": 523.485,
			"high": 523.51,
			"low": 523.445,
			"close": 523.4901,
			"volume": 33216,
			"datetime": 1712248080000
		},
		{
			"open": 523.49,
			"high": 523.54,
			"low": 523.48,
			"close": 523.5102,
			"volume": 34265,
			"datetime": 1712248140000
		},
		{
			"open": 523.52,
			"high": 523.53,
			"low": 523.46,
			"close": 523.495,
			"volume": 49003,
			"datetime": 1712248200000
		},
		{
			"open": 523.5,
			"high": 523.51,
			"low": 523.4301,
			"close": 523.495,
			"volume": 37532,
			"datetime": 1712248260000
		},
		{
			"open": 523.495,
			"high": 523.54,
			"low": 523.495,
			"close": 523.51,
			"volume": 34282,
			"datetime": 1712248320000
		},
		{
			"open": 523.49,
			"high": 523.52,
			"low": 523.41,
			"close": 523.41,
			"volume": 28759,
			"datetime": 1712248380000
		},
		{
			"open": 523.42,
			"high": 523.5,
			"low": 523.415,
			"close": 523.455,
			"volume": 37912,
			"datetime": 1712248440000
		},
		{
			"open": 523.46,
			"high": 523.5,
			"low": 523.43,
			"close": 523.465,
			"volume": 42390,
			"datetime": 1712248500000
		},
		{
			"open": 523.47,
			"high": 523.5301,
			"low": 523.455,
			"close": 523.49,
			"volume": 46482,
			"datetime": 1712248560000
		},
		{
			"open": 523.475,
			"high": 523.5,
			"low": 523.415,
			"close": 523.43,
			"volume": 39901,
			"datetime": 1712248620000
		},
		{
			"open": 523.43,
			"high": 523.49,
			"low": 523.43,
			"close": 523.47,
			"volume": 18759,
			"datetime": 1712248680000
		},
		{
			"open": 523.48,
			"high": 523.55,
			"low": 523.4601,
			"close": 523.52,
			"volume": 28765,
			"datetime": 1712248740000
		},
		{
			"open": 523.51,
			"high": 523.58,
			"low": 523.51,
			"close": 523.56,
			"volume": 26339,
			"datetime": 1712248800000
		},
		{
			"open": 523.565,
			"high": 523.58,
			"low": 523.49,
			"close": 523.495,
			"volume": 41129,
			"datetime": 1712248860000
		},
		{
			"open": 523.49,
			"high": 523.51,
			"low": 523.44,
			"close": 523.475,
			"volume": 45373,
			"datetime": 1712248920000
		},
		{
			"open": 523.47,
			"high": 523.47,
			"low": 523.41,
			"close": 523.42,
			"volume": 38607,
			"datetime": 1712248980000
		},
		{
			"open": 523.43,
			"high": 523.49,
			"low": 523.43,
			"close": 523.485,
			"volume": 42937,
			"datetime": 1712249040000
		},
		{
			"open": 523.48,
			"high": 523.51,
			"low": 523.445,
			"close": 523.5,
			"volume": 23027,
			"datetime": 1712249100000
		},
		{
			"open": 523.5,
			"high": 523.57,
			"low": 523.5,
			"close": 523.5599,
			"volume": 47721,
			"datetime": 1712249160000
		},
		{
			"open": 523.555,
			"high": 523.5699,
			"low": 523.51,
			"close": 523.54,
			"volume": 18749,
			"datetime": 1712249220000
		},
		{
			"open": 523.54,
			"high": 523.55,
			"low": 523.485,
			"close": 523.485,
			"volume": 27951,
			"datetime": 1712249280000
		},
		{
			"open": 523.49,
			"high": 523.505,
			"low": 523.47,
			"close": 523.49,
			"volume": 27052,
			"datetime": 1712249340000
		},
		{
			"open": 523.485,
			"high": 523.5499,
			"low": 523.475,
			"close": 523.53,
			"volume": 44850,
			"datetime": 1712249400000
		},
		{
			"open": 523.53,
			"high": 523.56,
			"low": 523.505,
			"close": 523.52,
			"volume": 19939,
			"datetime": 1712249460000
		},
		{
			"open": 523.52,
			"high": 523.61,
			"low": 523.52,
			"close": 523.585,
			"volume": 34692,
			"datetime": 1712249520000
		},
		{
			"open": 523.59,
			"high": 523.595,
			"low": 523.55,
			"close": 523.565,
			"volume": 29102,
			"datetime": 1712249580000
		},
		{
			"open": 523.56,
			"high": 523.62,
			"low": 523.56,
			"close": 523.62,
			"volume": 26001,
			"datetime": 1712249640000
		},
		{
			"open": 523.61,
			"high": 523.62,
			"low": 523.56,
			"close": 523.605,
			"volume": 26107,
			"datetime": 1712249700000
		},
		{
			"open": 523.61,
			"high": 523.62,
			"low": 523.5,
			"close": 523.505,
			"volume": 40294,
			"datetime": 1712249760000
		},
		{
			"open": 523.51,
			"high": 523.51,
			"low": 523.28,
			"close": 523.3,
			"volume": 138713,
			"datetime": 1712249820000
		},
		{
			"open": 523.31,
			"high": 523.42,
			"low": 523.3,
			"close": 523.4001,
			"volume": 76442,
			"datetime": 1712249880000
		},
		{
			"open": 523.41,
			"high": 523.4499,
			"low": 523.385,
			"close": 523.44,
			"volume": 34053,
			"datetime": 1712249940000
		},
		{
			"open": 523.44,
			"high": 523.44,
			"low": 523.365,
			"close": 523.41,
			"volume": 42574,
			"datetime": 1712250000000
		},
		{
			"open": 523.41,
			"high": 523.41,
			"low": 523.2901,
			"close": 523.3,
			"volume": 55440,
			"datetime": 1712250060000
		},
		{
			"open": 523.31,
			"high": 523.3399,
			"low": 523.2,
			"close": 523.25,
			"volume": 67291,
			"datetime": 1712250120000
		},
		{
			"open": 523.25,
			"high": 523.31,
			"low": 523.215,
			"close": 523.3099,
			"volume": 31258,
			"datetime": 1712250180000
		},
		{
			"open": 523.305,
			"high": 523.32,
			"low": 523.24,
			"close": 523.24,
			"volume": 36396,
			"datetime": 1712250240000
		},
		{
			"open": 523.23,
			"high": 523.31,
			"low": 523.23,
			"close": 523.255,
			"volume": 32539,
			"datetime": 1712250300000
		},
		{
			"open": 523.255,
			"high": 523.28,
			"low": 523.22,
			"close": 523.2501,
			"volume": 37420,
			"datetime": 1712250360000
		},
		{
			"open": 523.255,
			"high": 523.335,
			"low": 523.255,
			"close": 523.33,
			"volume": 61248,
			"datetime": 1712250420000
		},
		{
			"open": 523.335,
			"high": 523.37,
			"low": 523.3,
			"close": 523.345,
			"volume": 25443,
			"datetime": 1712250480000
		},
		{
			"open": 523.35,
			"high": 523.4214,
			"low": 523.32,
			"close": 523.4214,
			"volume": 34186,
			"datetime": 1712250540000
		},
		{
			"open": 523.421,
			"high": 523.47,
			"low": 523.36,
			"close": 523.38,
			"volume": 41382,
			"datetime": 1712250600000
		},
		{
			"open": 523.37,
			"high": 523.405,
			"low": 523.345,
			"close": 523.39,
			"volume": 38160,
			"datetime": 1712250660000
		},
		{
			"open": 523.4,
			"high": 523.41,
			"low": 523.35,
			"close": 523.41,
			"volume": 22839,
			"datetime": 1712250720000
		},
		{
			"open": 523.415,
			"high": 523.415,
			"low": 523.355,
			"close": 523.361,
			"volume": 43257,
			"datetime": 1712250780000
		},
		{
			"open": 523.37,
			"high": 523.42,
			"low": 523.32,
			"close": 523.36,
			"volume": 42368,
			"datetime": 1712250840000
		},
		{
			"open": 523.37,
			"high": 523.38,
			"low": 523.3,
			"close": 523.32,
			"volume": 60160,
			"datetime": 1712250900000
		},
		{
			"open": 523.32,
			"high": 523.35,
			"low": 523.31,
			"close": 523.35,
			"volume": 23593,
			"datetime": 1712250960000
		},
		{
			"open": 523.33,
			"high": 523.35,
			"low": 523.25,
			"close": 523.28,
			"volume": 30173,
			"datetime": 1712251020000
		},
		{
			"open": 523.285,
			"high": 523.315,
			"low": 523.27,
			"close": 523.2801,
			"volume": 34956,
			"datetime": 1712251080000
		},
		{
			"open": 523.28,
			"high": 523.3,
			"low": 523.245,
			"close": 523.2901,
			"volume": 26967,
			"datetime": 1712251140000
		},
		{
			"open": 523.28,
			"high": 523.3201,
			"low": 523.2718,
			"close": 523.28,
			"volume": 24402,
			"datetime": 1712251200000
		},
		{
			"open": 523.29,
			"high": 523.29,
			"low": 523.2,
			"close": 523.28,
			"volume": 46863,
			"datetime": 1712251260000
		},
		{
			"open": 523.27,
			"high": 523.28,
			"low": 523.245,
			"close": 523.28,
			"volume": 19523,
			"datetime": 1712251320000
		},
		{
			"open": 523.28,
			"high": 523.305,
			"low": 523.19,
			"close": 523.2,
			"volume": 33777,
			"datetime": 1712251380000
		},
		{
			"open": 523.2,
			"high": 523.25,
			"low": 523.195,
			"close": 523.24,
			"volume": 34711,
			"datetime": 1712251440000
		},
		{
			"open": 523.24,
			"high": 523.2912,
			"low": 523.22,
			"close": 523.28,
			"volume": 75255,
			"datetime": 1712251500000
		},
		{
			"open": 523.28,
			"high": 523.285,
			"low": 523.182,
			"close": 523.2,
			"volume": 70664,
			"datetime": 1712251560000
		},
		{
			"open": 523.21,
			"high": 523.22,
			"low": 523.16,
			"close": 523.215,
			"volume": 26002,
			"datetime": 1712251620000
		},
		{
			"open": 523.21,
			"high": 523.21,
			"low": 523.165,
			"close": 523.18,
			"volume": 25046,
			"datetime": 1712251680000
		},
		{
			"open": 523.1801,
			"high": 523.2,
			"low": 523.155,
			"close": 523.17,
			"volume": 24133,
			"datetime": 1712251740000
		},
		{
			"open": 523.17,
			"high": 523.19,
			"low": 523.13,
			"close": 523.14,
			"volume": 36165,
			"datetime": 1712251800000
		},
		{
			"open": 523.14,
			"high": 523.16,
			"low": 523.12,
			"close": 523.12,
			"volume": 35012,
			"datetime": 1712251860000
		},
		{
			"open": 523.12,
			"high": 523.1299,
			"low": 522.955,
			"close": 523,
			"volume": 84371,
			"datetime": 1712251920000
		},
		{
			"open": 523.0092,
			"high": 523.14,
			"low": 523.0092,
			"close": 523.04,
			"volume": 79810,
			"datetime": 1712251980000
		},
		{
			"open": 523.04,
			"high": 523.055,
			"low": 522.97,
			"close": 523,
			"volume": 31935,
			"datetime": 1712252040000
		},
		{
			"open": 522.9919,
			"high": 523.029,
			"low": 522.97,
			"close": 523.0212,
			"volume": 26959,
			"datetime": 1712252100000
		},
		{
			"open": 523.025,
			"high": 523.08,
			"low": 522.995,
			"close": 523.055,
			"volume": 62276,
			"datetime": 1712252160000
		},
		{
			"open": 523.055,
			"high": 523.1,
			"low": 523.02,
			"close": 523.09,
			"volume": 28645,
			"datetime": 1712252220000
		},
		{
			"open": 523.09,
			"high": 523.1,
			"low": 523.015,
			"close": 523.04,
			"volume": 45391,
			"datetime": 1712252280000
		},
		{
			"open": 523.03,
			"high": 523.06,
			"low": 522.96,
			"close": 522.98,
			"volume": 38719,
			"datetime": 1712252340000
		},
		{
			"open": 522.98,
			"high": 522.985,
			"low": 522.91,
			"close": 522.91,
			"volume": 46754,
			"datetime": 1712252400000
		},
		{
			"open": 522.91,
			"high": 522.96,
			"low": 522.74,
			"close": 522.7401,
			"volume": 81820,
			"datetime": 1712252460000
		},
		{
			"open": 522.75,
			"high": 522.785,
			"low": 522.67,
			"close": 522.675,
			"volume": 156396,
			"datetime": 1712252520000
		},
		{
			"open": 522.68,
			"high": 522.72,
			"low": 522.5,
			"close": 522.5,
			"volume": 118926,
			"datetime": 1712252580000
		},
		{
			"open": 522.51,
			"high": 522.68,
			"low": 522.5,
			"close": 522.655,
			"volume": 115726,
			"datetime": 1712252640000
		},
		{
			"open": 522.65,
			"high": 522.667,
			"low": 522.52,
			"close": 522.665,
			"volume": 113061,
			"datetime": 1712252700000
		},
		{
			"open": 522.66,
			"high": 522.69,
			"low": 522.545,
			"close": 522.56,
			"volume": 67521,
			"datetime": 1712252760000
		},
		{
			"open": 522.56,
			"high": 522.56,
			"low": 522.235,
			"close": 522.4299,
			"volume": 203935,
			"datetime": 1712252820000
		},
		{
			"open": 522.4201,
			"high": 522.47,
			"low": 522.35,
			"close": 522.43,
			"volume": 74750,
			"datetime": 1712252880000
		},
		{
			"open": 522.42,
			"high": 522.495,
			"low": 522.37,
			"close": 522.465,
			"volume": 76653,
			"datetime": 1712252940000
		},
		{
			"open": 522.46,
			"high": 522.48,
			"low": 522.4,
			"close": 522.47,
			"volume": 123467,
			"datetime": 1712253000000
		},
		{
			"open": 522.45,
			"high": 522.55,
			"low": 522.43,
			"close": 522.51,
			"volume": 78144,
			"datetime": 1712253060000
		},
		{
			"open": 522.51,
			"high": 522.52,
			"low": 522.41,
			"close": 522.41,
			"volume": 119253,
			"datetime": 1712253120000
		},
		{
			"open": 522.4,
			"high": 522.43,
			"low": 522.36,
			"close": 522.4,
			"volume": 110404,
			"datetime": 1712253180000
		},
		{
			"open": 522.4,
			"high": 522.42,
			"low": 522.31,
			"close": 522.34,
			"volume": 102885,
			"datetime": 1712253240000
		},
		{
			"open": 522.34,
			"high": 522.4,
			"low": 522.26,
			"close": 522.395,
			"volume": 103628,
			"datetime": 1712253300000
		},
		{
			"open": 522.3999,
			"high": 522.3999,
			"low": 522.27,
			"close": 522.3,
			"volume": 102519,
			"datetime": 1712253360000
		},
		{
			"open": 522.3,
			"high": 522.34,
			"low": 522.28,
			"close": 522.295,
			"volume": 37073,
			"datetime": 1712253420000
		},
		{
			"open": 522.295,
			"high": 522.3,
			"low": 521.71,
			"close": 521.83,
			"volume": 281594,
			"datetime": 1712253480000
		},
		{
			"open": 521.84,
			"high": 521.95,
			"low": 521.805,
			"close": 521.86,
			"volume": 124234,
			"datetime": 1712253540000
		},
		{
			"open": 521.865,
			"high": 521.865,
			"low": 521.53,
			"close": 521.71,
			"volume": 304085,
			"datetime": 1712253600000
		},
		{
			"open": 521.7,
			"high": 521.7295,
			"low": 521.55,
			"close": 521.56,
			"volume": 117695,
			"datetime": 1712253660000
		},
		{
			"open": 521.55,
			"high": 521.67,
			"low": 521.465,
			"close": 521.48,
			"volume": 188660,
			"datetime": 1712253720000
		},
		{
			"open": 521.48,
			"high": 521.69,
			"low": 521.47,
			"close": 521.619,
			"volume": 193985,
			"datetime": 1712253780000
		},
		{
			"open": 521.61,
			"high": 521.675,
			"low": 521.5,
			"close": 521.5488,
			"volume": 114053,
			"datetime": 1712253840000
		},
		{
			"open": 521.54,
			"high": 521.555,
			"low": 521.345,
			"close": 521.4197,
			"volume": 135407,
			"datetime": 1712253900000
		},
		{
			"open": 521.42,
			"high": 521.4691,
			"low": 521.01,
			"close": 521.09,
			"volume": 393633,
			"datetime": 1712253960000
		},
		{
			"open": 521.08,
			"high": 521.08,
			"low": 520.71,
			"close": 520.97,
			"volume": 437955,
			"datetime": 1712254020000
		},
		{
			"open": 520.97,
			"high": 520.99,
			"low": 520.69,
			"close": 520.815,
			"volume": 183884,
			"datetime": 1712254080000
		},
		{
			"open": 520.8,
			"high": 520.8,
			"low": 520.53,
			"close": 520.56,
			"volume": 257903,
			"datetime": 1712254140000
		},
		{
			"open": 520.565,
			"high": 520.59,
			"low": 519.93,
			"close": 520.08,
			"volume": 488436,
			"datetime": 1712254200000
		},
		{
			"open": 520.08,
			"high": 520.43,
			"low": 519.89,
			"close": 520.39,
			"volume": 441948,
			"datetime": 1712254260000
		},
		{
			"open": 520.37,
			"high": 520.51,
			"low": 520.21,
			"close": 520.29,
			"volume": 285441,
			"datetime": 1712254320000
		},
		{
			"open": 520.29,
			"high": 520.3,
			"low": 520.02,
			"close": 520.14,
			"volume": 410266,
			"datetime": 1712254380000
		},
		{
			"open": 520.1576,
			"high": 520.17,
			"low": 519.76,
			"close": 519.815,
			"volume": 356297,
			"datetime": 1712254440000
		},
		{
			"open": 519.815,
			"high": 519.85,
			"low": 519.54,
			"close": 519.62,
			"volume": 364172,
			"datetime": 1712254500000
		},
		{
			"open": 519.62,
			"high": 519.67,
			"low": 519.3,
			"close": 519.63,
			"volume": 394506,
			"datetime": 1712254560000
		},
		{
			"open": 519.625,
			"high": 519.69,
			"low": 519.38,
			"close": 519.4604,
			"volume": 301269,
			"datetime": 1712254620000
		},
		{
			"open": 519.46,
			"high": 519.46,
			"low": 518.94,
			"close": 519.105,
			"volume": 460899,
			"datetime": 1712254680000
		},
		{
			"open": 519.11,
			"high": 519.2799,
			"low": 518.82,
			"close": 519.07,
			"volume": 426782,
			"datetime": 1712254740000
		},
		{
			"open": 519.06,
			"high": 519.34,
			"low": 518.77,
			"close": 519.2906,
			"volume": 504579,
			"datetime": 1712254800000
		},
		{
			"open": 519.295,
			"high": 519.34,
			"low": 519.0024,
			"close": 519.23,
			"volume": 383346,
			"datetime": 1712254860000
		},
		{
			"open": 519.23,
			"high": 519.43,
			"low": 519.15,
			"close": 519.2301,
			"volume": 271730,
			"datetime": 1712254920000
		},
		{
			"open": 519.24,
			"high": 519.56,
			"low": 519.23,
			"close": 519.5,
			"volume": 371386,
			"datetime": 1712254980000
		},
		{
			"open": 519.5,
			"high": 519.64,
			"low": 519.4199,
			"close": 519.57,
			"volume": 232829,
			"datetime": 1712255040000
		},
		{
			"open": 519.58,
			"high": 519.68,
			"low": 519.53,
			"close": 519.5513,
			"volume": 231050,
			"datetime": 1712255100000
		},
		{
			"open": 519.555,
			"high": 519.58,
			"low": 519.23,
			"close": 519.3,
			"volume": 329049,
			"datetime": 1712255160000
		},
		{
			"open": 519.31,
			"high": 519.31,
			"low": 518.44,
			"close": 518.66,
			"volume": 576898,
			"datetime": 1712255220000
		},
		{
			"open": 518.66,
			"high": 518.69,
			"low": 518.1,
			"close": 518.245,
			"volume": 433314,
			"datetime": 1712255280000
		},
		{
			"open": 518.26,
			"high": 518.29,
			"low": 517.84,
			"close": 518.01,
			"volume": 443855,
			"datetime": 1712255340000
		},
		{
			"open": 518,
			"high": 518,
			"low": 517.07,
			"close": 517.16,
			"volume": 867166,
			"datetime": 1712255400000
		},
		{
			"open": 517.17,
			"high": 517.53,
			"low": 517.06,
			"close": 517.3,
			"volume": 805631,
			"datetime": 1712255460000
		},
		{
			"open": 517.3,
			"high": 517.31,
			"low": 516.37,
			"close": 516.63,
			"volume": 822824,
			"datetime": 1712255520000
		},
		{
			"open": 516.63,
			"high": 517.2,
			"low": 516.62,
			"close": 517.18,
			"volume": 808502,
			"datetime": 1712255580000
		},
		{
			"open": 517.18,
			"high": 517.19,
			"low": 516.5,
			"close": 516.51,
			"volume": 590846,
			"datetime": 1712255640000
		},
		{
			"open": 516.53,
			"high": 517.22,
			"low": 516.52,
			"close": 517.16,
			"volume": 557894,
			"datetime": 1712255700000
		},
		{
			"open": 517.17,
			"high": 517.54,
			"low": 516.96,
			"close": 517.49,
			"volume": 638739,
			"datetime": 1712255760000
		},
		{
			"open": 517.48,
			"high": 517.98,
			"low": 517.43,
			"close": 517.93,
			"volume": 480827,
			"datetime": 1712255820000
		},
		{
			"open": 517.93,
			"high": 518,
			"low": 517.67,
			"close": 517.79,
			"volume": 295309,
			"datetime": 1712255880000
		},
		{
			"open": 517.8,
			"high": 517.92,
			"low": 517.48,
			"close": 517.64,
			"volume": 381416,
			"datetime": 1712255940000
		},
		{
			"open": 517.65,
			"high": 517.65,
			"low": 517.225,
			"close": 517.43,
			"volume": 239387,
			"datetime": 1712256000000
		},
		{
			"open": 517.34,
			"high": 517.41,
			"low": 516.88,
			"close": 517.144,
			"volume": 204250,
			"datetime": 1712256060000
		},
		{
			"open": 517.13,
			"high": 517.45,
			"low": 516.8,
			"close": 516.9301,
			"volume": 348805,
			"datetime": 1712256120000
		},
		{
			"open": 516.93,
			"high": 517.12,
			"low": 516.73,
			"close": 517.0699,
			"volume": 247934,
			"datetime": 1712256180000
		},
		{
			"open": 517.05,
			"high": 517.06,
			"low": 516.09,
			"close": 516.2,
			"volume": 352350,
			"datetime": 1712256240000
		},
		{
			"open": 516.195,
			"high": 517.04,
			"low": 516.19,
			"close": 516.86,
			"volume": 361819,
			"datetime": 1712256300000
		},
		{
			"open": 516.84,
			"high": 517.14,
			"low": 516.79,
			"close": 516.955,
			"volume": 309178,
			"datetime": 1712256360000
		},
		{
			"open": 516.95,
			"high": 517.1221,
			"low": 516.7,
			"close": 516.93,
			"volume": 203925,
			"datetime": 1712256420000
		},
		{
			"open": 516.92,
			"high": 517.08,
			"low": 516.71,
			"close": 516.99,
			"volume": 147450,
			"datetime": 1712256480000
		},
		{
			"open": 517.015,
			"high": 517.22,
			"low": 516.76,
			"close": 517.165,
			"volume": 195739,
			"datetime": 1712256540000
		},
		{
			"open": 517.16,
			"high": 517.16,
			"low": 516.82,
			"close": 517.03,
			"volume": 211883,
			"datetime": 1712256600000
		},
		{
			"open": 517.005,
			"high": 517.23,
			"low": 516.99,
			"close": 517.1825,
			"volume": 214384,
			"datetime": 1712256660000
		},
		{
			"open": 517.18,
			"high": 517.18,
			"low": 516.57,
			"close": 516.62,
			"volume": 275010,
			"datetime": 1712256720000
		},
		{
			"open": 516.615,
			"high": 516.75,
			"low": 516.5,
			"close": 516.68,
			"volume": 164297,
			"datetime": 1712256780000
		},
		{
			"open": 516.67,
			"high": 516.8001,
			"low": 516.41,
			"close": 516.56,
			"volume": 149423,
			"datetime": 1712256840000
		},
		{
			"open": 516.56,
			"high": 516.61,
			"low": 515.57,
			"close": 515.655,
			"volume": 492194,
			"datetime": 1712256900000
		},
		{
			"open": 515.67,
			"high": 516.28,
			"low": 515.67,
			"close": 515.99,
			"volume": 391292,
			"datetime": 1712256960000
		},
		{
			"open": 515.96,
			"high": 516.21,
			"low": 515.84,
			"close": 516.165,
			"volume": 321410,
			"datetime": 1712257020000
		},
		{
			"open": 516.165,
			"high": 516.175,
			"low": 515.53,
			"close": 515.82,
			"volume": 338268,
			"datetime": 1712257080000
		},
		{
			"open": 515.825,
			"high": 515.935,
			"low": 515.5,
			"close": 515.891,
			"volume": 322493,
			"datetime": 1712257140000
		},
		{
			"open": 515.89,
			"high": 515.89,
			"low": 514.92,
			"close": 514.97,
			"volume": 611898,
			"datetime": 1712257200000
		},
		{
			"open": 514.98,
			"high": 515.23,
			"low": 514.85,
			"close": 515.05,
			"volume": 550875,
			"datetime": 1712257260000
		},
		{
			"open": 515.035,
			"high": 515.57,
			"low": 514.92,
			"close": 515.4,
			"volume": 458648,
			"datetime": 1712257320000
		},
		{
			"open": 515.385,
			"high": 515.85,
			"low": 515.37,
			"close": 515.645,
			"volume": 395738,
			"datetime": 1712257380000
		},
		{
			"open": 515.65,
			"high": 515.7,
			"low": 514.92,
			"close": 515.08,
			"volume": 448286,
			"datetime": 1712257440000
		},
		{
			"open": 515.09,
			"high": 515.76,
			"low": 515.09,
			"close": 515.5901,
			"volume": 486687,
			"datetime": 1712257500000
		},
		{
			"open": 515.6,
			"high": 515.68,
			"low": 515.29,
			"close": 515.6301,
			"volume": 287998,
			"datetime": 1712257560000
		},
		{
			"open": 515.62,
			"high": 515.96,
			"low": 515.55,
			"close": 515.65,
			"volume": 394222,
			"datetime": 1712257620000
		},
		{
			"open": 515.66,
			"high": 515.86,
			"low": 515.43,
			"close": 515.71,
			"volume": 245444,
			"datetime": 1712257680000
		},
		{
			"open": 515.69,
			"high": 516.06,
			"low": 515.56,
			"close": 515.8051,
			"volume": 294365,
			"datetime": 1712257740000
		},
		{
			"open": 515.8,
			"high": 515.8,
			"low": 515.195,
			"close": 515.2199,
			"volume": 262183,
			"datetime": 1712257800000
		},
		{
			"open": 515.2,
			"high": 515.49,
			"low": 515.17,
			"close": 515.29,
			"volume": 146018,
			"datetime": 1712257860000
		},
		{
			"open": 515.31,
			"high": 515.45,
			"low": 515.09,
			"close": 515.28,
			"volume": 236463,
			"datetime": 1712257920000
		},
		{
			"open": 515.26,
			"high": 515.4,
			"low": 515.19,
			"close": 515.3299,
			"volume": 257713,
			"datetime": 1712257980000
		},
		{
			"open": 515.33,
			"high": 515.6,
			"low": 515.06,
			"close": 515.465,
			"volume": 206303,
			"datetime": 1712258040000
		},
		{
			"open": 515.46,
			"high": 515.49,
			"low": 514.94,
			"close": 515.15,
			"volume": 243483,
			"datetime": 1712258100000
		},
		{
			"open": 515.1397,
			"high": 515.415,
			"low": 515.03,
			"close": 515.0724,
			"volume": 301800,
			"datetime": 1712258160000
		},
		{
			"open": 515.06,
			"high": 515.22,
			"low": 514.52,
			"close": 514.625,
			"volume": 397489,
			"datetime": 1712258220000
		},
		{
			"open": 514.61,
			"high": 515,
			"low": 514.61,
			"close": 514.915,
			"volume": 246155,
			"datetime": 1712258280000
		},
		{
			"open": 514.92,
			"high": 515.06,
			"low": 514.72,
			"close": 514.74,
			"volume": 278471,
			"datetime": 1712258340000
		},
		{
			"open": 514.73,
			"high": 515.37,
			"low": 514.7,
			"close": 515.14,
			"volume": 313529,
			"datetime": 1712258400000
		},
		{
			"open": 515.145,
			"high": 515.15,
			"low": 514.82,
			"close": 515.0699,
			"volume": 215421,
			"datetime": 1712258460000
		},
		{
			"open": 515.07,
			"high": 515.25,
			"low": 514.75,
			"close": 514.76,
			"volume": 229547,
			"datetime": 1712258520000
		},
		{
			"open": 514.78,
			"high": 514.9978,
			"low": 514.69,
			"close": 514.86,
			"volume": 232911,
			"datetime": 1712258580000
		},
		{
			"open": 514.88,
			"high": 514.9,
			"low": 514.67,
			"close": 514.76,
			"volume": 178231,
			"datetime": 1712258640000
		},
		{
			"open": 514.75,
			"high": 515.01,
			"low": 514.68,
			"close": 514.75,
			"volume": 226006,
			"datetime": 1712258700000
		},
		{
			"open": 514.78,
			"high": 514.78,
			"low": 514.46,
			"close": 514.55,
			"volume": 293081,
			"datetime": 1712258760000
		},
		{
			"open": 514.54,
			"high": 514.625,
			"low": 514.08,
			"close": 514.145,
			"volume": 274690,
			"datetime": 1712258820000
		},
		{
			"open": 514.145,
			"high": 514.25,
			"low": 513.86,
			"close": 513.92,
			"volume": 540820,
			"datetime": 1712258880000
		},
		{
			"open": 513.94,
			"high": 514.06,
			"low": 513.66,
			"close": 513.69,
			"volume": 381065,
			"datetime": 1712258940000
		},
		{
			"open": 513.71,
			"high": 514.22,
			"low": 513.67,
			"close": 513.79,
			"volume": 455116,
			"datetime": 1712259000000
		},
		{
			"open": 513.79,
			"high": 514.02,
			"low": 513.71,
			"close": 513.83,
			"volume": 247930,
			"datetime": 1712259060000
		},
		{
			"open": 513.83,
			"high": 513.965,
			"low": 513.55,
			"close": 513.8,
			"volume": 349568,
			"datetime": 1712259120000
		},
		{
			"open": 513.81,
			"high": 514.15,
			"low": 513.805,
			"close": 513.96,
			"volume": 277824,
			"datetime": 1712259180000
		},
		{
			"open": 513.9499,
			"high": 514.2,
			"low": 513.9,
			"close": 513.98,
			"volume": 251410,
			"datetime": 1712259240000
		},
		{
			"open": 513.98,
			"high": 514.01,
			"low": 513.57,
			"close": 513.695,
			"volume": 376288,
			"datetime": 1712259300000
		},
		{
			"open": 513.7,
			"high": 513.77,
			"low": 513.49,
			"close": 513.58,
			"volume": 432634,
			"datetime": 1712259360000
		},
		{
			"open": 513.58,
			"high": 513.89,
			"low": 513.56,
			"close": 513.66,
			"volume": 320048,
			"datetime": 1712259420000
		},
		{
			"open": 513.6599,
			"high": 513.71,
			"low": 513.3,
			"close": 513.3699,
			"volume": 347173,
			"datetime": 1712259480000
		},
		{
			"open": 513.335,
			"high": 513.43,
			"low": 513.19,
			"close": 513.225,
			"volume": 345190,
			"datetime": 1712259540000
		},
		{
			"open": 513.24,
			"high": 513.5,
			"low": 513.22,
			"close": 513.31,
			"volume": 271234,
			"datetime": 1712259600000
		},
		{
			"open": 513.33,
			"high": 513.34,
			"low": 512.755,
			"close": 512.78,
			"volume": 416784,
			"datetime": 1712259660000
		},
		{
			"open": 512.78,
			"high": 513.21,
			"low": 512.76,
			"close": 512.991,
			"volume": 421055,
			"datetime": 1712259720000
		},
		{
			"open": 513,
			"high": 513.09,
			"low": 512.89,
			"close": 512.9198,
			"volume": 309460,
			"datetime": 1712259780000
		},
		{
			"open": 512.92,
			"high": 513.39,
			"low": 512.87,
			"close": 513.3482,
			"volume": 373213,
			"datetime": 1712259840000
		},
		{
			"open": 513.35,
			"high": 513.74,
			"low": 513.27,
			"close": 513.7,
			"volume": 531238,
			"datetime": 1712259900000
		},
		{
			"open": 513.695,
			"high": 513.83,
			"low": 513.43,
			"close": 513.48,
			"volume": 498308,
			"datetime": 1712259960000
		},
		{
			"open": 513.49,
			"high": 513.67,
			"low": 513.34,
			"close": 513.61,
			"volume": 292920,
			"datetime": 1712260020000
		},
		{
			"open": 513.615,
			"high": 513.7,
			"low": 513.5,
			"close": 513.62,
			"volume": 230869,
			"datetime": 1712260080000
		},
		{
			"open": 513.62,
			"high": 514.03,
			"low": 513.48,
			"close": 513.9799,
			"volume": 512208,
			"datetime": 1712260140000
		},
		{
			"open": 513.97,
			"high": 514.38,
			"low": 513.66,
			"close": 513.71,
			"volume": 1032534,
			"datetime": 1712260200000
		},
		{
			"open": 513.71,
			"high": 513.84,
			"low": 513.53,
			"close": 513.74,
			"volume": 673264,
			"datetime": 1712260260000
		},
		{
			"open": 513.74,
			"high": 514.1,
			"low": 513.685,
			"close": 514,
			"volume": 696104,
			"datetime": 1712260320000
		},
		{
			"open": 514,
			"high": 514.01,
			"low": 513.29,
			"close": 513.45,
			"volume": 668784,
			"datetime": 1712260380000
		},
		{
			"open": 513.45,
			"high": 514.17,
			"low": 513.39,
			"close": 514.16,
			"volume": 1011761,
			"datetime": 1712260440000
		},
		{
			"open": 514.16,
			"high": 514.16,
			"low": 513.79,
			"close": 513.845,
			"volume": 1147185,
			"datetime": 1712260500000
		},
		{
			"open": 513.85,
			"high": 513.88,
			"low": 513.3,
			"close": 513.325,
			"volume": 619089,
			"datetime": 1712260560000
		},
		{
			"open": 513.33,
			"high": 513.41,
			"low": 513.09,
			"close": 513.12,
			"volume": 686933,
			"datetime": 1712260620000
		},
		{
			"open": 513.12,
			"high": 513.24,
			"low": 512.915,
			"close": 512.98,
			"volume": 1019667,
			"datetime": 1712260680000
		},
		{
			"open": 512.98,
			"high": 513.17,
			"low": 512.83,
			"close": 513.03,
			"volume": 3087158,
			"datetime": 1712260740000
		},
		{
			"open": 514.43,
			"high": 514.54,
			"low": 514.01,
			"close": 514.36,
			"volume": 651706,
			"datetime": 1712323800000
		},
		{
			"open": 514.36,
			"high": 514.81,
			"low": 514.24,
			"close": 514.8099,
			"volume": 270074,
			"datetime": 1712323860000
		},
		{
			"open": 514.81,
			"high": 515.18,
			"low": 514.745,
			"close": 514.88,
			"volume": 358815,
			"datetime": 1712323920000
		},
		{
			"open": 514.88,
			"high": 514.9199,
			"low": 514.54,
			"close": 514.75,
			"volume": 217145,
			"datetime": 1712323980000
		},
		{
			"open": 514.76,
			"high": 514.88,
			"low": 514.67,
			"close": 514.788,
			"volume": 197148,
			"datetime": 1712324040000
		},
		{
			"open": 514.78,
			"high": 515.09,
			"low": 514.75,
			"close": 514.81,
			"volume": 220889,
			"datetime": 1712324100000
		},
		{
			"open": 514.8,
			"high": 514.97,
			"low": 514.67,
			"close": 514.67,
			"volume": 154823,
			"datetime": 1712324160000
		},
		{
			"open": 514.66,
			"high": 514.73,
			"low": 514.36,
			"close": 514.36,
			"volume": 287457,
			"datetime": 1712324220000
		},
		{
			"open": 514.37,
			"high": 514.5,
			"low": 514.2,
			"close": 514.33,
			"volume": 159628,
			"datetime": 1712324280000
		},
		{
			"open": 514.33,
			"high": 514.49,
			"low": 514.2,
			"close": 514.21,
			"volume": 111204,
			"datetime": 1712324340000
		},
		{
			"open": 514.2114,
			"high": 514.56,
			"low": 514.06,
			"close": 514.56,
			"volume": 224985,
			"datetime": 1712324400000
		},
		{
			"open": 514.56,
			"high": 514.898,
			"low": 514.56,
			"close": 514.86,
			"volume": 149578,
			"datetime": 1712324460000
		},
		{
			"open": 514.87,
			"high": 515.315,
			"low": 514.87,
			"close": 515.2686,
			"volume": 138789,
			"datetime": 1712324520000
		},
		{
			"open": 515.27,
			"high": 515.52,
			"low": 515.11,
			"close": 515.165,
			"volume": 234051,
			"datetime": 1712324580000
		},
		{
			"open": 515.16,
			"high": 515.46,
			"low": 515.06,
			"close": 515.375,
			"volume": 99672,
			"datetime": 1712324640000
		},
		{
			"open": 515.38,
			"high": 515.44,
			"low": 515.0101,
			"close": 515.18,
			"volume": 161440,
			"datetime": 1712324700000
		},
		{
			"open": 515.19,
			"high": 515.4,
			"low": 515.1,
			"close": 515.395,
			"volume": 150592,
			"datetime": 1712324760000
		},
		{
			"open": 515.3903,
			"high": 515.605,
			"low": 515.3,
			"close": 515.592,
			"volume": 232162,
			"datetime": 1712324820000
		},
		{
			"open": 515.6,
			"high": 515.925,
			"low": 515.39,
			"close": 515.44,
			"volume": 229121,
			"datetime": 1712324880000
		},
		{
			"open": 515.46,
			"high": 515.565,
			"low": 515.3,
			"close": 515.5,
			"volume": 188017,
			"datetime": 1712324940000
		},
		{
			"open": 515.48,
			"high": 515.76,
			"low": 515.4,
			"close": 515.48,
			"volume": 152652,
			"datetime": 1712325000000
		},
		{
			"open": 515.5,
			"high": 515.72,
			"low": 515.46,
			"close": 515.645,
			"volume": 79703,
			"datetime": 1712325060000
		},
		{
			"open": 515.65,
			"high": 516.11,
			"low": 515.6,
			"close": 516.03,
			"volume": 329940,
			"datetime": 1712325120000
		},
		{
			"open": 516.03,
			"high": 516.13,
			"low": 515.79,
			"close": 515.97,
			"volume": 292238,
			"datetime": 1712325180000
		},
		{
			"open": 515.97,
			"high": 516.26,
			"low": 515.94,
			"close": 516.24,
			"volume": 205374,
			"datetime": 1712325240000
		},
		{
			"open": 516.25,
			"high": 516.29,
			"low": 516.09,
			"close": 516.18,
			"volume": 146437,
			"datetime": 1712325300000
		},
		{
			"open": 516.19,
			"high": 516.36,
			"low": 516.085,
			"close": 516.32,
			"volume": 131209,
			"datetime": 1712325360000
		},
		{
			"open": 516.33,
			"high": 516.4,
			"low": 516.16,
			"close": 516.26,
			"volume": 115305,
			"datetime": 1712325420000
		},
		{
			"open": 516.24,
			"high": 516.3201,
			"low": 515.93,
			"close": 515.97,
			"volume": 155402,
			"datetime": 1712325480000
		},
		{
			"open": 515.96,
			"high": 516,
			"low": 515.81,
			"close": 515.92,
			"volume": 165885,
			"datetime": 1712325540000
		},
		{
			"open": 515.92,
			"high": 516.08,
			"low": 515.85,
			"close": 515.95,
			"volume": 121086,
			"datetime": 1712325600000
		},
		{
			"open": 515.94,
			"high": 516.03,
			"low": 515.68,
			"close": 515.72,
			"volume": 150188,
			"datetime": 1712325660000
		},
		{
			"open": 515.73,
			"high": 515.76,
			"low": 515.55,
			"close": 515.6,
			"volume": 313116,
			"datetime": 1712325720000
		},
		{
			"open": 515.6001,
			"high": 515.67,
			"low": 515.5,
			"close": 515.57,
			"volume": 143341,
			"datetime": 1712325780000
		},
		{
			"open": 515.57,
			"high": 515.83,
			"low": 515.45,
			"close": 515.82,
			"volume": 149694,
			"datetime": 1712325840000
		},
		{
			"open": 515.81,
			"high": 516.25,
			"low": 515.78,
			"close": 516.23,
			"volume": 204214,
			"datetime": 1712325900000
		},
		{
			"open": 516.21,
			"high": 516.26,
			"low": 515.69,
			"close": 515.7,
			"volume": 195630,
			"datetime": 1712325960000
		},
		{
			"open": 515.7,
			"high": 515.73,
			"low": 514.99,
			"close": 515.16,
			"volume": 409222,
			"datetime": 1712326020000
		},
		{
			"open": 515.1576,
			"high": 515.37,
			"low": 515.09,
			"close": 515.165,
			"volume": 203734,
			"datetime": 1712326080000
		},
		{
			"open": 515.19,
			"high": 515.7,
			"low": 515.11,
			"close": 515.67,
			"volume": 206598,
			"datetime": 1712326140000
		},
		{
			"open": 515.6701,
			"high": 515.7299,
			"low": 515.4,
			"close": 515.43,
			"volume": 135663,
			"datetime": 1712326200000
		},
		{
			"open": 515.44,
			"high": 515.72,
			"low": 515.37,
			"close": 515.63,
			"volume": 103981,
			"datetime": 1712326260000
		},
		{
			"open": 515.63,
			"high": 516.42,
			"low": 515.63,
			"close": 516.4,
			"volume": 185636,
			"datetime": 1712326320000
		},
		{
			"open": 516.39,
			"high": 516.43,
			"low": 516.15,
			"close": 516.24,
			"volume": 225349,
			"datetime": 1712326380000
		},
		{
			"open": 516.2,
			"high": 516.22,
			"low": 515.99,
			"close": 516.04,
			"volume": 109114,
			"datetime": 1712326440000
		},
		{
			"open": 516.03,
			"high": 516.25,
			"low": 516.02,
			"close": 516.2,
			"volume": 93567,
			"datetime": 1712326500000
		},
		{
			"open": 516.19,
			"high": 516.2,
			"low": 515.98,
			"close": 515.985,
			"volume": 75148,
			"datetime": 1712326560000
		},
		{
			"open": 515.9799,
			"high": 516.05,
			"low": 515.87,
			"close": 515.9,
			"volume": 136359,
			"datetime": 1712326620000
		},
		{
			"open": 515.9,
			"high": 516.045,
			"low": 515.69,
			"close": 515.72,
			"volume": 88238,
			"datetime": 1712326680000
		},
		{
			"open": 515.7199,
			"high": 515.93,
			"low": 515.7,
			"close": 515.84,
			"volume": 55151,
			"datetime": 1712326740000
		},
		{
			"open": 515.82,
			"high": 516.02,
			"low": 515.77,
			"close": 515.835,
			"volume": 114836,
			"datetime": 1712326800000
		},
		{
			"open": 515.84,
			"high": 515.9,
			"low": 515.69,
			"close": 515.81,
			"volume": 124970,
			"datetime": 1712326860000
		},
		{
			"open": 515.82,
			"high": 515.8801,
			"low": 515.75,
			"close": 515.795,
			"volume": 72154,
			"datetime": 1712326920000
		},
		{
			"open": 515.79,
			"high": 515.84,
			"low": 515.58,
			"close": 515.76,
			"volume": 94365,
			"datetime": 1712326980000
		},
		{
			"open": 515.77,
			"high": 516.035,
			"low": 515.7,
			"close": 515.99,
			"volume": 136201,
			"datetime": 1712327040000
		},
		{
			"open": 515.9698,
			"high": 515.9698,
			"low": 515.46,
			"close": 515.64,
			"volume": 245055,
			"datetime": 1712327100000
		},
		{
			"open": 515.63,
			"high": 515.65,
			"low": 515.2,
			"close": 515.23,
			"volume": 185336,
			"datetime": 1712327160000
		},
		{
			"open": 515.24,
			"high": 515.24,
			"low": 514.89,
			"close": 515,
			"volume": 232759,
			"datetime": 1712327220000
		},
		{
			"open": 515,
			"high": 515.31,
			"low": 515,
			"close": 515.18,
			"volume": 136811,
			"datetime": 1712327280000
		},
		{
			"open": 515.18,
			"high": 515.53,
			"low": 515.091,
			"close": 515.44,
			"volume": 150988,
			"datetime": 1712327340000
		},
		{
			"open": 515.46,
			"high": 515.491,
			"low": 515.19,
			"close": 515.35,
			"volume": 222374,
			"datetime": 1712327400000
		},
		{
			"open": 515.35,
			"high": 515.83,
			"low": 515.27,
			"close": 515.82,
			"volume": 158184,
			"datetime": 1712327460000
		},
		{
			"open": 515.8201,
			"high": 516.04,
			"low": 515.785,
			"close": 515.935,
			"volume": 154664,
			"datetime": 1712327520000
		},
		{
			"open": 515.94,
			"high": 516.1,
			"low": 515.93,
			"close": 516.03,
			"volume": 84146,
			"datetime": 1712327580000
		},
		{
			"open": 516.02,
			"high": 516.1,
			"low": 515.855,
			"close": 515.98,
			"volume": 94402,
			"datetime": 1712327640000
		},
		{
			"open": 515.98,
			"high": 516.03,
			"low": 515.75,
			"close": 515.7601,
			"volume": 166089,
			"datetime": 1712327700000
		},
		{
			"open": 515.77,
			"high": 515.96,
			"low": 515.74,
			"close": 515.93,
			"volume": 84859,
			"datetime": 1712327760000
		},
		{
			"open": 515.955,
			"high": 516.08,
			"low": 515.915,
			"close": 516.01,
			"volume": 114258,
			"datetime": 1712327820000
		},
		{
			"open": 516.01,
			"high": 516.32,
			"low": 515.95,
			"close": 516.32,
			"volume": 114375,
			"datetime": 1712327880000
		},
		{
			"open": 516.32,
			"high": 516.37,
			"low": 515.89,
			"close": 515.92,
			"volume": 118935,
			"datetime": 1712327940000
		},
		{
			"open": 515.93,
			"high": 516.17,
			"low": 515.84,
			"close": 516.16,
			"volume": 88295,
			"datetime": 1712328000000
		},
		{
			"open": 516.16,
			"high": 516.29,
			"low": 516.16,
			"close": 516.29,
			"volume": 77228,
			"datetime": 1712328060000
		},
		{
			"open": 516.28,
			"high": 516.35,
			"low": 516.185,
			"close": 516.21,
			"volume": 80092,
			"datetime": 1712328120000
		},
		{
			"open": 516.22,
			"high": 516.295,
			"low": 516.17,
			"close": 516.22,
			"volume": 69746,
			"datetime": 1712328180000
		},
		{
			"open": 516.23,
			"high": 516.32,
			"low": 516.17,
			"close": 516.205,
			"volume": 120622,
			"datetime": 1712328240000
		},
		{
			"open": 516.2,
			"high": 516.59,
			"low": 516.16,
			"close": 516.455,
			"volume": 193330,
			"datetime": 1712328300000
		},
		{
			"open": 516.445,
			"high": 516.49,
			"low": 516.27,
			"close": 516.33,
			"volume": 116439,
			"datetime": 1712328360000
		},
		{
			"open": 516.32,
			"high": 516.4,
			"low": 516.17,
			"close": 516.4,
			"volume": 85111,
			"datetime": 1712328420000
		},
		{
			"open": 516.41,
			"high": 516.68,
			"low": 516.41,
			"close": 516.53,
			"volume": 103351,
			"datetime": 1712328480000
		},
		{
			"open": 516.545,
			"high": 517.03,
			"low": 516.505,
			"close": 517.03,
			"volume": 183091,
			"datetime": 1712328540000
		},
		{
			"open": 517.005,
			"high": 517.34,
			"low": 516.97,
			"close": 517.33,
			"volume": 210867,
			"datetime": 1712328600000
		},
		{
			"open": 517.33,
			"high": 517.55,
			"low": 517.325,
			"close": 517.39,
			"volume": 283393,
			"datetime": 1712328660000
		},
		{
			"open": 517.39,
			"high": 517.485,
			"low": 517.31,
			"close": 517.365,
			"volume": 165910,
			"datetime": 1712328720000
		},
		{
			"open": 517.36,
			"high": 517.38,
			"low": 517.26,
			"close": 517.27,
			"volume": 199045,
			"datetime": 1712328780000
		},
		{
			"open": 517.27,
			"high": 517.49,
			"low": 517.24,
			"close": 517.45,
			"volume": 87156,
			"datetime": 1712328840000
		},
		{
			"open": 517.45,
			"high": 517.59,
			"low": 517.36,
			"close": 517.56,
			"volume": 73679,
			"datetime": 1712328900000
		},
		{
			"open": 517.555,
			"high": 517.83,
			"low": 517.495,
			"close": 517.798,
			"volume": 166985,
			"datetime": 1712328960000
		},
		{
			"open": 517.8,
			"high": 518.0913,
			"low": 517.8,
			"close": 517.95,
			"volume": 253437,
			"datetime": 1712329020000
		},
		{
			"open": 517.96,
			"high": 518.01,
			"low": 517.86,
			"close": 517.9,
			"volume": 123038,
			"datetime": 1712329080000
		},
		{
			"open": 517.9,
			"high": 518.07,
			"low": 517.86,
			"close": 518.06,
			"volume": 112939,
			"datetime": 1712329140000
		},
		{
			"open": 518.05,
			"high": 518.08,
			"low": 517.75,
			"close": 517.95,
			"volume": 464709,
			"datetime": 1712329200000
		},
		{
			"open": 517.95,
			"high": 518.07,
			"low": 517.65,
			"close": 517.65,
			"volume": 274664,
			"datetime": 1712329260000
		},
		{
			"open": 517.64,
			"high": 517.8,
			"low": 517.58,
			"close": 517.6401,
			"volume": 192121,
			"datetime": 1712329320000
		},
		{
			"open": 517.64,
			"high": 517.72,
			"low": 517.58,
			"close": 517.64,
			"volume": 151498,
			"datetime": 1712329380000
		},
		{
			"open": 517.645,
			"high": 517.685,
			"low": 517.4,
			"close": 517.45,
			"volume": 180403,
			"datetime": 1712329440000
		},
		{
			"open": 517.45,
			"high": 517.485,
			"low": 517.03,
			"close": 517.2806,
			"volume": 233841,
			"datetime": 1712329500000
		},
		{
			"open": 517.29,
			"high": 517.45,
			"low": 517.175,
			"close": 517.34,
			"volume": 134002,
			"datetime": 1712329560000
		},
		{
			"open": 517.33,
			"high": 517.415,
			"low": 517.195,
			"close": 517.321,
			"volume": 99795,
			"datetime": 1712329620000
		},
		{
			"open": 517.32,
			"high": 517.5399,
			"low": 517.29,
			"close": 517.51,
			"volume": 75603,
			"datetime": 1712329680000
		},
		{
			"open": 517.5099,
			"high": 517.58,
			"low": 517.42,
			"close": 517.44,
			"volume": 91995,
			"datetime": 1712329740000
		},
		{
			"open": 517.43,
			"high": 517.56,
			"low": 517.29,
			"close": 517.52,
			"volume": 128751,
			"datetime": 1712329800000
		},
		{
			"open": 517.54,
			"high": 517.68,
			"low": 517.4899,
			"close": 517.55,
			"volume": 113962,
			"datetime": 1712329860000
		},
		{
			"open": 517.55,
			"high": 517.62,
			"low": 517.19,
			"close": 517.2201,
			"volume": 88937,
			"datetime": 1712329920000
		},
		{
			"open": 517.23,
			"high": 517.25,
			"low": 517.07,
			"close": 517.09,
			"volume": 117647,
			"datetime": 1712329980000
		},
		{
			"open": 517.09,
			"high": 517.23,
			"low": 517.08,
			"close": 517.18,
			"volume": 57052,
			"datetime": 1712330040000
		},
		{
			"open": 517.17,
			"high": 517.19,
			"low": 516.79,
			"close": 516.84,
			"volume": 166724,
			"datetime": 1712330100000
		},
		{
			"open": 516.85,
			"high": 516.91,
			"low": 516.43,
			"close": 516.53,
			"volume": 207329,
			"datetime": 1712330160000
		},
		{
			"open": 516.54,
			"high": 516.79,
			"low": 516.53,
			"close": 516.72,
			"volume": 194641,
			"datetime": 1712330220000
		},
		{
			"open": 516.73,
			"high": 516.86,
			"low": 516.68,
			"close": 516.8,
			"volume": 132636,
			"datetime": 1712330280000
		},
		{
			"open": 516.8,
			"high": 517.1,
			"low": 516.795,
			"close": 517.01,
			"volume": 135439,
			"datetime": 1712330340000
		},
		{
			"open": 516.99,
			"high": 517.205,
			"low": 516.98,
			"close": 517.1412,
			"volume": 115047,
			"datetime": 1712330400000
		},
		{
			"open": 517.145,
			"high": 517.17,
			"low": 516.835,
			"close": 517,
			"volume": 127554,
			"datetime": 1712330460000
		},
		{
			"open": 517,
			"high": 517.0686,
			"low": 516.86,
			"close": 516.98,
			"volume": 85800,
			"datetime": 1712330520000
		},
		{
			"open": 516.965,
			"high": 517.16,
			"low": 516.89,
			"close": 517.15,
			"volume": 62496,
			"datetime": 1712330580000
		},
		{
			"open": 517.15,
			"high": 517.34,
			"low": 517.0801,
			"close": 517.29,
			"volume": 68628,
			"datetime": 1712330640000
		},
		{
			"open": 517.29,
			"high": 517.34,
			"low": 517.205,
			"close": 517.26,
			"volume": 71879,
			"datetime": 1712330700000
		},
		{
			"open": 517.29,
			"high": 517.51,
			"low": 517.23,
			"close": 517.36,
			"volume": 87339,
			"datetime": 1712330760000
		},
		{
			"open": 517.37,
			"high": 517.37,
			"low": 517.1412,
			"close": 517.225,
			"volume": 88834,
			"datetime": 1712330820000
		},
		{
			"open": 517.24,
			"high": 517.53,
			"low": 517.16,
			"close": 517.49,
			"volume": 58070,
			"datetime": 1712330880000
		},
		{
			"open": 517.491,
			"high": 517.87,
			"low": 517.46,
			"close": 517.85,
			"volume": 135231,
			"datetime": 1712330940000
		},
		{
			"open": 517.84,
			"high": 517.84,
			"low": 517.67,
			"close": 517.7887,
			"volume": 110453,
			"datetime": 1712331000000
		},
		{
			"open": 517.79,
			"high": 517.85,
			"low": 517.59,
			"close": 517.74,
			"volume": 156109,
			"datetime": 1712331060000
		},
		{
			"open": 517.7299,
			"high": 517.98,
			"low": 517.7,
			"close": 517.925,
			"volume": 159438,
			"datetime": 1712331120000
		},
		{
			"open": 517.92,
			"high": 517.935,
			"low": 517.74,
			"close": 517.78,
			"volume": 114527,
			"datetime": 1712331180000
		},
		{
			"open": 517.78,
			"high": 517.81,
			"low": 517.58,
			"close": 517.62,
			"volume": 86787,
			"datetime": 1712331240000
		},
		{
			"open": 517.62,
			"high": 517.82,
			"low": 517.57,
			"close": 517.76,
			"volume": 117584,
			"datetime": 1712331300000
		},
		{
			"open": 517.77,
			"high": 517.85,
			"low": 517.635,
			"close": 517.8,
			"volume": 75447,
			"datetime": 1712331360000
		},
		{
			"open": 517.79,
			"high": 517.93,
			"low": 517.75,
			"close": 517.9093,
			"volume": 63281,
			"datetime": 1712331420000
		},
		{
			"open": 517.91,
			"high": 518.19,
			"low": 517.91,
			"close": 517.98,
			"volume": 198293,
			"datetime": 1712331480000
		},
		{
			"open": 518,
			"high": 518.13,
			"low": 517.96,
			"close": 518.12,
			"volume": 65456,
			"datetime": 1712331540000
		},
		{
			"open": 518.12,
			"high": 518.47,
			"low": 518.1,
			"close": 518.45,
			"volume": 200846,
			"datetime": 1712331600000
		},
		{
			"open": 518.44,
			"high": 518.54,
			"low": 518.33,
			"close": 518.54,
			"volume": 234688,
			"datetime": 1712331660000
		},
		{
			"open": 518.53,
			"high": 518.74,
			"low": 518.43,
			"close": 518.705,
			"volume": 140906,
			"datetime": 1712331720000
		},
		{
			"open": 518.7,
			"high": 518.95,
			"low": 518.58,
			"close": 518.9493,
			"volume": 189802,
			"datetime": 1712331780000
		},
		{
			"open": 518.95,
			"high": 518.96,
			"low": 518.775,
			"close": 518.85,
			"volume": 95999,
			"datetime": 1712331840000
		},
		{
			"open": 518.86,
			"high": 519.09,
			"low": 518.85,
			"close": 519.01,
			"volume": 143578,
			"datetime": 1712331900000
		},
		{
			"open": 519.02,
			"high": 519.195,
			"low": 518.98,
			"close": 519.06,
			"volume": 123056,
			"datetime": 1712331960000
		},
		{
			"open": 519.05,
			"high": 519.115,
			"low": 518.95,
			"close": 519.01,
			"volume": 123920,
			"datetime": 1712332020000
		},
		{
			"open": 519.01,
			"high": 519.09,
			"low": 518.91,
			"close": 519.08,
			"volume": 89204,
			"datetime": 1712332080000
		},
		{
			"open": 519.08,
			"high": 519.08,
			"low": 518.76,
			"close": 518.8001,
			"volume": 139384,
			"datetime": 1712332140000
		},
		{
			"open": 518.8,
			"high": 519.06,
			"low": 518.77,
			"close": 519.03,
			"volume": 120148,
			"datetime": 1712332200000
		},
		{
			"open": 519.01,
			"high": 519.0513,
			"low": 518.89,
			"close": 518.9681,
			"volume": 60349,
			"datetime": 1712332260000
		},
		{
			"open": 518.96,
			"high": 518.97,
			"low": 518.815,
			"close": 518.93,
			"volume": 100596,
			"datetime": 1712332320000
		},
		{
			"open": 518.939,
			"high": 519.06,
			"low": 518.93,
			"close": 519.02,
			"volume": 82293,
			"datetime": 1712332380000
		},
		{
			"open": 519.025,
			"high": 519.13,
			"low": 518.95,
			"close": 519.085,
			"volume": 63553,
			"datetime": 1712332440000
		},
		{
			"open": 519.09,
			"high": 519.45,
			"low": 519.07,
			"close": 519.42,
			"volume": 227705,
			"datetime": 1712332500000
		},
		{
			"open": 519.4201,
			"high": 519.6498,
			"low": 519.395,
			"close": 519.58,
			"volume": 235242,
			"datetime": 1712332560000
		},
		{
			"open": 519.57,
			"high": 519.72,
			"low": 519.51,
			"close": 519.59,
			"volume": 126473,
			"datetime": 1712332620000
		},
		{
			"open": 519.595,
			"high": 519.62,
			"low": 519.42,
			"close": 519.54,
			"volume": 162392,
			"datetime": 1712332680000
		},
		{
			"open": 519.53,
			"high": 519.53,
			"low": 519.42,
			"close": 519.43,
			"volume": 83391,
			"datetime": 1712332740000
		},
		{
			"open": 519.43,
			"high": 519.729,
			"low": 519.43,
			"close": 519.7197,
			"volume": 178997,
			"datetime": 1712332800000
		},
		{
			"open": 519.72,
			"high": 519.72,
			"low": 519.35,
			"close": 519.43,
			"volume": 106932,
			"datetime": 1712332860000
		},
		{
			"open": 519.42,
			"high": 519.59,
			"low": 519.39,
			"close": 519.525,
			"volume": 91070,
			"datetime": 1712332920000
		},
		{
			"open": 519.53,
			"high": 519.655,
			"low": 519.525,
			"close": 519.57,
			"volume": 81751,
			"datetime": 1712332980000
		},
		{
			"open": 519.58,
			"high": 519.6,
			"low": 519.43,
			"close": 519.48,
			"volume": 51092,
			"datetime": 1712333040000
		},
		{
			"open": 519.49,
			"high": 519.5299,
			"low": 519.39,
			"close": 519.44,
			"volume": 77055,
			"datetime": 1712333100000
		},
		{
			"open": 519.45,
			"high": 519.46,
			"low": 519.24,
			"close": 519.31,
			"volume": 164301,
			"datetime": 1712333160000
		},
		{
			"open": 519.31,
			"high": 519.3582,
			"low": 519.195,
			"close": 519.305,
			"volume": 158155,
			"datetime": 1712333220000
		},
		{
			"open": 519.3005,
			"high": 519.635,
			"low": 519.29,
			"close": 519.63,
			"volume": 99661,
			"datetime": 1712333280000
		},
		{
			"open": 519.63,
			"high": 519.7,
			"low": 519.58,
			"close": 519.62,
			"volume": 78115,
			"datetime": 1712333340000
		},
		{
			"open": 519.6201,
			"high": 519.8365,
			"low": 519.55,
			"close": 519.64,
			"volume": 232531,
			"datetime": 1712333400000
		},
		{
			"open": 519.65,
			"high": 519.735,
			"low": 519.56,
			"close": 519.585,
			"volume": 86349,
			"datetime": 1712333460000
		},
		{
			"open": 519.58,
			"high": 519.69,
			"low": 519.54,
			"close": 519.6,
			"volume": 120529,
			"datetime": 1712333520000
		},
		{
			"open": 519.6001,
			"high": 519.74,
			"low": 519.55,
			"close": 519.67,
			"volume": 99361,
			"datetime": 1712333580000
		},
		{
			"open": 519.67,
			"high": 519.74,
			"low": 519.6,
			"close": 519.67,
			"volume": 83949,
			"datetime": 1712333640000
		},
		{
			"open": 519.675,
			"high": 519.97,
			"low": 519.675,
			"close": 519.96,
			"volume": 184177,
			"datetime": 1712333700000
		},
		{
			"open": 519.96,
			"high": 520.01,
			"low": 519.73,
			"close": 519.81,
			"volume": 222641,
			"datetime": 1712333760000
		},
		{
			"open": 519.81,
			"high": 519.89,
			"low": 519.745,
			"close": 519.79,
			"volume": 118374,
			"datetime": 1712333820000
		},
		{
			"open": 519.79,
			"high": 519.855,
			"low": 519.75,
			"close": 519.81,
			"volume": 94221,
			"datetime": 1712333880000
		},
		{
			"open": 519.81,
			"high": 519.91,
			"low": 519.78,
			"close": 519.83,
			"volume": 92949,
			"datetime": 1712333940000
		},
		{
			"open": 519.83,
			"high": 519.97,
			"low": 519.735,
			"close": 519.77,
			"volume": 135633,
			"datetime": 1712334000000
		},
		{
			"open": 519.76,
			"high": 519.77,
			"low": 519.475,
			"close": 519.49,
			"volume": 168349,
			"datetime": 1712334060000
		},
		{
			"open": 519.485,
			"high": 519.59,
			"low": 519.485,
			"close": 519.59,
			"volume": 143391,
			"datetime": 1712334120000
		},
		{
			"open": 519.6,
			"high": 519.7999,
			"low": 519.6,
			"close": 519.765,
			"volume": 98163,
			"datetime": 1712334180000
		},
		{
			"open": 519.765,
			"high": 520.0099,
			"low": 519.72,
			"close": 519.995,
			"volume": 124298,
			"datetime": 1712334240000
		},
		{
			"open": 519.99,
			"high": 520,
			"low": 519.6,
			"close": 519.67,
			"volume": 122376,
			"datetime": 1712334300000
		},
		{
			"open": 519.6617,
			"high": 519.785,
			"low": 519.66,
			"close": 519.7,
			"volume": 126403,
			"datetime": 1712334360000
		},
		{
			"open": 519.7,
			"high": 519.78,
			"low": 519.66,
			"close": 519.72,
			"volume": 105916,
			"datetime": 1712334420000
		},
		{
			"open": 519.725,
			"high": 519.78,
			"low": 519.64,
			"close": 519.6984,
			"volume": 71591,
			"datetime": 1712334480000
		},
		{
			"open": 519.69,
			"high": 519.73,
			"low": 519.58,
			"close": 519.615,
			"volume": 93779,
			"datetime": 1712334540000
		},
		{
			"open": 519.61,
			"high": 519.69,
			"low": 519.475,
			"close": 519.52,
			"volume": 178019,
			"datetime": 1712334600000
		},
		{
			"open": 519.5176,
			"high": 519.58,
			"low": 519.24,
			"close": 519.27,
			"volume": 216769,
			"datetime": 1712334660000
		},
		{
			"open": 519.2799,
			"high": 519.36,
			"low": 519.115,
			"close": 519.2125,
			"volume": 205331,
			"datetime": 1712334720000
		},
		{
			"open": 519.2125,
			"high": 519.31,
			"low": 519.08,
			"close": 519.305,
			"volume": 146265,
			"datetime": 1712334780000
		},
		{
			"open": 519.32,
			"high": 519.67,
			"low": 519.252,
			"close": 519.52,
			"volume": 141269,
			"datetime": 1712334840000
		},
		{
			"open": 519.52,
			"high": 519.58,
			"low": 519.42,
			"close": 519.5,
			"volume": 99255,
			"datetime": 1712334900000
		},
		{
			"open": 519.52,
			"high": 519.565,
			"low": 519.39,
			"close": 519.44,
			"volume": 123739,
			"datetime": 1712334960000
		},
		{
			"open": 519.44,
			"high": 519.52,
			"low": 519.37,
			"close": 519.49,
			"volume": 100496,
			"datetime": 1712335020000
		},
		{
			"open": 519.49,
			"high": 519.59,
			"low": 519.43,
			"close": 519.54,
			"volume": 98076,
			"datetime": 1712335080000
		},
		{
			"open": 519.55,
			"high": 519.8566,
			"low": 519.54,
			"close": 519.85,
			"volume": 136363,
			"datetime": 1712335140000
		},
		{
			"open": 519.85,
			"high": 519.85,
			"low": 519.67,
			"close": 519.76,
			"volume": 83262,
			"datetime": 1712335200000
		},
		{
			"open": 519.75,
			"high": 519.75,
			"low": 519.541,
			"close": 519.595,
			"volume": 64280,
			"datetime": 1712335260000
		},
		{
			"open": 519.6,
			"high": 519.68,
			"low": 519.46,
			"close": 519.51,
			"volume": 105150,
			"datetime": 1712335320000
		},
		{
			"open": 519.54,
			"high": 519.67,
			"low": 519.5203,
			"close": 519.6,
			"volume": 53127,
			"datetime": 1712335380000
		},
		{
			"open": 519.6,
			"high": 519.61,
			"low": 519.5,
			"close": 519.6,
			"volume": 72019,
			"datetime": 1712335440000
		},
		{
			"open": 519.61,
			"high": 519.699,
			"low": 519.54,
			"close": 519.68,
			"volume": 99399,
			"datetime": 1712335500000
		},
		{
			"open": 519.68,
			"high": 519.78,
			"low": 519.5788,
			"close": 519.65,
			"volume": 83297,
			"datetime": 1712335560000
		},
		{
			"open": 519.64,
			"high": 519.77,
			"low": 519.59,
			"close": 519.63,
			"volume": 56739,
			"datetime": 1712335620000
		},
		{
			"open": 519.62,
			"high": 519.64,
			"low": 519.3912,
			"close": 519.45,
			"volume": 94530,
			"datetime": 1712335680000
		},
		{
			"open": 519.45,
			"high": 519.51,
			"low": 519.37,
			"close": 519.43,
			"volume": 94094,
			"datetime": 1712335740000
		},
		{
			"open": 519.445,
			"high": 519.67,
			"low": 519.4412,
			"close": 519.6486,
			"volume": 91272,
			"datetime": 1712335800000
		},
		{
			"open": 519.64,
			"high": 519.76,
			"low": 519.63,
			"close": 519.68,
			"volume": 89957,
			"datetime": 1712335860000
		},
		{
			"open": 519.675,
			"high": 519.8,
			"low": 519.675,
			"close": 519.795,
			"volume": 79978,
			"datetime": 1712335920000
		},
		{
			"open": 519.795,
			"high": 519.88,
			"low": 519.795,
			"close": 519.81,
			"volume": 79250,
			"datetime": 1712335980000
		},
		{
			"open": 519.82,
			"high": 519.93,
			"low": 519.74,
			"close": 519.77,
			"volume": 95678,
			"datetime": 1712336040000
		},
		{
			"open": 519.77,
			"high": 519.83,
			"low": 519.72,
			"close": 519.78,
			"volume": 114650,
			"datetime": 1712336100000
		},
		{
			"open": 519.78,
			"high": 519.85,
			"low": 519.7102,
			"close": 519.82,
			"volume": 81044,
			"datetime": 1712336160000
		},
		{
			"open": 519.82,
			"high": 520.02,
			"low": 519.82,
			"close": 519.9683,
			"volume": 129110,
			"datetime": 1712336220000
		},
		{
			"open": 519.96,
			"high": 519.975,
			"low": 519.88,
			"close": 519.975,
			"volume": 74623,
			"datetime": 1712336280000
		},
		{
			"open": 519.975,
			"high": 520.44,
			"low": 519.95,
			"close": 520.3398,
			"volume": 298764,
			"datetime": 1712336340000
		},
		{
			"open": 520.33,
			"high": 520.43,
			"low": 520.23,
			"close": 520.3017,
			"volume": 176289,
			"datetime": 1712336400000
		},
		{
			"open": 520.3,
			"high": 520.3004,
			"low": 519.93,
			"close": 519.935,
			"volume": 137159,
			"datetime": 1712336460000
		},
		{
			"open": 519.94,
			"high": 519.96,
			"low": 519.83,
			"close": 519.91,
			"volume": 111419,
			"datetime": 1712336520000
		},
		{
			"open": 519.9198,
			"high": 519.9693,
			"low": 519.8188,
			"close": 519.8188,
			"volume": 79989,
			"datetime": 1712336580000
		},
		{
			"open": 519.81,
			"high": 519.87,
			"low": 519.765,
			"close": 519.86,
			"volume": 77305,
			"datetime": 1712336640000
		},
		{
			"open": 519.85,
			"high": 519.85,
			"low": 519.68,
			"close": 519.73,
			"volume": 114480,
			"datetime": 1712336700000
		},
		{
			"open": 519.72,
			"high": 519.76,
			"low": 519.63,
			"close": 519.68,
			"volume": 93883,
			"datetime": 1712336760000
		},
		{
			"open": 519.68,
			"high": 519.71,
			"low": 519.22,
			"close": 519.27,
			"volume": 267922,
			"datetime": 1712336820000
		},
		{
			"open": 519.27,
			"high": 519.335,
			"low": 519.04,
			"close": 519.18,
			"volume": 160612,
			"datetime": 1712336880000
		},
		{
			"open": 519.18,
			"high": 519.24,
			"low": 519.1,
			"close": 519.16,
			"volume": 73759,
			"datetime": 1712336940000
		},
		{
			"open": 519.16,
			"high": 519.4,
			"low": 519.15,
			"close": 519.23,
			"volume": 126870,
			"datetime": 1712337000000
		},
		{
			"open": 519.23,
			"high": 519.38,
			"low": 519.2,
			"close": 519.285,
			"volume": 107128,
			"datetime": 1712337060000
		},
		{
			"open": 519.28,
			"high": 519.33,
			"low": 519.23,
			"close": 519.27,
			"volume": 99138,
			"datetime": 1712337120000
		},
		{
			"open": 519.27,
			"high": 519.28,
			"low": 518.925,
			"close": 518.93,
			"volume": 141616,
			"datetime": 1712337180000
		},
		{
			"open": 518.92,
			"high": 519.04,
			"low": 518.71,
			"close": 518.82,
			"volume": 169867,
			"datetime": 1712337240000
		},
		{
			"open": 518.82,
			"high": 519.055,
			"low": 518.79,
			"close": 518.84,
			"volume": 98819,
			"datetime": 1712337300000
		},
		{
			"open": 518.835,
			"high": 519.01,
			"low": 518.8,
			"close": 518.93,
			"volume": 97502,
			"datetime": 1712337360000
		},
		{
			"open": 518.94,
			"high": 519.025,
			"low": 518.86,
			"close": 518.91,
			"volume": 80293,
			"datetime": 1712337420000
		},
		{
			"open": 518.905,
			"high": 518.96,
			"low": 518.79,
			"close": 518.95,
			"volume": 124479,
			"datetime": 1712337480000
		},
		{
			"open": 518.95,
			"high": 518.955,
			"low": 518.84,
			"close": 518.86,
			"volume": 75903,
			"datetime": 1712337540000
		},
		{
			"open": 518.86,
			"high": 519.25,
			"low": 518.86,
			"close": 519.24,
			"volume": 119689,
			"datetime": 1712337600000
		},
		{
			"open": 519.25,
			"high": 519.4,
			"low": 519.21,
			"close": 519.22,
			"volume": 88669,
			"datetime": 1712337660000
		},
		{
			"open": 519.25,
			"high": 519.3,
			"low": 519.11,
			"close": 519.13,
			"volume": 62961,
			"datetime": 1712337720000
		},
		{
			"open": 519.141,
			"high": 519.3,
			"low": 519.14,
			"close": 519.26,
			"volume": 56532,
			"datetime": 1712337780000
		},
		{
			"open": 519.26,
			"high": 519.31,
			"low": 519.22,
			"close": 519.28,
			"volume": 39617,
			"datetime": 1712337840000
		},
		{
			"open": 519.28,
			"high": 519.34,
			"low": 519.09,
			"close": 519.17,
			"volume": 81014,
			"datetime": 1712337900000
		},
		{
			"open": 519.14,
			"high": 519.18,
			"low": 519.04,
			"close": 519.05,
			"volume": 96149,
			"datetime": 1712337960000
		},
		{
			"open": 519.03,
			"high": 519.17,
			"low": 519.03,
			"close": 519.12,
			"volume": 52904,
			"datetime": 1712338020000
		},
		{
			"open": 519.13,
			"high": 519.21,
			"low": 519.04,
			"close": 519.13,
			"volume": 58836,
			"datetime": 1712338080000
		},
		{
			"open": 519.12,
			"high": 519.27,
			"low": 519.0799,
			"close": 519.26,
			"volume": 44042,
			"datetime": 1712338140000
		},
		{
			"open": 519.26,
			"high": 519.37,
			"low": 519.2512,
			"close": 519.33,
			"volume": 106179,
			"datetime": 1712338200000
		},
		{
			"open": 519.32,
			"high": 519.375,
			"low": 519.27,
			"close": 519.31,
			"volume": 44073,
			"datetime": 1712338260000
		},
		{
			"open": 519.32,
			"high": 519.45,
			"low": 519.31,
			"close": 519.37,
			"volume": 63333,
			"datetime": 1712338320000
		},
		{
			"open": 519.38,
			"high": 519.51,
			"low": 519.3,
			"close": 519.49,
			"volume": 67383,
			"datetime": 1712338380000
		},
		{
			"open": 519.485,
			"high": 519.595,
			"low": 519.45,
			"close": 519.53,
			"volume": 55536,
			"datetime": 1712338440000
		},
		{
			"open": 519.53,
			"high": 519.54,
			"low": 519.4,
			"close": 519.43,
			"volume": 72143,
			"datetime": 1712338500000
		},
		{
			"open": 519.43,
			"high": 519.455,
			"low": 519.345,
			"close": 519.36,
			"volume": 61209,
			"datetime": 1712338560000
		},
		{
			"open": 519.35,
			"high": 519.415,
			"low": 519.32,
			"close": 519.38,
			"volume": 55690,
			"datetime": 1712338620000
		},
		{
			"open": 519.37,
			"high": 519.46,
			"low": 519.22,
			"close": 519.23,
			"volume": 60848,
			"datetime": 1712338680000
		},
		{
			"open": 519.2188,
			"high": 519.23,
			"low": 519.03,
			"close": 519.04,
			"volume": 99007,
			"datetime": 1712338740000
		},
		{
			"open": 519.05,
			"high": 519.2812,
			"low": 519.0387,
			"close": 519.1,
			"volume": 87482,
			"datetime": 1712338800000
		},
		{
			"open": 519.11,
			"high": 519.11,
			"low": 518.865,
			"close": 518.92,
			"volume": 88883,
			"datetime": 1712338860000
		},
		{
			"open": 518.91,
			"high": 518.98,
			"low": 518.75,
			"close": 518.7675,
			"volume": 61646,
			"datetime": 1712338920000
		},
		{
			"open": 518.76,
			"high": 518.86,
			"low": 518.58,
			"close": 518.61,
			"volume": 122122,
			"datetime": 1712338980000
		},
		{
			"open": 518.62,
			"high": 518.92,
			"low": 518.56,
			"close": 518.92,
			"volume": 141997,
			"datetime": 1712339040000
		},
		{
			"open": 518.925,
			"high": 518.94,
			"low": 518.63,
			"close": 518.7,
			"volume": 104180,
			"datetime": 1712339100000
		},
		{
			"open": 518.7,
			"high": 518.79,
			"low": 518.48,
			"close": 518.59,
			"volume": 125012,
			"datetime": 1712339160000
		},
		{
			"open": 518.6,
			"high": 518.68,
			"low": 518.43,
			"close": 518.675,
			"volume": 97816,
			"datetime": 1712339220000
		},
		{
			"open": 518.67,
			"high": 518.81,
			"low": 518.64,
			"close": 518.65,
			"volume": 105968,
			"datetime": 1712339280000
		},
		{
			"open": 518.65,
			"high": 518.69,
			"low": 518.45,
			"close": 518.63,
			"volume": 104163,
			"datetime": 1712339340000
		},
		{
			"open": 518.62,
			"high": 518.85,
			"low": 518.54,
			"close": 518.7213,
			"volume": 89879,
			"datetime": 1712339400000
		},
		{
			"open": 518.72,
			"high": 518.8,
			"low": 518.6,
			"close": 518.6,
			"volume": 64931,
			"datetime": 1712339460000
		},
		{
			"open": 518.6,
			"high": 518.65,
			"low": 518.23,
			"close": 518.32,
			"volume": 194311,
			"datetime": 1712339520000
		},
		{
			"open": 518.33,
			"high": 518.445,
			"low": 518.23,
			"close": 518.41,
			"volume": 78481,
			"datetime": 1712339580000
		},
		{
			"open": 518.39,
			"high": 518.46,
			"low": 518.2102,
			"close": 518.23,
			"volume": 101660,
			"datetime": 1712339640000
		},
		{
			"open": 518.245,
			"high": 518.31,
			"low": 518.15,
			"close": 518.27,
			"volume": 73805,
			"datetime": 1712339700000
		},
		{
			"open": 518.26,
			"high": 518.3,
			"low": 518.13,
			"close": 518.16,
			"volume": 86449,
			"datetime": 1712339760000
		},
		{
			"open": 518.16,
			"high": 518.165,
			"low": 517.43,
			"close": 517.65,
			"volume": 304819,
			"datetime": 1712339820000
		},
		{
			"open": 517.65,
			"high": 517.97,
			"low": 517.46,
			"close": 517.93,
			"volume": 219350,
			"datetime": 1712339880000
		},
		{
			"open": 517.92,
			"high": 517.95,
			"low": 517.72,
			"close": 517.8099,
			"volume": 126470,
			"datetime": 1712339940000
		},
		{
			"open": 517.8,
			"high": 517.885,
			"low": 517.48,
			"close": 517.5616,
			"volume": 148340,
			"datetime": 1712340000000
		},
		{
			"open": 517.57,
			"high": 517.57,
			"low": 516.97,
			"close": 517.13,
			"volume": 294512,
			"datetime": 1712340060000
		},
		{
			"open": 517.12,
			"high": 517.5039,
			"low": 517.0275,
			"close": 517.38,
			"volume": 246849,
			"datetime": 1712340120000
		},
		{
			"open": 517.35,
			"high": 517.48,
			"low": 517.2512,
			"close": 517.35,
			"volume": 83072,
			"datetime": 1712340180000
		},
		{
			"open": 517.3,
			"high": 517.77,
			"low": 517.29,
			"close": 517.68,
			"volume": 165495,
			"datetime": 1712340240000
		},
		{
			"open": 517.69,
			"high": 517.99,
			"low": 517.63,
			"close": 517.9665,
			"volume": 169288,
			"datetime": 1712340300000
		},
		{
			"open": 517.96,
			"high": 518.14,
			"low": 517.8,
			"close": 518.01,
			"volume": 152884,
			"datetime": 1712340360000
		},
		{
			"open": 517.995,
			"high": 518.77,
			"low": 517.98,
			"close": 518.44,
			"volume": 210320,
			"datetime": 1712340420000
		},
		{
			"open": 518.42,
			"high": 518.68,
			"low": 518.29,
			"close": 518.57,
			"volume": 128989,
			"datetime": 1712340480000
		},
		{
			"open": 518.59,
			"high": 518.92,
			"low": 518.57,
			"close": 518.84,
			"volume": 181479,
			"datetime": 1712340540000
		},
		{
			"open": 518.81,
			"high": 518.95,
			"low": 518.69,
			"close": 518.83,
			"volume": 151696,
			"datetime": 1712340600000
		},
		{
			"open": 518.82,
			"high": 519.04,
			"low": 518.7,
			"close": 518.7424,
			"volume": 189684,
			"datetime": 1712340660000
		},
		{
			"open": 518.7401,
			"high": 518.93,
			"low": 518.31,
			"close": 518.54,
			"volume": 237998,
			"datetime": 1712340720000
		},
		{
			"open": 518.5301,
			"high": 518.75,
			"low": 518.41,
			"close": 518.72,
			"volume": 195834,
			"datetime": 1712340780000
		},
		{
			"open": 518.725,
			"high": 518.79,
			"low": 518.54,
			"close": 518.5588,
			"volume": 74861,
			"datetime": 1712340840000
		},
		{
			"open": 518.55,
			"high": 518.81,
			"low": 518.55,
			"close": 518.71,
			"volume": 73342,
			"datetime": 1712340900000
		},
		{
			"open": 518.68,
			"high": 518.775,
			"low": 518.43,
			"close": 518.495,
			"volume": 86153,
			"datetime": 1712340960000
		},
		{
			"open": 518.51,
			"high": 518.65,
			"low": 518.48,
			"close": 518.59,
			"volume": 59448,
			"datetime": 1712341020000
		},
		{
			"open": 518.6,
			"high": 518.71,
			"low": 518.54,
			"close": 518.67,
			"volume": 78928,
			"datetime": 1712341080000
		},
		{
			"open": 518.65,
			"high": 518.72,
			"low": 518.51,
			"close": 518.62,
			"volume": 57991,
			"datetime": 1712341140000
		},
		{
			"open": 518.62,
			"high": 518.95,
			"low": 518.57,
			"close": 518.915,
			"volume": 96545,
			"datetime": 1712341200000
		},
		{
			"open": 518.918,
			"high": 519.18,
			"low": 518.91,
			"close": 519.13,
			"volume": 158716,
			"datetime": 1712341260000
		},
		{
			"open": 519.12,
			"high": 519.28,
			"low": 519.01,
			"close": 519.04,
			"volume": 144537,
			"datetime": 1712341320000
		},
		{
			"open": 519.08,
			"high": 519.12,
			"low": 518.65,
			"close": 518.68,
			"volume": 121598,
			"datetime": 1712341380000
		},
		{
			"open": 518.69,
			"high": 518.69,
			"low": 518.3,
			"close": 518.32,
			"volume": 125876,
			"datetime": 1712341440000
		},
		{
			"open": 518.32,
			"high": 518.53,
			"low": 518.21,
			"close": 518.41,
			"volume": 114707,
			"datetime": 1712341500000
		},
		{
			"open": 518.43,
			"high": 518.6,
			"low": 518.41,
			"close": 518.56,
			"volume": 71119,
			"datetime": 1712341560000
		},
		{
			"open": 518.56,
			"high": 518.73,
			"low": 518.519,
			"close": 518.53,
			"volume": 121732,
			"datetime": 1712341620000
		},
		{
			"open": 518.52,
			"high": 518.54,
			"low": 518.02,
			"close": 518.055,
			"volume": 93019,
			"datetime": 1712341680000
		},
		{
			"open": 518.07,
			"high": 518.35,
			"low": 517.77,
			"close": 517.79,
			"volume": 129033,
			"datetime": 1712341740000
		},
		{
			"open": 517.8,
			"high": 518.39,
			"low": 517.8,
			"close": 518.05,
			"volume": 204758,
			"datetime": 1712341800000
		},
		{
			"open": 518.03,
			"high": 518.235,
			"low": 517.84,
			"close": 518.13,
			"volume": 131488,
			"datetime": 1712341860000
		},
		{
			"open": 518.13,
			"high": 518.13,
			"low": 517.84,
			"close": 518.04,
			"volume": 73422,
			"datetime": 1712341920000
		},
		{
			"open": 518.05,
			"high": 518.2,
			"low": 518.01,
			"close": 518.15,
			"volume": 71430,
			"datetime": 1712341980000
		},
		{
			"open": 518.12,
			"high": 518.33,
			"low": 518.08,
			"close": 518.1,
			"volume": 82850,
			"datetime": 1712342040000
		},
		{
			"open": 518.14,
			"high": 518.36,
			"low": 517.985,
			"close": 518.24,
			"volume": 91455,
			"datetime": 1712342100000
		},
		{
			"open": 518.23,
			"high": 518.51,
			"low": 518.22,
			"close": 518.44,
			"volume": 102936,
			"datetime": 1712342160000
		},
		{
			"open": 518.41,
			"high": 518.71,
			"low": 518.39,
			"close": 518.39,
			"volume": 108470,
			"datetime": 1712342220000
		},
		{
			"open": 518.38,
			"high": 518.55,
			"low": 518.3,
			"close": 518.38,
			"volume": 91183,
			"datetime": 1712342280000
		},
		{
			"open": 518.37,
			"high": 518.59,
			"low": 518.275,
			"close": 518.48,
			"volume": 69900,
			"datetime": 1712342340000
		},
		{
			"open": 518.49,
			"high": 518.69,
			"low": 518.48,
			"close": 518.48,
			"volume": 68255,
			"datetime": 1712342400000
		},
		{
			"open": 518.475,
			"high": 518.475,
			"low": 517.94,
			"close": 518.15,
			"volume": 128386,
			"datetime": 1712342460000
		},
		{
			"open": 518.15,
			"high": 518.25,
			"low": 517.99,
			"close": 517.99,
			"volume": 82802,
			"datetime": 1712342520000
		},
		{
			"open": 518,
			"high": 518.06,
			"low": 517.7503,
			"close": 517.98,
			"volume": 104482,
			"datetime": 1712342580000
		},
		{
			"open": 517.9799,
			"high": 518.5,
			"low": 517.88,
			"close": 518.2,
			"volume": 141934,
			"datetime": 1712342640000
		},
		{
			"open": 518.2,
			"high": 518.23,
			"low": 517.97,
			"close": 517.995,
			"volume": 117585,
			"datetime": 1712342700000
		},
		{
			"open": 518.01,
			"high": 518.06,
			"low": 517.88,
			"close": 517.91,
			"volume": 82618,
			"datetime": 1712342760000
		},
		{
			"open": 517.9,
			"high": 518,
			"low": 517.57,
			"close": 517.59,
			"volume": 117400,
			"datetime": 1712342820000
		},
		{
			"open": 517.5999,
			"high": 517.9,
			"low": 517.55,
			"close": 517.6,
			"volume": 71848,
			"datetime": 1712342880000
		},
		{
			"open": 517.575,
			"high": 517.6,
			"low": 517.39,
			"close": 517.51,
			"volume": 108698,
			"datetime": 1712342940000
		},
		{
			"open": 517.52,
			"high": 517.84,
			"low": 517.4,
			"close": 517.695,
			"volume": 110583,
			"datetime": 1712343000000
		},
		{
			"open": 517.7,
			"high": 517.7,
			"low": 517.4,
			"close": 517.44,
			"volume": 79822,
			"datetime": 1712343060000
		},
		{
			"open": 517.44,
			"high": 517.49,
			"low": 517.36,
			"close": 517.45,
			"volume": 82440,
			"datetime": 1712343120000
		},
		{
			"open": 517.44,
			"high": 517.71,
			"low": 517.31,
			"close": 517.66,
			"volume": 101957,
			"datetime": 1712343180000
		},
		{
			"open": 517.66,
			"high": 517.66,
			"low": 517.52,
			"close": 517.59,
			"volume": 74391,
			"datetime": 1712343240000
		},
		{
			"open": 517.585,
			"high": 517.66,
			"low": 517.35,
			"close": 517.525,
			"volume": 70089,
			"datetime": 1712343300000
		},
		{
			"open": 517.53,
			"high": 517.65,
			"low": 517.365,
			"close": 517.43,
			"volume": 131534,
			"datetime": 1712343360000
		},
		{
			"open": 517.425,
			"high": 517.65,
			"low": 517.24,
			"close": 517.5,
			"volume": 148674,
			"datetime": 1712343420000
		},
		{
			"open": 517.5,
			"high": 517.74,
			"low": 517.5,
			"close": 517.65,
			"volume": 92331,
			"datetime": 1712343480000
		},
		{
			"open": 517.64,
			"high": 517.9,
			"low": 517.565,
			"close": 517.865,
			"volume": 112091,
			"datetime": 1712343540000
		},
		{
			"open": 517.88,
			"high": 518,
			"low": 517.71,
			"close": 517.94,
			"volume": 137904,
			"datetime": 1712343600000
		},
		{
			"open": 517.93,
			"high": 518.12,
			"low": 517.87,
			"close": 518.025,
			"volume": 231687,
			"datetime": 1712343660000
		},
		{
			"open": 518.025,
			"high": 518.08,
			"low": 517.7699,
			"close": 517.85,
			"volume": 119229,
			"datetime": 1712343720000
		},
		{
			"open": 517.87,
			"high": 518.02,
			"low": 517.82,
			"close": 518,
			"volume": 68440,
			"datetime": 1712343780000
		},
		{
			"open": 518,
			"high": 518.09,
			"low": 517.82,
			"close": 518.08,
			"volume": 58625,
			"datetime": 1712343840000
		},
		{
			"open": 518.07,
			"high": 518.25,
			"low": 518.059,
			"close": 518.1699,
			"volume": 149193,
			"datetime": 1712343900000
		},
		{
			"open": 518.17,
			"high": 518.32,
			"low": 518.12,
			"close": 518.26,
			"volume": 143321,
			"datetime": 1712343960000
		},
		{
			"open": 518.26,
			"high": 518.39,
			"low": 518.2,
			"close": 518.27,
			"volume": 114416,
			"datetime": 1712344020000
		},
		{
			"open": 518.27,
			"high": 518.29,
			"low": 517.68,
			"close": 517.86,
			"volume": 161277,
			"datetime": 1712344080000
		},
		{
			"open": 517.87,
			"high": 518.015,
			"low": 517.85,
			"close": 517.95,
			"volume": 80447,
			"datetime": 1712344140000
		},
		{
			"open": 517.94,
			"high": 518.11,
			"low": 517.86,
			"close": 518.105,
			"volume": 100418,
			"datetime": 1712344200000
		},
		{
			"open": 518.12,
			"high": 518.155,
			"low": 517.94,
			"close": 518.025,
			"volume": 83867,
			"datetime": 1712344260000
		},
		{
			"open": 518.02,
			"high": 518.045,
			"low": 517.86,
			"close": 517.93,
			"volume": 116476,
			"datetime": 1712344320000
		},
		{
			"open": 517.93,
			"high": 518.12,
			"low": 517.845,
			"close": 517.99,
			"volume": 85480,
			"datetime": 1712344380000
		},
		{
			"open": 517.98,
			"high": 518.355,
			"low": 517.98,
			"close": 518.31,
			"volume": 72966,
			"datetime": 1712344440000
		},
		{
			"open": 518.3,
			"high": 518.38,
			"low": 518.135,
			"close": 518.1499,
			"volume": 91698,
			"datetime": 1712344500000
		},
		{
			"open": 518.145,
			"high": 518.23,
			"low": 518.07,
			"close": 518.12,
			"volume": 92160,
			"datetime": 1712344560000
		},
		{
			"open": 518.06,
			"high": 518.15,
			"low": 517.91,
			"close": 517.94,
			"volume": 83459,
			"datetime": 1712344620000
		},
		{
			"open": 517.95,
			"high": 518.26,
			"low": 517.95,
			"close": 518.21,
			"volume": 48717,
			"datetime": 1712344680000
		},
		{
			"open": 518.21,
			"high": 518.35,
			"low": 518.19,
			"close": 518.2858,
			"volume": 56134,
			"datetime": 1712344740000
		},
		{
			"open": 518.29,
			"high": 518.325,
			"low": 518.14,
			"close": 518.26,
			"volume": 93221,
			"datetime": 1712344800000
		},
		{
			"open": 518.26,
			"high": 518.27,
			"low": 518.005,
			"close": 518.11,
			"volume": 91211,
			"datetime": 1712344860000
		},
		{
			"open": 518.12,
			"high": 518.27,
			"low": 518.07,
			"close": 518.07,
			"volume": 111632,
			"datetime": 1712344920000
		},
		{
			"open": 518.06,
			"high": 518.16,
			"low": 517.92,
			"close": 517.93,
			"volume": 82905,
			"datetime": 1712344980000
		},
		{
			"open": 517.95,
			"high": 518.115,
			"low": 517.825,
			"close": 518.085,
			"volume": 97408,
			"datetime": 1712345040000
		},
		{
			"open": 518.08,
			"high": 518.19,
			"low": 518.05,
			"close": 518.12,
			"volume": 90266,
			"datetime": 1712345100000
		},
		{
			"open": 518.11,
			"high": 518.17,
			"low": 518.02,
			"close": 518.06,
			"volume": 124703,
			"datetime": 1712345160000
		},
		{
			"open": 518.07,
			"high": 518.12,
			"low": 517.96,
			"close": 517.98,
			"volume": 83318,
			"datetime": 1712345220000
		},
		{
			"open": 517.97,
			"high": 518.29,
			"low": 517.96,
			"close": 518.254,
			"volume": 91882,
			"datetime": 1712345280000
		},
		{
			"open": 518.26,
			"high": 518.26,
			"low": 518.09,
			"close": 518.15,
			"volume": 111739,
			"datetime": 1712345340000
		},
		{
			"open": 518.15,
			"high": 518.19,
			"low": 518.0199,
			"close": 518.115,
			"volume": 136763,
			"datetime": 1712345400000
		},
		{
			"open": 518.14,
			"high": 518.39,
			"low": 518.13,
			"close": 518.36,
			"volume": 133673,
			"datetime": 1712345460000
		},
		{
			"open": 518.38,
			"high": 518.5,
			"low": 518.35,
			"close": 518.45,
			"volume": 175709,
			"datetime": 1712345520000
		},
		{
			"open": 518.445,
			"high": 518.82,
			"low": 518.44,
			"close": 518.82,
			"volume": 273096,
			"datetime": 1712345580000
		},
		{
			"open": 518.8289,
			"high": 519.04,
			"low": 518.78,
			"close": 518.97,
			"volume": 199989,
			"datetime": 1712345640000
		},
		{
			"open": 518.95,
			"high": 518.955,
			"low": 518.56,
			"close": 518.57,
			"volume": 161151,
			"datetime": 1712345700000
		},
		{
			"open": 518.58,
			"high": 518.61,
			"low": 518.52,
			"close": 518.6,
			"volume": 98993,
			"datetime": 1712345760000
		},
		{
			"open": 518.59,
			"high": 518.59,
			"low": 518.17,
			"close": 518.37,
			"volume": 157525,
			"datetime": 1712345820000
		},
		{
			"open": 518.3799,
			"high": 518.4375,
			"low": 518.28,
			"close": 518.35,
			"volume": 90953,
			"datetime": 1712345880000
		},
		{
			"open": 518.35,
			"high": 518.49,
			"low": 518.29,
			"close": 518.44,
			"volume": 84655,
			"datetime": 1712345940000
		},
		{
			"open": 518.43,
			"high": 518.58,
			"low": 518.41,
			"close": 518.57,
			"volume": 91024,
			"datetime": 1712346000000
		},
		{
			"open": 518.575,
			"high": 518.585,
			"low": 518.42,
			"close": 518.45,
			"volume": 96093,
			"datetime": 1712346060000
		},
		{
			"open": 518.45,
			"high": 518.4687,
			"low": 518.14,
			"close": 518.18,
			"volume": 134876,
			"datetime": 1712346120000
		},
		{
			"open": 518.18,
			"high": 518.33,
			"low": 518.14,
			"close": 518.27,
			"volume": 89449,
			"datetime": 1712346180000
		},
		{
			"open": 518.25,
			"high": 518.3101,
			"low": 518.17,
			"close": 518.185,
			"volume": 104899,
			"datetime": 1712346240000
		},
		{
			"open": 518.18,
			"high": 518.41,
			"low": 518.12,
			"close": 518.4,
			"volume": 139638,
			"datetime": 1712346300000
		},
		{
			"open": 518.4,
			"high": 518.64,
			"low": 518.2816,
			"close": 518.62,
			"volume": 172114,
			"datetime": 1712346360000
		},
		{
			"open": 518.61,
			"high": 518.72,
			"low": 518.57,
			"close": 518.65,
			"volume": 312819,
			"datetime": 1712346420000
		},
		{
			"open": 518.65,
			"high": 518.69,
			"low": 518.39,
			"close": 518.4,
			"volume": 176823,
			"datetime": 1712346480000
		},
		{
			"open": 518.4,
			"high": 518.6,
			"low": 518.38,
			"close": 518.46,
			"volume": 197727,
			"datetime": 1712346540000
		},
		{
			"open": 518.47,
			"high": 518.8,
			"low": 518.42,
			"close": 518.67,
			"volume": 317109,
			"datetime": 1712346600000
		},
		{
			"open": 518.66,
			"high": 518.85,
			"low": 518.585,
			"close": 518.795,
			"volume": 300112,
			"datetime": 1712346660000
		},
		{
			"open": 518.8,
			"high": 519.01,
			"low": 518.76,
			"close": 518.925,
			"volume": 233721,
			"datetime": 1712346720000
		},
		{
			"open": 518.92,
			"high": 519.025,
			"low": 518.87,
			"close": 518.945,
			"volume": 157050,
			"datetime": 1712346780000
		},
		{
			"open": 518.94,
			"high": 519.2,
			"low": 518.925,
			"close": 519.1799,
			"volume": 243372,
			"datetime": 1712346840000
		},
		{
			"open": 519.16,
			"high": 519.295,
			"low": 519.02,
			"close": 519.055,
			"volume": 348997,
			"datetime": 1712346900000
		},
		{
			"open": 519.06,
			"high": 519.095,
			"low": 518.78,
			"close": 518.79,
			"volume": 385653,
			"datetime": 1712346960000
		},
		{
			"open": 518.78,
			"high": 518.8486,
			"low": 518.76,
			"close": 518.83,
			"volume": 351379,
			"datetime": 1712347020000
		},
		{
			"open": 518.82,
			"high": 518.85,
			"low": 518.635,
			"close": 518.65,
			"volume": 553884,
			"datetime": 1712347080000
		},
		{
			"open": 518.655,
			"high": 518.835,
			"low": 518.39,
			"close": 518.4,
			"volume": 1805786,
			"datetime": 1712347140000
		},
		{
			"open": 519.15,
			"high": 519.38,
			"low": 518.985,
			"close": 519.03,
			"volume": 469051,
			"datetime": 1712583000000
		},
		{
			"open": 519.03,
			"high": 519.37,
			"low": 518.93,
			"close": 519.34,
			"volume": 216371,
			"datetime": 1712583060000
		},
		{
			"open": 519.34,
			"high": 519.39,
			"low": 519.05,
			"close": 519.125,
			"volume": 122227,
			"datetime": 1712583120000
		},
		{
			"open": 519.15,
			"high": 519.27,
			"low": 519.01,
			"close": 519.03,
			"volume": 140180,
			"datetime": 1712583180000
		},
		{
			"open": 519.07,
			"high": 519.23,
			"low": 518.91,
			"close": 518.965,
			"volume": 115187,
			"datetime": 1712583240000
		},
		{
			"open": 518.97,
			"high": 518.97,
			"low": 518.62,
			"close": 518.635,
			"volume": 159774,
			"datetime": 1712583300000
		},
		{
			"open": 518.63,
			"high": 518.8399,
			"low": 518.61,
			"close": 518.63,
			"volume": 177958,
			"datetime": 1712583360000
		},
		{
			"open": 518.63,
			"high": 518.825,
			"low": 518.63,
			"close": 518.68,
			"volume": 144555,
			"datetime": 1712583420000
		},
		{
			"open": 518.68,
			"high": 518.73,
			"low": 518.43,
			"close": 518.5301,
			"volume": 131356,
			"datetime": 1712583480000
		},
		{
			"open": 518.53,
			"high": 518.59,
			"low": 518.23,
			"close": 518.27,
			"volume": 142273,
			"datetime": 1712583540000
		},
		{
			"open": 518.28,
			"high": 518.31,
			"low": 517.98,
			"close": 518.1313,
			"volume": 152214,
			"datetime": 1712583600000
		},
		{
			"open": 518.14,
			"high": 518.61,
			"low": 518.14,
			"close": 518.51,
			"volume": 183041,
			"datetime": 1712583660000
		},
		{
			"open": 518.51,
			"high": 518.63,
			"low": 518.32,
			"close": 518.6099,
			"volume": 143282,
			"datetime": 1712583720000
		},
		{
			"open": 518.61,
			"high": 518.705,
			"low": 518.55,
			"close": 518.6799,
			"volume": 78955,
			"datetime": 1712583780000
		},
		{
			"open": 518.6999,
			"high": 518.89,
			"low": 518.61,
			"close": 518.68,
			"volume": 121833,
			"datetime": 1712583840000
		},
		{
			"open": 518.7,
			"high": 518.95,
			"low": 518.67,
			"close": 518.935,
			"volume": 158172,
			"datetime": 1712583900000
		},
		{
			"open": 518.93,
			"high": 519.075,
			"low": 518.85,
			"close": 518.95,
			"volume": 197396,
			"datetime": 1712583960000
		},
		{
			"open": 518.96,
			"high": 519.02,
			"low": 518.77,
			"close": 518.77,
			"volume": 102563,
			"datetime": 1712584020000
		},
		{
			"open": 518.76,
			"high": 518.96,
			"low": 518.75,
			"close": 518.95,
			"volume": 83337,
			"datetime": 1712584080000
		},
		{
			"open": 518.94,
			"high": 518.99,
			"low": 518.6717,
			"close": 518.73,
			"volume": 112066,
			"datetime": 1712584140000
		},
		{
			"open": 518.72,
			"high": 518.84,
			"low": 518.44,
			"close": 518.47,
			"volume": 121815,
			"datetime": 1712584200000
		},
		{
			"open": 518.46,
			"high": 518.63,
			"low": 518.46,
			"close": 518.57,
			"volume": 86184,
			"datetime": 1712584260000
		},
		{
			"open": 518.54,
			"high": 518.76,
			"low": 518.52,
			"close": 518.605,
			"volume": 99603,
			"datetime": 1712584320000
		},
		{
			"open": 518.605,
			"high": 518.89,
			"low": 518.57,
			"close": 518.86,
			"volume": 102857,
			"datetime": 1712584380000
		},
		{
			"open": 518.86,
			"high": 518.9199,
			"low": 518.74,
			"close": 518.77,
			"volume": 77615,
			"datetime": 1712584440000
		},
		{
			"open": 518.78,
			"high": 518.83,
			"low": 518.71,
			"close": 518.795,
			"volume": 63471,
			"datetime": 1712584500000
		},
		{
			"open": 518.7997,
			"high": 519.07,
			"low": 518.75,
			"close": 518.935,
			"volume": 95390,
			"datetime": 1712584560000
		},
		{
			"open": 518.94,
			"high": 518.945,
			"low": 518.73,
			"close": 518.84,
			"volume": 68094,
			"datetime": 1712584620000
		},
		{
			"open": 518.84,
			"high": 518.91,
			"low": 518.73,
			"close": 518.86,
			"volume": 65187,
			"datetime": 1712584680000
		},
		{
			"open": 518.87,
			"high": 518.96,
			"low": 518.78,
			"close": 518.83,
			"volume": 42953,
			"datetime": 1712584740000
		},
		{
			"open": 518.84,
			"high": 518.885,
			"low": 518.6399,
			"close": 518.76,
			"volume": 113751,
			"datetime": 1712584800000
		},
		{
			"open": 518.76,
			"high": 518.865,
			"low": 518.6,
			"close": 518.86,
			"volume": 84966,
			"datetime": 1712584860000
		},
		{
			"open": 518.87,
			"high": 518.9,
			"low": 518.54,
			"close": 518.7,
			"volume": 65322,
			"datetime": 1712584920000
		},
		{
			"open": 518.7103,
			"high": 518.7103,
			"low": 518.28,
			"close": 518.37,
			"volume": 107095,
			"datetime": 1712584980000
		},
		{
			"open": 518.37,
			"high": 518.535,
			"low": 518.33,
			"close": 518.46,
			"volume": 66150,
			"datetime": 1712585040000
		},
		{
			"open": 518.46,
			"high": 518.59,
			"low": 518.37,
			"close": 518.52,
			"volume": 82978,
			"datetime": 1712585100000
		},
		{
			"open": 518.54,
			"high": 518.72,
			"low": 518.35,
			"close": 518.42,
			"volume": 103326,
			"datetime": 1712585160000
		},
		{
			"open": 518.4151,
			"high": 518.45,
			"low": 518.2,
			"close": 518.29,
			"volume": 104191,
			"datetime": 1712585220000
		},
		{
			"open": 518.31,
			"high": 518.8097,
			"low": 518.2998,
			"close": 518.78,
			"volume": 103225,
			"datetime": 1712585280000
		},
		{
			"open": 518.8004,
			"high": 518.86,
			"low": 518.6,
			"close": 518.635,
			"volume": 58126,
			"datetime": 1712585340000
		},
		{
			"open": 518.645,
			"high": 518.79,
			"low": 518.63,
			"close": 518.75,
			"volume": 75660,
			"datetime": 1712585400000
		},
		{
			"open": 518.74,
			"high": 518.93,
			"low": 518.7301,
			"close": 518.76,
			"volume": 88003,
			"datetime": 1712585460000
		},
		{
			"open": 518.77,
			"high": 518.81,
			"low": 518.58,
			"close": 518.61,
			"volume": 61174,
			"datetime": 1712585520000
		},
		{
			"open": 518.6,
			"high": 518.655,
			"low": 518.455,
			"close": 518.62,
			"volume": 86812,
			"datetime": 1712585580000
		},
		{
			"open": 518.6,
			"high": 518.8,
			"low": 518.58,
			"close": 518.77,
			"volume": 75256,
			"datetime": 1712585640000
		},
		{
			"open": 518.75,
			"high": 518.98,
			"low": 518.65,
			"close": 518.72,
			"volume": 116400,
			"datetime": 1712585700000
		},
		{
			"open": 518.735,
			"high": 518.75,
			"low": 518.56,
			"close": 518.59,
			"volume": 65617,
			"datetime": 1712585760000
		},
		{
			"open": 518.58,
			"high": 518.76,
			"low": 518.52,
			"close": 518.755,
			"volume": 80731,
			"datetime": 1712585820000
		},
		{
			"open": 518.75,
			"high": 518.95,
			"low": 518.61,
			"close": 518.62,
			"volume": 81826,
			"datetime": 1712585880000
		},
		{
			"open": 518.61,
			"high": 518.6834,
			"low": 518.55,
			"close": 518.67,
			"volume": 82829,
			"datetime": 1712585940000
		},
		{
			"open": 518.69,
			"high": 518.92,
			"low": 518.68,
			"close": 518.9,
			"volume": 78885,
			"datetime": 1712586000000
		},
		{
			"open": 518.89,
			"high": 518.895,
			"low": 518.73,
			"close": 518.76,
			"volume": 48151,
			"datetime": 1712586060000
		},
		{
			"open": 518.78,
			"high": 519.17,
			"low": 518.74,
			"close": 519.0203,
			"volume": 95303,
			"datetime": 1712586120000
		},
		{
			"open": 519.02,
			"high": 519.03,
			"low": 518.9,
			"close": 518.985,
			"volume": 69164,
			"datetime": 1712586180000
		},
		{
			"open": 518.99,
			"high": 519.18,
			"low": 518.9808,
			"close": 519.16,
			"volume": 105165,
			"datetime": 1712586240000
		},
		{
			"open": 519.17,
			"high": 519.52,
			"low": 519.08,
			"close": 519.21,
			"volume": 251023,
			"datetime": 1712586300000
		},
		{
			"open": 519.23,
			"high": 519.4575,
			"low": 519.18,
			"close": 519.4502,
			"volume": 73681,
			"datetime": 1712586360000
		},
		{
			"open": 519.45,
			"high": 519.53,
			"low": 519.34,
			"close": 519.455,
			"volume": 166673,
			"datetime": 1712586420000
		},
		{
			"open": 519.4544,
			"high": 519.54,
			"low": 519.37,
			"close": 519.44,
			"volume": 150549,
			"datetime": 1712586480000
		},
		{
			"open": 519.45,
			"high": 519.58,
			"low": 519.44,
			"close": 519.537,
			"volume": 96875,
			"datetime": 1712586540000
		},
		{
			"open": 519.53,
			"high": 519.82,
			"low": 519.49,
			"close": 519.76,
			"volume": 231331,
			"datetime": 1712586600000
		},
		{
			"open": 519.785,
			"high": 519.8,
			"low": 519.66,
			"close": 519.78,
			"volume": 123999,
			"datetime": 1712586660000
		},
		{
			"open": 519.8,
			"high": 519.89,
			"low": 519.75,
			"close": 519.84,
			"volume": 140630,
			"datetime": 1712586720000
		},
		{
			"open": 519.855,
			"high": 520.12,
			"low": 519.755,
			"close": 519.93,
			"volume": 186712,
			"datetime": 1712586780000
		},
		{
			"open": 519.94,
			"high": 519.955,
			"low": 519.76,
			"close": 519.8,
			"volume": 96560,
			"datetime": 1712586840000
		},
		{
			"open": 519.8,
			"high": 519.92,
			"low": 519.775,
			"close": 519.8516,
			"volume": 131678,
			"datetime": 1712586900000
		},
		{
			"open": 519.86,
			"high": 519.9399,
			"low": 519.79,
			"close": 519.82,
			"volume": 92193,
			"datetime": 1712586960000
		},
		{
			"open": 519.82,
			"high": 519.92,
			"low": 519.75,
			"close": 519.82,
			"volume": 69114,
			"datetime": 1712587020000
		},
		{
			"open": 519.83,
			"high": 519.91,
			"low": 519.83,
			"close": 519.88,
			"volume": 53134,
			"datetime": 1712587080000
		},
		{
			"open": 519.89,
			"high": 519.9,
			"low": 519.82,
			"close": 519.83,
			"volume": 79645,
			"datetime": 1712587140000
		},
		{
			"open": 519.83,
			"high": 519.88,
			"low": 519.77,
			"close": 519.855,
			"volume": 53688,
			"datetime": 1712587200000
		},
		{
			"open": 519.86,
			"high": 519.88,
			"low": 519.62,
			"close": 519.755,
			"volume": 93104,
			"datetime": 1712587260000
		},
		{
			"open": 519.7562,
			"high": 519.8,
			"low": 519.615,
			"close": 519.64,
			"volume": 57967,
			"datetime": 1712587320000
		},
		{
			"open": 519.631,
			"high": 519.6801,
			"low": 519.545,
			"close": 519.64,
			"volume": 73292,
			"datetime": 1712587380000
		},
		{
			"open": 519.625,
			"high": 519.75,
			"low": 519.625,
			"close": 519.725,
			"volume": 75202,
			"datetime": 1712587440000
		},
		{
			"open": 519.74,
			"high": 519.84,
			"low": 519.74,
			"close": 519.78,
			"volume": 55575,
			"datetime": 1712587500000
		},
		{
			"open": 519.78,
			"high": 519.84,
			"low": 519.74,
			"close": 519.79,
			"volume": 49877,
			"datetime": 1712587560000
		},
		{
			"open": 519.7799,
			"high": 519.84,
			"low": 519.705,
			"close": 519.79,
			"volume": 80044,
			"datetime": 1712587620000
		},
		{
			"open": 519.77,
			"high": 519.83,
			"low": 519.72,
			"close": 519.79,
			"volume": 60025,
			"datetime": 1712587680000
		},
		{
			"open": 519.79,
			"high": 519.95,
			"low": 519.78,
			"close": 519.95,
			"volume": 109503,
			"datetime": 1712587740000
		},
		{
			"open": 519.95,
			"high": 520.1694,
			"low": 519.95,
			"close": 520.0215,
			"volume": 162870,
			"datetime": 1712587800000
		},
		{
			"open": 520.02,
			"high": 520.075,
			"low": 519.97,
			"close": 520.045,
			"volume": 61498,
			"datetime": 1712587860000
		},
		{
			"open": 520.0451,
			"high": 520.0451,
			"low": 519.92,
			"close": 519.97,
			"volume": 47820,
			"datetime": 1712587920000
		},
		{
			"open": 519.97,
			"high": 520.065,
			"low": 519.96,
			"close": 519.99,
			"volume": 45835,
			"datetime": 1712587980000
		},
		{
			"open": 519.99,
			"high": 520.14,
			"low": 519.96,
			"close": 520.11,
			"volume": 53243,
			"datetime": 1712588040000
		},
		{
			"open": 520.12,
			"high": 520.18,
			"low": 520.03,
			"close": 520.055,
			"volume": 86337,
			"datetime": 1712588100000
		},
		{
			"open": 520.06,
			"high": 520.08,
			"low": 519.92,
			"close": 519.92,
			"volume": 91106,
			"datetime": 1712588160000
		},
		{
			"open": 519.92,
			"high": 519.99,
			"low": 519.82,
			"close": 519.8516,
			"volume": 76234,
			"datetime": 1712588220000
		},
		{
			"open": 519.86,
			"high": 519.97,
			"low": 519.85,
			"close": 519.9,
			"volume": 89989,
			"datetime": 1712588280000
		},
		{
			"open": 519.89,
			"high": 520.01,
			"low": 519.89,
			"close": 519.99,
			"volume": 72147,
			"datetime": 1712588340000
		},
		{
			"open": 519.99,
			"high": 520.13,
			"low": 519.99,
			"close": 520.07,
			"volume": 78541,
			"datetime": 1712588400000
		},
		{
			"open": 520.09,
			"high": 520.1,
			"low": 519.83,
			"close": 519.8499,
			"volume": 83638,
			"datetime": 1712588460000
		},
		{
			"open": 519.85,
			"high": 519.8686,
			"low": 519.33,
			"close": 519.47,
			"volume": 160956,
			"datetime": 1712588520000
		},
		{
			"open": 519.48,
			"high": 519.74,
			"low": 519.4,
			"close": 519.705,
			"volume": 112843,
			"datetime": 1712588580000
		},
		{
			"open": 519.71,
			"high": 519.83,
			"low": 519.655,
			"close": 519.78,
			"volume": 87229,
			"datetime": 1712588640000
		},
		{
			"open": 519.7724,
			"high": 519.88,
			"low": 519.755,
			"close": 519.76,
			"volume": 67108,
			"datetime": 1712588700000
		},
		{
			"open": 519.765,
			"high": 519.87,
			"low": 519.725,
			"close": 519.798,
			"volume": 57117,
			"datetime": 1712588760000
		},
		{
			"open": 519.78,
			"high": 519.8,
			"low": 519.705,
			"close": 519.77,
			"volume": 41316,
			"datetime": 1712588820000
		},
		{
			"open": 519.77,
			"high": 519.8,
			"low": 519.59,
			"close": 519.66,
			"volume": 58702,
			"datetime": 1712588880000
		},
		{
			"open": 519.64,
			"high": 519.82,
			"low": 519.64,
			"close": 519.76,
			"volume": 34416,
			"datetime": 1712588940000
		},
		{
			"open": 519.79,
			"high": 520.01,
			"low": 519.7687,
			"close": 519.96,
			"volume": 78580,
			"datetime": 1712589000000
		},
		{
			"open": 519.955,
			"high": 519.955,
			"low": 519.87,
			"close": 519.9101,
			"volume": 37444,
			"datetime": 1712589060000
		},
		{
			"open": 519.91,
			"high": 519.96,
			"low": 519.7,
			"close": 519.7,
			"volume": 69349,
			"datetime": 1712589120000
		},
		{
			"open": 519.7,
			"high": 519.78,
			"low": 519.65,
			"close": 519.73,
			"volume": 37200,
			"datetime": 1712589180000
		},
		{
			"open": 519.74,
			"high": 519.8,
			"low": 519.65,
			"close": 519.77,
			"volume": 43199,
			"datetime": 1712589240000
		},
		{
			"open": 519.775,
			"high": 519.82,
			"low": 519.59,
			"close": 519.62,
			"volume": 50714,
			"datetime": 1712589300000
		},
		{
			"open": 519.61,
			"high": 519.69,
			"low": 519.56,
			"close": 519.63,
			"volume": 47565,
			"datetime": 1712589360000
		},
		{
			"open": 519.65,
			"high": 519.687,
			"low": 519.6,
			"close": 519.61,
			"volume": 45953,
			"datetime": 1712589420000
		},
		{
			"open": 519.61,
			"high": 519.75,
			"low": 519.575,
			"close": 519.695,
			"volume": 62771,
			"datetime": 1712589480000
		},
		{
			"open": 519.7,
			"high": 519.7001,
			"low": 519.56,
			"close": 519.62,
			"volume": 32262,
			"datetime": 1712589540000
		},
		{
			"open": 519.61,
			"high": 519.7,
			"low": 519.56,
			"close": 519.6,
			"volume": 34711,
			"datetime": 1712589600000
		},
		{
			"open": 519.595,
			"high": 519.62,
			"low": 519.45,
			"close": 519.615,
			"volume": 50568,
			"datetime": 1712589660000
		},
		{
			"open": 519.64,
			"high": 519.76,
			"low": 519.555,
			"close": 519.72,
			"volume": 54534,
			"datetime": 1712589720000
		},
		{
			"open": 519.72,
			"high": 519.81,
			"low": 519.69,
			"close": 519.79,
			"volume": 50098,
			"datetime": 1712589780000
		},
		{
			"open": 519.79,
			"high": 519.91,
			"low": 519.78,
			"close": 519.865,
			"volume": 63679,
			"datetime": 1712589840000
		},
		{
			"open": 519.86,
			"high": 519.88,
			"low": 519.64,
			"close": 519.65,
			"volume": 57320,
			"datetime": 1712589900000
		},
		{
			"open": 519.6499,
			"high": 519.69,
			"low": 519.595,
			"close": 519.625,
			"volume": 37947,
			"datetime": 1712589960000
		},
		{
			"open": 519.6191,
			"high": 519.6191,
			"low": 519.39,
			"close": 519.39,
			"volume": 64312,
			"datetime": 1712590020000
		},
		{
			"open": 519.3998,
			"high": 519.5079,
			"low": 519.385,
			"close": 519.471,
			"volume": 59483,
			"datetime": 1712590080000
		},
		{
			"open": 519.47,
			"high": 519.59,
			"low": 519.47,
			"close": 519.525,
			"volume": 65360,
			"datetime": 1712590140000
		},
		{
			"open": 519.5,
			"high": 519.51,
			"low": 519.185,
			"close": 519.31,
			"volume": 128699,
			"datetime": 1712590200000
		},
		{
			"open": 519.28,
			"high": 519.375,
			"low": 519.225,
			"close": 519.37,
			"volume": 57380,
			"datetime": 1712590260000
		},
		{
			"open": 519.375,
			"high": 519.42,
			"low": 519.32,
			"close": 519.36,
			"volume": 51342,
			"datetime": 1712590320000
		},
		{
			"open": 519.36,
			"high": 519.469,
			"low": 519.305,
			"close": 519.32,
			"volume": 57267,
			"datetime": 1712590380000
		},
		{
			"open": 519.32,
			"high": 519.36,
			"low": 519.26,
			"close": 519.34,
			"volume": 46914,
			"datetime": 1712590440000
		},
		{
			"open": 519.33,
			"high": 519.35,
			"low": 519.1,
			"close": 519.19,
			"volume": 106024,
			"datetime": 1712590500000
		},
		{
			"open": 519.18,
			"high": 519.18,
			"low": 518.55,
			"close": 518.81,
			"volume": 309408,
			"datetime": 1712590560000
		},
		{
			"open": 518.83,
			"high": 518.93,
			"low": 518.61,
			"close": 518.93,
			"volume": 151922,
			"datetime": 1712590620000
		},
		{
			"open": 518.93,
			"high": 519.01,
			"low": 518.74,
			"close": 518.82,
			"volume": 109821,
			"datetime": 1712590680000
		},
		{
			"open": 518.81,
			"high": 519.15,
			"low": 518.81,
			"close": 519.09,
			"volume": 92262,
			"datetime": 1712590740000
		},
		{
			"open": 519.073,
			"high": 519.13,
			"low": 518.93,
			"close": 518.97,
			"volume": 72051,
			"datetime": 1712590800000
		},
		{
			"open": 518.9697,
			"high": 519.025,
			"low": 518.815,
			"close": 518.92,
			"volume": 40544,
			"datetime": 1712590860000
		},
		{
			"open": 518.92,
			"high": 518.99,
			"low": 518.87,
			"close": 518.94,
			"volume": 34810,
			"datetime": 1712590920000
		},
		{
			"open": 518.945,
			"high": 519.1,
			"low": 518.945,
			"close": 519.01,
			"volume": 55159,
			"datetime": 1712590980000
		},
		{
			"open": 519.01,
			"high": 519.16,
			"low": 519.01,
			"close": 519.04,
			"volume": 88335,
			"datetime": 1712591040000
		},
		{
			"open": 519.04,
			"high": 519.04,
			"low": 518.44,
			"close": 518.66,
			"volume": 226594,
			"datetime": 1712591100000
		},
		{
			"open": 518.665,
			"high": 518.7945,
			"low": 518.59,
			"close": 518.61,
			"volume": 91070,
			"datetime": 1712591160000
		},
		{
			"open": 518.595,
			"high": 518.68,
			"low": 518.53,
			"close": 518.55,
			"volume": 52033,
			"datetime": 1712591220000
		},
		{
			"open": 518.57,
			"high": 518.865,
			"low": 518.5513,
			"close": 518.8,
			"volume": 93471,
			"datetime": 1712591280000
		},
		{
			"open": 518.79,
			"high": 518.8492,
			"low": 518.75,
			"close": 518.8,
			"volume": 44093,
			"datetime": 1712591340000
		},
		{
			"open": 518.79,
			"high": 518.795,
			"low": 518.67,
			"close": 518.76,
			"volume": 55364,
			"datetime": 1712591400000
		},
		{
			"open": 518.76,
			"high": 518.84,
			"low": 518.7,
			"close": 518.78,
			"volume": 70009,
			"datetime": 1712591460000
		},
		{
			"open": 518.78,
			"high": 518.905,
			"low": 518.76,
			"close": 518.81,
			"volume": 73648,
			"datetime": 1712591520000
		},
		{
			"open": 518.81,
			"high": 518.81,
			"low": 518.57,
			"close": 518.62,
			"volume": 65863,
			"datetime": 1712591580000
		},
		{
			"open": 518.64,
			"high": 518.8399,
			"low": 518.64,
			"close": 518.701,
			"volume": 54585,
			"datetime": 1712591640000
		},
		{
			"open": 518.7,
			"high": 518.73,
			"low": 518.47,
			"close": 518.5201,
			"volume": 64722,
			"datetime": 1712591700000
		},
		{
			"open": 518.51,
			"high": 518.56,
			"low": 518.28,
			"close": 518.42,
			"volume": 99684,
			"datetime": 1712591760000
		},
		{
			"open": 518.42,
			"high": 518.76,
			"low": 518.37,
			"close": 518.69,
			"volume": 89023,
			"datetime": 1712591820000
		},
		{
			"open": 518.67,
			"high": 518.69,
			"low": 518.5599,
			"close": 518.68,
			"volume": 56923,
			"datetime": 1712591880000
		},
		{
			"open": 518.68,
			"high": 518.78,
			"low": 518.65,
			"close": 518.73,
			"volume": 42731,
			"datetime": 1712591940000
		},
		{
			"open": 518.73,
			"high": 518.74,
			"low": 518.3903,
			"close": 518.4,
			"volume": 120184,
			"datetime": 1712592000000
		},
		{
			"open": 518.4,
			"high": 518.4975,
			"low": 518.36,
			"close": 518.39,
			"volume": 90604,
			"datetime": 1712592060000
		},
		{
			"open": 518.38,
			"high": 518.5099,
			"low": 518.285,
			"close": 518.46,
			"volume": 55914,
			"datetime": 1712592120000
		},
		{
			"open": 518.45,
			"high": 518.57,
			"low": 518.44,
			"close": 518.54,
			"volume": 56854,
			"datetime": 1712592180000
		},
		{
			"open": 518.525,
			"high": 518.66,
			"low": 518.4301,
			"close": 518.455,
			"volume": 64466,
			"datetime": 1712592240000
		},
		{
			"open": 518.45,
			"high": 518.505,
			"low": 518.37,
			"close": 518.4,
			"volume": 50985,
			"datetime": 1712592300000
		},
		{
			"open": 518.4,
			"high": 518.58,
			"low": 518.36,
			"close": 518.55,
			"volume": 81614,
			"datetime": 1712592360000
		},
		{
			"open": 518.57,
			"high": 518.73,
			"low": 518.56,
			"close": 518.69,
			"volume": 146977,
			"datetime": 1712592420000
		},
		{
			"open": 518.68,
			"high": 518.78,
			"low": 518.65,
			"close": 518.72,
			"volume": 56456,
			"datetime": 1712592480000
		},
		{
			"open": 518.7,
			"high": 518.7,
			"low": 518.4801,
			"close": 518.4999,
			"volume": 52975,
			"datetime": 1712592540000
		},
		{
			"open": 518.5,
			"high": 518.58,
			"low": 518.4676,
			"close": 518.545,
			"volume": 47821,
			"datetime": 1712592600000
		},
		{
			"open": 518.54,
			"high": 518.56,
			"low": 518.34,
			"close": 518.3899,
			"volume": 53502,
			"datetime": 1712592660000
		},
		{
			"open": 518.38,
			"high": 518.51,
			"low": 518.355,
			"close": 518.4501,
			"volume": 47924,
			"datetime": 1712592720000
		},
		{
			"open": 518.436,
			"high": 518.436,
			"low": 518.0407,
			"close": 518.12,
			"volume": 80972,
			"datetime": 1712592780000
		},
		{
			"open": 518.12,
			"high": 518.3,
			"low": 518.105,
			"close": 518.2499,
			"volume": 81370,
			"datetime": 1712592840000
		},
		{
			"open": 518.24,
			"high": 518.26,
			"low": 518.11,
			"close": 518.26,
			"volume": 54488,
			"datetime": 1712592900000
		},
		{
			"open": 518.25,
			"high": 518.43,
			"low": 518.25,
			"close": 518.35,
			"volume": 90141,
			"datetime": 1712592960000
		},
		{
			"open": 518.351,
			"high": 518.52,
			"low": 518.34,
			"close": 518.52,
			"volume": 68938,
			"datetime": 1712593020000
		},
		{
			"open": 518.52,
			"high": 518.585,
			"low": 518.47,
			"close": 518.5,
			"volume": 48428,
			"datetime": 1712593080000
		},
		{
			"open": 518.51,
			"high": 518.53,
			"low": 518.3616,
			"close": 518.3616,
			"volume": 35180,
			"datetime": 1712593140000
		},
		{
			"open": 518.37,
			"high": 518.45,
			"low": 518.268,
			"close": 518.4,
			"volume": 33197,
			"datetime": 1712593200000
		},
		{
			"open": 518.39,
			"high": 518.4676,
			"low": 518.35,
			"close": 518.45,
			"volume": 40374,
			"datetime": 1712593260000
		},
		{
			"open": 518.46,
			"high": 518.46,
			"low": 518.23,
			"close": 518.2387,
			"volume": 70739,
			"datetime": 1712593320000
		},
		{
			"open": 518.23,
			"high": 518.23,
			"low": 517.89,
			"close": 518.07,
			"volume": 188300,
			"datetime": 1712593380000
		},
		{
			"open": 518.07,
			"high": 518.1201,
			"low": 517.98,
			"close": 518.0601,
			"volume": 64144,
			"datetime": 1712593440000
		},
		{
			"open": 518.06,
			"high": 518.42,
			"low": 518.06,
			"close": 518.405,
			"volume": 130135,
			"datetime": 1712593500000
		},
		{
			"open": 518.4,
			"high": 518.49,
			"low": 518.335,
			"close": 518.36,
			"volume": 59511,
			"datetime": 1712593560000
		},
		{
			"open": 518.36,
			"high": 518.645,
			"low": 518.36,
			"close": 518.56,
			"volume": 90317,
			"datetime": 1712593620000
		},
		{
			"open": 518.57,
			"high": 518.59,
			"low": 518.47,
			"close": 518.56,
			"volume": 67963,
			"datetime": 1712593680000
		},
		{
			"open": 518.55,
			"high": 518.68,
			"low": 518.55,
			"close": 518.5601,
			"volume": 58646,
			"datetime": 1712593740000
		},
		{
			"open": 518.56,
			"high": 518.72,
			"low": 518.46,
			"close": 518.655,
			"volume": 64432,
			"datetime": 1712593800000
		},
		{
			"open": 518.65,
			"high": 518.75,
			"low": 518.555,
			"close": 518.75,
			"volume": 74755,
			"datetime": 1712593860000
		},
		{
			"open": 518.76,
			"high": 518.83,
			"low": 518.705,
			"close": 518.76,
			"volume": 80839,
			"datetime": 1712593920000
		},
		{
			"open": 518.76,
			"high": 518.83,
			"low": 518.68,
			"close": 518.7,
			"volume": 50652,
			"datetime": 1712593980000
		},
		{
			"open": 518.71,
			"high": 518.875,
			"low": 518.71,
			"close": 518.86,
			"volume": 71888,
			"datetime": 1712594040000
		},
		{
			"open": 518.8599,
			"high": 518.96,
			"low": 518.835,
			"close": 518.9051,
			"volume": 69032,
			"datetime": 1712594100000
		},
		{
			"open": 518.9001,
			"high": 518.94,
			"low": 518.76,
			"close": 518.8,
			"volume": 54883,
			"datetime": 1712594160000
		},
		{
			"open": 518.79,
			"high": 518.87,
			"low": 518.65,
			"close": 518.7,
			"volume": 44424,
			"datetime": 1712594220000
		},
		{
			"open": 518.71,
			"high": 518.8284,
			"low": 518.67,
			"close": 518.82,
			"volume": 32065,
			"datetime": 1712594280000
		},
		{
			"open": 518.8273,
			"high": 518.85,
			"low": 518.72,
			"close": 518.72,
			"volume": 24484,
			"datetime": 1712594340000
		},
		{
			"open": 518.71,
			"high": 518.925,
			"low": 518.69,
			"close": 518.88,
			"volume": 87566,
			"datetime": 1712594400000
		},
		{
			"open": 518.88,
			"high": 518.99,
			"low": 518.87,
			"close": 518.95,
			"volume": 62997,
			"datetime": 1712594460000
		},
		{
			"open": 518.945,
			"high": 519.07,
			"low": 518.915,
			"close": 518.99,
			"volume": 63207,
			"datetime": 1712594520000
		},
		{
			"open": 518.99,
			"high": 519.21,
			"low": 518.98,
			"close": 519.19,
			"volume": 74776,
			"datetime": 1712594580000
		},
		{
			"open": 519.19,
			"high": 519.19,
			"low": 519.03,
			"close": 519.0998,
			"volume": 60584,
			"datetime": 1712594640000
		},
		{
			"open": 519.1,
			"high": 519.19,
			"low": 519.04,
			"close": 519.05,
			"volume": 62669,
			"datetime": 1712594700000
		},
		{
			"open": 519.05,
			"high": 519.1,
			"low": 519.02,
			"close": 519.07,
			"volume": 40815,
			"datetime": 1712594760000
		},
		{
			"open": 519.05,
			"high": 519.1,
			"low": 518.94,
			"close": 518.97,
			"volume": 31482,
			"datetime": 1712594820000
		},
		{
			"open": 518.97,
			"high": 519.05,
			"low": 518.94,
			"close": 518.94,
			"volume": 32074,
			"datetime": 1712594880000
		},
		{
			"open": 518.94,
			"high": 519.04,
			"low": 518.86,
			"close": 518.97,
			"volume": 34707,
			"datetime": 1712594940000
		},
		{
			"open": 518.9699,
			"high": 519.1,
			"low": 518.9699,
			"close": 519.03,
			"volume": 33118,
			"datetime": 1712595000000
		},
		{
			"open": 519.01,
			"high": 519.03,
			"low": 518.95,
			"close": 519.02,
			"volume": 58303,
			"datetime": 1712595060000
		},
		{
			"open": 519.03,
			"high": 519.1,
			"low": 519.03,
			"close": 519.05,
			"volume": 24342,
			"datetime": 1712595120000
		},
		{
			"open": 519.04,
			"high": 519.07,
			"low": 518.92,
			"close": 518.9399,
			"volume": 20159,
			"datetime": 1712595180000
		},
		{
			"open": 518.9399,
			"high": 519.08,
			"low": 518.93,
			"close": 519.06,
			"volume": 32821,
			"datetime": 1712595240000
		},
		{
			"open": 519.07,
			"high": 519.0899,
			"low": 519.005,
			"close": 519.07,
			"volume": 23348,
			"datetime": 1712595300000
		},
		{
			"open": 519.08,
			"high": 519.32,
			"low": 519.06,
			"close": 519.26,
			"volume": 137409,
			"datetime": 1712595360000
		},
		{
			"open": 519.255,
			"high": 519.3587,
			"low": 519.2201,
			"close": 519.3,
			"volume": 29424,
			"datetime": 1712595420000
		},
		{
			"open": 519.31,
			"high": 519.38,
			"low": 519.2,
			"close": 519.24,
			"volume": 55592,
			"datetime": 1712595480000
		},
		{
			"open": 519.24,
			"high": 519.44,
			"low": 519.21,
			"close": 519.44,
			"volume": 77040,
			"datetime": 1712595540000
		},
		{
			"open": 519.43,
			"high": 519.46,
			"low": 519.3612,
			"close": 519.3612,
			"volume": 62406,
			"datetime": 1712595600000
		},
		{
			"open": 519.37,
			"high": 519.45,
			"low": 519.36,
			"close": 519.36,
			"volume": 36087,
			"datetime": 1712595660000
		},
		{
			"open": 519.355,
			"high": 519.39,
			"low": 519.21,
			"close": 519.24,
			"volume": 59202,
			"datetime": 1712595720000
		},
		{
			"open": 519.25,
			"high": 519.28,
			"low": 519.2,
			"close": 519.244,
			"volume": 59592,
			"datetime": 1712595780000
		},
		{
			"open": 519.24,
			"high": 519.28,
			"low": 519.18,
			"close": 519.2001,
			"volume": 27240,
			"datetime": 1712595840000
		},
		{
			"open": 519.21,
			"high": 519.27,
			"low": 519.1201,
			"close": 519.18,
			"volume": 44397,
			"datetime": 1712595900000
		},
		{
			"open": 519.19,
			"high": 519.245,
			"low": 519.08,
			"close": 519.1,
			"volume": 37340,
			"datetime": 1712595960000
		},
		{
			"open": 519.11,
			"high": 519.21,
			"low": 519.076,
			"close": 519.16,
			"volume": 34668,
			"datetime": 1712596020000
		},
		{
			"open": 519.15,
			"high": 519.255,
			"low": 519.0984,
			"close": 519.255,
			"volume": 31114,
			"datetime": 1712596080000
		},
		{
			"open": 519.27,
			"high": 519.27,
			"low": 519.13,
			"close": 519.14,
			"volume": 22451,
			"datetime": 1712596140000
		},
		{
			"open": 519.125,
			"high": 519.2,
			"low": 518.99,
			"close": 519.18,
			"volume": 162746,
			"datetime": 1712596200000
		},
		{
			"open": 519.185,
			"high": 519.22,
			"low": 519.11,
			"close": 519.175,
			"volume": 26575,
			"datetime": 1712596260000
		},
		{
			"open": 519.19,
			"high": 519.205,
			"low": 519.0003,
			"close": 519.06,
			"volume": 31163,
			"datetime": 1712596320000
		},
		{
			"open": 519.06,
			"high": 519.06,
			"low": 518.97,
			"close": 519.05,
			"volume": 50371,
			"datetime": 1712596380000
		},
		{
			"open": 519.0501,
			"high": 519.07,
			"low": 518.99,
			"close": 519.05,
			"volume": 53908,
			"datetime": 1712596440000
		},
		{
			"open": 519.06,
			"high": 519.11,
			"low": 519.0099,
			"close": 519.105,
			"volume": 37735,
			"datetime": 1712596500000
		},
		{
			"open": 519.1,
			"high": 519.13,
			"low": 518.86,
			"close": 518.99,
			"volume": 48823,
			"datetime": 1712596560000
		},
		{
			"open": 518.98,
			"high": 518.9901,
			"low": 518.81,
			"close": 518.84,
			"volume": 54688,
			"datetime": 1712596620000
		},
		{
			"open": 518.83,
			"high": 518.94,
			"low": 518.79,
			"close": 518.9213,
			"volume": 67130,
			"datetime": 1712596680000
		},
		{
			"open": 518.93,
			"high": 519.0598,
			"low": 518.9099,
			"close": 518.995,
			"volume": 31471,
			"datetime": 1712596740000
		},
		{
			"open": 518.9809,
			"high": 519.025,
			"low": 518.8938,
			"close": 518.9005,
			"volume": 23125,
			"datetime": 1712596800000
		},
		{
			"open": 518.9,
			"high": 519.06,
			"low": 518.9,
			"close": 518.925,
			"volume": 39081,
			"datetime": 1712596860000
		},
		{
			"open": 518.92,
			"high": 519.1,
			"low": 518.895,
			"close": 519.09,
			"volume": 35779,
			"datetime": 1712596920000
		},
		{
			"open": 519.09,
			"high": 519.16,
			"low": 519.0499,
			"close": 519.09,
			"volume": 41049,
			"datetime": 1712596980000
		},
		{
			"open": 519.085,
			"high": 519.16,
			"low": 519.07,
			"close": 519.09,
			"volume": 72977,
			"datetime": 1712597040000
		},
		{
			"open": 519.1,
			"high": 519.12,
			"low": 518.9801,
			"close": 519.01,
			"volume": 27155,
			"datetime": 1712597100000
		},
		{
			"open": 519.015,
			"high": 519.02,
			"low": 518.92,
			"close": 518.97,
			"volume": 33099,
			"datetime": 1712597160000
		},
		{
			"open": 518.97,
			"high": 519.105,
			"low": 518.95,
			"close": 519.045,
			"volume": 35491,
			"datetime": 1712597220000
		},
		{
			"open": 519.05,
			"high": 519.15,
			"low": 519.05,
			"close": 519.14,
			"volume": 40068,
			"datetime": 1712597280000
		},
		{
			"open": 519.15,
			"high": 519.28,
			"low": 519.075,
			"close": 519.275,
			"volume": 38744,
			"datetime": 1712597340000
		},
		{
			"open": 519.28,
			"high": 519.285,
			"low": 519.09,
			"close": 519.13,
			"volume": 46922,
			"datetime": 1712597400000
		},
		{
			"open": 519.14,
			"high": 519.175,
			"low": 519.08,
			"close": 519.09,
			"volume": 23185,
			"datetime": 1712597460000
		},
		{
			"open": 519.0999,
			"high": 519.14,
			"low": 519.055,
			"close": 519.0899,
			"volume": 29232,
			"datetime": 1712597520000
		},
		{
			"open": 519.09,
			"high": 519.16,
			"low": 519.07,
			"close": 519.13,
			"volume": 24698,
			"datetime": 1712597580000
		},
		{
			"open": 519.13,
			"high": 519.2,
			"low": 519.1,
			"close": 519.115,
			"volume": 39046,
			"datetime": 1712597640000
		},
		{
			"open": 519.12,
			"high": 519.13,
			"low": 518.98,
			"close": 519.05,
			"volume": 58183,
			"datetime": 1712597700000
		},
		{
			"open": 519.045,
			"high": 519.085,
			"low": 518.84,
			"close": 518.86,
			"volume": 87292,
			"datetime": 1712597760000
		},
		{
			"open": 518.86,
			"high": 518.97,
			"low": 518.86,
			"close": 518.96,
			"volume": 38779,
			"datetime": 1712597820000
		},
		{
			"open": 518.9601,
			"high": 519,
			"low": 518.89,
			"close": 518.96,
			"volume": 31638,
			"datetime": 1712597880000
		},
		{
			"open": 518.95,
			"high": 519,
			"low": 518.9307,
			"close": 518.97,
			"volume": 19722,
			"datetime": 1712597940000
		},
		{
			"open": 518.94,
			"high": 519.0403,
			"low": 518.94,
			"close": 518.99,
			"volume": 29136,
			"datetime": 1712598000000
		},
		{
			"open": 518.98,
			"high": 518.98,
			"low": 518.91,
			"close": 518.955,
			"volume": 21092,
			"datetime": 1712598060000
		},
		{
			"open": 518.96,
			"high": 518.97,
			"low": 518.83,
			"close": 518.885,
			"volume": 38456,
			"datetime": 1712598120000
		},
		{
			"open": 518.87,
			"high": 518.9,
			"low": 518.69,
			"close": 518.72,
			"volume": 87373,
			"datetime": 1712598180000
		},
		{
			"open": 518.72,
			"high": 518.95,
			"low": 518.71,
			"close": 518.905,
			"volume": 116424,
			"datetime": 1712598240000
		},
		{
			"open": 518.905,
			"high": 518.91,
			"low": 518.77,
			"close": 518.83,
			"volume": 40397,
			"datetime": 1712598300000
		},
		{
			"open": 518.825,
			"high": 518.8606,
			"low": 518.73,
			"close": 518.775,
			"volume": 75350,
			"datetime": 1712598360000
		},
		{
			"open": 518.78,
			"high": 518.835,
			"low": 518.7,
			"close": 518.76,
			"volume": 30602,
			"datetime": 1712598420000
		},
		{
			"open": 518.75,
			"high": 518.81,
			"low": 518.65,
			"close": 518.6801,
			"volume": 47233,
			"datetime": 1712598480000
		},
		{
			"open": 518.69,
			"high": 518.81,
			"low": 518.69,
			"close": 518.795,
			"volume": 46694,
			"datetime": 1712598540000
		},
		{
			"open": 518.79,
			"high": 518.81,
			"low": 518.73,
			"close": 518.79,
			"volume": 48489,
			"datetime": 1712598600000
		},
		{
			"open": 518.79,
			"high": 518.9,
			"low": 518.78,
			"close": 518.88,
			"volume": 43583,
			"datetime": 1712598660000
		},
		{
			"open": 518.89,
			"high": 518.99,
			"low": 518.86,
			"close": 518.865,
			"volume": 46677,
			"datetime": 1712598720000
		},
		{
			"open": 518.87,
			"high": 518.97,
			"low": 518.87,
			"close": 518.9401,
			"volume": 36254,
			"datetime": 1712598780000
		},
		{
			"open": 518.945,
			"high": 519.03,
			"low": 518.88,
			"close": 519.0199,
			"volume": 45923,
			"datetime": 1712598840000
		},
		{
			"open": 519.01,
			"high": 519.085,
			"low": 518.99,
			"close": 519.07,
			"volume": 34982,
			"datetime": 1712598900000
		},
		{
			"open": 519.07,
			"high": 519.2,
			"low": 519.042,
			"close": 519.17,
			"volume": 56168,
			"datetime": 1712598960000
		},
		{
			"open": 519.16,
			"high": 519.335,
			"low": 519.16,
			"close": 519.2399,
			"volume": 63963,
			"datetime": 1712599020000
		},
		{
			"open": 519.24,
			"high": 519.4,
			"low": 519.23,
			"close": 519.3812,
			"volume": 61987,
			"datetime": 1712599080000
		},
		{
			"open": 519.385,
			"high": 519.3899,
			"low": 519.26,
			"close": 519.31,
			"volume": 66822,
			"datetime": 1712599140000
		},
		{
			"open": 519.31,
			"high": 519.4,
			"low": 519.31,
			"close": 519.38,
			"volume": 43775,
			"datetime": 1712599200000
		},
		{
			"open": 519.38,
			"high": 519.46,
			"low": 519.22,
			"close": 519.225,
			"volume": 182128,
			"datetime": 1712599260000
		},
		{
			"open": 519.23,
			"high": 519.25,
			"low": 519.15,
			"close": 519.1699,
			"volume": 139803,
			"datetime": 1712599320000
		},
		{
			"open": 519.15,
			"high": 519.245,
			"low": 519.13,
			"close": 519.1615,
			"volume": 41031,
			"datetime": 1712599380000
		},
		{
			"open": 519.165,
			"high": 519.18,
			"low": 518.96,
			"close": 519.05,
			"volume": 109452,
			"datetime": 1712599440000
		},
		{
			"open": 519.045,
			"high": 519.14,
			"low": 519.01,
			"close": 519.115,
			"volume": 50315,
			"datetime": 1712599500000
		},
		{
			"open": 519.12,
			"high": 519.19,
			"low": 519.12,
			"close": 519.135,
			"volume": 40830,
			"datetime": 1712599560000
		},
		{
			"open": 519.14,
			"high": 519.32,
			"low": 519.1399,
			"close": 519.31,
			"volume": 64515,
			"datetime": 1712599620000
		},
		{
			"open": 519.31,
			"high": 519.36,
			"low": 519.2851,
			"close": 519.34,
			"volume": 70201,
			"datetime": 1712599680000
		},
		{
			"open": 519.35,
			"high": 519.41,
			"low": 519.2899,
			"close": 519.41,
			"volume": 54964,
			"datetime": 1712599740000
		},
		{
			"open": 519.405,
			"high": 519.45,
			"low": 519.305,
			"close": 519.36,
			"volume": 39248,
			"datetime": 1712599800000
		},
		{
			"open": 519.36,
			"high": 519.4,
			"low": 519.21,
			"close": 519.2114,
			"volume": 51391,
			"datetime": 1712599860000
		},
		{
			"open": 519.21,
			"high": 519.3,
			"low": 519.18,
			"close": 519.2,
			"volume": 31990,
			"datetime": 1712599920000
		},
		{
			"open": 519.21,
			"high": 519.28,
			"low": 519.18,
			"close": 519.27,
			"volume": 40708,
			"datetime": 1712599980000
		},
		{
			"open": 519.28,
			"high": 519.35,
			"low": 519.25,
			"close": 519.2714,
			"volume": 39493,
			"datetime": 1712600040000
		},
		{
			"open": 519.3,
			"high": 519.48,
			"low": 519.285,
			"close": 519.46,
			"volume": 38705,
			"datetime": 1712600100000
		},
		{
			"open": 519.455,
			"high": 519.4599,
			"low": 519.35,
			"close": 519.4,
			"volume": 41928,
			"datetime": 1712600160000
		},
		{
			"open": 519.38,
			"high": 519.46,
			"low": 519.35,
			"close": 519.4199,
			"volume": 30304,
			"datetime": 1712600220000
		},
		{
			"open": 519.42,
			"high": 519.4399,
			"low": 519.27,
			"close": 519.31,
			"volume": 35666,
			"datetime": 1712600280000
		},
		{
			"open": 519.31,
			"high": 519.3399,
			"low": 519.25,
			"close": 519.27,
			"volume": 19805,
			"datetime": 1712600340000
		},
		{
			"open": 519.27,
			"high": 519.33,
			"low": 519.23,
			"close": 519.236,
			"volume": 52015,
			"datetime": 1712600400000
		},
		{
			"open": 519.235,
			"high": 519.37,
			"low": 519.19,
			"close": 519.33,
			"volume": 51243,
			"datetime": 1712600460000
		},
		{
			"open": 519.35,
			"high": 519.3975,
			"low": 519.135,
			"close": 519.16,
			"volume": 49012,
			"datetime": 1712600520000
		},
		{
			"open": 519.1506,
			"high": 519.27,
			"low": 519.11,
			"close": 519.23,
			"volume": 60228,
			"datetime": 1712600580000
		},
		{
			"open": 519.22,
			"high": 519.29,
			"low": 518.9399,
			"close": 518.99,
			"volume": 64128,
			"datetime": 1712600640000
		},
		{
			"open": 519.01,
			"high": 519.16,
			"low": 518.98,
			"close": 519.16,
			"volume": 37387,
			"datetime": 1712600700000
		},
		{
			"open": 519.17,
			"high": 519.33,
			"low": 519.14,
			"close": 519.32,
			"volume": 52105,
			"datetime": 1712600760000
		},
		{
			"open": 519.3151,
			"high": 519.4101,
			"low": 519.255,
			"close": 519.405,
			"volume": 36063,
			"datetime": 1712600820000
		},
		{
			"open": 519.4,
			"high": 519.4,
			"low": 519.235,
			"close": 519.24,
			"volume": 90152,
			"datetime": 1712600880000
		},
		{
			"open": 519.25,
			"high": 519.274,
			"low": 519.15,
			"close": 519.18,
			"volume": 55447,
			"datetime": 1712600940000
		},
		{
			"open": 519.17,
			"high": 519.1999,
			"low": 519.01,
			"close": 519.055,
			"volume": 55605,
			"datetime": 1712601000000
		},
		{
			"open": 519.059,
			"high": 519.1088,
			"low": 519.0299,
			"close": 519.09,
			"volume": 25965,
			"datetime": 1712601060000
		},
		{
			"open": 519.08,
			"high": 519.15,
			"low": 519.07,
			"close": 519.12,
			"volume": 58014,
			"datetime": 1712601120000
		},
		{
			"open": 519.12,
			"high": 519.12,
			"low": 519,
			"close": 519.06,
			"volume": 42937,
			"datetime": 1712601180000
		},
		{
			"open": 519.07,
			"high": 519.08,
			"low": 519,
			"close": 519.005,
			"volume": 36906,
			"datetime": 1712601240000
		},
		{
			"open": 519,
			"high": 519.04,
			"low": 518.94,
			"close": 518.98,
			"volume": 46146,
			"datetime": 1712601300000
		},
		{
			"open": 518.98,
			"high": 519.01,
			"low": 518.88,
			"close": 518.95,
			"volume": 70977,
			"datetime": 1712601360000
		},
		{
			"open": 518.95,
			"high": 519.05,
			"low": 518.92,
			"close": 519,
			"volume": 47054,
			"datetime": 1712601420000
		},
		{
			"open": 519.01,
			"high": 519.03,
			"low": 518.78,
			"close": 518.86,
			"volume": 73794,
			"datetime": 1712601480000
		},
		{
			"open": 518.86,
			"high": 518.93,
			"low": 518.83,
			"close": 518.88,
			"volume": 37976,
			"datetime": 1712601540000
		},
		{
			"open": 518.8899,
			"high": 518.92,
			"low": 518.8051,
			"close": 518.8299,
			"volume": 36934,
			"datetime": 1712601600000
		},
		{
			"open": 518.825,
			"high": 518.96,
			"low": 518.78,
			"close": 518.865,
			"volume": 50434,
			"datetime": 1712601660000
		},
		{
			"open": 518.875,
			"high": 518.959,
			"low": 518.855,
			"close": 518.88,
			"volume": 33642,
			"datetime": 1712601720000
		},
		{
			"open": 518.89,
			"high": 518.9694,
			"low": 518.89,
			"close": 518.94,
			"volume": 40857,
			"datetime": 1712601780000
		},
		{
			"open": 518.935,
			"high": 518.95,
			"low": 518.86,
			"close": 518.885,
			"volume": 29109,
			"datetime": 1712601840000
		},
		{
			"open": 518.89,
			"high": 518.9,
			"low": 518.79,
			"close": 518.81,
			"volume": 46165,
			"datetime": 1712601900000
		},
		{
			"open": 518.81,
			"high": 518.8301,
			"low": 518.67,
			"close": 518.69,
			"volume": 77275,
			"datetime": 1712601960000
		},
		{
			"open": 518.6899,
			"high": 518.85,
			"low": 518.6699,
			"close": 518.84,
			"volume": 56719,
			"datetime": 1712602020000
		},
		{
			"open": 518.84,
			"high": 518.9665,
			"low": 518.84,
			"close": 518.89,
			"volume": 49316,
			"datetime": 1712602080000
		},
		{
			"open": 518.88,
			"high": 519.065,
			"low": 518.88,
			"close": 519.04,
			"volume": 84213,
			"datetime": 1712602140000
		},
		{
			"open": 519.0303,
			"high": 519.07,
			"low": 518.86,
			"close": 518.88,
			"volume": 84939,
			"datetime": 1712602200000
		},
		{
			"open": 518.88,
			"high": 518.965,
			"low": 518.83,
			"close": 518.88,
			"volume": 46162,
			"datetime": 1712602260000
		},
		{
			"open": 518.88,
			"high": 519.01,
			"low": 518.86,
			"close": 518.99,
			"volume": 42669,
			"datetime": 1712602320000
		},
		{
			"open": 518.99,
			"high": 519.085,
			"low": 518.915,
			"close": 519.08,
			"volume": 33467,
			"datetime": 1712602380000
		},
		{
			"open": 519.0799,
			"high": 519.09,
			"low": 519.01,
			"close": 519.01,
			"volume": 37407,
			"datetime": 1712602440000
		},
		{
			"open": 519.01,
			"high": 519.09,
			"low": 519.01,
			"close": 519.065,
			"volume": 34382,
			"datetime": 1712602500000
		},
		{
			"open": 519.06,
			"high": 519.19,
			"low": 519.0188,
			"close": 519.12,
			"volume": 64214,
			"datetime": 1712602560000
		},
		{
			"open": 519.12,
			"high": 519.145,
			"low": 519.035,
			"close": 519.1,
			"volume": 57264,
			"datetime": 1712602620000
		},
		{
			"open": 519.1,
			"high": 519.12,
			"low": 519.05,
			"close": 519.115,
			"volume": 29144,
			"datetime": 1712602680000
		},
		{
			"open": 519.11,
			"high": 519.26,
			"low": 519.07,
			"close": 519.255,
			"volume": 46649,
			"datetime": 1712602740000
		},
		{
			"open": 519.26,
			"high": 519.35,
			"low": 519.19,
			"close": 519.2081,
			"volume": 53780,
			"datetime": 1712602800000
		},
		{
			"open": 519.2099,
			"high": 519.275,
			"low": 519.15,
			"close": 519.24,
			"volume": 64701,
			"datetime": 1712602860000
		},
		{
			"open": 519.255,
			"high": 519.38,
			"low": 519.2099,
			"close": 519.33,
			"volume": 72139,
			"datetime": 1712602920000
		},
		{
			"open": 519.32,
			"high": 519.32,
			"low": 519.1276,
			"close": 519.2003,
			"volume": 61211,
			"datetime": 1712602980000
		},
		{
			"open": 519.2,
			"high": 519.21,
			"low": 519.075,
			"close": 519.13,
			"volume": 54380,
			"datetime": 1712603040000
		},
		{
			"open": 519.13,
			"high": 519.13,
			"low": 519.02,
			"close": 519.06,
			"volume": 65776,
			"datetime": 1712603100000
		},
		{
			"open": 519.06,
			"high": 519.135,
			"low": 519.01,
			"close": 519.07,
			"volume": 74221,
			"datetime": 1712603160000
		},
		{
			"open": 519.06,
			"high": 519.075,
			"low": 518.74,
			"close": 518.77,
			"volume": 129856,
			"datetime": 1712603220000
		},
		{
			"open": 518.76,
			"high": 518.98,
			"low": 518.74,
			"close": 518.9302,
			"volume": 53945,
			"datetime": 1712603280000
		},
		{
			"open": 518.93,
			"high": 519.16,
			"low": 518.91,
			"close": 519.13,
			"volume": 50993,
			"datetime": 1712603340000
		},
		{
			"open": 519.13,
			"high": 519.19,
			"low": 519.055,
			"close": 519.16,
			"volume": 65875,
			"datetime": 1712603400000
		},
		{
			"open": 519.16,
			"high": 519.21,
			"low": 519.07,
			"close": 519.09,
			"volume": 58892,
			"datetime": 1712603460000
		},
		{
			"open": 519.09,
			"high": 519.15,
			"low": 519.02,
			"close": 519.14,
			"volume": 90769,
			"datetime": 1712603520000
		},
		{
			"open": 519.1307,
			"high": 519.24,
			"low": 519.1307,
			"close": 519.24,
			"volume": 70638,
			"datetime": 1712603580000
		},
		{
			"open": 519.23,
			"high": 519.25,
			"low": 519.1,
			"close": 519.12,
			"volume": 38516,
			"datetime": 1712603640000
		},
		{
			"open": 519.12,
			"high": 519.16,
			"low": 519.07,
			"close": 519.13,
			"volume": 28976,
			"datetime": 1712603700000
		},
		{
			"open": 519.14,
			"high": 519.16,
			"low": 519.0799,
			"close": 519.15,
			"volume": 29363,
			"datetime": 1712603760000
		},
		{
			"open": 519.15,
			"high": 519.155,
			"low": 519.02,
			"close": 519.03,
			"volume": 35751,
			"datetime": 1712603820000
		},
		{
			"open": 519.03,
			"high": 519.13,
			"low": 518.96,
			"close": 519.13,
			"volume": 50577,
			"datetime": 1712603880000
		},
		{
			"open": 519.12,
			"high": 519.17,
			"low": 519.09,
			"close": 519.16,
			"volume": 60011,
			"datetime": 1712603940000
		},
		{
			"open": 519.16,
			"high": 519.24,
			"low": 519.155,
			"close": 519.21,
			"volume": 70009,
			"datetime": 1712604000000
		},
		{
			"open": 519.21,
			"high": 519.22,
			"low": 519.05,
			"close": 519.08,
			"volume": 47004,
			"datetime": 1712604060000
		},
		{
			"open": 519.08,
			"high": 519.14,
			"low": 519,
			"close": 519.01,
			"volume": 23195,
			"datetime": 1712604120000
		},
		{
			"open": 519.0099,
			"high": 519.065,
			"low": 518.92,
			"close": 518.94,
			"volume": 54119,
			"datetime": 1712604180000
		},
		{
			"open": 518.95,
			"high": 518.95,
			"low": 518.855,
			"close": 518.93,
			"volume": 55926,
			"datetime": 1712604240000
		},
		{
			"open": 518.93,
			"high": 519.03,
			"low": 518.9,
			"close": 519.01,
			"volume": 50244,
			"datetime": 1712604300000
		},
		{
			"open": 519.01,
			"high": 519.05,
			"low": 518.95,
			"close": 519.03,
			"volume": 30798,
			"datetime": 1712604360000
		},
		{
			"open": 519.02,
			"high": 519.06,
			"low": 518.95,
			"close": 518.975,
			"volume": 33472,
			"datetime": 1712604420000
		},
		{
			"open": 518.98,
			"high": 519.07,
			"low": 518.98,
			"close": 519.07,
			"volume": 38730,
			"datetime": 1712604480000
		},
		{
			"open": 519.06,
			"high": 519.28,
			"low": 519.05,
			"close": 519.255,
			"volume": 88054,
			"datetime": 1712604540000
		},
		{
			"open": 519.25,
			"high": 519.3587,
			"low": 519.2,
			"close": 519.3587,
			"volume": 98026,
			"datetime": 1712604600000
		},
		{
			"open": 519.3511,
			"high": 519.38,
			"low": 519.27,
			"close": 519.33,
			"volume": 81892,
			"datetime": 1712604660000
		},
		{
			"open": 519.32,
			"high": 519.495,
			"low": 519.29,
			"close": 519.45,
			"volume": 79559,
			"datetime": 1712604720000
		},
		{
			"open": 519.45,
			"high": 519.7,
			"low": 519.42,
			"close": 519.645,
			"volume": 126916,
			"datetime": 1712604780000
		},
		{
			"open": 519.645,
			"high": 519.67,
			"low": 519.57,
			"close": 519.62,
			"volume": 139232,
			"datetime": 1712604840000
		},
		{
			"open": 519.62,
			"high": 519.64,
			"low": 519.4,
			"close": 519.42,
			"volume": 108491,
			"datetime": 1712604900000
		},
		{
			"open": 519.42,
			"high": 519.455,
			"low": 519.296,
			"close": 519.37,
			"volume": 79027,
			"datetime": 1712604960000
		},
		{
			"open": 519.37,
			"high": 519.38,
			"low": 519.14,
			"close": 519.2,
			"volume": 101856,
			"datetime": 1712605020000
		},
		{
			"open": 519.2,
			"high": 519.2,
			"low": 519.045,
			"close": 519.06,
			"volume": 100411,
			"datetime": 1712605080000
		},
		{
			"open": 519.0525,
			"high": 519.09,
			"low": 519.02,
			"close": 519.085,
			"volume": 83442,
			"datetime": 1712605140000
		},
		{
			"open": 519.09,
			"high": 519.1,
			"low": 519.0021,
			"close": 519.03,
			"volume": 74574,
			"datetime": 1712605200000
		},
		{
			"open": 519.04,
			"high": 519.13,
			"low": 518.65,
			"close": 518.7,
			"volume": 280047,
			"datetime": 1712605260000
		},
		{
			"open": 518.71,
			"high": 518.8,
			"low": 518.65,
			"close": 518.75,
			"volume": 135190,
			"datetime": 1712605320000
		},
		{
			"open": 518.74,
			"high": 518.795,
			"low": 518.48,
			"close": 518.67,
			"volume": 120858,
			"datetime": 1712605380000
		},
		{
			"open": 518.65,
			"high": 518.74,
			"low": 518.6,
			"close": 518.62,
			"volume": 98938,
			"datetime": 1712605440000
		},
		{
			"open": 518.61,
			"high": 518.73,
			"low": 518.57,
			"close": 518.6899,
			"volume": 101234,
			"datetime": 1712605500000
		},
		{
			"open": 518.69,
			"high": 518.8599,
			"low": 518.69,
			"close": 518.81,
			"volume": 113949,
			"datetime": 1712605560000
		},
		{
			"open": 518.81,
			"high": 518.81,
			"low": 518.65,
			"close": 518.715,
			"volume": 117259,
			"datetime": 1712605620000
		},
		{
			"open": 518.71,
			"high": 518.8,
			"low": 518.64,
			"close": 518.7,
			"volume": 87656,
			"datetime": 1712605680000
		},
		{
			"open": 518.69,
			"high": 518.93,
			"low": 518.67,
			"close": 518.87,
			"volume": 154762,
			"datetime": 1712605740000
		},
		{
			"open": 518.86,
			"high": 518.89,
			"low": 518.78,
			"close": 518.825,
			"volume": 205509,
			"datetime": 1712605800000
		},
		{
			"open": 518.83,
			"high": 518.86,
			"low": 518.57,
			"close": 518.58,
			"volume": 223045,
			"datetime": 1712605860000
		},
		{
			"open": 518.57,
			"high": 518.66,
			"low": 518.36,
			"close": 518.61,
			"volume": 250474,
			"datetime": 1712605920000
		},
		{
			"open": 518.62,
			"high": 518.645,
			"low": 518.45,
			"close": 518.485,
			"volume": 263066,
			"datetime": 1712605980000
		},
		{
			"open": 518.48,
			"high": 518.669,
			"low": 518.46,
			"close": 518.55,
			"volume": 323375,
			"datetime": 1712606040000
		},
		{
			"open": 518.56,
			"high": 518.56,
			"low": 518.32,
			"close": 518.37,
			"volume": 378012,
			"datetime": 1712606100000
		},
		{
			"open": 518.375,
			"high": 518.38,
			"low": 518.29,
			"close": 518.365,
			"volume": 323969,
			"datetime": 1712606160000
		},
		{
			"open": 518.37,
			"high": 518.37,
			"low": 518.23,
			"close": 518.31,
			"volume": 349948,
			"datetime": 1712606220000
		},
		{
			"open": 518.31,
			"high": 518.49,
			"low": 518.28,
			"close": 518.48,
			"volume": 622932,
			"datetime": 1712606280000
		},
		{
			"open": 518.48,
			"high": 518.71,
			"low": 518.37,
			"close": 518.67,
			"volume": 3250984,
			"datetime": 1712606340000
		},
		{
			"open": 520.5,
			"high": 520.62,
			"low": 520.33,
			"close": 520.33,
			"volume": 734585,
			"datetime": 1712669400000
		},
		{
			"open": 520.31,
			"high": 520.51,
			"low": 520.2799,
			"close": 520.45,
			"volume": 173070,
			"datetime": 1712669460000
		},
		{
			"open": 520.44,
			"high": 520.7296,
			"low": 520.41,
			"close": 520.71,
			"volume": 178904,
			"datetime": 1712669520000
		},
		{
			"open": 520.71,
			"high": 520.75,
			"low": 520.23,
			"close": 520.3,
			"volume": 155119,
			"datetime": 1712669580000
		},
		{
			"open": 520.3,
			"high": 520.3399,
			"low": 520.04,
			"close": 520.06,
			"volume": 176390,
			"datetime": 1712669640000
		},
		{
			"open": 520.1,
			"high": 520.12,
			"low": 519.84,
			"close": 520.11,
			"volume": 197370,
			"datetime": 1712669700000
		},
		{
			"open": 520.1,
			"high": 520.29,
			"low": 520.035,
			"close": 520.26,
			"volume": 177882,
			"datetime": 1712669760000
		},
		{
			"open": 520.25,
			"high": 520.54,
			"low": 520.2,
			"close": 520.47,
			"volume": 91044,
			"datetime": 1712669820000
		},
		{
			"open": 520.48,
			"high": 520.5,
			"low": 520.21,
			"close": 520.25,
			"volume": 100297,
			"datetime": 1712669880000
		},
		{
			"open": 520.26,
			"high": 520.37,
			"low": 520.18,
			"close": 520.36,
			"volume": 100885,
			"datetime": 1712669940000
		},
		{
			"open": 520.3601,
			"high": 520.45,
			"low": 520.3601,
			"close": 520.385,
			"volume": 64260,
			"datetime": 1712670000000
		},
		{
			"open": 520.39,
			"high": 520.4394,
			"low": 520.19,
			"close": 520.2204,
			"volume": 84952,
			"datetime": 1712670060000
		},
		{
			"open": 520.22,
			"high": 520.28,
			"low": 520.13,
			"close": 520.1688,
			"volume": 89281,
			"datetime": 1712670120000
		},
		{
			"open": 520.16,
			"high": 520.2199,
			"low": 520.0197,
			"close": 520.11,
			"volume": 72301,
			"datetime": 1712670180000
		},
		{
			"open": 520.11,
			"high": 520.13,
			"low": 519.72,
			"close": 519.835,
			"volume": 112870,
			"datetime": 1712670240000
		},
		{
			"open": 519.82,
			"high": 520,
			"low": 519.8,
			"close": 519.91,
			"volume": 105815,
			"datetime": 1712670300000
		},
		{
			"open": 519.93,
			"high": 519.94,
			"low": 519.74,
			"close": 519.76,
			"volume": 79165,
			"datetime": 1712670360000
		},
		{
			"open": 519.76,
			"high": 519.85,
			"low": 519.57,
			"close": 519.66,
			"volume": 228985,
			"datetime": 1712670420000
		},
		{
			"open": 519.66,
			"high": 519.7,
			"low": 519.36,
			"close": 519.43,
			"volume": 165615,
			"datetime": 1712670480000
		},
		{
			"open": 519.44,
			"high": 519.48,
			"low": 519.04,
			"close": 519.12,
			"volume": 203855,
			"datetime": 1712670540000
		},
		{
			"open": 519.12,
			"high": 519.19,
			"low": 518.925,
			"close": 519,
			"volume": 217653,
			"datetime": 1712670600000
		},
		{
			"open": 519.01,
			"high": 519.2,
			"low": 518.955,
			"close": 519.115,
			"volume": 117884,
			"datetime": 1712670660000
		},
		{
			"open": 519.11,
			"high": 519.12,
			"low": 518.77,
			"close": 518.9306,
			"volume": 166609,
			"datetime": 1712670720000
		},
		{
			"open": 518.94,
			"high": 519.0598,
			"low": 518.92,
			"close": 519.04,
			"volume": 89667,
			"datetime": 1712670780000
		},
		{
			"open": 519.04,
			"high": 519.07,
			"low": 518.87,
			"close": 518.96,
			"volume": 126927,
			"datetime": 1712670840000
		},
		{
			"open": 518.97,
			"high": 519.1401,
			"low": 518.97,
			"close": 519.05,
			"volume": 130090,
			"datetime": 1712670900000
		},
		{
			"open": 519.035,
			"high": 519.035,
			"low": 518.86,
			"close": 518.94,
			"volume": 87936,
			"datetime": 1712670960000
		},
		{
			"open": 518.935,
			"high": 518.97,
			"low": 518.8,
			"close": 518.9399,
			"volume": 100706,
			"datetime": 1712671020000
		},
		{
			"open": 518.93,
			"high": 518.96,
			"low": 518.83,
			"close": 518.89,
			"volume": 80015,
			"datetime": 1712671080000
		},
		{
			"open": 518.92,
			"high": 519.02,
			"low": 518.87,
			"close": 518.88,
			"volume": 92820,
			"datetime": 1712671140000
		},
		{
			"open": 518.89,
			"high": 519.0299,
			"low": 518.8505,
			"close": 518.96,
			"volume": 142582,
			"datetime": 1712671200000
		},
		{
			"open": 518.97,
			"high": 518.97,
			"low": 518.75,
			"close": 518.94,
			"volume": 114817,
			"datetime": 1712671260000
		},
		{
			"open": 518.97,
			"high": 519.1,
			"low": 518.87,
			"close": 519.07,
			"volume": 188907,
			"datetime": 1712671320000
		},
		{
			"open": 519.08,
			"high": 519.18,
			"low": 519.015,
			"close": 519.1,
			"volume": 215883,
			"datetime": 1712671380000
		},
		{
			"open": 519.1,
			"high": 519.1,
			"low": 518.87,
			"close": 518.915,
			"volume": 106981,
			"datetime": 1712671440000
		},
		{
			"open": 518.91,
			"high": 518.98,
			"low": 518.8,
			"close": 518.8701,
			"volume": 106340,
			"datetime": 1712671500000
		},
		{
			"open": 518.88,
			"high": 518.91,
			"low": 518.66,
			"close": 518.79,
			"volume": 124199,
			"datetime": 1712671560000
		},
		{
			"open": 518.79,
			"high": 518.9213,
			"low": 518.74,
			"close": 518.84,
			"volume": 127027,
			"datetime": 1712671620000
		},
		{
			"open": 518.84,
			"high": 518.88,
			"low": 518.755,
			"close": 518.78,
			"volume": 91847,
			"datetime": 1712671680000
		},
		{
			"open": 518.775,
			"high": 518.93,
			"low": 518.72,
			"close": 518.85,
			"volume": 74021,
			"datetime": 1712671740000
		},
		{
			"open": 518.85,
			"high": 518.89,
			"low": 518.77,
			"close": 518.85,
			"volume": 68681,
			"datetime": 1712671800000
		},
		{
			"open": 518.855,
			"high": 518.86,
			"low": 518.6699,
			"close": 518.6917,
			"volume": 96167,
			"datetime": 1712671860000
		},
		{
			"open": 518.695,
			"high": 518.825,
			"low": 518.66,
			"close": 518.8,
			"volume": 62609,
			"datetime": 1712671920000
		},
		{
			"open": 518.7901,
			"high": 519.07,
			"low": 518.75,
			"close": 519.06,
			"volume": 108701,
			"datetime": 1712671980000
		},
		{
			"open": 519.06,
			"high": 519.1,
			"low": 519.01,
			"close": 519.04,
			"volume": 122293,
			"datetime": 1712672040000
		},
		{
			"open": 519.0475,
			"high": 519.28,
			"low": 519.0475,
			"close": 519.095,
			"volume": 136017,
			"datetime": 1712672100000
		},
		{
			"open": 519.11,
			"high": 519.15,
			"low": 519.08,
			"close": 519.11,
			"volume": 95502,
			"datetime": 1712672160000
		},
		{
			"open": 519.115,
			"high": 519.245,
			"low": 519.085,
			"close": 519.11,
			"volume": 49110,
			"datetime": 1712672220000
		},
		{
			"open": 519.115,
			"high": 519.12,
			"low": 518.85,
			"close": 518.995,
			"volume": 156031,
			"datetime": 1712672280000
		},
		{
			"open": 519,
			"high": 519.05,
			"low": 518.89,
			"close": 519.015,
			"volume": 88985,
			"datetime": 1712672340000
		},
		{
			"open": 519.005,
			"high": 519.06,
			"low": 518.91,
			"close": 519.01,
			"volume": 94842,
			"datetime": 1712672400000
		},
		{
			"open": 519.01,
			"high": 519.11,
			"low": 518.955,
			"close": 518.98,
			"volume": 54596,
			"datetime": 1712672460000
		},
		{
			"open": 518.98,
			"high": 519.1035,
			"low": 518.98,
			"close": 519.05,
			"volume": 62649,
			"datetime": 1712672520000
		},
		{
			"open": 519.05,
			"high": 519.1199,
			"low": 518.98,
			"close": 519.03,
			"volume": 42816,
			"datetime": 1712672580000
		},
		{
			"open": 519.02,
			"high": 519.06,
			"low": 518.895,
			"close": 518.98,
			"volume": 65838,
			"datetime": 1712672640000
		},
		{
			"open": 518.98,
			"high": 519.04,
			"low": 518.82,
			"close": 518.93,
			"volume": 52470,
			"datetime": 1712672700000
		},
		{
			"open": 518.95,
			"high": 518.95,
			"low": 518.77,
			"close": 518.8,
			"volume": 73781,
			"datetime": 1712672760000
		},
		{
			"open": 518.79,
			"high": 518.79,
			"low": 518.31,
			"close": 518.3288,
			"volume": 210919,
			"datetime": 1712672820000
		},
		{
			"open": 518.3299,
			"high": 518.39,
			"low": 517.75,
			"close": 517.87,
			"volume": 241162,
			"datetime": 1712672880000
		},
		{
			"open": 517.87,
			"high": 517.9,
			"low": 517.4103,
			"close": 517.59,
			"volume": 222230,
			"datetime": 1712672940000
		},
		{
			"open": 517.59,
			"high": 517.63,
			"low": 516.56,
			"close": 516.805,
			"volume": 537932,
			"datetime": 1712673000000
		},
		{
			"open": 516.8,
			"high": 516.91,
			"low": 516.35,
			"close": 516.86,
			"volume": 370844,
			"datetime": 1712673060000
		},
		{
			"open": 516.85,
			"high": 516.87,
			"low": 516.5,
			"close": 516.85,
			"volume": 221070,
			"datetime": 1712673120000
		},
		{
			"open": 516.85,
			"high": 517.27,
			"low": 516.85,
			"close": 517.05,
			"volume": 273362,
			"datetime": 1712673180000
		},
		{
			"open": 517.06,
			"high": 517.16,
			"low": 516.64,
			"close": 516.69,
			"volume": 170186,
			"datetime": 1712673240000
		},
		{
			"open": 516.7,
			"high": 516.8,
			"low": 516.64,
			"close": 516.71,
			"volume": 102383,
			"datetime": 1712673300000
		},
		{
			"open": 516.705,
			"high": 516.74,
			"low": 515.93,
			"close": 515.93,
			"volume": 280477,
			"datetime": 1712673360000
		},
		{
			"open": 515.94,
			"high": 516.07,
			"low": 515.78,
			"close": 515.84,
			"volume": 214090,
			"datetime": 1712673420000
		},
		{
			"open": 515.84,
			"high": 516.1802,
			"low": 515.75,
			"close": 516.005,
			"volume": 287713,
			"datetime": 1712673480000
		},
		{
			"open": 516,
			"high": 516.16,
			"low": 515.68,
			"close": 515.78,
			"volume": 147371,
			"datetime": 1712673540000
		},
		{
			"open": 515.79,
			"high": 516.09,
			"low": 515.63,
			"close": 515.71,
			"volume": 226291,
			"datetime": 1712673600000
		},
		{
			"open": 515.71,
			"high": 515.76,
			"low": 515.37,
			"close": 515.545,
			"volume": 293557,
			"datetime": 1712673660000
		},
		{
			"open": 515.55,
			"high": 515.63,
			"low": 514.805,
			"close": 514.95,
			"volume": 330944,
			"datetime": 1712673720000
		},
		{
			"open": 514.96,
			"high": 515.05,
			"low": 514.52,
			"close": 514.6413,
			"volume": 317266,
			"datetime": 1712673780000
		},
		{
			"open": 514.65,
			"high": 514.99,
			"low": 514.46,
			"close": 514.84,
			"volume": 326611,
			"datetime": 1712673840000
		},
		{
			"open": 514.88,
			"high": 515.365,
			"low": 514.85,
			"close": 515.35,
			"volume": 348473,
			"datetime": 1712673900000
		},
		{
			"open": 515.35,
			"high": 515.36,
			"low": 514.93,
			"close": 515.01,
			"volume": 385639,
			"datetime": 1712673960000
		},
		{
			"open": 515.01,
			"high": 515.015,
			"low": 514.57,
			"close": 514.74,
			"volume": 345125,
			"datetime": 1712674020000
		},
		{
			"open": 514.755,
			"high": 514.755,
			"low": 514.44,
			"close": 514.575,
			"volume": 332686,
			"datetime": 1712674080000
		},
		{
			"open": 514.565,
			"high": 514.71,
			"low": 514.53,
			"close": 514.62,
			"volume": 172977,
			"datetime": 1712674140000
		},
		{
			"open": 514.64,
			"high": 514.87,
			"low": 514.4101,
			"close": 514.81,
			"volume": 236899,
			"datetime": 1712674200000
		},
		{
			"open": 514.8,
			"high": 515.05,
			"low": 514.77,
			"close": 514.98,
			"volume": 251183,
			"datetime": 1712674260000
		},
		{
			"open": 514.98,
			"high": 515.075,
			"low": 514.54,
			"close": 514.59,
			"volume": 241141,
			"datetime": 1712674320000
		},
		{
			"open": 514.6,
			"high": 514.75,
			"low": 514.35,
			"close": 514.5215,
			"volume": 261647,
			"datetime": 1712674380000
		},
		{
			"open": 514.55,
			"high": 514.55,
			"low": 514.35,
			"close": 514.47,
			"volume": 106444,
			"datetime": 1712674440000
		},
		{
			"open": 514.465,
			"high": 514.645,
			"low": 514.44,
			"close": 514.56,
			"volume": 204453,
			"datetime": 1712674500000
		},
		{
			"open": 514.55,
			"high": 514.8799,
			"low": 514.53,
			"close": 514.86,
			"volume": 177742,
			"datetime": 1712674560000
		},
		{
			"open": 514.87,
			"high": 515.06,
			"low": 514.75,
			"close": 515.06,
			"volume": 197927,
			"datetime": 1712674620000
		},
		{
			"open": 515.06,
			"high": 515.2217,
			"low": 515.03,
			"close": 515.2217,
			"volume": 195458,
			"datetime": 1712674680000
		},
		{
			"open": 515.23,
			"high": 515.57,
			"low": 515.19,
			"close": 515.515,
			"volume": 201557,
			"datetime": 1712674740000
		},
		{
			"open": 515.52,
			"high": 515.6498,
			"low": 515.4099,
			"close": 515.645,
			"volume": 167771,
			"datetime": 1712674800000
		},
		{
			"open": 515.64,
			"high": 515.645,
			"low": 515.23,
			"close": 515.2493,
			"volume": 150774,
			"datetime": 1712674860000
		},
		{
			"open": 515.25,
			"high": 515.63,
			"low": 515.25,
			"close": 515.41,
			"volume": 144991,
			"datetime": 1712674920000
		},
		{
			"open": 515.41,
			"high": 515.43,
			"low": 515.19,
			"close": 515.2901,
			"volume": 160546,
			"datetime": 1712674980000
		},
		{
			"open": 515.29,
			"high": 515.485,
			"low": 515.28,
			"close": 515.43,
			"volume": 107284,
			"datetime": 1712675040000
		},
		{
			"open": 515.46,
			"high": 515.6,
			"low": 515.36,
			"close": 515.55,
			"volume": 67601,
			"datetime": 1712675100000
		},
		{
			"open": 515.54,
			"high": 515.78,
			"low": 515.43,
			"close": 515.76,
			"volume": 132041,
			"datetime": 1712675160000
		},
		{
			"open": 515.72,
			"high": 516.03,
			"low": 515.4,
			"close": 516,
			"volume": 201516,
			"datetime": 1712675220000
		},
		{
			"open": 516,
			"high": 516.01,
			"low": 515.85,
			"close": 515.93,
			"volume": 115287,
			"datetime": 1712675280000
		},
		{
			"open": 515.92,
			"high": 516.15,
			"low": 515.92,
			"close": 515.94,
			"volume": 154681,
			"datetime": 1712675340000
		},
		{
			"open": 515.93,
			"high": 515.97,
			"low": 515.7,
			"close": 515.94,
			"volume": 195032,
			"datetime": 1712675400000
		},
		{
			"open": 515.93,
			"high": 515.97,
			"low": 515.5975,
			"close": 515.615,
			"volume": 84275,
			"datetime": 1712675460000
		},
		{
			"open": 515.61,
			"high": 515.75,
			"low": 515.55,
			"close": 515.6,
			"volume": 87356,
			"datetime": 1712675520000
		},
		{
			"open": 515.61,
			"high": 515.82,
			"low": 515.5801,
			"close": 515.74,
			"volume": 62127,
			"datetime": 1712675580000
		},
		{
			"open": 515.72,
			"high": 515.79,
			"low": 515.6,
			"close": 515.6,
			"volume": 52322,
			"datetime": 1712675640000
		},
		{
			"open": 515.62,
			"high": 516.05,
			"low": 515.62,
			"close": 515.955,
			"volume": 94974,
			"datetime": 1712675700000
		},
		{
			"open": 515.95,
			"high": 516.36,
			"low": 515.9387,
			"close": 516.29,
			"volume": 152051,
			"datetime": 1712675760000
		},
		{
			"open": 516.295,
			"high": 516.53,
			"low": 516.24,
			"close": 516.375,
			"volume": 144133,
			"datetime": 1712675820000
		},
		{
			"open": 516.375,
			"high": 516.47,
			"low": 516.2727,
			"close": 516.39,
			"volume": 92416,
			"datetime": 1712675880000
		},
		{
			"open": 516.4,
			"high": 516.55,
			"low": 516.37,
			"close": 516.475,
			"volume": 62587,
			"datetime": 1712675940000
		},
		{
			"open": 516.46,
			"high": 516.5,
			"low": 516.18,
			"close": 516.285,
			"volume": 133583,
			"datetime": 1712676000000
		},
		{
			"open": 516.275,
			"high": 516.32,
			"low": 516.08,
			"close": 516.12,
			"volume": 80502,
			"datetime": 1712676060000
		},
		{
			"open": 516.11,
			"high": 516.35,
			"low": 516.09,
			"close": 516.261,
			"volume": 82014,
			"datetime": 1712676120000
		},
		{
			"open": 516.24,
			"high": 516.25,
			"low": 516.13,
			"close": 516.18,
			"volume": 83398,
			"datetime": 1712676180000
		},
		{
			"open": 516.2,
			"high": 516.31,
			"low": 515.96,
			"close": 516.22,
			"volume": 79305,
			"datetime": 1712676240000
		},
		{
			"open": 516.22,
			"high": 516.31,
			"low": 516.17,
			"close": 516.19,
			"volume": 71551,
			"datetime": 1712676300000
		},
		{
			"open": 516.18,
			"high": 516.4198,
			"low": 516.13,
			"close": 516.4,
			"volume": 63896,
			"datetime": 1712676360000
		},
		{
			"open": 516.39,
			"high": 516.39,
			"low": 516.135,
			"close": 516.135,
			"volume": 42221,
			"datetime": 1712676420000
		},
		{
			"open": 516.14,
			"high": 516.16,
			"low": 515.9416,
			"close": 516,
			"volume": 66972,
			"datetime": 1712676480000
		},
		{
			"open": 515.9688,
			"high": 516,
			"low": 515.84,
			"close": 515.95,
			"volume": 63185,
			"datetime": 1712676540000
		},
		{
			"open": 515.96,
			"high": 516.1164,
			"low": 515.93,
			"close": 516.05,
			"volume": 53565,
			"datetime": 1712676600000
		},
		{
			"open": 516.05,
			"high": 516.195,
			"low": 515.78,
			"close": 515.985,
			"volume": 76158,
			"datetime": 1712676660000
		},
		{
			"open": 515.98,
			"high": 516.095,
			"low": 515.91,
			"close": 515.91,
			"volume": 46355,
			"datetime": 1712676720000
		},
		{
			"open": 515.89,
			"high": 515.97,
			"low": 515.81,
			"close": 515.865,
			"volume": 94625,
			"datetime": 1712676780000
		},
		{
			"open": 515.87,
			"high": 516,
			"low": 515.86,
			"close": 515.87,
			"volume": 67491,
			"datetime": 1712676840000
		},
		{
			"open": 515.87,
			"high": 515.98,
			"low": 515.67,
			"close": 515.9492,
			"volume": 108049,
			"datetime": 1712676900000
		},
		{
			"open": 515.89,
			"high": 516.14,
			"low": 515.89,
			"close": 516.025,
			"volume": 87391,
			"datetime": 1712676960000
		},
		{
			"open": 516.02,
			"high": 516.19,
			"low": 516.015,
			"close": 516.02,
			"volume": 74561,
			"datetime": 1712677020000
		},
		{
			"open": 516.04,
			"high": 516.1499,
			"low": 515.98,
			"close": 516,
			"volume": 36666,
			"datetime": 1712677080000
		},
		{
			"open": 515.99,
			"high": 516.105,
			"low": 515.82,
			"close": 515.88,
			"volume": 104338,
			"datetime": 1712677140000
		},
		{
			"open": 515.89,
			"high": 516.095,
			"low": 515.89,
			"close": 515.9,
			"volume": 46999,
			"datetime": 1712677200000
		},
		{
			"open": 515.865,
			"high": 516.1096,
			"low": 515.845,
			"close": 516.0773,
			"volume": 50147,
			"datetime": 1712677260000
		},
		{
			"open": 516.075,
			"high": 516.21,
			"low": 515.95,
			"close": 516.125,
			"volume": 81724,
			"datetime": 1712677320000
		},
		{
			"open": 516.1,
			"high": 516.5497,
			"low": 516.07,
			"close": 516.41,
			"volume": 167168,
			"datetime": 1712677380000
		},
		{
			"open": 516.43,
			"high": 516.92,
			"low": 516.41,
			"close": 516.715,
			"volume": 342450,
			"datetime": 1712677440000
		},
		{
			"open": 516.69,
			"high": 516.91,
			"low": 516.62,
			"close": 516.81,
			"volume": 134483,
			"datetime": 1712677500000
		},
		{
			"open": 516.805,
			"high": 516.89,
			"low": 516.7,
			"close": 516.86,
			"volume": 91654,
			"datetime": 1712677560000
		},
		{
			"open": 516.86,
			"high": 516.96,
			"low": 516.79,
			"close": 516.91,
			"volume": 71005,
			"datetime": 1712677620000
		},
		{
			"open": 516.91,
			"high": 516.95,
			"low": 516.79,
			"close": 516.845,
			"volume": 64692,
			"datetime": 1712677680000
		},
		{
			"open": 516.83,
			"high": 517.125,
			"low": 516.81,
			"close": 516.98,
			"volume": 109226,
			"datetime": 1712677740000
		},
		{
			"open": 516.98,
			"high": 517.045,
			"low": 516.8,
			"close": 516.8999,
			"volume": 81301,
			"datetime": 1712677800000
		},
		{
			"open": 516.9,
			"high": 516.945,
			"low": 516.77,
			"close": 516.84,
			"volume": 70451,
			"datetime": 1712677860000
		},
		{
			"open": 516.83,
			"high": 516.8399,
			"low": 516.68,
			"close": 516.77,
			"volume": 67599,
			"datetime": 1712677920000
		},
		{
			"open": 516.78,
			"high": 516.81,
			"low": 516.58,
			"close": 516.71,
			"volume": 72180,
			"datetime": 1712677980000
		},
		{
			"open": 516.72,
			"high": 516.94,
			"low": 516.71,
			"close": 516.925,
			"volume": 124496,
			"datetime": 1712678040000
		},
		{
			"open": 516.93,
			"high": 517.03,
			"low": 516.92,
			"close": 516.9451,
			"volume": 75003,
			"datetime": 1712678100000
		},
		{
			"open": 516.96,
			"high": 516.99,
			"low": 516.87,
			"close": 516.95,
			"volume": 61829,
			"datetime": 1712678160000
		},
		{
			"open": 516.94,
			"high": 516.96,
			"low": 516.85,
			"close": 516.86,
			"volume": 58510,
			"datetime": 1712678220000
		},
		{
			"open": 516.85,
			"high": 516.94,
			"low": 516.85,
			"close": 516.905,
			"volume": 49465,
			"datetime": 1712678280000
		},
		{
			"open": 516.91,
			"high": 516.95,
			"low": 516.825,
			"close": 516.825,
			"volume": 61917,
			"datetime": 1712678340000
		},
		{
			"open": 516.825,
			"high": 517.33,
			"low": 516.76,
			"close": 517.15,
			"volume": 146963,
			"datetime": 1712678400000
		},
		{
			"open": 517.15,
			"high": 517.16,
			"low": 516.85,
			"close": 516.91,
			"volume": 75443,
			"datetime": 1712678460000
		},
		{
			"open": 516.9001,
			"high": 516.98,
			"low": 516.79,
			"close": 516.94,
			"volume": 64012,
			"datetime": 1712678520000
		},
		{
			"open": 516.95,
			"high": 517.03,
			"low": 516.86,
			"close": 517.01,
			"volume": 50349,
			"datetime": 1712678580000
		},
		{
			"open": 516.9999,
			"high": 517.13,
			"low": 516.985,
			"close": 517.1,
			"volume": 113712,
			"datetime": 1712678640000
		},
		{
			"open": 517.08,
			"high": 517.13,
			"low": 516.92,
			"close": 517.06,
			"volume": 49411,
			"datetime": 1712678700000
		},
		{
			"open": 517.06,
			"high": 517.07,
			"low": 516.9003,
			"close": 516.9773,
			"volume": 58944,
			"datetime": 1712678760000
		},
		{
			"open": 516.95,
			"high": 516.96,
			"low": 516.78,
			"close": 516.85,
			"volume": 60532,
			"datetime": 1712678820000
		},
		{
			"open": 516.87,
			"high": 517.03,
			"low": 516.85,
			"close": 516.985,
			"volume": 48578,
			"datetime": 1712678880000
		},
		{
			"open": 516.96,
			"high": 517.26,
			"low": 516.93,
			"close": 517.12,
			"volume": 69155,
			"datetime": 1712678940000
		},
		{
			"open": 517.11,
			"high": 517.165,
			"low": 517.055,
			"close": 517.115,
			"volume": 31437,
			"datetime": 1712679000000
		},
		{
			"open": 517.1,
			"high": 517.165,
			"low": 517.005,
			"close": 517.15,
			"volume": 32474,
			"datetime": 1712679060000
		},
		{
			"open": 517.16,
			"high": 517.63,
			"low": 517.155,
			"close": 517.55,
			"volume": 204510,
			"datetime": 1712679120000
		},
		{
			"open": 517.55,
			"high": 517.64,
			"low": 517.52,
			"close": 517.5501,
			"volume": 82255,
			"datetime": 1712679180000
		},
		{
			"open": 517.5587,
			"high": 517.63,
			"low": 517.4,
			"close": 517.405,
			"volume": 87425,
			"datetime": 1712679240000
		},
		{
			"open": 517.405,
			"high": 517.495,
			"low": 517.3789,
			"close": 517.415,
			"volume": 52173,
			"datetime": 1712679300000
		},
		{
			"open": 517.415,
			"high": 517.46,
			"low": 517.32,
			"close": 517.43,
			"volume": 60883,
			"datetime": 1712679360000
		},
		{
			"open": 517.42,
			"high": 517.47,
			"low": 517.37,
			"close": 517.44,
			"volume": 36164,
			"datetime": 1712679420000
		},
		{
			"open": 517.42,
			"high": 517.47,
			"low": 517.26,
			"close": 517.42,
			"volume": 57195,
			"datetime": 1712679480000
		},
		{
			"open": 517.42,
			"high": 517.43,
			"low": 517.28,
			"close": 517.3,
			"volume": 39327,
			"datetime": 1712679540000
		},
		{
			"open": 517.3,
			"high": 517.39,
			"low": 517.24,
			"close": 517.29,
			"volume": 67182,
			"datetime": 1712679600000
		},
		{
			"open": 517.31,
			"high": 517.435,
			"low": 517.265,
			"close": 517.435,
			"volume": 51017,
			"datetime": 1712679660000
		},
		{
			"open": 517.425,
			"high": 517.52,
			"low": 517.38,
			"close": 517.43,
			"volume": 46221,
			"datetime": 1712679720000
		},
		{
			"open": 517.44,
			"high": 517.44,
			"low": 517.23,
			"close": 517.2501,
			"volume": 41291,
			"datetime": 1712679780000
		},
		{
			"open": 517.26,
			"high": 517.43,
			"low": 517.26,
			"close": 517.41,
			"volume": 52639,
			"datetime": 1712679840000
		},
		{
			"open": 517.415,
			"high": 517.45,
			"low": 517.23,
			"close": 517.31,
			"volume": 45284,
			"datetime": 1712679900000
		},
		{
			"open": 517.31,
			"high": 517.56,
			"low": 517.3099,
			"close": 517.52,
			"volume": 58426,
			"datetime": 1712679960000
		},
		{
			"open": 517.51,
			"high": 517.51,
			"low": 517.27,
			"close": 517.27,
			"volume": 35080,
			"datetime": 1712680020000
		},
		{
			"open": 517.27,
			"high": 517.36,
			"low": 517.26,
			"close": 517.315,
			"volume": 45502,
			"datetime": 1712680080000
		},
		{
			"open": 517.32,
			"high": 517.365,
			"low": 517.24,
			"close": 517.26,
			"volume": 40373,
			"datetime": 1712680140000
		},
		{
			"open": 517.26,
			"high": 517.42,
			"low": 517.26,
			"close": 517.3,
			"volume": 45360,
			"datetime": 1712680200000
		},
		{
			"open": 517.29,
			"high": 517.48,
			"low": 517.29,
			"close": 517.45,
			"volume": 34684,
			"datetime": 1712680260000
		},
		{
			"open": 517.44,
			"high": 517.59,
			"low": 517.385,
			"close": 517.545,
			"volume": 70282,
			"datetime": 1712680320000
		},
		{
			"open": 517.55,
			"high": 517.63,
			"low": 517.52,
			"close": 517.5901,
			"volume": 49716,
			"datetime": 1712680380000
		},
		{
			"open": 517.6,
			"high": 517.78,
			"low": 517.565,
			"close": 517.745,
			"volume": 91592,
			"datetime": 1712680440000
		},
		{
			"open": 517.745,
			"high": 517.75,
			"low": 517.62,
			"close": 517.67,
			"volume": 35121,
			"datetime": 1712680500000
		},
		{
			"open": 517.66,
			"high": 517.69,
			"low": 517.62,
			"close": 517.645,
			"volume": 33582,
			"datetime": 1712680560000
		},
		{
			"open": 517.64,
			"high": 517.71,
			"low": 517.46,
			"close": 517.47,
			"volume": 58933,
			"datetime": 1712680620000
		},
		{
			"open": 517.47,
			"high": 517.605,
			"low": 517.47,
			"close": 517.5699,
			"volume": 30134,
			"datetime": 1712680680000
		},
		{
			"open": 517.55,
			"high": 517.7099,
			"low": 517.54,
			"close": 517.67,
			"volume": 24443,
			"datetime": 1712680740000
		},
		{
			"open": 517.68,
			"high": 517.69,
			"low": 517.47,
			"close": 517.5299,
			"volume": 37952,
			"datetime": 1712680800000
		},
		{
			"open": 517.53,
			"high": 517.62,
			"low": 517.52,
			"close": 517.62,
			"volume": 22812,
			"datetime": 1712680860000
		},
		{
			"open": 517.63,
			"high": 517.665,
			"low": 517.51,
			"close": 517.5401,
			"volume": 28197,
			"datetime": 1712680920000
		},
		{
			"open": 517.54,
			"high": 517.695,
			"low": 517.54,
			"close": 517.695,
			"volume": 33350,
			"datetime": 1712680980000
		},
		{
			"open": 517.69,
			"high": 517.69,
			"low": 517.46,
			"close": 517.4699,
			"volume": 70045,
			"datetime": 1712681040000
		},
		{
			"open": 517.48,
			"high": 517.61,
			"low": 517.4,
			"close": 517.42,
			"volume": 53622,
			"datetime": 1712681100000
		},
		{
			"open": 517.43,
			"high": 517.44,
			"low": 517.25,
			"close": 517.29,
			"volume": 71894,
			"datetime": 1712681160000
		},
		{
			"open": 517.3,
			"high": 517.49,
			"low": 517.3,
			"close": 517.42,
			"volume": 54532,
			"datetime": 1712681220000
		},
		{
			"open": 517.43,
			"high": 517.51,
			"low": 517.365,
			"close": 517.4999,
			"volume": 43147,
			"datetime": 1712681280000
		},
		{
			"open": 517.49,
			"high": 517.495,
			"low": 517.41,
			"close": 517.425,
			"volume": 18359,
			"datetime": 1712681340000
		},
		{
			"open": 517.41,
			"high": 517.5,
			"low": 517.36,
			"close": 517.36,
			"volume": 48760,
			"datetime": 1712681400000
		},
		{
			"open": 517.3535,
			"high": 517.37,
			"low": 517.18,
			"close": 517.18,
			"volume": 61849,
			"datetime": 1712681460000
		},
		{
			"open": 517.17,
			"high": 517.2898,
			"low": 517.16,
			"close": 517.21,
			"volume": 59715,
			"datetime": 1712681520000
		},
		{
			"open": 517.2,
			"high": 517.21,
			"low": 516.82,
			"close": 516.89,
			"volume": 109601,
			"datetime": 1712681580000
		},
		{
			"open": 516.89,
			"high": 516.955,
			"low": 516.575,
			"close": 516.64,
			"volume": 176144,
			"datetime": 1712681640000
		},
		{
			"open": 516.63,
			"high": 516.81,
			"low": 516.59,
			"close": 516.7401,
			"volume": 127525,
			"datetime": 1712681700000
		},
		{
			"open": 516.75,
			"high": 516.86,
			"low": 516.73,
			"close": 516.79,
			"volume": 79865,
			"datetime": 1712681760000
		},
		{
			"open": 516.77,
			"high": 517.01,
			"low": 516.77,
			"close": 516.86,
			"volume": 71735,
			"datetime": 1712681820000
		},
		{
			"open": 516.86,
			"high": 516.87,
			"low": 516.71,
			"close": 516.725,
			"volume": 59847,
			"datetime": 1712681880000
		},
		{
			"open": 516.72,
			"high": 516.83,
			"low": 516.68,
			"close": 516.79,
			"volume": 27872,
			"datetime": 1712681940000
		},
		{
			"open": 516.8,
			"high": 516.8,
			"low": 516.67,
			"close": 516.79,
			"volume": 66048,
			"datetime": 1712682000000
		},
		{
			"open": 516.8,
			"high": 517.02,
			"low": 516.8,
			"close": 516.99,
			"volume": 53678,
			"datetime": 1712682060000
		},
		{
			"open": 517,
			"high": 517.02,
			"low": 516.6,
			"close": 517,
			"volume": 178933,
			"datetime": 1712682120000
		},
		{
			"open": 516.9998,
			"high": 517.05,
			"low": 516.86,
			"close": 516.955,
			"volume": 70641,
			"datetime": 1712682180000
		},
		{
			"open": 516.95,
			"high": 517.19,
			"low": 516.95,
			"close": 517.19,
			"volume": 59366,
			"datetime": 1712682240000
		},
		{
			"open": 517.19,
			"high": 517.295,
			"low": 517.09,
			"close": 517.23,
			"volume": 59134,
			"datetime": 1712682300000
		},
		{
			"open": 517.22,
			"high": 517.46,
			"low": 517.21,
			"close": 517.365,
			"volume": 80790,
			"datetime": 1712682360000
		},
		{
			"open": 517.3699,
			"high": 517.38,
			"low": 517.28,
			"close": 517.2999,
			"volume": 58421,
			"datetime": 1712682420000
		},
		{
			"open": 517.325,
			"high": 517.38,
			"low": 517.27,
			"close": 517.31,
			"volume": 53447,
			"datetime": 1712682480000
		},
		{
			"open": 517.3,
			"high": 517.43,
			"low": 517.22,
			"close": 517.39,
			"volume": 50654,
			"datetime": 1712682540000
		},
		{
			"open": 517.4,
			"high": 517.48,
			"low": 517.3,
			"close": 517.3297,
			"volume": 59744,
			"datetime": 1712682600000
		},
		{
			"open": 517.315,
			"high": 517.325,
			"low": 517.155,
			"close": 517.19,
			"volume": 45709,
			"datetime": 1712682660000
		},
		{
			"open": 517.16,
			"high": 517.31,
			"low": 517.16,
			"close": 517.255,
			"volume": 52251,
			"datetime": 1712682720000
		},
		{
			"open": 517.265,
			"high": 517.37,
			"low": 517.2,
			"close": 517.37,
			"volume": 42827,
			"datetime": 1712682780000
		},
		{
			"open": 517.37,
			"high": 517.42,
			"low": 517.28,
			"close": 517.3,
			"volume": 54433,
			"datetime": 1712682840000
		},
		{
			"open": 517.32,
			"high": 517.33,
			"low": 517.11,
			"close": 517.145,
			"volume": 71122,
			"datetime": 1712682900000
		},
		{
			"open": 517.14,
			"high": 517.14,
			"low": 517,
			"close": 517.0301,
			"volume": 45726,
			"datetime": 1712682960000
		},
		{
			"open": 517.03,
			"high": 517.19,
			"low": 517.025,
			"close": 517.15,
			"volume": 55536,
			"datetime": 1712683020000
		},
		{
			"open": 517.16,
			"high": 517.36,
			"low": 517.14,
			"close": 517.28,
			"volume": 86680,
			"datetime": 1712683080000
		},
		{
			"open": 517.27,
			"high": 517.275,
			"low": 517.095,
			"close": 517.23,
			"volume": 37082,
			"datetime": 1712683140000
		},
		{
			"open": 517.24,
			"high": 517.26,
			"low": 517.15,
			"close": 517.15,
			"volume": 27567,
			"datetime": 1712683200000
		},
		{
			"open": 517.15,
			"high": 517.5,
			"low": 517.12,
			"close": 517.5,
			"volume": 79657,
			"datetime": 1712683260000
		},
		{
			"open": 517.495,
			"high": 517.64,
			"low": 517.37,
			"close": 517.54,
			"volume": 103304,
			"datetime": 1712683320000
		},
		{
			"open": 517.55,
			"high": 517.63,
			"low": 517.5,
			"close": 517.515,
			"volume": 46508,
			"datetime": 1712683380000
		},
		{
			"open": 517.52,
			"high": 517.53,
			"low": 517.205,
			"close": 517.23,
			"volume": 52008,
			"datetime": 1712683440000
		},
		{
			"open": 517.225,
			"high": 517.31,
			"low": 517.105,
			"close": 517.225,
			"volume": 70368,
			"datetime": 1712683500000
		},
		{
			"open": 517.22,
			"high": 517.32,
			"low": 517.16,
			"close": 517.21,
			"volume": 57339,
			"datetime": 1712683560000
		},
		{
			"open": 517.22,
			"high": 517.23,
			"low": 516.98,
			"close": 517.06,
			"volume": 82813,
			"datetime": 1712683620000
		},
		{
			"open": 517.075,
			"high": 517.09,
			"low": 517,
			"close": 517.065,
			"volume": 51641,
			"datetime": 1712683680000
		},
		{
			"open": 517.07,
			"high": 517.2,
			"low": 517.06,
			"close": 517.16,
			"volume": 60612,
			"datetime": 1712683740000
		},
		{
			"open": 517.17,
			"high": 517.245,
			"low": 517.1,
			"close": 517.12,
			"volume": 40290,
			"datetime": 1712683800000
		},
		{
			"open": 517.124,
			"high": 517.29,
			"low": 517.05,
			"close": 517.24,
			"volume": 50539,
			"datetime": 1712683860000
		},
		{
			"open": 517.25,
			"high": 517.39,
			"low": 517.21,
			"close": 517.22,
			"volume": 48636,
			"datetime": 1712683920000
		},
		{
			"open": 517.16,
			"high": 517.16,
			"low": 517.06,
			"close": 517.08,
			"volume": 26042,
			"datetime": 1712683980000
		},
		{
			"open": 517.08,
			"high": 517.15,
			"low": 517.05,
			"close": 517.105,
			"volume": 38483,
			"datetime": 1712684040000
		},
		{
			"open": 517.12,
			"high": 517.26,
			"low": 517.1,
			"close": 517.22,
			"volume": 29558,
			"datetime": 1712684100000
		},
		{
			"open": 517.22,
			"high": 517.24,
			"low": 517.13,
			"close": 517.15,
			"volume": 24569,
			"datetime": 1712684160000
		},
		{
			"open": 517.14,
			"high": 517.2389,
			"low": 517.08,
			"close": 517.13,
			"volume": 16933,
			"datetime": 1712684220000
		},
		{
			"open": 517.145,
			"high": 517.34,
			"low": 517.14,
			"close": 517.28,
			"volume": 38508,
			"datetime": 1712684280000
		},
		{
			"open": 517.2951,
			"high": 517.48,
			"low": 517.28,
			"close": 517.48,
			"volume": 47838,
			"datetime": 1712684340000
		},
		{
			"open": 517.48,
			"high": 517.6,
			"low": 517.46,
			"close": 517.55,
			"volume": 76848,
			"datetime": 1712684400000
		},
		{
			"open": 517.55,
			"high": 517.64,
			"low": 517.33,
			"close": 517.3463,
			"volume": 67080,
			"datetime": 1712684460000
		},
		{
			"open": 517.35,
			"high": 517.36,
			"low": 516.87,
			"close": 516.9,
			"volume": 73937,
			"datetime": 1712684520000
		},
		{
			"open": 516.91,
			"high": 517.11,
			"low": 516.8999,
			"close": 517.04,
			"volume": 65474,
			"datetime": 1712684580000
		},
		{
			"open": 517.03,
			"high": 517.09,
			"low": 516.95,
			"close": 516.99,
			"volume": 51519,
			"datetime": 1712684640000
		},
		{
			"open": 516.99,
			"high": 517.052,
			"low": 516.84,
			"close": 516.93,
			"volume": 76834,
			"datetime": 1712684700000
		},
		{
			"open": 516.925,
			"high": 516.9799,
			"low": 516.845,
			"close": 516.97,
			"volume": 56392,
			"datetime": 1712684760000
		},
		{
			"open": 516.98,
			"high": 517.08,
			"low": 516.91,
			"close": 516.98,
			"volume": 54744,
			"datetime": 1712684820000
		},
		{
			"open": 516.92,
			"high": 516.96,
			"low": 516.84,
			"close": 516.88,
			"volume": 44472,
			"datetime": 1712684880000
		},
		{
			"open": 516.87,
			"high": 516.89,
			"low": 516.72,
			"close": 516.8557,
			"volume": 128148,
			"datetime": 1712684940000
		},
		{
			"open": 516.85,
			"high": 517.03,
			"low": 516.815,
			"close": 516.995,
			"volume": 104796,
			"datetime": 1712685000000
		},
		{
			"open": 516.995,
			"high": 517.15,
			"low": 516.99,
			"close": 517.075,
			"volume": 75728,
			"datetime": 1712685060000
		},
		{
			"open": 517.075,
			"high": 517.11,
			"low": 516.83,
			"close": 516.9,
			"volume": 62220,
			"datetime": 1712685120000
		},
		{
			"open": 516.9,
			"high": 516.94,
			"low": 516.83,
			"close": 516.93,
			"volume": 38753,
			"datetime": 1712685180000
		},
		{
			"open": 516.93,
			"high": 517.02,
			"low": 516.915,
			"close": 516.99,
			"volume": 42648,
			"datetime": 1712685240000
		},
		{
			"open": 517,
			"high": 517.01,
			"low": 516.82,
			"close": 516.83,
			"volume": 37790,
			"datetime": 1712685300000
		},
		{
			"open": 516.82,
			"high": 516.885,
			"low": 516.77,
			"close": 516.855,
			"volume": 42244,
			"datetime": 1712685360000
		},
		{
			"open": 516.86,
			"high": 516.98,
			"low": 516.81,
			"close": 516.81,
			"volume": 55439,
			"datetime": 1712685420000
		},
		{
			"open": 516.81,
			"high": 516.85,
			"low": 516.75,
			"close": 516.835,
			"volume": 34260,
			"datetime": 1712685480000
		},
		{
			"open": 516.8301,
			"high": 516.9,
			"low": 516.66,
			"close": 516.6701,
			"volume": 59472,
			"datetime": 1712685540000
		},
		{
			"open": 516.67,
			"high": 516.735,
			"low": 516.44,
			"close": 516.57,
			"volume": 92431,
			"datetime": 1712685600000
		},
		{
			"open": 516.58,
			"high": 516.65,
			"low": 516.45,
			"close": 516.484,
			"volume": 55656,
			"datetime": 1712685660000
		},
		{
			"open": 516.47,
			"high": 516.47,
			"low": 516.23,
			"close": 516.38,
			"volume": 82130,
			"datetime": 1712685720000
		},
		{
			"open": 516.39,
			"high": 516.486,
			"low": 516.35,
			"close": 516.3899,
			"volume": 42198,
			"datetime": 1712685780000
		},
		{
			"open": 516.37,
			"high": 516.66,
			"low": 516.335,
			"close": 516.63,
			"volume": 68732,
			"datetime": 1712685840000
		},
		{
			"open": 516.63,
			"high": 516.8,
			"low": 516.61,
			"close": 516.725,
			"volume": 51245,
			"datetime": 1712685900000
		},
		{
			"open": 516.72,
			"high": 516.84,
			"low": 516.68,
			"close": 516.75,
			"volume": 37036,
			"datetime": 1712685960000
		},
		{
			"open": 516.76,
			"high": 516.8,
			"low": 516.62,
			"close": 516.65,
			"volume": 35384,
			"datetime": 1712686020000
		},
		{
			"open": 516.65,
			"high": 516.8,
			"low": 516.5599,
			"close": 516.8,
			"volume": 62486,
			"datetime": 1712686080000
		},
		{
			"open": 516.81,
			"high": 517.05,
			"low": 516.76,
			"close": 516.98,
			"volume": 88554,
			"datetime": 1712686140000
		},
		{
			"open": 516.9911,
			"high": 517.0599,
			"low": 516.865,
			"close": 516.9,
			"volume": 62639,
			"datetime": 1712686200000
		},
		{
			"open": 516.89,
			"high": 516.94,
			"low": 516.73,
			"close": 516.76,
			"volume": 55971,
			"datetime": 1712686260000
		},
		{
			"open": 516.775,
			"high": 517.035,
			"low": 516.76,
			"close": 517.025,
			"volume": 68285,
			"datetime": 1712686320000
		},
		{
			"open": 517.03,
			"high": 517.03,
			"low": 516.84,
			"close": 516.915,
			"volume": 66645,
			"datetime": 1712686380000
		},
		{
			"open": 516.92,
			"high": 517.02,
			"low": 516.84,
			"close": 516.84,
			"volume": 55397,
			"datetime": 1712686440000
		},
		{
			"open": 516.84,
			"high": 517.04,
			"low": 516.83,
			"close": 516.96,
			"volume": 58403,
			"datetime": 1712686500000
		},
		{
			"open": 516.95,
			"high": 516.96,
			"low": 516.76,
			"close": 516.85,
			"volume": 60896,
			"datetime": 1712686560000
		},
		{
			"open": 516.85,
			"high": 516.92,
			"low": 516.83,
			"close": 516.87,
			"volume": 62289,
			"datetime": 1712686620000
		},
		{
			"open": 516.89,
			"high": 517.15,
			"low": 516.82,
			"close": 517.1,
			"volume": 79428,
			"datetime": 1712686680000
		},
		{
			"open": 517.1,
			"high": 517.17,
			"low": 517.04,
			"close": 517.0799,
			"volume": 60944,
			"datetime": 1712686740000
		},
		{
			"open": 517.08,
			"high": 517.12,
			"low": 517.02,
			"close": 517.11,
			"volume": 37924,
			"datetime": 1712686800000
		},
		{
			"open": 517.12,
			"high": 517.16,
			"low": 517.01,
			"close": 517.12,
			"volume": 32441,
			"datetime": 1712686860000
		},
		{
			"open": 517.13,
			"high": 517.29,
			"low": 517.13,
			"close": 517.21,
			"volume": 92637,
			"datetime": 1712686920000
		},
		{
			"open": 517.22,
			"high": 517.22,
			"low": 517.02,
			"close": 517.045,
			"volume": 69470,
			"datetime": 1712686980000
		},
		{
			"open": 517.0451,
			"high": 517.05,
			"low": 516.68,
			"close": 516.75,
			"volume": 70472,
			"datetime": 1712687040000
		},
		{
			"open": 516.76,
			"high": 517.01,
			"low": 516.76,
			"close": 516.975,
			"volume": 72654,
			"datetime": 1712687100000
		},
		{
			"open": 516.97,
			"high": 517.02,
			"low": 516.9,
			"close": 516.94,
			"volume": 28094,
			"datetime": 1712687160000
		},
		{
			"open": 516.94,
			"high": 516.95,
			"low": 516.81,
			"close": 516.854,
			"volume": 53985,
			"datetime": 1712687220000
		},
		{
			"open": 516.85,
			"high": 516.86,
			"low": 516.65,
			"close": 516.69,
			"volume": 55389,
			"datetime": 1712687280000
		},
		{
			"open": 516.68,
			"high": 516.78,
			"low": 516.53,
			"close": 516.65,
			"volume": 48331,
			"datetime": 1712687340000
		},
		{
			"open": 516.6575,
			"high": 516.7,
			"low": 516.375,
			"close": 516.515,
			"volume": 78126,
			"datetime": 1712687400000
		},
		{
			"open": 516.52,
			"high": 516.55,
			"low": 516.41,
			"close": 516.51,
			"volume": 62307,
			"datetime": 1712687460000
		},
		{
			"open": 516.5,
			"high": 516.625,
			"low": 516.4896,
			"close": 516.55,
			"volume": 85028,
			"datetime": 1712687520000
		},
		{
			"open": 516.55,
			"high": 516.595,
			"low": 516.45,
			"close": 516.46,
			"volume": 67476,
			"datetime": 1712687580000
		},
		{
			"open": 516.47,
			"high": 516.59,
			"low": 516.45,
			"close": 516.5804,
			"volume": 40900,
			"datetime": 1712687640000
		},
		{
			"open": 516.585,
			"high": 516.85,
			"low": 516.4603,
			"close": 516.77,
			"volume": 131270,
			"datetime": 1712687700000
		},
		{
			"open": 516.79,
			"high": 516.91,
			"low": 516.67,
			"close": 516.68,
			"volume": 77469,
			"datetime": 1712687760000
		},
		{
			"open": 516.67,
			"high": 516.7,
			"low": 516.55,
			"close": 516.68,
			"volume": 50566,
			"datetime": 1712687820000
		},
		{
			"open": 516.68,
			"high": 516.82,
			"low": 516.68,
			"close": 516.765,
			"volume": 37797,
			"datetime": 1712687880000
		},
		{
			"open": 516.74,
			"high": 516.74,
			"low": 516.4,
			"close": 516.48,
			"volume": 80341,
			"datetime": 1712687940000
		},
		{
			"open": 516.47,
			"high": 516.6,
			"low": 516.46,
			"close": 516.48,
			"volume": 40184,
			"datetime": 1712688000000
		},
		{
			"open": 516.47,
			"high": 516.63,
			"low": 516.345,
			"close": 516.53,
			"volume": 83519,
			"datetime": 1712688060000
		},
		{
			"open": 516.525,
			"high": 516.63,
			"low": 516.4595,
			"close": 516.62,
			"volume": 43012,
			"datetime": 1712688120000
		},
		{
			"open": 516.63,
			"high": 516.72,
			"low": 516.58,
			"close": 516.64,
			"volume": 49237,
			"datetime": 1712688180000
		},
		{
			"open": 516.645,
			"high": 516.68,
			"low": 516.43,
			"close": 516.4786,
			"volume": 34954,
			"datetime": 1712688240000
		},
		{
			"open": 516.48,
			"high": 516.53,
			"low": 516.34,
			"close": 516.34,
			"volume": 55529,
			"datetime": 1712688300000
		},
		{
			"open": 516.35,
			"high": 516.48,
			"low": 516.295,
			"close": 516.45,
			"volume": 53837,
			"datetime": 1712688360000
		},
		{
			"open": 516.47,
			"high": 516.56,
			"low": 516.4,
			"close": 516.555,
			"volume": 71682,
			"datetime": 1712688420000
		},
		{
			"open": 516.55,
			"high": 516.7,
			"low": 516.5001,
			"close": 516.51,
			"volume": 59414,
			"datetime": 1712688480000
		},
		{
			"open": 516.495,
			"high": 516.495,
			"low": 516.335,
			"close": 516.4,
			"volume": 36752,
			"datetime": 1712688540000
		},
		{
			"open": 516.41,
			"high": 516.52,
			"low": 516.2599,
			"close": 516.46,
			"volume": 70957,
			"datetime": 1712688600000
		},
		{
			"open": 516.46,
			"high": 516.61,
			"low": 516.3706,
			"close": 516.52,
			"volume": 80887,
			"datetime": 1712688660000
		},
		{
			"open": 516.53,
			"high": 516.75,
			"low": 516.52,
			"close": 516.655,
			"volume": 105028,
			"datetime": 1712688720000
		},
		{
			"open": 516.66,
			"high": 516.815,
			"low": 516.65,
			"close": 516.75,
			"volume": 43001,
			"datetime": 1712688780000
		},
		{
			"open": 516.735,
			"high": 516.74,
			"low": 516.595,
			"close": 516.606,
			"volume": 36829,
			"datetime": 1712688840000
		},
		{
			"open": 516.6,
			"high": 516.63,
			"low": 516.49,
			"close": 516.56,
			"volume": 55956,
			"datetime": 1712688900000
		},
		{
			"open": 516.57,
			"high": 516.72,
			"low": 516.535,
			"close": 516.68,
			"volume": 50754,
			"datetime": 1712688960000
		},
		{
			"open": 516.68,
			"high": 516.6898,
			"low": 516.55,
			"close": 516.61,
			"volume": 30287,
			"datetime": 1712689020000
		},
		{
			"open": 516.6,
			"high": 516.6791,
			"low": 516.48,
			"close": 516.67,
			"volume": 56033,
			"datetime": 1712689080000
		},
		{
			"open": 516.6605,
			"high": 516.665,
			"low": 516.46,
			"close": 516.51,
			"volume": 38120,
			"datetime": 1712689140000
		},
		{
			"open": 516.5,
			"high": 516.52,
			"low": 516.23,
			"close": 516.245,
			"volume": 89901,
			"datetime": 1712689200000
		},
		{
			"open": 516.22,
			"high": 516.29,
			"low": 516.135,
			"close": 516.25,
			"volume": 106338,
			"datetime": 1712689260000
		},
		{
			"open": 516.24,
			"high": 516.25,
			"low": 516.04,
			"close": 516.06,
			"volume": 63027,
			"datetime": 1712689320000
		},
		{
			"open": 516.045,
			"high": 516.4,
			"low": 516.035,
			"close": 516.36,
			"volume": 74009,
			"datetime": 1712689380000
		},
		{
			"open": 516.36,
			"high": 516.3701,
			"low": 516.23,
			"close": 516.34,
			"volume": 35262,
			"datetime": 1712689440000
		},
		{
			"open": 516.32,
			"high": 516.39,
			"low": 516.28,
			"close": 516.34,
			"volume": 57737,
			"datetime": 1712689500000
		},
		{
			"open": 516.34,
			"high": 516.46,
			"low": 516.19,
			"close": 516.19,
			"volume": 116344,
			"datetime": 1712689560000
		},
		{
			"open": 516.19,
			"high": 516.215,
			"low": 516.04,
			"close": 516.14,
			"volume": 61770,
			"datetime": 1712689620000
		},
		{
			"open": 516.14,
			"high": 516.17,
			"low": 515.98,
			"close": 516.04,
			"volume": 62914,
			"datetime": 1712689680000
		},
		{
			"open": 516.04,
			"high": 516.11,
			"low": 515.77,
			"close": 515.915,
			"volume": 148281,
			"datetime": 1712689740000
		},
		{
			"open": 515.9,
			"high": 515.9908,
			"low": 515.725,
			"close": 515.83,
			"volume": 77858,
			"datetime": 1712689800000
		},
		{
			"open": 515.82,
			"high": 515.85,
			"low": 515.71,
			"close": 515.8399,
			"volume": 78249,
			"datetime": 1712689860000
		},
		{
			"open": 515.82,
			"high": 516.02,
			"low": 515.8099,
			"close": 515.98,
			"volume": 77091,
			"datetime": 1712689920000
		},
		{
			"open": 515.97,
			"high": 516.15,
			"low": 515.92,
			"close": 516.115,
			"volume": 58924,
			"datetime": 1712689980000
		},
		{
			"open": 516.14,
			"high": 516.21,
			"low": 516.07,
			"close": 516.07,
			"volume": 74822,
			"datetime": 1712690040000
		},
		{
			"open": 516.065,
			"high": 516.245,
			"low": 516.03,
			"close": 516.2303,
			"volume": 75945,
			"datetime": 1712690100000
		},
		{
			"open": 516.235,
			"high": 516.39,
			"low": 516.1673,
			"close": 516.31,
			"volume": 91508,
			"datetime": 1712690160000
		},
		{
			"open": 516.32,
			"high": 516.48,
			"low": 516.2905,
			"close": 516.465,
			"volume": 67244,
			"datetime": 1712690220000
		},
		{
			"open": 516.465,
			"high": 516.63,
			"low": 516.435,
			"close": 516.535,
			"volume": 77001,
			"datetime": 1712690280000
		},
		{
			"open": 516.5331,
			"high": 516.75,
			"low": 516.5,
			"close": 516.75,
			"volume": 68944,
			"datetime": 1712690340000
		},
		{
			"open": 516.75,
			"high": 516.9001,
			"low": 516.7,
			"close": 516.78,
			"volume": 130918,
			"datetime": 1712690400000
		},
		{
			"open": 516.78,
			"high": 516.96,
			"low": 516.695,
			"close": 516.9399,
			"volume": 84758,
			"datetime": 1712690460000
		},
		{
			"open": 516.93,
			"high": 517.46,
			"low": 516.8813,
			"close": 517.25,
			"volume": 170991,
			"datetime": 1712690520000
		},
		{
			"open": 517.26,
			"high": 517.41,
			"low": 517.22,
			"close": 517.35,
			"volume": 85817,
			"datetime": 1712690580000
		},
		{
			"open": 517.335,
			"high": 517.44,
			"low": 517.275,
			"close": 517.39,
			"volume": 69451,
			"datetime": 1712690640000
		},
		{
			"open": 517.37,
			"high": 517.64,
			"low": 517.37,
			"close": 517.5201,
			"volume": 126624,
			"datetime": 1712690700000
		},
		{
			"open": 517.52,
			"high": 518.07,
			"low": 517.52,
			"close": 517.8799,
			"volume": 288349,
			"datetime": 1712690760000
		},
		{
			"open": 517.874,
			"high": 517.99,
			"low": 517.845,
			"close": 517.95,
			"volume": 258321,
			"datetime": 1712690820000
		},
		{
			"open": 517.95,
			"high": 518.12,
			"low": 517.91,
			"close": 518.03,
			"volume": 241920,
			"datetime": 1712690880000
		},
		{
			"open": 518.04,
			"high": 518.61,
			"low": 518.04,
			"close": 518.55,
			"volume": 366959,
			"datetime": 1712690940000
		},
		{
			"open": 518.51,
			"high": 518.615,
			"low": 518.26,
			"close": 518.38,
			"volume": 255634,
			"datetime": 1712691000000
		},
		{
			"open": 518.38,
			"high": 519.02,
			"low": 518.38,
			"close": 518.71,
			"volume": 308570,
			"datetime": 1712691060000
		},
		{
			"open": 518.71,
			"high": 518.86,
			"low": 518.6,
			"close": 518.6999,
			"volume": 231673,
			"datetime": 1712691120000
		},
		{
			"open": 518.69,
			"high": 518.77,
			"low": 518.55,
			"close": 518.69,
			"volume": 154555,
			"datetime": 1712691180000
		},
		{
			"open": 518.72,
			"high": 518.73,
			"low": 518.335,
			"close": 518.45,
			"volume": 175660,
			"datetime": 1712691240000
		},
		{
			"open": 518.47,
			"high": 518.6,
			"low": 518.3675,
			"close": 518.55,
			"volume": 146299,
			"datetime": 1712691300000
		},
		{
			"open": 518.56,
			"high": 518.56,
			"low": 518.26,
			"close": 518.3701,
			"volume": 128570,
			"datetime": 1712691360000
		},
		{
			"open": 518.39,
			"high": 518.39,
			"low": 518.02,
			"close": 518.04,
			"volume": 181417,
			"datetime": 1712691420000
		},
		{
			"open": 518.04,
			"high": 518.16,
			"low": 518.02,
			"close": 518.1102,
			"volume": 105026,
			"datetime": 1712691480000
		},
		{
			"open": 518.13,
			"high": 518.14,
			"low": 517.78,
			"close": 517.795,
			"volume": 146748,
			"datetime": 1712691540000
		},
		{
			"open": 517.8,
			"high": 517.9988,
			"low": 517.8,
			"close": 517.93,
			"volume": 140433,
			"datetime": 1712691600000
		},
		{
			"open": 517.93,
			"high": 517.94,
			"low": 517.465,
			"close": 517.47,
			"volume": 230677,
			"datetime": 1712691660000
		},
		{
			"open": 517.48,
			"high": 517.86,
			"low": 517.45,
			"close": 517.81,
			"volume": 160047,
			"datetime": 1712691720000
		},
		{
			"open": 517.83,
			"high": 517.92,
			"low": 517.7,
			"close": 517.74,
			"volume": 88199,
			"datetime": 1712691780000
		},
		{
			"open": 517.7482,
			"high": 518.11,
			"low": 517.745,
			"close": 518.03,
			"volume": 163703,
			"datetime": 1712691840000
		},
		{
			"open": 518.03,
			"high": 518.14,
			"low": 517.77,
			"close": 517.8,
			"volume": 151966,
			"datetime": 1712691900000
		},
		{
			"open": 517.79,
			"high": 517.899,
			"low": 517.71,
			"close": 517.85,
			"volume": 113901,
			"datetime": 1712691960000
		},
		{
			"open": 517.84,
			"high": 517.91,
			"low": 517.72,
			"close": 517.84,
			"volume": 113414,
			"datetime": 1712692020000
		},
		{
			"open": 517.85,
			"high": 517.98,
			"low": 517.755,
			"close": 517.86,
			"volume": 127162,
			"datetime": 1712692080000
		},
		{
			"open": 517.855,
			"high": 517.96,
			"low": 517.725,
			"close": 517.95,
			"volume": 152828,
			"datetime": 1712692140000
		},
		{
			"open": 517.95,
			"high": 517.97,
			"low": 517.75,
			"close": 517.9495,
			"volume": 210627,
			"datetime": 1712692200000
		},
		{
			"open": 517.95,
			"high": 517.98,
			"low": 517.6801,
			"close": 517.76,
			"volume": 125177,
			"datetime": 1712692260000
		},
		{
			"open": 517.75,
			"high": 518.05,
			"low": 517.71,
			"close": 518.0494,
			"volume": 125924,
			"datetime": 1712692320000
		},
		{
			"open": 518.05,
			"high": 518.07,
			"low": 517.81,
			"close": 517.93,
			"volume": 163011,
			"datetime": 1712692380000
		},
		{
			"open": 517.925,
			"high": 518.21,
			"low": 517.925,
			"close": 518.21,
			"volume": 314556,
			"datetime": 1712692440000
		},
		{
			"open": 518.2,
			"high": 518.635,
			"low": 518.2,
			"close": 518.6,
			"volume": 452477,
			"datetime": 1712692500000
		},
		{
			"open": 518.61,
			"high": 518.725,
			"low": 518.525,
			"close": 518.725,
			"volume": 415972,
			"datetime": 1712692560000
		},
		{
			"open": 518.725,
			"high": 518.74,
			"low": 518.37,
			"close": 518.57,
			"volume": 541706,
			"datetime": 1712692620000
		},
		{
			"open": 518.565,
			"high": 518.87,
			"low": 518.47,
			"close": 518.86,
			"volume": 907766,
			"datetime": 1712692680000
		},
		{
			"open": 518.85,
			"high": 519.44,
			"low": 518.81,
			"close": 519.29,
			"volume": 2166451,
			"datetime": 1712692740000
		},
		{
            "open": 519.15,
            "high": 519.38,
            "low": 518.985,
            "close": 519.03,
            "volume": 469051,
            "datetime": 1712583000000
        },
        {
            "open": 519.03,
            "high": 519.37,
            "low": 518.93,
            "close": 519.34,
            "volume": 216371,
            "datetime": 1712583060000
        },
        {
            "open": 519.34,
            "high": 519.39,
            "low": 519.05,
            "close": 519.125,
            "volume": 122227,
            "datetime": 1712583120000
        },
        {
            "open": 519.15,
            "high": 519.27,
            "low": 519.01,
            "close": 519.03,
            "volume": 140180,
            "datetime": 1712583180000
        },
        {
            "open": 519.07,
            "high": 519.23,
            "low": 518.91,
            "close": 518.965,
            "volume": 115187,
            "datetime": 1712583240000
        },
        {
            "open": 518.97,
            "high": 518.97,
            "low": 518.62,
            "close": 518.635,
            "volume": 159774,
            "datetime": 1712583300000
        },
        {
            "open": 518.63,
            "high": 518.8399,
            "low": 518.61,
            "close": 518.63,
            "volume": 177958,
            "datetime": 1712583360000
        },
        {
            "open": 518.63,
            "high": 518.825,
            "low": 518.63,
            "close": 518.68,
            "volume": 144555,
            "datetime": 1712583420000
        },
        {
            "open": 518.68,
            "high": 518.73,
            "low": 518.43,
            "close": 518.5301,
            "volume": 131356,
            "datetime": 1712583480000
        },
        {
            "open": 518.53,
            "high": 518.59,
            "low": 518.23,
            "close": 518.27,
            "volume": 142273,
            "datetime": 1712583540000
        },
        {
            "open": 518.28,
            "high": 518.31,
            "low": 517.98,
            "close": 518.1313,
            "volume": 152214,
            "datetime": 1712583600000
        },
        {
            "open": 518.14,
            "high": 518.61,
            "low": 518.14,
            "close": 518.51,
            "volume": 183041,
            "datetime": 1712583660000
        },
        {
            "open": 518.51,
            "high": 518.63,
            "low": 518.32,
            "close": 518.6099,
            "volume": 143282,
            "datetime": 1712583720000
        },
        {
            "open": 518.61,
            "high": 518.705,
            "low": 518.55,
            "close": 518.6799,
            "volume": 78955,
            "datetime": 1712583780000
        },
        {
            "open": 518.6999,
            "high": 518.89,
            "low": 518.61,
            "close": 518.68,
            "volume": 121833,
            "datetime": 1712583840000
        },
        {
            "open": 518.7,
            "high": 518.95,
            "low": 518.67,
            "close": 518.935,
            "volume": 158172,
            "datetime": 1712583900000
        },
        {
            "open": 518.93,
            "high": 519.075,
            "low": 518.85,
            "close": 518.95,
            "volume": 197396,
            "datetime": 1712583960000
        },
        {
            "open": 518.96,
            "high": 519.02,
            "low": 518.77,
            "close": 518.77,
            "volume": 102563,
            "datetime": 1712584020000
        },
        {
            "open": 518.76,
            "high": 518.96,
            "low": 518.75,
            "close": 518.95,
            "volume": 83337,
            "datetime": 1712584080000
        },
        {
            "open": 518.94,
            "high": 518.99,
            "low": 518.6717,
            "close": 518.73,
            "volume": 112066,
            "datetime": 1712584140000
        },
        {
            "open": 518.72,
            "high": 518.84,
            "low": 518.44,
            "close": 518.47,
            "volume": 121815,
            "datetime": 1712584200000
        },
        {
            "open": 518.46,
            "high": 518.63,
            "low": 518.46,
            "close": 518.57,
            "volume": 86184,
            "datetime": 1712584260000
        },
        {
            "open": 518.54,
            "high": 518.76,
            "low": 518.52,
            "close": 518.605,
            "volume": 99603,
            "datetime": 1712584320000
        },
        {
            "open": 518.605,
            "high": 518.89,
            "low": 518.57,
            "close": 518.86,
            "volume": 102857,
            "datetime": 1712584380000
        },
        {
            "open": 518.86,
            "high": 518.9199,
            "low": 518.74,
            "close": 518.77,
            "volume": 77615,
            "datetime": 1712584440000
        },
        {
            "open": 518.78,
            "high": 518.83,
            "low": 518.71,
            "close": 518.795,
            "volume": 63471,
            "datetime": 1712584500000
        },
        {
            "open": 518.7997,
            "high": 519.07,
            "low": 518.75,
            "close": 518.935,
            "volume": 95390,
            "datetime": 1712584560000
        },
        {
            "open": 518.94,
            "high": 518.945,
            "low": 518.73,
            "close": 518.84,
            "volume": 68094,
            "datetime": 1712584620000
        },
        {
            "open": 518.84,
            "high": 518.91,
            "low": 518.73,
            "close": 518.86,
            "volume": 65187,
            "datetime": 1712584680000
        },
        {
            "open": 518.87,
            "high": 518.96,
            "low": 518.78,
            "close": 518.83,
            "volume": 42953,
            "datetime": 1712584740000
        },
        {
            "open": 518.84,
            "high": 518.885,
            "low": 518.6399,
            "close": 518.76,
            "volume": 113751,
            "datetime": 1712584800000
        },
        {
            "open": 518.76,
            "high": 518.865,
            "low": 518.6,
            "close": 518.86,
            "volume": 84966,
            "datetime": 1712584860000
        },
        {
            "open": 518.87,
            "high": 518.9,
            "low": 518.54,
            "close": 518.7,
            "volume": 65322,
            "datetime": 1712584920000
        },
        {
            "open": 518.7103,
            "high": 518.7103,
            "low": 518.28,
            "close": 518.37,
            "volume": 107095,
            "datetime": 1712584980000
        },
        {
            "open": 518.37,
            "high": 518.535,
            "low": 518.33,
            "close": 518.46,
            "volume": 66150,
            "datetime": 1712585040000
        },
        {
            "open": 518.46,
            "high": 518.59,
            "low": 518.37,
            "close": 518.52,
            "volume": 82978,
            "datetime": 1712585100000
        },
        {
            "open": 518.54,
            "high": 518.72,
            "low": 518.35,
            "close": 518.42,
            "volume": 103326,
            "datetime": 1712585160000
        },
        {
            "open": 518.4151,
            "high": 518.45,
            "low": 518.2,
            "close": 518.29,
            "volume": 104191,
            "datetime": 1712585220000
        },
        {
            "open": 518.31,
            "high": 518.8097,
            "low": 518.2998,
            "close": 518.78,
            "volume": 103225,
            "datetime": 1712585280000
        },
        {
            "open": 518.8004,
            "high": 518.86,
            "low": 518.6,
            "close": 518.635,
            "volume": 58126,
            "datetime": 1712585340000
        },
        {
            "open": 518.645,
            "high": 518.79,
            "low": 518.63,
            "close": 518.75,
            "volume": 75660,
            "datetime": 1712585400000
        },
        {
            "open": 518.74,
            "high": 518.93,
            "low": 518.7301,
            "close": 518.76,
            "volume": 88003,
            "datetime": 1712585460000
        },
        {
            "open": 518.77,
            "high": 518.81,
            "low": 518.58,
            "close": 518.61,
            "volume": 61174,
            "datetime": 1712585520000
        },
        {
            "open": 518.6,
            "high": 518.655,
            "low": 518.455,
            "close": 518.62,
            "volume": 86812,
            "datetime": 1712585580000
        },
        {
            "open": 518.6,
            "high": 518.8,
            "low": 518.58,
            "close": 518.77,
            "volume": 75256,
            "datetime": 1712585640000
        },
        {
            "open": 518.75,
            "high": 518.98,
            "low": 518.65,
            "close": 518.72,
            "volume": 116400,
            "datetime": 1712585700000
        },
        {
            "open": 518.735,
            "high": 518.75,
            "low": 518.56,
            "close": 518.59,
            "volume": 65617,
            "datetime": 1712585760000
        },
        {
            "open": 518.58,
            "high": 518.76,
            "low": 518.52,
            "close": 518.755,
            "volume": 80731,
            "datetime": 1712585820000
        },
        {
            "open": 518.75,
            "high": 518.95,
            "low": 518.61,
            "close": 518.62,
            "volume": 81826,
            "datetime": 1712585880000
        },
        {
            "open": 518.61,
            "high": 518.6834,
            "low": 518.55,
            "close": 518.67,
            "volume": 82829,
            "datetime": 1712585940000
        },
        {
            "open": 518.69,
            "high": 518.92,
            "low": 518.68,
            "close": 518.9,
            "volume": 78885,
            "datetime": 1712586000000
        },
        {
            "open": 518.89,
            "high": 518.895,
            "low": 518.73,
            "close": 518.76,
            "volume": 48151,
            "datetime": 1712586060000
        },
        {
            "open": 518.78,
            "high": 519.17,
            "low": 518.74,
            "close": 519.0203,
            "volume": 95303,
            "datetime": 1712586120000
        },
        {
            "open": 519.02,
            "high": 519.03,
            "low": 518.9,
            "close": 518.985,
            "volume": 69164,
            "datetime": 1712586180000
        },
        {
            "open": 518.99,
            "high": 519.18,
            "low": 518.9808,
            "close": 519.16,
            "volume": 105165,
            "datetime": 1712586240000
        },
        {
            "open": 519.17,
            "high": 519.52,
            "low": 519.08,
            "close": 519.21,
            "volume": 251023,
            "datetime": 1712586300000
        },
        {
            "open": 519.23,
            "high": 519.4575,
            "low": 519.18,
            "close": 519.4502,
            "volume": 73681,
            "datetime": 1712586360000
        },
        {
            "open": 519.45,
            "high": 519.53,
            "low": 519.34,
            "close": 519.455,
            "volume": 166673,
            "datetime": 1712586420000
        },
        {
            "open": 519.4544,
            "high": 519.54,
            "low": 519.37,
            "close": 519.44,
            "volume": 150549,
            "datetime": 1712586480000
        },
        {
            "open": 519.45,
            "high": 519.58,
            "low": 519.44,
            "close": 519.537,
            "volume": 96875,
            "datetime": 1712586540000
        },
        {
            "open": 519.53,
            "high": 519.82,
            "low": 519.49,
            "close": 519.76,
            "volume": 231331,
            "datetime": 1712586600000
        },
        {
            "open": 519.785,
            "high": 519.8,
            "low": 519.66,
            "close": 519.78,
            "volume": 123999,
            "datetime": 1712586660000
        },
        {
            "open": 519.8,
            "high": 519.89,
            "low": 519.75,
            "close": 519.84,
            "volume": 140630,
            "datetime": 1712586720000
        },
        {
            "open": 519.855,
            "high": 520.12,
            "low": 519.755,
            "close": 519.93,
            "volume": 186712,
            "datetime": 1712586780000
        },
        {
            "open": 519.94,
            "high": 519.955,
            "low": 519.76,
            "close": 519.8,
            "volume": 96560,
            "datetime": 1712586840000
        },
        {
            "open": 519.8,
            "high": 519.92,
            "low": 519.775,
            "close": 519.8516,
            "volume": 131678,
            "datetime": 1712586900000
        },
        {
            "open": 519.86,
            "high": 519.9399,
            "low": 519.79,
            "close": 519.82,
            "volume": 92193,
            "datetime": 1712586960000
        },
        {
            "open": 519.82,
            "high": 519.92,
            "low": 519.75,
            "close": 519.82,
            "volume": 69114,
            "datetime": 1712587020000
        },
        {
            "open": 519.83,
            "high": 519.91,
            "low": 519.83,
            "close": 519.88,
            "volume": 53134,
            "datetime": 1712587080000
        },
        {
            "open": 519.89,
            "high": 519.9,
            "low": 519.82,
            "close": 519.83,
            "volume": 79645,
            "datetime": 1712587140000
        },
        {
            "open": 519.83,
            "high": 519.88,
            "low": 519.77,
            "close": 519.855,
            "volume": 53688,
            "datetime": 1712587200000
        },
        {
            "open": 519.86,
            "high": 519.88,
            "low": 519.62,
            "close": 519.755,
            "volume": 93104,
            "datetime": 1712587260000
        },
        {
            "open": 519.7562,
            "high": 519.8,
            "low": 519.615,
            "close": 519.64,
            "volume": 57967,
            "datetime": 1712587320000
        },
        {
            "open": 519.631,
            "high": 519.6801,
            "low": 519.545,
            "close": 519.64,
            "volume": 73292,
            "datetime": 1712587380000
        },
        {
            "open": 519.625,
            "high": 519.75,
            "low": 519.625,
            "close": 519.725,
            "volume": 75202,
            "datetime": 1712587440000
        },
        {
            "open": 519.74,
            "high": 519.84,
            "low": 519.74,
            "close": 519.78,
            "volume": 55575,
            "datetime": 1712587500000
        },
        {
            "open": 519.78,
            "high": 519.84,
            "low": 519.74,
            "close": 519.79,
            "volume": 49877,
            "datetime": 1712587560000
        },
        {
            "open": 519.7799,
            "high": 519.84,
            "low": 519.705,
            "close": 519.79,
            "volume": 80044,
            "datetime": 1712587620000
        },
        {
            "open": 519.77,
            "high": 519.83,
            "low": 519.72,
            "close": 519.79,
            "volume": 60025,
            "datetime": 1712587680000
        },
        {
            "open": 519.79,
            "high": 519.95,
            "low": 519.78,
            "close": 519.95,
            "volume": 109503,
            "datetime": 1712587740000
        },
        {
            "open": 519.95,
            "high": 520.1694,
            "low": 519.95,
            "close": 520.0215,
            "volume": 162870,
            "datetime": 1712587800000
        },
        {
            "open": 520.02,
            "high": 520.075,
            "low": 519.97,
            "close": 520.045,
            "volume": 61498,
            "datetime": 1712587860000
        },
        {
            "open": 520.0451,
            "high": 520.0451,
            "low": 519.92,
            "close": 519.97,
            "volume": 47820,
            "datetime": 1712587920000
        },
        {
            "open": 519.97,
            "high": 520.065,
            "low": 519.96,
            "close": 519.99,
            "volume": 45835,
            "datetime": 1712587980000
        },
        {
            "open": 519.99,
            "high": 520.14,
            "low": 519.96,
            "close": 520.11,
            "volume": 53243,
            "datetime": 1712588040000
        },
        {
            "open": 520.12,
            "high": 520.18,
            "low": 520.03,
            "close": 520.055,
            "volume": 86337,
            "datetime": 1712588100000
        },
        {
            "open": 520.06,
            "high": 520.08,
            "low": 519.92,
            "close": 519.92,
            "volume": 91106,
            "datetime": 1712588160000
        },
        {
            "open": 519.92,
            "high": 519.99,
            "low": 519.82,
            "close": 519.8516,
            "volume": 76234,
            "datetime": 1712588220000
        },
        {
            "open": 519.86,
            "high": 519.97,
            "low": 519.85,
            "close": 519.9,
            "volume": 89989,
            "datetime": 1712588280000
        },
        {
            "open": 519.89,
            "high": 520.01,
            "low": 519.89,
            "close": 519.99,
            "volume": 72147,
            "datetime": 1712588340000
        },
        {
            "open": 519.99,
            "high": 520.13,
            "low": 519.99,
            "close": 520.07,
            "volume": 78541,
            "datetime": 1712588400000
        },
        {
            "open": 520.09,
            "high": 520.1,
            "low": 519.83,
            "close": 519.8499,
            "volume": 83638,
            "datetime": 1712588460000
        },
        {
            "open": 519.85,
            "high": 519.8686,
            "low": 519.33,
            "close": 519.47,
            "volume": 160956,
            "datetime": 1712588520000
        },
        {
            "open": 519.48,
            "high": 519.74,
            "low": 519.4,
            "close": 519.705,
            "volume": 112843,
            "datetime": 1712588580000
        },
        {
            "open": 519.71,
            "high": 519.83,
            "low": 519.655,
            "close": 519.78,
            "volume": 87229,
            "datetime": 1712588640000
        },
        {
            "open": 519.7724,
            "high": 519.88,
            "low": 519.755,
            "close": 519.76,
            "volume": 67108,
            "datetime": 1712588700000
        },
        {
            "open": 519.765,
            "high": 519.87,
            "low": 519.725,
            "close": 519.798,
            "volume": 57117,
            "datetime": 1712588760000
        },
        {
            "open": 519.78,
            "high": 519.8,
            "low": 519.705,
            "close": 519.77,
            "volume": 41316,
            "datetime": 1712588820000
        },
        {
            "open": 519.77,
            "high": 519.8,
            "low": 519.59,
            "close": 519.66,
            "volume": 58702,
            "datetime": 1712588880000
        },
        {
            "open": 519.64,
            "high": 519.82,
            "low": 519.64,
            "close": 519.76,
            "volume": 34416,
            "datetime": 1712588940000
        },
        {
            "open": 519.79,
            "high": 520.01,
            "low": 519.7687,
            "close": 519.96,
            "volume": 78580,
            "datetime": 1712589000000
        },
        {
            "open": 519.955,
            "high": 519.955,
            "low": 519.87,
            "close": 519.9101,
            "volume": 37444,
            "datetime": 1712589060000
        },
        {
            "open": 519.91,
            "high": 519.96,
            "low": 519.7,
            "close": 519.7,
            "volume": 69349,
            "datetime": 1712589120000
        },
        {
            "open": 519.7,
            "high": 519.78,
            "low": 519.65,
            "close": 519.73,
            "volume": 37200,
            "datetime": 1712589180000
        },
        {
            "open": 519.74,
            "high": 519.8,
            "low": 519.65,
            "close": 519.77,
            "volume": 43199,
            "datetime": 1712589240000
        },
        {
            "open": 519.775,
            "high": 519.82,
            "low": 519.59,
            "close": 519.62,
            "volume": 50714,
            "datetime": 1712589300000
        },
        {
            "open": 519.61,
            "high": 519.69,
            "low": 519.56,
            "close": 519.63,
            "volume": 47565,
            "datetime": 1712589360000
        },
        {
            "open": 519.65,
            "high": 519.687,
            "low": 519.6,
            "close": 519.61,
            "volume": 45953,
            "datetime": 1712589420000
        },
        {
            "open": 519.61,
            "high": 519.75,
            "low": 519.575,
            "close": 519.695,
            "volume": 62771,
            "datetime": 1712589480000
        },
        {
            "open": 519.7,
            "high": 519.7001,
            "low": 519.56,
            "close": 519.62,
            "volume": 32262,
            "datetime": 1712589540000
        },
        {
            "open": 519.61,
            "high": 519.7,
            "low": 519.56,
            "close": 519.6,
            "volume": 34711,
            "datetime": 1712589600000
        },
        {
            "open": 519.595,
            "high": 519.62,
            "low": 519.45,
            "close": 519.615,
            "volume": 50568,
            "datetime": 1712589660000
        },
        {
            "open": 519.64,
            "high": 519.76,
            "low": 519.555,
            "close": 519.72,
            "volume": 54534,
            "datetime": 1712589720000
        },
        {
            "open": 519.72,
            "high": 519.81,
            "low": 519.69,
            "close": 519.79,
            "volume": 50098,
            "datetime": 1712589780000
        },
        {
            "open": 519.79,
            "high": 519.91,
            "low": 519.78,
            "close": 519.865,
            "volume": 63679,
            "datetime": 1712589840000
        },
        {
            "open": 519.86,
            "high": 519.88,
            "low": 519.64,
            "close": 519.65,
            "volume": 57320,
            "datetime": 1712589900000
        },
        {
            "open": 519.6499,
            "high": 519.69,
            "low": 519.595,
            "close": 519.625,
            "volume": 37947,
            "datetime": 1712589960000
        },
        {
            "open": 519.6191,
            "high": 519.6191,
            "low": 519.39,
            "close": 519.39,
            "volume": 64312,
            "datetime": 1712590020000
        },
        {
            "open": 519.3998,
            "high": 519.5079,
            "low": 519.385,
            "close": 519.471,
            "volume": 59483,
            "datetime": 1712590080000
        },
        {
            "open": 519.47,
            "high": 519.59,
            "low": 519.47,
            "close": 519.525,
            "volume": 65360,
            "datetime": 1712590140000
        },
        {
            "open": 519.5,
            "high": 519.51,
            "low": 519.185,
            "close": 519.31,
            "volume": 128699,
            "datetime": 1712590200000
        },
        {
            "open": 519.28,
            "high": 519.375,
            "low": 519.225,
            "close": 519.37,
            "volume": 57380,
            "datetime": 1712590260000
        },
        {
            "open": 519.375,
            "high": 519.42,
            "low": 519.32,
            "close": 519.36,
            "volume": 51342,
            "datetime": 1712590320000
        },
        {
            "open": 519.36,
            "high": 519.469,
            "low": 519.305,
            "close": 519.32,
            "volume": 57267,
            "datetime": 1712590380000
        },
        {
            "open": 519.32,
            "high": 519.36,
            "low": 519.26,
            "close": 519.34,
            "volume": 46914,
            "datetime": 1712590440000
        },
        {
            "open": 519.33,
            "high": 519.35,
            "low": 519.1,
            "close": 519.19,
            "volume": 106024,
            "datetime": 1712590500000
        },
        {
            "open": 519.18,
            "high": 519.18,
            "low": 518.55,
            "close": 518.81,
            "volume": 309408,
            "datetime": 1712590560000
        },
        {
            "open": 518.83,
            "high": 518.93,
            "low": 518.61,
            "close": 518.93,
            "volume": 151922,
            "datetime": 1712590620000
        },
        {
            "open": 518.93,
            "high": 519.01,
            "low": 518.74,
            "close": 518.82,
            "volume": 109821,
            "datetime": 1712590680000
        },
        {
            "open": 518.81,
            "high": 519.15,
            "low": 518.81,
            "close": 519.09,
            "volume": 92262,
            "datetime": 1712590740000
        },
        {
            "open": 519.073,
            "high": 519.13,
            "low": 518.93,
            "close": 518.97,
            "volume": 72051,
            "datetime": 1712590800000
        },
        {
            "open": 518.9697,
            "high": 519.025,
            "low": 518.815,
            "close": 518.92,
            "volume": 40544,
            "datetime": 1712590860000
        },
        {
            "open": 518.92,
            "high": 518.99,
            "low": 518.87,
            "close": 518.94,
            "volume": 34810,
            "datetime": 1712590920000
        },
        {
            "open": 518.945,
            "high": 519.1,
            "low": 518.945,
            "close": 519.01,
            "volume": 55159,
            "datetime": 1712590980000
        },
        {
            "open": 519.01,
            "high": 519.16,
            "low": 519.01,
            "close": 519.04,
            "volume": 88335,
            "datetime": 1712591040000
        },
        {
            "open": 519.04,
            "high": 519.04,
            "low": 518.44,
            "close": 518.66,
            "volume": 226594,
            "datetime": 1712591100000
        },
        {
            "open": 518.665,
            "high": 518.7945,
            "low": 518.59,
            "close": 518.61,
            "volume": 91070,
            "datetime": 1712591160000
        },
        {
            "open": 518.595,
            "high": 518.68,
            "low": 518.53,
            "close": 518.55,
            "volume": 52033,
            "datetime": 1712591220000
        },
        {
            "open": 518.57,
            "high": 518.865,
            "low": 518.5513,
            "close": 518.8,
            "volume": 93471,
            "datetime": 1712591280000
        },
        {
            "open": 518.79,
            "high": 518.8492,
            "low": 518.75,
            "close": 518.8,
            "volume": 44093,
            "datetime": 1712591340000
        },
        {
            "open": 518.79,
            "high": 518.795,
            "low": 518.67,
            "close": 518.76,
            "volume": 55364,
            "datetime": 1712591400000
        },
        {
            "open": 518.76,
            "high": 518.84,
            "low": 518.7,
            "close": 518.78,
            "volume": 70009,
            "datetime": 1712591460000
        },
        {
            "open": 518.78,
            "high": 518.905,
            "low": 518.76,
            "close": 518.81,
            "volume": 73648,
            "datetime": 1712591520000
        },
        {
            "open": 518.81,
            "high": 518.81,
            "low": 518.57,
            "close": 518.62,
            "volume": 65863,
            "datetime": 1712591580000
        },
        {
            "open": 518.64,
            "high": 518.8399,
            "low": 518.64,
            "close": 518.701,
            "volume": 54585,
            "datetime": 1712591640000
        },
        {
            "open": 518.7,
            "high": 518.73,
            "low": 518.47,
            "close": 518.5201,
            "volume": 64722,
            "datetime": 1712591700000
        },
        {
            "open": 518.51,
            "high": 518.56,
            "low": 518.28,
            "close": 518.42,
            "volume": 99684,
            "datetime": 1712591760000
        },
        {
            "open": 518.42,
            "high": 518.76,
            "low": 518.37,
            "close": 518.69,
            "volume": 89023,
            "datetime": 1712591820000
        },
        {
            "open": 518.67,
            "high": 518.69,
            "low": 518.5599,
            "close": 518.68,
            "volume": 56923,
            "datetime": 1712591880000
        },
        {
            "open": 518.68,
            "high": 518.78,
            "low": 518.65,
            "close": 518.73,
            "volume": 42731,
            "datetime": 1712591940000
        },
        {
            "open": 518.73,
            "high": 518.74,
            "low": 518.3903,
            "close": 518.4,
            "volume": 120184,
            "datetime": 1712592000000
        },
        {
            "open": 518.4,
            "high": 518.4975,
            "low": 518.36,
            "close": 518.39,
            "volume": 90604,
            "datetime": 1712592060000
        },
        {
            "open": 518.38,
            "high": 518.5099,
            "low": 518.285,
            "close": 518.46,
            "volume": 55914,
            "datetime": 1712592120000
        },
        {
            "open": 518.45,
            "high": 518.57,
            "low": 518.44,
            "close": 518.54,
            "volume": 56854,
            "datetime": 1712592180000
        },
        {
            "open": 518.525,
            "high": 518.66,
            "low": 518.4301,
            "close": 518.455,
            "volume": 64466,
            "datetime": 1712592240000
        },
        {
            "open": 518.45,
            "high": 518.505,
            "low": 518.37,
            "close": 518.4,
            "volume": 50985,
            "datetime": 1712592300000
        },
        {
            "open": 518.4,
            "high": 518.58,
            "low": 518.36,
            "close": 518.55,
            "volume": 81614,
            "datetime": 1712592360000
        },
        {
            "open": 518.57,
            "high": 518.73,
            "low": 518.56,
            "close": 518.69,
            "volume": 146977,
            "datetime": 1712592420000
        },
        {
            "open": 518.68,
            "high": 518.78,
            "low": 518.65,
            "close": 518.72,
            "volume": 56456,
            "datetime": 1712592480000
        },
        {
            "open": 518.7,
            "high": 518.7,
            "low": 518.4801,
            "close": 518.4999,
            "volume": 52975,
            "datetime": 1712592540000
        },
        {
            "open": 518.5,
            "high": 518.58,
            "low": 518.4676,
            "close": 518.545,
            "volume": 47821,
            "datetime": 1712592600000
        },
        {
            "open": 518.54,
            "high": 518.56,
            "low": 518.34,
            "close": 518.3899,
            "volume": 53502,
            "datetime": 1712592660000
        },
        {
            "open": 518.38,
            "high": 518.51,
            "low": 518.355,
            "close": 518.4501,
            "volume": 47924,
            "datetime": 1712592720000
        },
        {
            "open": 518.436,
            "high": 518.436,
            "low": 518.0407,
            "close": 518.12,
            "volume": 80972,
            "datetime": 1712592780000
        },
        {
            "open": 518.12,
            "high": 518.3,
            "low": 518.105,
            "close": 518.2499,
            "volume": 81370,
            "datetime": 1712592840000
        },
        {
            "open": 518.24,
            "high": 518.26,
            "low": 518.11,
            "close": 518.26,
            "volume": 54488,
            "datetime": 1712592900000
        },
        {
            "open": 518.25,
            "high": 518.43,
            "low": 518.25,
            "close": 518.35,
            "volume": 90141,
            "datetime": 1712592960000
        },
        {
            "open": 518.351,
            "high": 518.52,
            "low": 518.34,
            "close": 518.52,
            "volume": 68938,
            "datetime": 1712593020000
        },
        {
            "open": 518.52,
            "high": 518.585,
            "low": 518.47,
            "close": 518.5,
            "volume": 48428,
            "datetime": 1712593080000
        },
        {
            "open": 518.51,
            "high": 518.53,
            "low": 518.3616,
            "close": 518.3616,
            "volume": 35180,
            "datetime": 1712593140000
        },
        {
            "open": 518.37,
            "high": 518.45,
            "low": 518.268,
            "close": 518.4,
            "volume": 33197,
            "datetime": 1712593200000
        },
        {
            "open": 518.39,
            "high": 518.4676,
            "low": 518.35,
            "close": 518.45,
            "volume": 40374,
            "datetime": 1712593260000
        },
        {
            "open": 518.46,
            "high": 518.46,
            "low": 518.23,
            "close": 518.2387,
            "volume": 70739,
            "datetime": 1712593320000
        },
        {
            "open": 518.23,
            "high": 518.23,
            "low": 517.89,
            "close": 518.07,
            "volume": 188300,
            "datetime": 1712593380000
        },
        {
            "open": 518.07,
            "high": 518.1201,
            "low": 517.98,
            "close": 518.0601,
            "volume": 64144,
            "datetime": 1712593440000
        },
        {
            "open": 518.06,
            "high": 518.42,
            "low": 518.06,
            "close": 518.405,
            "volume": 130135,
            "datetime": 1712593500000
        },
        {
            "open": 518.4,
            "high": 518.49,
            "low": 518.335,
            "close": 518.36,
            "volume": 59511,
            "datetime": 1712593560000
        },
        {
            "open": 518.36,
            "high": 518.645,
            "low": 518.36,
            "close": 518.56,
            "volume": 90317,
            "datetime": 1712593620000
        },
        {
            "open": 518.57,
            "high": 518.59,
            "low": 518.47,
            "close": 518.56,
            "volume": 67963,
            "datetime": 1712593680000
        },
        {
            "open": 518.55,
            "high": 518.68,
            "low": 518.55,
            "close": 518.5601,
            "volume": 58646,
            "datetime": 1712593740000
        },
        {
            "open": 518.56,
            "high": 518.72,
            "low": 518.46,
            "close": 518.655,
            "volume": 64432,
            "datetime": 1712593800000
        },
        {
            "open": 518.65,
            "high": 518.75,
            "low": 518.555,
            "close": 518.75,
            "volume": 74755,
            "datetime": 1712593860000
        },
        {
            "open": 518.76,
            "high": 518.83,
            "low": 518.705,
            "close": 518.76,
            "volume": 80839,
            "datetime": 1712593920000
        },
        {
            "open": 518.76,
            "high": 518.83,
            "low": 518.68,
            "close": 518.7,
            "volume": 50652,
            "datetime": 1712593980000
        },
        {
            "open": 518.71,
            "high": 518.875,
            "low": 518.71,
            "close": 518.86,
            "volume": 71888,
            "datetime": 1712594040000
        },
        {
            "open": 518.8599,
            "high": 518.96,
            "low": 518.835,
            "close": 518.9051,
            "volume": 69032,
            "datetime": 1712594100000
        },
        {
            "open": 518.9001,
            "high": 518.94,
            "low": 518.76,
            "close": 518.8,
            "volume": 54883,
            "datetime": 1712594160000
        },
        {
            "open": 518.79,
            "high": 518.87,
            "low": 518.65,
            "close": 518.7,
            "volume": 44424,
            "datetime": 1712594220000
        },
        {
            "open": 518.71,
            "high": 518.8284,
            "low": 518.67,
            "close": 518.82,
            "volume": 32065,
            "datetime": 1712594280000
        },
        {
            "open": 518.8273,
            "high": 518.85,
            "low": 518.72,
            "close": 518.72,
            "volume": 24484,
            "datetime": 1712594340000
        },
        {
            "open": 518.71,
            "high": 518.925,
            "low": 518.69,
            "close": 518.88,
            "volume": 87566,
            "datetime": 1712594400000
        },
        {
            "open": 518.88,
            "high": 518.99,
            "low": 518.87,
            "close": 518.95,
            "volume": 62997,
            "datetime": 1712594460000
        },
        {
            "open": 518.945,
            "high": 519.07,
            "low": 518.915,
            "close": 518.99,
            "volume": 63207,
            "datetime": 1712594520000
        },
        {
            "open": 518.99,
            "high": 519.21,
            "low": 518.98,
            "close": 519.19,
            "volume": 74776,
            "datetime": 1712594580000
        },
        {
            "open": 519.19,
            "high": 519.19,
            "low": 519.03,
            "close": 519.0998,
            "volume": 60584,
            "datetime": 1712594640000
        },
        {
            "open": 519.1,
            "high": 519.19,
            "low": 519.04,
            "close": 519.05,
            "volume": 62669,
            "datetime": 1712594700000
        },
        {
            "open": 519.05,
            "high": 519.1,
            "low": 519.02,
            "close": 519.07,
            "volume": 40815,
            "datetime": 1712594760000
        },
        {
            "open": 519.05,
            "high": 519.1,
            "low": 518.94,
            "close": 518.97,
            "volume": 31482,
            "datetime": 1712594820000
        },
        {
            "open": 518.97,
            "high": 519.05,
            "low": 518.94,
            "close": 518.94,
            "volume": 32074,
            "datetime": 1712594880000
        },
        {
            "open": 518.94,
            "high": 519.04,
            "low": 518.86,
            "close": 518.97,
            "volume": 34707,
            "datetime": 1712594940000
        },
        {
            "open": 518.9699,
            "high": 519.1,
            "low": 518.9699,
            "close": 519.03,
            "volume": 33118,
            "datetime": 1712595000000
        },
        {
            "open": 519.01,
            "high": 519.03,
            "low": 518.95,
            "close": 519.02,
            "volume": 58303,
            "datetime": 1712595060000
        },
        {
            "open": 519.03,
            "high": 519.1,
            "low": 519.03,
            "close": 519.05,
            "volume": 24342,
            "datetime": 1712595120000
        },
        {
            "open": 519.04,
            "high": 519.07,
            "low": 518.92,
            "close": 518.9399,
            "volume": 20159,
            "datetime": 1712595180000
        },
        {
            "open": 518.9399,
            "high": 519.08,
            "low": 518.93,
            "close": 519.06,
            "volume": 32821,
            "datetime": 1712595240000
        },
        {
            "open": 519.07,
            "high": 519.0899,
            "low": 519.005,
            "close": 519.07,
            "volume": 23348,
            "datetime": 1712595300000
        },
        {
            "open": 519.08,
            "high": 519.32,
            "low": 519.06,
            "close": 519.26,
            "volume": 137409,
            "datetime": 1712595360000
        },
        {
            "open": 519.255,
            "high": 519.3587,
            "low": 519.2201,
            "close": 519.3,
            "volume": 29424,
            "datetime": 1712595420000
        },
        {
            "open": 519.31,
            "high": 519.38,
            "low": 519.2,
            "close": 519.24,
            "volume": 55592,
            "datetime": 1712595480000
        },
        {
            "open": 519.24,
            "high": 519.44,
            "low": 519.21,
            "close": 519.44,
            "volume": 77040,
            "datetime": 1712595540000
        },
        {
            "open": 519.43,
            "high": 519.46,
            "low": 519.3612,
            "close": 519.3612,
            "volume": 62406,
            "datetime": 1712595600000
        },
        {
            "open": 519.37,
            "high": 519.45,
            "low": 519.36,
            "close": 519.36,
            "volume": 36087,
            "datetime": 1712595660000
        },
        {
            "open": 519.355,
            "high": 519.39,
            "low": 519.21,
            "close": 519.24,
            "volume": 59202,
            "datetime": 1712595720000
        },
        {
            "open": 519.25,
            "high": 519.28,
            "low": 519.2,
            "close": 519.244,
            "volume": 59592,
            "datetime": 1712595780000
        },
        {
            "open": 519.24,
            "high": 519.28,
            "low": 519.18,
            "close": 519.2001,
            "volume": 27240,
            "datetime": 1712595840000
        },
        {
            "open": 519.21,
            "high": 519.27,
            "low": 519.1201,
            "close": 519.18,
            "volume": 44397,
            "datetime": 1712595900000
        },
        {
            "open": 519.19,
            "high": 519.245,
            "low": 519.08,
            "close": 519.1,
            "volume": 37340,
            "datetime": 1712595960000
        },
        {
            "open": 519.11,
            "high": 519.21,
            "low": 519.076,
            "close": 519.16,
            "volume": 34668,
            "datetime": 1712596020000
        },
        {
            "open": 519.15,
            "high": 519.255,
            "low": 519.0984,
            "close": 519.255,
            "volume": 31114,
            "datetime": 1712596080000
        },
        {
            "open": 519.27,
            "high": 519.27,
            "low": 519.13,
            "close": 519.14,
            "volume": 22451,
            "datetime": 1712596140000
        },
        {
            "open": 519.125,
            "high": 519.2,
            "low": 518.99,
            "close": 519.18,
            "volume": 162746,
            "datetime": 1712596200000
        },
        {
            "open": 519.185,
            "high": 519.22,
            "low": 519.11,
            "close": 519.175,
            "volume": 26575,
            "datetime": 1712596260000
        },
        {
            "open": 519.19,
            "high": 519.205,
            "low": 519.0003,
            "close": 519.06,
            "volume": 31163,
            "datetime": 1712596320000
        },
        {
            "open": 519.06,
            "high": 519.06,
            "low": 518.97,
            "close": 519.05,
            "volume": 50371,
            "datetime": 1712596380000
        },
        {
            "open": 519.0501,
            "high": 519.07,
            "low": 518.99,
            "close": 519.05,
            "volume": 53908,
            "datetime": 1712596440000
        },
        {
            "open": 519.06,
            "high": 519.11,
            "low": 519.0099,
            "close": 519.105,
            "volume": 37735,
            "datetime": 1712596500000
        },
        {
            "open": 519.1,
            "high": 519.13,
            "low": 518.86,
            "close": 518.99,
            "volume": 48823,
            "datetime": 1712596560000
        },
        {
            "open": 518.98,
            "high": 518.9901,
            "low": 518.81,
            "close": 518.84,
            "volume": 54688,
            "datetime": 1712596620000
        },
        {
            "open": 518.83,
            "high": 518.94,
            "low": 518.79,
            "close": 518.9213,
            "volume": 67130,
            "datetime": 1712596680000
        },
        {
            "open": 518.93,
            "high": 519.0598,
            "low": 518.9099,
            "close": 518.995,
            "volume": 31471,
            "datetime": 1712596740000
        },
        {
            "open": 518.9809,
            "high": 519.025,
            "low": 518.8938,
            "close": 518.9005,
            "volume": 23125,
            "datetime": 1712596800000
        },
        {
            "open": 518.9,
            "high": 519.06,
            "low": 518.9,
            "close": 518.925,
            "volume": 39081,
            "datetime": 1712596860000
        },
        {
            "open": 518.92,
            "high": 519.1,
            "low": 518.895,
            "close": 519.09,
            "volume": 35779,
            "datetime": 1712596920000
        },
        {
            "open": 519.09,
            "high": 519.16,
            "low": 519.0499,
            "close": 519.09,
            "volume": 41049,
            "datetime": 1712596980000
        },
        {
            "open": 519.085,
            "high": 519.16,
            "low": 519.07,
            "close": 519.09,
            "volume": 72977,
            "datetime": 1712597040000
        },
        {
            "open": 519.1,
            "high": 519.12,
            "low": 518.9801,
            "close": 519.01,
            "volume": 27155,
            "datetime": 1712597100000
        },
        {
            "open": 519.015,
            "high": 519.02,
            "low": 518.92,
            "close": 518.97,
            "volume": 33099,
            "datetime": 1712597160000
        },
        {
            "open": 518.97,
            "high": 519.105,
            "low": 518.95,
            "close": 519.045,
            "volume": 35491,
            "datetime": 1712597220000
        },
        {
            "open": 519.05,
            "high": 519.15,
            "low": 519.05,
            "close": 519.14,
            "volume": 40068,
            "datetime": 1712597280000
        },
        {
            "open": 519.15,
            "high": 519.28,
            "low": 519.075,
            "close": 519.275,
            "volume": 38744,
            "datetime": 1712597340000
        },
        {
            "open": 519.28,
            "high": 519.285,
            "low": 519.09,
            "close": 519.13,
            "volume": 46922,
            "datetime": 1712597400000
        },
        {
            "open": 519.14,
            "high": 519.175,
            "low": 519.08,
            "close": 519.09,
            "volume": 23185,
            "datetime": 1712597460000
        },
        {
            "open": 519.0999,
            "high": 519.14,
            "low": 519.055,
            "close": 519.0899,
            "volume": 29232,
            "datetime": 1712597520000
        },
        {
            "open": 519.09,
            "high": 519.16,
            "low": 519.07,
            "close": 519.13,
            "volume": 24698,
            "datetime": 1712597580000
        },
        {
            "open": 519.13,
            "high": 519.2,
            "low": 519.1,
            "close": 519.115,
            "volume": 39046,
            "datetime": 1712597640000
        },
        {
            "open": 519.12,
            "high": 519.13,
            "low": 518.98,
            "close": 519.05,
            "volume": 58183,
            "datetime": 1712597700000
        },
        {
            "open": 519.045,
            "high": 519.085,
            "low": 518.84,
            "close": 518.86,
            "volume": 87292,
            "datetime": 1712597760000
        },
        {
            "open": 518.86,
            "high": 518.97,
            "low": 518.86,
            "close": 518.96,
            "volume": 38779,
            "datetime": 1712597820000
        },
        {
            "open": 518.9601,
            "high": 519,
            "low": 518.89,
            "close": 518.96,
            "volume": 31638,
            "datetime": 1712597880000
        },
        {
            "open": 518.95,
            "high": 519,
            "low": 518.9307,
            "close": 518.97,
            "volume": 19722,
            "datetime": 1712597940000
        },
        {
            "open": 518.94,
            "high": 519.0403,
            "low": 518.94,
            "close": 518.99,
            "volume": 29136,
            "datetime": 1712598000000
        },
        {
            "open": 518.98,
            "high": 518.98,
            "low": 518.91,
            "close": 518.955,
            "volume": 21092,
            "datetime": 1712598060000
        },
        {
            "open": 518.96,
            "high": 518.97,
            "low": 518.83,
            "close": 518.885,
            "volume": 38456,
            "datetime": 1712598120000
        },
        {
            "open": 518.87,
            "high": 518.9,
            "low": 518.69,
            "close": 518.72,
            "volume": 87373,
            "datetime": 1712598180000
        },
        {
            "open": 518.72,
            "high": 518.95,
            "low": 518.71,
            "close": 518.905,
            "volume": 116424,
            "datetime": 1712598240000
        },
        {
            "open": 518.905,
            "high": 518.91,
            "low": 518.77,
            "close": 518.83,
            "volume": 40397,
            "datetime": 1712598300000
        },
        {
            "open": 518.825,
            "high": 518.8606,
            "low": 518.73,
            "close": 518.775,
            "volume": 75350,
            "datetime": 1712598360000
        },
        {
            "open": 518.78,
            "high": 518.835,
            "low": 518.7,
            "close": 518.76,
            "volume": 30602,
            "datetime": 1712598420000
        },
        {
            "open": 518.75,
            "high": 518.81,
            "low": 518.65,
            "close": 518.6801,
            "volume": 47233,
            "datetime": 1712598480000
        },
        {
            "open": 518.69,
            "high": 518.81,
            "low": 518.69,
            "close": 518.795,
            "volume": 46694,
            "datetime": 1712598540000
        },
        {
            "open": 518.79,
            "high": 518.81,
            "low": 518.73,
            "close": 518.79,
            "volume": 48489,
            "datetime": 1712598600000
        },
        {
            "open": 518.79,
            "high": 518.9,
            "low": 518.78,
            "close": 518.88,
            "volume": 43583,
            "datetime": 1712598660000
        },
        {
            "open": 518.89,
            "high": 518.99,
            "low": 518.86,
            "close": 518.865,
            "volume": 46677,
            "datetime": 1712598720000
        },
        {
            "open": 518.87,
            "high": 518.97,
            "low": 518.87,
            "close": 518.9401,
            "volume": 36254,
            "datetime": 1712598780000
        },
        {
            "open": 518.945,
            "high": 519.03,
            "low": 518.88,
            "close": 519.0199,
            "volume": 45923,
            "datetime": 1712598840000
        },
        {
            "open": 519.01,
            "high": 519.085,
            "low": 518.99,
            "close": 519.07,
            "volume": 34982,
            "datetime": 1712598900000
        },
        {
            "open": 519.07,
            "high": 519.2,
            "low": 519.042,
            "close": 519.17,
            "volume": 56168,
            "datetime": 1712598960000
        },
        {
            "open": 519.16,
            "high": 519.335,
            "low": 519.16,
            "close": 519.2399,
            "volume": 63963,
            "datetime": 1712599020000
        },
        {
            "open": 519.24,
            "high": 519.4,
            "low": 519.23,
            "close": 519.3812,
            "volume": 61987,
            "datetime": 1712599080000
        },
        {
            "open": 519.385,
            "high": 519.3899,
            "low": 519.26,
            "close": 519.31,
            "volume": 66822,
            "datetime": 1712599140000
        },
        {
            "open": 519.31,
            "high": 519.4,
            "low": 519.31,
            "close": 519.38,
            "volume": 43775,
            "datetime": 1712599200000
        },
        {
            "open": 519.38,
            "high": 519.46,
            "low": 519.22,
            "close": 519.225,
            "volume": 182128,
            "datetime": 1712599260000
        },
        {
            "open": 519.23,
            "high": 519.25,
            "low": 519.15,
            "close": 519.1699,
            "volume": 139803,
            "datetime": 1712599320000
        },
        {
            "open": 519.15,
            "high": 519.245,
            "low": 519.13,
            "close": 519.1615,
            "volume": 41031,
            "datetime": 1712599380000
        },
        {
            "open": 519.165,
            "high": 519.18,
            "low": 518.96,
            "close": 519.05,
            "volume": 109452,
            "datetime": 1712599440000
        },
        {
            "open": 519.045,
            "high": 519.14,
            "low": 519.01,
            "close": 519.115,
            "volume": 50315,
            "datetime": 1712599500000
        },
        {
            "open": 519.12,
            "high": 519.19,
            "low": 519.12,
            "close": 519.135,
            "volume": 40830,
            "datetime": 1712599560000
        },
        {
            "open": 519.14,
            "high": 519.32,
            "low": 519.1399,
            "close": 519.31,
            "volume": 64515,
            "datetime": 1712599620000
        },
        {
            "open": 519.31,
            "high": 519.36,
            "low": 519.2851,
            "close": 519.34,
            "volume": 70201,
            "datetime": 1712599680000
        },
        {
            "open": 519.35,
            "high": 519.41,
            "low": 519.2899,
            "close": 519.41,
            "volume": 54964,
            "datetime": 1712599740000
        },
        {
            "open": 519.405,
            "high": 519.45,
            "low": 519.305,
            "close": 519.36,
            "volume": 39248,
            "datetime": 1712599800000
        },
        {
            "open": 519.36,
            "high": 519.4,
            "low": 519.21,
            "close": 519.2114,
            "volume": 51391,
            "datetime": 1712599860000
        },
        {
            "open": 519.21,
            "high": 519.3,
            "low": 519.18,
            "close": 519.2,
            "volume": 31990,
            "datetime": 1712599920000
        },
        {
            "open": 519.21,
            "high": 519.28,
            "low": 519.18,
            "close": 519.27,
            "volume": 40708,
            "datetime": 1712599980000
        },
        {
            "open": 519.28,
            "high": 519.35,
            "low": 519.25,
            "close": 519.2714,
            "volume": 39493,
            "datetime": 1712600040000
        },
        {
            "open": 519.3,
            "high": 519.48,
            "low": 519.285,
            "close": 519.46,
            "volume": 38705,
            "datetime": 1712600100000
        },
        {
            "open": 519.455,
            "high": 519.4599,
            "low": 519.35,
            "close": 519.4,
            "volume": 41928,
            "datetime": 1712600160000
        },
        {
            "open": 519.38,
            "high": 519.46,
            "low": 519.35,
            "close": 519.4199,
            "volume": 30304,
            "datetime": 1712600220000
        },
        {
            "open": 519.42,
            "high": 519.4399,
            "low": 519.27,
            "close": 519.31,
            "volume": 35666,
            "datetime": 1712600280000
        },
        {
            "open": 519.31,
            "high": 519.3399,
            "low": 519.25,
            "close": 519.27,
            "volume": 19805,
            "datetime": 1712600340000
        },
        {
            "open": 519.27,
            "high": 519.33,
            "low": 519.23,
            "close": 519.236,
            "volume": 52015,
            "datetime": 1712600400000
        },
        {
            "open": 519.235,
            "high": 519.37,
            "low": 519.19,
            "close": 519.33,
            "volume": 51243,
            "datetime": 1712600460000
        },
        {
            "open": 519.35,
            "high": 519.3975,
            "low": 519.135,
            "close": 519.16,
            "volume": 49012,
            "datetime": 1712600520000
        },
        {
            "open": 519.1506,
            "high": 519.27,
            "low": 519.11,
            "close": 519.23,
            "volume": 60228,
            "datetime": 1712600580000
        },
        {
            "open": 519.22,
            "high": 519.29,
            "low": 518.9399,
            "close": 518.99,
            "volume": 64128,
            "datetime": 1712600640000
        },
        {
            "open": 519.01,
            "high": 519.16,
            "low": 518.98,
            "close": 519.16,
            "volume": 37387,
            "datetime": 1712600700000
        },
        {
            "open": 519.17,
            "high": 519.33,
            "low": 519.14,
            "close": 519.32,
            "volume": 52105,
            "datetime": 1712600760000
        },
        {
            "open": 519.3151,
            "high": 519.4101,
            "low": 519.255,
            "close": 519.405,
            "volume": 36063,
            "datetime": 1712600820000
        },
        {
            "open": 519.4,
            "high": 519.4,
            "low": 519.235,
            "close": 519.24,
            "volume": 90152,
            "datetime": 1712600880000
        },
        {
            "open": 519.25,
            "high": 519.274,
            "low": 519.15,
            "close": 519.18,
            "volume": 55447,
            "datetime": 1712600940000
        },
        {
            "open": 519.17,
            "high": 519.1999,
            "low": 519.01,
            "close": 519.055,
            "volume": 55605,
            "datetime": 1712601000000
        },
        {
            "open": 519.059,
            "high": 519.1088,
            "low": 519.0299,
            "close": 519.09,
            "volume": 25965,
            "datetime": 1712601060000
        },
        {
            "open": 519.08,
            "high": 519.15,
            "low": 519.07,
            "close": 519.12,
            "volume": 58014,
            "datetime": 1712601120000
        },
        {
            "open": 519.12,
            "high": 519.12,
            "low": 519,
            "close": 519.06,
            "volume": 42937,
            "datetime": 1712601180000
        },
        {
            "open": 519.07,
            "high": 519.08,
            "low": 519,
            "close": 519.005,
            "volume": 36906,
            "datetime": 1712601240000
        },
        {
            "open": 519,
            "high": 519.04,
            "low": 518.94,
            "close": 518.98,
            "volume": 46146,
            "datetime": 1712601300000
        },
        {
            "open": 518.98,
            "high": 519.01,
            "low": 518.88,
            "close": 518.95,
            "volume": 70977,
            "datetime": 1712601360000
        },
        {
            "open": 518.95,
            "high": 519.05,
            "low": 518.92,
            "close": 519,
            "volume": 47054,
            "datetime": 1712601420000
        },
        {
            "open": 519.01,
            "high": 519.03,
            "low": 518.78,
            "close": 518.86,
            "volume": 73794,
            "datetime": 1712601480000
        },
        {
            "open": 518.86,
            "high": 518.93,
            "low": 518.83,
            "close": 518.88,
            "volume": 37976,
            "datetime": 1712601540000
        },
        {
            "open": 518.8899,
            "high": 518.92,
            "low": 518.8051,
            "close": 518.8299,
            "volume": 36934,
            "datetime": 1712601600000
        },
        {
            "open": 518.825,
            "high": 518.96,
            "low": 518.78,
            "close": 518.865,
            "volume": 50434,
            "datetime": 1712601660000
        },
        {
            "open": 518.875,
            "high": 518.959,
            "low": 518.855,
            "close": 518.88,
            "volume": 33642,
            "datetime": 1712601720000
        },
        {
            "open": 518.89,
            "high": 518.9694,
            "low": 518.89,
            "close": 518.94,
            "volume": 40857,
            "datetime": 1712601780000
        },
        {
            "open": 518.935,
            "high": 518.95,
            "low": 518.86,
            "close": 518.885,
            "volume": 29109,
            "datetime": 1712601840000
        },
        {
            "open": 518.89,
            "high": 518.9,
            "low": 518.79,
            "close": 518.81,
            "volume": 46165,
            "datetime": 1712601900000
        },
        {
            "open": 518.81,
            "high": 518.8301,
            "low": 518.67,
            "close": 518.69,
            "volume": 77275,
            "datetime": 1712601960000
        },
        {
            "open": 518.6899,
            "high": 518.85,
            "low": 518.6699,
            "close": 518.84,
            "volume": 56719,
            "datetime": 1712602020000
        },
        {
            "open": 518.84,
            "high": 518.9665,
            "low": 518.84,
            "close": 518.89,
            "volume": 49316,
            "datetime": 1712602080000
        },
        {
            "open": 518.88,
            "high": 519.065,
            "low": 518.88,
            "close": 519.04,
            "volume": 84213,
            "datetime": 1712602140000
        },
        {
            "open": 519.0303,
            "high": 519.07,
            "low": 518.86,
            "close": 518.88,
            "volume": 84939,
            "datetime": 1712602200000
        },
        {
            "open": 518.88,
            "high": 518.965,
            "low": 518.83,
            "close": 518.88,
            "volume": 46162,
            "datetime": 1712602260000
        },
        {
            "open": 518.88,
            "high": 519.01,
            "low": 518.86,
            "close": 518.99,
            "volume": 42669,
            "datetime": 1712602320000
        },
        {
            "open": 518.99,
            "high": 519.085,
            "low": 518.915,
            "close": 519.08,
            "volume": 33467,
            "datetime": 1712602380000
        },
        {
            "open": 519.0799,
            "high": 519.09,
            "low": 519.01,
            "close": 519.01,
            "volume": 37407,
            "datetime": 1712602440000
        },
        {
            "open": 519.01,
            "high": 519.09,
            "low": 519.01,
            "close": 519.065,
            "volume": 34382,
            "datetime": 1712602500000
        },
        {
            "open": 519.06,
            "high": 519.19,
            "low": 519.0188,
            "close": 519.12,
            "volume": 64214,
            "datetime": 1712602560000
        },
        {
            "open": 519.12,
            "high": 519.145,
            "low": 519.035,
            "close": 519.1,
            "volume": 57264,
            "datetime": 1712602620000
        },
        {
            "open": 519.1,
            "high": 519.12,
            "low": 519.05,
            "close": 519.115,
            "volume": 29144,
            "datetime": 1712602680000
        },
        {
            "open": 519.11,
            "high": 519.26,
            "low": 519.07,
            "close": 519.255,
            "volume": 46649,
            "datetime": 1712602740000
        },
        {
            "open": 519.26,
            "high": 519.35,
            "low": 519.19,
            "close": 519.2081,
            "volume": 53780,
            "datetime": 1712602800000
        },
        {
            "open": 519.2099,
            "high": 519.275,
            "low": 519.15,
            "close": 519.24,
            "volume": 64701,
            "datetime": 1712602860000
        },
        {
            "open": 519.255,
            "high": 519.38,
            "low": 519.2099,
            "close": 519.33,
            "volume": 72139,
            "datetime": 1712602920000
        },
        {
            "open": 519.32,
            "high": 519.32,
            "low": 519.1276,
            "close": 519.2003,
            "volume": 61211,
            "datetime": 1712602980000
        },
        {
            "open": 519.2,
            "high": 519.21,
            "low": 519.075,
            "close": 519.13,
            "volume": 54380,
            "datetime": 1712603040000
        },
        {
            "open": 519.13,
            "high": 519.13,
            "low": 519.02,
            "close": 519.06,
            "volume": 65776,
            "datetime": 1712603100000
        },
        {
            "open": 519.06,
            "high": 519.135,
            "low": 519.01,
            "close": 519.07,
            "volume": 74221,
            "datetime": 1712603160000
        },
        {
            "open": 519.06,
            "high": 519.075,
            "low": 518.74,
            "close": 518.77,
            "volume": 129856,
            "datetime": 1712603220000
        },
        {
            "open": 518.76,
            "high": 518.98,
            "low": 518.74,
            "close": 518.9302,
            "volume": 53945,
            "datetime": 1712603280000
        },
        {
            "open": 518.93,
            "high": 519.16,
            "low": 518.91,
            "close": 519.13,
            "volume": 50993,
            "datetime": 1712603340000
        },
        {
            "open": 519.13,
            "high": 519.19,
            "low": 519.055,
            "close": 519.16,
            "volume": 65875,
            "datetime": 1712603400000
        },
        {
            "open": 519.16,
            "high": 519.21,
            "low": 519.07,
            "close": 519.09,
            "volume": 58892,
            "datetime": 1712603460000
        },
        {
            "open": 519.09,
            "high": 519.15,
            "low": 519.02,
            "close": 519.14,
            "volume": 90769,
            "datetime": 1712603520000
        },
        {
            "open": 519.1307,
            "high": 519.24,
            "low": 519.1307,
            "close": 519.24,
            "volume": 70638,
            "datetime": 1712603580000
        },
        {
            "open": 519.23,
            "high": 519.25,
            "low": 519.1,
            "close": 519.12,
            "volume": 38516,
            "datetime": 1712603640000
        },
        {
            "open": 519.12,
            "high": 519.16,
            "low": 519.07,
            "close": 519.13,
            "volume": 28976,
            "datetime": 1712603700000
        },
        {
            "open": 519.14,
            "high": 519.16,
            "low": 519.0799,
            "close": 519.15,
            "volume": 29363,
            "datetime": 1712603760000
        },
        {
            "open": 519.15,
            "high": 519.155,
            "low": 519.02,
            "close": 519.03,
            "volume": 35751,
            "datetime": 1712603820000
        },
        {
            "open": 519.03,
            "high": 519.13,
            "low": 518.96,
            "close": 519.13,
            "volume": 50577,
            "datetime": 1712603880000
        },
        {
            "open": 519.12,
            "high": 519.17,
            "low": 519.09,
            "close": 519.16,
            "volume": 60011,
            "datetime": 1712603940000
        },
        {
            "open": 519.16,
            "high": 519.24,
            "low": 519.155,
            "close": 519.21,
            "volume": 70009,
            "datetime": 1712604000000
        },
        {
            "open": 519.21,
            "high": 519.22,
            "low": 519.05,
            "close": 519.08,
            "volume": 47004,
            "datetime": 1712604060000
        },
        {
            "open": 519.08,
            "high": 519.14,
            "low": 519,
            "close": 519.01,
            "volume": 23195,
            "datetime": 1712604120000
        },
        {
            "open": 519.0099,
            "high": 519.065,
            "low": 518.92,
            "close": 518.94,
            "volume": 54119,
            "datetime": 1712604180000
        },
        {
            "open": 518.95,
            "high": 518.95,
            "low": 518.855,
            "close": 518.93,
            "volume": 55926,
            "datetime": 1712604240000
        },
        {
            "open": 518.93,
            "high": 519.03,
            "low": 518.9,
            "close": 519.01,
            "volume": 50244,
            "datetime": 1712604300000
        },
        {
            "open": 519.01,
            "high": 519.05,
            "low": 518.95,
            "close": 519.03,
            "volume": 30798,
            "datetime": 1712604360000
        },
        {
            "open": 519.02,
            "high": 519.06,
            "low": 518.95,
            "close": 518.975,
            "volume": 33472,
            "datetime": 1712604420000
        },
        {
            "open": 518.98,
            "high": 519.07,
            "low": 518.98,
            "close": 519.07,
            "volume": 38730,
            "datetime": 1712604480000
        },
        {
            "open": 519.06,
            "high": 519.28,
            "low": 519.05,
            "close": 519.255,
            "volume": 88054,
            "datetime": 1712604540000
        },
        {
            "open": 519.25,
            "high": 519.3587,
            "low": 519.2,
            "close": 519.3587,
            "volume": 98026,
            "datetime": 1712604600000
        },
        {
            "open": 519.3511,
            "high": 519.38,
            "low": 519.27,
            "close": 519.33,
            "volume": 81892,
            "datetime": 1712604660000
        },
        {
            "open": 519.32,
            "high": 519.495,
            "low": 519.29,
            "close": 519.45,
            "volume": 79559,
            "datetime": 1712604720000
        },
        {
            "open": 519.45,
            "high": 519.7,
            "low": 519.42,
            "close": 519.645,
            "volume": 126916,
            "datetime": 1712604780000
        },
        {
            "open": 519.645,
            "high": 519.67,
            "low": 519.57,
            "close": 519.62,
            "volume": 139232,
            "datetime": 1712604840000
        },
        {
            "open": 519.62,
            "high": 519.64,
            "low": 519.4,
            "close": 519.42,
            "volume": 108491,
            "datetime": 1712604900000
        },
        {
            "open": 519.42,
            "high": 519.455,
            "low": 519.296,
            "close": 519.37,
            "volume": 79027,
            "datetime": 1712604960000
        },
        {
            "open": 519.37,
            "high": 519.38,
            "low": 519.14,
            "close": 519.2,
            "volume": 101856,
            "datetime": 1712605020000
        },
        {
            "open": 519.2,
            "high": 519.2,
            "low": 519.045,
            "close": 519.06,
            "volume": 100411,
            "datetime": 1712605080000
        },
        {
            "open": 519.0525,
            "high": 519.09,
            "low": 519.02,
            "close": 519.085,
            "volume": 83442,
            "datetime": 1712605140000
        },
        {
            "open": 519.09,
            "high": 519.1,
            "low": 519.0021,
            "close": 519.03,
            "volume": 74574,
            "datetime": 1712605200000
        },
        {
            "open": 519.04,
            "high": 519.13,
            "low": 518.65,
            "close": 518.7,
            "volume": 280047,
            "datetime": 1712605260000
        },
        {
            "open": 518.71,
            "high": 518.8,
            "low": 518.65,
            "close": 518.75,
            "volume": 135190,
            "datetime": 1712605320000
        },
        {
            "open": 518.74,
            "high": 518.795,
            "low": 518.48,
            "close": 518.67,
            "volume": 120858,
            "datetime": 1712605380000
        },
        {
            "open": 518.65,
            "high": 518.74,
            "low": 518.6,
            "close": 518.62,
            "volume": 98938,
            "datetime": 1712605440000
        },
        {
            "open": 518.61,
            "high": 518.73,
            "low": 518.57,
            "close": 518.6899,
            "volume": 101234,
            "datetime": 1712605500000
        },
        {
            "open": 518.69,
            "high": 518.8599,
            "low": 518.69,
            "close": 518.81,
            "volume": 113949,
            "datetime": 1712605560000
        },
        {
            "open": 518.81,
            "high": 518.81,
            "low": 518.65,
            "close": 518.715,
            "volume": 117259,
            "datetime": 1712605620000
        },
        {
            "open": 518.71,
            "high": 518.8,
            "low": 518.64,
            "close": 518.7,
            "volume": 87656,
            "datetime": 1712605680000
        },
        {
            "open": 518.69,
            "high": 518.93,
            "low": 518.67,
            "close": 518.87,
            "volume": 154762,
            "datetime": 1712605740000
        },
        {
            "open": 518.86,
            "high": 518.89,
            "low": 518.78,
            "close": 518.825,
            "volume": 205509,
            "datetime": 1712605800000
        },
        {
            "open": 518.83,
            "high": 518.86,
            "low": 518.57,
            "close": 518.58,
            "volume": 223045,
            "datetime": 1712605860000
        },
        {
            "open": 518.57,
            "high": 518.66,
            "low": 518.36,
            "close": 518.61,
            "volume": 250474,
            "datetime": 1712605920000
        },
        {
            "open": 518.62,
            "high": 518.645,
            "low": 518.45,
            "close": 518.485,
            "volume": 263066,
            "datetime": 1712605980000
        },
        {
            "open": 518.48,
            "high": 518.669,
            "low": 518.46,
            "close": 518.55,
            "volume": 323375,
            "datetime": 1712606040000
        },
        {
            "open": 518.56,
            "high": 518.56,
            "low": 518.32,
            "close": 518.37,
            "volume": 378012,
            "datetime": 1712606100000
        },
        {
            "open": 518.375,
            "high": 518.38,
            "low": 518.29,
            "close": 518.365,
            "volume": 323969,
            "datetime": 1712606160000
        },
        {
            "open": 518.37,
            "high": 518.37,
            "low": 518.23,
            "close": 518.31,
            "volume": 349948,
            "datetime": 1712606220000
        },
        {
            "open": 518.31,
            "high": 518.49,
            "low": 518.28,
            "close": 518.48,
            "volume": 622932,
            "datetime": 1712606280000
        },
        {
            "open": 518.48,
            "high": 518.71,
            "low": 518.37,
            "close": 518.67,
            "volume": 3250984,
            "datetime": 1712606340000
        },
        {
            "open": 520.5,
            "high": 520.62,
            "low": 520.33,
            "close": 520.33,
            "volume": 734585,
            "datetime": 1712669400000
        },
        {
            "open": 520.31,
            "high": 520.51,
            "low": 520.2799,
            "close": 520.45,
            "volume": 173070,
            "datetime": 1712669460000
        },
        {
            "open": 520.44,
            "high": 520.7296,
            "low": 520.41,
            "close": 520.71,
            "volume": 178904,
            "datetime": 1712669520000
        },
        {
            "open": 520.71,
            "high": 520.75,
            "low": 520.23,
            "close": 520.3,
            "volume": 155119,
            "datetime": 1712669580000
        },
        {
            "open": 520.3,
            "high": 520.3399,
            "low": 520.04,
            "close": 520.06,
            "volume": 176390,
            "datetime": 1712669640000
        },
        {
            "open": 520.1,
            "high": 520.12,
            "low": 519.84,
            "close": 520.11,
            "volume": 197370,
            "datetime": 1712669700000
        },
        {
            "open": 520.1,
            "high": 520.29,
            "low": 520.035,
            "close": 520.26,
            "volume": 177882,
            "datetime": 1712669760000
        },
        {
            "open": 520.25,
            "high": 520.54,
            "low": 520.2,
            "close": 520.47,
            "volume": 91044,
            "datetime": 1712669820000
        },
        {
            "open": 520.48,
            "high": 520.5,
            "low": 520.21,
            "close": 520.25,
            "volume": 100297,
            "datetime": 1712669880000
        },
        {
            "open": 520.26,
            "high": 520.37,
            "low": 520.18,
            "close": 520.36,
            "volume": 100885,
            "datetime": 1712669940000
        },
        {
            "open": 520.3601,
            "high": 520.45,
            "low": 520.3601,
            "close": 520.385,
            "volume": 64260,
            "datetime": 1712670000000
        },
        {
            "open": 520.39,
            "high": 520.4394,
            "low": 520.19,
            "close": 520.2204,
            "volume": 84952,
            "datetime": 1712670060000
        },
        {
            "open": 520.22,
            "high": 520.28,
            "low": 520.13,
            "close": 520.1688,
            "volume": 89281,
            "datetime": 1712670120000
        },
        {
            "open": 520.16,
            "high": 520.2199,
            "low": 520.0197,
            "close": 520.11,
            "volume": 72301,
            "datetime": 1712670180000
        },
        {
            "open": 520.11,
            "high": 520.13,
            "low": 519.72,
            "close": 519.835,
            "volume": 112870,
            "datetime": 1712670240000
        },
        {
            "open": 519.82,
            "high": 520,
            "low": 519.8,
            "close": 519.91,
            "volume": 105815,
            "datetime": 1712670300000
        },
        {
            "open": 519.93,
            "high": 519.94,
            "low": 519.74,
            "close": 519.76,
            "volume": 79165,
            "datetime": 1712670360000
        },
        {
            "open": 519.76,
            "high": 519.85,
            "low": 519.57,
            "close": 519.66,
            "volume": 228985,
            "datetime": 1712670420000
        },
        {
            "open": 519.66,
            "high": 519.7,
            "low": 519.36,
            "close": 519.43,
            "volume": 165615,
            "datetime": 1712670480000
        },
        {
            "open": 519.44,
            "high": 519.48,
            "low": 519.04,
            "close": 519.12,
            "volume": 203855,
            "datetime": 1712670540000
        },
        {
            "open": 519.12,
            "high": 519.19,
            "low": 518.925,
            "close": 519,
            "volume": 217653,
            "datetime": 1712670600000
        },
        {
            "open": 519.01,
            "high": 519.2,
            "low": 518.955,
            "close": 519.115,
            "volume": 117884,
            "datetime": 1712670660000
        },
        {
            "open": 519.11,
            "high": 519.12,
            "low": 518.77,
            "close": 518.9306,
            "volume": 166609,
            "datetime": 1712670720000
        },
        {
            "open": 518.94,
            "high": 519.0598,
            "low": 518.92,
            "close": 519.04,
            "volume": 89667,
            "datetime": 1712670780000
        },
        {
            "open": 519.04,
            "high": 519.07,
            "low": 518.87,
            "close": 518.96,
            "volume": 126927,
            "datetime": 1712670840000
        },
        {
            "open": 518.97,
            "high": 519.1401,
            "low": 518.97,
            "close": 519.05,
            "volume": 130090,
            "datetime": 1712670900000
        },
        {
            "open": 519.035,
            "high": 519.035,
            "low": 518.86,
            "close": 518.94,
            "volume": 87936,
            "datetime": 1712670960000
        },
        {
            "open": 518.935,
            "high": 518.97,
            "low": 518.8,
            "close": 518.9399,
            "volume": 100706,
            "datetime": 1712671020000
        },
        {
            "open": 518.93,
            "high": 518.96,
            "low": 518.83,
            "close": 518.89,
            "volume": 80015,
            "datetime": 1712671080000
        },
        {
            "open": 518.92,
            "high": 519.02,
            "low": 518.87,
            "close": 518.88,
            "volume": 92820,
            "datetime": 1712671140000
        },
        {
            "open": 518.89,
            "high": 519.0299,
            "low": 518.8505,
            "close": 518.96,
            "volume": 142582,
            "datetime": 1712671200000
        },
        {
            "open": 518.97,
            "high": 518.97,
            "low": 518.75,
            "close": 518.94,
            "volume": 114817,
            "datetime": 1712671260000
        },
        {
            "open": 518.97,
            "high": 519.1,
            "low": 518.87,
            "close": 519.07,
            "volume": 188907,
            "datetime": 1712671320000
        },
        {
            "open": 519.08,
            "high": 519.18,
            "low": 519.015,
            "close": 519.1,
            "volume": 215883,
            "datetime": 1712671380000
        },
        {
            "open": 519.1,
            "high": 519.1,
            "low": 518.87,
            "close": 518.915,
            "volume": 106981,
            "datetime": 1712671440000
        },
        {
            "open": 518.91,
            "high": 518.98,
            "low": 518.8,
            "close": 518.8701,
            "volume": 106340,
            "datetime": 1712671500000
        },
        {
            "open": 518.88,
            "high": 518.91,
            "low": 518.66,
            "close": 518.79,
            "volume": 124199,
            "datetime": 1712671560000
        },
        {
            "open": 518.79,
            "high": 518.9213,
            "low": 518.74,
            "close": 518.84,
            "volume": 127027,
            "datetime": 1712671620000
        },
        {
            "open": 518.84,
            "high": 518.88,
            "low": 518.755,
            "close": 518.78,
            "volume": 91847,
            "datetime": 1712671680000
        },
        {
            "open": 518.775,
            "high": 518.93,
            "low": 518.72,
            "close": 518.85,
            "volume": 74021,
            "datetime": 1712671740000
        },
        {
            "open": 518.85,
            "high": 518.89,
            "low": 518.77,
            "close": 518.85,
            "volume": 68681,
            "datetime": 1712671800000
        },
        {
            "open": 518.855,
            "high": 518.86,
            "low": 518.6699,
            "close": 518.6917,
            "volume": 96167,
            "datetime": 1712671860000
        },
        {
            "open": 518.695,
            "high": 518.825,
            "low": 518.66,
            "close": 518.8,
            "volume": 62609,
            "datetime": 1712671920000
        },
        {
            "open": 518.7901,
            "high": 519.07,
            "low": 518.75,
            "close": 519.06,
            "volume": 108701,
            "datetime": 1712671980000
        },
        {
            "open": 519.06,
            "high": 519.1,
            "low": 519.01,
            "close": 519.04,
            "volume": 122293,
            "datetime": 1712672040000
        },
        {
            "open": 519.0475,
            "high": 519.28,
            "low": 519.0475,
            "close": 519.095,
            "volume": 136017,
            "datetime": 1712672100000
        },
        {
            "open": 519.11,
            "high": 519.15,
            "low": 519.08,
            "close": 519.11,
            "volume": 95502,
            "datetime": 1712672160000
        },
        {
            "open": 519.115,
            "high": 519.245,
            "low": 519.085,
            "close": 519.11,
            "volume": 49110,
            "datetime": 1712672220000
        },
        {
            "open": 519.115,
            "high": 519.12,
            "low": 518.85,
            "close": 518.995,
            "volume": 156031,
            "datetime": 1712672280000
        },
        {
            "open": 519,
            "high": 519.05,
            "low": 518.89,
            "close": 519.015,
            "volume": 88985,
            "datetime": 1712672340000
        },
        {
            "open": 519.005,
            "high": 519.06,
            "low": 518.91,
            "close": 519.01,
            "volume": 94842,
            "datetime": 1712672400000
        },
        {
            "open": 519.01,
            "high": 519.11,
            "low": 518.955,
            "close": 518.98,
            "volume": 54596,
            "datetime": 1712672460000
        },
        {
            "open": 518.98,
            "high": 519.1035,
            "low": 518.98,
            "close": 519.05,
            "volume": 62649,
            "datetime": 1712672520000
        },
        {
            "open": 519.05,
            "high": 519.1199,
            "low": 518.98,
            "close": 519.03,
            "volume": 42816,
            "datetime": 1712672580000
        },
        {
            "open": 519.02,
            "high": 519.06,
            "low": 518.895,
            "close": 518.98,
            "volume": 65838,
            "datetime": 1712672640000
        },
        {
            "open": 518.98,
            "high": 519.04,
            "low": 518.82,
            "close": 518.93,
            "volume": 52470,
            "datetime": 1712672700000
        },
        {
            "open": 518.95,
            "high": 518.95,
            "low": 518.77,
            "close": 518.8,
            "volume": 73781,
            "datetime": 1712672760000
        },
        {
            "open": 518.79,
            "high": 518.79,
            "low": 518.31,
            "close": 518.3288,
            "volume": 210919,
            "datetime": 1712672820000
        },
        {
            "open": 518.3299,
            "high": 518.39,
            "low": 517.75,
            "close": 517.87,
            "volume": 241162,
            "datetime": 1712672880000
        },
        {
            "open": 517.87,
            "high": 517.9,
            "low": 517.4103,
            "close": 517.59,
            "volume": 222230,
            "datetime": 1712672940000
        },
        {
            "open": 517.59,
            "high": 517.63,
            "low": 516.56,
            "close": 516.805,
            "volume": 537932,
            "datetime": 1712673000000
        },
        {
            "open": 516.8,
            "high": 516.91,
            "low": 516.35,
            "close": 516.86,
            "volume": 370844,
            "datetime": 1712673060000
        },
        {
            "open": 516.85,
            "high": 516.87,
            "low": 516.5,
            "close": 516.85,
            "volume": 221070,
            "datetime": 1712673120000
        },
        {
            "open": 516.85,
            "high": 517.27,
            "low": 516.85,
            "close": 517.05,
            "volume": 273362,
            "datetime": 1712673180000
        },
        {
            "open": 517.06,
            "high": 517.16,
            "low": 516.64,
            "close": 516.69,
            "volume": 170186,
            "datetime": 1712673240000
        },
        {
            "open": 516.7,
            "high": 516.8,
            "low": 516.64,
            "close": 516.71,
            "volume": 102383,
            "datetime": 1712673300000
        },
        {
            "open": 516.705,
            "high": 516.74,
            "low": 515.93,
            "close": 515.93,
            "volume": 280477,
            "datetime": 1712673360000
        },
        {
            "open": 515.94,
            "high": 516.07,
            "low": 515.78,
            "close": 515.84,
            "volume": 214090,
            "datetime": 1712673420000
        },
        {
            "open": 515.84,
            "high": 516.1802,
            "low": 515.75,
            "close": 516.005,
            "volume": 287713,
            "datetime": 1712673480000
        },
        {
            "open": 516,
            "high": 516.16,
            "low": 515.68,
            "close": 515.78,
            "volume": 147371,
            "datetime": 1712673540000
        },
        {
            "open": 515.79,
            "high": 516.09,
            "low": 515.63,
            "close": 515.71,
            "volume": 226291,
            "datetime": 1712673600000
        },
        {
            "open": 515.71,
            "high": 515.76,
            "low": 515.37,
            "close": 515.545,
            "volume": 293557,
            "datetime": 1712673660000
        },
        {
            "open": 515.55,
            "high": 515.63,
            "low": 514.805,
            "close": 514.95,
            "volume": 330944,
            "datetime": 1712673720000
        },
        {
            "open": 514.96,
            "high": 515.05,
            "low": 514.52,
            "close": 514.6413,
            "volume": 317266,
            "datetime": 1712673780000
        },
        {
            "open": 514.65,
            "high": 514.99,
            "low": 514.46,
            "close": 514.84,
            "volume": 326611,
            "datetime": 1712673840000
        },
        {
            "open": 514.88,
            "high": 515.365,
            "low": 514.85,
            "close": 515.35,
            "volume": 348473,
            "datetime": 1712673900000
        },
        {
            "open": 515.35,
            "high": 515.36,
            "low": 514.93,
            "close": 515.01,
            "volume": 385639,
            "datetime": 1712673960000
        },
        {
            "open": 515.01,
            "high": 515.015,
            "low": 514.57,
            "close": 514.74,
            "volume": 345125,
            "datetime": 1712674020000
        },
        {
            "open": 514.755,
            "high": 514.755,
            "low": 514.44,
            "close": 514.575,
            "volume": 332686,
            "datetime": 1712674080000
        },
        {
            "open": 514.565,
            "high": 514.71,
            "low": 514.53,
            "close": 514.62,
            "volume": 172977,
            "datetime": 1712674140000
        },
        {
            "open": 514.64,
            "high": 514.87,
            "low": 514.4101,
            "close": 514.81,
            "volume": 236899,
            "datetime": 1712674200000
        },
        {
            "open": 514.8,
            "high": 515.05,
            "low": 514.77,
            "close": 514.98,
            "volume": 251183,
            "datetime": 1712674260000
        },
        {
            "open": 514.98,
            "high": 515.075,
            "low": 514.54,
            "close": 514.59,
            "volume": 241141,
            "datetime": 1712674320000
        },
        {
            "open": 514.6,
            "high": 514.75,
            "low": 514.35,
            "close": 514.5215,
            "volume": 261647,
            "datetime": 1712674380000
        },
        {
            "open": 514.55,
            "high": 514.55,
            "low": 514.35,
            "close": 514.47,
            "volume": 106444,
            "datetime": 1712674440000
        },
        {
            "open": 514.465,
            "high": 514.645,
            "low": 514.44,
            "close": 514.56,
            "volume": 204453,
            "datetime": 1712674500000
        },
        {
            "open": 514.55,
            "high": 514.8799,
            "low": 514.53,
            "close": 514.86,
            "volume": 177742,
            "datetime": 1712674560000
        },
        {
            "open": 514.87,
            "high": 515.06,
            "low": 514.75,
            "close": 515.06,
            "volume": 197927,
            "datetime": 1712674620000
        },
        {
            "open": 515.06,
            "high": 515.2217,
            "low": 515.03,
            "close": 515.2217,
            "volume": 195458,
            "datetime": 1712674680000
        },
        {
            "open": 515.23,
            "high": 515.57,
            "low": 515.19,
            "close": 515.515,
            "volume": 201557,
            "datetime": 1712674740000
        },
        {
            "open": 515.52,
            "high": 515.6498,
            "low": 515.4099,
            "close": 515.645,
            "volume": 167771,
            "datetime": 1712674800000
        },
        {
            "open": 515.64,
            "high": 515.645,
            "low": 515.23,
            "close": 515.2493,
            "volume": 150774,
            "datetime": 1712674860000
        },
        {
            "open": 515.25,
            "high": 515.63,
            "low": 515.25,
            "close": 515.41,
            "volume": 144991,
            "datetime": 1712674920000
        },
        {
            "open": 515.41,
            "high": 515.43,
            "low": 515.19,
            "close": 515.2901,
            "volume": 160546,
            "datetime": 1712674980000
        },
        {
            "open": 515.29,
            "high": 515.485,
            "low": 515.28,
            "close": 515.43,
            "volume": 107284,
            "datetime": 1712675040000
        },
        {
            "open": 515.46,
            "high": 515.6,
            "low": 515.36,
            "close": 515.55,
            "volume": 67601,
            "datetime": 1712675100000
        },
        {
            "open": 515.54,
            "high": 515.78,
            "low": 515.43,
            "close": 515.76,
            "volume": 132041,
            "datetime": 1712675160000
        },
        {
            "open": 515.72,
            "high": 516.03,
            "low": 515.4,
            "close": 516,
            "volume": 201516,
            "datetime": 1712675220000
        },
        {
            "open": 516,
            "high": 516.01,
            "low": 515.85,
            "close": 515.93,
            "volume": 115287,
            "datetime": 1712675280000
        },
        {
            "open": 515.92,
            "high": 516.15,
            "low": 515.92,
            "close": 515.94,
            "volume": 154681,
            "datetime": 1712675340000
        },
        {
            "open": 515.93,
            "high": 515.97,
            "low": 515.7,
            "close": 515.94,
            "volume": 195032,
            "datetime": 1712675400000
        },
        {
            "open": 515.93,
            "high": 515.97,
            "low": 515.5975,
            "close": 515.615,
            "volume": 84275,
            "datetime": 1712675460000
        },
        {
            "open": 515.61,
            "high": 515.75,
            "low": 515.55,
            "close": 515.6,
            "volume": 87356,
            "datetime": 1712675520000
        },
        {
            "open": 515.61,
            "high": 515.82,
            "low": 515.5801,
            "close": 515.74,
            "volume": 62127,
            "datetime": 1712675580000
        },
        {
            "open": 515.72,
            "high": 515.79,
            "low": 515.6,
            "close": 515.6,
            "volume": 52322,
            "datetime": 1712675640000
        },
        {
            "open": 515.62,
            "high": 516.05,
            "low": 515.62,
            "close": 515.955,
            "volume": 94974,
            "datetime": 1712675700000
        },
        {
            "open": 515.95,
            "high": 516.36,
            "low": 515.9387,
            "close": 516.29,
            "volume": 152051,
            "datetime": 1712675760000
        },
        {
            "open": 516.295,
            "high": 516.53,
            "low": 516.24,
            "close": 516.375,
            "volume": 144133,
            "datetime": 1712675820000
        },
        {
            "open": 516.375,
            "high": 516.47,
            "low": 516.2727,
            "close": 516.39,
            "volume": 92416,
            "datetime": 1712675880000
        },
        {
            "open": 516.4,
            "high": 516.55,
            "low": 516.37,
            "close": 516.475,
            "volume": 62587,
            "datetime": 1712675940000
        },
        {
            "open": 516.46,
            "high": 516.5,
            "low": 516.18,
            "close": 516.285,
            "volume": 133583,
            "datetime": 1712676000000
        },
        {
            "open": 516.275,
            "high": 516.32,
            "low": 516.08,
            "close": 516.12,
            "volume": 80502,
            "datetime": 1712676060000
        },
        {
            "open": 516.11,
            "high": 516.35,
            "low": 516.09,
            "close": 516.261,
            "volume": 82014,
            "datetime": 1712676120000
        },
        {
            "open": 516.24,
            "high": 516.25,
            "low": 516.13,
            "close": 516.18,
            "volume": 83398,
            "datetime": 1712676180000
        },
        {
            "open": 516.2,
            "high": 516.31,
            "low": 515.96,
            "close": 516.22,
            "volume": 79305,
            "datetime": 1712676240000
        },
        {
            "open": 516.22,
            "high": 516.31,
            "low": 516.17,
            "close": 516.19,
            "volume": 71551,
            "datetime": 1712676300000
        },
        {
            "open": 516.18,
            "high": 516.4198,
            "low": 516.13,
            "close": 516.4,
            "volume": 63896,
            "datetime": 1712676360000
        },
        {
            "open": 516.39,
            "high": 516.39,
            "low": 516.135,
            "close": 516.135,
            "volume": 42221,
            "datetime": 1712676420000
        },
        {
            "open": 516.14,
            "high": 516.16,
            "low": 515.9416,
            "close": 516,
            "volume": 66972,
            "datetime": 1712676480000
        },
        {
            "open": 515.9688,
            "high": 516,
            "low": 515.84,
            "close": 515.95,
            "volume": 63185,
            "datetime": 1712676540000
        },
        {
            "open": 515.96,
            "high": 516.1164,
            "low": 515.93,
            "close": 516.05,
            "volume": 53565,
            "datetime": 1712676600000
        },
        {
            "open": 516.05,
            "high": 516.195,
            "low": 515.78,
            "close": 515.985,
            "volume": 76158,
            "datetime": 1712676660000
        },
        {
            "open": 515.98,
            "high": 516.095,
            "low": 515.91,
            "close": 515.91,
            "volume": 46355,
            "datetime": 1712676720000
        },
        {
            "open": 515.89,
            "high": 515.97,
            "low": 515.81,
            "close": 515.865,
            "volume": 94625,
            "datetime": 1712676780000
        },
        {
            "open": 515.87,
            "high": 516,
            "low": 515.86,
            "close": 515.87,
            "volume": 67491,
            "datetime": 1712676840000
        },
        {
            "open": 515.87,
            "high": 515.98,
            "low": 515.67,
            "close": 515.9492,
            "volume": 108049,
            "datetime": 1712676900000
        },
        {
            "open": 515.89,
            "high": 516.14,
            "low": 515.89,
            "close": 516.025,
            "volume": 87391,
            "datetime": 1712676960000
        },
        {
            "open": 516.02,
            "high": 516.19,
            "low": 516.015,
            "close": 516.02,
            "volume": 74561,
            "datetime": 1712677020000
        },
        {
            "open": 516.04,
            "high": 516.1499,
            "low": 515.98,
            "close": 516,
            "volume": 36666,
            "datetime": 1712677080000
        },
        {
            "open": 515.99,
            "high": 516.105,
            "low": 515.82,
            "close": 515.88,
            "volume": 104338,
            "datetime": 1712677140000
        },
        {
            "open": 515.89,
            "high": 516.095,
            "low": 515.89,
            "close": 515.9,
            "volume": 46999,
            "datetime": 1712677200000
        },
        {
            "open": 515.865,
            "high": 516.1096,
            "low": 515.845,
            "close": 516.0773,
            "volume": 50147,
            "datetime": 1712677260000
        },
        {
            "open": 516.075,
            "high": 516.21,
            "low": 515.95,
            "close": 516.125,
            "volume": 81724,
            "datetime": 1712677320000
        },
        {
            "open": 516.1,
            "high": 516.5497,
            "low": 516.07,
            "close": 516.41,
            "volume": 167168,
            "datetime": 1712677380000
        },
        {
            "open": 516.43,
            "high": 516.92,
            "low": 516.41,
            "close": 516.715,
            "volume": 342450,
            "datetime": 1712677440000
        },
        {
            "open": 516.69,
            "high": 516.91,
            "low": 516.62,
            "close": 516.81,
            "volume": 134483,
            "datetime": 1712677500000
        },
        {
            "open": 516.805,
            "high": 516.89,
            "low": 516.7,
            "close": 516.86,
            "volume": 91654,
            "datetime": 1712677560000
        },
        {
            "open": 516.86,
            "high": 516.96,
            "low": 516.79,
            "close": 516.91,
            "volume": 71005,
            "datetime": 1712677620000
        },
        {
            "open": 516.91,
            "high": 516.95,
            "low": 516.79,
            "close": 516.845,
            "volume": 64692,
            "datetime": 1712677680000
        },
        {
            "open": 516.83,
            "high": 517.125,
            "low": 516.81,
            "close": 516.98,
            "volume": 109226,
            "datetime": 1712677740000
        },
        {
            "open": 516.98,
            "high": 517.045,
            "low": 516.8,
            "close": 516.8999,
            "volume": 81301,
            "datetime": 1712677800000
        },
        {
            "open": 516.9,
            "high": 516.945,
            "low": 516.77,
            "close": 516.84,
            "volume": 70451,
            "datetime": 1712677860000
        },
        {
            "open": 516.83,
            "high": 516.8399,
            "low": 516.68,
            "close": 516.77,
            "volume": 67599,
            "datetime": 1712677920000
        },
        {
            "open": 516.78,
            "high": 516.81,
            "low": 516.58,
            "close": 516.71,
            "volume": 72180,
            "datetime": 1712677980000
        },
        {
            "open": 516.72,
            "high": 516.94,
            "low": 516.71,
            "close": 516.925,
            "volume": 124496,
            "datetime": 1712678040000
        },
        {
            "open": 516.93,
            "high": 517.03,
            "low": 516.92,
            "close": 516.9451,
            "volume": 75003,
            "datetime": 1712678100000
        },
        {
            "open": 516.96,
            "high": 516.99,
            "low": 516.87,
            "close": 516.95,
            "volume": 61829,
            "datetime": 1712678160000
        },
        {
            "open": 516.94,
            "high": 516.96,
            "low": 516.85,
            "close": 516.86,
            "volume": 58510,
            "datetime": 1712678220000
        },
        {
            "open": 516.85,
            "high": 516.94,
            "low": 516.85,
            "close": 516.905,
            "volume": 49465,
            "datetime": 1712678280000
        },
        {
            "open": 516.91,
            "high": 516.95,
            "low": 516.825,
            "close": 516.825,
            "volume": 61917,
            "datetime": 1712678340000
        },
        {
            "open": 516.825,
            "high": 517.33,
            "low": 516.76,
            "close": 517.15,
            "volume": 146963,
            "datetime": 1712678400000
        },
        {
            "open": 517.15,
            "high": 517.16,
            "low": 516.85,
            "close": 516.91,
            "volume": 75443,
            "datetime": 1712678460000
        },
        {
            "open": 516.9001,
            "high": 516.98,
            "low": 516.79,
            "close": 516.94,
            "volume": 64012,
            "datetime": 1712678520000
        },
        {
            "open": 516.95,
            "high": 517.03,
            "low": 516.86,
            "close": 517.01,
            "volume": 50349,
            "datetime": 1712678580000
        },
        {
            "open": 516.9999,
            "high": 517.13,
            "low": 516.985,
            "close": 517.1,
            "volume": 113712,
            "datetime": 1712678640000
        },
        {
            "open": 517.08,
            "high": 517.13,
            "low": 516.92,
            "close": 517.06,
            "volume": 49411,
            "datetime": 1712678700000
        },
        {
            "open": 517.06,
            "high": 517.07,
            "low": 516.9003,
            "close": 516.9773,
            "volume": 58944,
            "datetime": 1712678760000
        },
        {
            "open": 516.95,
            "high": 516.96,
            "low": 516.78,
            "close": 516.85,
            "volume": 60532,
            "datetime": 1712678820000
        },
        {
            "open": 516.87,
            "high": 517.03,
            "low": 516.85,
            "close": 516.985,
            "volume": 48578,
            "datetime": 1712678880000
        },
        {
            "open": 516.96,
            "high": 517.26,
            "low": 516.93,
            "close": 517.12,
            "volume": 69155,
            "datetime": 1712678940000
        },
        {
            "open": 517.11,
            "high": 517.165,
            "low": 517.055,
            "close": 517.115,
            "volume": 31437,
            "datetime": 1712679000000
        },
        {
            "open": 517.1,
            "high": 517.165,
            "low": 517.005,
            "close": 517.15,
            "volume": 32474,
            "datetime": 1712679060000
        },
        {
            "open": 517.16,
            "high": 517.63,
            "low": 517.155,
            "close": 517.55,
            "volume": 204510,
            "datetime": 1712679120000
        },
        {
            "open": 517.55,
            "high": 517.64,
            "low": 517.52,
            "close": 517.5501,
            "volume": 82255,
            "datetime": 1712679180000
        },
        {
            "open": 517.5587,
            "high": 517.63,
            "low": 517.4,
            "close": 517.405,
            "volume": 87425,
            "datetime": 1712679240000
        },
        {
            "open": 517.405,
            "high": 517.495,
            "low": 517.3789,
            "close": 517.415,
            "volume": 52173,
            "datetime": 1712679300000
        },
        {
            "open": 517.415,
            "high": 517.46,
            "low": 517.32,
            "close": 517.43,
            "volume": 60883,
            "datetime": 1712679360000
        },
        {
            "open": 517.42,
            "high": 517.47,
            "low": 517.37,
            "close": 517.44,
            "volume": 36164,
            "datetime": 1712679420000
        },
        {
            "open": 517.42,
            "high": 517.47,
            "low": 517.26,
            "close": 517.42,
            "volume": 57195,
            "datetime": 1712679480000
        },
        {
            "open": 517.42,
            "high": 517.43,
            "low": 517.28,
            "close": 517.3,
            "volume": 39327,
            "datetime": 1712679540000
        },
        {
            "open": 517.3,
            "high": 517.39,
            "low": 517.24,
            "close": 517.29,
            "volume": 67182,
            "datetime": 1712679600000
        },
        {
            "open": 517.31,
            "high": 517.435,
            "low": 517.265,
            "close": 517.435,
            "volume": 51017,
            "datetime": 1712679660000
        },
        {
            "open": 517.425,
            "high": 517.52,
            "low": 517.38,
            "close": 517.43,
            "volume": 46221,
            "datetime": 1712679720000
        },
        {
            "open": 517.44,
            "high": 517.44,
            "low": 517.23,
            "close": 517.2501,
            "volume": 41291,
            "datetime": 1712679780000
        },
        {
            "open": 517.26,
            "high": 517.43,
            "low": 517.26,
            "close": 517.41,
            "volume": 52639,
            "datetime": 1712679840000
        },
        {
            "open": 517.415,
            "high": 517.45,
            "low": 517.23,
            "close": 517.31,
            "volume": 45284,
            "datetime": 1712679900000
        },
        {
            "open": 517.31,
            "high": 517.56,
            "low": 517.3099,
            "close": 517.52,
            "volume": 58426,
            "datetime": 1712679960000
        },
        {
            "open": 517.51,
            "high": 517.51,
            "low": 517.27,
            "close": 517.27,
            "volume": 35080,
            "datetime": 1712680020000
        },
        {
            "open": 517.27,
            "high": 517.36,
            "low": 517.26,
            "close": 517.315,
            "volume": 45502,
            "datetime": 1712680080000
        },
        {
            "open": 517.32,
            "high": 517.365,
            "low": 517.24,
            "close": 517.26,
            "volume": 40373,
            "datetime": 1712680140000
        },
        {
            "open": 517.26,
            "high": 517.42,
            "low": 517.26,
            "close": 517.3,
            "volume": 45360,
            "datetime": 1712680200000
        },
        {
            "open": 517.29,
            "high": 517.48,
            "low": 517.29,
            "close": 517.45,
            "volume": 34684,
            "datetime": 1712680260000
        },
        {
            "open": 517.44,
            "high": 517.59,
            "low": 517.385,
            "close": 517.545,
            "volume": 70282,
            "datetime": 1712680320000
        },
        {
            "open": 517.55,
            "high": 517.63,
            "low": 517.52,
            "close": 517.5901,
            "volume": 49716,
            "datetime": 1712680380000
        },
        {
            "open": 517.6,
            "high": 517.78,
            "low": 517.565,
            "close": 517.745,
            "volume": 91592,
            "datetime": 1712680440000
        },
        {
            "open": 517.745,
            "high": 517.75,
            "low": 517.62,
            "close": 517.67,
            "volume": 35121,
            "datetime": 1712680500000
        },
        {
            "open": 517.66,
            "high": 517.69,
            "low": 517.62,
            "close": 517.645,
            "volume": 33582,
            "datetime": 1712680560000
        },
        {
            "open": 517.64,
            "high": 517.71,
            "low": 517.46,
            "close": 517.47,
            "volume": 58933,
            "datetime": 1712680620000
        },
        {
            "open": 517.47,
            "high": 517.605,
            "low": 517.47,
            "close": 517.5699,
            "volume": 30134,
            "datetime": 1712680680000
        },
        {
            "open": 517.55,
            "high": 517.7099,
            "low": 517.54,
            "close": 517.67,
            "volume": 24443,
            "datetime": 1712680740000
        },
        {
            "open": 517.68,
            "high": 517.69,
            "low": 517.47,
            "close": 517.5299,
            "volume": 37952,
            "datetime": 1712680800000
        },
        {
            "open": 517.53,
            "high": 517.62,
            "low": 517.52,
            "close": 517.62,
            "volume": 22812,
            "datetime": 1712680860000
        },
        {
            "open": 517.63,
            "high": 517.665,
            "low": 517.51,
            "close": 517.5401,
            "volume": 28197,
            "datetime": 1712680920000
        },
        {
            "open": 517.54,
            "high": 517.695,
            "low": 517.54,
            "close": 517.695,
            "volume": 33350,
            "datetime": 1712680980000
        },
        {
            "open": 517.69,
            "high": 517.69,
            "low": 517.46,
            "close": 517.4699,
            "volume": 70045,
            "datetime": 1712681040000
        },
        {
            "open": 517.48,
            "high": 517.61,
            "low": 517.4,
            "close": 517.42,
            "volume": 53622,
            "datetime": 1712681100000
        },
        {
            "open": 517.43,
            "high": 517.44,
            "low": 517.25,
            "close": 517.29,
            "volume": 71894,
            "datetime": 1712681160000
        },
        {
            "open": 517.3,
            "high": 517.49,
            "low": 517.3,
            "close": 517.42,
            "volume": 54532,
            "datetime": 1712681220000
        },
        {
            "open": 517.43,
            "high": 517.51,
            "low": 517.365,
            "close": 517.4999,
            "volume": 43147,
            "datetime": 1712681280000
        },
        {
            "open": 517.49,
            "high": 517.495,
            "low": 517.41,
            "close": 517.425,
            "volume": 18359,
            "datetime": 1712681340000
        },
        {
            "open": 517.41,
            "high": 517.5,
            "low": 517.36,
            "close": 517.36,
            "volume": 48760,
            "datetime": 1712681400000
        },
        {
            "open": 517.3535,
            "high": 517.37,
            "low": 517.18,
            "close": 517.18,
            "volume": 61849,
            "datetime": 1712681460000
        },
        {
            "open": 517.17,
            "high": 517.2898,
            "low": 517.16,
            "close": 517.21,
            "volume": 59715,
            "datetime": 1712681520000
        },
        {
            "open": 517.2,
            "high": 517.21,
            "low": 516.82,
            "close": 516.89,
            "volume": 109601,
            "datetime": 1712681580000
        },
        {
            "open": 516.89,
            "high": 516.955,
            "low": 516.575,
            "close": 516.64,
            "volume": 176144,
            "datetime": 1712681640000
        },
        {
            "open": 516.63,
            "high": 516.81,
            "low": 516.59,
            "close": 516.7401,
            "volume": 127525,
            "datetime": 1712681700000
        },
        {
            "open": 516.75,
            "high": 516.86,
            "low": 516.73,
            "close": 516.79,
            "volume": 79865,
            "datetime": 1712681760000
        },
        {
            "open": 516.77,
            "high": 517.01,
            "low": 516.77,
            "close": 516.86,
            "volume": 71735,
            "datetime": 1712681820000
        },
        {
            "open": 516.86,
            "high": 516.87,
            "low": 516.71,
            "close": 516.725,
            "volume": 59847,
            "datetime": 1712681880000
        },
        {
            "open": 516.72,
            "high": 516.83,
            "low": 516.68,
            "close": 516.79,
            "volume": 27872,
            "datetime": 1712681940000
        },
        {
            "open": 516.8,
            "high": 516.8,
            "low": 516.67,
            "close": 516.79,
            "volume": 66048,
            "datetime": 1712682000000
        },
        {
            "open": 516.8,
            "high": 517.02,
            "low": 516.8,
            "close": 516.99,
            "volume": 53678,
            "datetime": 1712682060000
        },
        {
            "open": 517,
            "high": 517.02,
            "low": 516.6,
            "close": 517,
            "volume": 178933,
            "datetime": 1712682120000
        },
        {
            "open": 516.9998,
            "high": 517.05,
            "low": 516.86,
            "close": 516.955,
            "volume": 70641,
            "datetime": 1712682180000
        },
        {
            "open": 516.95,
            "high": 517.19,
            "low": 516.95,
            "close": 517.19,
            "volume": 59366,
            "datetime": 1712682240000
        },
        {
            "open": 517.19,
            "high": 517.295,
            "low": 517.09,
            "close": 517.23,
            "volume": 59134,
            "datetime": 1712682300000
        },
        {
            "open": 517.22,
            "high": 517.46,
            "low": 517.21,
            "close": 517.365,
            "volume": 80790,
            "datetime": 1712682360000
        },
        {
            "open": 517.3699,
            "high": 517.38,
            "low": 517.28,
            "close": 517.2999,
            "volume": 58421,
            "datetime": 1712682420000
        },
        {
            "open": 517.325,
            "high": 517.38,
            "low": 517.27,
            "close": 517.31,
            "volume": 53447,
            "datetime": 1712682480000
        },
        {
            "open": 517.3,
            "high": 517.43,
            "low": 517.22,
            "close": 517.39,
            "volume": 50654,
            "datetime": 1712682540000
        },
        {
            "open": 517.4,
            "high": 517.48,
            "low": 517.3,
            "close": 517.3297,
            "volume": 59744,
            "datetime": 1712682600000
        },
        {
            "open": 517.315,
            "high": 517.325,
            "low": 517.155,
            "close": 517.19,
            "volume": 45709,
            "datetime": 1712682660000
        },
        {
            "open": 517.16,
            "high": 517.31,
            "low": 517.16,
            "close": 517.255,
            "volume": 52251,
            "datetime": 1712682720000
        },
        {
            "open": 517.265,
            "high": 517.37,
            "low": 517.2,
            "close": 517.37,
            "volume": 42827,
            "datetime": 1712682780000
        },
        {
            "open": 517.37,
            "high": 517.42,
            "low": 517.28,
            "close": 517.3,
            "volume": 54433,
            "datetime": 1712682840000
        },
        {
            "open": 517.32,
            "high": 517.33,
            "low": 517.11,
            "close": 517.145,
            "volume": 71122,
            "datetime": 1712682900000
        },
        {
            "open": 517.14,
            "high": 517.14,
            "low": 517,
            "close": 517.0301,
            "volume": 45726,
            "datetime": 1712682960000
        },
        {
            "open": 517.03,
            "high": 517.19,
            "low": 517.025,
            "close": 517.15,
            "volume": 55536,
            "datetime": 1712683020000
        },
        {
            "open": 517.16,
            "high": 517.36,
            "low": 517.14,
            "close": 517.28,
            "volume": 86680,
            "datetime": 1712683080000
        },
        {
            "open": 517.27,
            "high": 517.275,
            "low": 517.095,
            "close": 517.23,
            "volume": 37082,
            "datetime": 1712683140000
        },
        {
            "open": 517.24,
            "high": 517.26,
            "low": 517.15,
            "close": 517.15,
            "volume": 27567,
            "datetime": 1712683200000
        },
        {
            "open": 517.15,
            "high": 517.5,
            "low": 517.12,
            "close": 517.5,
            "volume": 79657,
            "datetime": 1712683260000
        },
        {
            "open": 517.495,
            "high": 517.64,
            "low": 517.37,
            "close": 517.54,
            "volume": 103304,
            "datetime": 1712683320000
        },
        {
            "open": 517.55,
            "high": 517.63,
            "low": 517.5,
            "close": 517.515,
            "volume": 46508,
            "datetime": 1712683380000
        },
        {
            "open": 517.52,
            "high": 517.53,
            "low": 517.205,
            "close": 517.23,
            "volume": 52008,
            "datetime": 1712683440000
        },
        {
            "open": 517.225,
            "high": 517.31,
            "low": 517.105,
            "close": 517.225,
            "volume": 70368,
            "datetime": 1712683500000
        },
        {
            "open": 517.22,
            "high": 517.32,
            "low": 517.16,
            "close": 517.21,
            "volume": 57339,
            "datetime": 1712683560000
        },
        {
            "open": 517.22,
            "high": 517.23,
            "low": 516.98,
            "close": 517.06,
            "volume": 82813,
            "datetime": 1712683620000
        },
        {
            "open": 517.075,
            "high": 517.09,
            "low": 517,
            "close": 517.065,
            "volume": 51641,
            "datetime": 1712683680000
        },
        {
            "open": 517.07,
            "high": 517.2,
            "low": 517.06,
            "close": 517.16,
            "volume": 60612,
            "datetime": 1712683740000
        },
        {
            "open": 517.17,
            "high": 517.245,
            "low": 517.1,
            "close": 517.12,
            "volume": 40290,
            "datetime": 1712683800000
        },
        {
            "open": 517.124,
            "high": 517.29,
            "low": 517.05,
            "close": 517.24,
            "volume": 50539,
            "datetime": 1712683860000
        },
        {
            "open": 517.25,
            "high": 517.39,
            "low": 517.21,
            "close": 517.22,
            "volume": 48636,
            "datetime": 1712683920000
        },
        {
            "open": 517.16,
            "high": 517.16,
            "low": 517.06,
            "close": 517.08,
            "volume": 26042,
            "datetime": 1712683980000
        },
        {
            "open": 517.08,
            "high": 517.15,
            "low": 517.05,
            "close": 517.105,
            "volume": 38483,
            "datetime": 1712684040000
        },
        {
            "open": 517.12,
            "high": 517.26,
            "low": 517.1,
            "close": 517.22,
            "volume": 29558,
            "datetime": 1712684100000
        },
        {
            "open": 517.22,
            "high": 517.24,
            "low": 517.13,
            "close": 517.15,
            "volume": 24569,
            "datetime": 1712684160000
        },
        {
            "open": 517.14,
            "high": 517.2389,
            "low": 517.08,
            "close": 517.13,
            "volume": 16933,
            "datetime": 1712684220000
        },
        {
            "open": 517.145,
            "high": 517.34,
            "low": 517.14,
            "close": 517.28,
            "volume": 38508,
            "datetime": 1712684280000
        },
        {
            "open": 517.2951,
            "high": 517.48,
            "low": 517.28,
            "close": 517.48,
            "volume": 47838,
            "datetime": 1712684340000
        },
        {
            "open": 517.48,
            "high": 517.6,
            "low": 517.46,
            "close": 517.55,
            "volume": 76848,
            "datetime": 1712684400000
        },
        {
            "open": 517.55,
            "high": 517.64,
            "low": 517.33,
            "close": 517.3463,
            "volume": 67080,
            "datetime": 1712684460000
        },
        {
            "open": 517.35,
            "high": 517.36,
            "low": 516.87,
            "close": 516.9,
            "volume": 73937,
            "datetime": 1712684520000
        },
        {
            "open": 516.91,
            "high": 517.11,
            "low": 516.8999,
            "close": 517.04,
            "volume": 65474,
            "datetime": 1712684580000
        },
        {
            "open": 517.03,
            "high": 517.09,
            "low": 516.95,
            "close": 516.99,
            "volume": 51519,
            "datetime": 1712684640000
        },
        {
            "open": 516.99,
            "high": 517.052,
            "low": 516.84,
            "close": 516.93,
            "volume": 76834,
            "datetime": 1712684700000
        },
        {
            "open": 516.925,
            "high": 516.9799,
            "low": 516.845,
            "close": 516.97,
            "volume": 56392,
            "datetime": 1712684760000
        },
        {
            "open": 516.98,
            "high": 517.08,
            "low": 516.91,
            "close": 516.98,
            "volume": 54744,
            "datetime": 1712684820000
        },
        {
            "open": 516.92,
            "high": 516.96,
            "low": 516.84,
            "close": 516.88,
            "volume": 44472,
            "datetime": 1712684880000
        },
        {
            "open": 516.87,
            "high": 516.89,
            "low": 516.72,
            "close": 516.8557,
            "volume": 128148,
            "datetime": 1712684940000
        },
        {
            "open": 516.85,
            "high": 517.03,
            "low": 516.815,
            "close": 516.995,
            "volume": 104796,
            "datetime": 1712685000000
        },
        {
            "open": 516.995,
            "high": 517.15,
            "low": 516.99,
            "close": 517.075,
            "volume": 75728,
            "datetime": 1712685060000
        },
        {
            "open": 517.075,
            "high": 517.11,
            "low": 516.83,
            "close": 516.9,
            "volume": 62220,
            "datetime": 1712685120000
        },
        {
            "open": 516.9,
            "high": 516.94,
            "low": 516.83,
            "close": 516.93,
            "volume": 38753,
            "datetime": 1712685180000
        },
        {
            "open": 516.93,
            "high": 517.02,
            "low": 516.915,
            "close": 516.99,
            "volume": 42648,
            "datetime": 1712685240000
        },
        {
            "open": 517,
            "high": 517.01,
            "low": 516.82,
            "close": 516.83,
            "volume": 37790,
            "datetime": 1712685300000
        },
        {
            "open": 516.82,
            "high": 516.885,
            "low": 516.77,
            "close": 516.855,
            "volume": 42244,
            "datetime": 1712685360000
        },
        {
            "open": 516.86,
            "high": 516.98,
            "low": 516.81,
            "close": 516.81,
            "volume": 55439,
            "datetime": 1712685420000
        },
        {
            "open": 516.81,
            "high": 516.85,
            "low": 516.75,
            "close": 516.835,
            "volume": 34260,
            "datetime": 1712685480000
        },
        {
            "open": 516.8301,
            "high": 516.9,
            "low": 516.66,
            "close": 516.6701,
            "volume": 59472,
            "datetime": 1712685540000
        },
        {
            "open": 516.67,
            "high": 516.735,
            "low": 516.44,
            "close": 516.57,
            "volume": 92431,
            "datetime": 1712685600000
        },
        {
            "open": 516.58,
            "high": 516.65,
            "low": 516.45,
            "close": 516.484,
            "volume": 55656,
            "datetime": 1712685660000
        },
        {
            "open": 516.47,
            "high": 516.47,
            "low": 516.23,
            "close": 516.38,
            "volume": 82130,
            "datetime": 1712685720000
        },
        {
            "open": 516.39,
            "high": 516.486,
            "low": 516.35,
            "close": 516.3899,
            "volume": 42198,
            "datetime": 1712685780000
        },
        {
            "open": 516.37,
            "high": 516.66,
            "low": 516.335,
            "close": 516.63,
            "volume": 68732,
            "datetime": 1712685840000
        },
        {
            "open": 516.63,
            "high": 516.8,
            "low": 516.61,
            "close": 516.725,
            "volume": 51245,
            "datetime": 1712685900000
        },
        {
            "open": 516.72,
            "high": 516.84,
            "low": 516.68,
            "close": 516.75,
            "volume": 37036,
            "datetime": 1712685960000
        },
        {
            "open": 516.76,
            "high": 516.8,
            "low": 516.62,
            "close": 516.65,
            "volume": 35384,
            "datetime": 1712686020000
        },
        {
            "open": 516.65,
            "high": 516.8,
            "low": 516.5599,
            "close": 516.8,
            "volume": 62486,
            "datetime": 1712686080000
        },
        {
            "open": 516.81,
            "high": 517.05,
            "low": 516.76,
            "close": 516.98,
            "volume": 88554,
            "datetime": 1712686140000
        },
        {
            "open": 516.9911,
            "high": 517.0599,
            "low": 516.865,
            "close": 516.9,
            "volume": 62639,
            "datetime": 1712686200000
        },
        {
            "open": 516.89,
            "high": 516.94,
            "low": 516.73,
            "close": 516.76,
            "volume": 55971,
            "datetime": 1712686260000
        },
        {
            "open": 516.775,
            "high": 517.035,
            "low": 516.76,
            "close": 517.025,
            "volume": 68285,
            "datetime": 1712686320000
        },
        {
            "open": 517.03,
            "high": 517.03,
            "low": 516.84,
            "close": 516.915,
            "volume": 66645,
            "datetime": 1712686380000
        },
        {
            "open": 516.92,
            "high": 517.02,
            "low": 516.84,
            "close": 516.84,
            "volume": 55397,
            "datetime": 1712686440000
        },
        {
            "open": 516.84,
            "high": 517.04,
            "low": 516.83,
            "close": 516.96,
            "volume": 58403,
            "datetime": 1712686500000
        },
        {
            "open": 516.95,
            "high": 516.96,
            "low": 516.76,
            "close": 516.85,
            "volume": 60896,
            "datetime": 1712686560000
        },
        {
            "open": 516.85,
            "high": 516.92,
            "low": 516.83,
            "close": 516.87,
            "volume": 62289,
            "datetime": 1712686620000
        },
        {
            "open": 516.89,
            "high": 517.15,
            "low": 516.82,
            "close": 517.1,
            "volume": 79428,
            "datetime": 1712686680000
        },
        {
            "open": 517.1,
            "high": 517.17,
            "low": 517.04,
            "close": 517.0799,
            "volume": 60944,
            "datetime": 1712686740000
        },
        {
            "open": 517.08,
            "high": 517.12,
            "low": 517.02,
            "close": 517.11,
            "volume": 37924,
            "datetime": 1712686800000
        },
        {
            "open": 517.12,
            "high": 517.16,
            "low": 517.01,
            "close": 517.12,
            "volume": 32441,
            "datetime": 1712686860000
        },
        {
            "open": 517.13,
            "high": 517.29,
            "low": 517.13,
            "close": 517.21,
            "volume": 92637,
            "datetime": 1712686920000
        },
        {
            "open": 517.22,
            "high": 517.22,
            "low": 517.02,
            "close": 517.045,
            "volume": 69470,
            "datetime": 1712686980000
        },
        {
            "open": 517.0451,
            "high": 517.05,
            "low": 516.68,
            "close": 516.75,
            "volume": 70472,
            "datetime": 1712687040000
        },
        {
            "open": 516.76,
            "high": 517.01,
            "low": 516.76,
            "close": 516.975,
            "volume": 72654,
            "datetime": 1712687100000
        },
        {
            "open": 516.97,
            "high": 517.02,
            "low": 516.9,
            "close": 516.94,
            "volume": 28094,
            "datetime": 1712687160000
        },
        {
            "open": 516.94,
            "high": 516.95,
            "low": 516.81,
            "close": 516.854,
            "volume": 53985,
            "datetime": 1712687220000
        },
        {
            "open": 516.85,
            "high": 516.86,
            "low": 516.65,
            "close": 516.69,
            "volume": 55389,
            "datetime": 1712687280000
        },
        {
            "open": 516.68,
            "high": 516.78,
            "low": 516.53,
            "close": 516.65,
            "volume": 48331,
            "datetime": 1712687340000
        },
        {
            "open": 516.6575,
            "high": 516.7,
            "low": 516.375,
            "close": 516.515,
            "volume": 78126,
            "datetime": 1712687400000
        },
        {
            "open": 516.52,
            "high": 516.55,
            "low": 516.41,
            "close": 516.51,
            "volume": 62307,
            "datetime": 1712687460000
        },
        {
            "open": 516.5,
            "high": 516.625,
            "low": 516.4896,
            "close": 516.55,
            "volume": 85028,
            "datetime": 1712687520000
        },
        {
            "open": 516.55,
            "high": 516.595,
            "low": 516.45,
            "close": 516.46,
            "volume": 67476,
            "datetime": 1712687580000
        },
        {
            "open": 516.47,
            "high": 516.59,
            "low": 516.45,
            "close": 516.5804,
            "volume": 40900,
            "datetime": 1712687640000
        },
        {
            "open": 516.585,
            "high": 516.85,
            "low": 516.4603,
            "close": 516.77,
            "volume": 131270,
            "datetime": 1712687700000
        },
        {
            "open": 516.79,
            "high": 516.91,
            "low": 516.67,
            "close": 516.68,
            "volume": 77469,
            "datetime": 1712687760000
        },
        {
            "open": 516.67,
            "high": 516.7,
            "low": 516.55,
            "close": 516.68,
            "volume": 50566,
            "datetime": 1712687820000
        },
        {
            "open": 516.68,
            "high": 516.82,
            "low": 516.68,
            "close": 516.765,
            "volume": 37797,
            "datetime": 1712687880000
        },
        {
            "open": 516.74,
            "high": 516.74,
            "low": 516.4,
            "close": 516.48,
            "volume": 80341,
            "datetime": 1712687940000
        },
        {
            "open": 516.47,
            "high": 516.6,
            "low": 516.46,
            "close": 516.48,
            "volume": 40184,
            "datetime": 1712688000000
        },
        {
            "open": 516.47,
            "high": 516.63,
            "low": 516.345,
            "close": 516.53,
            "volume": 83519,
            "datetime": 1712688060000
        },
        {
            "open": 516.525,
            "high": 516.63,
            "low": 516.4595,
            "close": 516.62,
            "volume": 43012,
            "datetime": 1712688120000
        },
        {
            "open": 516.63,
            "high": 516.72,
            "low": 516.58,
            "close": 516.64,
            "volume": 49237,
            "datetime": 1712688180000
        },
        {
            "open": 516.645,
            "high": 516.68,
            "low": 516.43,
            "close": 516.4786,
            "volume": 34954,
            "datetime": 1712688240000
        },
        {
            "open": 516.48,
            "high": 516.53,
            "low": 516.34,
            "close": 516.34,
            "volume": 55529,
            "datetime": 1712688300000
        },
        {
            "open": 516.35,
            "high": 516.48,
            "low": 516.295,
            "close": 516.45,
            "volume": 53837,
            "datetime": 1712688360000
        },
        {
            "open": 516.47,
            "high": 516.56,
            "low": 516.4,
            "close": 516.555,
            "volume": 71682,
            "datetime": 1712688420000
        },
        {
            "open": 516.55,
            "high": 516.7,
            "low": 516.5001,
            "close": 516.51,
            "volume": 59414,
            "datetime": 1712688480000
        },
        {
            "open": 516.495,
            "high": 516.495,
            "low": 516.335,
            "close": 516.4,
            "volume": 36752,
            "datetime": 1712688540000
        },
        {
            "open": 516.41,
            "high": 516.52,
            "low": 516.2599,
            "close": 516.46,
            "volume": 70957,
            "datetime": 1712688600000
        },
        {
            "open": 516.46,
            "high": 516.61,
            "low": 516.3706,
            "close": 516.52,
            "volume": 80887,
            "datetime": 1712688660000
        },
        {
            "open": 516.53,
            "high": 516.75,
            "low": 516.52,
            "close": 516.655,
            "volume": 105028,
            "datetime": 1712688720000
        },
        {
            "open": 516.66,
            "high": 516.815,
            "low": 516.65,
            "close": 516.75,
            "volume": 43001,
            "datetime": 1712688780000
        },
        {
            "open": 516.735,
            "high": 516.74,
            "low": 516.595,
            "close": 516.606,
            "volume": 36829,
            "datetime": 1712688840000
        },
        {
            "open": 516.6,
            "high": 516.63,
            "low": 516.49,
            "close": 516.56,
            "volume": 55956,
            "datetime": 1712688900000
        },
        {
            "open": 516.57,
            "high": 516.72,
            "low": 516.535,
            "close": 516.68,
            "volume": 50754,
            "datetime": 1712688960000
        },
        {
            "open": 516.68,
            "high": 516.6898,
            "low": 516.55,
            "close": 516.61,
            "volume": 30287,
            "datetime": 1712689020000
        },
        {
            "open": 516.6,
            "high": 516.6791,
            "low": 516.48,
            "close": 516.67,
            "volume": 56033,
            "datetime": 1712689080000
        },
        {
            "open": 516.6605,
            "high": 516.665,
            "low": 516.46,
            "close": 516.51,
            "volume": 38120,
            "datetime": 1712689140000
        },
        {
            "open": 516.5,
            "high": 516.52,
            "low": 516.23,
            "close": 516.245,
            "volume": 89901,
            "datetime": 1712689200000
        },
        {
            "open": 516.22,
            "high": 516.29,
            "low": 516.135,
            "close": 516.25,
            "volume": 106338,
            "datetime": 1712689260000
        },
        {
            "open": 516.24,
            "high": 516.25,
            "low": 516.04,
            "close": 516.06,
            "volume": 63027,
            "datetime": 1712689320000
        },
        {
            "open": 516.045,
            "high": 516.4,
            "low": 516.035,
            "close": 516.36,
            "volume": 74009,
            "datetime": 1712689380000
        },
        {
            "open": 516.36,
            "high": 516.3701,
            "low": 516.23,
            "close": 516.34,
            "volume": 35262,
            "datetime": 1712689440000
        },
        {
            "open": 516.32,
            "high": 516.39,
            "low": 516.28,
            "close": 516.34,
            "volume": 57737,
            "datetime": 1712689500000
        },
        {
            "open": 516.34,
            "high": 516.46,
            "low": 516.19,
            "close": 516.19,
            "volume": 116344,
            "datetime": 1712689560000
        },
        {
            "open": 516.19,
            "high": 516.215,
            "low": 516.04,
            "close": 516.14,
            "volume": 61770,
            "datetime": 1712689620000
        },
        {
            "open": 516.14,
            "high": 516.17,
            "low": 515.98,
            "close": 516.04,
            "volume": 62914,
            "datetime": 1712689680000
        },
        {
            "open": 516.04,
            "high": 516.11,
            "low": 515.77,
            "close": 515.915,
            "volume": 148281,
            "datetime": 1712689740000
        },
        {
            "open": 515.9,
            "high": 515.9908,
            "low": 515.725,
            "close": 515.83,
            "volume": 77858,
            "datetime": 1712689800000
        },
        {
            "open": 515.82,
            "high": 515.85,
            "low": 515.71,
            "close": 515.8399,
            "volume": 78249,
            "datetime": 1712689860000
        },
        {
            "open": 515.82,
            "high": 516.02,
            "low": 515.8099,
            "close": 515.98,
            "volume": 77091,
            "datetime": 1712689920000
        },
        {
            "open": 515.97,
            "high": 516.15,
            "low": 515.92,
            "close": 516.115,
            "volume": 58924,
            "datetime": 1712689980000
        },
        {
            "open": 516.14,
            "high": 516.21,
            "low": 516.07,
            "close": 516.07,
            "volume": 74822,
            "datetime": 1712690040000
        },
        {
            "open": 516.065,
            "high": 516.245,
            "low": 516.03,
            "close": 516.2303,
            "volume": 75945,
            "datetime": 1712690100000
        },
        {
            "open": 516.235,
            "high": 516.39,
            "low": 516.1673,
            "close": 516.31,
            "volume": 91508,
            "datetime": 1712690160000
        },
        {
            "open": 516.32,
            "high": 516.48,
            "low": 516.2905,
            "close": 516.465,
            "volume": 67244,
            "datetime": 1712690220000
        },
        {
            "open": 516.465,
            "high": 516.63,
            "low": 516.435,
            "close": 516.535,
            "volume": 77001,
            "datetime": 1712690280000
        },
        {
            "open": 516.5331,
            "high": 516.75,
            "low": 516.5,
            "close": 516.75,
            "volume": 68944,
            "datetime": 1712690340000
        },
        {
            "open": 516.75,
            "high": 516.9001,
            "low": 516.7,
            "close": 516.78,
            "volume": 130918,
            "datetime": 1712690400000
        },
        {
            "open": 516.78,
            "high": 516.96,
            "low": 516.695,
            "close": 516.9399,
            "volume": 84758,
            "datetime": 1712690460000
        },
        {
            "open": 516.93,
            "high": 517.46,
            "low": 516.8813,
            "close": 517.25,
            "volume": 170991,
            "datetime": 1712690520000
        },
        {
            "open": 517.26,
            "high": 517.41,
            "low": 517.22,
            "close": 517.35,
            "volume": 85817,
            "datetime": 1712690580000
        },
        {
            "open": 517.335,
            "high": 517.44,
            "low": 517.275,
            "close": 517.39,
            "volume": 69451,
            "datetime": 1712690640000
        },
        {
            "open": 517.37,
            "high": 517.64,
            "low": 517.37,
            "close": 517.5201,
            "volume": 126624,
            "datetime": 1712690700000
        },
        {
            "open": 517.52,
            "high": 518.07,
            "low": 517.52,
            "close": 517.8799,
            "volume": 288349,
            "datetime": 1712690760000
        },
        {
            "open": 517.874,
            "high": 517.99,
            "low": 517.845,
            "close": 517.95,
            "volume": 258321,
            "datetime": 1712690820000
        },
        {
            "open": 517.95,
            "high": 518.12,
            "low": 517.91,
            "close": 518.03,
            "volume": 241920,
            "datetime": 1712690880000
        },
        {
            "open": 518.04,
            "high": 518.61,
            "low": 518.04,
            "close": 518.55,
            "volume": 366959,
            "datetime": 1712690940000
        },
        {
            "open": 518.51,
            "high": 518.615,
            "low": 518.26,
            "close": 518.38,
            "volume": 255634,
            "datetime": 1712691000000
        },
        {
            "open": 518.38,
            "high": 519.02,
            "low": 518.38,
            "close": 518.71,
            "volume": 308570,
            "datetime": 1712691060000
        },
        {
            "open": 518.71,
            "high": 518.86,
            "low": 518.6,
            "close": 518.6999,
            "volume": 231673,
            "datetime": 1712691120000
        },
        {
            "open": 518.69,
            "high": 518.77,
            "low": 518.55,
            "close": 518.69,
            "volume": 154555,
            "datetime": 1712691180000
        },
        {
            "open": 518.72,
            "high": 518.73,
            "low": 518.335,
            "close": 518.45,
            "volume": 175660,
            "datetime": 1712691240000
        },
        {
            "open": 518.47,
            "high": 518.6,
            "low": 518.3675,
            "close": 518.55,
            "volume": 146299,
            "datetime": 1712691300000
        },
        {
            "open": 518.56,
            "high": 518.56,
            "low": 518.26,
            "close": 518.3701,
            "volume": 128570,
            "datetime": 1712691360000
        },
        {
            "open": 518.39,
            "high": 518.39,
            "low": 518.02,
            "close": 518.04,
            "volume": 181417,
            "datetime": 1712691420000
        },
        {
            "open": 518.04,
            "high": 518.16,
            "low": 518.02,
            "close": 518.1102,
            "volume": 105026,
            "datetime": 1712691480000
        },
        {
            "open": 518.13,
            "high": 518.14,
            "low": 517.78,
            "close": 517.795,
            "volume": 146748,
            "datetime": 1712691540000
        },
        {
            "open": 517.8,
            "high": 517.9988,
            "low": 517.8,
            "close": 517.93,
            "volume": 140433,
            "datetime": 1712691600000
        },
        {
            "open": 517.93,
            "high": 517.94,
            "low": 517.465,
            "close": 517.47,
            "volume": 230677,
            "datetime": 1712691660000
        },
        {
            "open": 517.48,
            "high": 517.86,
            "low": 517.45,
            "close": 517.81,
            "volume": 160047,
            "datetime": 1712691720000
        },
        {
            "open": 517.83,
            "high": 517.92,
            "low": 517.7,
            "close": 517.74,
            "volume": 88199,
            "datetime": 1712691780000
        },
        {
            "open": 517.7482,
            "high": 518.11,
            "low": 517.745,
            "close": 518.03,
            "volume": 163703,
            "datetime": 1712691840000
        },
        {
            "open": 518.03,
            "high": 518.14,
            "low": 517.77,
            "close": 517.8,
            "volume": 151966,
            "datetime": 1712691900000
        },
        {
            "open": 517.79,
            "high": 517.899,
            "low": 517.71,
            "close": 517.85,
            "volume": 113901,
            "datetime": 1712691960000
        },
        {
            "open": 517.84,
            "high": 517.91,
            "low": 517.72,
            "close": 517.84,
            "volume": 113414,
            "datetime": 1712692020000
        },
        {
            "open": 517.85,
            "high": 517.98,
            "low": 517.755,
            "close": 517.86,
            "volume": 127162,
            "datetime": 1712692080000
        },
        {
            "open": 517.855,
            "high": 517.96,
            "low": 517.725,
            "close": 517.95,
            "volume": 152828,
            "datetime": 1712692140000
        },
        {
            "open": 517.95,
            "high": 517.97,
            "low": 517.75,
            "close": 517.9495,
            "volume": 210627,
            "datetime": 1712692200000
        },
        {
            "open": 517.95,
            "high": 517.98,
            "low": 517.6801,
            "close": 517.76,
            "volume": 125177,
            "datetime": 1712692260000
        },
        {
            "open": 517.75,
            "high": 518.05,
            "low": 517.71,
            "close": 518.0494,
            "volume": 125924,
            "datetime": 1712692320000
        },
        {
            "open": 518.05,
            "high": 518.07,
            "low": 517.81,
            "close": 517.93,
            "volume": 163011,
            "datetime": 1712692380000
        },
        {
            "open": 517.925,
            "high": 518.21,
            "low": 517.925,
            "close": 518.21,
            "volume": 314556,
            "datetime": 1712692440000
        },
        {
            "open": 518.2,
            "high": 518.635,
            "low": 518.2,
            "close": 518.6,
            "volume": 452477,
            "datetime": 1712692500000
        },
        {
            "open": 518.61,
            "high": 518.725,
            "low": 518.525,
            "close": 518.725,
            "volume": 415972,
            "datetime": 1712692560000
        },
        {
            "open": 518.725,
            "high": 518.74,
            "low": 518.37,
            "close": 518.57,
            "volume": 541706,
            "datetime": 1712692620000
        },
        {
            "open": 518.565,
            "high": 518.87,
            "low": 518.47,
            "close": 518.86,
            "volume": 907766,
            "datetime": 1712692680000
        },
        {
            "open": 518.85,
            "high": 519.44,
            "low": 518.81,
            "close": 519.29,
            "volume": 2166451,
            "datetime": 1712692740000
        },
        {
            "open": 513.48,
            "high": 513.61,
            "low": 513.18,
            "close": 513.28,
            "volume": 585259,
            "datetime": 1712755800000
        },
        {
            "open": 513.27,
            "high": 513.27,
            "low": 512.76,
            "close": 512.91,
            "volume": 490342,
            "datetime": 1712755860000
        },
        {
            "open": 512.91,
            "high": 513.66,
            "low": 512.86,
            "close": 513.64,
            "volume": 340053,
            "datetime": 1712755920000
        },
        {
            "open": 513.63,
            "high": 513.63,
            "low": 513.14,
            "close": 513.2399,
            "volume": 307967,
            "datetime": 1712755980000
        },
        {
            "open": 513.22,
            "high": 513.3899,
            "low": 512.94,
            "close": 513.3899,
            "volume": 325504,
            "datetime": 1712756040000
        },
        {
            "open": 513.365,
            "high": 513.82,
            "low": 513.2,
            "close": 513.77,
            "volume": 451889,
            "datetime": 1712756100000
        },
        {
            "open": 513.755,
            "high": 513.91,
            "low": 513.43,
            "close": 513.6,
            "volume": 273085,
            "datetime": 1712756160000
        },
        {
            "open": 513.605,
            "high": 513.605,
            "low": 512.98,
            "close": 513.06,
            "volume": 260296,
            "datetime": 1712756220000
        },
        {
            "open": 513.08,
            "high": 513.77,
            "low": 512.98,
            "close": 513.68,
            "volume": 359777,
            "datetime": 1712756280000
        },
        {
            "open": 513.7,
            "high": 513.72,
            "low": 513.17,
            "close": 513.5,
            "volume": 234498,
            "datetime": 1712756340000
        },
        {
            "open": 513.47,
            "high": 513.485,
            "low": 513.1,
            "close": 513.15,
            "volume": 191090,
            "datetime": 1712756400000
        },
        {
            "open": 513.145,
            "high": 513.385,
            "low": 513.12,
            "close": 513.28,
            "volume": 128785,
            "datetime": 1712756460000
        },
        {
            "open": 513.26,
            "high": 513.51,
            "low": 513.1,
            "close": 513.12,
            "volume": 196258,
            "datetime": 1712756520000
        },
        {
            "open": 513.1,
            "high": 513.74,
            "low": 513.06,
            "close": 513.68,
            "volume": 149690,
            "datetime": 1712756580000
        },
        {
            "open": 513.72,
            "high": 514.16,
            "low": 513.62,
            "close": 514.0506,
            "volume": 257094,
            "datetime": 1712756640000
        },
        {
            "open": 514.06,
            "high": 514.1,
            "low": 513.17,
            "close": 513.28,
            "volume": 444137,
            "datetime": 1712756700000
        },
        {
            "open": 513.29,
            "high": 513.66,
            "low": 513.01,
            "close": 513.07,
            "volume": 357464,
            "datetime": 1712756760000
        },
        {
            "open": 513.07,
            "high": 513.17,
            "low": 512.72,
            "close": 513.0212,
            "volume": 390042,
            "datetime": 1712756820000
        },
        {
            "open": 513.03,
            "high": 513.15,
            "low": 512.84,
            "close": 512.94,
            "volume": 193885,
            "datetime": 1712756880000
        },
        {
            "open": 512.93,
            "high": 513.39,
            "low": 512.67,
            "close": 512.7601,
            "volume": 273445,
            "datetime": 1712756940000
        },
        {
            "open": 512.78,
            "high": 513.39,
            "low": 512.72,
            "close": 513.3,
            "volume": 248262,
            "datetime": 1712757000000
        },
        {
            "open": 513.31,
            "high": 513.84,
            "low": 513.21,
            "close": 513.78,
            "volume": 282032,
            "datetime": 1712757060000
        },
        {
            "open": 513.77,
            "high": 513.82,
            "low": 513.34,
            "close": 513.46,
            "volume": 201415,
            "datetime": 1712757120000
        },
        {
            "open": 513.46,
            "high": 513.75,
            "low": 513.14,
            "close": 513.72,
            "volume": 228049,
            "datetime": 1712757180000
        },
        {
            "open": 513.76,
            "high": 514.3,
            "low": 513.6,
            "close": 514.125,
            "volume": 339364,
            "datetime": 1712757240000
        },
        {
            "open": 514.12,
            "high": 514.12,
            "low": 513.67,
            "close": 513.8896,
            "volume": 222942,
            "datetime": 1712757300000
        },
        {
            "open": 513.88,
            "high": 514.32,
            "low": 513.75,
            "close": 514.26,
            "volume": 211881,
            "datetime": 1712757360000
        },
        {
            "open": 514.29,
            "high": 514.48,
            "low": 514.05,
            "close": 514.225,
            "volume": 301298,
            "datetime": 1712757420000
        },
        {
            "open": 514.21,
            "high": 514.28,
            "low": 513.99,
            "close": 514.1901,
            "volume": 180975,
            "datetime": 1712757480000
        },
        {
            "open": 514.21,
            "high": 514.22,
            "low": 513.79,
            "close": 513.985,
            "volume": 203117,
            "datetime": 1712757540000
        },
        {
            "open": 514,
            "high": 514,
            "low": 513.53,
            "close": 513.59,
            "volume": 263824,
            "datetime": 1712757600000
        },
        {
            "open": 513.6,
            "high": 514.22,
            "low": 513.59,
            "close": 513.89,
            "volume": 200844,
            "datetime": 1712757660000
        },
        {
            "open": 513.87,
            "high": 513.985,
            "low": 513.6401,
            "close": 513.75,
            "volume": 111868,
            "datetime": 1712757720000
        },
        {
            "open": 513.73,
            "high": 513.83,
            "low": 513.41,
            "close": 513.8,
            "volume": 220531,
            "datetime": 1712757780000
        },
        {
            "open": 513.8001,
            "high": 513.8001,
            "low": 513.48,
            "close": 513.5597,
            "volume": 121993,
            "datetime": 1712757840000
        },
        {
            "open": 513.57,
            "high": 514.04,
            "low": 513.57,
            "close": 513.8921,
            "volume": 143162,
            "datetime": 1712757900000
        },
        {
            "open": 513.87,
            "high": 514,
            "low": 513.475,
            "close": 513.57,
            "volume": 131431,
            "datetime": 1712757960000
        },
        {
            "open": 513.58,
            "high": 514.14,
            "low": 513.5766,
            "close": 514.05,
            "volume": 104870,
            "datetime": 1712758020000
        },
        {
            "open": 514.07,
            "high": 514.39,
            "low": 514,
            "close": 514.29,
            "volume": 123965,
            "datetime": 1712758080000
        },
        {
            "open": 514.32,
            "high": 514.87,
            "low": 514.12,
            "close": 514.46,
            "volume": 226187,
            "datetime": 1712758140000
        },
        {
            "open": 514.44,
            "high": 514.49,
            "low": 514.15,
            "close": 514.34,
            "volume": 215153,
            "datetime": 1712758200000
        },
        {
            "open": 514.33,
            "high": 514.46,
            "low": 514.205,
            "close": 514.34,
            "volume": 138284,
            "datetime": 1712758260000
        },
        {
            "open": 514.36,
            "high": 514.49,
            "low": 514.27,
            "close": 514.39,
            "volume": 126730,
            "datetime": 1712758320000
        },
        {
            "open": 514.39,
            "high": 514.49,
            "low": 513.95,
            "close": 514.22,
            "volume": 197759,
            "datetime": 1712758380000
        },
        {
            "open": 514.23,
            "high": 514.31,
            "low": 514.02,
            "close": 514.15,
            "volume": 142571,
            "datetime": 1712758440000
        },
        {
            "open": 514.17,
            "high": 514.3,
            "low": 513.73,
            "close": 513.74,
            "volume": 151219,
            "datetime": 1712758500000
        },
        {
            "open": 513.75,
            "high": 514.37,
            "low": 513.75,
            "close": 514.1401,
            "volume": 128135,
            "datetime": 1712758560000
        },
        {
            "open": 514.12,
            "high": 514.32,
            "low": 514.02,
            "close": 514.29,
            "volume": 75964,
            "datetime": 1712758620000
        },
        {
            "open": 514.29,
            "high": 514.68,
            "low": 514.29,
            "close": 514.585,
            "volume": 180805,
            "datetime": 1712758680000
        },
        {
            "open": 514.62,
            "high": 515.045,
            "low": 514.55,
            "close": 514.865,
            "volume": 362100,
            "datetime": 1712758740000
        },
        {
            "open": 514.86,
            "high": 515.01,
            "low": 514.69,
            "close": 514.9,
            "volume": 119442,
            "datetime": 1712758800000
        },
        {
            "open": 514.87,
            "high": 514.9603,
            "low": 514.6,
            "close": 514.75,
            "volume": 175749,
            "datetime": 1712758860000
        },
        {
            "open": 514.77,
            "high": 514.99,
            "low": 514.745,
            "close": 514.8,
            "volume": 80656,
            "datetime": 1712758920000
        },
        {
            "open": 514.78,
            "high": 514.93,
            "low": 514.51,
            "close": 514.58,
            "volume": 159299,
            "datetime": 1712758980000
        },
        {
            "open": 514.58,
            "high": 514.835,
            "low": 514.5599,
            "close": 514.69,
            "volume": 146605,
            "datetime": 1712759040000
        },
        {
            "open": 514.69,
            "high": 515.13,
            "low": 514.675,
            "close": 514.89,
            "volume": 250103,
            "datetime": 1712759100000
        },
        {
            "open": 514.89,
            "high": 514.93,
            "low": 514.59,
            "close": 514.738,
            "volume": 159418,
            "datetime": 1712759160000
        },
        {
            "open": 514.73,
            "high": 514.8493,
            "low": 514.56,
            "close": 514.665,
            "volume": 103774,
            "datetime": 1712759220000
        },
        {
            "open": 514.66,
            "high": 514.84,
            "low": 514.605,
            "close": 514.64,
            "volume": 77801,
            "datetime": 1712759280000
        },
        {
            "open": 514.64,
            "high": 514.84,
            "low": 514.63,
            "close": 514.67,
            "volume": 84460,
            "datetime": 1712759340000
        },
        {
            "open": 514.66,
            "high": 514.84,
            "low": 514.605,
            "close": 514.76,
            "volume": 163021,
            "datetime": 1712759400000
        },
        {
            "open": 514.76,
            "high": 514.945,
            "low": 514.67,
            "close": 514.7078,
            "volume": 96528,
            "datetime": 1712759460000
        },
        {
            "open": 514.7,
            "high": 515.18,
            "low": 514.56,
            "close": 515.055,
            "volume": 142126,
            "datetime": 1712759520000
        },
        {
            "open": 515.06,
            "high": 515.401,
            "low": 514.97,
            "close": 515.34,
            "volume": 270013,
            "datetime": 1712759580000
        },
        {
            "open": 515.405,
            "high": 515.57,
            "low": 515.18,
            "close": 515.28,
            "volume": 268945,
            "datetime": 1712759640000
        },
        {
            "open": 515.3,
            "high": 515.465,
            "low": 515.18,
            "close": 515.24,
            "volume": 125396,
            "datetime": 1712759700000
        },
        {
            "open": 515.2603,
            "high": 515.52,
            "low": 515.2601,
            "close": 515.5,
            "volume": 118699,
            "datetime": 1712759760000
        },
        {
            "open": 515.48,
            "high": 515.51,
            "low": 515.31,
            "close": 515.43,
            "volume": 109584,
            "datetime": 1712759820000
        },
        {
            "open": 515.45,
            "high": 516.16,
            "low": 515.45,
            "close": 516.065,
            "volume": 300808,
            "datetime": 1712759880000
        },
        {
            "open": 516.055,
            "high": 516.15,
            "low": 515.87,
            "close": 515.955,
            "volume": 180525,
            "datetime": 1712759940000
        },
        {
            "open": 515.96,
            "high": 516.03,
            "low": 515.64,
            "close": 515.76,
            "volume": 199491,
            "datetime": 1712760000000
        },
        {
            "open": 515.77,
            "high": 515.89,
            "low": 515.68,
            "close": 515.72,
            "volume": 93865,
            "datetime": 1712760060000
        },
        {
            "open": 515.76,
            "high": 515.79,
            "low": 515.57,
            "close": 515.58,
            "volume": 103846,
            "datetime": 1712760120000
        },
        {
            "open": 515.59,
            "high": 515.69,
            "low": 515.495,
            "close": 515.54,
            "volume": 131415,
            "datetime": 1712760180000
        },
        {
            "open": 515.56,
            "high": 515.63,
            "low": 515.25,
            "close": 515.26,
            "volume": 155630,
            "datetime": 1712760240000
        },
        {
            "open": 515.268,
            "high": 515.5,
            "low": 515.268,
            "close": 515.3483,
            "volume": 89388,
            "datetime": 1712760300000
        },
        {
            "open": 515.355,
            "high": 515.43,
            "low": 514.97,
            "close": 514.985,
            "volume": 160594,
            "datetime": 1712760360000
        },
        {
            "open": 514.985,
            "high": 515.19,
            "low": 514.97,
            "close": 515.14,
            "volume": 94242,
            "datetime": 1712760420000
        },
        {
            "open": 515.18,
            "high": 515.22,
            "low": 515,
            "close": 515.01,
            "volume": 69906,
            "datetime": 1712760480000
        },
        {
            "open": 515.02,
            "high": 515.15,
            "low": 514.82,
            "close": 515.09,
            "volume": 157216,
            "datetime": 1712760540000
        },
        {
            "open": 515.11,
            "high": 515.29,
            "low": 515.05,
            "close": 515.07,
            "volume": 93315,
            "datetime": 1712760600000
        },
        {
            "open": 515.09,
            "high": 515.19,
            "low": 514.64,
            "close": 514.72,
            "volume": 154440,
            "datetime": 1712760660000
        },
        {
            "open": 514.72,
            "high": 514.8101,
            "low": 514.615,
            "close": 514.78,
            "volume": 94976,
            "datetime": 1712760720000
        },
        {
            "open": 514.79,
            "high": 514.9307,
            "low": 514.625,
            "close": 514.68,
            "volume": 66045,
            "datetime": 1712760780000
        },
        {
            "open": 514.66,
            "high": 514.67,
            "low": 514.3699,
            "close": 514.4101,
            "volume": 193332,
            "datetime": 1712760840000
        },
        {
            "open": 514.42,
            "high": 514.525,
            "low": 514.14,
            "close": 514.235,
            "volume": 215750,
            "datetime": 1712760900000
        },
        {
            "open": 514.235,
            "high": 514.355,
            "low": 514.17,
            "close": 514.215,
            "volume": 174766,
            "datetime": 1712760960000
        },
        {
            "open": 514.22,
            "high": 514.33,
            "low": 514.04,
            "close": 514.09,
            "volume": 120594,
            "datetime": 1712761020000
        },
        {
            "open": 514.08,
            "high": 514.34,
            "low": 513.97,
            "close": 514.27,
            "volume": 204954,
            "datetime": 1712761080000
        },
        {
            "open": 514.3,
            "high": 514.51,
            "low": 514.3,
            "close": 514.46,
            "volume": 121798,
            "datetime": 1712761140000
        },
        {
            "open": 514.47,
            "high": 514.53,
            "low": 514.2,
            "close": 514.21,
            "volume": 160457,
            "datetime": 1712761200000
        },
        {
            "open": 514.21,
            "high": 514.245,
            "low": 514.01,
            "close": 514.075,
            "volume": 103935,
            "datetime": 1712761260000
        },
        {
            "open": 514.07,
            "high": 514.26,
            "low": 514.06,
            "close": 514.21,
            "volume": 115672,
            "datetime": 1712761320000
        },
        {
            "open": 514.2,
            "high": 514.2,
            "low": 513.99,
            "close": 514.01,
            "volume": 65905,
            "datetime": 1712761380000
        },
        {
            "open": 514.02,
            "high": 514.04,
            "low": 513.875,
            "close": 513.9115,
            "volume": 136789,
            "datetime": 1712761440000
        },
        {
            "open": 513.92,
            "high": 514.105,
            "low": 513.865,
            "close": 513.955,
            "volume": 113122,
            "datetime": 1712761500000
        },
        {
            "open": 513.97,
            "high": 513.995,
            "low": 513.82,
            "close": 513.9,
            "volume": 129839,
            "datetime": 1712761560000
        },
        {
            "open": 513.89,
            "high": 514.33,
            "low": 513.87,
            "close": 514.2901,
            "volume": 109501,
            "datetime": 1712761620000
        },
        {
            "open": 514.29,
            "high": 514.31,
            "low": 513.91,
            "close": 513.915,
            "volume": 96727,
            "datetime": 1712761680000
        },
        {
            "open": 513.91,
            "high": 514.105,
            "low": 513.81,
            "close": 514.065,
            "volume": 130129,
            "datetime": 1712761740000
        },
        {
            "open": 514.065,
            "high": 514.075,
            "low": 513.81,
            "close": 513.835,
            "volume": 105049,
            "datetime": 1712761800000
        },
        {
            "open": 513.83,
            "high": 514.01,
            "low": 513.82,
            "close": 513.98,
            "volume": 72114,
            "datetime": 1712761860000
        },
        {
            "open": 513.97,
            "high": 514.005,
            "low": 513.74,
            "close": 513.755,
            "volume": 80628,
            "datetime": 1712761920000
        },
        {
            "open": 513.76,
            "high": 513.99,
            "low": 513.54,
            "close": 513.94,
            "volume": 254049,
            "datetime": 1712761980000
        },
        {
            "open": 513.955,
            "high": 514.035,
            "low": 513.84,
            "close": 513.87,
            "volume": 53768,
            "datetime": 1712762040000
        },
        {
            "open": 513.875,
            "high": 513.91,
            "low": 513.67,
            "close": 513.735,
            "volume": 103771,
            "datetime": 1712762100000
        },
        {
            "open": 513.74,
            "high": 514,
            "low": 513.74,
            "close": 513.86,
            "volume": 125669,
            "datetime": 1712762160000
        },
        {
            "open": 513.86,
            "high": 513.895,
            "low": 513.5,
            "close": 513.5,
            "volume": 82959,
            "datetime": 1712762220000
        },
        {
            "open": 513.49,
            "high": 513.75,
            "low": 513.48,
            "close": 513.745,
            "volume": 109939,
            "datetime": 1712762280000
        },
        {
            "open": 513.74,
            "high": 513.74,
            "low": 513.565,
            "close": 513.62,
            "volume": 67845,
            "datetime": 1712762340000
        },
        {
            "open": 513.61,
            "high": 513.905,
            "low": 513.61,
            "close": 513.9,
            "volume": 86330,
            "datetime": 1712762400000
        },
        {
            "open": 513.91,
            "high": 514.1399,
            "low": 513.905,
            "close": 513.99,
            "volume": 144534,
            "datetime": 1712762460000
        },
        {
            "open": 513.98,
            "high": 513.99,
            "low": 513.802,
            "close": 513.92,
            "volume": 64771,
            "datetime": 1712762520000
        },
        {
            "open": 513.915,
            "high": 513.96,
            "low": 513.81,
            "close": 513.95,
            "volume": 70269,
            "datetime": 1712762580000
        },
        {
            "open": 513.945,
            "high": 513.97,
            "low": 513.77,
            "close": 513.88,
            "volume": 62903,
            "datetime": 1712762640000
        },
        {
            "open": 513.88,
            "high": 513.98,
            "low": 513.86,
            "close": 513.9,
            "volume": 74141,
            "datetime": 1712762700000
        },
        {
            "open": 513.91,
            "high": 514.1,
            "low": 513.91,
            "close": 514.02,
            "volume": 75874,
            "datetime": 1712762760000
        },
        {
            "open": 514.02,
            "high": 514.33,
            "low": 514.01,
            "close": 514.16,
            "volume": 132435,
            "datetime": 1712762820000
        },
        {
            "open": 514.14,
            "high": 514.4,
            "low": 514.14,
            "close": 514.33,
            "volume": 127914,
            "datetime": 1712762880000
        },
        {
            "open": 514.35,
            "high": 514.49,
            "low": 514.28,
            "close": 514.485,
            "volume": 90812,
            "datetime": 1712762940000
        },
        {
            "open": 514.49,
            "high": 514.53,
            "low": 514.22,
            "close": 514.28,
            "volume": 160784,
            "datetime": 1712763000000
        },
        {
            "open": 514.265,
            "high": 514.265,
            "low": 513.96,
            "close": 513.99,
            "volume": 121563,
            "datetime": 1712763060000
        },
        {
            "open": 513.99,
            "high": 514.09,
            "low": 513.78,
            "close": 513.8407,
            "volume": 87906,
            "datetime": 1712763120000
        },
        {
            "open": 513.85,
            "high": 513.88,
            "low": 513.66,
            "close": 513.84,
            "volume": 93674,
            "datetime": 1712763180000
        },
        {
            "open": 513.84,
            "high": 514.065,
            "low": 513.79,
            "close": 514.005,
            "volume": 59309,
            "datetime": 1712763240000
        },
        {
            "open": 514.01,
            "high": 514.0887,
            "low": 513.86,
            "close": 514.02,
            "volume": 53632,
            "datetime": 1712763300000
        },
        {
            "open": 514.03,
            "high": 514.07,
            "low": 513.75,
            "close": 513.76,
            "volume": 54517,
            "datetime": 1712763360000
        },
        {
            "open": 513.74,
            "high": 513.81,
            "low": 513.58,
            "close": 513.65,
            "volume": 75947,
            "datetime": 1712763420000
        },
        {
            "open": 513.63,
            "high": 513.71,
            "low": 513.47,
            "close": 513.61,
            "volume": 102812,
            "datetime": 1712763480000
        },
        {
            "open": 513.61,
            "high": 513.645,
            "low": 513.405,
            "close": 513.5,
            "volume": 106866,
            "datetime": 1712763540000
        },
        {
            "open": 513.51,
            "high": 513.55,
            "low": 513.35,
            "close": 513.4497,
            "volume": 124667,
            "datetime": 1712763600000
        },
        {
            "open": 513.44,
            "high": 513.46,
            "low": 513.32,
            "close": 513.43,
            "volume": 77957,
            "datetime": 1712763660000
        },
        {
            "open": 513.44,
            "high": 513.5,
            "low": 513.24,
            "close": 513.385,
            "volume": 145884,
            "datetime": 1712763720000
        },
        {
            "open": 513.38,
            "high": 513.43,
            "low": 513.18,
            "close": 513.209,
            "volume": 54481,
            "datetime": 1712763780000
        },
        {
            "open": 513.2,
            "high": 513.22,
            "low": 513,
            "close": 513.13,
            "volume": 136333,
            "datetime": 1712763840000
        },
        {
            "open": 513.15,
            "high": 513.24,
            "low": 513.02,
            "close": 513.235,
            "volume": 106814,
            "datetime": 1712763900000
        },
        {
            "open": 513.24,
            "high": 513.35,
            "low": 513.095,
            "close": 513.26,
            "volume": 96161,
            "datetime": 1712763960000
        },
        {
            "open": 513.225,
            "high": 513.4,
            "low": 513.13,
            "close": 513.27,
            "volume": 104874,
            "datetime": 1712764020000
        },
        {
            "open": 513.26,
            "high": 513.52,
            "low": 513.26,
            "close": 513.4,
            "volume": 56133,
            "datetime": 1712764080000
        },
        {
            "open": 513.35,
            "high": 513.35,
            "low": 513.22,
            "close": 513.26,
            "volume": 69589,
            "datetime": 1712764140000
        },
        {
            "open": 513.26,
            "high": 513.31,
            "low": 513.18,
            "close": 513.225,
            "volume": 74990,
            "datetime": 1712764200000
        },
        {
            "open": 513.23,
            "high": 513.25,
            "low": 513.15,
            "close": 513.19,
            "volume": 85987,
            "datetime": 1712764260000
        },
        {
            "open": 513.19,
            "high": 513.45,
            "low": 513.19,
            "close": 513.405,
            "volume": 73914,
            "datetime": 1712764320000
        },
        {
            "open": 513.4003,
            "high": 513.42,
            "low": 513.29,
            "close": 513.32,
            "volume": 36634,
            "datetime": 1712764380000
        },
        {
            "open": 513.33,
            "high": 513.39,
            "low": 513.22,
            "close": 513.37,
            "volume": 50985,
            "datetime": 1712764440000
        },
        {
            "open": 513.36,
            "high": 513.37,
            "low": 513.11,
            "close": 513.18,
            "volume": 46422,
            "datetime": 1712764500000
        },
        {
            "open": 513.19,
            "high": 513.28,
            "low": 513.13,
            "close": 513.25,
            "volume": 53463,
            "datetime": 1712764560000
        },
        {
            "open": 513.25,
            "high": 513.36,
            "low": 513.17,
            "close": 513.355,
            "volume": 31249,
            "datetime": 1712764620000
        },
        {
            "open": 513.3596,
            "high": 513.42,
            "low": 513.21,
            "close": 513.29,
            "volume": 54848,
            "datetime": 1712764680000
        },
        {
            "open": 513.28,
            "high": 513.38,
            "low": 513.2501,
            "close": 513.3499,
            "volume": 33110,
            "datetime": 1712764740000
        },
        {
            "open": 513.34,
            "high": 513.43,
            "low": 513.23,
            "close": 513.2518,
            "volume": 45734,
            "datetime": 1712764800000
        },
        {
            "open": 513.26,
            "high": 513.34,
            "low": 513.169,
            "close": 513.18,
            "volume": 58697,
            "datetime": 1712764860000
        },
        {
            "open": 513.17,
            "high": 513.25,
            "low": 513.095,
            "close": 513.23,
            "volume": 53794,
            "datetime": 1712764920000
        },
        {
            "open": 513.25,
            "high": 513.57,
            "low": 513.24,
            "close": 513.5512,
            "volume": 145794,
            "datetime": 1712764980000
        },
        {
            "open": 513.555,
            "high": 513.715,
            "low": 513.545,
            "close": 513.64,
            "volume": 124542,
            "datetime": 1712765040000
        },
        {
            "open": 513.65,
            "high": 513.81,
            "low": 513.63,
            "close": 513.685,
            "volume": 122096,
            "datetime": 1712765100000
        },
        {
            "open": 513.68,
            "high": 513.68,
            "low": 513.26,
            "close": 513.26,
            "volume": 125349,
            "datetime": 1712765160000
        },
        {
            "open": 513.26,
            "high": 513.5401,
            "low": 513.26,
            "close": 513.5,
            "volume": 63727,
            "datetime": 1712765220000
        },
        {
            "open": 513.48,
            "high": 513.53,
            "low": 513.36,
            "close": 513.36,
            "volume": 58702,
            "datetime": 1712765280000
        },
        {
            "open": 513.355,
            "high": 513.505,
            "low": 513.355,
            "close": 513.43,
            "volume": 34112,
            "datetime": 1712765340000
        },
        {
            "open": 513.42,
            "high": 513.49,
            "low": 513.31,
            "close": 513.3299,
            "volume": 57102,
            "datetime": 1712765400000
        },
        {
            "open": 513.33,
            "high": 513.44,
            "low": 513.27,
            "close": 513.44,
            "volume": 41013,
            "datetime": 1712765460000
        },
        {
            "open": 513.44,
            "high": 513.48,
            "low": 513.15,
            "close": 513.18,
            "volume": 63023,
            "datetime": 1712765520000
        },
        {
            "open": 513.15,
            "high": 513.15,
            "low": 513.01,
            "close": 513.03,
            "volume": 97804,
            "datetime": 1712765580000
        },
        {
            "open": 513.03,
            "high": 513.1,
            "low": 512.98,
            "close": 513.01,
            "volume": 162410,
            "datetime": 1712765640000
        },
        {
            "open": 513.02,
            "high": 513.155,
            "low": 513.02,
            "close": 513.1,
            "volume": 50310,
            "datetime": 1712765700000
        },
        {
            "open": 513.1,
            "high": 513.245,
            "low": 513.06,
            "close": 513.235,
            "volume": 47561,
            "datetime": 1712765760000
        },
        {
            "open": 513.24,
            "high": 513.25,
            "low": 513.075,
            "close": 513.14,
            "volume": 45377,
            "datetime": 1712765820000
        },
        {
            "open": 513.11,
            "high": 513.315,
            "low": 513.11,
            "close": 513.23,
            "volume": 48106,
            "datetime": 1712765880000
        },
        {
            "open": 513.215,
            "high": 513.3,
            "low": 513.09,
            "close": 513.105,
            "volume": 71497,
            "datetime": 1712765940000
        },
        {
            "open": 513.1,
            "high": 513.26,
            "low": 513.05,
            "close": 513.104,
            "volume": 38802,
            "datetime": 1712766000000
        },
        {
            "open": 513.11,
            "high": 513.16,
            "low": 513.01,
            "close": 513.095,
            "volume": 38799,
            "datetime": 1712766060000
        },
        {
            "open": 513.08,
            "high": 513.14,
            "low": 513.01,
            "close": 513.06,
            "volume": 56226,
            "datetime": 1712766120000
        },
        {
            "open": 513.05,
            "high": 513.07,
            "low": 512.99,
            "close": 513.05,
            "volume": 28796,
            "datetime": 1712766180000
        },
        {
            "open": 513.06,
            "high": 513.2801,
            "low": 513.06,
            "close": 513.27,
            "volume": 70578,
            "datetime": 1712766240000
        },
        {
            "open": 513.26,
            "high": 513.52,
            "low": 513.25,
            "close": 513.47,
            "volume": 82163,
            "datetime": 1712766300000
        },
        {
            "open": 513.48,
            "high": 513.52,
            "low": 513.37,
            "close": 513.455,
            "volume": 57428,
            "datetime": 1712766360000
        },
        {
            "open": 513.45,
            "high": 513.575,
            "low": 513.3899,
            "close": 513.55,
            "volume": 67248,
            "datetime": 1712766420000
        },
        {
            "open": 513.54,
            "high": 513.5601,
            "low": 513.36,
            "close": 513.37,
            "volume": 59025,
            "datetime": 1712766480000
        },
        {
            "open": 513.37,
            "high": 513.41,
            "low": 513.31,
            "close": 513.395,
            "volume": 37793,
            "datetime": 1712766540000
        },
        {
            "open": 513.4,
            "high": 513.45,
            "low": 513.24,
            "close": 513.28,
            "volume": 50157,
            "datetime": 1712766600000
        },
        {
            "open": 513.28,
            "high": 513.41,
            "low": 513.27,
            "close": 513.27,
            "volume": 37332,
            "datetime": 1712766660000
        },
        {
            "open": 513.27,
            "high": 513.3801,
            "low": 513.25,
            "close": 513.28,
            "volume": 24120,
            "datetime": 1712766720000
        },
        {
            "open": 513.2851,
            "high": 513.37,
            "low": 513.25,
            "close": 513.36,
            "volume": 55827,
            "datetime": 1712766780000
        },
        {
            "open": 513.37,
            "high": 513.4303,
            "low": 513.36,
            "close": 513.41,
            "volume": 47873,
            "datetime": 1712766840000
        },
        {
            "open": 513.415,
            "high": 513.66,
            "low": 513.415,
            "close": 513.6312,
            "volume": 83835,
            "datetime": 1712766900000
        },
        {
            "open": 513.6313,
            "high": 513.71,
            "low": 513.57,
            "close": 513.64,
            "volume": 123457,
            "datetime": 1712766960000
        },
        {
            "open": 513.6592,
            "high": 513.83,
            "low": 513.65,
            "close": 513.72,
            "volume": 81559,
            "datetime": 1712767020000
        },
        {
            "open": 513.72,
            "high": 513.8,
            "low": 513.57,
            "close": 513.65,
            "volume": 105625,
            "datetime": 1712767080000
        },
        {
            "open": 513.66,
            "high": 513.72,
            "low": 513.56,
            "close": 513.57,
            "volume": 94552,
            "datetime": 1712767140000
        },
        {
            "open": 513.57,
            "high": 513.65,
            "low": 513.4288,
            "close": 513.465,
            "volume": 78946,
            "datetime": 1712767200000
        },
        {
            "open": 513.4699,
            "high": 513.58,
            "low": 513.39,
            "close": 513.57,
            "volume": 48029,
            "datetime": 1712767260000
        },
        {
            "open": 513.56,
            "high": 513.71,
            "low": 513.52,
            "close": 513.67,
            "volume": 57447,
            "datetime": 1712767320000
        },
        {
            "open": 513.67,
            "high": 513.69,
            "low": 513.54,
            "close": 513.56,
            "volume": 41480,
            "datetime": 1712767380000
        },
        {
            "open": 513.57,
            "high": 513.6,
            "low": 513.36,
            "close": 513.36,
            "volume": 45455,
            "datetime": 1712767440000
        },
        {
            "open": 513.36,
            "high": 513.53,
            "low": 513.34,
            "close": 513.475,
            "volume": 51665,
            "datetime": 1712767500000
        },
        {
            "open": 513.48,
            "high": 513.54,
            "low": 513.44,
            "close": 513.5,
            "volume": 30441,
            "datetime": 1712767560000
        },
        {
            "open": 513.4872,
            "high": 513.53,
            "low": 513.41,
            "close": 513.46,
            "volume": 22665,
            "datetime": 1712767620000
        },
        {
            "open": 513.47,
            "high": 513.55,
            "low": 513.32,
            "close": 513.49,
            "volume": 58639,
            "datetime": 1712767680000
        },
        {
            "open": 513.52,
            "high": 513.71,
            "low": 513.51,
            "close": 513.66,
            "volume": 70786,
            "datetime": 1712767740000
        },
        {
            "open": 513.65,
            "high": 513.705,
            "low": 513.51,
            "close": 513.56,
            "volume": 52402,
            "datetime": 1712767800000
        },
        {
            "open": 513.59,
            "high": 513.6797,
            "low": 513.56,
            "close": 513.6595,
            "volume": 51800,
            "datetime": 1712767860000
        },
        {
            "open": 513.655,
            "high": 513.77,
            "low": 513.47,
            "close": 513.55,
            "volume": 106992,
            "datetime": 1712767920000
        },
        {
            "open": 513.53,
            "high": 513.96,
            "low": 513.49,
            "close": 513.88,
            "volume": 65616,
            "datetime": 1712767980000
        },
        {
            "open": 513.88,
            "high": 513.98,
            "low": 513.825,
            "close": 513.95,
            "volume": 54158,
            "datetime": 1712768040000
        },
        {
            "open": 513.94,
            "high": 514.15,
            "low": 513.895,
            "close": 514.1,
            "volume": 216217,
            "datetime": 1712768100000
        },
        {
            "open": 514.11,
            "high": 514.15,
            "low": 514.05,
            "close": 514.1288,
            "volume": 143416,
            "datetime": 1712768160000
        },
        {
            "open": 514.12,
            "high": 514.15,
            "low": 514.035,
            "close": 514.14,
            "volume": 76880,
            "datetime": 1712768220000
        },
        {
            "open": 514.14,
            "high": 514.28,
            "low": 514.08,
            "close": 514.24,
            "volume": 154257,
            "datetime": 1712768280000
        },
        {
            "open": 514.23,
            "high": 514.38,
            "low": 514.15,
            "close": 514.16,
            "volume": 89181,
            "datetime": 1712768340000
        },
        {
            "open": 514.17,
            "high": 514.36,
            "low": 514.165,
            "close": 514.29,
            "volume": 68506,
            "datetime": 1712768400000
        },
        {
            "open": 514.29,
            "high": 514.46,
            "low": 513.48,
            "close": 513.605,
            "volume": 274364,
            "datetime": 1712768460000
        },
        {
            "open": 513.61,
            "high": 513.68,
            "low": 513.33,
            "close": 513.59,
            "volume": 214476,
            "datetime": 1712768520000
        },
        {
            "open": 513.58,
            "high": 513.62,
            "low": 513,
            "close": 513.14,
            "volume": 256945,
            "datetime": 1712768580000
        },
        {
            "open": 513.13,
            "high": 513.13,
            "low": 512.58,
            "close": 512.83,
            "volume": 279222,
            "datetime": 1712768640000
        },
        {
            "open": 512.84,
            "high": 512.88,
            "low": 512.43,
            "close": 512.48,
            "volume": 226838,
            "datetime": 1712768700000
        },
        {
            "open": 512.49,
            "high": 512.585,
            "low": 512.27,
            "close": 512.37,
            "volume": 251849,
            "datetime": 1712768760000
        },
        {
            "open": 512.36,
            "high": 512.48,
            "low": 512.3,
            "close": 512.3361,
            "volume": 124025,
            "datetime": 1712768820000
        },
        {
            "open": 512.34,
            "high": 512.4172,
            "low": 512.14,
            "close": 512.22,
            "volume": 124434,
            "datetime": 1712768880000
        },
        {
            "open": 512.2085,
            "high": 512.43,
            "low": 512.13,
            "close": 512.16,
            "volume": 139662,
            "datetime": 1712768940000
        },
        {
            "open": 512.17,
            "high": 512.379,
            "low": 512.11,
            "close": 512.3,
            "volume": 171623,
            "datetime": 1712769000000
        },
        {
            "open": 512.29,
            "high": 512.325,
            "low": 512.09,
            "close": 512.18,
            "volume": 118597,
            "datetime": 1712769060000
        },
        {
            "open": 512.1502,
            "high": 512.285,
            "low": 512.12,
            "close": 512.21,
            "volume": 88641,
            "datetime": 1712769120000
        },
        {
            "open": 512.21,
            "high": 512.56,
            "low": 512.21,
            "close": 512.48,
            "volume": 104203,
            "datetime": 1712769180000
        },
        {
            "open": 512.4987,
            "high": 512.8345,
            "low": 512.44,
            "close": 512.7494,
            "volume": 203862,
            "datetime": 1712769240000
        },
        {
            "open": 512.74,
            "high": 512.74,
            "low": 512.47,
            "close": 512.49,
            "volume": 122464,
            "datetime": 1712769300000
        },
        {
            "open": 512.5,
            "high": 512.595,
            "low": 512.45,
            "close": 512.56,
            "volume": 83654,
            "datetime": 1712769360000
        },
        {
            "open": 512.5613,
            "high": 513.029,
            "low": 512.52,
            "close": 512.94,
            "volume": 122526,
            "datetime": 1712769420000
        },
        {
            "open": 512.97,
            "high": 512.9819,
            "low": 512.76,
            "close": 512.81,
            "volume": 90911,
            "datetime": 1712769480000
        },
        {
            "open": 512.85,
            "high": 513.6,
            "low": 512.85,
            "close": 513.55,
            "volume": 307423,
            "datetime": 1712769540000
        },
        {
            "open": 513.54,
            "high": 513.84,
            "low": 513.39,
            "close": 513.81,
            "volume": 183939,
            "datetime": 1712769600000
        },
        {
            "open": 513.82,
            "high": 514.29,
            "low": 513.76,
            "close": 514.2,
            "volume": 279131,
            "datetime": 1712769660000
        },
        {
            "open": 514.18,
            "high": 514.92,
            "low": 514.07,
            "close": 514.8499,
            "volume": 335557,
            "datetime": 1712769720000
        },
        {
            "open": 514.84,
            "high": 514.9897,
            "low": 514.65,
            "close": 514.69,
            "volume": 324229,
            "datetime": 1712769780000
        },
        {
            "open": 514.67,
            "high": 515.02,
            "low": 514.63,
            "close": 514.89,
            "volume": 175621,
            "datetime": 1712769840000
        },
        {
            "open": 514.88,
            "high": 514.97,
            "low": 514.6,
            "close": 514.97,
            "volume": 283596,
            "datetime": 1712769900000
        },
        {
            "open": 514.97,
            "high": 515.05,
            "low": 514.72,
            "close": 514.85,
            "volume": 134660,
            "datetime": 1712769960000
        },
        {
            "open": 514.86,
            "high": 515.14,
            "low": 514.59,
            "close": 514.5994,
            "volume": 173857,
            "datetime": 1712770020000
        },
        {
            "open": 514.6,
            "high": 514.78,
            "low": 514.4,
            "close": 514.44,
            "volume": 151378,
            "datetime": 1712770080000
        },
        {
            "open": 514.4569,
            "high": 514.4569,
            "low": 513.82,
            "close": 513.8604,
            "volume": 197620,
            "datetime": 1712770140000
        },
        {
            "open": 513.88,
            "high": 514.25,
            "low": 513.71,
            "close": 514.08,
            "volume": 172887,
            "datetime": 1712770200000
        },
        {
            "open": 514.13,
            "high": 514.16,
            "low": 513.51,
            "close": 513.71,
            "volume": 506415,
            "datetime": 1712770260000
        },
        {
            "open": 513.67,
            "high": 513.72,
            "low": 513.01,
            "close": 513.07,
            "volume": 340997,
            "datetime": 1712770320000
        },
        {
            "open": 513.07,
            "high": 513.2466,
            "low": 512.74,
            "close": 512.93,
            "volume": 309244,
            "datetime": 1712770380000
        },
        {
            "open": 512.94,
            "high": 513.355,
            "low": 512.72,
            "close": 513.3,
            "volume": 282719,
            "datetime": 1712770440000
        },
        {
            "open": 513.3,
            "high": 513.47,
            "low": 512.78,
            "close": 512.92,
            "volume": 177960,
            "datetime": 1712770500000
        },
        {
            "open": 512.9,
            "high": 512.98,
            "low": 512.59,
            "close": 512.64,
            "volume": 140654,
            "datetime": 1712770560000
        },
        {
            "open": 512.65,
            "high": 513.03,
            "low": 512.58,
            "close": 512.58,
            "volume": 142288,
            "datetime": 1712770620000
        },
        {
            "open": 512.59,
            "high": 512.78,
            "low": 512.54,
            "close": 512.65,
            "volume": 98546,
            "datetime": 1712770680000
        },
        {
            "open": 512.66,
            "high": 512.71,
            "low": 512.4,
            "close": 512.59,
            "volume": 135848,
            "datetime": 1712770740000
        },
        {
            "open": 512.57,
            "high": 512.89,
            "low": 512.29,
            "close": 512.43,
            "volume": 173265,
            "datetime": 1712770800000
        },
        {
            "open": 512.45,
            "high": 512.97,
            "low": 512.4,
            "close": 512.75,
            "volume": 126391,
            "datetime": 1712770860000
        },
        {
            "open": 512.75,
            "high": 513.08,
            "low": 512.68,
            "close": 512.905,
            "volume": 169798,
            "datetime": 1712770920000
        },
        {
            "open": 512.91,
            "high": 513.23,
            "low": 512.74,
            "close": 512.75,
            "volume": 108257,
            "datetime": 1712770980000
        },
        {
            "open": 512.77,
            "high": 512.81,
            "low": 512.5,
            "close": 512.58,
            "volume": 106862,
            "datetime": 1712771040000
        },
        {
            "open": 512.59,
            "high": 512.66,
            "low": 512.5,
            "close": 512.62,
            "volume": 90009,
            "datetime": 1712771100000
        },
        {
            "open": 512.62,
            "high": 512.7113,
            "low": 512.4701,
            "close": 512.51,
            "volume": 103266,
            "datetime": 1712771160000
        },
        {
            "open": 512.54,
            "high": 512.7338,
            "low": 512.54,
            "close": 512.64,
            "volume": 83491,
            "datetime": 1712771220000
        },
        {
            "open": 512.64,
            "high": 512.8513,
            "low": 512.6,
            "close": 512.66,
            "volume": 100840,
            "datetime": 1712771280000
        },
        {
            "open": 512.65,
            "high": 513.17,
            "low": 512.54,
            "close": 513.1189,
            "volume": 88818,
            "datetime": 1712771340000
        },
        {
            "open": 513.11,
            "high": 513.305,
            "low": 512.8,
            "close": 512.82,
            "volume": 115986,
            "datetime": 1712771400000
        },
        {
            "open": 512.83,
            "high": 512.98,
            "low": 512.6201,
            "close": 512.82,
            "volume": 88865,
            "datetime": 1712771460000
        },
        {
            "open": 512.7837,
            "high": 513.12,
            "low": 512.7837,
            "close": 513.02,
            "volume": 64199,
            "datetime": 1712771520000
        },
        {
            "open": 513.03,
            "high": 513.2,
            "low": 512.88,
            "close": 513.075,
            "volume": 46330,
            "datetime": 1712771580000
        },
        {
            "open": 513.11,
            "high": 513.29,
            "low": 512.97,
            "close": 513.23,
            "volume": 49333,
            "datetime": 1712771640000
        },
        {
            "open": 513.21,
            "high": 513.33,
            "low": 513.1,
            "close": 513.26,
            "volume": 203148,
            "datetime": 1712771700000
        },
        {
            "open": 513.27,
            "high": 514.399,
            "low": 513.26,
            "close": 514.385,
            "volume": 433144,
            "datetime": 1712771760000
        },
        {
            "open": 514.36,
            "high": 514.9,
            "low": 514.18,
            "close": 514.55,
            "volume": 418500,
            "datetime": 1712771820000
        },
        {
            "open": 514.55,
            "high": 514.57,
            "low": 513.69,
            "close": 513.79,
            "volume": 310954,
            "datetime": 1712771880000
        },
        {
            "open": 513.7804,
            "high": 513.92,
            "low": 513.68,
            "close": 513.76,
            "volume": 146884,
            "datetime": 1712771940000
        },
        {
            "open": 513.7804,
            "high": 514.14,
            "low": 513.21,
            "close": 513.93,
            "volume": 397945,
            "datetime": 1712772000000
        },
        {
            "open": 513.9,
            "high": 514.31,
            "low": 513.56,
            "close": 514.2099,
            "volume": 160748,
            "datetime": 1712772060000
        },
        {
            "open": 514.18,
            "high": 514.315,
            "low": 513.96,
            "close": 514.15,
            "volume": 121109,
            "datetime": 1712772120000
        },
        {
            "open": 514.13,
            "high": 514.305,
            "low": 513.9301,
            "close": 514,
            "volume": 132768,
            "datetime": 1712772180000
        },
        {
            "open": 514.03,
            "high": 514.5499,
            "low": 513.98,
            "close": 514.365,
            "volume": 214143,
            "datetime": 1712772240000
        },
        {
            "open": 514.35,
            "high": 514.36,
            "low": 513.92,
            "close": 514.3,
            "volume": 163791,
            "datetime": 1712772300000
        },
        {
            "open": 514.3,
            "high": 514.32,
            "low": 513.92,
            "close": 513.94,
            "volume": 106625,
            "datetime": 1712772360000
        },
        {
            "open": 513.96,
            "high": 514.02,
            "low": 513.62,
            "close": 513.62,
            "volume": 127284,
            "datetime": 1712772420000
        },
        {
            "open": 513.61,
            "high": 514.32,
            "low": 513.57,
            "close": 514.21,
            "volume": 149138,
            "datetime": 1712772480000
        },
        {
            "open": 514.2,
            "high": 514.2601,
            "low": 514.03,
            "close": 514.07,
            "volume": 61910,
            "datetime": 1712772540000
        },
        {
            "open": 514.05,
            "high": 514.22,
            "low": 513.84,
            "close": 514.01,
            "volume": 93411,
            "datetime": 1712772600000
        },
        {
            "open": 514.01,
            "high": 514.05,
            "low": 513.73,
            "close": 513.87,
            "volume": 85044,
            "datetime": 1712772660000
        },
        {
            "open": 513.88,
            "high": 514.34,
            "low": 513.86,
            "close": 514.31,
            "volume": 96062,
            "datetime": 1712772720000
        },
        {
            "open": 514.3,
            "high": 514.46,
            "low": 514.15,
            "close": 514.24,
            "volume": 92860,
            "datetime": 1712772780000
        },
        {
            "open": 514.23,
            "high": 514.39,
            "low": 514.06,
            "close": 514.37,
            "volume": 93983,
            "datetime": 1712772840000
        },
        {
            "open": 514.36,
            "high": 514.68,
            "low": 514.2901,
            "close": 514.425,
            "volume": 171289,
            "datetime": 1712772900000
        },
        {
            "open": 514.43,
            "high": 514.4801,
            "low": 514.08,
            "close": 514.16,
            "volume": 116380,
            "datetime": 1712772960000
        },
        {
            "open": 514.18,
            "high": 514.385,
            "low": 513.84,
            "close": 513.8908,
            "volume": 67705,
            "datetime": 1712773020000
        },
        {
            "open": 513.9001,
            "high": 514.05,
            "low": 513.58,
            "close": 513.655,
            "volume": 116851,
            "datetime": 1712773080000
        },
        {
            "open": 513.62,
            "high": 513.76,
            "low": 513.45,
            "close": 513.73,
            "volume": 187471,
            "datetime": 1712773140000
        },
        {
            "open": 513.77,
            "high": 514.26,
            "low": 513.76,
            "close": 514.06,
            "volume": 158045,
            "datetime": 1712773200000
        },
        {
            "open": 514.055,
            "high": 514.21,
            "low": 513.83,
            "close": 514.02,
            "volume": 118707,
            "datetime": 1712773260000
        },
        {
            "open": 514.04,
            "high": 514.06,
            "low": 513.51,
            "close": 513.53,
            "volume": 170626,
            "datetime": 1712773320000
        },
        {
            "open": 513.52,
            "high": 513.6,
            "low": 513.16,
            "close": 513.36,
            "volume": 203425,
            "datetime": 1712773380000
        },
        {
            "open": 513.35,
            "high": 513.4,
            "low": 513.08,
            "close": 513.22,
            "volume": 218620,
            "datetime": 1712773440000
        },
        {
            "open": 513.21,
            "high": 513.53,
            "low": 513.21,
            "close": 513.26,
            "volume": 73982,
            "datetime": 1712773500000
        },
        {
            "open": 513.31,
            "high": 513.41,
            "low": 513.23,
            "close": 513.28,
            "volume": 78612,
            "datetime": 1712773560000
        },
        {
            "open": 513.28,
            "high": 513.78,
            "low": 513.28,
            "close": 513.4999,
            "volume": 117190,
            "datetime": 1712773620000
        },
        {
            "open": 513.5,
            "high": 513.5,
            "low": 513.16,
            "close": 513.36,
            "volume": 67420,
            "datetime": 1712773680000
        },
        {
            "open": 513.36,
            "high": 513.48,
            "low": 513.11,
            "close": 513.2,
            "volume": 74172,
            "datetime": 1712773740000
        },
        {
            "open": 513.19,
            "high": 513.4,
            "low": 513.12,
            "close": 513.31,
            "volume": 72030,
            "datetime": 1712773800000
        },
        {
            "open": 513.29,
            "high": 513.51,
            "low": 513.185,
            "close": 513.49,
            "volume": 57987,
            "datetime": 1712773860000
        },
        {
            "open": 513.5,
            "high": 513.54,
            "low": 513.23,
            "close": 513.23,
            "volume": 47376,
            "datetime": 1712773920000
        },
        {
            "open": 513.22,
            "high": 513.25,
            "low": 513,
            "close": 513.125,
            "volume": 81039,
            "datetime": 1712773980000
        },
        {
            "open": 513.12,
            "high": 513.41,
            "low": 513.115,
            "close": 513.32,
            "volume": 55075,
            "datetime": 1712774040000
        },
        {
            "open": 513.31,
            "high": 513.39,
            "low": 513.16,
            "close": 513.3253,
            "volume": 51558,
            "datetime": 1712774100000
        },
        {
            "open": 513.32,
            "high": 513.545,
            "low": 513.1999,
            "close": 513.39,
            "volume": 58096,
            "datetime": 1712774160000
        },
        {
            "open": 513.36,
            "high": 513.51,
            "low": 513.27,
            "close": 513.39,
            "volume": 46258,
            "datetime": 1712774220000
        },
        {
            "open": 513.42,
            "high": 513.78,
            "low": 513.36,
            "close": 513.73,
            "volume": 102864,
            "datetime": 1712774280000
        },
        {
            "open": 513.73,
            "high": 513.94,
            "low": 513.56,
            "close": 513.88,
            "volume": 113676,
            "datetime": 1712774340000
        },
        {
            "open": 513.855,
            "high": 513.9796,
            "low": 513.76,
            "close": 513.88,
            "volume": 100115,
            "datetime": 1712774400000
        },
        {
            "open": 513.88,
            "high": 513.94,
            "low": 513.63,
            "close": 513.765,
            "volume": 99300,
            "datetime": 1712774460000
        },
        {
            "open": 513.74,
            "high": 514.11,
            "low": 513.7,
            "close": 513.95,
            "volume": 76832,
            "datetime": 1712774520000
        },
        {
            "open": 513.95,
            "high": 514.02,
            "low": 513.82,
            "close": 513.905,
            "volume": 66925,
            "datetime": 1712774580000
        },
        {
            "open": 513.91,
            "high": 514.105,
            "low": 513.85,
            "close": 513.9,
            "volume": 80802,
            "datetime": 1712774640000
        },
        {
            "open": 513.91,
            "high": 514.08,
            "low": 513.85,
            "close": 513.89,
            "volume": 64850,
            "datetime": 1712774700000
        },
        {
            "open": 513.89,
            "high": 514.1123,
            "low": 513.77,
            "close": 514.07,
            "volume": 74613,
            "datetime": 1712774760000
        },
        {
            "open": 514.07,
            "high": 514.11,
            "low": 513.87,
            "close": 513.93,
            "volume": 78662,
            "datetime": 1712774820000
        },
        {
            "open": 513.94,
            "high": 513.97,
            "low": 513.67,
            "close": 513.9385,
            "volume": 54716,
            "datetime": 1712774880000
        },
        {
            "open": 513.95,
            "high": 513.97,
            "low": 513.75,
            "close": 513.95,
            "volume": 48653,
            "datetime": 1712774940000
        },
        {
            "open": 513.92,
            "high": 514.17,
            "low": 513.78,
            "close": 514.12,
            "volume": 57013,
            "datetime": 1712775000000
        },
        {
            "open": 514.11,
            "high": 514.18,
            "low": 513.9,
            "close": 513.98,
            "volume": 54856,
            "datetime": 1712775060000
        },
        {
            "open": 513.99,
            "high": 514.08,
            "low": 513.38,
            "close": 513.45,
            "volume": 181327,
            "datetime": 1712775120000
        },
        {
            "open": 513.49,
            "high": 513.57,
            "low": 513.34,
            "close": 513.446,
            "volume": 92964,
            "datetime": 1712775180000
        },
        {
            "open": 513.46,
            "high": 513.72,
            "low": 513.4483,
            "close": 513.55,
            "volume": 93911,
            "datetime": 1712775240000
        },
        {
            "open": 513.53,
            "high": 513.55,
            "low": 513.0201,
            "close": 513.06,
            "volume": 123777,
            "datetime": 1712775300000
        },
        {
            "open": 513.05,
            "high": 513.09,
            "low": 512.78,
            "close": 512.78,
            "volume": 142216,
            "datetime": 1712775360000
        },
        {
            "open": 512.77,
            "high": 512.89,
            "low": 512.7,
            "close": 512.72,
            "volume": 111564,
            "datetime": 1712775420000
        },
        {
            "open": 512.73,
            "high": 512.835,
            "low": 512.5651,
            "close": 512.75,
            "volume": 100058,
            "datetime": 1712775480000
        },
        {
            "open": 512.77,
            "high": 512.8112,
            "low": 512.61,
            "close": 512.635,
            "volume": 149388,
            "datetime": 1712775540000
        },
        {
            "open": 512.63,
            "high": 513.01,
            "low": 512.57,
            "close": 513.01,
            "volume": 119692,
            "datetime": 1712775600000
        },
        {
            "open": 513.01,
            "high": 513.22,
            "low": 512.67,
            "close": 512.72,
            "volume": 128412,
            "datetime": 1712775660000
        },
        {
            "open": 512.71,
            "high": 512.75,
            "low": 512.49,
            "close": 512.62,
            "volume": 108916,
            "datetime": 1712775720000
        },
        {
            "open": 512.615,
            "high": 512.83,
            "low": 512.615,
            "close": 512.6895,
            "volume": 98816,
            "datetime": 1712775780000
        },
        {
            "open": 512.669,
            "high": 512.75,
            "low": 512.53,
            "close": 512.56,
            "volume": 68425,
            "datetime": 1712775840000
        },
        {
            "open": 512.57,
            "high": 512.7,
            "low": 512.445,
            "close": 512.49,
            "volume": 81836,
            "datetime": 1712775900000
        },
        {
            "open": 512.5,
            "high": 512.71,
            "low": 512.465,
            "close": 512.64,
            "volume": 95543,
            "datetime": 1712775960000
        },
        {
            "open": 512.65,
            "high": 513.17,
            "low": 512.575,
            "close": 513.17,
            "volume": 130309,
            "datetime": 1712776020000
        },
        {
            "open": 513.17,
            "high": 513.27,
            "low": 512.89,
            "close": 512.93,
            "volume": 109087,
            "datetime": 1712776080000
        },
        {
            "open": 512.92,
            "high": 513.23,
            "low": 512.8888,
            "close": 513.23,
            "volume": 54869,
            "datetime": 1712776140000
        },
        {
            "open": 513.2386,
            "high": 513.52,
            "low": 513.17,
            "close": 513.27,
            "volume": 162770,
            "datetime": 1712776200000
        },
        {
            "open": 513.25,
            "high": 513.45,
            "low": 513.22,
            "close": 513.31,
            "volume": 117837,
            "datetime": 1712776260000
        },
        {
            "open": 513.29,
            "high": 513.34,
            "low": 513.18,
            "close": 513.29,
            "volume": 69278,
            "datetime": 1712776320000
        },
        {
            "open": 513.29,
            "high": 513.79,
            "low": 513.25,
            "close": 513.76,
            "volume": 151135,
            "datetime": 1712776380000
        },
        {
            "open": 513.76,
            "high": 514.09,
            "low": 513.64,
            "close": 514.07,
            "volume": 225838,
            "datetime": 1712776440000
        },
        {
            "open": 514.06,
            "high": 514.48,
            "low": 513.97,
            "close": 514.32,
            "volume": 261718,
            "datetime": 1712776500000
        },
        {
            "open": 514.31,
            "high": 514.36,
            "low": 514.102,
            "close": 514.27,
            "volume": 226791,
            "datetime": 1712776560000
        },
        {
            "open": 514.27,
            "high": 514.43,
            "low": 513.83,
            "close": 513.9925,
            "volume": 158053,
            "datetime": 1712776620000
        },
        {
            "open": 514.01,
            "high": 514.16,
            "low": 513.96,
            "close": 514.13,
            "volume": 72615,
            "datetime": 1712776680000
        },
        {
            "open": 514.14,
            "high": 514.4,
            "low": 514.14,
            "close": 514.33,
            "volume": 118151,
            "datetime": 1712776740000
        },
        {
            "open": 514.35,
            "high": 514.84,
            "low": 514.3201,
            "close": 514.55,
            "volume": 174775,
            "datetime": 1712776800000
        },
        {
            "open": 514.58,
            "high": 514.73,
            "low": 514.23,
            "close": 514.33,
            "volume": 183975,
            "datetime": 1712776860000
        },
        {
            "open": 514.3304,
            "high": 514.5,
            "low": 514.18,
            "close": 514.38,
            "volume": 179103,
            "datetime": 1712776920000
        },
        {
            "open": 514.4199,
            "high": 514.48,
            "low": 514.305,
            "close": 514.3801,
            "volume": 70353,
            "datetime": 1712776980000
        },
        {
            "open": 514.37,
            "high": 514.7725,
            "low": 514.37,
            "close": 514.76,
            "volume": 127371,
            "datetime": 1712777040000
        },
        {
            "open": 514.76,
            "high": 515.07,
            "low": 514.64,
            "close": 514.968,
            "volume": 212691,
            "datetime": 1712777100000
        },
        {
            "open": 514.94,
            "high": 515.07,
            "low": 514.72,
            "close": 514.73,
            "volume": 209770,
            "datetime": 1712777160000
        },
        {
            "open": 514.74,
            "high": 514.745,
            "low": 514.45,
            "close": 514.51,
            "volume": 145780,
            "datetime": 1712777220000
        },
        {
            "open": 514.51,
            "high": 514.69,
            "low": 514.47,
            "close": 514.59,
            "volume": 79844,
            "datetime": 1712777280000
        },
        {
            "open": 514.61,
            "high": 514.62,
            "low": 514.21,
            "close": 514.26,
            "volume": 87380,
            "datetime": 1712777340000
        },
        {
            "open": 514.26,
            "high": 514.37,
            "low": 513.71,
            "close": 513.8,
            "volume": 165518,
            "datetime": 1712777400000
        },
        {
            "open": 513.81,
            "high": 513.94,
            "low": 513.695,
            "close": 513.84,
            "volume": 205535,
            "datetime": 1712777460000
        },
        {
            "open": 513.84,
            "high": 513.9498,
            "low": 513.57,
            "close": 513.65,
            "volume": 243323,
            "datetime": 1712777520000
        },
        {
            "open": 513.66,
            "high": 513.77,
            "low": 513.41,
            "close": 513.47,
            "volume": 139544,
            "datetime": 1712777580000
        },
        {
            "open": 513.49,
            "high": 513.71,
            "low": 513.48,
            "close": 513.705,
            "volume": 144508,
            "datetime": 1712777640000
        },
        {
            "open": 513.69,
            "high": 513.9599,
            "low": 513.54,
            "close": 513.74,
            "volume": 310581,
            "datetime": 1712777700000
        },
        {
            "open": 513.75,
            "high": 513.83,
            "low": 513.51,
            "close": 513.51,
            "volume": 120718,
            "datetime": 1712777760000
        },
        {
            "open": 513.515,
            "high": 513.89,
            "low": 513.49,
            "close": 513.74,
            "volume": 199759,
            "datetime": 1712777820000
        },
        {
            "open": 513.75,
            "high": 513.86,
            "low": 513.61,
            "close": 513.69,
            "volume": 166276,
            "datetime": 1712777880000
        },
        {
            "open": 513.68,
            "high": 513.68,
            "low": 513.455,
            "close": 513.48,
            "volume": 121303,
            "datetime": 1712777940000
        },
        {
            "open": 513.5,
            "high": 513.89,
            "low": 513.5,
            "close": 513.83,
            "volume": 155758,
            "datetime": 1712778000000
        },
        {
            "open": 513.84,
            "high": 514.25,
            "low": 513.84,
            "close": 514.16,
            "volume": 251626,
            "datetime": 1712778060000
        },
        {
            "open": 514.15,
            "high": 514.31,
            "low": 514.08,
            "close": 514.26,
            "volume": 176003,
            "datetime": 1712778120000
        },
        {
            "open": 514.24,
            "high": 514.48,
            "low": 514.16,
            "close": 514.39,
            "volume": 156000,
            "datetime": 1712778180000
        },
        {
            "open": 514.39,
            "high": 514.61,
            "low": 514.345,
            "close": 514.55,
            "volume": 140845,
            "datetime": 1712778240000
        },
        {
            "open": 514.54,
            "high": 514.5903,
            "low": 514.34,
            "close": 514.46,
            "volume": 222757,
            "datetime": 1712778300000
        },
        {
            "open": 514.47,
            "high": 514.83,
            "low": 514.38,
            "close": 514.8,
            "volume": 242954,
            "datetime": 1712778360000
        },
        {
            "open": 514.79,
            "high": 515,
            "low": 514.71,
            "close": 514.7305,
            "volume": 318213,
            "datetime": 1712778420000
        },
        {
            "open": 514.73,
            "high": 514.74,
            "low": 514.52,
            "close": 514.635,
            "volume": 264679,
            "datetime": 1712778480000
        },
        {
            "open": 514.64,
            "high": 514.895,
            "low": 514.62,
            "close": 514.65,
            "volume": 359427,
            "datetime": 1712778540000
        },
        {
            "open": 514.67,
            "high": 514.79,
            "low": 514.245,
            "close": 514.395,
            "volume": 401857,
            "datetime": 1712778600000
        },
        {
            "open": 514.43,
            "high": 514.69,
            "low": 514.315,
            "close": 514.455,
            "volume": 275014,
            "datetime": 1712778660000
        },
        {
            "open": 514.45,
            "high": 514.47,
            "low": 514.33,
            "close": 514.3501,
            "volume": 295122,
            "datetime": 1712778720000
        },
        {
            "open": 514.36,
            "high": 514.66,
            "low": 514.26,
            "close": 514.49,
            "volume": 297632,
            "datetime": 1712778780000
        },
        {
            "open": 514.5,
            "high": 514.72,
            "low": 514.44,
            "close": 514.68,
            "volume": 404086,
            "datetime": 1712778840000
        },
        {
            "open": 514.67,
            "high": 514.98,
            "low": 514.49,
            "close": 514.52,
            "volume": 423808,
            "datetime": 1712778900000
        },
        {
            "open": 514.51,
            "high": 514.71,
            "low": 514.5,
            "close": 514.65,
            "volume": 270456,
            "datetime": 1712778960000
        },
        {
            "open": 514.65,
            "high": 515.01,
            "low": 514.59,
            "close": 514.9699,
            "volume": 381852,
            "datetime": 1712779020000
        },
        {
            "open": 514.965,
            "high": 514.99,
            "low": 514.77,
            "close": 514.95,
            "volume": 664224,
            "datetime": 1712779080000
        },
        {
            "open": 514.95,
            "high": 514.96,
            "low": 514.08,
            "close": 514.16,
            "volume": 1863446,
            "datetime": 1712779140000
        },
        {
            "open": 515.68,
            "high": 515.87,
            "low": 515.2,
            "close": 515.5,
            "volume": 606643,
            "datetime": 1712842200000
        },
        {
            "open": 515.51,
            "high": 515.61,
            "low": 515,
            "close": 515.07,
            "volume": 208232,
            "datetime": 1712842260000
        },
        {
            "open": 515.07,
            "high": 515.1701,
            "low": 514.88,
            "close": 515.03,
            "volume": 265865,
            "datetime": 1712842320000
        },
        {
            "open": 515.04,
            "high": 515.1,
            "low": 514.82,
            "close": 515.06,
            "volume": 191664,
            "datetime": 1712842380000
        },
        {
            "open": 515.07,
            "high": 515.4,
            "low": 515.04,
            "close": 515.39,
            "volume": 195871,
            "datetime": 1712842440000
        },
        {
            "open": 515.41,
            "high": 515.45,
            "low": 515.05,
            "close": 515.265,
            "volume": 161027,
            "datetime": 1712842500000
        },
        {
            "open": 515.27,
            "high": 515.32,
            "low": 514.675,
            "close": 514.8098,
            "volume": 145795,
            "datetime": 1712842560000
        },
        {
            "open": 514.79,
            "high": 515.01,
            "low": 514.715,
            "close": 514.83,
            "volume": 137448,
            "datetime": 1712842620000
        },
        {
            "open": 514.82,
            "high": 515.12,
            "low": 514.7116,
            "close": 515.1,
            "volume": 93232,
            "datetime": 1712842680000
        },
        {
            "open": 515.09,
            "high": 515.09,
            "low": 514.5,
            "close": 514.55,
            "volume": 130330,
            "datetime": 1712842740000
        },
        {
            "open": 514.55,
            "high": 514.59,
            "low": 514.09,
            "close": 514.425,
            "volume": 266855,
            "datetime": 1712842800000
        },
        {
            "open": 514.445,
            "high": 514.99,
            "low": 514.44,
            "close": 514.81,
            "volume": 306136,
            "datetime": 1712842860000
        },
        {
            "open": 514.81,
            "high": 514.915,
            "low": 514.53,
            "close": 514.685,
            "volume": 91864,
            "datetime": 1712842920000
        },
        {
            "open": 514.7,
            "high": 514.81,
            "low": 514.3899,
            "close": 514.51,
            "volume": 104265,
            "datetime": 1712842980000
        },
        {
            "open": 514.46,
            "high": 514.53,
            "low": 514.3,
            "close": 514.365,
            "volume": 117467,
            "datetime": 1712843040000
        },
        {
            "open": 514.37,
            "high": 514.76,
            "low": 514.37,
            "close": 514.76,
            "volume": 146335,
            "datetime": 1712843100000
        },
        {
            "open": 514.76,
            "high": 514.83,
            "low": 514.27,
            "close": 514.31,
            "volume": 161467,
            "datetime": 1712843160000
        },
        {
            "open": 514.32,
            "high": 514.49,
            "low": 514.3068,
            "close": 514.4001,
            "volume": 118980,
            "datetime": 1712843220000
        },
        {
            "open": 514.4199,
            "high": 514.48,
            "low": 514.23,
            "close": 514.43,
            "volume": 99720,
            "datetime": 1712843280000
        },
        {
            "open": 514.48,
            "high": 514.57,
            "low": 514.28,
            "close": 514.535,
            "volume": 116756,
            "datetime": 1712843340000
        },
        {
            "open": 514.55,
            "high": 514.67,
            "low": 514.26,
            "close": 514.49,
            "volume": 122450,
            "datetime": 1712843400000
        },
        {
            "open": 514.51,
            "high": 514.74,
            "low": 514.46,
            "close": 514.5901,
            "volume": 115044,
            "datetime": 1712843460000
        },
        {
            "open": 514.6,
            "high": 514.9,
            "low": 514.58,
            "close": 514.79,
            "volume": 160917,
            "datetime": 1712843520000
        },
        {
            "open": 514.8,
            "high": 514.87,
            "low": 514.35,
            "close": 514.35,
            "volume": 85706,
            "datetime": 1712843580000
        },
        {
            "open": 514.34,
            "high": 514.71,
            "low": 514.34,
            "close": 514.71,
            "volume": 80686,
            "datetime": 1712843640000
        },
        {
            "open": 514.71,
            "high": 515.01,
            "low": 514.7,
            "close": 514.71,
            "volume": 133201,
            "datetime": 1712843700000
        },
        {
            "open": 514.73,
            "high": 514.76,
            "low": 514.32,
            "close": 514.36,
            "volume": 100505,
            "datetime": 1712843760000
        },
        {
            "open": 514.36,
            "high": 514.57,
            "low": 514.36,
            "close": 514.55,
            "volume": 63618,
            "datetime": 1712843820000
        },
        {
            "open": 514.55,
            "high": 514.9,
            "low": 514.525,
            "close": 514.8912,
            "volume": 85383,
            "datetime": 1712843880000
        },
        {
            "open": 514.9,
            "high": 514.93,
            "low": 514.49,
            "close": 514.61,
            "volume": 79087,
            "datetime": 1712843940000
        },
        {
            "open": 514.62,
            "high": 514.69,
            "low": 513.99,
            "close": 514.06,
            "volume": 166146,
            "datetime": 1712844000000
        },
        {
            "open": 514.03,
            "high": 514.24,
            "low": 513.59,
            "close": 513.685,
            "volume": 233008,
            "datetime": 1712844060000
        },
        {
            "open": 513.68,
            "high": 513.81,
            "low": 513.61,
            "close": 513.6603,
            "volume": 104990,
            "datetime": 1712844120000
        },
        {
            "open": 513.66,
            "high": 513.905,
            "low": 513.66,
            "close": 513.8412,
            "volume": 176807,
            "datetime": 1712844180000
        },
        {
            "open": 513.86,
            "high": 514.08,
            "low": 513.65,
            "close": 513.79,
            "volume": 134087,
            "datetime": 1712844240000
        },
        {
            "open": 513.81,
            "high": 513.95,
            "low": 513.45,
            "close": 513.46,
            "volume": 140951,
            "datetime": 1712844300000
        },
        {
            "open": 513.47,
            "high": 513.71,
            "low": 513.47,
            "close": 513.56,
            "volume": 171097,
            "datetime": 1712844360000
        },
        {
            "open": 513.57,
            "high": 513.585,
            "low": 513.15,
            "close": 513.2599,
            "volume": 202139,
            "datetime": 1712844420000
        },
        {
            "open": 513.27,
            "high": 513.48,
            "low": 513.04,
            "close": 513.3,
            "volume": 345586,
            "datetime": 1712844480000
        },
        {
            "open": 513.3,
            "high": 513.39,
            "low": 513.14,
            "close": 513.24,
            "volume": 97616,
            "datetime": 1712844540000
        },
        {
            "open": 513.22,
            "high": 513.41,
            "low": 513.14,
            "close": 513.15,
            "volume": 104235,
            "datetime": 1712844600000
        },
        {
            "open": 513.14,
            "high": 513.22,
            "low": 512.7,
            "close": 512.82,
            "volume": 268181,
            "datetime": 1712844660000
        },
        {
            "open": 512.83,
            "high": 512.96,
            "low": 512.7399,
            "close": 512.86,
            "volume": 128263,
            "datetime": 1712844720000
        },
        {
            "open": 512.85,
            "high": 512.97,
            "low": 512.68,
            "close": 512.82,
            "volume": 168840,
            "datetime": 1712844780000
        },
        {
            "open": 512.82,
            "high": 513.22,
            "low": 512.82,
            "close": 513.1,
            "volume": 161937,
            "datetime": 1712844840000
        },
        {
            "open": 513.08,
            "high": 513.11,
            "low": 512.29,
            "close": 512.36,
            "volume": 206323,
            "datetime": 1712844900000
        },
        {
            "open": 512.35,
            "high": 512.45,
            "low": 512.14,
            "close": 512.29,
            "volume": 188950,
            "datetime": 1712844960000
        },
        {
            "open": 512.2899,
            "high": 512.4,
            "low": 512.08,
            "close": 512.3599,
            "volume": 123628,
            "datetime": 1712845020000
        },
        {
            "open": 512.35,
            "high": 512.62,
            "low": 512.13,
            "close": 512.47,
            "volume": 142526,
            "datetime": 1712845080000
        },
        {
            "open": 512.49,
            "high": 512.55,
            "low": 512.31,
            "close": 512.31,
            "volume": 157157,
            "datetime": 1712845140000
        },
        {
            "open": 512.3,
            "high": 512.67,
            "low": 512.25,
            "close": 512.6413,
            "volume": 145468,
            "datetime": 1712845200000
        },
        {
            "open": 512.63,
            "high": 512.98,
            "low": 512.6,
            "close": 512.75,
            "volume": 189680,
            "datetime": 1712845260000
        },
        {
            "open": 512.75,
            "high": 512.95,
            "low": 512.65,
            "close": 512.66,
            "volume": 114922,
            "datetime": 1712845320000
        },
        {
            "open": 512.66,
            "high": 512.915,
            "low": 512.66,
            "close": 512.8401,
            "volume": 98005,
            "datetime": 1712845380000
        },
        {
            "open": 512.84,
            "high": 513.01,
            "low": 512.795,
            "close": 512.91,
            "volume": 87277,
            "datetime": 1712845440000
        },
        {
            "open": 512.92,
            "high": 512.97,
            "low": 512.68,
            "close": 512.775,
            "volume": 90194,
            "datetime": 1712845500000
        },
        {
            "open": 512.79,
            "high": 512.89,
            "low": 512.6511,
            "close": 512.8,
            "volume": 74284,
            "datetime": 1712845560000
        },
        {
            "open": 512.7801,
            "high": 512.82,
            "low": 512.43,
            "close": 512.512,
            "volume": 152224,
            "datetime": 1712845620000
        },
        {
            "open": 512.5,
            "high": 512.75,
            "low": 512.35,
            "close": 512.74,
            "volume": 140218,
            "datetime": 1712845680000
        },
        {
            "open": 512.76,
            "high": 512.8,
            "low": 512.53,
            "close": 512.57,
            "volume": 125597,
            "datetime": 1712845740000
        },
        {
            "open": 512.57,
            "high": 512.8,
            "low": 512.4514,
            "close": 512.78,
            "volume": 131187,
            "datetime": 1712845800000
        },
        {
            "open": 512.79,
            "high": 513.4,
            "low": 512.79,
            "close": 513.29,
            "volume": 366724,
            "datetime": 1712845860000
        },
        {
            "open": 513.305,
            "high": 513.335,
            "low": 512.7701,
            "close": 512.81,
            "volume": 154870,
            "datetime": 1712845920000
        },
        {
            "open": 512.8,
            "high": 512.98,
            "low": 512.75,
            "close": 512.975,
            "volume": 70849,
            "datetime": 1712845980000
        },
        {
            "open": 512.96,
            "high": 512.99,
            "low": 512.8,
            "close": 512.865,
            "volume": 65632,
            "datetime": 1712846040000
        },
        {
            "open": 512.87,
            "high": 512.95,
            "low": 512.79,
            "close": 512.82,
            "volume": 59653,
            "datetime": 1712846100000
        },
        {
            "open": 512.81,
            "high": 513,
            "low": 512.8,
            "close": 512.92,
            "volume": 75481,
            "datetime": 1712846160000
        },
        {
            "open": 512.94,
            "high": 513.2,
            "low": 512.92,
            "close": 513.1,
            "volume": 82649,
            "datetime": 1712846220000
        },
        {
            "open": 513.09,
            "high": 513.15,
            "low": 512.92,
            "close": 513.05,
            "volume": 49328,
            "datetime": 1712846280000
        },
        {
            "open": 513.07,
            "high": 513.49,
            "low": 512.8801,
            "close": 513.38,
            "volume": 217807,
            "datetime": 1712846340000
        },
        {
            "open": 513.39,
            "high": 513.62,
            "low": 513.31,
            "close": 513.54,
            "volume": 212406,
            "datetime": 1712846400000
        },
        {
            "open": 513.56,
            "high": 513.5601,
            "low": 513.4,
            "close": 513.47,
            "volume": 65621,
            "datetime": 1712846460000
        },
        {
            "open": 513.47,
            "high": 513.49,
            "low": 513.28,
            "close": 513.45,
            "volume": 89625,
            "datetime": 1712846520000
        },
        {
            "open": 513.45,
            "high": 513.49,
            "low": 513.24,
            "close": 513.49,
            "volume": 97004,
            "datetime": 1712846580000
        },
        {
            "open": 513.51,
            "high": 513.5299,
            "low": 513.34,
            "close": 513.44,
            "volume": 53032,
            "datetime": 1712846640000
        },
        {
            "open": 513.45,
            "high": 513.82,
            "low": 513.45,
            "close": 513.79,
            "volume": 198859,
            "datetime": 1712846700000
        },
        {
            "open": 513.79,
            "high": 513.81,
            "low": 513.56,
            "close": 513.61,
            "volume": 127256,
            "datetime": 1712846760000
        },
        {
            "open": 513.62,
            "high": 513.715,
            "low": 513.59,
            "close": 513.6823,
            "volume": 127175,
            "datetime": 1712846820000
        },
        {
            "open": 513.68,
            "high": 513.8,
            "low": 513.64,
            "close": 513.78,
            "volume": 75895,
            "datetime": 1712846880000
        },
        {
            "open": 513.78,
            "high": 513.79,
            "low": 513.52,
            "close": 513.525,
            "volume": 77974,
            "datetime": 1712846940000
        },
        {
            "open": 513.5213,
            "high": 513.645,
            "low": 513.45,
            "close": 513.625,
            "volume": 68952,
            "datetime": 1712847000000
        },
        {
            "open": 513.63,
            "high": 513.74,
            "low": 513.46,
            "close": 513.46,
            "volume": 87876,
            "datetime": 1712847060000
        },
        {
            "open": 513.48,
            "high": 513.5,
            "low": 513.3702,
            "close": 513.39,
            "volume": 93714,
            "datetime": 1712847120000
        },
        {
            "open": 513.4,
            "high": 513.53,
            "low": 513.3301,
            "close": 513.49,
            "volume": 73645,
            "datetime": 1712847180000
        },
        {
            "open": 513.49,
            "high": 513.64,
            "low": 513.49,
            "close": 513.54,
            "volume": 48648,
            "datetime": 1712847240000
        },
        {
            "open": 513.58,
            "high": 513.65,
            "low": 513.51,
            "close": 513.52,
            "volume": 49860,
            "datetime": 1712847300000
        },
        {
            "open": 513.51,
            "high": 513.56,
            "low": 513.44,
            "close": 513.45,
            "volume": 64075,
            "datetime": 1712847360000
        },
        {
            "open": 513.46,
            "high": 513.5,
            "low": 513.39,
            "close": 513.47,
            "volume": 64448,
            "datetime": 1712847420000
        },
        {
            "open": 513.47,
            "high": 513.5,
            "low": 513.37,
            "close": 513.39,
            "volume": 54687,
            "datetime": 1712847480000
        },
        {
            "open": 513.4,
            "high": 513.43,
            "low": 513.14,
            "close": 513.22,
            "volume": 166546,
            "datetime": 1712847540000
        },
        {
            "open": 513.23,
            "high": 513.34,
            "low": 513.15,
            "close": 513.33,
            "volume": 110684,
            "datetime": 1712847600000
        },
        {
            "open": 513.33,
            "high": 513.44,
            "low": 513.26,
            "close": 513.3911,
            "volume": 80601,
            "datetime": 1712847660000
        },
        {
            "open": 513.39,
            "high": 513.74,
            "low": 513.37,
            "close": 513.72,
            "volume": 138829,
            "datetime": 1712847720000
        },
        {
            "open": 513.7192,
            "high": 513.8,
            "low": 513.69,
            "close": 513.73,
            "volume": 131460,
            "datetime": 1712847780000
        },
        {
            "open": 513.73,
            "high": 513.92,
            "low": 513.61,
            "close": 513.73,
            "volume": 168651,
            "datetime": 1712847840000
        },
        {
            "open": 513.725,
            "high": 513.75,
            "low": 513.58,
            "close": 513.67,
            "volume": 83898,
            "datetime": 1712847900000
        },
        {
            "open": 513.69,
            "high": 513.725,
            "low": 513.525,
            "close": 513.53,
            "volume": 60684,
            "datetime": 1712847960000
        },
        {
            "open": 513.52,
            "high": 513.53,
            "low": 513.2,
            "close": 513.2501,
            "volume": 82238,
            "datetime": 1712848020000
        },
        {
            "open": 513.27,
            "high": 513.42,
            "low": 513.23,
            "close": 513.2999,
            "volume": 119655,
            "datetime": 1712848080000
        },
        {
            "open": 513.29,
            "high": 513.48,
            "low": 513.28,
            "close": 513.3003,
            "volume": 107087,
            "datetime": 1712848140000
        },
        {
            "open": 513.31,
            "high": 513.62,
            "low": 513.31,
            "close": 513.56,
            "volume": 88605,
            "datetime": 1712848200000
        },
        {
            "open": 513.59,
            "high": 513.7399,
            "low": 513.575,
            "close": 513.7,
            "volume": 41775,
            "datetime": 1712848260000
        },
        {
            "open": 513.69,
            "high": 513.775,
            "low": 513.64,
            "close": 513.65,
            "volume": 76810,
            "datetime": 1712848320000
        },
        {
            "open": 513.64,
            "high": 513.67,
            "low": 513.56,
            "close": 513.61,
            "volume": 54504,
            "datetime": 1712848380000
        },
        {
            "open": 513.6,
            "high": 513.6,
            "low": 513.45,
            "close": 513.52,
            "volume": 68123,
            "datetime": 1712848440000
        },
        {
            "open": 513.53,
            "high": 513.85,
            "low": 513.5299,
            "close": 513.8,
            "volume": 80899,
            "datetime": 1712848500000
        },
        {
            "open": 513.79,
            "high": 514.12,
            "low": 513.78,
            "close": 513.84,
            "volume": 147541,
            "datetime": 1712848560000
        },
        {
            "open": 513.88,
            "high": 513.91,
            "low": 513.76,
            "close": 513.85,
            "volume": 67332,
            "datetime": 1712848620000
        },
        {
            "open": 513.855,
            "high": 513.8624,
            "low": 513.62,
            "close": 513.71,
            "volume": 74531,
            "datetime": 1712848680000
        },
        {
            "open": 513.73,
            "high": 513.81,
            "low": 513.68,
            "close": 513.72,
            "volume": 52289,
            "datetime": 1712848740000
        },
        {
            "open": 513.73,
            "high": 513.85,
            "low": 513.7,
            "close": 513.825,
            "volume": 43848,
            "datetime": 1712848800000
        },
        {
            "open": 513.825,
            "high": 514.1,
            "low": 513.78,
            "close": 514.05,
            "volume": 78398,
            "datetime": 1712848860000
        },
        {
            "open": 514.0503,
            "high": 514.23,
            "low": 514.05,
            "close": 514.1179,
            "volume": 108026,
            "datetime": 1712848920000
        },
        {
            "open": 514.125,
            "high": 514.125,
            "low": 513.8724,
            "close": 513.93,
            "volume": 97430,
            "datetime": 1712848980000
        },
        {
            "open": 513.92,
            "high": 513.995,
            "low": 513.78,
            "close": 513.8,
            "volume": 64245,
            "datetime": 1712849040000
        },
        {
            "open": 513.799,
            "high": 513.8726,
            "low": 513.62,
            "close": 513.68,
            "volume": 82118,
            "datetime": 1712849100000
        },
        {
            "open": 513.65,
            "high": 513.67,
            "low": 513.525,
            "close": 513.6,
            "volume": 113054,
            "datetime": 1712849160000
        },
        {
            "open": 513.62,
            "high": 513.84,
            "low": 513.59,
            "close": 513.72,
            "volume": 56922,
            "datetime": 1712849220000
        },
        {
            "open": 513.72,
            "high": 513.78,
            "low": 513.48,
            "close": 513.58,
            "volume": 134373,
            "datetime": 1712849280000
        },
        {
            "open": 513.58,
            "high": 513.7499,
            "low": 513.53,
            "close": 513.57,
            "volume": 103776,
            "datetime": 1712849340000
        },
        {
            "open": 513.57,
            "high": 513.62,
            "low": 513.415,
            "close": 513.56,
            "volume": 98350,
            "datetime": 1712849400000
        },
        {
            "open": 513.545,
            "high": 513.86,
            "low": 513.545,
            "close": 513.86,
            "volume": 92022,
            "datetime": 1712849460000
        },
        {
            "open": 513.85,
            "high": 513.97,
            "low": 513.77,
            "close": 513.905,
            "volume": 83552,
            "datetime": 1712849520000
        },
        {
            "open": 513.89,
            "high": 513.92,
            "low": 513.6005,
            "close": 513.6593,
            "volume": 68748,
            "datetime": 1712849580000
        },
        {
            "open": 513.6612,
            "high": 513.79,
            "low": 513.63,
            "close": 513.77,
            "volume": 70395,
            "datetime": 1712849640000
        },
        {
            "open": 513.77,
            "high": 513.93,
            "low": 513.69,
            "close": 513.9218,
            "volume": 63421,
            "datetime": 1712849700000
        },
        {
            "open": 513.925,
            "high": 514.02,
            "low": 513.86,
            "close": 513.88,
            "volume": 81166,
            "datetime": 1712849760000
        },
        {
            "open": 513.875,
            "high": 514.07,
            "low": 513.78,
            "close": 513.92,
            "volume": 62835,
            "datetime": 1712849820000
        },
        {
            "open": 513.92,
            "high": 514.136,
            "low": 513.882,
            "close": 514.136,
            "volume": 48842,
            "datetime": 1712849880000
        },
        {
            "open": 514.15,
            "high": 514.215,
            "low": 514.08,
            "close": 514.14,
            "volume": 86313,
            "datetime": 1712849940000
        },
        {
            "open": 514.11,
            "high": 514.3,
            "low": 514.11,
            "close": 514.23,
            "volume": 82294,
            "datetime": 1712850000000
        },
        {
            "open": 514.225,
            "high": 514.55,
            "low": 514.1729,
            "close": 514.5,
            "volume": 175579,
            "datetime": 1712850060000
        },
        {
            "open": 514.505,
            "high": 514.53,
            "low": 514.3901,
            "close": 514.415,
            "volume": 105929,
            "datetime": 1712850120000
        },
        {
            "open": 514.3983,
            "high": 514.405,
            "low": 514.1501,
            "close": 514.19,
            "volume": 99847,
            "datetime": 1712850180000
        },
        {
            "open": 514.18,
            "high": 514.26,
            "low": 513.88,
            "close": 513.9,
            "volume": 141205,
            "datetime": 1712850240000
        },
        {
            "open": 513.89,
            "high": 514.14,
            "low": 513.88,
            "close": 514.05,
            "volume": 149442,
            "datetime": 1712850300000
        },
        {
            "open": 514.05,
            "high": 514.24,
            "low": 514.045,
            "close": 514.2,
            "volume": 102999,
            "datetime": 1712850360000
        },
        {
            "open": 514.21,
            "high": 514.39,
            "low": 514.2,
            "close": 514.335,
            "volume": 91622,
            "datetime": 1712850420000
        },
        {
            "open": 514.35,
            "high": 514.49,
            "low": 514.29,
            "close": 514.3501,
            "volume": 101149,
            "datetime": 1712850480000
        },
        {
            "open": 514.37,
            "high": 514.38,
            "low": 514.28,
            "close": 514.2899,
            "volume": 68873,
            "datetime": 1712850540000
        },
        {
            "open": 514.29,
            "high": 514.49,
            "low": 514.27,
            "close": 514.435,
            "volume": 79255,
            "datetime": 1712850600000
        },
        {
            "open": 514.46,
            "high": 514.53,
            "low": 514.38,
            "close": 514.415,
            "volume": 66528,
            "datetime": 1712850660000
        },
        {
            "open": 514.415,
            "high": 514.54,
            "low": 514.415,
            "close": 514.5,
            "volume": 67246,
            "datetime": 1712850720000
        },
        {
            "open": 514.5,
            "high": 514.55,
            "low": 514.41,
            "close": 514.53,
            "volume": 84954,
            "datetime": 1712850780000
        },
        {
            "open": 514.5213,
            "high": 514.5599,
            "low": 514.45,
            "close": 514.495,
            "volume": 77427,
            "datetime": 1712850840000
        },
        {
            "open": 514.49,
            "high": 514.66,
            "low": 514.49,
            "close": 514.54,
            "volume": 84955,
            "datetime": 1712850900000
        },
        {
            "open": 514.55,
            "high": 514.92,
            "low": 514.55,
            "close": 514.74,
            "volume": 181406,
            "datetime": 1712850960000
        },
        {
            "open": 514.75,
            "high": 514.91,
            "low": 514.725,
            "close": 514.86,
            "volume": 79725,
            "datetime": 1712851020000
        },
        {
            "open": 514.8511,
            "high": 515.22,
            "low": 514.8511,
            "close": 515.16,
            "volume": 225745,
            "datetime": 1712851080000
        },
        {
            "open": 515.17,
            "high": 515.45,
            "low": 515.17,
            "close": 515.34,
            "volume": 203260,
            "datetime": 1712851140000
        },
        {
            "open": 515.33,
            "high": 515.44,
            "low": 515.19,
            "close": 515.35,
            "volume": 202772,
            "datetime": 1712851200000
        },
        {
            "open": 515.33,
            "high": 515.5,
            "low": 515.255,
            "close": 515.255,
            "volume": 113182,
            "datetime": 1712851260000
        },
        {
            "open": 515.26,
            "high": 515.37,
            "low": 515.08,
            "close": 515.13,
            "volume": 233666,
            "datetime": 1712851320000
        },
        {
            "open": 515.14,
            "high": 515.19,
            "low": 514.92,
            "close": 515.075,
            "volume": 171416,
            "datetime": 1712851380000
        },
        {
            "open": 515.08,
            "high": 515.21,
            "low": 515.03,
            "close": 515.12,
            "volume": 205231,
            "datetime": 1712851440000
        },
        {
            "open": 515.125,
            "high": 515.17,
            "low": 515.05,
            "close": 515.06,
            "volume": 81019,
            "datetime": 1712851500000
        },
        {
            "open": 515.07,
            "high": 515.23,
            "low": 515.07,
            "close": 515.1502,
            "volume": 96109,
            "datetime": 1712851560000
        },
        {
            "open": 515.16,
            "high": 515.24,
            "low": 515.12,
            "close": 515.12,
            "volume": 75402,
            "datetime": 1712851620000
        },
        {
            "open": 515.12,
            "high": 515.12,
            "low": 514.97,
            "close": 515.06,
            "volume": 176965,
            "datetime": 1712851680000
        },
        {
            "open": 515.06,
            "high": 515.15,
            "low": 514.92,
            "close": 514.97,
            "volume": 149059,
            "datetime": 1712851740000
        },
        {
            "open": 514.97,
            "high": 515.16,
            "low": 514.97,
            "close": 515.06,
            "volume": 183173,
            "datetime": 1712851800000
        },
        {
            "open": 515.05,
            "high": 515.14,
            "low": 514.88,
            "close": 515,
            "volume": 113591,
            "datetime": 1712851860000
        },
        {
            "open": 515.01,
            "high": 515.04,
            "low": 514.9,
            "close": 514.92,
            "volume": 84186,
            "datetime": 1712851920000
        },
        {
            "open": 514.93,
            "high": 515.03,
            "low": 514.9203,
            "close": 514.99,
            "volume": 94265,
            "datetime": 1712851980000
        },
        {
            "open": 515,
            "high": 515.16,
            "low": 514.995,
            "close": 515.13,
            "volume": 49564,
            "datetime": 1712852040000
        },
        {
            "open": 515.11,
            "high": 515.165,
            "low": 514.9699,
            "close": 514.99,
            "volume": 74569,
            "datetime": 1712852100000
        },
        {
            "open": 515,
            "high": 515.075,
            "low": 514.95,
            "close": 515.045,
            "volume": 81083,
            "datetime": 1712852160000
        },
        {
            "open": 515.04,
            "high": 515.1499,
            "low": 515.025,
            "close": 515.05,
            "volume": 57688,
            "datetime": 1712852220000
        },
        {
            "open": 515.06,
            "high": 515.21,
            "low": 515.05,
            "close": 515.11,
            "volume": 61236,
            "datetime": 1712852280000
        },
        {
            "open": 515.13,
            "high": 515.32,
            "low": 515.12,
            "close": 515.29,
            "volume": 58877,
            "datetime": 1712852340000
        },
        {
            "open": 515.29,
            "high": 515.295,
            "low": 515.02,
            "close": 515.065,
            "volume": 101126,
            "datetime": 1712852400000
        },
        {
            "open": 515.0605,
            "high": 515.27,
            "low": 515.06,
            "close": 515.27,
            "volume": 55827,
            "datetime": 1712852460000
        },
        {
            "open": 515.28,
            "high": 515.33,
            "low": 515.245,
            "close": 515.29,
            "volume": 59572,
            "datetime": 1712852520000
        },
        {
            "open": 515.295,
            "high": 515.49,
            "low": 515.295,
            "close": 515.43,
            "volume": 54456,
            "datetime": 1712852580000
        },
        {
            "open": 515.42,
            "high": 515.52,
            "low": 515.4,
            "close": 515.49,
            "volume": 88019,
            "datetime": 1712852640000
        },
        {
            "open": 515.495,
            "high": 515.5,
            "low": 515.28,
            "close": 515.335,
            "volume": 140046,
            "datetime": 1712852700000
        },
        {
            "open": 515.33,
            "high": 515.37,
            "low": 515.28,
            "close": 515.365,
            "volume": 86406,
            "datetime": 1712852760000
        },
        {
            "open": 515.37,
            "high": 515.385,
            "low": 515.08,
            "close": 515.1401,
            "volume": 114171,
            "datetime": 1712852820000
        },
        {
            "open": 515.15,
            "high": 515.1799,
            "low": 514.92,
            "close": 515.02,
            "volume": 170111,
            "datetime": 1712852880000
        },
        {
            "open": 515.02,
            "high": 515.09,
            "low": 514.81,
            "close": 514.8399,
            "volume": 134626,
            "datetime": 1712852940000
        },
        {
            "open": 514.84,
            "high": 514.97,
            "low": 514.73,
            "close": 514.855,
            "volume": 122745,
            "datetime": 1712853000000
        },
        {
            "open": 514.85,
            "high": 515.06,
            "low": 514.85,
            "close": 515.01,
            "volume": 50452,
            "datetime": 1712853060000
        },
        {
            "open": 515.02,
            "high": 515.13,
            "low": 515.01,
            "close": 515.08,
            "volume": 56729,
            "datetime": 1712853120000
        },
        {
            "open": 515.09,
            "high": 515.175,
            "low": 515.05,
            "close": 515.15,
            "volume": 54244,
            "datetime": 1712853180000
        },
        {
            "open": 515.16,
            "high": 515.17,
            "low": 515.1,
            "close": 515.16,
            "volume": 41589,
            "datetime": 1712853240000
        },
        {
            "open": 515.15,
            "high": 515.2299,
            "low": 515.03,
            "close": 515.21,
            "volume": 74286,
            "datetime": 1712853300000
        },
        {
            "open": 515.195,
            "high": 515.26,
            "low": 515.13,
            "close": 515.13,
            "volume": 66339,
            "datetime": 1712853360000
        },
        {
            "open": 515.135,
            "high": 515.135,
            "low": 514.96,
            "close": 515.01,
            "volume": 86407,
            "datetime": 1712853420000
        },
        {
            "open": 515.01,
            "high": 515.08,
            "low": 514.99,
            "close": 515.03,
            "volume": 42826,
            "datetime": 1712853480000
        },
        {
            "open": 515.03,
            "high": 515.05,
            "low": 514.88,
            "close": 515.04,
            "volume": 117643,
            "datetime": 1712853540000
        },
        {
            "open": 515.07,
            "high": 515.09,
            "low": 514.99,
            "close": 515.01,
            "volume": 84256,
            "datetime": 1712853600000
        },
        {
            "open": 515.02,
            "high": 515.065,
            "low": 514.982,
            "close": 515.055,
            "volume": 60249,
            "datetime": 1712853660000
        },
        {
            "open": 515.06,
            "high": 515.245,
            "low": 515.06,
            "close": 515.245,
            "volume": 63394,
            "datetime": 1712853720000
        },
        {
            "open": 515.25,
            "high": 515.35,
            "low": 515.19,
            "close": 515.21,
            "volume": 128450,
            "datetime": 1712853780000
        },
        {
            "open": 515.21,
            "high": 515.21,
            "low": 515.1,
            "close": 515.16,
            "volume": 81998,
            "datetime": 1712853840000
        },
        {
            "open": 515.17,
            "high": 515.29,
            "low": 515.1302,
            "close": 515.21,
            "volume": 69951,
            "datetime": 1712853900000
        },
        {
            "open": 515.21,
            "high": 515.28,
            "low": 515.16,
            "close": 515.28,
            "volume": 50191,
            "datetime": 1712853960000
        },
        {
            "open": 515.2999,
            "high": 515.34,
            "low": 515.26,
            "close": 515.26,
            "volume": 61706,
            "datetime": 1712854020000
        },
        {
            "open": 515.255,
            "high": 515.41,
            "low": 515.255,
            "close": 515.395,
            "volume": 70795,
            "datetime": 1712854080000
        },
        {
            "open": 515.4,
            "high": 515.595,
            "low": 515.4,
            "close": 515.585,
            "volume": 143949,
            "datetime": 1712854140000
        },
        {
            "open": 515.58,
            "high": 515.65,
            "low": 515.42,
            "close": 515.54,
            "volume": 128471,
            "datetime": 1712854200000
        },
        {
            "open": 515.54,
            "high": 515.54,
            "low": 515.4403,
            "close": 515.5,
            "volume": 65116,
            "datetime": 1712854260000
        },
        {
            "open": 515.49,
            "high": 515.54,
            "low": 515.42,
            "close": 515.43,
            "volume": 76058,
            "datetime": 1712854320000
        },
        {
            "open": 515.45,
            "high": 515.54,
            "low": 515.41,
            "close": 515.45,
            "volume": 53566,
            "datetime": 1712854380000
        },
        {
            "open": 515.46,
            "high": 515.48,
            "low": 515.36,
            "close": 515.375,
            "volume": 65453,
            "datetime": 1712854440000
        },
        {
            "open": 515.37,
            "high": 515.47,
            "low": 515.31,
            "close": 515.47,
            "volume": 88220,
            "datetime": 1712854500000
        },
        {
            "open": 515.47,
            "high": 515.52,
            "low": 515.33,
            "close": 515.34,
            "volume": 49067,
            "datetime": 1712854560000
        },
        {
            "open": 515.3482,
            "high": 515.3482,
            "low": 515.1575,
            "close": 515.19,
            "volume": 118126,
            "datetime": 1712854620000
        },
        {
            "open": 515.19,
            "high": 515.43,
            "low": 515.17,
            "close": 515.42,
            "volume": 65238,
            "datetime": 1712854680000
        },
        {
            "open": 515.41,
            "high": 515.426,
            "low": 515.235,
            "close": 515.29,
            "volume": 33789,
            "datetime": 1712854740000
        },
        {
            "open": 515.3,
            "high": 515.43,
            "low": 515.22,
            "close": 515.255,
            "volume": 60910,
            "datetime": 1712854800000
        },
        {
            "open": 515.25,
            "high": 515.43,
            "low": 514.88,
            "close": 515.41,
            "volume": 374860,
            "datetime": 1712854860000
        },
        {
            "open": 515.38,
            "high": 515.39,
            "low": 515.11,
            "close": 515.27,
            "volume": 129977,
            "datetime": 1712854920000
        },
        {
            "open": 515.26,
            "high": 515.79,
            "low": 515.1,
            "close": 515.75,
            "volume": 124652,
            "datetime": 1712854980000
        },
        {
            "open": 515.73,
            "high": 516.335,
            "low": 515.69,
            "close": 516.16,
            "volume": 298361,
            "datetime": 1712855040000
        },
        {
            "open": 516.15,
            "high": 516.33,
            "low": 516.04,
            "close": 516.24,
            "volume": 261713,
            "datetime": 1712855100000
        },
        {
            "open": 516.26,
            "high": 516.62,
            "low": 516.19,
            "close": 516.54,
            "volume": 215797,
            "datetime": 1712855160000
        },
        {
            "open": 516.54,
            "high": 516.73,
            "low": 516.5,
            "close": 516.62,
            "volume": 177390,
            "datetime": 1712855220000
        },
        {
            "open": 516.63,
            "high": 516.86,
            "low": 516.565,
            "close": 516.65,
            "volume": 251029,
            "datetime": 1712855280000
        },
        {
            "open": 516.63,
            "high": 516.6901,
            "low": 516.465,
            "close": 516.54,
            "volume": 146288,
            "datetime": 1712855340000
        },
        {
            "open": 516.55,
            "high": 516.84,
            "low": 516.5275,
            "close": 516.74,
            "volume": 100476,
            "datetime": 1712855400000
        },
        {
            "open": 516.7301,
            "high": 516.82,
            "low": 516.64,
            "close": 516.75,
            "volume": 80506,
            "datetime": 1712855460000
        },
        {
            "open": 516.74,
            "high": 516.95,
            "low": 516.74,
            "close": 516.9,
            "volume": 171338,
            "datetime": 1712855520000
        },
        {
            "open": 516.91,
            "high": 516.98,
            "low": 516.75,
            "close": 516.89,
            "volume": 139329,
            "datetime": 1712855580000
        },
        {
            "open": 516.89,
            "high": 517.085,
            "low": 516.89,
            "close": 516.97,
            "volume": 119165,
            "datetime": 1712855640000
        },
        {
            "open": 516.98,
            "high": 517.09,
            "low": 516.76,
            "close": 516.9,
            "volume": 170019,
            "datetime": 1712855700000
        },
        {
            "open": 516.91,
            "high": 516.9601,
            "low": 516.82,
            "close": 516.925,
            "volume": 163593,
            "datetime": 1712855760000
        },
        {
            "open": 516.93,
            "high": 517.01,
            "low": 516.86,
            "close": 516.89,
            "volume": 84929,
            "datetime": 1712855820000
        },
        {
            "open": 516.89,
            "high": 517.05,
            "low": 516.89,
            "close": 517.02,
            "volume": 109355,
            "datetime": 1712855880000
        },
        {
            "open": 517.04,
            "high": 517.52,
            "low": 517.04,
            "close": 517.49,
            "volume": 252670,
            "datetime": 1712855940000
        },
        {
            "open": 517.48,
            "high": 517.82,
            "low": 517.43,
            "close": 517.81,
            "volume": 416379,
            "datetime": 1712856000000
        },
        {
            "open": 517.815,
            "high": 517.86,
            "low": 517.62,
            "close": 517.66,
            "volume": 257253,
            "datetime": 1712856060000
        },
        {
            "open": 517.65,
            "high": 517.705,
            "low": 517.285,
            "close": 517.33,
            "volume": 196029,
            "datetime": 1712856120000
        },
        {
            "open": 517.335,
            "high": 517.52,
            "low": 517.21,
            "close": 517.44,
            "volume": 147161,
            "datetime": 1712856180000
        },
        {
            "open": 517.45,
            "high": 517.57,
            "low": 517.44,
            "close": 517.545,
            "volume": 102985,
            "datetime": 1712856240000
        },
        {
            "open": 517.55,
            "high": 517.82,
            "low": 517.545,
            "close": 517.75,
            "volume": 157037,
            "datetime": 1712856300000
        },
        {
            "open": 517.75,
            "high": 517.77,
            "low": 517.6,
            "close": 517.7,
            "volume": 97753,
            "datetime": 1712856360000
        },
        {
            "open": 517.7,
            "high": 517.82,
            "low": 517.61,
            "close": 517.76,
            "volume": 154746,
            "datetime": 1712856420000
        },
        {
            "open": 517.7501,
            "high": 517.91,
            "low": 517.74,
            "close": 517.7501,
            "volume": 103730,
            "datetime": 1712856480000
        },
        {
            "open": 517.75,
            "high": 517.75,
            "low": 517.48,
            "close": 517.51,
            "volume": 134491,
            "datetime": 1712856540000
        },
        {
            "open": 517.52,
            "high": 517.67,
            "low": 517.52,
            "close": 517.645,
            "volume": 96291,
            "datetime": 1712856600000
        },
        {
            "open": 517.64,
            "high": 517.78,
            "low": 517.61,
            "close": 517.71,
            "volume": 135844,
            "datetime": 1712856660000
        },
        {
            "open": 517.71,
            "high": 517.76,
            "low": 517.62,
            "close": 517.655,
            "volume": 95513,
            "datetime": 1712856720000
        },
        {
            "open": 517.66,
            "high": 517.71,
            "low": 517.57,
            "close": 517.71,
            "volume": 92204,
            "datetime": 1712856780000
        },
        {
            "open": 517.72,
            "high": 517.83,
            "low": 517.54,
            "close": 517.5701,
            "volume": 180194,
            "datetime": 1712856840000
        },
        {
            "open": 517.57,
            "high": 517.6,
            "low": 517.42,
            "close": 517.4601,
            "volume": 135849,
            "datetime": 1712856900000
        },
        {
            "open": 517.4673,
            "high": 517.65,
            "low": 517.41,
            "close": 517.59,
            "volume": 120931,
            "datetime": 1712856960000
        },
        {
            "open": 517.595,
            "high": 517.635,
            "low": 517.45,
            "close": 517.485,
            "volume": 75578,
            "datetime": 1712857020000
        },
        {
            "open": 517.5,
            "high": 517.57,
            "low": 517.32,
            "close": 517.34,
            "volume": 117405,
            "datetime": 1712857080000
        },
        {
            "open": 517.33,
            "high": 517.39,
            "low": 517.205,
            "close": 517.21,
            "volume": 158936,
            "datetime": 1712857140000
        },
        {
            "open": 517.22,
            "high": 517.29,
            "low": 517.175,
            "close": 517.26,
            "volume": 107948,
            "datetime": 1712857200000
        },
        {
            "open": 517.255,
            "high": 517.28,
            "low": 516.96,
            "close": 517.065,
            "volume": 148633,
            "datetime": 1712857260000
        },
        {
            "open": 517.05,
            "high": 517.47,
            "low": 517.05,
            "close": 517.4383,
            "volume": 154218,
            "datetime": 1712857320000
        },
        {
            "open": 517.44,
            "high": 517.59,
            "low": 517.44,
            "close": 517.58,
            "volume": 93950,
            "datetime": 1712857380000
        },
        {
            "open": 517.6,
            "high": 517.8,
            "low": 517.58,
            "close": 517.7,
            "volume": 119018,
            "datetime": 1712857440000
        },
        {
            "open": 517.72,
            "high": 517.9,
            "low": 517.635,
            "close": 517.82,
            "volume": 114525,
            "datetime": 1712857500000
        },
        {
            "open": 517.81,
            "high": 517.81,
            "low": 517.53,
            "close": 517.59,
            "volume": 124845,
            "datetime": 1712857560000
        },
        {
            "open": 517.59,
            "high": 517.72,
            "low": 517.55,
            "close": 517.67,
            "volume": 102198,
            "datetime": 1712857620000
        },
        {
            "open": 517.66,
            "high": 517.82,
            "low": 517.64,
            "close": 517.8,
            "volume": 59370,
            "datetime": 1712857680000
        },
        {
            "open": 517.8,
            "high": 517.95,
            "low": 517.77,
            "close": 517.94,
            "volume": 102136,
            "datetime": 1712857740000
        },
        {
            "open": 517.94,
            "high": 518.01,
            "low": 517.8,
            "close": 517.8231,
            "volume": 80186,
            "datetime": 1712857800000
        },
        {
            "open": 517.83,
            "high": 517.87,
            "low": 517.68,
            "close": 517.855,
            "volume": 71357,
            "datetime": 1712857860000
        },
        {
            "open": 517.85,
            "high": 517.92,
            "low": 517.81,
            "close": 517.87,
            "volume": 61274,
            "datetime": 1712857920000
        },
        {
            "open": 517.88,
            "high": 518.11,
            "low": 517.84,
            "close": 517.985,
            "volume": 119900,
            "datetime": 1712857980000
        },
        {
            "open": 517.98,
            "high": 518.01,
            "low": 517.88,
            "close": 517.9801,
            "volume": 79222,
            "datetime": 1712858040000
        },
        {
            "open": 517.98,
            "high": 518.01,
            "low": 517.9,
            "close": 517.98,
            "volume": 54428,
            "datetime": 1712858100000
        },
        {
            "open": 517.985,
            "high": 518.13,
            "low": 517.97,
            "close": 518.05,
            "volume": 141783,
            "datetime": 1712858160000
        },
        {
            "open": 518.05,
            "high": 518.05,
            "low": 517.863,
            "close": 517.925,
            "volume": 99348,
            "datetime": 1712858220000
        },
        {
            "open": 517.93,
            "high": 518.025,
            "low": 517.92,
            "close": 517.99,
            "volume": 58259,
            "datetime": 1712858280000
        },
        {
            "open": 517.995,
            "high": 518.04,
            "low": 517.93,
            "close": 518,
            "volume": 48485,
            "datetime": 1712858340000
        },
        {
            "open": 518.005,
            "high": 518.25,
            "low": 517.92,
            "close": 518.05,
            "volume": 174980,
            "datetime": 1712858400000
        },
        {
            "open": 518.05,
            "high": 518.18,
            "low": 518.02,
            "close": 518.1499,
            "volume": 73075,
            "datetime": 1712858460000
        },
        {
            "open": 518.145,
            "high": 518.54,
            "low": 518.1,
            "close": 518.53,
            "volume": 237512,
            "datetime": 1712858520000
        },
        {
            "open": 518.53,
            "high": 518.535,
            "low": 518.28,
            "close": 518.285,
            "volume": 76120,
            "datetime": 1712858580000
        },
        {
            "open": 518.28,
            "high": 518.38,
            "low": 518.24,
            "close": 518.33,
            "volume": 108434,
            "datetime": 1712858640000
        },
        {
            "open": 518.335,
            "high": 518.49,
            "low": 518.335,
            "close": 518.44,
            "volume": 93678,
            "datetime": 1712858700000
        },
        {
            "open": 518.47,
            "high": 518.62,
            "low": 518.46,
            "close": 518.54,
            "volume": 83585,
            "datetime": 1712858760000
        },
        {
            "open": 518.55,
            "high": 518.93,
            "low": 518.55,
            "close": 518.9,
            "volume": 214265,
            "datetime": 1712858820000
        },
        {
            "open": 518.92,
            "high": 519.25,
            "low": 518.85,
            "close": 519.195,
            "volume": 282816,
            "datetime": 1712858880000
        },
        {
            "open": 519.2,
            "high": 519.27,
            "low": 518.975,
            "close": 519.02,
            "volume": 135369,
            "datetime": 1712858940000
        },
        {
            "open": 519.03,
            "high": 519.08,
            "low": 518.85,
            "close": 518.85,
            "volume": 147848,
            "datetime": 1712859000000
        },
        {
            "open": 518.88,
            "high": 518.93,
            "low": 518.76,
            "close": 518.8598,
            "volume": 194860,
            "datetime": 1712859060000
        },
        {
            "open": 518.85,
            "high": 518.915,
            "low": 518.72,
            "close": 518.77,
            "volume": 113117,
            "datetime": 1712859120000
        },
        {
            "open": 518.771,
            "high": 518.835,
            "low": 518.71,
            "close": 518.805,
            "volume": 93264,
            "datetime": 1712859180000
        },
        {
            "open": 518.79,
            "high": 518.835,
            "low": 518.66,
            "close": 518.71,
            "volume": 64930,
            "datetime": 1712859240000
        },
        {
            "open": 518.71,
            "high": 518.935,
            "low": 518.71,
            "close": 518.88,
            "volume": 100889,
            "datetime": 1712859300000
        },
        {
            "open": 518.88,
            "high": 518.98,
            "low": 518.82,
            "close": 518.87,
            "volume": 82434,
            "datetime": 1712859360000
        },
        {
            "open": 518.87,
            "high": 519.04,
            "low": 518.765,
            "close": 518.78,
            "volume": 112074,
            "datetime": 1712859420000
        },
        {
            "open": 518.7696,
            "high": 518.805,
            "low": 518.72,
            "close": 518.77,
            "volume": 58835,
            "datetime": 1712859480000
        },
        {
            "open": 518.765,
            "high": 518.8013,
            "low": 518.68,
            "close": 518.72,
            "volume": 68179,
            "datetime": 1712859540000
        },
        {
            "open": 518.715,
            "high": 518.81,
            "low": 518.67,
            "close": 518.78,
            "volume": 42640,
            "datetime": 1712859600000
        },
        {
            "open": 518.79,
            "high": 518.815,
            "low": 518.65,
            "close": 518.79,
            "volume": 59130,
            "datetime": 1712859660000
        },
        {
            "open": 518.7928,
            "high": 518.9,
            "low": 518.71,
            "close": 518.8,
            "volume": 50418,
            "datetime": 1712859720000
        },
        {
            "open": 518.79,
            "high": 518.8,
            "low": 518.69,
            "close": 518.7399,
            "volume": 44793,
            "datetime": 1712859780000
        },
        {
            "open": 518.75,
            "high": 518.93,
            "low": 518.72,
            "close": 518.92,
            "volume": 71603,
            "datetime": 1712859840000
        },
        {
            "open": 518.91,
            "high": 519,
            "low": 518.88,
            "close": 518.98,
            "volume": 86558,
            "datetime": 1712859900000
        },
        {
            "open": 518.985,
            "high": 519.27,
            "low": 518.9801,
            "close": 519.225,
            "volume": 114852,
            "datetime": 1712859960000
        },
        {
            "open": 519.23,
            "high": 519.2302,
            "low": 519.14,
            "close": 519.165,
            "volume": 97574,
            "datetime": 1712860020000
        },
        {
            "open": 519.175,
            "high": 519.2,
            "low": 519.03,
            "close": 519.07,
            "volume": 85430,
            "datetime": 1712860080000
        },
        {
            "open": 519.06,
            "high": 519.08,
            "low": 518.96,
            "close": 519.08,
            "volume": 100918,
            "datetime": 1712860140000
        },
        {
            "open": 519.07,
            "high": 519.21,
            "low": 519,
            "close": 519.07,
            "volume": 122917,
            "datetime": 1712860200000
        },
        {
            "open": 519.06,
            "high": 519.09,
            "low": 518.96,
            "close": 519.09,
            "volume": 103610,
            "datetime": 1712860260000
        },
        {
            "open": 519.09,
            "high": 519.22,
            "low": 519.09,
            "close": 519.19,
            "volume": 69385,
            "datetime": 1712860320000
        },
        {
            "open": 519.19,
            "high": 519.2197,
            "low": 519.08,
            "close": 519.18,
            "volume": 50968,
            "datetime": 1712860380000
        },
        {
            "open": 519.19,
            "high": 519.37,
            "low": 519.165,
            "close": 519.33,
            "volume": 65907,
            "datetime": 1712860440000
        },
        {
            "open": 519.32,
            "high": 519.39,
            "low": 519.195,
            "close": 519.28,
            "volume": 78929,
            "datetime": 1712860500000
        },
        {
            "open": 519.28,
            "high": 519.46,
            "low": 519.28,
            "close": 519.365,
            "volume": 124115,
            "datetime": 1712860560000
        },
        {
            "open": 519.365,
            "high": 519.3901,
            "low": 519.2099,
            "close": 519.375,
            "volume": 59619,
            "datetime": 1712860620000
        },
        {
            "open": 519.395,
            "high": 519.465,
            "low": 519.345,
            "close": 519.405,
            "volume": 80498,
            "datetime": 1712860680000
        },
        {
            "open": 519.4001,
            "high": 519.42,
            "low": 519.34,
            "close": 519.42,
            "volume": 56964,
            "datetime": 1712860740000
        },
        {
            "open": 519.42,
            "high": 519.48,
            "low": 519.3,
            "close": 519.4,
            "volume": 71957,
            "datetime": 1712860800000
        },
        {
            "open": 519.39,
            "high": 519.39,
            "low": 519.18,
            "close": 519.25,
            "volume": 135427,
            "datetime": 1712860860000
        },
        {
            "open": 519.26,
            "high": 519.3705,
            "low": 519.21,
            "close": 519.3705,
            "volume": 74871,
            "datetime": 1712860920000
        },
        {
            "open": 519.38,
            "high": 519.41,
            "low": 519.345,
            "close": 519.385,
            "volume": 71202,
            "datetime": 1712860980000
        },
        {
            "open": 519.39,
            "high": 519.395,
            "low": 519.275,
            "close": 519.34,
            "volume": 97135,
            "datetime": 1712861040000
        },
        {
            "open": 519.33,
            "high": 519.38,
            "low": 519.0699,
            "close": 519.0704,
            "volume": 125300,
            "datetime": 1712861100000
        },
        {
            "open": 519.08,
            "high": 519.26,
            "low": 519.08,
            "close": 519.15,
            "volume": 102296,
            "datetime": 1712861160000
        },
        {
            "open": 519.16,
            "high": 519.185,
            "low": 518.98,
            "close": 519.0783,
            "volume": 116165,
            "datetime": 1712861220000
        },
        {
            "open": 519.08,
            "high": 519.1,
            "low": 518.8585,
            "close": 518.94,
            "volume": 111841,
            "datetime": 1712861280000
        },
        {
            "open": 518.9499,
            "high": 518.98,
            "low": 518.835,
            "close": 518.98,
            "volume": 120888,
            "datetime": 1712861340000
        },
        {
            "open": 518.975,
            "high": 519.04,
            "low": 518.915,
            "close": 518.94,
            "volume": 82791,
            "datetime": 1712861400000
        },
        {
            "open": 518.9478,
            "high": 518.99,
            "low": 518.88,
            "close": 518.96,
            "volume": 65515,
            "datetime": 1712861460000
        },
        {
            "open": 518.97,
            "high": 518.97,
            "low": 518.8,
            "close": 518.81,
            "volume": 68439,
            "datetime": 1712861520000
        },
        {
            "open": 518.8199,
            "high": 518.8199,
            "low": 518.54,
            "close": 518.64,
            "volume": 153788,
            "datetime": 1712861580000
        },
        {
            "open": 518.64,
            "high": 518.87,
            "low": 518.6,
            "close": 518.7499,
            "volume": 134839,
            "datetime": 1712861640000
        },
        {
            "open": 518.74,
            "high": 518.92,
            "low": 518.71,
            "close": 518.87,
            "volume": 100744,
            "datetime": 1712861700000
        },
        {
            "open": 518.87,
            "high": 518.905,
            "low": 518.7812,
            "close": 518.84,
            "volume": 52070,
            "datetime": 1712861760000
        },
        {
            "open": 518.85,
            "high": 518.97,
            "low": 518.77,
            "close": 518.955,
            "volume": 91261,
            "datetime": 1712861820000
        },
        {
            "open": 518.95,
            "high": 518.97,
            "low": 518.8,
            "close": 518.8,
            "volume": 47866,
            "datetime": 1712861880000
        },
        {
            "open": 518.81,
            "high": 518.835,
            "low": 518.68,
            "close": 518.689,
            "volume": 56649,
            "datetime": 1712861940000
        },
        {
            "open": 518.69,
            "high": 519.01,
            "low": 518.69,
            "close": 518.89,
            "volume": 110396,
            "datetime": 1712862000000
        },
        {
            "open": 518.8902,
            "high": 518.8902,
            "low": 518.69,
            "close": 518.74,
            "volume": 94554,
            "datetime": 1712862060000
        },
        {
            "open": 518.73,
            "high": 518.82,
            "low": 518.685,
            "close": 518.72,
            "volume": 55766,
            "datetime": 1712862120000
        },
        {
            "open": 518.72,
            "high": 518.86,
            "low": 518.68,
            "close": 518.86,
            "volume": 60921,
            "datetime": 1712862180000
        },
        {
            "open": 518.865,
            "high": 518.9,
            "low": 518.72,
            "close": 518.8,
            "volume": 68919,
            "datetime": 1712862240000
        },
        {
            "open": 518.8,
            "high": 519.09,
            "low": 518.79,
            "close": 519.06,
            "volume": 101102,
            "datetime": 1712862300000
        },
        {
            "open": 519.07,
            "high": 519.12,
            "low": 518.99,
            "close": 519.055,
            "volume": 65341,
            "datetime": 1712862360000
        },
        {
            "open": 519.06,
            "high": 519.23,
            "low": 519.03,
            "close": 519.19,
            "volume": 79830,
            "datetime": 1712862420000
        },
        {
            "open": 519.18,
            "high": 519.2,
            "low": 519.02,
            "close": 519.06,
            "volume": 57768,
            "datetime": 1712862480000
        },
        {
            "open": 519.07,
            "high": 519.08,
            "low": 518.97,
            "close": 519.02,
            "volume": 63848,
            "datetime": 1712862540000
        },
        {
            "open": 519.01,
            "high": 519.165,
            "low": 519.01,
            "close": 519.08,
            "volume": 57604,
            "datetime": 1712862600000
        },
        {
            "open": 519.095,
            "high": 519.11,
            "low": 518.93,
            "close": 518.93,
            "volume": 80630,
            "datetime": 1712862660000
        },
        {
            "open": 518.9201,
            "high": 518.96,
            "low": 518.79,
            "close": 518.94,
            "volume": 105065,
            "datetime": 1712862720000
        },
        {
            "open": 518.945,
            "high": 518.945,
            "low": 518.76,
            "close": 518.8,
            "volume": 70170,
            "datetime": 1712862780000
        },
        {
            "open": 518.795,
            "high": 518.835,
            "low": 518.68,
            "close": 518.7,
            "volume": 65976,
            "datetime": 1712862840000
        },
        {
            "open": 518.71,
            "high": 518.72,
            "low": 518.51,
            "close": 518.576,
            "volume": 120157,
            "datetime": 1712862900000
        },
        {
            "open": 518.58,
            "high": 518.785,
            "low": 518.53,
            "close": 518.715,
            "volume": 126773,
            "datetime": 1712862960000
        },
        {
            "open": 518.72,
            "high": 518.785,
            "low": 518.654,
            "close": 518.78,
            "volume": 54255,
            "datetime": 1712863020000
        },
        {
            "open": 518.78,
            "high": 518.91,
            "low": 518.735,
            "close": 518.77,
            "volume": 47420,
            "datetime": 1712863080000
        },
        {
            "open": 518.77,
            "high": 518.81,
            "low": 518.58,
            "close": 518.62,
            "volume": 74022,
            "datetime": 1712863140000
        },
        {
            "open": 518.62,
            "high": 518.68,
            "low": 518.545,
            "close": 518.65,
            "volume": 53399,
            "datetime": 1712863200000
        },
        {
            "open": 518.649,
            "high": 518.8401,
            "low": 518.63,
            "close": 518.78,
            "volume": 54051,
            "datetime": 1712863260000
        },
        {
            "open": 518.77,
            "high": 519.01,
            "low": 518.77,
            "close": 518.94,
            "volume": 79723,
            "datetime": 1712863320000
        },
        {
            "open": 518.94,
            "high": 518.94,
            "low": 518.8,
            "close": 518.85,
            "volume": 37452,
            "datetime": 1712863380000
        },
        {
            "open": 518.85,
            "high": 518.93,
            "low": 518.78,
            "close": 518.87,
            "volume": 64532,
            "datetime": 1712863440000
        },
        {
            "open": 518.87,
            "high": 518.995,
            "low": 518.83,
            "close": 518.93,
            "volume": 73799,
            "datetime": 1712863500000
        },
        {
            "open": 518.94,
            "high": 519.055,
            "low": 518.86,
            "close": 518.91,
            "volume": 112567,
            "datetime": 1712863560000
        },
        {
            "open": 518.905,
            "high": 518.98,
            "low": 518.72,
            "close": 518.74,
            "volume": 90060,
            "datetime": 1712863620000
        },
        {
            "open": 518.731,
            "high": 518.8301,
            "low": 518.725,
            "close": 518.8,
            "volume": 95411,
            "datetime": 1712863680000
        },
        {
            "open": 518.81,
            "high": 519,
            "low": 518.81,
            "close": 518.91,
            "volume": 58049,
            "datetime": 1712863740000
        },
        {
            "open": 518.915,
            "high": 518.96,
            "low": 518.75,
            "close": 518.96,
            "volume": 76331,
            "datetime": 1712863800000
        },
        {
            "open": 518.955,
            "high": 518.99,
            "low": 518.9,
            "close": 518.97,
            "volume": 102232,
            "datetime": 1712863860000
        },
        {
            "open": 518.96,
            "high": 519,
            "low": 518.77,
            "close": 518.8,
            "volume": 78461,
            "datetime": 1712863920000
        },
        {
            "open": 518.79,
            "high": 518.8045,
            "low": 518.7,
            "close": 518.76,
            "volume": 94619,
            "datetime": 1712863980000
        },
        {
            "open": 518.77,
            "high": 518.99,
            "low": 518.77,
            "close": 518.94,
            "volume": 58720,
            "datetime": 1712864040000
        },
        {
            "open": 518.95,
            "high": 518.97,
            "low": 518.86,
            "close": 518.93,
            "volume": 82024,
            "datetime": 1712864100000
        },
        {
            "open": 518.91,
            "high": 518.96,
            "low": 518.82,
            "close": 518.925,
            "volume": 123663,
            "datetime": 1712864160000
        },
        {
            "open": 518.93,
            "high": 518.95,
            "low": 518.82,
            "close": 518.84,
            "volume": 73416,
            "datetime": 1712864220000
        },
        {
            "open": 518.85,
            "high": 518.92,
            "low": 518.815,
            "close": 518.85,
            "volume": 66068,
            "datetime": 1712864280000
        },
        {
            "open": 518.855,
            "high": 518.96,
            "low": 518.815,
            "close": 518.9383,
            "volume": 97665,
            "datetime": 1712864340000
        },
        {
            "open": 518.935,
            "high": 519.12,
            "low": 518.9301,
            "close": 519.06,
            "volume": 147664,
            "datetime": 1712864400000
        },
        {
            "open": 519.0619,
            "high": 519.095,
            "low": 518.97,
            "close": 519.095,
            "volume": 133864,
            "datetime": 1712864460000
        },
        {
            "open": 519.095,
            "high": 519.11,
            "low": 518.88,
            "close": 518.99,
            "volume": 167555,
            "datetime": 1712864520000
        },
        {
            "open": 518.99,
            "high": 519.03,
            "low": 518.9603,
            "close": 519.005,
            "volume": 114196,
            "datetime": 1712864580000
        },
        {
            "open": 519.01,
            "high": 519.22,
            "low": 518.99,
            "close": 519.17,
            "volume": 196937,
            "datetime": 1712864640000
        },
        {
            "open": 519.17,
            "high": 519.39,
            "low": 519.0502,
            "close": 519.2901,
            "volume": 485427,
            "datetime": 1712864700000
        },
        {
            "open": 519.2901,
            "high": 519.31,
            "low": 519.12,
            "close": 519.1717,
            "volume": 160786,
            "datetime": 1712864760000
        },
        {
            "open": 519.18,
            "high": 519.22,
            "low": 519.07,
            "close": 519.075,
            "volume": 154770,
            "datetime": 1712864820000
        },
        {
            "open": 519.08,
            "high": 519.09,
            "low": 518.945,
            "close": 519.05,
            "volume": 184613,
            "datetime": 1712864880000
        },
        {
            "open": 519.05,
            "high": 519.07,
            "low": 518.83,
            "close": 518.87,
            "volume": 140835,
            "datetime": 1712864940000
        },
        {
            "open": 518.87,
            "high": 518.87,
            "low": 518.43,
            "close": 518.4894,
            "volume": 381259,
            "datetime": 1712865000000
        },
        {
            "open": 518.49,
            "high": 518.7601,
            "low": 518.4,
            "close": 518.72,
            "volume": 344240,
            "datetime": 1712865060000
        },
        {
            "open": 518.71,
            "high": 518.72,
            "low": 518.48,
            "close": 518.515,
            "volume": 262583,
            "datetime": 1712865120000
        },
        {
            "open": 518.515,
            "high": 518.61,
            "low": 518.47,
            "close": 518.53,
            "volume": 203989,
            "datetime": 1712865180000
        },
        {
            "open": 518.56,
            "high": 518.695,
            "low": 518.5,
            "close": 518.615,
            "volume": 408964,
            "datetime": 1712865240000
        },
        {
            "open": 518.61,
            "high": 518.765,
            "low": 518.51,
            "close": 518.63,
            "volume": 524942,
            "datetime": 1712865300000
        },
        {
            "open": 518.63,
            "high": 518.68,
            "low": 518.5,
            "close": 518.59,
            "volume": 391342,
            "datetime": 1712865360000
        },
        {
            "open": 518.59,
            "high": 518.595,
            "low": 518.425,
            "close": 518.575,
            "volume": 490630,
            "datetime": 1712865420000
        },
        {
            "open": 518.57,
            "high": 518.58,
            "low": 518.33,
            "close": 518.4,
            "volume": 712337,
            "datetime": 1712865480000
        },
        {
            "open": 518.4,
            "high": 518.4199,
            "low": 517.97,
            "close": 518.05,
            "volume": 2838348,
            "datetime": 1712865540000
        },
        {
            "open": 514.38,
            "high": 514.5699,
            "low": 514.27,
            "close": 514.55,
            "volume": 697087,
            "datetime": 1712928600000
        },
        {
            "open": 514.55,
            "high": 514.831,
            "low": 514.08,
            "close": 514.831,
            "volume": 328087,
            "datetime": 1712928660000
        },
        {
            "open": 514.835,
            "high": 515.28,
            "low": 514.62,
            "close": 515.24,
            "volume": 358631,
            "datetime": 1712928720000
        },
        {
            "open": 515.25,
            "high": 515.28,
            "low": 514.97,
            "close": 514.9799,
            "volume": 274291,
            "datetime": 1712928780000
        },
        {
            "open": 514.98,
            "high": 515.07,
            "low": 514.84,
            "close": 514.98,
            "volume": 214274,
            "datetime": 1712928840000
        },
        {
            "open": 514.99,
            "high": 515.03,
            "low": 514.78,
            "close": 514.88,
            "volume": 351856,
            "datetime": 1712928900000
        },
        {
            "open": 514.88,
            "high": 515.055,
            "low": 514.75,
            "close": 515.03,
            "volume": 226462,
            "datetime": 1712928960000
        },
        {
            "open": 515.03,
            "high": 515.09,
            "low": 514.9,
            "close": 514.99,
            "volume": 158442,
            "datetime": 1712929020000
        },
        {
            "open": 514.99,
            "high": 515.24,
            "low": 514.82,
            "close": 514.9501,
            "volume": 196169,
            "datetime": 1712929080000
        },
        {
            "open": 514.96,
            "high": 515.18,
            "low": 514.96,
            "close": 515.15,
            "volume": 155253,
            "datetime": 1712929140000
        },
        {
            "open": 515.14,
            "high": 515.14,
            "low": 514.92,
            "close": 515.12,
            "volume": 157594,
            "datetime": 1712929200000
        },
        {
            "open": 515.11,
            "high": 515.22,
            "low": 515.03,
            "close": 515.15,
            "volume": 194369,
            "datetime": 1712929260000
        },
        {
            "open": 515.15,
            "high": 515.15,
            "low": 514.95,
            "close": 515.02,
            "volume": 175632,
            "datetime": 1712929320000
        },
        {
            "open": 515.03,
            "high": 515.26,
            "low": 514.86,
            "close": 515.23,
            "volume": 202795,
            "datetime": 1712929380000
        },
        {
            "open": 515.24,
            "high": 515.31,
            "low": 515.14,
            "close": 515.1651,
            "volume": 154041,
            "datetime": 1712929440000
        },
        {
            "open": 515.18,
            "high": 515.44,
            "low": 515.04,
            "close": 515.385,
            "volume": 295862,
            "datetime": 1712929500000
        },
        {
            "open": 515.385,
            "high": 515.815,
            "low": 515.27,
            "close": 515.7401,
            "volume": 354578,
            "datetime": 1712929560000
        },
        {
            "open": 515.74,
            "high": 515.755,
            "low": 515.555,
            "close": 515.61,
            "volume": 192069,
            "datetime": 1712929620000
        },
        {
            "open": 515.62,
            "high": 515.74,
            "low": 515.4197,
            "close": 515.47,
            "volume": 245157,
            "datetime": 1712929680000
        },
        {
            "open": 515.5,
            "high": 515.5,
            "low": 515.27,
            "close": 515.33,
            "volume": 180825,
            "datetime": 1712929740000
        },
        {
            "open": 515.34,
            "high": 515.6751,
            "low": 515.31,
            "close": 515.54,
            "volume": 189533,
            "datetime": 1712929800000
        },
        {
            "open": 515.54,
            "high": 515.59,
            "low": 515.23,
            "close": 515.2483,
            "volume": 199373,
            "datetime": 1712929860000
        },
        {
            "open": 515.25,
            "high": 515.3412,
            "low": 514.97,
            "close": 515.06,
            "volume": 305379,
            "datetime": 1712929920000
        },
        {
            "open": 515.0601,
            "high": 515.12,
            "low": 514.955,
            "close": 515.1,
            "volume": 129689,
            "datetime": 1712929980000
        },
        {
            "open": 515.1012,
            "high": 515.1012,
            "low": 514.78,
            "close": 515.01,
            "volume": 179922,
            "datetime": 1712930040000
        },
        {
            "open": 515.015,
            "high": 515.08,
            "low": 514.845,
            "close": 514.845,
            "volume": 147699,
            "datetime": 1712930100000
        },
        {
            "open": 514.85,
            "high": 515.01,
            "low": 514.79,
            "close": 514.84,
            "volume": 184813,
            "datetime": 1712930160000
        },
        {
            "open": 514.84,
            "high": 514.9399,
            "low": 514.72,
            "close": 514.89,
            "volume": 162918,
            "datetime": 1712930220000
        },
        {
            "open": 514.885,
            "high": 515.1595,
            "low": 514.87,
            "close": 515.1288,
            "volume": 184625,
            "datetime": 1712930280000
        },
        {
            "open": 515.13,
            "high": 515.38,
            "low": 515.13,
            "close": 515.34,
            "volume": 150460,
            "datetime": 1712930340000
        },
        {
            "open": 515.37,
            "high": 515.44,
            "low": 514.42,
            "close": 515.04,
            "volume": 490262,
            "datetime": 1712930400000
        },
        {
            "open": 515.02,
            "high": 515.26,
            "low": 514.841,
            "close": 514.89,
            "volume": 259758,
            "datetime": 1712930460000
        },
        {
            "open": 514.89,
            "high": 514.96,
            "low": 514.47,
            "close": 514.58,
            "volume": 211265,
            "datetime": 1712930520000
        },
        {
            "open": 514.58,
            "high": 514.74,
            "low": 514.28,
            "close": 514.37,
            "volume": 260957,
            "datetime": 1712930580000
        },
        {
            "open": 514.365,
            "high": 514.74,
            "low": 514.32,
            "close": 514.52,
            "volume": 278871,
            "datetime": 1712930640000
        },
        {
            "open": 514.53,
            "high": 514.65,
            "low": 514.44,
            "close": 514.565,
            "volume": 257321,
            "datetime": 1712930700000
        },
        {
            "open": 514.57,
            "high": 514.75,
            "low": 514.19,
            "close": 514.22,
            "volume": 219077,
            "datetime": 1712930760000
        },
        {
            "open": 514.21,
            "high": 514.28,
            "low": 513.835,
            "close": 514.155,
            "volume": 361916,
            "datetime": 1712930820000
        },
        {
            "open": 514.17,
            "high": 514.19,
            "low": 513.76,
            "close": 513.81,
            "volume": 211996,
            "datetime": 1712930880000
        },
        {
            "open": 513.82,
            "high": 514.24,
            "low": 513.71,
            "close": 513.97,
            "volume": 246417,
            "datetime": 1712930940000
        },
        {
            "open": 513.96,
            "high": 514.4,
            "low": 513.94,
            "close": 514.19,
            "volume": 263160,
            "datetime": 1712931000000
        },
        {
            "open": 514.18,
            "high": 514.25,
            "low": 513.96,
            "close": 514.06,
            "volume": 107314,
            "datetime": 1712931060000
        },
        {
            "open": 514.06,
            "high": 514.11,
            "low": 513.78,
            "close": 514.1,
            "volume": 153669,
            "datetime": 1712931120000
        },
        {
            "open": 514.1,
            "high": 514.1,
            "low": 513.86,
            "close": 513.915,
            "volume": 123650,
            "datetime": 1712931180000
        },
        {
            "open": 513.92,
            "high": 514.21,
            "low": 513.89,
            "close": 514.19,
            "volume": 111617,
            "datetime": 1712931240000
        },
        {
            "open": 514.18,
            "high": 514.18,
            "low": 513.63,
            "close": 513.65,
            "volume": 282280,
            "datetime": 1712931300000
        },
        {
            "open": 513.6411,
            "high": 513.91,
            "low": 513.56,
            "close": 513.9,
            "volume": 184665,
            "datetime": 1712931360000
        },
        {
            "open": 513.89,
            "high": 514.24,
            "low": 513.8,
            "close": 513.95,
            "volume": 177059,
            "datetime": 1712931420000
        },
        {
            "open": 513.95,
            "high": 514.4399,
            "low": 513.89,
            "close": 514.34,
            "volume": 232278,
            "datetime": 1712931480000
        },
        {
            "open": 514.35,
            "high": 514.55,
            "low": 514.24,
            "close": 514.32,
            "volume": 185040,
            "datetime": 1712931540000
        },
        {
            "open": 514.31,
            "high": 514.47,
            "low": 514.29,
            "close": 514.36,
            "volume": 96071,
            "datetime": 1712931600000
        },
        {
            "open": 514.34,
            "high": 514.42,
            "low": 514.205,
            "close": 514.26,
            "volume": 187730,
            "datetime": 1712931660000
        },
        {
            "open": 514.26,
            "high": 514.5188,
            "low": 514.2502,
            "close": 514.295,
            "volume": 106480,
            "datetime": 1712931720000
        },
        {
            "open": 514.29,
            "high": 514.48,
            "low": 514.23,
            "close": 514.34,
            "volume": 94642,
            "datetime": 1712931780000
        },
        {
            "open": 514.3425,
            "high": 514.42,
            "low": 514.165,
            "close": 514.185,
            "volume": 103319,
            "datetime": 1712931840000
        },
        {
            "open": 514.189,
            "high": 514.27,
            "low": 514.07,
            "close": 514.13,
            "volume": 126698,
            "datetime": 1712931900000
        },
        {
            "open": 514.12,
            "high": 514.15,
            "low": 513.98,
            "close": 514.12,
            "volume": 101518,
            "datetime": 1712931960000
        },
        {
            "open": 514.12,
            "high": 514.12,
            "low": 513.94,
            "close": 513.95,
            "volume": 86375,
            "datetime": 1712932020000
        },
        {
            "open": 513.95,
            "high": 514.05,
            "low": 513.76,
            "close": 513.784,
            "volume": 136011,
            "datetime": 1712932080000
        },
        {
            "open": 513.79,
            "high": 513.94,
            "low": 513.74,
            "close": 513.8,
            "volume": 120783,
            "datetime": 1712932140000
        },
        {
            "open": 513.81,
            "high": 514,
            "low": 513.7,
            "close": 513.97,
            "volume": 148400,
            "datetime": 1712932200000
        },
        {
            "open": 513.96,
            "high": 514.06,
            "low": 513.87,
            "close": 514.01,
            "volume": 97485,
            "datetime": 1712932260000
        },
        {
            "open": 514,
            "high": 514.05,
            "low": 513.92,
            "close": 513.95,
            "volume": 96718,
            "datetime": 1712932320000
        },
        {
            "open": 513.94,
            "high": 514.09,
            "low": 513.81,
            "close": 513.81,
            "volume": 141209,
            "datetime": 1712932380000
        },
        {
            "open": 513.81,
            "high": 513.825,
            "low": 513.54,
            "close": 513.572,
            "volume": 176538,
            "datetime": 1712932440000
        },
        {
            "open": 513.58,
            "high": 513.74,
            "low": 513.43,
            "close": 513.482,
            "volume": 192945,
            "datetime": 1712932500000
        },
        {
            "open": 513.5,
            "high": 513.53,
            "low": 513.07,
            "close": 513.091,
            "volume": 240543,
            "datetime": 1712932560000
        },
        {
            "open": 513.09,
            "high": 513.0995,
            "low": 512.7525,
            "close": 513.02,
            "volume": 273276,
            "datetime": 1712932620000
        },
        {
            "open": 513.02,
            "high": 513.305,
            "low": 512.93,
            "close": 513.05,
            "volume": 203691,
            "datetime": 1712932680000
        },
        {
            "open": 513.04,
            "high": 513.095,
            "low": 512.93,
            "close": 512.99,
            "volume": 164236,
            "datetime": 1712932740000
        },
        {
            "open": 512.98,
            "high": 513.11,
            "low": 512.94,
            "close": 513.0317,
            "volume": 140340,
            "datetime": 1712932800000
        },
        {
            "open": 513.03,
            "high": 513.03,
            "low": 512.85,
            "close": 512.92,
            "volume": 177620,
            "datetime": 1712932860000
        },
        {
            "open": 512.9,
            "high": 512.98,
            "low": 512.76,
            "close": 512.77,
            "volume": 168817,
            "datetime": 1712932920000
        },
        {
            "open": 512.77,
            "high": 512.81,
            "low": 512.45,
            "close": 512.695,
            "volume": 276622,
            "datetime": 1712932980000
        },
        {
            "open": 512.7,
            "high": 513,
            "low": 512.67,
            "close": 512.84,
            "volume": 165354,
            "datetime": 1712933040000
        },
        {
            "open": 512.84,
            "high": 512.95,
            "low": 512.6901,
            "close": 512.765,
            "volume": 120362,
            "datetime": 1712933100000
        },
        {
            "open": 512.75,
            "high": 512.83,
            "low": 512.725,
            "close": 512.76,
            "volume": 152453,
            "datetime": 1712933160000
        },
        {
            "open": 512.75,
            "high": 512.78,
            "low": 512.575,
            "close": 512.6593,
            "volume": 127317,
            "datetime": 1712933220000
        },
        {
            "open": 512.65,
            "high": 512.7,
            "low": 512.53,
            "close": 512.66,
            "volume": 94052,
            "datetime": 1712933280000
        },
        {
            "open": 512.66,
            "high": 512.665,
            "low": 512.46,
            "close": 512.54,
            "volume": 135756,
            "datetime": 1712933340000
        },
        {
            "open": 512.535,
            "high": 512.555,
            "low": 512.39,
            "close": 512.5,
            "volume": 162312,
            "datetime": 1712933400000
        },
        {
            "open": 512.495,
            "high": 512.5,
            "low": 512.34,
            "close": 512.375,
            "volume": 114876,
            "datetime": 1712933460000
        },
        {
            "open": 512.37,
            "high": 512.37,
            "low": 512.02,
            "close": 512.17,
            "volume": 249828,
            "datetime": 1712933520000
        },
        {
            "open": 512.174,
            "high": 512.41,
            "low": 512.14,
            "close": 512.34,
            "volume": 211144,
            "datetime": 1712933580000
        },
        {
            "open": 512.33,
            "high": 512.7,
            "low": 512.32,
            "close": 512.65,
            "volume": 213199,
            "datetime": 1712933640000
        },
        {
            "open": 512.64,
            "high": 512.76,
            "low": 512.59,
            "close": 512.68,
            "volume": 177095,
            "datetime": 1712933700000
        },
        {
            "open": 512.68,
            "high": 512.84,
            "low": 512.63,
            "close": 512.8301,
            "volume": 173265,
            "datetime": 1712933760000
        },
        {
            "open": 512.83,
            "high": 512.8991,
            "low": 512.74,
            "close": 512.87,
            "volume": 131749,
            "datetime": 1712933820000
        },
        {
            "open": 512.88,
            "high": 512.8999,
            "low": 512.59,
            "close": 512.6,
            "volume": 127766,
            "datetime": 1712933880000
        },
        {
            "open": 512.61,
            "high": 512.63,
            "low": 512.235,
            "close": 512.3101,
            "volume": 131490,
            "datetime": 1712933940000
        },
        {
            "open": 512.31,
            "high": 512.745,
            "low": 512.2,
            "close": 512.59,
            "volume": 320454,
            "datetime": 1712934000000
        },
        {
            "open": 512.59,
            "high": 512.96,
            "low": 512.52,
            "close": 512.855,
            "volume": 194578,
            "datetime": 1712934060000
        },
        {
            "open": 512.84,
            "high": 513.0401,
            "low": 512.75,
            "close": 512.99,
            "volume": 127344,
            "datetime": 1712934120000
        },
        {
            "open": 512.99,
            "high": 513.025,
            "low": 512.82,
            "close": 512.92,
            "volume": 79081,
            "datetime": 1712934180000
        },
        {
            "open": 512.92,
            "high": 513.055,
            "low": 512.85,
            "close": 512.985,
            "volume": 95340,
            "datetime": 1712934240000
        },
        {
            "open": 512.99,
            "high": 512.99,
            "low": 512.67,
            "close": 512.77,
            "volume": 139818,
            "datetime": 1712934300000
        },
        {
            "open": 512.77,
            "high": 512.9,
            "low": 512.74,
            "close": 512.83,
            "volume": 95591,
            "datetime": 1712934360000
        },
        {
            "open": 512.84,
            "high": 512.89,
            "low": 512.54,
            "close": 512.57,
            "volume": 127800,
            "datetime": 1712934420000
        },
        {
            "open": 512.57,
            "high": 512.7,
            "low": 512.53,
            "close": 512.55,
            "volume": 115535,
            "datetime": 1712934480000
        },
        {
            "open": 512.55,
            "high": 512.64,
            "low": 512.46,
            "close": 512.52,
            "volume": 155464,
            "datetime": 1712934540000
        },
        {
            "open": 512.52,
            "high": 512.615,
            "low": 512.39,
            "close": 512.54,
            "volume": 124076,
            "datetime": 1712934600000
        },
        {
            "open": 512.53,
            "high": 512.795,
            "low": 512.52,
            "close": 512.695,
            "volume": 128061,
            "datetime": 1712934660000
        },
        {
            "open": 512.69,
            "high": 512.86,
            "low": 512.67,
            "close": 512.81,
            "volume": 126366,
            "datetime": 1712934720000
        },
        {
            "open": 512.82,
            "high": 512.96,
            "low": 512.71,
            "close": 512.82,
            "volume": 130719,
            "datetime": 1712934780000
        },
        {
            "open": 512.82,
            "high": 512.88,
            "low": 512.67,
            "close": 512.725,
            "volume": 84475,
            "datetime": 1712934840000
        },
        {
            "open": 512.73,
            "high": 512.82,
            "low": 512.57,
            "close": 512.57,
            "volume": 127098,
            "datetime": 1712934900000
        },
        {
            "open": 512.57,
            "high": 512.89,
            "low": 512.56,
            "close": 512.88,
            "volume": 110079,
            "datetime": 1712934960000
        },
        {
            "open": 512.88,
            "high": 513.17,
            "low": 512.8,
            "close": 513.065,
            "volume": 158421,
            "datetime": 1712935020000
        },
        {
            "open": 513.04,
            "high": 513.4,
            "low": 513.04,
            "close": 513.3301,
            "volume": 141035,
            "datetime": 1712935080000
        },
        {
            "open": 513.33,
            "high": 513.335,
            "low": 513.19,
            "close": 513.27,
            "volume": 116907,
            "datetime": 1712935140000
        },
        {
            "open": 513.2784,
            "high": 513.37,
            "low": 513.26,
            "close": 513.355,
            "volume": 95226,
            "datetime": 1712935200000
        },
        {
            "open": 513.36,
            "high": 513.42,
            "low": 513.315,
            "close": 513.375,
            "volume": 92431,
            "datetime": 1712935260000
        },
        {
            "open": 513.38,
            "high": 513.395,
            "low": 513.17,
            "close": 513.17,
            "volume": 113760,
            "datetime": 1712935320000
        },
        {
            "open": 513.16,
            "high": 513.16,
            "low": 512.9118,
            "close": 512.93,
            "volume": 149404,
            "datetime": 1712935380000
        },
        {
            "open": 512.9251,
            "high": 513.12,
            "low": 512.92,
            "close": 513.1,
            "volume": 134259,
            "datetime": 1712935440000
        },
        {
            "open": 513.085,
            "high": 513.095,
            "low": 512.97,
            "close": 512.975,
            "volume": 93132,
            "datetime": 1712935500000
        },
        {
            "open": 512.98,
            "high": 513.1995,
            "low": 512.97,
            "close": 513.19,
            "volume": 50331,
            "datetime": 1712935560000
        },
        {
            "open": 513.18,
            "high": 513.306,
            "low": 513.135,
            "close": 513.28,
            "volume": 88097,
            "datetime": 1712935620000
        },
        {
            "open": 513.29,
            "high": 513.39,
            "low": 513.27,
            "close": 513.38,
            "volume": 98230,
            "datetime": 1712935680000
        },
        {
            "open": 513.4,
            "high": 513.54,
            "low": 513.33,
            "close": 513.53,
            "volume": 133698,
            "datetime": 1712935740000
        },
        {
            "open": 513.53,
            "high": 513.58,
            "low": 513.445,
            "close": 513.48,
            "volume": 95029,
            "datetime": 1712935800000
        },
        {
            "open": 513.4603,
            "high": 513.645,
            "low": 513.42,
            "close": 513.6201,
            "volume": 143486,
            "datetime": 1712935860000
        },
        {
            "open": 513.62,
            "high": 513.63,
            "low": 513.55,
            "close": 513.57,
            "volume": 51081,
            "datetime": 1712935920000
        },
        {
            "open": 513.5792,
            "high": 513.6,
            "low": 513.48,
            "close": 513.48,
            "volume": 64684,
            "datetime": 1712935980000
        },
        {
            "open": 513.47,
            "high": 513.81,
            "low": 513.47,
            "close": 513.78,
            "volume": 123375,
            "datetime": 1712936040000
        },
        {
            "open": 513.7725,
            "high": 514.03,
            "low": 513.65,
            "close": 513.83,
            "volume": 144515,
            "datetime": 1712936100000
        },
        {
            "open": 513.84,
            "high": 513.88,
            "low": 513.63,
            "close": 513.63,
            "volume": 69003,
            "datetime": 1712936160000
        },
        {
            "open": 513.63,
            "high": 513.71,
            "low": 513.53,
            "close": 513.58,
            "volume": 79031,
            "datetime": 1712936220000
        },
        {
            "open": 513.5764,
            "high": 513.6099,
            "low": 513.39,
            "close": 513.4,
            "volume": 97913,
            "datetime": 1712936280000
        },
        {
            "open": 513.41,
            "high": 513.54,
            "low": 513.385,
            "close": 513.435,
            "volume": 67380,
            "datetime": 1712936340000
        },
        {
            "open": 513.435,
            "high": 513.57,
            "low": 513.43,
            "close": 513.55,
            "volume": 62961,
            "datetime": 1712936400000
        },
        {
            "open": 513.54,
            "high": 513.54,
            "low": 513.27,
            "close": 513.3194,
            "volume": 78116,
            "datetime": 1712936460000
        },
        {
            "open": 513.3252,
            "high": 513.3799,
            "low": 513.17,
            "close": 513.17,
            "volume": 93620,
            "datetime": 1712936520000
        },
        {
            "open": 513.17,
            "high": 513.38,
            "low": 513.14,
            "close": 513.31,
            "volume": 86498,
            "datetime": 1712936580000
        },
        {
            "open": 513.31,
            "high": 513.58,
            "low": 513.31,
            "close": 513.54,
            "volume": 100602,
            "datetime": 1712936640000
        },
        {
            "open": 513.54,
            "high": 513.6,
            "low": 513.415,
            "close": 513.4601,
            "volume": 63210,
            "datetime": 1712936700000
        },
        {
            "open": 513.48,
            "high": 513.67,
            "low": 513.48,
            "close": 513.56,
            "volume": 89347,
            "datetime": 1712936760000
        },
        {
            "open": 513.56,
            "high": 513.84,
            "low": 513.535,
            "close": 513.82,
            "volume": 67111,
            "datetime": 1712936820000
        },
        {
            "open": 513.84,
            "high": 514,
            "low": 513.81,
            "close": 513.91,
            "volume": 107032,
            "datetime": 1712936880000
        },
        {
            "open": 513.91,
            "high": 513.98,
            "low": 513.86,
            "close": 513.91,
            "volume": 64880,
            "datetime": 1712936940000
        },
        {
            "open": 513.91,
            "high": 513.99,
            "low": 513.465,
            "close": 513.52,
            "volume": 122525,
            "datetime": 1712937000000
        },
        {
            "open": 513.5,
            "high": 513.69,
            "low": 513.5,
            "close": 513.58,
            "volume": 93393,
            "datetime": 1712937060000
        },
        {
            "open": 513.6,
            "high": 513.6,
            "low": 513.46,
            "close": 513.46,
            "volume": 71996,
            "datetime": 1712937120000
        },
        {
            "open": 513.47,
            "high": 513.59,
            "low": 513.41,
            "close": 513.58,
            "volume": 49077,
            "datetime": 1712937180000
        },
        {
            "open": 513.57,
            "high": 513.57,
            "low": 513.34,
            "close": 513.5,
            "volume": 97118,
            "datetime": 1712937240000
        },
        {
            "open": 513.51,
            "high": 513.57,
            "low": 513.44,
            "close": 513.54,
            "volume": 74038,
            "datetime": 1712937300000
        },
        {
            "open": 513.5399,
            "high": 513.57,
            "low": 513.32,
            "close": 513.34,
            "volume": 58135,
            "datetime": 1712937360000
        },
        {
            "open": 513.36,
            "high": 513.63,
            "low": 513.34,
            "close": 513.5809,
            "volume": 64130,
            "datetime": 1712937420000
        },
        {
            "open": 513.59,
            "high": 513.69,
            "low": 513.51,
            "close": 513.6,
            "volume": 123957,
            "datetime": 1712937480000
        },
        {
            "open": 513.609,
            "high": 513.61,
            "low": 513.395,
            "close": 513.4,
            "volume": 57331,
            "datetime": 1712937540000
        },
        {
            "open": 513.43,
            "high": 513.475,
            "low": 513.28,
            "close": 513.33,
            "volume": 75924,
            "datetime": 1712937600000
        },
        {
            "open": 513.33,
            "high": 513.365,
            "low": 513.18,
            "close": 513.27,
            "volume": 64450,
            "datetime": 1712937660000
        },
        {
            "open": 513.28,
            "high": 513.31,
            "low": 512.96,
            "close": 513.0318,
            "volume": 95315,
            "datetime": 1712937720000
        },
        {
            "open": 513.0493,
            "high": 513.33,
            "low": 512.99,
            "close": 513.24,
            "volume": 78889,
            "datetime": 1712937780000
        },
        {
            "open": 513.25,
            "high": 513.26,
            "low": 513.06,
            "close": 513.1296,
            "volume": 76071,
            "datetime": 1712937840000
        },
        {
            "open": 513.13,
            "high": 513.39,
            "low": 513.13,
            "close": 513.33,
            "volume": 79274,
            "datetime": 1712937900000
        },
        {
            "open": 513.33,
            "high": 513.53,
            "low": 513.24,
            "close": 513.47,
            "volume": 96893,
            "datetime": 1712937960000
        },
        {
            "open": 513.45,
            "high": 513.46,
            "low": 513.15,
            "close": 513.17,
            "volume": 59203,
            "datetime": 1712938020000
        },
        {
            "open": 513.18,
            "high": 513.375,
            "low": 513.18,
            "close": 513.27,
            "volume": 62456,
            "datetime": 1712938080000
        },
        {
            "open": 513.27,
            "high": 513.27,
            "low": 513.15,
            "close": 513.16,
            "volume": 38954,
            "datetime": 1712938140000
        },
        {
            "open": 513.17,
            "high": 513.23,
            "low": 513.05,
            "close": 513.13,
            "volume": 87847,
            "datetime": 1712938200000
        },
        {
            "open": 513.14,
            "high": 513.155,
            "low": 512.8,
            "close": 512.93,
            "volume": 124367,
            "datetime": 1712938260000
        },
        {
            "open": 512.9363,
            "high": 513.09,
            "low": 512.92,
            "close": 512.92,
            "volume": 101863,
            "datetime": 1712938320000
        },
        {
            "open": 512.91,
            "high": 512.92,
            "low": 512.645,
            "close": 512.75,
            "volume": 121979,
            "datetime": 1712938380000
        },
        {
            "open": 512.765,
            "high": 512.78,
            "low": 512.64,
            "close": 512.64,
            "volume": 101064,
            "datetime": 1712938440000
        },
        {
            "open": 512.64,
            "high": 512.8379,
            "low": 512.63,
            "close": 512.655,
            "volume": 125140,
            "datetime": 1712938500000
        },
        {
            "open": 512.65,
            "high": 512.78,
            "low": 512.5918,
            "close": 512.61,
            "volume": 94521,
            "datetime": 1712938560000
        },
        {
            "open": 512.595,
            "high": 512.64,
            "low": 512.295,
            "close": 512.38,
            "volume": 206433,
            "datetime": 1712938620000
        },
        {
            "open": 512.39,
            "high": 512.52,
            "low": 512.3303,
            "close": 512.3303,
            "volume": 100701,
            "datetime": 1712938680000
        },
        {
            "open": 512.34,
            "high": 512.3804,
            "low": 511.79,
            "close": 511.85,
            "volume": 352496,
            "datetime": 1712938740000
        },
        {
            "open": 511.85,
            "high": 512.19,
            "low": 511.84,
            "close": 512.19,
            "volume": 206226,
            "datetime": 1712938800000
        },
        {
            "open": 512.2,
            "high": 512.47,
            "low": 512.05,
            "close": 512.465,
            "volume": 284837,
            "datetime": 1712938860000
        },
        {
            "open": 512.47,
            "high": 512.4933,
            "low": 512.38,
            "close": 512.42,
            "volume": 121907,
            "datetime": 1712938920000
        },
        {
            "open": 512.435,
            "high": 512.445,
            "low": 512.19,
            "close": 512.21,
            "volume": 99649,
            "datetime": 1712938980000
        },
        {
            "open": 512.19,
            "high": 512.3948,
            "low": 512.12,
            "close": 512.32,
            "volume": 97334,
            "datetime": 1712939040000
        },
        {
            "open": 512.33,
            "high": 512.55,
            "low": 512.2999,
            "close": 512.44,
            "volume": 126191,
            "datetime": 1712939100000
        },
        {
            "open": 512.445,
            "high": 512.445,
            "low": 512.02,
            "close": 512.12,
            "volume": 170370,
            "datetime": 1712939160000
        },
        {
            "open": 512.12,
            "high": 512.14,
            "low": 512.01,
            "close": 512.07,
            "volume": 141906,
            "datetime": 1712939220000
        },
        {
            "open": 512.06,
            "high": 512.13,
            "low": 511.96,
            "close": 511.99,
            "volume": 213946,
            "datetime": 1712939280000
        },
        {
            "open": 511.99,
            "high": 512.01,
            "low": 511.76,
            "close": 511.82,
            "volume": 178708,
            "datetime": 1712939340000
        },
        {
            "open": 511.8,
            "high": 512.02,
            "low": 511.8,
            "close": 511.92,
            "volume": 114448,
            "datetime": 1712939400000
        },
        {
            "open": 511.92,
            "high": 511.92,
            "low": 511.56,
            "close": 511.66,
            "volume": 202645,
            "datetime": 1712939460000
        },
        {
            "open": 511.6591,
            "high": 511.6591,
            "low": 511.29,
            "close": 511.31,
            "volume": 202900,
            "datetime": 1712939520000
        },
        {
            "open": 511.33,
            "high": 511.54,
            "low": 511.27,
            "close": 511.4399,
            "volume": 165193,
            "datetime": 1712939580000
        },
        {
            "open": 511.44,
            "high": 511.75,
            "low": 511.42,
            "close": 511.59,
            "volume": 153291,
            "datetime": 1712939640000
        },
        {
            "open": 511.59,
            "high": 511.71,
            "low": 511.37,
            "close": 511.41,
            "volume": 137310,
            "datetime": 1712939700000
        },
        {
            "open": 511.41,
            "high": 511.51,
            "low": 511.36,
            "close": 511.3876,
            "volume": 157536,
            "datetime": 1712939760000
        },
        {
            "open": 511.39,
            "high": 511.405,
            "low": 511.085,
            "close": 511.11,
            "volume": 264682,
            "datetime": 1712939820000
        },
        {
            "open": 511.11,
            "high": 511.12,
            "low": 510.6175,
            "close": 510.7999,
            "volume": 307981,
            "datetime": 1712939880000
        },
        {
            "open": 510.79,
            "high": 510.95,
            "low": 510.57,
            "close": 510.925,
            "volume": 305635,
            "datetime": 1712939940000
        },
        {
            "open": 510.9299,
            "high": 511.0701,
            "low": 510.81,
            "close": 510.8203,
            "volume": 252654,
            "datetime": 1712940000000
        },
        {
            "open": 510.82,
            "high": 511.02,
            "low": 510.72,
            "close": 510.79,
            "volume": 173945,
            "datetime": 1712940060000
        },
        {
            "open": 510.79,
            "high": 510.96,
            "low": 510.73,
            "close": 510.85,
            "volume": 157958,
            "datetime": 1712940120000
        },
        {
            "open": 510.84,
            "high": 510.945,
            "low": 510.8,
            "close": 510.815,
            "volume": 137263,
            "datetime": 1712940180000
        },
        {
            "open": 510.805,
            "high": 510.83,
            "low": 510.62,
            "close": 510.69,
            "volume": 200967,
            "datetime": 1712940240000
        },
        {
            "open": 510.69,
            "high": 510.84,
            "low": 510.68,
            "close": 510.84,
            "volume": 105986,
            "datetime": 1712940300000
        },
        {
            "open": 510.83,
            "high": 510.99,
            "low": 510.66,
            "close": 510.66,
            "volume": 200975,
            "datetime": 1712940360000
        },
        {
            "open": 510.66,
            "high": 510.74,
            "low": 510.48,
            "close": 510.65,
            "volume": 247558,
            "datetime": 1712940420000
        },
        {
            "open": 510.66,
            "high": 510.78,
            "low": 510.615,
            "close": 510.72,
            "volume": 129005,
            "datetime": 1712940480000
        },
        {
            "open": 510.73,
            "high": 510.73,
            "low": 510.51,
            "close": 510.56,
            "volume": 107773,
            "datetime": 1712940540000
        },
        {
            "open": 510.56,
            "high": 510.957,
            "low": 510.56,
            "close": 510.92,
            "volume": 209849,
            "datetime": 1712940600000
        },
        {
            "open": 510.915,
            "high": 510.99,
            "low": 510.77,
            "close": 510.85,
            "volume": 150565,
            "datetime": 1712940660000
        },
        {
            "open": 510.84,
            "high": 511.075,
            "low": 510.81,
            "close": 510.9699,
            "volume": 182115,
            "datetime": 1712940720000
        },
        {
            "open": 510.96,
            "high": 510.96,
            "low": 510.76,
            "close": 510.8599,
            "volume": 96216,
            "datetime": 1712940780000
        },
        {
            "open": 510.85,
            "high": 510.93,
            "low": 510.78,
            "close": 510.85,
            "volume": 89997,
            "datetime": 1712940840000
        },
        {
            "open": 510.79,
            "high": 510.92,
            "low": 510.73,
            "close": 510.79,
            "volume": 82118,
            "datetime": 1712940900000
        },
        {
            "open": 510.82,
            "high": 510.98,
            "low": 510.7401,
            "close": 510.93,
            "volume": 98949,
            "datetime": 1712940960000
        },
        {
            "open": 510.89,
            "high": 510.895,
            "low": 510.69,
            "close": 510.79,
            "volume": 113083,
            "datetime": 1712941020000
        },
        {
            "open": 510.78,
            "high": 510.84,
            "low": 510.73,
            "close": 510.77,
            "volume": 98494,
            "datetime": 1712941080000
        },
        {
            "open": 510.77,
            "high": 510.84,
            "low": 510.56,
            "close": 510.57,
            "volume": 105860,
            "datetime": 1712941140000
        },
        {
            "open": 510.58,
            "high": 510.7,
            "low": 510.5606,
            "close": 510.7,
            "volume": 124714,
            "datetime": 1712941200000
        },
        {
            "open": 510.68,
            "high": 510.82,
            "low": 510.6103,
            "close": 510.64,
            "volume": 72893,
            "datetime": 1712941260000
        },
        {
            "open": 510.63,
            "high": 510.63,
            "low": 510.18,
            "close": 510.4198,
            "volume": 281510,
            "datetime": 1712941320000
        },
        {
            "open": 510.4,
            "high": 510.53,
            "low": 510.31,
            "close": 510.365,
            "volume": 111983,
            "datetime": 1712941380000
        },
        {
            "open": 510.36,
            "high": 510.49,
            "low": 510.26,
            "close": 510.425,
            "volume": 140393,
            "datetime": 1712941440000
        },
        {
            "open": 510.43,
            "high": 510.6612,
            "low": 510.43,
            "close": 510.51,
            "volume": 190591,
            "datetime": 1712941500000
        },
        {
            "open": 510.5,
            "high": 510.77,
            "low": 510.47,
            "close": 510.72,
            "volume": 133482,
            "datetime": 1712941560000
        },
        {
            "open": 510.71,
            "high": 510.72,
            "low": 510.53,
            "close": 510.5488,
            "volume": 131041,
            "datetime": 1712941620000
        },
        {
            "open": 510.54,
            "high": 510.66,
            "low": 510.41,
            "close": 510.4196,
            "volume": 154341,
            "datetime": 1712941680000
        },
        {
            "open": 510.415,
            "high": 510.45,
            "low": 510.03,
            "close": 510.26,
            "volume": 232255,
            "datetime": 1712941740000
        },
        {
            "open": 510.275,
            "high": 510.59,
            "low": 510.26,
            "close": 510.52,
            "volume": 209294,
            "datetime": 1712941800000
        },
        {
            "open": 510.52,
            "high": 510.65,
            "low": 510.38,
            "close": 510.48,
            "volume": 121665,
            "datetime": 1712941860000
        },
        {
            "open": 510.47,
            "high": 510.61,
            "low": 510.4203,
            "close": 510.53,
            "volume": 68010,
            "datetime": 1712941920000
        },
        {
            "open": 510.53,
            "high": 510.67,
            "low": 510.5,
            "close": 510.63,
            "volume": 75891,
            "datetime": 1712941980000
        },
        {
            "open": 510.62,
            "high": 510.72,
            "low": 510.56,
            "close": 510.5698,
            "volume": 126271,
            "datetime": 1712942040000
        },
        {
            "open": 510.56,
            "high": 511.09,
            "low": 510.54,
            "close": 511.02,
            "volume": 230705,
            "datetime": 1712942100000
        },
        {
            "open": 511.01,
            "high": 511.02,
            "low": 510.9,
            "close": 510.96,
            "volume": 124418,
            "datetime": 1712942160000
        },
        {
            "open": 510.94,
            "high": 511.07,
            "low": 510.81,
            "close": 510.88,
            "volume": 92107,
            "datetime": 1712942220000
        },
        {
            "open": 510.89,
            "high": 510.9497,
            "low": 510.76,
            "close": 510.8151,
            "volume": 100454,
            "datetime": 1712942280000
        },
        {
            "open": 510.825,
            "high": 510.875,
            "low": 510.4,
            "close": 510.44,
            "volume": 166983,
            "datetime": 1712942340000
        },
        {
            "open": 510.43,
            "high": 510.45,
            "low": 510.16,
            "close": 510.28,
            "volume": 158049,
            "datetime": 1712942400000
        },
        {
            "open": 510.27,
            "high": 510.3,
            "low": 510.01,
            "close": 510.09,
            "volume": 229892,
            "datetime": 1712942460000
        },
        {
            "open": 510.08,
            "high": 510.1,
            "low": 509.865,
            "close": 509.9,
            "volume": 319641,
            "datetime": 1712942520000
        },
        {
            "open": 509.89,
            "high": 510.02,
            "low": 509.805,
            "close": 509.93,
            "volume": 135209,
            "datetime": 1712942580000
        },
        {
            "open": 509.92,
            "high": 509.96,
            "low": 509.75,
            "close": 509.815,
            "volume": 122227,
            "datetime": 1712942640000
        },
        {
            "open": 509.82,
            "high": 510.06,
            "low": 509.805,
            "close": 509.9799,
            "volume": 158918,
            "datetime": 1712942700000
        },
        {
            "open": 509.975,
            "high": 510.025,
            "low": 509.74,
            "close": 509.74,
            "volume": 83781,
            "datetime": 1712942760000
        },
        {
            "open": 509.745,
            "high": 509.89,
            "low": 509.725,
            "close": 509.775,
            "volume": 175996,
            "datetime": 1712942820000
        },
        {
            "open": 509.78,
            "high": 509.84,
            "low": 509.555,
            "close": 509.6,
            "volume": 151174,
            "datetime": 1712942880000
        },
        {
            "open": 509.6025,
            "high": 509.75,
            "low": 509.47,
            "close": 509.7,
            "volume": 207196,
            "datetime": 1712942940000
        },
        {
            "open": 509.69,
            "high": 509.695,
            "low": 509.42,
            "close": 509.515,
            "volume": 141940,
            "datetime": 1712943000000
        },
        {
            "open": 509.52,
            "high": 509.649,
            "low": 509.375,
            "close": 509.385,
            "volume": 209365,
            "datetime": 1712943060000
        },
        {
            "open": 509.38,
            "high": 509.5,
            "low": 509.32,
            "close": 509.39,
            "volume": 154466,
            "datetime": 1712943120000
        },
        {
            "open": 509.395,
            "high": 509.63,
            "low": 509.38,
            "close": 509.52,
            "volume": 127872,
            "datetime": 1712943180000
        },
        {
            "open": 509.51,
            "high": 509.54,
            "low": 509.42,
            "close": 509.46,
            "volume": 52499,
            "datetime": 1712943240000
        },
        {
            "open": 509.455,
            "high": 509.58,
            "low": 509.405,
            "close": 509.42,
            "volume": 87895,
            "datetime": 1712943300000
        },
        {
            "open": 509.42,
            "high": 509.5,
            "low": 509.34,
            "close": 509.49,
            "volume": 178251,
            "datetime": 1712943360000
        },
        {
            "open": 509.48,
            "high": 509.58,
            "low": 509.4171,
            "close": 509.495,
            "volume": 80289,
            "datetime": 1712943420000
        },
        {
            "open": 509.495,
            "high": 509.495,
            "low": 509.335,
            "close": 509.46,
            "volume": 115900,
            "datetime": 1712943480000
        },
        {
            "open": 509.45,
            "high": 509.68,
            "low": 509.4,
            "close": 509.63,
            "volume": 114462,
            "datetime": 1712943540000
        },
        {
            "open": 509.62,
            "high": 509.9,
            "low": 509.6,
            "close": 509.765,
            "volume": 270205,
            "datetime": 1712943600000
        },
        {
            "open": 509.77,
            "high": 509.965,
            "low": 509.7407,
            "close": 509.945,
            "volume": 112503,
            "datetime": 1712943660000
        },
        {
            "open": 509.945,
            "high": 510.22,
            "low": 509.855,
            "close": 510.1817,
            "volume": 182217,
            "datetime": 1712943720000
        },
        {
            "open": 510.19,
            "high": 510.53,
            "low": 510.15,
            "close": 510.47,
            "volume": 247970,
            "datetime": 1712943780000
        },
        {
            "open": 510.4601,
            "high": 510.485,
            "low": 510.24,
            "close": 510.305,
            "volume": 214665,
            "datetime": 1712943840000
        },
        {
            "open": 510.31,
            "high": 510.4796,
            "low": 510.28,
            "close": 510.41,
            "volume": 133073,
            "datetime": 1712943900000
        },
        {
            "open": 510.42,
            "high": 510.66,
            "low": 510.35,
            "close": 510.6251,
            "volume": 113574,
            "datetime": 1712943960000
        },
        {
            "open": 510.62,
            "high": 511.0404,
            "low": 510.62,
            "close": 510.9,
            "volume": 305902,
            "datetime": 1712944020000
        },
        {
            "open": 510.9,
            "high": 511.12,
            "low": 510.82,
            "close": 511.0097,
            "volume": 246323,
            "datetime": 1712944080000
        },
        {
            "open": 511.01,
            "high": 511.165,
            "low": 510.85,
            "close": 510.905,
            "volume": 190746,
            "datetime": 1712944140000
        },
        {
            "open": 510.93,
            "high": 511.25,
            "low": 510.91,
            "close": 511.14,
            "volume": 140403,
            "datetime": 1712944200000
        },
        {
            "open": 511.15,
            "high": 511.195,
            "low": 510.69,
            "close": 510.7299,
            "volume": 168753,
            "datetime": 1712944260000
        },
        {
            "open": 510.72,
            "high": 510.87,
            "low": 510.61,
            "close": 510.68,
            "volume": 146880,
            "datetime": 1712944320000
        },
        {
            "open": 510.67,
            "high": 510.96,
            "low": 510.655,
            "close": 510.74,
            "volume": 87956,
            "datetime": 1712944380000
        },
        {
            "open": 510.74,
            "high": 510.76,
            "low": 510.5,
            "close": 510.59,
            "volume": 130484,
            "datetime": 1712944440000
        },
        {
            "open": 510.6,
            "high": 510.79,
            "low": 510.56,
            "close": 510.74,
            "volume": 111834,
            "datetime": 1712944500000
        },
        {
            "open": 510.71,
            "high": 510.8,
            "low": 510.62,
            "close": 510.76,
            "volume": 66737,
            "datetime": 1712944560000
        },
        {
            "open": 510.77,
            "high": 510.97,
            "low": 510.68,
            "close": 510.68,
            "volume": 117278,
            "datetime": 1712944620000
        },
        {
            "open": 510.67,
            "high": 510.67,
            "low": 510.475,
            "close": 510.51,
            "volume": 148562,
            "datetime": 1712944680000
        },
        {
            "open": 510.52,
            "high": 510.5994,
            "low": 510.46,
            "close": 510.49,
            "volume": 86431,
            "datetime": 1712944740000
        },
        {
            "open": 510.49,
            "high": 510.49,
            "low": 510.26,
            "close": 510.4,
            "volume": 207114,
            "datetime": 1712944800000
        },
        {
            "open": 510.39,
            "high": 510.395,
            "low": 509.96,
            "close": 510.07,
            "volume": 155888,
            "datetime": 1712944860000
        },
        {
            "open": 510.09,
            "high": 510.21,
            "low": 509.975,
            "close": 510.07,
            "volume": 82942,
            "datetime": 1712944920000
        },
        {
            "open": 510.07,
            "high": 510.2101,
            "low": 510.07,
            "close": 510.14,
            "volume": 102455,
            "datetime": 1712944980000
        },
        {
            "open": 510.13,
            "high": 510.45,
            "low": 510.085,
            "close": 510.41,
            "volume": 88998,
            "datetime": 1712945040000
        },
        {
            "open": 510.4,
            "high": 510.93,
            "low": 510.34,
            "close": 510.87,
            "volume": 183234,
            "datetime": 1712945100000
        },
        {
            "open": 510.87,
            "high": 510.91,
            "low": 510.685,
            "close": 510.795,
            "volume": 96573,
            "datetime": 1712945160000
        },
        {
            "open": 510.79,
            "high": 511.13,
            "low": 510.76,
            "close": 511.08,
            "volume": 106703,
            "datetime": 1712945220000
        },
        {
            "open": 511.08,
            "high": 511.4,
            "low": 511.08,
            "close": 511.4,
            "volume": 167164,
            "datetime": 1712945280000
        },
        {
            "open": 511.4,
            "high": 511.4,
            "low": 510.98,
            "close": 511.1806,
            "volume": 141657,
            "datetime": 1712945340000
        },
        {
            "open": 511.18,
            "high": 511.2,
            "low": 510.995,
            "close": 511.1651,
            "volume": 94618,
            "datetime": 1712945400000
        },
        {
            "open": 511.16,
            "high": 511.415,
            "low": 511.16,
            "close": 511.38,
            "volume": 149626,
            "datetime": 1712945460000
        },
        {
            "open": 511.37,
            "high": 511.415,
            "low": 510.87,
            "close": 510.87,
            "volume": 237569,
            "datetime": 1712945520000
        },
        {
            "open": 510.86,
            "high": 511.18,
            "low": 510.79,
            "close": 511.02,
            "volume": 145944,
            "datetime": 1712945580000
        },
        {
            "open": 511.02,
            "high": 511.05,
            "low": 510.83,
            "close": 510.86,
            "volume": 125336,
            "datetime": 1712945640000
        },
        {
            "open": 510.86,
            "high": 511.12,
            "low": 510.78,
            "close": 511.09,
            "volume": 110777,
            "datetime": 1712945700000
        },
        {
            "open": 511.09,
            "high": 511.29,
            "low": 511.0895,
            "close": 511.2,
            "volume": 104186,
            "datetime": 1712945760000
        },
        {
            "open": 511.21,
            "high": 511.33,
            "low": 511.18,
            "close": 511.215,
            "volume": 87679,
            "datetime": 1712945820000
        },
        {
            "open": 511.22,
            "high": 511.27,
            "low": 511,
            "close": 511.11,
            "volume": 114784,
            "datetime": 1712945880000
        },
        {
            "open": 511.08,
            "high": 511.24,
            "low": 510.94,
            "close": 510.988,
            "volume": 103944,
            "datetime": 1712945940000
        },
        {
            "open": 510.98,
            "high": 511.18,
            "low": 510.98,
            "close": 511.085,
            "volume": 87172,
            "datetime": 1712946000000
        },
        {
            "open": 511.06,
            "high": 511.06,
            "low": 510.84,
            "close": 510.9983,
            "volume": 102845,
            "datetime": 1712946060000
        },
        {
            "open": 510.985,
            "high": 511.1799,
            "low": 510.96,
            "close": 511.1588,
            "volume": 33969,
            "datetime": 1712946120000
        },
        {
            "open": 511.155,
            "high": 511.19,
            "low": 510.885,
            "close": 510.93,
            "volume": 82540,
            "datetime": 1712946180000
        },
        {
            "open": 510.93,
            "high": 510.93,
            "low": 510.58,
            "close": 510.5903,
            "volume": 135250,
            "datetime": 1712946240000
        },
        {
            "open": 510.595,
            "high": 510.73,
            "low": 510.455,
            "close": 510.56,
            "volume": 222462,
            "datetime": 1712946300000
        },
        {
            "open": 510.58,
            "high": 510.68,
            "low": 510.5,
            "close": 510.6,
            "volume": 142198,
            "datetime": 1712946360000
        },
        {
            "open": 510.61,
            "high": 510.69,
            "low": 510.29,
            "close": 510.31,
            "volume": 112489,
            "datetime": 1712946420000
        },
        {
            "open": 510.32,
            "high": 510.65,
            "low": 510.32,
            "close": 510.5,
            "volume": 195972,
            "datetime": 1712946480000
        },
        {
            "open": 510.51,
            "high": 510.7213,
            "low": 510.46,
            "close": 510.67,
            "volume": 90067,
            "datetime": 1712946540000
        },
        {
            "open": 510.68,
            "high": 510.74,
            "low": 510.38,
            "close": 510.4,
            "volume": 101856,
            "datetime": 1712946600000
        },
        {
            "open": 510.415,
            "high": 510.52,
            "low": 510.33,
            "close": 510.48,
            "volume": 68100,
            "datetime": 1712946660000
        },
        {
            "open": 510.46,
            "high": 510.47,
            "low": 510.01,
            "close": 510.245,
            "volume": 158623,
            "datetime": 1712946720000
        },
        {
            "open": 510.24,
            "high": 510.25,
            "low": 510.09,
            "close": 510.13,
            "volume": 89834,
            "datetime": 1712946780000
        },
        {
            "open": 510.13,
            "high": 510.42,
            "low": 510.11,
            "close": 510.25,
            "volume": 95851,
            "datetime": 1712946840000
        },
        {
            "open": 510.24,
            "high": 510.42,
            "low": 510.19,
            "close": 510.26,
            "volume": 77098,
            "datetime": 1712946900000
        },
        {
            "open": 510.26,
            "high": 510.285,
            "low": 510.115,
            "close": 510.2,
            "volume": 111511,
            "datetime": 1712946960000
        },
        {
            "open": 510.2,
            "high": 510.21,
            "low": 509.83,
            "close": 509.96,
            "volume": 161533,
            "datetime": 1712947020000
        },
        {
            "open": 509.99,
            "high": 510.03,
            "low": 509.77,
            "close": 509.92,
            "volume": 165639,
            "datetime": 1712947080000
        },
        {
            "open": 509.89,
            "high": 509.99,
            "low": 509.76,
            "close": 509.835,
            "volume": 123322,
            "datetime": 1712947140000
        },
        {
            "open": 509.81,
            "high": 509.89,
            "low": 509.64,
            "close": 509.71,
            "volume": 172886,
            "datetime": 1712947200000
        },
        {
            "open": 509.71,
            "high": 509.93,
            "low": 509.655,
            "close": 509.78,
            "volume": 100067,
            "datetime": 1712947260000
        },
        {
            "open": 509.79,
            "high": 510.45,
            "low": 509.785,
            "close": 510.26,
            "volume": 292131,
            "datetime": 1712947320000
        },
        {
            "open": 510.27,
            "high": 510.34,
            "low": 509.94,
            "close": 510.0493,
            "volume": 118008,
            "datetime": 1712947380000
        },
        {
            "open": 510,
            "high": 510.08,
            "low": 509.9,
            "close": 509.945,
            "volume": 233466,
            "datetime": 1712947440000
        },
        {
            "open": 509.95,
            "high": 510.14,
            "low": 509.86,
            "close": 509.91,
            "volume": 93994,
            "datetime": 1712947500000
        },
        {
            "open": 509.9,
            "high": 510.17,
            "low": 509.8401,
            "close": 510.12,
            "volume": 58465,
            "datetime": 1712947560000
        },
        {
            "open": 510.12,
            "high": 510.12,
            "low": 509.84,
            "close": 509.94,
            "volume": 88153,
            "datetime": 1712947620000
        },
        {
            "open": 509.93,
            "high": 510.05,
            "low": 509.76,
            "close": 509.96,
            "volume": 100808,
            "datetime": 1712947680000
        },
        {
            "open": 509.97,
            "high": 510,
            "low": 509.721,
            "close": 509.8185,
            "volume": 80875,
            "datetime": 1712947740000
        },
        {
            "open": 509.83,
            "high": 510.02,
            "low": 509.81,
            "close": 510.0183,
            "volume": 77804,
            "datetime": 1712947800000
        },
        {
            "open": 510.01,
            "high": 510.18,
            "low": 510.01,
            "close": 510.14,
            "volume": 90146,
            "datetime": 1712947860000
        },
        {
            "open": 510.14,
            "high": 510.25,
            "low": 510.06,
            "close": 510.12,
            "volume": 105251,
            "datetime": 1712947920000
        },
        {
            "open": 510.14,
            "high": 510.32,
            "low": 510.14,
            "close": 510.236,
            "volume": 86073,
            "datetime": 1712947980000
        },
        {
            "open": 510.23,
            "high": 510.38,
            "low": 510.03,
            "close": 510.17,
            "volume": 111949,
            "datetime": 1712948040000
        },
        {
            "open": 510.1699,
            "high": 510.22,
            "low": 510.04,
            "close": 510.1,
            "volume": 143922,
            "datetime": 1712948100000
        },
        {
            "open": 510.09,
            "high": 510.29,
            "low": 510.01,
            "close": 510.0688,
            "volume": 173899,
            "datetime": 1712948160000
        },
        {
            "open": 510.06,
            "high": 510.13,
            "low": 509.89,
            "close": 510,
            "volume": 84680,
            "datetime": 1712948220000
        },
        {
            "open": 509.99,
            "high": 510.001,
            "low": 509.65,
            "close": 509.655,
            "volume": 190948,
            "datetime": 1712948280000
        },
        {
            "open": 509.65,
            "high": 509.745,
            "low": 509.56,
            "close": 509.625,
            "volume": 134601,
            "datetime": 1712948340000
        },
        {
            "open": 509.62,
            "high": 509.62,
            "low": 509.22,
            "close": 509.45,
            "volume": 181319,
            "datetime": 1712948400000
        },
        {
            "open": 509.42,
            "high": 509.5399,
            "low": 509.33,
            "close": 509.4212,
            "volume": 144605,
            "datetime": 1712948460000
        },
        {
            "open": 509.42,
            "high": 509.59,
            "low": 509.36,
            "close": 509.58,
            "volume": 155112,
            "datetime": 1712948520000
        },
        {
            "open": 509.57,
            "high": 509.57,
            "low": 509.37,
            "close": 509.5,
            "volume": 111114,
            "datetime": 1712948580000
        },
        {
            "open": 509.5,
            "high": 509.79,
            "low": 509.5,
            "close": 509.685,
            "volume": 167190,
            "datetime": 1712948640000
        },
        {
            "open": 509.685,
            "high": 509.75,
            "low": 509.45,
            "close": 509.5,
            "volume": 107747,
            "datetime": 1712948700000
        },
        {
            "open": 509.5,
            "high": 509.93,
            "low": 509.5,
            "close": 509.62,
            "volume": 236122,
            "datetime": 1712948760000
        },
        {
            "open": 509.625,
            "high": 509.75,
            "low": 509.575,
            "close": 509.7,
            "volume": 75341,
            "datetime": 1712948820000
        },
        {
            "open": 509.6979,
            "high": 509.75,
            "low": 509.36,
            "close": 509.39,
            "volume": 131885,
            "datetime": 1712948880000
        },
        {
            "open": 509.39,
            "high": 509.56,
            "low": 509.33,
            "close": 509.555,
            "volume": 117303,
            "datetime": 1712948940000
        },
        {
            "open": 509.56,
            "high": 509.61,
            "low": 509.44,
            "close": 509.49,
            "volume": 98702,
            "datetime": 1712949000000
        },
        {
            "open": 509.5,
            "high": 509.78,
            "low": 509.49,
            "close": 509.73,
            "volume": 129347,
            "datetime": 1712949060000
        },
        {
            "open": 509.715,
            "high": 509.78,
            "low": 509.515,
            "close": 509.535,
            "volume": 109293,
            "datetime": 1712949120000
        },
        {
            "open": 509.53,
            "high": 509.545,
            "low": 509.31,
            "close": 509.4492,
            "volume": 142734,
            "datetime": 1712949180000
        },
        {
            "open": 509.44,
            "high": 509.45,
            "low": 509.31,
            "close": 509.32,
            "volume": 91555,
            "datetime": 1712949240000
        },
        {
            "open": 509.34,
            "high": 509.35,
            "low": 509.15,
            "close": 509.24,
            "volume": 214019,
            "datetime": 1712949300000
        },
        {
            "open": 509.24,
            "high": 509.4,
            "low": 509.2103,
            "close": 509.22,
            "volume": 132852,
            "datetime": 1712949360000
        },
        {
            "open": 509.2299,
            "high": 509.45,
            "low": 509.08,
            "close": 509.13,
            "volume": 111888,
            "datetime": 1712949420000
        },
        {
            "open": 509.16,
            "high": 509.4,
            "low": 509.1599,
            "close": 509.4,
            "volume": 152779,
            "datetime": 1712949480000
        },
        {
            "open": 509.4,
            "high": 509.82,
            "low": 509.4,
            "close": 509.5993,
            "volume": 251799,
            "datetime": 1712949540000
        },
        {
            "open": 509.59,
            "high": 509.88,
            "low": 509.5699,
            "close": 509.79,
            "volume": 163710,
            "datetime": 1712949600000
        },
        {
            "open": 509.8,
            "high": 509.905,
            "low": 509.631,
            "close": 509.79,
            "volume": 173648,
            "datetime": 1712949660000
        },
        {
            "open": 509.8002,
            "high": 509.8002,
            "low": 509.51,
            "close": 509.72,
            "volume": 117691,
            "datetime": 1712949720000
        },
        {
            "open": 509.72,
            "high": 509.825,
            "low": 509.67,
            "close": 509.825,
            "volume": 69763,
            "datetime": 1712949780000
        },
        {
            "open": 509.83,
            "high": 509.97,
            "low": 509.805,
            "close": 509.845,
            "volume": 169649,
            "datetime": 1712949840000
        },
        {
            "open": 509.855,
            "high": 510.08,
            "low": 509.8299,
            "close": 509.95,
            "volume": 188145,
            "datetime": 1712949900000
        },
        {
            "open": 509.955,
            "high": 509.965,
            "low": 509.63,
            "close": 509.76,
            "volume": 200727,
            "datetime": 1712949960000
        },
        {
            "open": 509.76,
            "high": 509.87,
            "low": 509.655,
            "close": 509.73,
            "volume": 101122,
            "datetime": 1712950020000
        },
        {
            "open": 509.77,
            "high": 509.8,
            "low": 509.5,
            "close": 509.605,
            "volume": 106043,
            "datetime": 1712950080000
        },
        {
            "open": 509.605,
            "high": 509.64,
            "low": 509.46,
            "close": 509.505,
            "volume": 104761,
            "datetime": 1712950140000
        },
        {
            "open": 509.495,
            "high": 509.63,
            "low": 509.4,
            "close": 509.47,
            "volume": 155746,
            "datetime": 1712950200000
        },
        {
            "open": 509.47,
            "high": 509.72,
            "low": 509.44,
            "close": 509.6892,
            "volume": 122937,
            "datetime": 1712950260000
        },
        {
            "open": 509.69,
            "high": 510.04,
            "low": 509.69,
            "close": 509.955,
            "volume": 174295,
            "datetime": 1712950320000
        },
        {
            "open": 509.97,
            "high": 510.1,
            "low": 509.92,
            "close": 510.085,
            "volume": 237273,
            "datetime": 1712950380000
        },
        {
            "open": 510.09,
            "high": 510.16,
            "low": 509.91,
            "close": 509.91,
            "volume": 146744,
            "datetime": 1712950440000
        },
        {
            "open": 509.91,
            "high": 509.925,
            "low": 509.705,
            "close": 509.765,
            "volume": 194460,
            "datetime": 1712950500000
        },
        {
            "open": 509.75,
            "high": 509.76,
            "low": 509.625,
            "close": 509.745,
            "volume": 222807,
            "datetime": 1712950560000
        },
        {
            "open": 509.745,
            "high": 509.805,
            "low": 509.56,
            "close": 509.56,
            "volume": 138888,
            "datetime": 1712950620000
        },
        {
            "open": 509.56,
            "high": 509.595,
            "low": 509.26,
            "close": 509.34,
            "volume": 197502,
            "datetime": 1712950680000
        },
        {
            "open": 509.335,
            "high": 509.47,
            "low": 509.28,
            "close": 509.29,
            "volume": 194311,
            "datetime": 1712950740000
        },
        {
            "open": 509.3,
            "high": 509.75,
            "low": 509.3,
            "close": 509.67,
            "volume": 216655,
            "datetime": 1712950800000
        },
        {
            "open": 509.67,
            "high": 510.06,
            "low": 509.6599,
            "close": 510.05,
            "volume": 238739,
            "datetime": 1712950860000
        },
        {
            "open": 510.05,
            "high": 510.17,
            "low": 509.96,
            "close": 510.17,
            "volume": 258547,
            "datetime": 1712950920000
        },
        {
            "open": 510.17,
            "high": 510.48,
            "low": 510.16,
            "close": 510.43,
            "volume": 399910,
            "datetime": 1712950980000
        },
        {
            "open": 510.42,
            "high": 510.52,
            "low": 510.32,
            "close": 510.49,
            "volume": 301600,
            "datetime": 1712951040000
        },
        {
            "open": 510.49,
            "high": 510.74,
            "low": 510.46,
            "close": 510.68,
            "volume": 429468,
            "datetime": 1712951100000
        },
        {
            "open": 510.69,
            "high": 510.87,
            "low": 510.69,
            "close": 510.735,
            "volume": 321713,
            "datetime": 1712951160000
        },
        {
            "open": 510.735,
            "high": 510.795,
            "low": 510.33,
            "close": 510.3499,
            "volume": 493342,
            "datetime": 1712951220000
        },
        {
            "open": 510.34,
            "high": 510.4699,
            "low": 510.32,
            "close": 510.38,
            "volume": 231083,
            "datetime": 1712951280000
        },
        {
            "open": 510.39,
            "high": 510.7298,
            "low": 510.385,
            "close": 510.72,
            "volume": 255623,
            "datetime": 1712951340000
        },
        {
            "open": 510.73,
            "high": 510.9,
            "low": 510.22,
            "close": 510.31,
            "volume": 562118,
            "datetime": 1712951400000
        },
        {
            "open": 510.3,
            "high": 510.63,
            "low": 510.26,
            "close": 510.37,
            "volume": 440167,
            "datetime": 1712951460000
        },
        {
            "open": 510.37,
            "high": 510.38,
            "low": 510.14,
            "close": 510.295,
            "volume": 475011,
            "datetime": 1712951520000
        },
        {
            "open": 510.3,
            "high": 510.305,
            "low": 509.881,
            "close": 510.09,
            "volume": 462646,
            "datetime": 1712951580000
        },
        {
            "open": 510.08,
            "high": 510.39,
            "low": 510.06,
            "close": 510.2599,
            "volume": 579948,
            "datetime": 1712951640000
        },
        {
            "open": 510.26,
            "high": 510.57,
            "low": 510.12,
            "close": 510.46,
            "volume": 508831,
            "datetime": 1712951700000
        },
        {
            "open": 510.46,
            "high": 510.5773,
            "low": 510.3,
            "close": 510.505,
            "volume": 605852,
            "datetime": 1712951760000
        },
        {
            "open": 510.5099,
            "high": 510.6,
            "low": 510.41,
            "close": 510.515,
            "volume": 582863,
            "datetime": 1712951820000
        },
        {
            "open": 510.52,
            "high": 510.655,
            "low": 510.42,
            "close": 510.4501,
            "volume": 873957,
            "datetime": 1712951880000
        },
        {
            "open": 510.46,
            "high": 510.93,
            "low": 510.46,
            "close": 510.82,
            "volume": 1914424,
            "datetime": 1712951940000
        },
        {
            "open": 515.13,
            "high": 515.3,
            "low": 514.59,
            "close": 514.7,
            "volume": 1513713,
            "datetime": 1713187800000
        },
        {
            "open": 514.7,
            "high": 515.04,
            "low": 514.65,
            "close": 514.71,
            "volume": 333205,
            "datetime": 1713187860000
        },
        {
            "open": 514.685,
            "high": 514.88,
            "low": 514.62,
            "close": 514.78,
            "volume": 229952,
            "datetime": 1713187920000
        },
        {
            "open": 514.77,
            "high": 514.84,
            "low": 514.46,
            "close": 514.4793,
            "volume": 307887,
            "datetime": 1713187980000
        },
        {
            "open": 514.48,
            "high": 514.65,
            "low": 514.34,
            "close": 514.4,
            "volume": 301397,
            "datetime": 1713188040000
        },
        {
            "open": 514.38,
            "high": 514.4401,
            "low": 514.19,
            "close": 514.405,
            "volume": 285611,
            "datetime": 1713188100000
        },
        {
            "open": 514.415,
            "high": 514.51,
            "low": 514.26,
            "close": 514.35,
            "volume": 241667,
            "datetime": 1713188160000
        },
        {
            "open": 514.37,
            "high": 514.68,
            "low": 514.32,
            "close": 514.48,
            "volume": 198443,
            "datetime": 1713188220000
        },
        {
            "open": 514.46,
            "high": 514.59,
            "low": 514.44,
            "close": 514.585,
            "volume": 126712,
            "datetime": 1713188280000
        },
        {
            "open": 514.595,
            "high": 514.77,
            "low": 514.56,
            "close": 514.7,
            "volume": 158786,
            "datetime": 1713188340000
        },
        {
            "open": 514.7,
            "high": 515.215,
            "low": 514.7,
            "close": 515.1804,
            "volume": 224407,
            "datetime": 1713188400000
        },
        {
            "open": 515.1888,
            "high": 515.26,
            "low": 515.01,
            "close": 515.06,
            "volume": 195376,
            "datetime": 1713188460000
        },
        {
            "open": 515.06,
            "high": 515.27,
            "low": 514.93,
            "close": 514.975,
            "volume": 157468,
            "datetime": 1713188520000
        },
        {
            "open": 514.98,
            "high": 515.08,
            "low": 514.9404,
            "close": 515.07,
            "volume": 94652,
            "datetime": 1713188580000
        },
        {
            "open": 515.08,
            "high": 515.165,
            "low": 515.0108,
            "close": 515.045,
            "volume": 122994,
            "datetime": 1713188640000
        },
        {
            "open": 515.06,
            "high": 515.215,
            "low": 514.8,
            "close": 514.83,
            "volume": 192255,
            "datetime": 1713188700000
        },
        {
            "open": 514.84,
            "high": 514.96,
            "low": 514.75,
            "close": 514.89,
            "volume": 193661,
            "datetime": 1713188760000
        },
        {
            "open": 514.9,
            "high": 514.92,
            "low": 514.29,
            "close": 514.3399,
            "volume": 336429,
            "datetime": 1713188820000
        },
        {
            "open": 514.34,
            "high": 514.66,
            "low": 514.34,
            "close": 514.62,
            "volume": 236129,
            "datetime": 1713188880000
        },
        {
            "open": 514.64,
            "high": 514.855,
            "low": 514.63,
            "close": 514.69,
            "volume": 173990,
            "datetime": 1713188940000
        },
        {
            "open": 514.7,
            "high": 515,
            "low": 514.68,
            "close": 514.79,
            "volume": 153524,
            "datetime": 1713189000000
        },
        {
            "open": 514.77,
            "high": 514.975,
            "low": 514.75,
            "close": 514.75,
            "volume": 149346,
            "datetime": 1713189060000
        },
        {
            "open": 514.76,
            "high": 514.955,
            "low": 514.74,
            "close": 514.81,
            "volume": 116794,
            "datetime": 1713189120000
        },
        {
            "open": 514.805,
            "high": 514.98,
            "low": 514.74,
            "close": 514.96,
            "volume": 116577,
            "datetime": 1713189180000
        },
        {
            "open": 514.96,
            "high": 515.04,
            "low": 514.92,
            "close": 514.96,
            "volume": 119056,
            "datetime": 1713189240000
        },
        {
            "open": 514.97,
            "high": 515.04,
            "low": 514.8,
            "close": 514.8,
            "volume": 210183,
            "datetime": 1713189300000
        },
        {
            "open": 514.81,
            "high": 514.85,
            "low": 514.671,
            "close": 514.72,
            "volume": 142707,
            "datetime": 1713189360000
        },
        {
            "open": 514.71,
            "high": 514.79,
            "low": 514.67,
            "close": 514.71,
            "volume": 100733,
            "datetime": 1713189420000
        },
        {
            "open": 514.7,
            "high": 514.745,
            "low": 514.54,
            "close": 514.68,
            "volume": 138784,
            "datetime": 1713189480000
        },
        {
            "open": 514.69,
            "high": 514.91,
            "low": 514.69,
            "close": 514.89,
            "volume": 105593,
            "datetime": 1713189540000
        },
        {
            "open": 514.9,
            "high": 514.99,
            "low": 514.7,
            "close": 514.89,
            "volume": 214022,
            "datetime": 1713189600000
        },
        {
            "open": 514.88,
            "high": 515.01,
            "low": 514.78,
            "close": 514.955,
            "volume": 152099,
            "datetime": 1713189660000
        },
        {
            "open": 514.94,
            "high": 515.0099,
            "low": 514.83,
            "close": 514.91,
            "volume": 134131,
            "datetime": 1713189720000
        },
        {
            "open": 514.9,
            "high": 514.92,
            "low": 514.63,
            "close": 514.64,
            "volume": 119180,
            "datetime": 1713189780000
        },
        {
            "open": 514.635,
            "high": 514.67,
            "low": 514.44,
            "close": 514.57,
            "volume": 135292,
            "datetime": 1713189840000
        },
        {
            "open": 514.58,
            "high": 514.62,
            "low": 514.44,
            "close": 514.485,
            "volume": 127579,
            "datetime": 1713189900000
        },
        {
            "open": 514.49,
            "high": 514.635,
            "low": 514.36,
            "close": 514.4,
            "volume": 92892,
            "datetime": 1713189960000
        },
        {
            "open": 514.41,
            "high": 514.42,
            "low": 513.865,
            "close": 513.87,
            "volume": 200548,
            "datetime": 1713190020000
        },
        {
            "open": 513.87,
            "high": 513.92,
            "low": 513.27,
            "close": 513.42,
            "volume": 334963,
            "datetime": 1713190080000
        },
        {
            "open": 513.44,
            "high": 513.55,
            "low": 513.07,
            "close": 513.07,
            "volume": 228162,
            "datetime": 1713190140000
        },
        {
            "open": 513.06,
            "high": 513.17,
            "low": 512.53,
            "close": 512.61,
            "volume": 389935,
            "datetime": 1713190200000
        },
        {
            "open": 512.6,
            "high": 512.71,
            "low": 511.94,
            "close": 511.95,
            "volume": 331132,
            "datetime": 1713190260000
        },
        {
            "open": 511.94,
            "high": 512.49,
            "low": 511.94,
            "close": 512.47,
            "volume": 389072,
            "datetime": 1713190320000
        },
        {
            "open": 512.48,
            "high": 512.8,
            "low": 512.43,
            "close": 512.655,
            "volume": 293531,
            "datetime": 1713190380000
        },
        {
            "open": 512.655,
            "high": 512.67,
            "low": 512.36,
            "close": 512.5,
            "volume": 139192,
            "datetime": 1713190440000
        },
        {
            "open": 512.51,
            "high": 513.2,
            "low": 512.47,
            "close": 513.19,
            "volume": 291936,
            "datetime": 1713190500000
        },
        {
            "open": 513.18,
            "high": 513.45,
            "low": 513.18,
            "close": 513.315,
            "volume": 167960,
            "datetime": 1713190560000
        },
        {
            "open": 513.31,
            "high": 513.36,
            "low": 513.09,
            "close": 513.24,
            "volume": 104804,
            "datetime": 1713190620000
        },
        {
            "open": 513.24,
            "high": 513.32,
            "low": 513.1104,
            "close": 513.29,
            "volume": 120286,
            "datetime": 1713190680000
        },
        {
            "open": 513.28,
            "high": 513.285,
            "low": 513.04,
            "close": 513.12,
            "volume": 93046,
            "datetime": 1713190740000
        },
        {
            "open": 513.12,
            "high": 513.1799,
            "low": 512.71,
            "close": 512.72,
            "volume": 159161,
            "datetime": 1713190800000
        },
        {
            "open": 512.73,
            "high": 512.73,
            "low": 512.2803,
            "close": 512.395,
            "volume": 151358,
            "datetime": 1713190860000
        },
        {
            "open": 512.4,
            "high": 512.66,
            "low": 512.3,
            "close": 512.65,
            "volume": 143341,
            "datetime": 1713190920000
        },
        {
            "open": 512.67,
            "high": 512.99,
            "low": 512.645,
            "close": 512.92,
            "volume": 148069,
            "datetime": 1713190980000
        },
        {
            "open": 512.89,
            "high": 512.95,
            "low": 512.65,
            "close": 512.67,
            "volume": 119547,
            "datetime": 1713191040000
        },
        {
            "open": 512.67,
            "high": 512.7976,
            "low": 512.51,
            "close": 512.6,
            "volume": 89033,
            "datetime": 1713191100000
        },
        {
            "open": 512.63,
            "high": 512.73,
            "low": 512.5757,
            "close": 512.68,
            "volume": 86567,
            "datetime": 1713191160000
        },
        {
            "open": 512.66,
            "high": 512.751,
            "low": 512.32,
            "close": 512.33,
            "volume": 117461,
            "datetime": 1713191220000
        },
        {
            "open": 512.34,
            "high": 512.46,
            "low": 512.21,
            "close": 512.39,
            "volume": 160550,
            "datetime": 1713191280000
        },
        {
            "open": 512.4,
            "high": 512.63,
            "low": 512.24,
            "close": 512.57,
            "volume": 268114,
            "datetime": 1713191340000
        },
        {
            "open": 512.58,
            "high": 512.78,
            "low": 512.365,
            "close": 512.65,
            "volume": 192059,
            "datetime": 1713191400000
        },
        {
            "open": 512.64,
            "high": 512.83,
            "low": 512.59,
            "close": 512.76,
            "volume": 101451,
            "datetime": 1713191460000
        },
        {
            "open": 512.75,
            "high": 512.88,
            "low": 512.66,
            "close": 512.875,
            "volume": 118603,
            "datetime": 1713191520000
        },
        {
            "open": 512.87,
            "high": 513.01,
            "low": 512.44,
            "close": 512.56,
            "volume": 161132,
            "datetime": 1713191580000
        },
        {
            "open": 512.55,
            "high": 512.6,
            "low": 512.26,
            "close": 512.26,
            "volume": 134930,
            "datetime": 1713191640000
        },
        {
            "open": 512.24,
            "high": 512.42,
            "low": 512.18,
            "close": 512.225,
            "volume": 144051,
            "datetime": 1713191700000
        },
        {
            "open": 512.22,
            "high": 512.68,
            "low": 512.16,
            "close": 512.58,
            "volume": 192609,
            "datetime": 1713191760000
        },
        {
            "open": 512.57,
            "high": 512.63,
            "low": 511.86,
            "close": 511.91,
            "volume": 138037,
            "datetime": 1713191820000
        },
        {
            "open": 511.93,
            "high": 512.01,
            "low": 511.55,
            "close": 511.64,
            "volume": 232102,
            "datetime": 1713191880000
        },
        {
            "open": 511.63,
            "high": 511.72,
            "low": 511.48,
            "close": 511.72,
            "volume": 154779,
            "datetime": 1713191940000
        },
        {
            "open": 511.73,
            "high": 512.21,
            "low": 511.67,
            "close": 512.12,
            "volume": 255577,
            "datetime": 1713192000000
        },
        {
            "open": 512.13,
            "high": 512.26,
            "low": 511.9,
            "close": 512.12,
            "volume": 146055,
            "datetime": 1713192060000
        },
        {
            "open": 512.11,
            "high": 512.265,
            "low": 511.9,
            "close": 511.98,
            "volume": 182790,
            "datetime": 1713192120000
        },
        {
            "open": 511.98,
            "high": 512.04,
            "low": 511.595,
            "close": 511.7925,
            "volume": 156319,
            "datetime": 1713192180000
        },
        {
            "open": 511.79,
            "high": 512.135,
            "low": 511.76,
            "close": 512.09,
            "volume": 201992,
            "datetime": 1713192240000
        },
        {
            "open": 512.08,
            "high": 512.1,
            "low": 511.8099,
            "close": 511.865,
            "volume": 91267,
            "datetime": 1713192300000
        },
        {
            "open": 511.86,
            "high": 512.02,
            "low": 511.52,
            "close": 511.58,
            "volume": 168655,
            "datetime": 1713192360000
        },
        {
            "open": 511.58,
            "high": 511.68,
            "low": 511.52,
            "close": 511.68,
            "volume": 108158,
            "datetime": 1713192420000
        },
        {
            "open": 511.67,
            "high": 512.21,
            "low": 511.6,
            "close": 512.16,
            "volume": 255398,
            "datetime": 1713192480000
        },
        {
            "open": 512.16,
            "high": 512.34,
            "low": 512.12,
            "close": 512.235,
            "volume": 202703,
            "datetime": 1713192540000
        },
        {
            "open": 512.25,
            "high": 512.2799,
            "low": 512.06,
            "close": 512.062,
            "volume": 161694,
            "datetime": 1713192600000
        },
        {
            "open": 512.05,
            "high": 512.13,
            "low": 511.85,
            "close": 511.86,
            "volume": 155259,
            "datetime": 1713192660000
        },
        {
            "open": 511.86,
            "high": 511.93,
            "low": 511.72,
            "close": 511.735,
            "volume": 92976,
            "datetime": 1713192720000
        },
        {
            "open": 511.73,
            "high": 511.89,
            "low": 511.374,
            "close": 511.38,
            "volume": 123348,
            "datetime": 1713192780000
        },
        {
            "open": 511.37,
            "high": 511.57,
            "low": 511.34,
            "close": 511.41,
            "volume": 85568,
            "datetime": 1713192840000
        },
        {
            "open": 511.42,
            "high": 511.58,
            "low": 511.36,
            "close": 511.58,
            "volume": 85132,
            "datetime": 1713192900000
        },
        {
            "open": 511.59,
            "high": 511.6799,
            "low": 511.33,
            "close": 511.65,
            "volume": 99681,
            "datetime": 1713192960000
        },
        {
            "open": 511.64,
            "high": 511.77,
            "low": 511.5001,
            "close": 511.6,
            "volume": 99426,
            "datetime": 1713193020000
        },
        {
            "open": 511.58,
            "high": 511.58,
            "low": 511.35,
            "close": 511.356,
            "volume": 72187,
            "datetime": 1713193080000
        },
        {
            "open": 511.34,
            "high": 511.425,
            "low": 511.07,
            "close": 511.1001,
            "volume": 122204,
            "datetime": 1713193140000
        },
        {
            "open": 511.11,
            "high": 511.25,
            "low": 510.86,
            "close": 510.98,
            "volume": 249145,
            "datetime": 1713193200000
        },
        {
            "open": 510.98,
            "high": 511.32,
            "low": 510.89,
            "close": 511.24,
            "volume": 197836,
            "datetime": 1713193260000
        },
        {
            "open": 511.25,
            "high": 511.335,
            "low": 511.025,
            "close": 511.21,
            "volume": 139916,
            "datetime": 1713193320000
        },
        {
            "open": 511.18,
            "high": 511.45,
            "low": 511.18,
            "close": 511.33,
            "volume": 184341,
            "datetime": 1713193380000
        },
        {
            "open": 511.34,
            "high": 511.47,
            "low": 511.27,
            "close": 511.345,
            "volume": 127478,
            "datetime": 1713193440000
        },
        {
            "open": 511.33,
            "high": 511.46,
            "low": 511.29,
            "close": 511.335,
            "volume": 74917,
            "datetime": 1713193500000
        },
        {
            "open": 511.35,
            "high": 511.39,
            "low": 511.06,
            "close": 511.08,
            "volume": 103732,
            "datetime": 1713193560000
        },
        {
            "open": 511.08,
            "high": 511.34,
            "low": 511.04,
            "close": 511.29,
            "volume": 74358,
            "datetime": 1713193620000
        },
        {
            "open": 511.295,
            "high": 511.34,
            "low": 511.16,
            "close": 511.205,
            "volume": 52155,
            "datetime": 1713193680000
        },
        {
            "open": 511.23,
            "high": 511.23,
            "low": 511.04,
            "close": 511.0776,
            "volume": 114855,
            "datetime": 1713193740000
        },
        {
            "open": 511.08,
            "high": 511.37,
            "low": 511.06,
            "close": 511.31,
            "volume": 126021,
            "datetime": 1713193800000
        },
        {
            "open": 511.315,
            "high": 511.52,
            "low": 511.17,
            "close": 511.36,
            "volume": 175566,
            "datetime": 1713193860000
        },
        {
            "open": 511.36,
            "high": 511.465,
            "low": 511.34,
            "close": 511.44,
            "volume": 66691,
            "datetime": 1713193920000
        },
        {
            "open": 511.43,
            "high": 511.55,
            "low": 511.3,
            "close": 511.36,
            "volume": 89063,
            "datetime": 1713193980000
        },
        {
            "open": 511.36,
            "high": 511.45,
            "low": 511.27,
            "close": 511.27,
            "volume": 69577,
            "datetime": 1713194040000
        },
        {
            "open": 511.26,
            "high": 511.2903,
            "low": 511.03,
            "close": 511.05,
            "volume": 85753,
            "datetime": 1713194100000
        },
        {
            "open": 511.02,
            "high": 511.31,
            "low": 511,
            "close": 511.025,
            "volume": 166468,
            "datetime": 1713194160000
        },
        {
            "open": 511.03,
            "high": 511.34,
            "low": 511.01,
            "close": 511.145,
            "volume": 130484,
            "datetime": 1713194220000
        },
        {
            "open": 511.12,
            "high": 511.22,
            "low": 511,
            "close": 511.03,
            "volume": 79456,
            "datetime": 1713194280000
        },
        {
            "open": 511.04,
            "high": 511.23,
            "low": 510.97,
            "close": 511.22,
            "volume": 111134,
            "datetime": 1713194340000
        },
        {
            "open": 511.21,
            "high": 511.2304,
            "low": 510.95,
            "close": 511.04,
            "volume": 118898,
            "datetime": 1713194400000
        },
        {
            "open": 511.05,
            "high": 511.15,
            "low": 510.935,
            "close": 511.01,
            "volume": 139014,
            "datetime": 1713194460000
        },
        {
            "open": 511.01,
            "high": 511.04,
            "low": 510.81,
            "close": 510.88,
            "volume": 146858,
            "datetime": 1713194520000
        },
        {
            "open": 510.89,
            "high": 511.0076,
            "low": 510.81,
            "close": 510.995,
            "volume": 88739,
            "datetime": 1713194580000
        },
        {
            "open": 510.9992,
            "high": 511.18,
            "low": 510.94,
            "close": 511.05,
            "volume": 175309,
            "datetime": 1713194640000
        },
        {
            "open": 511.04,
            "high": 511.185,
            "low": 510.96,
            "close": 511.115,
            "volume": 89619,
            "datetime": 1713194700000
        },
        {
            "open": 511.1,
            "high": 511.1376,
            "low": 510.97,
            "close": 511.04,
            "volume": 82335,
            "datetime": 1713194760000
        },
        {
            "open": 511.04,
            "high": 511.37,
            "low": 511.04,
            "close": 511.335,
            "volume": 100817,
            "datetime": 1713194820000
        },
        {
            "open": 511.3404,
            "high": 511.57,
            "low": 511.32,
            "close": 511.51,
            "volume": 134575,
            "datetime": 1713194880000
        },
        {
            "open": 511.51,
            "high": 511.63,
            "low": 511.475,
            "close": 511.5801,
            "volume": 142439,
            "datetime": 1713194940000
        },
        {
            "open": 511.6,
            "high": 511.81,
            "low": 511.56,
            "close": 511.645,
            "volume": 160233,
            "datetime": 1713195000000
        },
        {
            "open": 511.65,
            "high": 511.829,
            "low": 511.54,
            "close": 511.73,
            "volume": 98017,
            "datetime": 1713195060000
        },
        {
            "open": 511.73,
            "high": 511.98,
            "low": 511.71,
            "close": 511.79,
            "volume": 160373,
            "datetime": 1713195120000
        },
        {
            "open": 511.805,
            "high": 512.04,
            "low": 511.74,
            "close": 511.96,
            "volume": 104695,
            "datetime": 1713195180000
        },
        {
            "open": 511.97,
            "high": 512.19,
            "low": 511.945,
            "close": 512.11,
            "volume": 120883,
            "datetime": 1713195240000
        },
        {
            "open": 512.12,
            "high": 512.33,
            "low": 511.9599,
            "close": 512.29,
            "volume": 233631,
            "datetime": 1713195300000
        },
        {
            "open": 512.28,
            "high": 512.31,
            "low": 511.79,
            "close": 512.02,
            "volume": 231415,
            "datetime": 1713195360000
        },
        {
            "open": 512.01,
            "high": 512.32,
            "low": 511.91,
            "close": 512.14,
            "volume": 130179,
            "datetime": 1713195420000
        },
        {
            "open": 512.14,
            "high": 512.19,
            "low": 512.04,
            "close": 512.1,
            "volume": 80458,
            "datetime": 1713195480000
        },
        {
            "open": 512.095,
            "high": 512.1105,
            "low": 511.98,
            "close": 511.995,
            "volume": 61368,
            "datetime": 1713195540000
        },
        {
            "open": 511.995,
            "high": 512.27,
            "low": 511.995,
            "close": 512.24,
            "volume": 82668,
            "datetime": 1713195600000
        },
        {
            "open": 512.25,
            "high": 512.2895,
            "low": 511.97,
            "close": 512,
            "volume": 70601,
            "datetime": 1713195660000
        },
        {
            "open": 512.01,
            "high": 512.03,
            "low": 511.63,
            "close": 511.71,
            "volume": 150932,
            "datetime": 1713195720000
        },
        {
            "open": 511.685,
            "high": 511.7999,
            "low": 511.63,
            "close": 511.75,
            "volume": 67352,
            "datetime": 1713195780000
        },
        {
            "open": 511.77,
            "high": 511.86,
            "low": 511.72,
            "close": 511.72,
            "volume": 49669,
            "datetime": 1713195840000
        },
        {
            "open": 511.74,
            "high": 512.11,
            "low": 511.74,
            "close": 511.96,
            "volume": 101751,
            "datetime": 1713195900000
        },
        {
            "open": 511.98,
            "high": 512.07,
            "low": 511.915,
            "close": 512.05,
            "volume": 88199,
            "datetime": 1713195960000
        },
        {
            "open": 512.055,
            "high": 512.1,
            "low": 511.76,
            "close": 511.76,
            "volume": 74963,
            "datetime": 1713196020000
        },
        {
            "open": 511.76,
            "high": 511.91,
            "low": 511.655,
            "close": 511.886,
            "volume": 68738,
            "datetime": 1713196080000
        },
        {
            "open": 511.88,
            "high": 511.885,
            "low": 511.67,
            "close": 511.73,
            "volume": 35129,
            "datetime": 1713196140000
        },
        {
            "open": 511.72,
            "high": 512.045,
            "low": 511.6603,
            "close": 512.0399,
            "volume": 61650,
            "datetime": 1713196200000
        },
        {
            "open": 512.03,
            "high": 512.27,
            "low": 512.01,
            "close": 512.27,
            "volume": 65395,
            "datetime": 1713196260000
        },
        {
            "open": 512.27,
            "high": 512.54,
            "low": 512.265,
            "close": 512.48,
            "volume": 92322,
            "datetime": 1713196320000
        },
        {
            "open": 512.48,
            "high": 512.53,
            "low": 512.31,
            "close": 512.46,
            "volume": 99593,
            "datetime": 1713196380000
        },
        {
            "open": 512.46,
            "high": 512.58,
            "low": 512.44,
            "close": 512.5515,
            "volume": 77364,
            "datetime": 1713196440000
        },
        {
            "open": 512.56,
            "high": 512.6,
            "low": 512.48,
            "close": 512.495,
            "volume": 86858,
            "datetime": 1713196500000
        },
        {
            "open": 512.495,
            "high": 512.6,
            "low": 512.44,
            "close": 512.475,
            "volume": 89187,
            "datetime": 1713196560000
        },
        {
            "open": 512.47,
            "high": 512.495,
            "low": 512.37,
            "close": 512.39,
            "volume": 70601,
            "datetime": 1713196620000
        },
        {
            "open": 512.385,
            "high": 512.42,
            "low": 512.29,
            "close": 512.31,
            "volume": 76253,
            "datetime": 1713196680000
        },
        {
            "open": 512.31,
            "high": 512.34,
            "low": 512.24,
            "close": 512.315,
            "volume": 46910,
            "datetime": 1713196740000
        },
        {
            "open": 512.32,
            "high": 512.38,
            "low": 512.165,
            "close": 512.36,
            "volume": 48725,
            "datetime": 1713196800000
        },
        {
            "open": 512.355,
            "high": 512.5024,
            "low": 512.31,
            "close": 512.495,
            "volume": 42428,
            "datetime": 1713196860000
        },
        {
            "open": 512.496,
            "high": 512.595,
            "low": 512.385,
            "close": 512.59,
            "volume": 55756,
            "datetime": 1713196920000
        },
        {
            "open": 512.59,
            "high": 512.62,
            "low": 512.471,
            "close": 512.56,
            "volume": 93786,
            "datetime": 1713196980000
        },
        {
            "open": 512.56,
            "high": 512.6,
            "low": 512.435,
            "close": 512.5969,
            "volume": 80407,
            "datetime": 1713197040000
        },
        {
            "open": 512.59,
            "high": 512.59,
            "low": 512.43,
            "close": 512.48,
            "volume": 58819,
            "datetime": 1713197100000
        },
        {
            "open": 512.47,
            "high": 512.51,
            "low": 512.36,
            "close": 512.3898,
            "volume": 33774,
            "datetime": 1713197160000
        },
        {
            "open": 512.3887,
            "high": 512.42,
            "low": 512.2303,
            "close": 512.27,
            "volume": 73528,
            "datetime": 1713197220000
        },
        {
            "open": 512.26,
            "high": 512.4699,
            "low": 512.24,
            "close": 512.45,
            "volume": 41096,
            "datetime": 1713197280000
        },
        {
            "open": 512.455,
            "high": 512.59,
            "low": 512.45,
            "close": 512.53,
            "volume": 33253,
            "datetime": 1713197340000
        },
        {
            "open": 512.515,
            "high": 512.61,
            "low": 512.4475,
            "close": 512.46,
            "volume": 39707,
            "datetime": 1713197400000
        },
        {
            "open": 512.46,
            "high": 512.465,
            "low": 512.3,
            "close": 512.335,
            "volume": 50493,
            "datetime": 1713197460000
        },
        {
            "open": 512.33,
            "high": 512.37,
            "low": 512.28,
            "close": 512.3,
            "volume": 56487,
            "datetime": 1713197520000
        },
        {
            "open": 512.3,
            "high": 512.36,
            "low": 512.24,
            "close": 512.335,
            "volume": 40212,
            "datetime": 1713197580000
        },
        {
            "open": 512.34,
            "high": 512.43,
            "low": 512.31,
            "close": 512.4,
            "volume": 36746,
            "datetime": 1713197640000
        },
        {
            "open": 512.39,
            "high": 512.485,
            "low": 512.3,
            "close": 512.3,
            "volume": 43823,
            "datetime": 1713197700000
        },
        {
            "open": 512.29,
            "high": 512.32,
            "low": 512.19,
            "close": 512.2301,
            "volume": 58845,
            "datetime": 1713197760000
        },
        {
            "open": 512.23,
            "high": 512.245,
            "low": 512.04,
            "close": 512.12,
            "volume": 87735,
            "datetime": 1713197820000
        },
        {
            "open": 512.12,
            "high": 512.12,
            "low": 511.81,
            "close": 511.86,
            "volume": 118344,
            "datetime": 1713197880000
        },
        {
            "open": 511.8599,
            "high": 511.87,
            "low": 511.64,
            "close": 511.675,
            "volume": 101938,
            "datetime": 1713197940000
        },
        {
            "open": 511.68,
            "high": 511.9,
            "low": 511.68,
            "close": 511.81,
            "volume": 49459,
            "datetime": 1713198000000
        },
        {
            "open": 511.82,
            "high": 511.825,
            "low": 511.548,
            "close": 511.548,
            "volume": 61593,
            "datetime": 1713198060000
        },
        {
            "open": 511.54,
            "high": 511.59,
            "low": 511.22,
            "close": 511.27,
            "volume": 173225,
            "datetime": 1713198120000
        },
        {
            "open": 511.27,
            "high": 511.33,
            "low": 511.0175,
            "close": 511.06,
            "volume": 155670,
            "datetime": 1713198180000
        },
        {
            "open": 511.06,
            "high": 511.159,
            "low": 510.6,
            "close": 510.7,
            "volume": 183653,
            "datetime": 1713198240000
        },
        {
            "open": 510.69,
            "high": 510.83,
            "low": 510.54,
            "close": 510.78,
            "volume": 151864,
            "datetime": 1713198300000
        },
        {
            "open": 510.78,
            "high": 510.81,
            "low": 510.58,
            "close": 510.7,
            "volume": 107077,
            "datetime": 1713198360000
        },
        {
            "open": 510.7,
            "high": 510.74,
            "low": 510.365,
            "close": 510.565,
            "volume": 131723,
            "datetime": 1713198420000
        },
        {
            "open": 510.57,
            "high": 510.74,
            "low": 510.55,
            "close": 510.66,
            "volume": 73547,
            "datetime": 1713198480000
        },
        {
            "open": 510.66,
            "high": 510.7299,
            "low": 510.57,
            "close": 510.6,
            "volume": 69951,
            "datetime": 1713198540000
        },
        {
            "open": 510.61,
            "high": 510.62,
            "low": 510.06,
            "close": 510.14,
            "volume": 164345,
            "datetime": 1713198600000
        },
        {
            "open": 510.13,
            "high": 510.201,
            "low": 509.994,
            "close": 510.12,
            "volume": 148896,
            "datetime": 1713198660000
        },
        {
            "open": 510.11,
            "high": 510.3,
            "low": 510.01,
            "close": 510.135,
            "volume": 116684,
            "datetime": 1713198720000
        },
        {
            "open": 510.13,
            "high": 510.399,
            "low": 510.09,
            "close": 510.31,
            "volume": 98144,
            "datetime": 1713198780000
        },
        {
            "open": 510.3,
            "high": 510.365,
            "low": 510.17,
            "close": 510.21,
            "volume": 61784,
            "datetime": 1713198840000
        },
        {
            "open": 510.205,
            "high": 510.325,
            "low": 510.17,
            "close": 510.27,
            "volume": 83456,
            "datetime": 1713198900000
        },
        {
            "open": 510.275,
            "high": 510.275,
            "low": 510.01,
            "close": 510.04,
            "volume": 89921,
            "datetime": 1713198960000
        },
        {
            "open": 510.05,
            "high": 510.15,
            "low": 509.91,
            "close": 509.965,
            "volume": 116533,
            "datetime": 1713199020000
        },
        {
            "open": 509.965,
            "high": 510.065,
            "low": 509.66,
            "close": 509.82,
            "volume": 216080,
            "datetime": 1713199080000
        },
        {
            "open": 509.81,
            "high": 509.88,
            "low": 509.7105,
            "close": 509.88,
            "volume": 109899,
            "datetime": 1713199140000
        },
        {
            "open": 509.89,
            "high": 510.24,
            "low": 509.89,
            "close": 510.046,
            "volume": 176093,
            "datetime": 1713199200000
        },
        {
            "open": 510.0499,
            "high": 510.1403,
            "low": 509.97,
            "close": 509.97,
            "volume": 124904,
            "datetime": 1713199260000
        },
        {
            "open": 509.95,
            "high": 509.955,
            "low": 509.57,
            "close": 509.635,
            "volume": 118938,
            "datetime": 1713199320000
        },
        {
            "open": 509.65,
            "high": 509.72,
            "low": 509.62,
            "close": 509.68,
            "volume": 91418,
            "datetime": 1713199380000
        },
        {
            "open": 509.67,
            "high": 509.84,
            "low": 509.51,
            "close": 509.7005,
            "volume": 143326,
            "datetime": 1713199440000
        },
        {
            "open": 509.675,
            "high": 509.72,
            "low": 509.56,
            "close": 509.58,
            "volume": 94771,
            "datetime": 1713199500000
        },
        {
            "open": 509.575,
            "high": 509.73,
            "low": 509.51,
            "close": 509.71,
            "volume": 142772,
            "datetime": 1713199560000
        },
        {
            "open": 509.71,
            "high": 509.8799,
            "low": 509.69,
            "close": 509.71,
            "volume": 175216,
            "datetime": 1713199620000
        },
        {
            "open": 509.7295,
            "high": 510.03,
            "low": 509.68,
            "close": 510,
            "volume": 173544,
            "datetime": 1713199680000
        },
        {
            "open": 510,
            "high": 510.02,
            "low": 509.72,
            "close": 509.78,
            "volume": 126616,
            "datetime": 1713199740000
        },
        {
            "open": 509.77,
            "high": 509.88,
            "low": 509.74,
            "close": 509.82,
            "volume": 64431,
            "datetime": 1713199800000
        },
        {
            "open": 509.82,
            "high": 509.9792,
            "low": 509.67,
            "close": 509.92,
            "volume": 153899,
            "datetime": 1713199860000
        },
        {
            "open": 509.9,
            "high": 509.92,
            "low": 509.715,
            "close": 509.84,
            "volume": 98445,
            "datetime": 1713199920000
        },
        {
            "open": 509.82,
            "high": 510.06,
            "low": 509.81,
            "close": 510.049,
            "volume": 115996,
            "datetime": 1713199980000
        },
        {
            "open": 510.045,
            "high": 510.05,
            "low": 509.77,
            "close": 509.93,
            "volume": 150809,
            "datetime": 1713200040000
        },
        {
            "open": 509.93,
            "high": 509.9396,
            "low": 509.7814,
            "close": 509.79,
            "volume": 108955,
            "datetime": 1713200100000
        },
        {
            "open": 509.79,
            "high": 509.97,
            "low": 509.72,
            "close": 509.8496,
            "volume": 113662,
            "datetime": 1713200160000
        },
        {
            "open": 509.83,
            "high": 509.88,
            "low": 509.73,
            "close": 509.77,
            "volume": 44011,
            "datetime": 1713200220000
        },
        {
            "open": 509.77,
            "high": 509.995,
            "low": 509.77,
            "close": 509.98,
            "volume": 78024,
            "datetime": 1713200280000
        },
        {
            "open": 509.98,
            "high": 510.19,
            "low": 509.94,
            "close": 510.075,
            "volume": 93931,
            "datetime": 1713200340000
        },
        {
            "open": 510.06,
            "high": 510.07,
            "low": 509.64,
            "close": 509.68,
            "volume": 115818,
            "datetime": 1713200400000
        },
        {
            "open": 509.675,
            "high": 509.78,
            "low": 509.59,
            "close": 509.75,
            "volume": 79183,
            "datetime": 1713200460000
        },
        {
            "open": 509.76,
            "high": 509.97,
            "low": 509.76,
            "close": 509.94,
            "volume": 60943,
            "datetime": 1713200520000
        },
        {
            "open": 509.94,
            "high": 510.04,
            "low": 509.79,
            "close": 510,
            "volume": 75052,
            "datetime": 1713200580000
        },
        {
            "open": 510,
            "high": 510.195,
            "low": 509.9801,
            "close": 509.99,
            "volume": 88416,
            "datetime": 1713200640000
        },
        {
            "open": 509.97,
            "high": 510.28,
            "low": 509.93,
            "close": 510.205,
            "volume": 95345,
            "datetime": 1713200700000
        },
        {
            "open": 510.2,
            "high": 510.37,
            "low": 510.2,
            "close": 510.31,
            "volume": 113339,
            "datetime": 1713200760000
        },
        {
            "open": 510.3,
            "high": 510.42,
            "low": 510.245,
            "close": 510.38,
            "volume": 74271,
            "datetime": 1713200820000
        },
        {
            "open": 510.38,
            "high": 510.56,
            "low": 510.28,
            "close": 510.53,
            "volume": 95879,
            "datetime": 1713200880000
        },
        {
            "open": 510.53,
            "high": 510.5401,
            "low": 510.344,
            "close": 510.4104,
            "volume": 70694,
            "datetime": 1713200940000
        },
        {
            "open": 510.42,
            "high": 510.43,
            "low": 509.76,
            "close": 509.805,
            "volume": 137238,
            "datetime": 1713201000000
        },
        {
            "open": 509.81,
            "high": 509.96,
            "low": 509.79,
            "close": 509.9,
            "volume": 81680,
            "datetime": 1713201060000
        },
        {
            "open": 509.92,
            "high": 510.02,
            "low": 509.8325,
            "close": 509.94,
            "volume": 96461,
            "datetime": 1713201120000
        },
        {
            "open": 509.95,
            "high": 509.96,
            "low": 509.63,
            "close": 509.655,
            "volume": 131605,
            "datetime": 1713201180000
        },
        {
            "open": 509.66,
            "high": 509.87,
            "low": 509.625,
            "close": 509.7,
            "volume": 98046,
            "datetime": 1713201240000
        },
        {
            "open": 509.69,
            "high": 509.69,
            "low": 509.5203,
            "close": 509.56,
            "volume": 110786,
            "datetime": 1713201300000
        },
        {
            "open": 509.54,
            "high": 509.72,
            "low": 509.47,
            "close": 509.5,
            "volume": 147803,
            "datetime": 1713201360000
        },
        {
            "open": 509.48,
            "high": 509.7,
            "low": 509.45,
            "close": 509.58,
            "volume": 107570,
            "datetime": 1713201420000
        },
        {
            "open": 509.58,
            "high": 509.59,
            "low": 508.95,
            "close": 508.98,
            "volume": 248786,
            "datetime": 1713201480000
        },
        {
            "open": 508.98,
            "high": 509.05,
            "low": 508.59,
            "close": 508.64,
            "volume": 238706,
            "datetime": 1713201540000
        },
        {
            "open": 508.63,
            "high": 508.69,
            "low": 508.31,
            "close": 508.3242,
            "volume": 323153,
            "datetime": 1713201600000
        },
        {
            "open": 508.34,
            "high": 508.47,
            "low": 508.04,
            "close": 508.07,
            "volume": 237010,
            "datetime": 1713201660000
        },
        {
            "open": 508.08,
            "high": 508.18,
            "low": 507.84,
            "close": 507.92,
            "volume": 214119,
            "datetime": 1713201720000
        },
        {
            "open": 507.88,
            "high": 508.16,
            "low": 507.8,
            "close": 507.97,
            "volume": 292153,
            "datetime": 1713201780000
        },
        {
            "open": 507.97,
            "high": 508.1,
            "low": 507.88,
            "close": 507.9,
            "volume": 138802,
            "datetime": 1713201840000
        },
        {
            "open": 507.89,
            "high": 508.05,
            "low": 507.78,
            "close": 507.82,
            "volume": 133645,
            "datetime": 1713201900000
        },
        {
            "open": 507.82,
            "high": 507.82,
            "low": 507.3013,
            "close": 507.42,
            "volume": 240087,
            "datetime": 1713201960000
        },
        {
            "open": 507.43,
            "high": 507.6,
            "low": 507.19,
            "close": 507.31,
            "volume": 186916,
            "datetime": 1713202020000
        },
        {
            "open": 507.3,
            "high": 507.35,
            "low": 506.686,
            "close": 506.74,
            "volume": 321231,
            "datetime": 1713202080000
        },
        {
            "open": 506.73,
            "high": 506.87,
            "low": 506.55,
            "close": 506.57,
            "volume": 318796,
            "datetime": 1713202140000
        },
        {
            "open": 506.58,
            "high": 506.61,
            "low": 505.75,
            "close": 505.7901,
            "volume": 535626,
            "datetime": 1713202200000
        },
        {
            "open": 505.8,
            "high": 506.26,
            "low": 505.56,
            "close": 506.17,
            "volume": 524298,
            "datetime": 1713202260000
        },
        {
            "open": 506.16,
            "high": 506.31,
            "low": 505.61,
            "close": 505.78,
            "volume": 433669,
            "datetime": 1713202320000
        },
        {
            "open": 505.79,
            "high": 506.15,
            "low": 505.77,
            "close": 506.07,
            "volume": 351806,
            "datetime": 1713202380000
        },
        {
            "open": 506.11,
            "high": 506.57,
            "low": 505.99,
            "close": 506.17,
            "volume": 301550,
            "datetime": 1713202440000
        },
        {
            "open": 506.18,
            "high": 506.85,
            "low": 506.18,
            "close": 506.805,
            "volume": 215334,
            "datetime": 1713202500000
        },
        {
            "open": 506.84,
            "high": 506.84,
            "low": 506.23,
            "close": 506.32,
            "volume": 203228,
            "datetime": 1713202560000
        },
        {
            "open": 506.33,
            "high": 506.6,
            "low": 506.32,
            "close": 506.45,
            "volume": 241974,
            "datetime": 1713202620000
        },
        {
            "open": 506.47,
            "high": 506.56,
            "low": 506.02,
            "close": 506.04,
            "volume": 192281,
            "datetime": 1713202680000
        },
        {
            "open": 506,
            "high": 506.04,
            "low": 505.79,
            "close": 505.83,
            "volume": 203859,
            "datetime": 1713202740000
        },
        {
            "open": 505.85,
            "high": 506.05,
            "low": 505.525,
            "close": 506.0105,
            "volume": 384248,
            "datetime": 1713202800000
        },
        {
            "open": 506.0199,
            "high": 506.15,
            "low": 505.845,
            "close": 506.05,
            "volume": 225221,
            "datetime": 1713202860000
        },
        {
            "open": 506.05,
            "high": 506.645,
            "low": 506.03,
            "close": 506.59,
            "volume": 393635,
            "datetime": 1713202920000
        },
        {
            "open": 506.58,
            "high": 506.59,
            "low": 506.23,
            "close": 506.34,
            "volume": 192301,
            "datetime": 1713202980000
        },
        {
            "open": 506.33,
            "high": 506.33,
            "low": 505.88,
            "close": 505.88,
            "volume": 218376,
            "datetime": 1713203040000
        },
        {
            "open": 505.87,
            "high": 506.24,
            "low": 505.84,
            "close": 506.16,
            "volume": 214781,
            "datetime": 1713203100000
        },
        {
            "open": 506.14,
            "high": 506.25,
            "low": 505.79,
            "close": 505.81,
            "volume": 160034,
            "datetime": 1713203160000
        },
        {
            "open": 505.8,
            "high": 505.98,
            "low": 505.71,
            "close": 505.86,
            "volume": 139119,
            "datetime": 1713203220000
        },
        {
            "open": 505.86,
            "high": 506.1,
            "low": 505.8,
            "close": 505.86,
            "volume": 148819,
            "datetime": 1713203280000
        },
        {
            "open": 505.86,
            "high": 505.86,
            "low": 505.32,
            "close": 505.4897,
            "volume": 329835,
            "datetime": 1713203340000
        },
        {
            "open": 505.5,
            "high": 505.71,
            "low": 505.35,
            "close": 505.44,
            "volume": 202965,
            "datetime": 1713203400000
        },
        {
            "open": 505.45,
            "high": 505.45,
            "low": 505.01,
            "close": 505.14,
            "volume": 309620,
            "datetime": 1713203460000
        },
        {
            "open": 505.149,
            "high": 505.79,
            "low": 505.12,
            "close": 505.79,
            "volume": 296411,
            "datetime": 1713203520000
        },
        {
            "open": 505.8,
            "high": 506.022,
            "low": 505.66,
            "close": 505.9,
            "volume": 144346,
            "datetime": 1713203580000
        },
        {
            "open": 505.89,
            "high": 506.05,
            "low": 505.76,
            "close": 505.87,
            "volume": 151089,
            "datetime": 1713203640000
        },
        {
            "open": 505.86,
            "high": 505.96,
            "low": 505.765,
            "close": 505.79,
            "volume": 103738,
            "datetime": 1713203700000
        },
        {
            "open": 505.81,
            "high": 506,
            "low": 505.75,
            "close": 505.87,
            "volume": 90034,
            "datetime": 1713203760000
        },
        {
            "open": 505.86,
            "high": 506.21,
            "low": 505.69,
            "close": 506.01,
            "volume": 210877,
            "datetime": 1713203820000
        },
        {
            "open": 506.02,
            "high": 506.56,
            "low": 506.02,
            "close": 506.46,
            "volume": 211231,
            "datetime": 1713203880000
        },
        {
            "open": 506.46,
            "high": 506.61,
            "low": 506.2,
            "close": 506.365,
            "volume": 178763,
            "datetime": 1713203940000
        },
        {
            "open": 506.3706,
            "high": 506.44,
            "low": 506,
            "close": 506.27,
            "volume": 145506,
            "datetime": 1713204000000
        },
        {
            "open": 506.24,
            "high": 506.285,
            "low": 505.77,
            "close": 505.81,
            "volume": 145001,
            "datetime": 1713204060000
        },
        {
            "open": 505.8,
            "high": 505.89,
            "low": 505.56,
            "close": 505.7099,
            "volume": 213864,
            "datetime": 1713204120000
        },
        {
            "open": 505.75,
            "high": 505.79,
            "low": 505.58,
            "close": 505.7,
            "volume": 91666,
            "datetime": 1713204180000
        },
        {
            "open": 505.68,
            "high": 506.06,
            "low": 505.63,
            "close": 505.95,
            "volume": 151074,
            "datetime": 1713204240000
        },
        {
            "open": 505.94,
            "high": 506.23,
            "low": 505.93,
            "close": 506.045,
            "volume": 177747,
            "datetime": 1713204300000
        },
        {
            "open": 506.04,
            "high": 506.082,
            "low": 505.7,
            "close": 505.735,
            "volume": 194155,
            "datetime": 1713204360000
        },
        {
            "open": 505.71,
            "high": 505.9299,
            "low": 505.63,
            "close": 505.67,
            "volume": 84968,
            "datetime": 1713204420000
        },
        {
            "open": 505.6529,
            "high": 505.87,
            "low": 505.47,
            "close": 505.5201,
            "volume": 114378,
            "datetime": 1713204480000
        },
        {
            "open": 505.51,
            "high": 505.68,
            "low": 505.41,
            "close": 505.5903,
            "volume": 182245,
            "datetime": 1713204540000
        },
        {
            "open": 505.59,
            "high": 505.625,
            "low": 505.15,
            "close": 505.16,
            "volume": 165432,
            "datetime": 1713204600000
        },
        {
            "open": 505.14,
            "high": 505.27,
            "low": 505.02,
            "close": 505.1,
            "volume": 159433,
            "datetime": 1713204660000
        },
        {
            "open": 505.11,
            "high": 505.21,
            "low": 504.79,
            "close": 504.915,
            "volume": 496019,
            "datetime": 1713204720000
        },
        {
            "open": 504.92,
            "high": 505.34,
            "low": 504.915,
            "close": 505.2186,
            "volume": 232212,
            "datetime": 1713204780000
        },
        {
            "open": 505.21,
            "high": 505.26,
            "low": 505.0497,
            "close": 505.13,
            "volume": 92097,
            "datetime": 1713204840000
        },
        {
            "open": 505.12,
            "high": 505.2973,
            "low": 505.02,
            "close": 505.21,
            "volume": 183960,
            "datetime": 1713204900000
        },
        {
            "open": 505.18,
            "high": 505.28,
            "low": 504.89,
            "close": 504.92,
            "volume": 201450,
            "datetime": 1713204960000
        },
        {
            "open": 504.91,
            "high": 505.18,
            "low": 504.91,
            "close": 504.94,
            "volume": 211966,
            "datetime": 1713205020000
        },
        {
            "open": 504.94,
            "high": 505.3,
            "low": 504.81,
            "close": 505.2,
            "volume": 414056,
            "datetime": 1713205080000
        },
        {
            "open": 505.2,
            "high": 505.44,
            "low": 505.18,
            "close": 505.35,
            "volume": 175471,
            "datetime": 1713205140000
        },
        {
            "open": 505.34,
            "high": 505.59,
            "low": 505.28,
            "close": 505.57,
            "volume": 173796,
            "datetime": 1713205200000
        },
        {
            "open": 505.58,
            "high": 505.59,
            "low": 505.2,
            "close": 505.2,
            "volume": 200429,
            "datetime": 1713205260000
        },
        {
            "open": 505.17,
            "high": 505.21,
            "low": 504.98,
            "close": 505.035,
            "volume": 139052,
            "datetime": 1713205320000
        },
        {
            "open": 505.015,
            "high": 505.09,
            "low": 504.73,
            "close": 505,
            "volume": 213843,
            "datetime": 1713205380000
        },
        {
            "open": 504.99,
            "high": 505.04,
            "low": 504.74,
            "close": 504.8725,
            "volume": 111170,
            "datetime": 1713205440000
        },
        {
            "open": 504.875,
            "high": 505.44,
            "low": 504.86,
            "close": 505.35,
            "volume": 190165,
            "datetime": 1713205500000
        },
        {
            "open": 505.35,
            "high": 505.56,
            "low": 505.25,
            "close": 505.3799,
            "volume": 133542,
            "datetime": 1713205560000
        },
        {
            "open": 505.38,
            "high": 505.61,
            "low": 505.31,
            "close": 505.44,
            "volume": 118489,
            "datetime": 1713205620000
        },
        {
            "open": 505.44,
            "high": 505.51,
            "low": 505.14,
            "close": 505.225,
            "volume": 120089,
            "datetime": 1713205680000
        },
        {
            "open": 505.23,
            "high": 505.34,
            "low": 505.11,
            "close": 505.11,
            "volume": 70989,
            "datetime": 1713205740000
        },
        {
            "open": 505.1,
            "high": 505.45,
            "low": 504.94,
            "close": 505.36,
            "volume": 133173,
            "datetime": 1713205800000
        },
        {
            "open": 505.3699,
            "high": 505.45,
            "low": 505.23,
            "close": 505.341,
            "volume": 74704,
            "datetime": 1713205860000
        },
        {
            "open": 505.35,
            "high": 505.7,
            "low": 505.28,
            "close": 505.69,
            "volume": 134864,
            "datetime": 1713205920000
        },
        {
            "open": 505.69,
            "high": 506.05,
            "low": 505.66,
            "close": 505.84,
            "volume": 252504,
            "datetime": 1713205980000
        },
        {
            "open": 505.83,
            "high": 506.04,
            "low": 505.61,
            "close": 505.95,
            "volume": 224164,
            "datetime": 1713206040000
        },
        {
            "open": 505.95,
            "high": 506.39,
            "low": 505.92,
            "close": 506.365,
            "volume": 230988,
            "datetime": 1713206100000
        },
        {
            "open": 506.35,
            "high": 506.37,
            "low": 505.965,
            "close": 506.26,
            "volume": 254580,
            "datetime": 1713206160000
        },
        {
            "open": 506.26,
            "high": 506.46,
            "low": 506.2,
            "close": 506.34,
            "volume": 190942,
            "datetime": 1713206220000
        },
        {
            "open": 506.34,
            "high": 506.49,
            "low": 505.985,
            "close": 505.985,
            "volume": 154644,
            "datetime": 1713206280000
        },
        {
            "open": 505.98,
            "high": 506.02,
            "low": 505.59,
            "close": 505.7299,
            "volume": 161725,
            "datetime": 1713206340000
        },
        {
            "open": 505.715,
            "high": 506.08,
            "low": 505.705,
            "close": 505.94,
            "volume": 123246,
            "datetime": 1713206400000
        },
        {
            "open": 505.94,
            "high": 506.12,
            "low": 505.82,
            "close": 505.95,
            "volume": 97413,
            "datetime": 1713206460000
        },
        {
            "open": 505.96,
            "high": 506,
            "low": 505.61,
            "close": 505.64,
            "volume": 115258,
            "datetime": 1713206520000
        },
        {
            "open": 505.65,
            "high": 505.72,
            "low": 505.55,
            "close": 505.55,
            "volume": 74918,
            "datetime": 1713206580000
        },
        {
            "open": 505.55,
            "high": 505.64,
            "low": 505.42,
            "close": 505.5,
            "volume": 114311,
            "datetime": 1713206640000
        },
        {
            "open": 505.53,
            "high": 505.6,
            "low": 505.26,
            "close": 505.295,
            "volume": 96869,
            "datetime": 1713206700000
        },
        {
            "open": 505.292,
            "high": 505.45,
            "low": 505.24,
            "close": 505.295,
            "volume": 75380,
            "datetime": 1713206760000
        },
        {
            "open": 505.3,
            "high": 505.4201,
            "low": 505.265,
            "close": 505.29,
            "volume": 61311,
            "datetime": 1713206820000
        },
        {
            "open": 505.29,
            "high": 505.52,
            "low": 505.2301,
            "close": 505.2301,
            "volume": 62010,
            "datetime": 1713206880000
        },
        {
            "open": 505.22,
            "high": 505.285,
            "low": 504.915,
            "close": 504.97,
            "volume": 142732,
            "datetime": 1713206940000
        },
        {
            "open": 504.99,
            "high": 505.07,
            "low": 504.815,
            "close": 504.88,
            "volume": 92925,
            "datetime": 1713207000000
        },
        {
            "open": 504.88,
            "high": 504.93,
            "low": 504.63,
            "close": 504.765,
            "volume": 179018,
            "datetime": 1713207060000
        },
        {
            "open": 504.76,
            "high": 504.89,
            "low": 504.51,
            "close": 504.515,
            "volume": 171033,
            "datetime": 1713207120000
        },
        {
            "open": 504.515,
            "high": 504.6525,
            "low": 504.4801,
            "close": 504.49,
            "volume": 168827,
            "datetime": 1713207180000
        },
        {
            "open": 504.49,
            "high": 504.655,
            "low": 504.425,
            "close": 504.51,
            "volume": 100372,
            "datetime": 1713207240000
        },
        {
            "open": 504.5,
            "high": 504.83,
            "low": 504.4725,
            "close": 504.69,
            "volume": 175535,
            "datetime": 1713207300000
        },
        {
            "open": 504.68,
            "high": 504.87,
            "low": 504.66,
            "close": 504.76,
            "volume": 114776,
            "datetime": 1713207360000
        },
        {
            "open": 504.74,
            "high": 504.93,
            "low": 504.705,
            "close": 504.92,
            "volume": 105747,
            "datetime": 1713207420000
        },
        {
            "open": 504.9233,
            "high": 505.46,
            "low": 504.9233,
            "close": 505.13,
            "volume": 327444,
            "datetime": 1713207480000
        },
        {
            "open": 505.13,
            "high": 505.25,
            "low": 505.1,
            "close": 505.14,
            "volume": 125463,
            "datetime": 1713207540000
        },
        {
            "open": 505.11,
            "high": 505.25,
            "low": 504.84,
            "close": 505.245,
            "volume": 132415,
            "datetime": 1713207600000
        },
        {
            "open": 505.26,
            "high": 505.26,
            "low": 504.89,
            "close": 505.05,
            "volume": 112586,
            "datetime": 1713207660000
        },
        {
            "open": 505.03,
            "high": 505.27,
            "low": 504.965,
            "close": 505.07,
            "volume": 93464,
            "datetime": 1713207720000
        },
        {
            "open": 505.045,
            "high": 505.05,
            "low": 504.48,
            "close": 504.56,
            "volume": 222042,
            "datetime": 1713207780000
        },
        {
            "open": 504.55,
            "high": 504.6,
            "low": 504.43,
            "close": 504.46,
            "volume": 154077,
            "datetime": 1713207840000
        },
        {
            "open": 504.45,
            "high": 504.665,
            "low": 504.245,
            "close": 504.62,
            "volume": 179013,
            "datetime": 1713207900000
        },
        {
            "open": 504.61,
            "high": 504.61,
            "low": 504.39,
            "close": 504.4,
            "volume": 97048,
            "datetime": 1713207960000
        },
        {
            "open": 504.41,
            "high": 504.62,
            "low": 504.365,
            "close": 504.4099,
            "volume": 104824,
            "datetime": 1713208020000
        },
        {
            "open": 504.4,
            "high": 504.52,
            "low": 504.35,
            "close": 504.36,
            "volume": 80867,
            "datetime": 1713208080000
        },
        {
            "open": 504.35,
            "high": 504.78,
            "low": 504.33,
            "close": 504.76,
            "volume": 235880,
            "datetime": 1713208140000
        },
        {
            "open": 504.78,
            "high": 504.805,
            "low": 504.6,
            "close": 504.68,
            "volume": 202613,
            "datetime": 1713208200000
        },
        {
            "open": 504.69,
            "high": 504.83,
            "low": 504.6099,
            "close": 504.78,
            "volume": 188365,
            "datetime": 1713208260000
        },
        {
            "open": 504.76,
            "high": 504.94,
            "low": 504.68,
            "close": 504.68,
            "volume": 197030,
            "datetime": 1713208320000
        },
        {
            "open": 504.67,
            "high": 504.78,
            "low": 504.51,
            "close": 504.58,
            "volume": 142521,
            "datetime": 1713208380000
        },
        {
            "open": 504.57,
            "high": 504.63,
            "low": 504.41,
            "close": 504.47,
            "volume": 136580,
            "datetime": 1713208440000
        },
        {
            "open": 504.48,
            "high": 504.71,
            "low": 504.441,
            "close": 504.52,
            "volume": 105501,
            "datetime": 1713208500000
        },
        {
            "open": 504.52,
            "high": 504.57,
            "low": 504.31,
            "close": 504.35,
            "volume": 129695,
            "datetime": 1713208560000
        },
        {
            "open": 504.355,
            "high": 504.36,
            "low": 503.69,
            "close": 503.79,
            "volume": 331310,
            "datetime": 1713208620000
        },
        {
            "open": 503.78,
            "high": 503.83,
            "low": 503.64,
            "close": 503.7,
            "volume": 230738,
            "datetime": 1713208680000
        },
        {
            "open": 503.69,
            "high": 503.79,
            "low": 503.58,
            "close": 503.67,
            "volume": 176017,
            "datetime": 1713208740000
        },
        {
            "open": 503.67,
            "high": 504.31,
            "low": 503.67,
            "close": 504.25,
            "volume": 272505,
            "datetime": 1713208800000
        },
        {
            "open": 504.24,
            "high": 504.53,
            "low": 504.19,
            "close": 504.49,
            "volume": 215685,
            "datetime": 1713208860000
        },
        {
            "open": 504.5,
            "high": 504.655,
            "low": 504.35,
            "close": 504.46,
            "volume": 178628,
            "datetime": 1713208920000
        },
        {
            "open": 504.45,
            "high": 504.76,
            "low": 504.37,
            "close": 504.75,
            "volume": 103422,
            "datetime": 1713208980000
        },
        {
            "open": 504.76,
            "high": 504.9,
            "low": 504.52,
            "close": 504.6201,
            "volume": 177049,
            "datetime": 1713209040000
        },
        {
            "open": 504.63,
            "high": 504.85,
            "low": 504.55,
            "close": 504.74,
            "volume": 72589,
            "datetime": 1713209100000
        },
        {
            "open": 504.75,
            "high": 504.76,
            "low": 504.39,
            "close": 504.48,
            "volume": 207678,
            "datetime": 1713209160000
        },
        {
            "open": 504.48,
            "high": 504.63,
            "low": 504.42,
            "close": 504.505,
            "volume": 83353,
            "datetime": 1713209220000
        },
        {
            "open": 504.5,
            "high": 504.55,
            "low": 504.33,
            "close": 504.4,
            "volume": 133536,
            "datetime": 1713209280000
        },
        {
            "open": 504.38,
            "high": 504.45,
            "low": 504.18,
            "close": 504.2599,
            "volume": 149535,
            "datetime": 1713209340000
        },
        {
            "open": 504.26,
            "high": 504.685,
            "low": 504.25,
            "close": 504.64,
            "volume": 145196,
            "datetime": 1713209400000
        },
        {
            "open": 504.61,
            "high": 505.14,
            "low": 504.47,
            "close": 505.06,
            "volume": 279029,
            "datetime": 1713209460000
        },
        {
            "open": 505.06,
            "high": 505.2686,
            "low": 504.91,
            "close": 505.195,
            "volume": 171444,
            "datetime": 1713209520000
        },
        {
            "open": 505.18,
            "high": 505.265,
            "low": 504.92,
            "close": 504.93,
            "volume": 148221,
            "datetime": 1713209580000
        },
        {
            "open": 504.9295,
            "high": 505.07,
            "low": 504.795,
            "close": 504.83,
            "volume": 188236,
            "datetime": 1713209640000
        },
        {
            "open": 504.84,
            "high": 505.01,
            "low": 504.72,
            "close": 504.81,
            "volume": 137639,
            "datetime": 1713209700000
        },
        {
            "open": 504.81,
            "high": 505.01,
            "low": 504.8,
            "close": 504.84,
            "volume": 188310,
            "datetime": 1713209760000
        },
        {
            "open": 504.83,
            "high": 504.86,
            "low": 504.5,
            "close": 504.54,
            "volume": 228095,
            "datetime": 1713209820000
        },
        {
            "open": 504.535,
            "high": 505.09,
            "low": 504.52,
            "close": 505.01,
            "volume": 243775,
            "datetime": 1713209880000
        },
        {
            "open": 505.01,
            "high": 505.055,
            "low": 504.89,
            "close": 504.965,
            "volume": 171077,
            "datetime": 1713209940000
        },
        {
            "open": 504.9599,
            "high": 505.02,
            "low": 504.74,
            "close": 504.79,
            "volume": 224680,
            "datetime": 1713210000000
        },
        {
            "open": 504.78,
            "high": 504.82,
            "low": 504.47,
            "close": 504.53,
            "volume": 277572,
            "datetime": 1713210060000
        },
        {
            "open": 504.53,
            "high": 504.62,
            "low": 504.3,
            "close": 504.46,
            "volume": 353345,
            "datetime": 1713210120000
        },
        {
            "open": 504.45,
            "high": 504.71,
            "low": 504.28,
            "close": 504.56,
            "volume": 224492,
            "datetime": 1713210180000
        },
        {
            "open": 504.565,
            "high": 504.59,
            "low": 504.3,
            "close": 504.36,
            "volume": 160950,
            "datetime": 1713210240000
        },
        {
            "open": 504.38,
            "high": 504.65,
            "low": 504.23,
            "close": 504.575,
            "volume": 324847,
            "datetime": 1713210300000
        },
        {
            "open": 504.58,
            "high": 504.63,
            "low": 504.29,
            "close": 504.4499,
            "volume": 258844,
            "datetime": 1713210360000
        },
        {
            "open": 504.44,
            "high": 504.62,
            "low": 504.33,
            "close": 504.415,
            "volume": 272596,
            "datetime": 1713210420000
        },
        {
            "open": 504.4,
            "high": 504.54,
            "low": 504.31,
            "close": 504.53,
            "volume": 224087,
            "datetime": 1713210480000
        },
        {
            "open": 504.53,
            "high": 505.26,
            "low": 504.51,
            "close": 505.15,
            "volume": 420374,
            "datetime": 1713210540000
        },
        {
            "open": 505.14,
            "high": 505.15,
            "low": 504.82,
            "close": 505.02,
            "volume": 493718,
            "datetime": 1713210600000
        },
        {
            "open": 505.03,
            "high": 505.16,
            "low": 504.67,
            "close": 504.88,
            "volume": 453183,
            "datetime": 1713210660000
        },
        {
            "open": 504.87,
            "high": 505.0989,
            "low": 504.76,
            "close": 504.89,
            "volume": 307514,
            "datetime": 1713210720000
        },
        {
            "open": 504.895,
            "high": 504.905,
            "low": 504.63,
            "close": 504.7499,
            "volume": 298646,
            "datetime": 1713210780000
        },
        {
            "open": 504.75,
            "high": 504.98,
            "low": 504.735,
            "close": 504.925,
            "volume": 466048,
            "datetime": 1713210840000
        },
        {
            "open": 504.925,
            "high": 505.1,
            "low": 504.77,
            "close": 505.1,
            "volume": 433858,
            "datetime": 1713210900000
        },
        {
            "open": 505.11,
            "high": 505.18,
            "low": 504.96,
            "close": 505.135,
            "volume": 425476,
            "datetime": 1713210960000
        },
        {
            "open": 505.14,
            "high": 505.19,
            "low": 504.855,
            "close": 504.955,
            "volume": 449328,
            "datetime": 1713211020000
        },
        {
            "open": 504.955,
            "high": 505.01,
            "low": 504.69,
            "close": 504.695,
            "volume": 817920,
            "datetime": 1713211080000
        },
        {
            "open": 504.68,
            "high": 504.93,
            "low": 504.45,
            "close": 504.5,
            "volume": 1794904,
            "datetime": 1713211140000
        },
        {
            "open": 504.94,
            "high": 505.01,
            "low": 504.56,
            "close": 504.84,
            "volume": 769476,
            "datetime": 1713274200000
        },
        {
            "open": 504.85,
            "high": 504.91,
            "low": 504.62,
            "close": 504.66,
            "volume": 216735,
            "datetime": 1713274260000
        },
        {
            "open": 504.66,
            "high": 505.12,
            "low": 504.66,
            "close": 504.9999,
            "volume": 331704,
            "datetime": 1713274320000
        },
        {
            "open": 504.99,
            "high": 505.08,
            "low": 504.57,
            "close": 504.89,
            "volume": 222357,
            "datetime": 1713274380000
        },
        {
            "open": 504.88,
            "high": 505.2,
            "low": 504.83,
            "close": 505.08,
            "volume": 341960,
            "datetime": 1713274440000
        },
        {
            "open": 505.08,
            "high": 505.09,
            "low": 504.53,
            "close": 504.57,
            "volume": 185023,
            "datetime": 1713274500000
        },
        {
            "open": 504.56,
            "high": 504.79,
            "low": 504.41,
            "close": 504.44,
            "volume": 198626,
            "datetime": 1713274560000
        },
        {
            "open": 504.435,
            "high": 504.435,
            "low": 503.82,
            "close": 503.84,
            "volume": 283958,
            "datetime": 1713274620000
        },
        {
            "open": 503.86,
            "high": 504.2,
            "low": 503.64,
            "close": 504.005,
            "volume": 248121,
            "datetime": 1713274680000
        },
        {
            "open": 504.02,
            "high": 504.45,
            "low": 504.01,
            "close": 504.09,
            "volume": 191263,
            "datetime": 1713274740000
        },
        {
            "open": 504.1,
            "high": 504.1312,
            "low": 503.82,
            "close": 503.99,
            "volume": 211622,
            "datetime": 1713274800000
        },
        {
            "open": 503.99,
            "high": 504.155,
            "low": 503.79,
            "close": 504.13,
            "volume": 114524,
            "datetime": 1713274860000
        },
        {
            "open": 504.11,
            "high": 504.13,
            "low": 503.835,
            "close": 503.96,
            "volume": 164918,
            "datetime": 1713274920000
        },
        {
            "open": 503.99,
            "high": 504.06,
            "low": 503.52,
            "close": 503.56,
            "volume": 201918,
            "datetime": 1713274980000
        },
        {
            "open": 503.55,
            "high": 503.94,
            "low": 503.5,
            "close": 503.89,
            "volume": 198176,
            "datetime": 1713275040000
        },
        {
            "open": 503.89,
            "high": 504.1,
            "low": 503.62,
            "close": 503.6403,
            "volume": 217689,
            "datetime": 1713275100000
        },
        {
            "open": 503.65,
            "high": 503.68,
            "low": 503.15,
            "close": 503.3,
            "volume": 208336,
            "datetime": 1713275160000
        },
        {
            "open": 503.32,
            "high": 503.35,
            "low": 502.95,
            "close": 503.1,
            "volume": 288705,
            "datetime": 1713275220000
        },
        {
            "open": 503.11,
            "high": 503.41,
            "low": 503.09,
            "close": 503.14,
            "volume": 112268,
            "datetime": 1713275280000
        },
        {
            "open": 503.13,
            "high": 503.58,
            "low": 503.08,
            "close": 503.41,
            "volume": 157752,
            "datetime": 1713275340000
        },
        {
            "open": 503.42,
            "high": 503.89,
            "low": 503.4,
            "close": 503.81,
            "volume": 252099,
            "datetime": 1713275400000
        },
        {
            "open": 503.81,
            "high": 504,
            "low": 503.53,
            "close": 503.85,
            "volume": 230406,
            "datetime": 1713275460000
        },
        {
            "open": 503.86,
            "high": 503.97,
            "low": 503.66,
            "close": 503.9,
            "volume": 166615,
            "datetime": 1713275520000
        },
        {
            "open": 503.9,
            "high": 503.9,
            "low": 503.67,
            "close": 503.88,
            "volume": 73522,
            "datetime": 1713275580000
        },
        {
            "open": 503.89,
            "high": 504.02,
            "low": 503.8,
            "close": 503.96,
            "volume": 107374,
            "datetime": 1713275640000
        },
        {
            "open": 503.96,
            "high": 504.23,
            "low": 503.78,
            "close": 504.2,
            "volume": 181285,
            "datetime": 1713275700000
        },
        {
            "open": 504.2,
            "high": 504.525,
            "low": 504.1499,
            "close": 504.46,
            "volume": 250381,
            "datetime": 1713275760000
        },
        {
            "open": 504.47,
            "high": 504.72,
            "low": 504.42,
            "close": 504.68,
            "volume": 265018,
            "datetime": 1713275820000
        },
        {
            "open": 504.67,
            "high": 504.95,
            "low": 504.65,
            "close": 504.88,
            "volume": 204117,
            "datetime": 1713275880000
        },
        {
            "open": 504.86,
            "high": 504.89,
            "low": 504.4,
            "close": 504.44,
            "volume": 140407,
            "datetime": 1713275940000
        },
        {
            "open": 504.42,
            "high": 504.48,
            "low": 504.13,
            "close": 504.17,
            "volume": 154530,
            "datetime": 1713276000000
        },
        {
            "open": 504.19,
            "high": 504.369,
            "low": 504.145,
            "close": 504.28,
            "volume": 101902,
            "datetime": 1713276060000
        },
        {
            "open": 504.28,
            "high": 504.385,
            "low": 504.2013,
            "close": 504.26,
            "volume": 84150,
            "datetime": 1713276120000
        },
        {
            "open": 504.27,
            "high": 504.35,
            "low": 503.9287,
            "close": 503.98,
            "volume": 123905,
            "datetime": 1713276180000
        },
        {
            "open": 503.965,
            "high": 504.39,
            "low": 503.96,
            "close": 504.3301,
            "volume": 94387,
            "datetime": 1713276240000
        },
        {
            "open": 504.34,
            "high": 504.35,
            "low": 504.09,
            "close": 504.19,
            "volume": 61016,
            "datetime": 1713276300000
        },
        {
            "open": 504.1899,
            "high": 504.26,
            "low": 504.025,
            "close": 504.03,
            "volume": 116538,
            "datetime": 1713276360000
        },
        {
            "open": 504.03,
            "high": 504.2,
            "low": 503.74,
            "close": 504.05,
            "volume": 122606,
            "datetime": 1713276420000
        },
        {
            "open": 504.06,
            "high": 504.4,
            "low": 504.06,
            "close": 504.22,
            "volume": 150681,
            "datetime": 1713276480000
        },
        {
            "open": 504.21,
            "high": 504.72,
            "low": 504.15,
            "close": 504.58,
            "volume": 248669,
            "datetime": 1713276540000
        },
        {
            "open": 504.57,
            "high": 504.59,
            "low": 504.26,
            "close": 504.45,
            "volume": 110896,
            "datetime": 1713276600000
        },
        {
            "open": 504.45,
            "high": 504.64,
            "low": 504.44,
            "close": 504.63,
            "volume": 138232,
            "datetime": 1713276660000
        },
        {
            "open": 504.63,
            "high": 504.63,
            "low": 504.39,
            "close": 504.53,
            "volume": 76978,
            "datetime": 1713276720000
        },
        {
            "open": 504.55,
            "high": 504.64,
            "low": 504.33,
            "close": 504.6,
            "volume": 63413,
            "datetime": 1713276780000
        },
        {
            "open": 504.59,
            "high": 504.97,
            "low": 504.5601,
            "close": 504.91,
            "volume": 112040,
            "datetime": 1713276840000
        },
        {
            "open": 504.9,
            "high": 505.23,
            "low": 504.82,
            "close": 505.19,
            "volume": 229361,
            "datetime": 1713276900000
        },
        {
            "open": 505.18,
            "high": 505.28,
            "low": 505.055,
            "close": 505.14,
            "volume": 148848,
            "datetime": 1713276960000
        },
        {
            "open": 505.14,
            "high": 505.14,
            "low": 504.4701,
            "close": 504.54,
            "volume": 273290,
            "datetime": 1713277020000
        },
        {
            "open": 504.53,
            "high": 504.72,
            "low": 504.5,
            "close": 504.7,
            "volume": 107754,
            "datetime": 1713277080000
        },
        {
            "open": 504.68,
            "high": 504.6901,
            "low": 504.545,
            "close": 504.65,
            "volume": 64330,
            "datetime": 1713277140000
        },
        {
            "open": 504.66,
            "high": 504.93,
            "low": 504.53,
            "close": 504.54,
            "volume": 117913,
            "datetime": 1713277200000
        },
        {
            "open": 504.5597,
            "high": 504.5597,
            "low": 504.08,
            "close": 504.18,
            "volume": 148732,
            "datetime": 1713277260000
        },
        {
            "open": 504.16,
            "high": 504.29,
            "low": 504.03,
            "close": 504.11,
            "volume": 101615,
            "datetime": 1713277320000
        },
        {
            "open": 504.1,
            "high": 504.21,
            "low": 503.91,
            "close": 503.9199,
            "volume": 97117,
            "datetime": 1713277380000
        },
        {
            "open": 503.92,
            "high": 504.14,
            "low": 503.92,
            "close": 504,
            "volume": 94328,
            "datetime": 1713277440000
        },
        {
            "open": 504.01,
            "high": 504.19,
            "low": 503.775,
            "close": 503.835,
            "volume": 99750,
            "datetime": 1713277500000
        },
        {
            "open": 503.82,
            "high": 504.07,
            "low": 503.61,
            "close": 503.8751,
            "volume": 177055,
            "datetime": 1713277560000
        },
        {
            "open": 503.88,
            "high": 503.89,
            "low": 503.61,
            "close": 503.61,
            "volume": 83421,
            "datetime": 1713277620000
        },
        {
            "open": 503.63,
            "high": 503.73,
            "low": 503.39,
            "close": 503.41,
            "volume": 133309,
            "datetime": 1713277680000
        },
        {
            "open": 503.43,
            "high": 503.635,
            "low": 503.18,
            "close": 503.18,
            "volume": 160447,
            "datetime": 1713277740000
        },
        {
            "open": 503.17,
            "high": 503.57,
            "low": 503.14,
            "close": 503.3,
            "volume": 196243,
            "datetime": 1713277800000
        },
        {
            "open": 503.3,
            "high": 503.41,
            "low": 503.22,
            "close": 503.33,
            "volume": 121375,
            "datetime": 1713277860000
        },
        {
            "open": 503.31,
            "high": 503.36,
            "low": 503.16,
            "close": 503.17,
            "volume": 92128,
            "datetime": 1713277920000
        },
        {
            "open": 503.17,
            "high": 503.22,
            "low": 502.86,
            "close": 503.19,
            "volume": 226269,
            "datetime": 1713277980000
        },
        {
            "open": 503.18,
            "high": 503.36,
            "low": 503.065,
            "close": 503.09,
            "volume": 157184,
            "datetime": 1713278040000
        },
        {
            "open": 503.0801,
            "high": 503.24,
            "low": 503.01,
            "close": 503.17,
            "volume": 86403,
            "datetime": 1713278100000
        },
        {
            "open": 503.16,
            "high": 503.33,
            "low": 502.9,
            "close": 502.94,
            "volume": 140995,
            "datetime": 1713278160000
        },
        {
            "open": 502.9394,
            "high": 502.9501,
            "low": 502.42,
            "close": 502.56,
            "volume": 235034,
            "datetime": 1713278220000
        },
        {
            "open": 502.56,
            "high": 502.7,
            "low": 502.52,
            "close": 502.6301,
            "volume": 123020,
            "datetime": 1713278280000
        },
        {
            "open": 502.62,
            "high": 502.95,
            "low": 502.58,
            "close": 502.86,
            "volume": 129695,
            "datetime": 1713278340000
        },
        {
            "open": 502.85,
            "high": 503.17,
            "low": 502.8,
            "close": 502.835,
            "volume": 102917,
            "datetime": 1713278400000
        },
        {
            "open": 502.835,
            "high": 503.01,
            "low": 502.8,
            "close": 502.97,
            "volume": 116780,
            "datetime": 1713278460000
        },
        {
            "open": 502.95,
            "high": 503.0399,
            "low": 502.76,
            "close": 502.79,
            "volume": 105926,
            "datetime": 1713278520000
        },
        {
            "open": 502.8,
            "high": 503.05,
            "low": 502.8,
            "close": 502.98,
            "volume": 121857,
            "datetime": 1713278580000
        },
        {
            "open": 502.98,
            "high": 503.37,
            "low": 502.98,
            "close": 503.25,
            "volume": 129779,
            "datetime": 1713278640000
        },
        {
            "open": 503.23,
            "high": 503.48,
            "low": 503.2,
            "close": 503.4,
            "volume": 159871,
            "datetime": 1713278700000
        },
        {
            "open": 503.38,
            "high": 503.49,
            "low": 503.1788,
            "close": 503.2,
            "volume": 175215,
            "datetime": 1713278760000
        },
        {
            "open": 503.2,
            "high": 503.44,
            "low": 503.16,
            "close": 503.34,
            "volume": 55664,
            "datetime": 1713278820000
        },
        {
            "open": 503.31,
            "high": 503.77,
            "low": 503.31,
            "close": 503.65,
            "volume": 162474,
            "datetime": 1713278880000
        },
        {
            "open": 503.64,
            "high": 503.68,
            "low": 503.395,
            "close": 503.4,
            "volume": 145074,
            "datetime": 1713278940000
        },
        {
            "open": 503.41,
            "high": 503.475,
            "low": 503.1502,
            "close": 503.18,
            "volume": 113158,
            "datetime": 1713279000000
        },
        {
            "open": 503.16,
            "high": 503.22,
            "low": 502.83,
            "close": 503,
            "volume": 152161,
            "datetime": 1713279060000
        },
        {
            "open": 503.01,
            "high": 503.28,
            "low": 502.98,
            "close": 503.16,
            "volume": 149974,
            "datetime": 1713279120000
        },
        {
            "open": 503.14,
            "high": 503.41,
            "low": 503.1196,
            "close": 503.37,
            "volume": 64986,
            "datetime": 1713279180000
        },
        {
            "open": 503.38,
            "high": 503.41,
            "low": 503.17,
            "close": 503.19,
            "volume": 73111,
            "datetime": 1713279240000
        },
        {
            "open": 503.18,
            "high": 503.33,
            "low": 503.05,
            "close": 503.105,
            "volume": 198041,
            "datetime": 1713279300000
        },
        {
            "open": 503.09,
            "high": 503.4,
            "low": 503.07,
            "close": 503.225,
            "volume": 109625,
            "datetime": 1713279360000
        },
        {
            "open": 503.21,
            "high": 503.275,
            "low": 503.02,
            "close": 503.1399,
            "volume": 136847,
            "datetime": 1713279420000
        },
        {
            "open": 503.12,
            "high": 503.18,
            "low": 502.95,
            "close": 503.13,
            "volume": 95323,
            "datetime": 1713279480000
        },
        {
            "open": 503.13,
            "high": 503.18,
            "low": 503.01,
            "close": 503.1015,
            "volume": 104339,
            "datetime": 1713279540000
        },
        {
            "open": 503.1,
            "high": 503.2217,
            "low": 502.9,
            "close": 503.075,
            "volume": 91816,
            "datetime": 1713279600000
        },
        {
            "open": 503.08,
            "high": 503.15,
            "low": 502.875,
            "close": 503.04,
            "volume": 106331,
            "datetime": 1713279660000
        },
        {
            "open": 503.05,
            "high": 503.35,
            "low": 503.025,
            "close": 503.31,
            "volume": 109214,
            "datetime": 1713279720000
        },
        {
            "open": 503.3,
            "high": 503.61,
            "low": 503.27,
            "close": 503.61,
            "volume": 125540,
            "datetime": 1713279780000
        },
        {
            "open": 503.5918,
            "high": 503.7,
            "low": 503.3709,
            "close": 503.455,
            "volume": 163042,
            "datetime": 1713279840000
        },
        {
            "open": 503.46,
            "high": 503.56,
            "low": 503.35,
            "close": 503.51,
            "volume": 106201,
            "datetime": 1713279900000
        },
        {
            "open": 503.5,
            "high": 503.84,
            "low": 503.455,
            "close": 503.59,
            "volume": 127734,
            "datetime": 1713279960000
        },
        {
            "open": 503.5975,
            "high": 503.67,
            "low": 503.41,
            "close": 503.56,
            "volume": 98929,
            "datetime": 1713280020000
        },
        {
            "open": 503.55,
            "high": 503.825,
            "low": 503.55,
            "close": 503.7612,
            "volume": 108188,
            "datetime": 1713280080000
        },
        {
            "open": 503.76,
            "high": 503.85,
            "low": 503.66,
            "close": 503.72,
            "volume": 62616,
            "datetime": 1713280140000
        },
        {
            "open": 503.7187,
            "high": 503.93,
            "low": 503.685,
            "close": 503.765,
            "volume": 156874,
            "datetime": 1713280200000
        },
        {
            "open": 503.75,
            "high": 503.7669,
            "low": 503.5626,
            "close": 503.72,
            "volume": 108296,
            "datetime": 1713280260000
        },
        {
            "open": 503.72,
            "high": 503.86,
            "low": 503.695,
            "close": 503.74,
            "volume": 81233,
            "datetime": 1713280320000
        },
        {
            "open": 503.7311,
            "high": 503.7311,
            "low": 503.4,
            "close": 503.41,
            "volume": 82571,
            "datetime": 1713280380000
        },
        {
            "open": 503.4,
            "high": 503.625,
            "low": 503.39,
            "close": 503.545,
            "volume": 80820,
            "datetime": 1713280440000
        },
        {
            "open": 503.53,
            "high": 503.68,
            "low": 503.46,
            "close": 503.52,
            "volume": 70555,
            "datetime": 1713280500000
        },
        {
            "open": 503.5,
            "high": 503.91,
            "low": 503.44,
            "close": 503.825,
            "volume": 92546,
            "datetime": 1713280560000
        },
        {
            "open": 503.83,
            "high": 504.11,
            "low": 503.795,
            "close": 503.85,
            "volume": 142040,
            "datetime": 1713280620000
        },
        {
            "open": 503.86,
            "high": 503.865,
            "low": 503.5687,
            "close": 503.65,
            "volume": 67934,
            "datetime": 1713280680000
        },
        {
            "open": 503.65,
            "high": 503.72,
            "low": 503.5399,
            "close": 503.54,
            "volume": 65787,
            "datetime": 1713280740000
        },
        {
            "open": 503.52,
            "high": 503.65,
            "low": 503.465,
            "close": 503.58,
            "volume": 79659,
            "datetime": 1713280800000
        },
        {
            "open": 503.58,
            "high": 503.6501,
            "low": 503.42,
            "close": 503.47,
            "volume": 38103,
            "datetime": 1713280860000
        },
        {
            "open": 503.47,
            "high": 503.74,
            "low": 503.42,
            "close": 503.74,
            "volume": 53303,
            "datetime": 1713280920000
        },
        {
            "open": 503.72,
            "high": 504.175,
            "low": 503.69,
            "close": 504.12,
            "volume": 110530,
            "datetime": 1713280980000
        },
        {
            "open": 504.11,
            "high": 504.23,
            "low": 504.07,
            "close": 504.1293,
            "volume": 112125,
            "datetime": 1713281040000
        },
        {
            "open": 504.12,
            "high": 504.2899,
            "low": 504.02,
            "close": 504.19,
            "volume": 77727,
            "datetime": 1713281100000
        },
        {
            "open": 504.18,
            "high": 504.34,
            "low": 504.17,
            "close": 504.25,
            "volume": 93743,
            "datetime": 1713281160000
        },
        {
            "open": 504.2359,
            "high": 504.33,
            "low": 504.12,
            "close": 504.22,
            "volume": 63746,
            "datetime": 1713281220000
        },
        {
            "open": 504.24,
            "high": 504.3,
            "low": 504.08,
            "close": 504.14,
            "volume": 85724,
            "datetime": 1713281280000
        },
        {
            "open": 504.13,
            "high": 504.2,
            "low": 504.035,
            "close": 504.1821,
            "volume": 88507,
            "datetime": 1713281340000
        },
        {
            "open": 504.185,
            "high": 504.21,
            "low": 503.92,
            "close": 503.92,
            "volume": 109489,
            "datetime": 1713281400000
        },
        {
            "open": 503.92,
            "high": 503.92,
            "low": 503.715,
            "close": 503.79,
            "volume": 120206,
            "datetime": 1713281460000
        },
        {
            "open": 503.79,
            "high": 503.83,
            "low": 503.63,
            "close": 503.83,
            "volume": 72733,
            "datetime": 1713281520000
        },
        {
            "open": 503.82,
            "high": 504,
            "low": 503.8,
            "close": 503.8,
            "volume": 54675,
            "datetime": 1713281580000
        },
        {
            "open": 503.8,
            "high": 504.14,
            "low": 503.75,
            "close": 504.11,
            "volume": 52748,
            "datetime": 1713281640000
        },
        {
            "open": 504.12,
            "high": 504.1903,
            "low": 504,
            "close": 504.05,
            "volume": 55364,
            "datetime": 1713281700000
        },
        {
            "open": 504.02,
            "high": 504.08,
            "low": 503.94,
            "close": 504.06,
            "volume": 40970,
            "datetime": 1713281760000
        },
        {
            "open": 504.0608,
            "high": 504.12,
            "low": 503.88,
            "close": 503.905,
            "volume": 49117,
            "datetime": 1713281820000
        },
        {
            "open": 503.92,
            "high": 504.1613,
            "low": 503.91,
            "close": 504.105,
            "volume": 75777,
            "datetime": 1713281880000
        },
        {
            "open": 504.1,
            "high": 504.12,
            "low": 503.8,
            "close": 503.82,
            "volume": 63555,
            "datetime": 1713281940000
        },
        {
            "open": 503.81,
            "high": 504.07,
            "low": 503.81,
            "close": 504.0692,
            "volume": 126166,
            "datetime": 1713282000000
        },
        {
            "open": 504.08,
            "high": 504.19,
            "low": 503.95,
            "close": 504.19,
            "volume": 49729,
            "datetime": 1713282060000
        },
        {
            "open": 504.2,
            "high": 504.44,
            "low": 504.2,
            "close": 504.4099,
            "volume": 86623,
            "datetime": 1713282120000
        },
        {
            "open": 504.415,
            "high": 504.45,
            "low": 504.335,
            "close": 504.4103,
            "volume": 55872,
            "datetime": 1713282180000
        },
        {
            "open": 504.42,
            "high": 504.44,
            "low": 504.31,
            "close": 504.365,
            "volume": 53497,
            "datetime": 1713282240000
        },
        {
            "open": 504.3698,
            "high": 504.37,
            "low": 504.04,
            "close": 504.06,
            "volume": 121983,
            "datetime": 1713282300000
        },
        {
            "open": 504.09,
            "high": 504.245,
            "low": 503.97,
            "close": 504.23,
            "volume": 122975,
            "datetime": 1713282360000
        },
        {
            "open": 504.24,
            "high": 504.38,
            "low": 504.1604,
            "close": 504.38,
            "volume": 77303,
            "datetime": 1713282420000
        },
        {
            "open": 504.39,
            "high": 504.64,
            "low": 504.38,
            "close": 504.54,
            "volume": 110732,
            "datetime": 1713282480000
        },
        {
            "open": 504.5312,
            "high": 504.5312,
            "low": 504.22,
            "close": 504.33,
            "volume": 64181,
            "datetime": 1713282540000
        },
        {
            "open": 504.33,
            "high": 504.65,
            "low": 504.33,
            "close": 504.6319,
            "volume": 44077,
            "datetime": 1713282600000
        },
        {
            "open": 504.6379,
            "high": 504.81,
            "low": 504.59,
            "close": 504.72,
            "volume": 151811,
            "datetime": 1713282660000
        },
        {
            "open": 504.7001,
            "high": 504.865,
            "low": 504.7001,
            "close": 504.85,
            "volume": 120801,
            "datetime": 1713282720000
        },
        {
            "open": 504.85,
            "high": 504.995,
            "low": 504.77,
            "close": 504.96,
            "volume": 156979,
            "datetime": 1713282780000
        },
        {
            "open": 504.96,
            "high": 505.3151,
            "low": 504.87,
            "close": 505.3,
            "volume": 150798,
            "datetime": 1713282840000
        },
        {
            "open": 505.29,
            "high": 505.36,
            "low": 505.2,
            "close": 505.2,
            "volume": 128014,
            "datetime": 1713282900000
        },
        {
            "open": 505.2001,
            "high": 505.35,
            "low": 505.06,
            "close": 505.07,
            "volume": 114056,
            "datetime": 1713282960000
        },
        {
            "open": 505.06,
            "high": 505.09,
            "low": 504.7,
            "close": 504.8,
            "volume": 155834,
            "datetime": 1713283020000
        },
        {
            "open": 504.7999,
            "high": 504.8,
            "low": 504.53,
            "close": 504.58,
            "volume": 139622,
            "datetime": 1713283080000
        },
        {
            "open": 504.57,
            "high": 504.57,
            "low": 504.18,
            "close": 504.245,
            "volume": 141780,
            "datetime": 1713283140000
        },
        {
            "open": 504.24,
            "high": 504.26,
            "low": 503.81,
            "close": 503.9,
            "volume": 202118,
            "datetime": 1713283200000
        },
        {
            "open": 503.89,
            "high": 503.9987,
            "low": 503.59,
            "close": 503.63,
            "volume": 244582,
            "datetime": 1713283260000
        },
        {
            "open": 503.64,
            "high": 503.92,
            "low": 503.61,
            "close": 503.78,
            "volume": 114717,
            "datetime": 1713283320000
        },
        {
            "open": 503.76,
            "high": 503.8695,
            "low": 503.5,
            "close": 503.58,
            "volume": 111640,
            "datetime": 1713283380000
        },
        {
            "open": 503.58,
            "high": 503.83,
            "low": 503.58,
            "close": 503.8,
            "volume": 139256,
            "datetime": 1713283440000
        },
        {
            "open": 503.79,
            "high": 503.83,
            "low": 503.64,
            "close": 503.76,
            "volume": 52744,
            "datetime": 1713283500000
        },
        {
            "open": 503.76,
            "high": 504,
            "low": 503.7,
            "close": 503.94,
            "volume": 115600,
            "datetime": 1713283560000
        },
        {
            "open": 503.95,
            "high": 504.19,
            "low": 503.93,
            "close": 504.14,
            "volume": 56063,
            "datetime": 1713283620000
        },
        {
            "open": 504.14,
            "high": 504.475,
            "low": 504.04,
            "close": 504.45,
            "volume": 135511,
            "datetime": 1713283680000
        },
        {
            "open": 504.5,
            "high": 504.6,
            "low": 504.33,
            "close": 504.52,
            "volume": 109484,
            "datetime": 1713283740000
        },
        {
            "open": 504.52,
            "high": 504.685,
            "low": 504.45,
            "close": 504.5401,
            "volume": 102508,
            "datetime": 1713283800000
        },
        {
            "open": 504.54,
            "high": 504.64,
            "low": 504.4,
            "close": 504.5896,
            "volume": 73351,
            "datetime": 1713283860000
        },
        {
            "open": 504.59,
            "high": 504.62,
            "low": 504.22,
            "close": 504.27,
            "volume": 75798,
            "datetime": 1713283920000
        },
        {
            "open": 504.2601,
            "high": 504.6624,
            "low": 504.2601,
            "close": 504.56,
            "volume": 65623,
            "datetime": 1713283980000
        },
        {
            "open": 504.54,
            "high": 504.6497,
            "low": 504.505,
            "close": 504.6396,
            "volume": 51679,
            "datetime": 1713284040000
        },
        {
            "open": 504.63,
            "high": 504.77,
            "low": 504.51,
            "close": 504.77,
            "volume": 65473,
            "datetime": 1713284100000
        },
        {
            "open": 504.77,
            "high": 504.77,
            "low": 504.565,
            "close": 504.73,
            "volume": 55970,
            "datetime": 1713284160000
        },
        {
            "open": 504.72,
            "high": 504.77,
            "low": 504.605,
            "close": 504.77,
            "volume": 63719,
            "datetime": 1713284220000
        },
        {
            "open": 504.76,
            "high": 504.7999,
            "low": 504.53,
            "close": 504.61,
            "volume": 68089,
            "datetime": 1713284280000
        },
        {
            "open": 504.605,
            "high": 504.7199,
            "low": 504.531,
            "close": 504.665,
            "volume": 60472,
            "datetime": 1713284340000
        },
        {
            "open": 504.66,
            "high": 504.66,
            "low": 504.44,
            "close": 504.59,
            "volume": 59485,
            "datetime": 1713284400000
        },
        {
            "open": 504.55,
            "high": 504.82,
            "low": 504.51,
            "close": 504.8,
            "volume": 43451,
            "datetime": 1713284460000
        },
        {
            "open": 504.8,
            "high": 504.805,
            "low": 504.6599,
            "close": 504.72,
            "volume": 56084,
            "datetime": 1713284520000
        },
        {
            "open": 504.724,
            "high": 504.78,
            "low": 504.59,
            "close": 504.675,
            "volume": 61013,
            "datetime": 1713284580000
        },
        {
            "open": 504.67,
            "high": 504.71,
            "low": 504.49,
            "close": 504.63,
            "volume": 35234,
            "datetime": 1713284640000
        },
        {
            "open": 504.61,
            "high": 504.796,
            "low": 504.5899,
            "close": 504.796,
            "volume": 50699,
            "datetime": 1713284700000
        },
        {
            "open": 504.795,
            "high": 504.85,
            "low": 504.66,
            "close": 504.77,
            "volume": 52967,
            "datetime": 1713284760000
        },
        {
            "open": 504.74,
            "high": 504.82,
            "low": 504.66,
            "close": 504.75,
            "volume": 37825,
            "datetime": 1713284820000
        },
        {
            "open": 504.79,
            "high": 505,
            "low": 504.78,
            "close": 504.8095,
            "volume": 69497,
            "datetime": 1713284880000
        },
        {
            "open": 504.79,
            "high": 504.815,
            "low": 504.38,
            "close": 504.4901,
            "volume": 91429,
            "datetime": 1713284940000
        },
        {
            "open": 504.49,
            "high": 504.88,
            "low": 504.4188,
            "close": 504.79,
            "volume": 126247,
            "datetime": 1713285000000
        },
        {
            "open": 504.78,
            "high": 504.7993,
            "low": 504.475,
            "close": 504.51,
            "volume": 55829,
            "datetime": 1713285060000
        },
        {
            "open": 504.51,
            "high": 504.66,
            "low": 504.445,
            "close": 504.56,
            "volume": 39953,
            "datetime": 1713285120000
        },
        {
            "open": 504.54,
            "high": 504.59,
            "low": 504.43,
            "close": 504.5602,
            "volume": 43076,
            "datetime": 1713285180000
        },
        {
            "open": 504.56,
            "high": 504.67,
            "low": 504.441,
            "close": 504.45,
            "volume": 67188,
            "datetime": 1713285240000
        },
        {
            "open": 504.44,
            "high": 504.48,
            "low": 504.25,
            "close": 504.41,
            "volume": 79879,
            "datetime": 1713285300000
        },
        {
            "open": 504.43,
            "high": 504.6,
            "low": 504.3837,
            "close": 504.46,
            "volume": 121296,
            "datetime": 1713285360000
        },
        {
            "open": 504.48,
            "high": 504.55,
            "low": 504.26,
            "close": 504.32,
            "volume": 54164,
            "datetime": 1713285420000
        },
        {
            "open": 504.339,
            "high": 504.54,
            "low": 504.26,
            "close": 504.48,
            "volume": 44510,
            "datetime": 1713285480000
        },
        {
            "open": 504.49,
            "high": 504.57,
            "low": 504.37,
            "close": 504.42,
            "volume": 47662,
            "datetime": 1713285540000
        },
        {
            "open": 504.45,
            "high": 504.65,
            "low": 504.43,
            "close": 504.53,
            "volume": 55492,
            "datetime": 1713285600000
        },
        {
            "open": 504.51,
            "high": 504.7604,
            "low": 504.455,
            "close": 504.7604,
            "volume": 61616,
            "datetime": 1713285660000
        },
        {
            "open": 504.7697,
            "high": 504.805,
            "low": 504.55,
            "close": 504.6,
            "volume": 39832,
            "datetime": 1713285720000
        },
        {
            "open": 504.59,
            "high": 504.635,
            "low": 504.23,
            "close": 504.325,
            "volume": 66063,
            "datetime": 1713285780000
        },
        {
            "open": 504.33,
            "high": 504.4,
            "low": 504.15,
            "close": 504.25,
            "volume": 89556,
            "datetime": 1713285840000
        },
        {
            "open": 504.25,
            "high": 504.35,
            "low": 504.21,
            "close": 504.31,
            "volume": 45677,
            "datetime": 1713285900000
        },
        {
            "open": 504.31,
            "high": 504.56,
            "low": 504.19,
            "close": 504.4176,
            "volume": 88072,
            "datetime": 1713285960000
        },
        {
            "open": 504.43,
            "high": 504.62,
            "low": 504.325,
            "close": 504.61,
            "volume": 40585,
            "datetime": 1713286020000
        },
        {
            "open": 504.61,
            "high": 504.88,
            "low": 504.61,
            "close": 504.82,
            "volume": 58102,
            "datetime": 1713286080000
        },
        {
            "open": 504.8101,
            "high": 504.9199,
            "low": 504.695,
            "close": 504.9099,
            "volume": 45117,
            "datetime": 1713286140000
        },
        {
            "open": 504.8501,
            "high": 505.0097,
            "low": 504.7,
            "close": 504.97,
            "volume": 49336,
            "datetime": 1713286200000
        },
        {
            "open": 504.96,
            "high": 505.1,
            "low": 504.82,
            "close": 504.8585,
            "volume": 103868,
            "datetime": 1713286260000
        },
        {
            "open": 504.84,
            "high": 504.95,
            "low": 504.81,
            "close": 504.8862,
            "volume": 46335,
            "datetime": 1713286320000
        },
        {
            "open": 504.875,
            "high": 504.9,
            "low": 504.66,
            "close": 504.66,
            "volume": 68486,
            "datetime": 1713286380000
        },
        {
            "open": 504.64,
            "high": 504.7599,
            "low": 504.52,
            "close": 504.56,
            "volume": 48281,
            "datetime": 1713286440000
        },
        {
            "open": 504.59,
            "high": 504.65,
            "low": 504.49,
            "close": 504.5288,
            "volume": 58665,
            "datetime": 1713286500000
        },
        {
            "open": 504.54,
            "high": 504.61,
            "low": 504.405,
            "close": 504.4899,
            "volume": 50197,
            "datetime": 1713286560000
        },
        {
            "open": 504.525,
            "high": 504.53,
            "low": 504.2101,
            "close": 504.3,
            "volume": 44127,
            "datetime": 1713286620000
        },
        {
            "open": 504.3,
            "high": 504.52,
            "low": 504.295,
            "close": 504.4399,
            "volume": 55928,
            "datetime": 1713286680000
        },
        {
            "open": 504.43,
            "high": 504.7023,
            "low": 504.41,
            "close": 504.5187,
            "volume": 62636,
            "datetime": 1713286740000
        },
        {
            "open": 504.55,
            "high": 504.65,
            "low": 504.34,
            "close": 504.39,
            "volume": 50615,
            "datetime": 1713286800000
        },
        {
            "open": 504.39,
            "high": 504.45,
            "low": 504.14,
            "close": 504.1894,
            "volume": 71394,
            "datetime": 1713286860000
        },
        {
            "open": 504.18,
            "high": 504.265,
            "low": 504.03,
            "close": 504.185,
            "volume": 66930,
            "datetime": 1713286920000
        },
        {
            "open": 504.19,
            "high": 504.25,
            "low": 503.97,
            "close": 504.01,
            "volume": 72491,
            "datetime": 1713286980000
        },
        {
            "open": 504.009,
            "high": 504.12,
            "low": 503.79,
            "close": 503.93,
            "volume": 70908,
            "datetime": 1713287040000
        },
        {
            "open": 503.95,
            "high": 503.9504,
            "low": 503.74,
            "close": 503.89,
            "volume": 69555,
            "datetime": 1713287100000
        },
        {
            "open": 503.87,
            "high": 504.06,
            "low": 503.8207,
            "close": 503.89,
            "volume": 72742,
            "datetime": 1713287160000
        },
        {
            "open": 503.87,
            "high": 504.5,
            "low": 503.87,
            "close": 504.44,
            "volume": 89145,
            "datetime": 1713287220000
        },
        {
            "open": 504.45,
            "high": 504.74,
            "low": 504.43,
            "close": 504.57,
            "volume": 97524,
            "datetime": 1713287280000
        },
        {
            "open": 504.5701,
            "high": 504.69,
            "low": 504.5,
            "close": 504.6,
            "volume": 57098,
            "datetime": 1713287340000
        },
        {
            "open": 504.58,
            "high": 504.7,
            "low": 504.4488,
            "close": 504.52,
            "volume": 68764,
            "datetime": 1713287400000
        },
        {
            "open": 504.51,
            "high": 504.63,
            "low": 504.44,
            "close": 504.55,
            "volume": 57342,
            "datetime": 1713287460000
        },
        {
            "open": 504.52,
            "high": 504.54,
            "low": 504.12,
            "close": 504.31,
            "volume": 101461,
            "datetime": 1713287520000
        },
        {
            "open": 504.31,
            "high": 504.72,
            "low": 504.29,
            "close": 504.64,
            "volume": 84429,
            "datetime": 1713287580000
        },
        {
            "open": 504.65,
            "high": 504.81,
            "low": 504.5551,
            "close": 504.64,
            "volume": 74021,
            "datetime": 1713287640000
        },
        {
            "open": 504.61,
            "high": 504.7,
            "low": 504.33,
            "close": 504.41,
            "volume": 98471,
            "datetime": 1713287700000
        },
        {
            "open": 504.41,
            "high": 504.7,
            "low": 504.41,
            "close": 504.69,
            "volume": 68310,
            "datetime": 1713287760000
        },
        {
            "open": 504.705,
            "high": 504.74,
            "low": 504.48,
            "close": 504.71,
            "volume": 80920,
            "datetime": 1713287820000
        },
        {
            "open": 504.71,
            "high": 504.7897,
            "low": 504.63,
            "close": 504.7,
            "volume": 58302,
            "datetime": 1713287880000
        },
        {
            "open": 504.68,
            "high": 504.9,
            "low": 504.55,
            "close": 504.707,
            "volume": 92644,
            "datetime": 1713287940000
        },
        {
            "open": 504.71,
            "high": 504.84,
            "low": 504.63,
            "close": 504.78,
            "volume": 47075,
            "datetime": 1713288000000
        },
        {
            "open": 504.78,
            "high": 504.905,
            "low": 504.48,
            "close": 504.58,
            "volume": 114665,
            "datetime": 1713288060000
        },
        {
            "open": 504.56,
            "high": 504.7599,
            "low": 504.52,
            "close": 504.75,
            "volume": 55107,
            "datetime": 1713288120000
        },
        {
            "open": 504.78,
            "high": 504.9195,
            "low": 504.73,
            "close": 504.91,
            "volume": 53007,
            "datetime": 1713288180000
        },
        {
            "open": 504.91,
            "high": 504.95,
            "low": 504.73,
            "close": 504.92,
            "volume": 87157,
            "datetime": 1713288240000
        },
        {
            "open": 504.92,
            "high": 504.93,
            "low": 504.595,
            "close": 504.67,
            "volume": 56453,
            "datetime": 1713288300000
        },
        {
            "open": 504.66,
            "high": 504.85,
            "low": 504.64,
            "close": 504.81,
            "volume": 49092,
            "datetime": 1713288360000
        },
        {
            "open": 504.81,
            "high": 504.9597,
            "low": 504.73,
            "close": 504.88,
            "volume": 38207,
            "datetime": 1713288420000
        },
        {
            "open": 504.87,
            "high": 505.05,
            "low": 504.82,
            "close": 505.035,
            "volume": 70213,
            "datetime": 1713288480000
        },
        {
            "open": 505.03,
            "high": 505.03,
            "low": 504.12,
            "close": 504.33,
            "volume": 332233,
            "datetime": 1713288540000
        },
        {
            "open": 504.34,
            "high": 504.49,
            "low": 502.82,
            "close": 502.85,
            "volume": 668798,
            "datetime": 1713288600000
        },
        {
            "open": 502.87,
            "high": 503.5,
            "low": 502.53,
            "close": 502.53,
            "volume": 462715,
            "datetime": 1713288660000
        },
        {
            "open": 502.53,
            "high": 503.56,
            "low": 502.21,
            "close": 503.4,
            "volume": 713391,
            "datetime": 1713288720000
        },
        {
            "open": 503.46,
            "high": 503.465,
            "low": 502.87,
            "close": 503.13,
            "volume": 287364,
            "datetime": 1713288780000
        },
        {
            "open": 503.11,
            "high": 503.53,
            "low": 502.94,
            "close": 503.4701,
            "volume": 196644,
            "datetime": 1713288840000
        },
        {
            "open": 503.5,
            "high": 504.25,
            "low": 503.4514,
            "close": 503.91,
            "volume": 428504,
            "datetime": 1713288900000
        },
        {
            "open": 503.91,
            "high": 504.62,
            "low": 503.8,
            "close": 504.35,
            "volume": 252035,
            "datetime": 1713288960000
        },
        {
            "open": 504.3,
            "high": 504.31,
            "low": 503.42,
            "close": 503.64,
            "volume": 206907,
            "datetime": 1713289020000
        },
        {
            "open": 503.62,
            "high": 504.28,
            "low": 503.59,
            "close": 504.22,
            "volume": 147169,
            "datetime": 1713289080000
        },
        {
            "open": 504.2,
            "high": 504.2,
            "low": 503.52,
            "close": 504.01,
            "volume": 214472,
            "datetime": 1713289140000
        },
        {
            "open": 504.02,
            "high": 504.855,
            "low": 504.02,
            "close": 504.57,
            "volume": 309498,
            "datetime": 1713289200000
        },
        {
            "open": 504.56,
            "high": 504.68,
            "low": 504.31,
            "close": 504.39,
            "volume": 119851,
            "datetime": 1713289260000
        },
        {
            "open": 504.41,
            "high": 504.54,
            "low": 504.27,
            "close": 504.325,
            "volume": 197684,
            "datetime": 1713289320000
        },
        {
            "open": 504.36,
            "high": 504.57,
            "low": 504.2,
            "close": 504.37,
            "volume": 117712,
            "datetime": 1713289380000
        },
        {
            "open": 504.42,
            "high": 504.47,
            "low": 503.96,
            "close": 504.09,
            "volume": 129752,
            "datetime": 1713289440000
        },
        {
            "open": 504.12,
            "high": 504.59,
            "low": 504.12,
            "close": 504.35,
            "volume": 111190,
            "datetime": 1713289500000
        },
        {
            "open": 504.37,
            "high": 504.42,
            "low": 503.85,
            "close": 504.23,
            "volume": 141215,
            "datetime": 1713289560000
        },
        {
            "open": 504.24,
            "high": 504.41,
            "low": 503.72,
            "close": 503.85,
            "volume": 105303,
            "datetime": 1713289620000
        },
        {
            "open": 503.85,
            "high": 504.07,
            "low": 503.61,
            "close": 503.94,
            "volume": 91923,
            "datetime": 1713289680000
        },
        {
            "open": 503.9,
            "high": 504.06,
            "low": 503.35,
            "close": 503.38,
            "volume": 129990,
            "datetime": 1713289740000
        },
        {
            "open": 503.41,
            "high": 503.79,
            "low": 503.35,
            "close": 503.61,
            "volume": 122970,
            "datetime": 1713289800000
        },
        {
            "open": 503.63,
            "high": 503.74,
            "low": 503.04,
            "close": 503.14,
            "volume": 169373,
            "datetime": 1713289860000
        },
        {
            "open": 503.15,
            "high": 503.22,
            "low": 502.62,
            "close": 502.86,
            "volume": 281459,
            "datetime": 1713289920000
        },
        {
            "open": 502.83,
            "high": 503.25,
            "low": 502.82,
            "close": 503.12,
            "volume": 196275,
            "datetime": 1713289980000
        },
        {
            "open": 503.06,
            "high": 504.09,
            "low": 503.05,
            "close": 503.72,
            "volume": 332135,
            "datetime": 1713290040000
        },
        {
            "open": 503.72,
            "high": 503.81,
            "low": 503.52,
            "close": 503.72,
            "volume": 97252,
            "datetime": 1713290100000
        },
        {
            "open": 503.76,
            "high": 503.92,
            "low": 503.58,
            "close": 503.6851,
            "volume": 73068,
            "datetime": 1713290160000
        },
        {
            "open": 503.67,
            "high": 503.8,
            "low": 503.48,
            "close": 503.71,
            "volume": 54352,
            "datetime": 1713290220000
        },
        {
            "open": 503.74,
            "high": 503.86,
            "low": 503.52,
            "close": 503.655,
            "volume": 79667,
            "datetime": 1713290280000
        },
        {
            "open": 503.64,
            "high": 504.13,
            "low": 503.6,
            "close": 503.9699,
            "volume": 104431,
            "datetime": 1713290340000
        },
        {
            "open": 503.96,
            "high": 503.97,
            "low": 503.59,
            "close": 503.684,
            "volume": 117230,
            "datetime": 1713290400000
        },
        {
            "open": 503.67,
            "high": 503.92,
            "low": 503.47,
            "close": 503.54,
            "volume": 74382,
            "datetime": 1713290460000
        },
        {
            "open": 503.49,
            "high": 503.73,
            "low": 503.41,
            "close": 503.63,
            "volume": 70400,
            "datetime": 1713290520000
        },
        {
            "open": 503.64,
            "high": 504.02,
            "low": 503.64,
            "close": 503.76,
            "volume": 124285,
            "datetime": 1713290580000
        },
        {
            "open": 503.7677,
            "high": 504.3,
            "low": 503.7677,
            "close": 504.23,
            "volume": 127907,
            "datetime": 1713290640000
        },
        {
            "open": 504.23,
            "high": 504.45,
            "low": 504.15,
            "close": 504.31,
            "volume": 212351,
            "datetime": 1713290700000
        },
        {
            "open": 504.3,
            "high": 504.625,
            "low": 504.16,
            "close": 504.6,
            "volume": 156307,
            "datetime": 1713290760000
        },
        {
            "open": 504.6,
            "high": 505.41,
            "low": 504.57,
            "close": 505.32,
            "volume": 336816,
            "datetime": 1713290820000
        },
        {
            "open": 505.31,
            "high": 505.92,
            "low": 505.3,
            "close": 505.92,
            "volume": 523005,
            "datetime": 1713290880000
        },
        {
            "open": 505.93,
            "high": 506.36,
            "low": 505.93,
            "close": 506.295,
            "volume": 338991,
            "datetime": 1713290940000
        },
        {
            "open": 506.29,
            "high": 506.29,
            "low": 505.88,
            "close": 506.16,
            "volume": 209595,
            "datetime": 1713291000000
        },
        {
            "open": 506.15,
            "high": 506.3,
            "low": 506,
            "close": 506.15,
            "volume": 121757,
            "datetime": 1713291060000
        },
        {
            "open": 506.15,
            "high": 506.5,
            "low": 505.9,
            "close": 505.9301,
            "volume": 233888,
            "datetime": 1713291120000
        },
        {
            "open": 505.96,
            "high": 505.98,
            "low": 505.42,
            "close": 505.56,
            "volume": 213941,
            "datetime": 1713291180000
        },
        {
            "open": 505.54,
            "high": 505.62,
            "low": 504.69,
            "close": 504.7434,
            "volume": 231997,
            "datetime": 1713291240000
        },
        {
            "open": 504.75,
            "high": 504.88,
            "low": 504.18,
            "close": 504.19,
            "volume": 433345,
            "datetime": 1713291300000
        },
        {
            "open": 504.18,
            "high": 504.395,
            "low": 503.91,
            "close": 504.17,
            "volume": 252540,
            "datetime": 1713291360000
        },
        {
            "open": 504.18,
            "high": 504.31,
            "low": 503.8,
            "close": 504,
            "volume": 194656,
            "datetime": 1713291420000
        },
        {
            "open": 504.02,
            "high": 504.14,
            "low": 503.6,
            "close": 503.66,
            "volume": 201537,
            "datetime": 1713291480000
        },
        {
            "open": 503.68,
            "high": 503.85,
            "low": 503.525,
            "close": 503.6551,
            "volume": 157338,
            "datetime": 1713291540000
        },
        {
            "open": 503.65,
            "high": 503.88,
            "low": 503.12,
            "close": 503.185,
            "volume": 257182,
            "datetime": 1713291600000
        },
        {
            "open": 503.2,
            "high": 503.27,
            "low": 502.6,
            "close": 502.8899,
            "volume": 323155,
            "datetime": 1713291660000
        },
        {
            "open": 502.89,
            "high": 503.3,
            "low": 502.81,
            "close": 503.16,
            "volume": 220348,
            "datetime": 1713291720000
        },
        {
            "open": 503.15,
            "high": 503.225,
            "low": 502.98,
            "close": 503.13,
            "volume": 142385,
            "datetime": 1713291780000
        },
        {
            "open": 503.12,
            "high": 503.25,
            "low": 502.88,
            "close": 503.09,
            "volume": 216497,
            "datetime": 1713291840000
        },
        {
            "open": 503.11,
            "high": 503.73,
            "low": 503.07,
            "close": 503.72,
            "volume": 186480,
            "datetime": 1713291900000
        },
        {
            "open": 503.71,
            "high": 504.02,
            "low": 503.67,
            "close": 503.8,
            "volume": 159254,
            "datetime": 1713291960000
        },
        {
            "open": 503.79,
            "high": 503.84,
            "low": 503.11,
            "close": 503.25,
            "volume": 190316,
            "datetime": 1713292020000
        },
        {
            "open": 503.22,
            "high": 503.27,
            "low": 502.76,
            "close": 502.86,
            "volume": 164567,
            "datetime": 1713292080000
        },
        {
            "open": 502.91,
            "high": 503.16,
            "low": 502.69,
            "close": 503.04,
            "volume": 158344,
            "datetime": 1713292140000
        },
        {
            "open": 503.04,
            "high": 503.47,
            "low": 502.99,
            "close": 503.22,
            "volume": 152081,
            "datetime": 1713292200000
        },
        {
            "open": 503.23,
            "high": 503.38,
            "low": 503.09,
            "close": 503.21,
            "volume": 124576,
            "datetime": 1713292260000
        },
        {
            "open": 503.21,
            "high": 503.43,
            "low": 503.15,
            "close": 503.25,
            "volume": 96472,
            "datetime": 1713292320000
        },
        {
            "open": 503.28,
            "high": 503.33,
            "low": 503.0201,
            "close": 503.33,
            "volume": 112529,
            "datetime": 1713292380000
        },
        {
            "open": 503.34,
            "high": 504.27,
            "low": 503.29,
            "close": 504.17,
            "volume": 256976,
            "datetime": 1713292440000
        },
        {
            "open": 504.19,
            "high": 504.39,
            "low": 503.91,
            "close": 504.2,
            "volume": 161427,
            "datetime": 1713292500000
        },
        {
            "open": 504.2,
            "high": 504.34,
            "low": 504.04,
            "close": 504.17,
            "volume": 114082,
            "datetime": 1713292560000
        },
        {
            "open": 504.19,
            "high": 504.2,
            "low": 503.61,
            "close": 503.77,
            "volume": 149129,
            "datetime": 1713292620000
        },
        {
            "open": 503.78,
            "high": 504.16,
            "low": 503.75,
            "close": 503.99,
            "volume": 77659,
            "datetime": 1713292680000
        },
        {
            "open": 504,
            "high": 504.16,
            "low": 503.91,
            "close": 504.1299,
            "volume": 68311,
            "datetime": 1713292740000
        },
        {
            "open": 504.12,
            "high": 504.4,
            "low": 503.95,
            "close": 503.96,
            "volume": 111376,
            "datetime": 1713292800000
        },
        {
            "open": 503.98,
            "high": 504.16,
            "low": 503.89,
            "close": 504.03,
            "volume": 72936,
            "datetime": 1713292860000
        },
        {
            "open": 504.04,
            "high": 504.335,
            "low": 503.99,
            "close": 504.11,
            "volume": 91127,
            "datetime": 1713292920000
        },
        {
            "open": 504.1,
            "high": 504.55,
            "low": 504.1,
            "close": 504.31,
            "volume": 145950,
            "datetime": 1713292980000
        },
        {
            "open": 504.33,
            "high": 504.47,
            "low": 504.15,
            "close": 504.3404,
            "volume": 49578,
            "datetime": 1713293040000
        },
        {
            "open": 504.31,
            "high": 504.34,
            "low": 504,
            "close": 504.08,
            "volume": 48654,
            "datetime": 1713293100000
        },
        {
            "open": 504.07,
            "high": 504.23,
            "low": 503.92,
            "close": 504.07,
            "volume": 65064,
            "datetime": 1713293160000
        },
        {
            "open": 504.0899,
            "high": 504.54,
            "low": 504.0701,
            "close": 504.52,
            "volume": 60183,
            "datetime": 1713293220000
        },
        {
            "open": 504.51,
            "high": 504.68,
            "low": 504.2801,
            "close": 504.305,
            "volume": 83151,
            "datetime": 1713293280000
        },
        {
            "open": 504.28,
            "high": 504.332,
            "low": 504.094,
            "close": 504.27,
            "volume": 87594,
            "datetime": 1713293340000
        },
        {
            "open": 504.29,
            "high": 505.03,
            "low": 504.27,
            "close": 504.95,
            "volume": 129754,
            "datetime": 1713293400000
        },
        {
            "open": 504.94,
            "high": 505.34,
            "low": 504.88,
            "close": 505.236,
            "volume": 162474,
            "datetime": 1713293460000
        },
        {
            "open": 505.23,
            "high": 505.43,
            "low": 505.09,
            "close": 505.36,
            "volume": 119711,
            "datetime": 1713293520000
        },
        {
            "open": 505.365,
            "high": 505.54,
            "low": 505.27,
            "close": 505.31,
            "volume": 129742,
            "datetime": 1713293580000
        },
        {
            "open": 505.29,
            "high": 505.515,
            "low": 505.24,
            "close": 505.34,
            "volume": 102438,
            "datetime": 1713293640000
        },
        {
            "open": 505.345,
            "high": 505.5099,
            "low": 505.08,
            "close": 505.41,
            "volume": 112808,
            "datetime": 1713293700000
        },
        {
            "open": 505.41,
            "high": 505.5,
            "low": 505.2902,
            "close": 505.47,
            "volume": 76394,
            "datetime": 1713293760000
        },
        {
            "open": 505.48,
            "high": 505.64,
            "low": 505.36,
            "close": 505.48,
            "volume": 110058,
            "datetime": 1713293820000
        },
        {
            "open": 505.47,
            "high": 505.72,
            "low": 505.39,
            "close": 505.55,
            "volume": 102885,
            "datetime": 1713293880000
        },
        {
            "open": 505.55,
            "high": 505.81,
            "low": 505.47,
            "close": 505.8,
            "volume": 118826,
            "datetime": 1713293940000
        },
        {
            "open": 505.81,
            "high": 505.85,
            "low": 505.31,
            "close": 505.32,
            "volume": 173229,
            "datetime": 1713294000000
        },
        {
            "open": 505.34,
            "high": 505.5672,
            "low": 505.23,
            "close": 505.5501,
            "volume": 168324,
            "datetime": 1713294060000
        },
        {
            "open": 505.55,
            "high": 505.8598,
            "low": 505.55,
            "close": 505.7,
            "volume": 122646,
            "datetime": 1713294120000
        },
        {
            "open": 505.69,
            "high": 505.71,
            "low": 505.3,
            "close": 505.33,
            "volume": 110108,
            "datetime": 1713294180000
        },
        {
            "open": 505.34,
            "high": 505.485,
            "low": 505.06,
            "close": 505.23,
            "volume": 135498,
            "datetime": 1713294240000
        },
        {
            "open": 505.24,
            "high": 505.31,
            "low": 504.94,
            "close": 505.26,
            "volume": 136406,
            "datetime": 1713294300000
        },
        {
            "open": 505.26,
            "high": 505.43,
            "low": 505.1,
            "close": 505.41,
            "volume": 89061,
            "datetime": 1713294360000
        },
        {
            "open": 505.39,
            "high": 505.485,
            "low": 505.22,
            "close": 505.44,
            "volume": 124044,
            "datetime": 1713294420000
        },
        {
            "open": 505.46,
            "high": 505.4702,
            "low": 505.23,
            "close": 505.32,
            "volume": 76604,
            "datetime": 1713294480000
        },
        {
            "open": 505.33,
            "high": 505.45,
            "low": 505.21,
            "close": 505.2499,
            "volume": 138986,
            "datetime": 1713294540000
        },
        {
            "open": 505.23,
            "high": 505.45,
            "low": 505.08,
            "close": 505.18,
            "volume": 122981,
            "datetime": 1713294600000
        },
        {
            "open": 505.2,
            "high": 505.5,
            "low": 505.08,
            "close": 505.26,
            "volume": 143968,
            "datetime": 1713294660000
        },
        {
            "open": 505.3,
            "high": 505.46,
            "low": 505.18,
            "close": 505.25,
            "volume": 101624,
            "datetime": 1713294720000
        },
        {
            "open": 505.27,
            "high": 505.32,
            "low": 505.12,
            "close": 505.32,
            "volume": 81458,
            "datetime": 1713294780000
        },
        {
            "open": 505.33,
            "high": 505.4,
            "low": 505.14,
            "close": 505.15,
            "volume": 65157,
            "datetime": 1713294840000
        },
        {
            "open": 505.15,
            "high": 505.29,
            "low": 505,
            "close": 505.1,
            "volume": 102458,
            "datetime": 1713294900000
        },
        {
            "open": 505.1,
            "high": 505.12,
            "low": 504.73,
            "close": 504.73,
            "volume": 177905,
            "datetime": 1713294960000
        },
        {
            "open": 504.73,
            "high": 504.96,
            "low": 504.7,
            "close": 504.77,
            "volume": 95564,
            "datetime": 1713295020000
        },
        {
            "open": 504.7601,
            "high": 505.12,
            "low": 504.7601,
            "close": 504.9875,
            "volume": 102643,
            "datetime": 1713295080000
        },
        {
            "open": 504.98,
            "high": 505.35,
            "low": 504.941,
            "close": 505.35,
            "volume": 80849,
            "datetime": 1713295140000
        },
        {
            "open": 505.36,
            "high": 505.37,
            "low": 505.03,
            "close": 505.09,
            "volume": 74186,
            "datetime": 1713295200000
        },
        {
            "open": 505.11,
            "high": 505.3,
            "low": 505.1,
            "close": 505.23,
            "volume": 68263,
            "datetime": 1713295260000
        },
        {
            "open": 505.25,
            "high": 505.3399,
            "low": 505.14,
            "close": 505.32,
            "volume": 58738,
            "datetime": 1713295320000
        },
        {
            "open": 505.34,
            "high": 505.63,
            "low": 505.21,
            "close": 505.55,
            "volume": 181388,
            "datetime": 1713295380000
        },
        {
            "open": 505.55,
            "high": 505.59,
            "low": 505.45,
            "close": 505.49,
            "volume": 71427,
            "datetime": 1713295440000
        },
        {
            "open": 505.49,
            "high": 505.64,
            "low": 505.355,
            "close": 505.61,
            "volume": 95102,
            "datetime": 1713295500000
        },
        {
            "open": 505.61,
            "high": 505.65,
            "low": 505.43,
            "close": 505.54,
            "volume": 77410,
            "datetime": 1713295560000
        },
        {
            "open": 505.55,
            "high": 505.73,
            "low": 505.5,
            "close": 505.66,
            "volume": 99873,
            "datetime": 1713295620000
        },
        {
            "open": 505.66,
            "high": 505.8,
            "low": 505.65,
            "close": 505.75,
            "volume": 89184,
            "datetime": 1713295680000
        },
        {
            "open": 505.78,
            "high": 505.78,
            "low": 505.54,
            "close": 505.62,
            "volume": 74009,
            "datetime": 1713295740000
        },
        {
            "open": 505.63,
            "high": 505.63,
            "low": 505.12,
            "close": 505.32,
            "volume": 255449,
            "datetime": 1713295800000
        },
        {
            "open": 505.3,
            "high": 505.3,
            "low": 505.01,
            "close": 505.22,
            "volume": 138493,
            "datetime": 1713295860000
        },
        {
            "open": 505.22,
            "high": 505.31,
            "low": 504.98,
            "close": 505.04,
            "volume": 108010,
            "datetime": 1713295920000
        },
        {
            "open": 505.06,
            "high": 505.08,
            "low": 504.55,
            "close": 504.5999,
            "volume": 170199,
            "datetime": 1713295980000
        },
        {
            "open": 504.5899,
            "high": 504.625,
            "low": 503.83,
            "close": 503.855,
            "volume": 412092,
            "datetime": 1713296040000
        },
        {
            "open": 503.86,
            "high": 504.36,
            "low": 503.575,
            "close": 504.31,
            "volume": 424628,
            "datetime": 1713296100000
        },
        {
            "open": 504.31,
            "high": 504.41,
            "low": 504.11,
            "close": 504.14,
            "volume": 318018,
            "datetime": 1713296160000
        },
        {
            "open": 504.15,
            "high": 504.41,
            "low": 504.07,
            "close": 504.4,
            "volume": 183401,
            "datetime": 1713296220000
        },
        {
            "open": 504.4,
            "high": 504.62,
            "low": 504.285,
            "close": 504.62,
            "volume": 223981,
            "datetime": 1713296280000
        },
        {
            "open": 504.62,
            "high": 504.73,
            "low": 504.48,
            "close": 504.48,
            "volume": 208615,
            "datetime": 1713296340000
        },
        {
            "open": 504.48,
            "high": 504.57,
            "low": 504.275,
            "close": 504.36,
            "volume": 195033,
            "datetime": 1713296400000
        },
        {
            "open": 504.35,
            "high": 504.39,
            "low": 504.18,
            "close": 504.29,
            "volume": 134019,
            "datetime": 1713296460000
        },
        {
            "open": 504.29,
            "high": 504.33,
            "low": 504,
            "close": 504.15,
            "volume": 204395,
            "datetime": 1713296520000
        },
        {
            "open": 504.15,
            "high": 504.41,
            "low": 504.11,
            "close": 504.35,
            "volume": 299443,
            "datetime": 1713296580000
        },
        {
            "open": 504.35,
            "high": 504.5,
            "low": 504.29,
            "close": 504.43,
            "volume": 212436,
            "datetime": 1713296640000
        },
        {
            "open": 504.425,
            "high": 504.84,
            "low": 504.42,
            "close": 504.72,
            "volume": 278879,
            "datetime": 1713296700000
        },
        {
            "open": 504.725,
            "high": 504.75,
            "low": 504.55,
            "close": 504.59,
            "volume": 133752,
            "datetime": 1713296760000
        },
        {
            "open": 504.58,
            "high": 504.65,
            "low": 504.175,
            "close": 504.24,
            "volume": 174536,
            "datetime": 1713296820000
        },
        {
            "open": 504.24,
            "high": 504.56,
            "low": 504.21,
            "close": 504.43,
            "volume": 129475,
            "datetime": 1713296880000
        },
        {
            "open": 504.42,
            "high": 504.74,
            "low": 504.41,
            "close": 504.66,
            "volume": 162348,
            "datetime": 1713296940000
        },
        {
            "open": 504.65,
            "high": 504.82,
            "low": 504.5,
            "close": 504.76,
            "volume": 248842,
            "datetime": 1713297000000
        },
        {
            "open": 504.76,
            "high": 504.89,
            "low": 504.65,
            "close": 504.66,
            "volume": 209787,
            "datetime": 1713297060000
        },
        {
            "open": 504.65,
            "high": 504.83,
            "low": 504.52,
            "close": 504.76,
            "volume": 237078,
            "datetime": 1713297120000
        },
        {
            "open": 504.76,
            "high": 504.78,
            "low": 504.39,
            "close": 504.45,
            "volume": 250018,
            "datetime": 1713297180000
        },
        {
            "open": 504.45,
            "high": 504.86,
            "low": 504.44,
            "close": 504.62,
            "volume": 364645,
            "datetime": 1713297240000
        },
        {
            "open": 504.62,
            "high": 504.78,
            "low": 504.32,
            "close": 504.33,
            "volume": 325624,
            "datetime": 1713297300000
        },
        {
            "open": 504.34,
            "high": 504.34,
            "low": 503.8,
            "close": 503.84,
            "volume": 508106,
            "datetime": 1713297360000
        },
        {
            "open": 503.85,
            "high": 503.96,
            "low": 503.69,
            "close": 503.81,
            "volume": 373590,
            "datetime": 1713297420000
        },
        {
            "open": 503.82,
            "high": 503.85,
            "low": 503.46,
            "close": 503.47,
            "volume": 880890,
            "datetime": 1713297480000
        },
        {
            "open": 503.4799,
            "high": 503.76,
            "low": 503.295,
            "close": 503.49,
            "volume": 1784738,
            "datetime": 1713297540000
        },
        {
            "open": 506.05,
            "high": 506.12,
            "low": 505.46,
            "close": 505.66,
            "volume": 981508,
            "datetime": 1713360600000
        },
        {
            "open": 505.65,
            "high": 505.93,
            "low": 505.4501,
            "close": 505.92,
            "volume": 185725,
            "datetime": 1713360660000
        },
        {
            "open": 505.92,
            "high": 505.92,
            "low": 505.6152,
            "close": 505.86,
            "volume": 202299,
            "datetime": 1713360720000
        },
        {
            "open": 505.855,
            "high": 506.06,
            "low": 505.65,
            "close": 505.96,
            "volume": 162488,
            "datetime": 1713360780000
        },
        {
            "open": 505.96,
            "high": 505.9601,
            "low": 505.55,
            "close": 505.555,
            "volume": 177569,
            "datetime": 1713360840000
        },
        {
            "open": 505.56,
            "high": 505.64,
            "low": 505.23,
            "close": 505.62,
            "volume": 321985,
            "datetime": 1713360900000
        },
        {
            "open": 505.62,
            "high": 505.8,
            "low": 505.54,
            "close": 505.72,
            "volume": 128744,
            "datetime": 1713360960000
        },
        {
            "open": 505.74,
            "high": 505.92,
            "low": 505.7099,
            "close": 505.778,
            "volume": 89035,
            "datetime": 1713361020000
        },
        {
            "open": 505.77,
            "high": 505.88,
            "low": 505.59,
            "close": 505.81,
            "volume": 134420,
            "datetime": 1713361080000
        },
        {
            "open": 505.81,
            "high": 505.89,
            "low": 505.62,
            "close": 505.69,
            "volume": 79347,
            "datetime": 1713361140000
        },
        {
            "open": 505.7,
            "high": 505.84,
            "low": 505.42,
            "close": 505.45,
            "volume": 192256,
            "datetime": 1713361200000
        },
        {
            "open": 505.44,
            "high": 505.59,
            "low": 505.24,
            "close": 505.51,
            "volume": 243492,
            "datetime": 1713361260000
        },
        {
            "open": 505.4901,
            "high": 505.96,
            "low": 505.48,
            "close": 505.935,
            "volume": 175369,
            "datetime": 1713361320000
        },
        {
            "open": 505.94,
            "high": 506.08,
            "low": 505.84,
            "close": 505.93,
            "volume": 194877,
            "datetime": 1713361380000
        },
        {
            "open": 505.92,
            "high": 506,
            "low": 505.8,
            "close": 505.955,
            "volume": 98758,
            "datetime": 1713361440000
        },
        {
            "open": 505.96,
            "high": 506.06,
            "low": 505.82,
            "close": 505.99,
            "volume": 152818,
            "datetime": 1713361500000
        },
        {
            "open": 505.98,
            "high": 506.11,
            "low": 505.86,
            "close": 506.045,
            "volume": 123959,
            "datetime": 1713361560000
        },
        {
            "open": 506.03,
            "high": 506.22,
            "low": 505.99,
            "close": 506.03,
            "volume": 195944,
            "datetime": 1713361620000
        },
        {
            "open": 506.05,
            "high": 506.065,
            "low": 505.66,
            "close": 505.76,
            "volume": 95115,
            "datetime": 1713361680000
        },
        {
            "open": 505.76,
            "high": 505.77,
            "low": 505.41,
            "close": 505.44,
            "volume": 145227,
            "datetime": 1713361740000
        },
        {
            "open": 505.44,
            "high": 505.678,
            "low": 505.425,
            "close": 505.6201,
            "volume": 133031,
            "datetime": 1713361800000
        },
        {
            "open": 505.6213,
            "high": 505.67,
            "low": 505.06,
            "close": 505.07,
            "volume": 175930,
            "datetime": 1713361860000
        },
        {
            "open": 505.08,
            "high": 505.34,
            "low": 505.03,
            "close": 505.1098,
            "volume": 225230,
            "datetime": 1713361920000
        },
        {
            "open": 505.11,
            "high": 505.44,
            "low": 505.11,
            "close": 505.34,
            "volume": 203672,
            "datetime": 1713361980000
        },
        {
            "open": 505.34,
            "high": 505.41,
            "low": 505.17,
            "close": 505.3299,
            "volume": 66100,
            "datetime": 1713362040000
        },
        {
            "open": 505.34,
            "high": 505.62,
            "low": 505.26,
            "close": 505.53,
            "volume": 188128,
            "datetime": 1713362100000
        },
        {
            "open": 505.52,
            "high": 505.61,
            "low": 505.29,
            "close": 505.4,
            "volume": 146251,
            "datetime": 1713362160000
        },
        {
            "open": 505.42,
            "high": 505.52,
            "low": 505,
            "close": 505.1001,
            "volume": 168500,
            "datetime": 1713362220000
        },
        {
            "open": 505.1,
            "high": 505.35,
            "low": 505.08,
            "close": 505.24,
            "volume": 84441,
            "datetime": 1713362280000
        },
        {
            "open": 505.245,
            "high": 505.39,
            "low": 505.22,
            "close": 505.295,
            "volume": 72088,
            "datetime": 1713362340000
        },
        {
            "open": 505.3,
            "high": 505.355,
            "low": 504.745,
            "close": 504.82,
            "volume": 199622,
            "datetime": 1713362400000
        },
        {
            "open": 504.8,
            "high": 504.97,
            "low": 504.68,
            "close": 504.79,
            "volume": 211539,
            "datetime": 1713362460000
        },
        {
            "open": 504.79,
            "high": 505.07,
            "low": 504.73,
            "close": 504.83,
            "volume": 101270,
            "datetime": 1713362520000
        },
        {
            "open": 504.83,
            "high": 504.85,
            "low": 504.44,
            "close": 504.48,
            "volume": 167073,
            "datetime": 1713362580000
        },
        {
            "open": 504.49,
            "high": 504.53,
            "low": 504.14,
            "close": 504.3395,
            "volume": 202402,
            "datetime": 1713362640000
        },
        {
            "open": 504.34,
            "high": 504.57,
            "low": 504.31,
            "close": 504.38,
            "volume": 107632,
            "datetime": 1713362700000
        },
        {
            "open": 504.39,
            "high": 504.76,
            "low": 504.33,
            "close": 504.72,
            "volume": 97190,
            "datetime": 1713362760000
        },
        {
            "open": 504.72,
            "high": 504.91,
            "low": 504.62,
            "close": 504.87,
            "volume": 94132,
            "datetime": 1713362820000
        },
        {
            "open": 504.87,
            "high": 504.925,
            "low": 504.6497,
            "close": 504.72,
            "volume": 87834,
            "datetime": 1713362880000
        },
        {
            "open": 504.74,
            "high": 504.79,
            "low": 504.56,
            "close": 504.74,
            "volume": 110625,
            "datetime": 1713362940000
        },
        {
            "open": 504.73,
            "high": 504.81,
            "low": 504.48,
            "close": 504.56,
            "volume": 88853,
            "datetime": 1713363000000
        },
        {
            "open": 504.57,
            "high": 504.64,
            "low": 504.12,
            "close": 504.2,
            "volume": 180964,
            "datetime": 1713363060000
        },
        {
            "open": 504.1999,
            "high": 504.22,
            "low": 503.8,
            "close": 503.9299,
            "volume": 259920,
            "datetime": 1713363120000
        },
        {
            "open": 503.93,
            "high": 504.31,
            "low": 503.86,
            "close": 504.15,
            "volume": 222644,
            "datetime": 1713363180000
        },
        {
            "open": 504.13,
            "high": 504.38,
            "low": 504.049,
            "close": 504.075,
            "volume": 147006,
            "datetime": 1713363240000
        },
        {
            "open": 504.07,
            "high": 504.15,
            "low": 503.745,
            "close": 503.87,
            "volume": 191788,
            "datetime": 1713363300000
        },
        {
            "open": 503.88,
            "high": 504.05,
            "low": 503.745,
            "close": 504.03,
            "volume": 119558,
            "datetime": 1713363360000
        },
        {
            "open": 504.04,
            "high": 504.27,
            "low": 504.005,
            "close": 504.09,
            "volume": 80890,
            "datetime": 1713363420000
        },
        {
            "open": 504.07,
            "high": 504.27,
            "low": 504,
            "close": 504.075,
            "volume": 64794,
            "datetime": 1713363480000
        },
        {
            "open": 504.08,
            "high": 504.2,
            "low": 503.83,
            "close": 504.0001,
            "volume": 109833,
            "datetime": 1713363540000
        },
        {
            "open": 504.02,
            "high": 504.04,
            "low": 503.86,
            "close": 504.01,
            "volume": 64888,
            "datetime": 1713363600000
        },
        {
            "open": 504.04,
            "high": 504.05,
            "low": 503.89,
            "close": 503.96,
            "volume": 80378,
            "datetime": 1713363660000
        },
        {
            "open": 503.97,
            "high": 504.12,
            "low": 503.55,
            "close": 503.635,
            "volume": 149740,
            "datetime": 1713363720000
        },
        {
            "open": 503.65,
            "high": 503.7,
            "low": 503.45,
            "close": 503.665,
            "volume": 174645,
            "datetime": 1713363780000
        },
        {
            "open": 503.67,
            "high": 504.03,
            "low": 503.65,
            "close": 504.02,
            "volume": 142133,
            "datetime": 1713363840000
        },
        {
            "open": 504.03,
            "high": 504.36,
            "low": 504.0199,
            "close": 504.34,
            "volume": 199449,
            "datetime": 1713363900000
        },
        {
            "open": 504.32,
            "high": 504.68,
            "low": 504.29,
            "close": 504.375,
            "volume": 167277,
            "datetime": 1713363960000
        },
        {
            "open": 504.37,
            "high": 504.48,
            "low": 504.21,
            "close": 504.33,
            "volume": 130917,
            "datetime": 1713364020000
        },
        {
            "open": 504.32,
            "high": 504.43,
            "low": 504.27,
            "close": 504.43,
            "volume": 99777,
            "datetime": 1713364080000
        },
        {
            "open": 504.43,
            "high": 504.45,
            "low": 504.25,
            "close": 504.38,
            "volume": 80114,
            "datetime": 1713364140000
        },
        {
            "open": 504.36,
            "high": 504.51,
            "low": 504.2,
            "close": 504.22,
            "volume": 72578,
            "datetime": 1713364200000
        },
        {
            "open": 504.25,
            "high": 504.38,
            "low": 503.96,
            "close": 504.27,
            "volume": 129841,
            "datetime": 1713364260000
        },
        {
            "open": 504.27,
            "high": 504.385,
            "low": 504.135,
            "close": 504.19,
            "volume": 59529,
            "datetime": 1713364320000
        },
        {
            "open": 504.1751,
            "high": 504.24,
            "low": 504.06,
            "close": 504.15,
            "volume": 66602,
            "datetime": 1713364380000
        },
        {
            "open": 504.145,
            "high": 504.27,
            "low": 503.97,
            "close": 504.01,
            "volume": 74025,
            "datetime": 1713364440000
        },
        {
            "open": 503.99,
            "high": 504.09,
            "low": 503.885,
            "close": 504.07,
            "volume": 72238,
            "datetime": 1713364500000
        },
        {
            "open": 504.065,
            "high": 504.21,
            "low": 503.765,
            "close": 503.77,
            "volume": 72710,
            "datetime": 1713364560000
        },
        {
            "open": 503.77,
            "high": 503.86,
            "low": 503.56,
            "close": 503.65,
            "volume": 102774,
            "datetime": 1713364620000
        },
        {
            "open": 503.64,
            "high": 503.98,
            "low": 503.6,
            "close": 503.98,
            "volume": 72576,
            "datetime": 1713364680000
        },
        {
            "open": 503.98,
            "high": 504.16,
            "low": 503.835,
            "close": 503.835,
            "volume": 68012,
            "datetime": 1713364740000
        },
        {
            "open": 503.85,
            "high": 503.88,
            "low": 503.68,
            "close": 503.82,
            "volume": 60177,
            "datetime": 1713364800000
        },
        {
            "open": 503.81,
            "high": 504.02,
            "low": 503.785,
            "close": 503.95,
            "volume": 79038,
            "datetime": 1713364860000
        },
        {
            "open": 503.9499,
            "high": 503.9499,
            "low": 503.71,
            "close": 503.85,
            "volume": 87565,
            "datetime": 1713364920000
        },
        {
            "open": 503.83,
            "high": 503.99,
            "low": 503.62,
            "close": 503.6501,
            "volume": 113514,
            "datetime": 1713364980000
        },
        {
            "open": 503.65,
            "high": 503.83,
            "low": 503.585,
            "close": 503.82,
            "volume": 80684,
            "datetime": 1713365040000
        },
        {
            "open": 503.82,
            "high": 503.8497,
            "low": 503.63,
            "close": 503.72,
            "volume": 44657,
            "datetime": 1713365100000
        },
        {
            "open": 503.705,
            "high": 503.91,
            "low": 503.7,
            "close": 503.87,
            "volume": 55420,
            "datetime": 1713365160000
        },
        {
            "open": 503.865,
            "high": 504.04,
            "low": 503.865,
            "close": 503.97,
            "volume": 61346,
            "datetime": 1713365220000
        },
        {
            "open": 503.96,
            "high": 504.37,
            "low": 503.9588,
            "close": 504.37,
            "volume": 113923,
            "datetime": 1713365280000
        },
        {
            "open": 504.36,
            "high": 504.36,
            "low": 504.16,
            "close": 504.23,
            "volume": 126130,
            "datetime": 1713365340000
        },
        {
            "open": 504.2,
            "high": 504.21,
            "low": 503.92,
            "close": 503.9212,
            "volume": 63365,
            "datetime": 1713365400000
        },
        {
            "open": 503.89,
            "high": 503.91,
            "low": 503.03,
            "close": 503.13,
            "volume": 217505,
            "datetime": 1713365460000
        },
        {
            "open": 503.13,
            "high": 503.2,
            "low": 502.52,
            "close": 502.61,
            "volume": 264892,
            "datetime": 1713365520000
        },
        {
            "open": 502.62,
            "high": 502.72,
            "low": 502.29,
            "close": 502.49,
            "volume": 251862,
            "datetime": 1713365580000
        },
        {
            "open": 502.5,
            "high": 502.535,
            "low": 502.19,
            "close": 502.47,
            "volume": 185697,
            "datetime": 1713365640000
        },
        {
            "open": 502.5,
            "high": 502.99,
            "low": 502.49,
            "close": 502.75,
            "volume": 247313,
            "datetime": 1713365700000
        },
        {
            "open": 502.75,
            "high": 502.89,
            "low": 502.55,
            "close": 502.68,
            "volume": 191286,
            "datetime": 1713365760000
        },
        {
            "open": 502.69,
            "high": 502.97,
            "low": 502.69,
            "close": 502.89,
            "volume": 127375,
            "datetime": 1713365820000
        },
        {
            "open": 502.9,
            "high": 503.035,
            "low": 502.81,
            "close": 502.92,
            "volume": 88007,
            "datetime": 1713365880000
        },
        {
            "open": 502.88,
            "high": 502.955,
            "low": 502.78,
            "close": 502.9,
            "volume": 103150,
            "datetime": 1713365940000
        },
        {
            "open": 502.91,
            "high": 503.26,
            "low": 502.85,
            "close": 503.2,
            "volume": 202031,
            "datetime": 1713366000000
        },
        {
            "open": 503.19,
            "high": 503.79,
            "low": 503.1801,
            "close": 503.65,
            "volume": 239479,
            "datetime": 1713366060000
        },
        {
            "open": 503.65,
            "high": 503.68,
            "low": 503.43,
            "close": 503.5929,
            "volume": 97935,
            "datetime": 1713366120000
        },
        {
            "open": 503.65,
            "high": 503.81,
            "low": 503.56,
            "close": 503.725,
            "volume": 55556,
            "datetime": 1713366180000
        },
        {
            "open": 503.75,
            "high": 504.01,
            "low": 503.71,
            "close": 503.97,
            "volume": 171827,
            "datetime": 1713366240000
        },
        {
            "open": 503.93,
            "high": 503.93,
            "low": 503.63,
            "close": 503.645,
            "volume": 129202,
            "datetime": 1713366300000
        },
        {
            "open": 503.645,
            "high": 503.665,
            "low": 503.13,
            "close": 503.2,
            "volume": 169221,
            "datetime": 1713366360000
        },
        {
            "open": 503.22,
            "high": 503.36,
            "low": 502.89,
            "close": 503.24,
            "volume": 126748,
            "datetime": 1713366420000
        },
        {
            "open": 503.25,
            "high": 503.31,
            "low": 503.1287,
            "close": 503.19,
            "volume": 91653,
            "datetime": 1713366480000
        },
        {
            "open": 503.18,
            "high": 503.2,
            "low": 503.02,
            "close": 503.19,
            "volume": 73469,
            "datetime": 1713366540000
        },
        {
            "open": 503.21,
            "high": 503.29,
            "low": 503.085,
            "close": 503.11,
            "volume": 81013,
            "datetime": 1713366600000
        },
        {
            "open": 503.11,
            "high": 503.34,
            "low": 503.06,
            "close": 503.13,
            "volume": 52893,
            "datetime": 1713366660000
        },
        {
            "open": 503.11,
            "high": 503.25,
            "low": 502.82,
            "close": 503.085,
            "volume": 90819,
            "datetime": 1713366720000
        },
        {
            "open": 503.1,
            "high": 503.105,
            "low": 502.77,
            "close": 502.925,
            "volume": 55269,
            "datetime": 1713366780000
        },
        {
            "open": 502.92,
            "high": 503,
            "low": 502.74,
            "close": 502.87,
            "volume": 49092,
            "datetime": 1713366840000
        },
        {
            "open": 502.89,
            "high": 503.1698,
            "low": 502.8,
            "close": 502.88,
            "volume": 83197,
            "datetime": 1713366900000
        },
        {
            "open": 502.86,
            "high": 503.12,
            "low": 502.84,
            "close": 503.04,
            "volume": 73470,
            "datetime": 1713366960000
        },
        {
            "open": 503.03,
            "high": 503.03,
            "low": 502.78,
            "close": 502.89,
            "volume": 43387,
            "datetime": 1713367020000
        },
        {
            "open": 502.9,
            "high": 502.9,
            "low": 502.715,
            "close": 502.83,
            "volume": 66362,
            "datetime": 1713367080000
        },
        {
            "open": 502.84,
            "high": 502.89,
            "low": 502.48,
            "close": 502.48,
            "volume": 134718,
            "datetime": 1713367140000
        },
        {
            "open": 502.48,
            "high": 502.69,
            "low": 502.48,
            "close": 502.63,
            "volume": 81618,
            "datetime": 1713367200000
        },
        {
            "open": 502.62,
            "high": 502.69,
            "low": 502.33,
            "close": 502.45,
            "volume": 173921,
            "datetime": 1713367260000
        },
        {
            "open": 502.45,
            "high": 502.68,
            "low": 502.4401,
            "close": 502.61,
            "volume": 101524,
            "datetime": 1713367320000
        },
        {
            "open": 502.61,
            "high": 502.93,
            "low": 502.51,
            "close": 502.65,
            "volume": 161218,
            "datetime": 1713367380000
        },
        {
            "open": 502.66,
            "high": 502.71,
            "low": 502.49,
            "close": 502.6699,
            "volume": 75938,
            "datetime": 1713367440000
        },
        {
            "open": 502.67,
            "high": 502.73,
            "low": 502.5,
            "close": 502.5,
            "volume": 70626,
            "datetime": 1713367500000
        },
        {
            "open": 502.49,
            "high": 502.645,
            "low": 502.37,
            "close": 502.545,
            "volume": 104324,
            "datetime": 1713367560000
        },
        {
            "open": 502.545,
            "high": 502.94,
            "low": 502.5406,
            "close": 502.87,
            "volume": 170838,
            "datetime": 1713367620000
        },
        {
            "open": 502.87,
            "high": 502.99,
            "low": 502.56,
            "close": 502.57,
            "volume": 68825,
            "datetime": 1713367680000
        },
        {
            "open": 502.57,
            "high": 502.59,
            "low": 502.09,
            "close": 502.1,
            "volume": 153368,
            "datetime": 1713367740000
        },
        {
            "open": 502.1,
            "high": 502.18,
            "low": 501.33,
            "close": 501.435,
            "volume": 514109,
            "datetime": 1713367800000
        },
        {
            "open": 501.42,
            "high": 501.75,
            "low": 501.42,
            "close": 501.52,
            "volume": 257860,
            "datetime": 1713367860000
        },
        {
            "open": 501.535,
            "high": 501.55,
            "low": 501.041,
            "close": 501.041,
            "volume": 250535,
            "datetime": 1713367920000
        },
        {
            "open": 501.09,
            "high": 501.4,
            "low": 501.09,
            "close": 501.24,
            "volume": 147818,
            "datetime": 1713367980000
        },
        {
            "open": 501.25,
            "high": 501.35,
            "low": 501.01,
            "close": 501.21,
            "volume": 130107,
            "datetime": 1713368040000
        },
        {
            "open": 501.18,
            "high": 501.55,
            "low": 501.13,
            "close": 501.39,
            "volume": 134889,
            "datetime": 1713368100000
        },
        {
            "open": 501.38,
            "high": 501.43,
            "low": 501.2,
            "close": 501.23,
            "volume": 118520,
            "datetime": 1713368160000
        },
        {
            "open": 501.23,
            "high": 501.44,
            "low": 501.16,
            "close": 501.38,
            "volume": 123550,
            "datetime": 1713368220000
        },
        {
            "open": 501.39,
            "high": 501.6,
            "low": 501.32,
            "close": 501.495,
            "volume": 136361,
            "datetime": 1713368280000
        },
        {
            "open": 501.51,
            "high": 501.6,
            "low": 501.36,
            "close": 501.42,
            "volume": 89856,
            "datetime": 1713368340000
        },
        {
            "open": 501.39,
            "high": 501.69,
            "low": 501.39,
            "close": 501.66,
            "volume": 96890,
            "datetime": 1713368400000
        },
        {
            "open": 501.6599,
            "high": 501.74,
            "low": 501.51,
            "close": 501.6,
            "volume": 143603,
            "datetime": 1713368460000
        },
        {
            "open": 501.6,
            "high": 501.73,
            "low": 501.5,
            "close": 501.69,
            "volume": 67904,
            "datetime": 1713368520000
        },
        {
            "open": 501.6902,
            "high": 502.09,
            "low": 501.66,
            "close": 502.01,
            "volume": 149142,
            "datetime": 1713368580000
        },
        {
            "open": 502,
            "high": 502.0706,
            "low": 501.75,
            "close": 501.89,
            "volume": 136356,
            "datetime": 1713368640000
        },
        {
            "open": 501.89,
            "high": 502.02,
            "low": 501.51,
            "close": 501.53,
            "volume": 130250,
            "datetime": 1713368700000
        },
        {
            "open": 501.54,
            "high": 501.64,
            "low": 501.4508,
            "close": 501.6,
            "volume": 88221,
            "datetime": 1713368760000
        },
        {
            "open": 501.64,
            "high": 501.71,
            "low": 501.43,
            "close": 501.58,
            "volume": 78436,
            "datetime": 1713368820000
        },
        {
            "open": 501.57,
            "high": 501.58,
            "low": 501.35,
            "close": 501.53,
            "volume": 72441,
            "datetime": 1713368880000
        },
        {
            "open": 501.515,
            "high": 501.525,
            "low": 501.32,
            "close": 501.5,
            "volume": 53503,
            "datetime": 1713368940000
        },
        {
            "open": 501.5,
            "high": 501.87,
            "low": 501.485,
            "close": 501.85,
            "volume": 117103,
            "datetime": 1713369000000
        },
        {
            "open": 501.85,
            "high": 501.85,
            "low": 501.5,
            "close": 501.5,
            "volume": 109531,
            "datetime": 1713369060000
        },
        {
            "open": 501.5,
            "high": 501.65,
            "low": 501.45,
            "close": 501.57,
            "volume": 69257,
            "datetime": 1713369120000
        },
        {
            "open": 501.55,
            "high": 501.72,
            "low": 501.5099,
            "close": 501.65,
            "volume": 55055,
            "datetime": 1713369180000
        },
        {
            "open": 501.64,
            "high": 501.95,
            "low": 501.63,
            "close": 501.84,
            "volume": 78909,
            "datetime": 1713369240000
        },
        {
            "open": 501.8492,
            "high": 501.9499,
            "low": 501.69,
            "close": 501.94,
            "volume": 62653,
            "datetime": 1713369300000
        },
        {
            "open": 501.94,
            "high": 501.94,
            "low": 501.745,
            "close": 501.82,
            "volume": 89382,
            "datetime": 1713369360000
        },
        {
            "open": 501.81,
            "high": 501.969,
            "low": 501.78,
            "close": 501.96,
            "volume": 88274,
            "datetime": 1713369420000
        },
        {
            "open": 501.95,
            "high": 502.02,
            "low": 501.71,
            "close": 501.72,
            "volume": 143297,
            "datetime": 1713369480000
        },
        {
            "open": 501.71,
            "high": 501.78,
            "low": 501.63,
            "close": 501.73,
            "volume": 70512,
            "datetime": 1713369540000
        },
        {
            "open": 501.71,
            "high": 501.71,
            "low": 501.44,
            "close": 501.505,
            "volume": 163505,
            "datetime": 1713369600000
        },
        {
            "open": 501.51,
            "high": 501.79,
            "low": 501.4725,
            "close": 501.62,
            "volume": 109618,
            "datetime": 1713369660000
        },
        {
            "open": 501.61,
            "high": 501.61,
            "low": 501.25,
            "close": 501.29,
            "volume": 139686,
            "datetime": 1713369720000
        },
        {
            "open": 501.3,
            "high": 501.42,
            "low": 501.22,
            "close": 501.285,
            "volume": 110472,
            "datetime": 1713369780000
        },
        {
            "open": 501.28,
            "high": 501.28,
            "low": 500.775,
            "close": 500.8399,
            "volume": 240280,
            "datetime": 1713369840000
        },
        {
            "open": 500.85,
            "high": 500.9113,
            "low": 500.295,
            "close": 500.3499,
            "volume": 239232,
            "datetime": 1713369900000
        },
        {
            "open": 500.35,
            "high": 500.48,
            "low": 500.25,
            "close": 500.3399,
            "volume": 188267,
            "datetime": 1713369960000
        },
        {
            "open": 500.34,
            "high": 500.58,
            "low": 500.29,
            "close": 500.46,
            "volume": 117666,
            "datetime": 1713370020000
        },
        {
            "open": 500.46,
            "high": 500.495,
            "low": 500.18,
            "close": 500.295,
            "volume": 181304,
            "datetime": 1713370080000
        },
        {
            "open": 500.29,
            "high": 500.42,
            "low": 500.17,
            "close": 500.3688,
            "volume": 144021,
            "datetime": 1713370140000
        },
        {
            "open": 500.395,
            "high": 500.95,
            "low": 500.395,
            "close": 500.82,
            "volume": 252769,
            "datetime": 1713370200000
        },
        {
            "open": 500.8199,
            "high": 501.28,
            "low": 500.78,
            "close": 501.05,
            "volume": 353511,
            "datetime": 1713370260000
        },
        {
            "open": 501.08,
            "high": 501.2,
            "low": 500.99,
            "close": 501.085,
            "volume": 128886,
            "datetime": 1713370320000
        },
        {
            "open": 501.08,
            "high": 501.17,
            "low": 500.9901,
            "close": 501.13,
            "volume": 75402,
            "datetime": 1713370380000
        },
        {
            "open": 501.1,
            "high": 501.21,
            "low": 500.96,
            "close": 501.015,
            "volume": 97070,
            "datetime": 1713370440000
        },
        {
            "open": 501.02,
            "high": 501.08,
            "low": 500.8,
            "close": 500.83,
            "volume": 108203,
            "datetime": 1713370500000
        },
        {
            "open": 500.84,
            "high": 501.095,
            "low": 500.795,
            "close": 500.84,
            "volume": 124582,
            "datetime": 1713370560000
        },
        {
            "open": 500.84,
            "high": 500.89,
            "low": 500.595,
            "close": 500.75,
            "volume": 240423,
            "datetime": 1713370620000
        },
        {
            "open": 500.75,
            "high": 500.82,
            "low": 500.57,
            "close": 500.58,
            "volume": 166929,
            "datetime": 1713370680000
        },
        {
            "open": 500.56,
            "high": 500.755,
            "low": 500.56,
            "close": 500.65,
            "volume": 90023,
            "datetime": 1713370740000
        },
        {
            "open": 500.66,
            "high": 500.81,
            "low": 500.59,
            "close": 500.64,
            "volume": 78118,
            "datetime": 1713370800000
        },
        {
            "open": 500.63,
            "high": 501.1,
            "low": 500.63,
            "close": 500.91,
            "volume": 153844,
            "datetime": 1713370860000
        },
        {
            "open": 500.92,
            "high": 500.93,
            "low": 500.33,
            "close": 500.39,
            "volume": 118999,
            "datetime": 1713370920000
        },
        {
            "open": 500.4,
            "high": 500.46,
            "low": 500.3,
            "close": 500.35,
            "volume": 107447,
            "datetime": 1713370980000
        },
        {
            "open": 500.37,
            "high": 500.57,
            "low": 500.335,
            "close": 500.4191,
            "volume": 136586,
            "datetime": 1713371040000
        },
        {
            "open": 500.42,
            "high": 500.92,
            "low": 500.38,
            "close": 500.92,
            "volume": 150892,
            "datetime": 1713371100000
        },
        {
            "open": 500.91,
            "high": 500.95,
            "low": 500.54,
            "close": 500.64,
            "volume": 124295,
            "datetime": 1713371160000
        },
        {
            "open": 500.64,
            "high": 500.84,
            "low": 500.575,
            "close": 500.6185,
            "volume": 101118,
            "datetime": 1713371220000
        },
        {
            "open": 500.61,
            "high": 500.65,
            "low": 500.28,
            "close": 500.335,
            "volume": 149548,
            "datetime": 1713371280000
        },
        {
            "open": 500.35,
            "high": 500.56,
            "low": 500.3,
            "close": 500.48,
            "volume": 61234,
            "datetime": 1713371340000
        },
        {
            "open": 500.48,
            "high": 500.57,
            "low": 500.25,
            "close": 500.25,
            "volume": 109971,
            "datetime": 1713371400000
        },
        {
            "open": 500.27,
            "high": 500.4,
            "low": 500.21,
            "close": 500.3799,
            "volume": 84908,
            "datetime": 1713371460000
        },
        {
            "open": 500.38,
            "high": 500.48,
            "low": 500.265,
            "close": 500.29,
            "volume": 69909,
            "datetime": 1713371520000
        },
        {
            "open": 500.28,
            "high": 500.53,
            "low": 500.05,
            "close": 500.335,
            "volume": 196913,
            "datetime": 1713371580000
        },
        {
            "open": 500.33,
            "high": 500.5,
            "low": 500.32,
            "close": 500.33,
            "volume": 66563,
            "datetime": 1713371640000
        },
        {
            "open": 500.34,
            "high": 500.39,
            "low": 500.07,
            "close": 500.15,
            "volume": 117370,
            "datetime": 1713371700000
        },
        {
            "open": 500.16,
            "high": 500.35,
            "low": 500.03,
            "close": 500.17,
            "volume": 209063,
            "datetime": 1713371760000
        },
        {
            "open": 500.19,
            "high": 500.22,
            "low": 500.07,
            "close": 500.185,
            "volume": 78621,
            "datetime": 1713371820000
        },
        {
            "open": 500.19,
            "high": 500.26,
            "low": 500.1,
            "close": 500.2,
            "volume": 68203,
            "datetime": 1713371880000
        },
        {
            "open": 500.19,
            "high": 500.36,
            "low": 500.11,
            "close": 500.19,
            "volume": 192022,
            "datetime": 1713371940000
        },
        {
            "open": 500.18,
            "high": 500.34,
            "low": 500.03,
            "close": 500.255,
            "volume": 158193,
            "datetime": 1713372000000
        },
        {
            "open": 500.28,
            "high": 500.4438,
            "low": 500.175,
            "close": 500.21,
            "volume": 156355,
            "datetime": 1713372060000
        },
        {
            "open": 500.2,
            "high": 500.21,
            "low": 499.68,
            "close": 499.815,
            "volume": 371201,
            "datetime": 1713372120000
        },
        {
            "open": 499.82,
            "high": 499.9,
            "low": 499.51,
            "close": 499.6,
            "volume": 193972,
            "datetime": 1713372180000
        },
        {
            "open": 499.61,
            "high": 499.62,
            "low": 499.37,
            "close": 499.41,
            "volume": 166071,
            "datetime": 1713372240000
        },
        {
            "open": 499.42,
            "high": 499.78,
            "low": 499.415,
            "close": 499.63,
            "volume": 217893,
            "datetime": 1713372300000
        },
        {
            "open": 499.6199,
            "high": 499.64,
            "low": 499.12,
            "close": 499.23,
            "volume": 252368,
            "datetime": 1713372360000
        },
        {
            "open": 499.23,
            "high": 499.715,
            "low": 499.2288,
            "close": 499.7,
            "volume": 232469,
            "datetime": 1713372420000
        },
        {
            "open": 499.69,
            "high": 499.78,
            "low": 499.56,
            "close": 499.78,
            "volume": 188897,
            "datetime": 1713372480000
        },
        {
            "open": 499.77,
            "high": 500.13,
            "low": 499.74,
            "close": 500.07,
            "volume": 244524,
            "datetime": 1713372540000
        },
        {
            "open": 500.07,
            "high": 500.165,
            "low": 499.8,
            "close": 499.8104,
            "volume": 167822,
            "datetime": 1713372600000
        },
        {
            "open": 499.82,
            "high": 500.06,
            "low": 499.8,
            "close": 499.945,
            "volume": 175182,
            "datetime": 1713372660000
        },
        {
            "open": 499.97,
            "high": 500.2,
            "low": 499.94,
            "close": 500.17,
            "volume": 136133,
            "datetime": 1713372720000
        },
        {
            "open": 500.16,
            "high": 500.2,
            "low": 500.04,
            "close": 500.05,
            "volume": 93042,
            "datetime": 1713372780000
        },
        {
            "open": 500.04,
            "high": 500.27,
            "low": 500,
            "close": 500.2,
            "volume": 105398,
            "datetime": 1713372840000
        },
        {
            "open": 500.2,
            "high": 500.2,
            "low": 499.97,
            "close": 500.115,
            "volume": 103555,
            "datetime": 1713372900000
        },
        {
            "open": 500.13,
            "high": 500.22,
            "low": 500.08,
            "close": 500.13,
            "volume": 76343,
            "datetime": 1713372960000
        },
        {
            "open": 500.13,
            "high": 500.15,
            "low": 499.78,
            "close": 499.78,
            "volume": 134908,
            "datetime": 1713373020000
        },
        {
            "open": 499.8,
            "high": 500.12,
            "low": 499.67,
            "close": 500.1,
            "volume": 191311,
            "datetime": 1713373080000
        },
        {
            "open": 500.08,
            "high": 500.18,
            "low": 499.86,
            "close": 499.89,
            "volume": 104650,
            "datetime": 1713373140000
        },
        {
            "open": 499.91,
            "high": 500.15,
            "low": 499.69,
            "close": 500.12,
            "volume": 173453,
            "datetime": 1713373200000
        },
        {
            "open": 500.1,
            "high": 500.4,
            "low": 499.87,
            "close": 500.3,
            "volume": 175101,
            "datetime": 1713373260000
        },
        {
            "open": 500.3,
            "high": 500.46,
            "low": 500.07,
            "close": 500.13,
            "volume": 308037,
            "datetime": 1713373320000
        },
        {
            "open": 500.13,
            "high": 500.47,
            "low": 500.13,
            "close": 500.4678,
            "volume": 133895,
            "datetime": 1713373380000
        },
        {
            "open": 500.48,
            "high": 500.55,
            "low": 500.25,
            "close": 500.44,
            "volume": 198968,
            "datetime": 1713373440000
        },
        {
            "open": 500.43,
            "high": 500.58,
            "low": 500.3,
            "close": 500.39,
            "volume": 190407,
            "datetime": 1713373500000
        },
        {
            "open": 500.35,
            "high": 500.83,
            "low": 500.22,
            "close": 500.775,
            "volume": 218520,
            "datetime": 1713373560000
        },
        {
            "open": 500.78,
            "high": 500.98,
            "low": 500.5101,
            "close": 500.66,
            "volume": 170227,
            "datetime": 1713373620000
        },
        {
            "open": 500.66,
            "high": 500.89,
            "low": 500.56,
            "close": 500.81,
            "volume": 154641,
            "datetime": 1713373680000
        },
        {
            "open": 500.8151,
            "high": 500.887,
            "low": 500.62,
            "close": 500.87,
            "volume": 79486,
            "datetime": 1713373740000
        },
        {
            "open": 500.87,
            "high": 500.9,
            "low": 500.73,
            "close": 500.76,
            "volume": 67906,
            "datetime": 1713373800000
        },
        {
            "open": 500.76,
            "high": 501.015,
            "low": 500.73,
            "close": 500.78,
            "volume": 109888,
            "datetime": 1713373860000
        },
        {
            "open": 500.785,
            "high": 501.065,
            "low": 500.7201,
            "close": 500.98,
            "volume": 79111,
            "datetime": 1713373920000
        },
        {
            "open": 500.98,
            "high": 500.99,
            "low": 500.65,
            "close": 500.665,
            "volume": 110768,
            "datetime": 1713373980000
        },
        {
            "open": 500.66,
            "high": 500.92,
            "low": 500.66,
            "close": 500.89,
            "volume": 107649,
            "datetime": 1713374040000
        },
        {
            "open": 500.9,
            "high": 501.1,
            "low": 500.9,
            "close": 501.08,
            "volume": 94714,
            "datetime": 1713374100000
        },
        {
            "open": 501.09,
            "high": 501.59,
            "low": 501.08,
            "close": 501.44,
            "volume": 204902,
            "datetime": 1713374160000
        },
        {
            "open": 501.43,
            "high": 501.48,
            "low": 500.95,
            "close": 500.96,
            "volume": 190459,
            "datetime": 1713374220000
        },
        {
            "open": 500.97,
            "high": 501.43,
            "low": 500.96,
            "close": 501.29,
            "volume": 219220,
            "datetime": 1713374280000
        },
        {
            "open": 501.27,
            "high": 501.525,
            "low": 501.2488,
            "close": 501.33,
            "volume": 97774,
            "datetime": 1713374340000
        },
        {
            "open": 501.325,
            "high": 501.61,
            "low": 501.22,
            "close": 501.55,
            "volume": 67583,
            "datetime": 1713374400000
        },
        {
            "open": 501.54,
            "high": 501.71,
            "low": 501.35,
            "close": 501.39,
            "volume": 113328,
            "datetime": 1713374460000
        },
        {
            "open": 501.4,
            "high": 501.5496,
            "low": 501.32,
            "close": 501.51,
            "volume": 111753,
            "datetime": 1713374520000
        },
        {
            "open": 501.4774,
            "high": 501.54,
            "low": 501.29,
            "close": 501.33,
            "volume": 100470,
            "datetime": 1713374580000
        },
        {
            "open": 501.31,
            "high": 501.4,
            "low": 501.15,
            "close": 501.26,
            "volume": 89786,
            "datetime": 1713374640000
        },
        {
            "open": 501.28,
            "high": 501.53,
            "low": 501.11,
            "close": 501.1694,
            "volume": 81186,
            "datetime": 1713374700000
        },
        {
            "open": 501.165,
            "high": 501.365,
            "low": 501.05,
            "close": 501.365,
            "volume": 133520,
            "datetime": 1713374760000
        },
        {
            "open": 501.37,
            "high": 501.555,
            "low": 501.35,
            "close": 501.51,
            "volume": 91833,
            "datetime": 1713374820000
        },
        {
            "open": 501.52,
            "high": 501.78,
            "low": 501.5,
            "close": 501.7,
            "volume": 147069,
            "datetime": 1713374880000
        },
        {
            "open": 501.7,
            "high": 501.79,
            "low": 501.57,
            "close": 501.77,
            "volume": 71154,
            "datetime": 1713374940000
        },
        {
            "open": 501.8,
            "high": 501.98,
            "low": 501.73,
            "close": 501.895,
            "volume": 127878,
            "datetime": 1713375000000
        },
        {
            "open": 501.88,
            "high": 501.9,
            "low": 501.65,
            "close": 501.65,
            "volume": 69704,
            "datetime": 1713375060000
        },
        {
            "open": 501.6501,
            "high": 501.83,
            "low": 501.6501,
            "close": 501.73,
            "volume": 80506,
            "datetime": 1713375120000
        },
        {
            "open": 501.74,
            "high": 501.8,
            "low": 501.5651,
            "close": 501.7,
            "volume": 66128,
            "datetime": 1713375180000
        },
        {
            "open": 501.695,
            "high": 501.71,
            "low": 501.46,
            "close": 501.585,
            "volume": 95281,
            "datetime": 1713375240000
        },
        {
            "open": 501.59,
            "high": 501.7699,
            "low": 501.55,
            "close": 501.715,
            "volume": 99805,
            "datetime": 1713375300000
        },
        {
            "open": 501.71,
            "high": 501.8,
            "low": 501.6251,
            "close": 501.7293,
            "volume": 72751,
            "datetime": 1713375360000
        },
        {
            "open": 501.7,
            "high": 502.03,
            "low": 501.69,
            "close": 501.92,
            "volume": 105223,
            "datetime": 1713375420000
        },
        {
            "open": 501.945,
            "high": 501.96,
            "low": 501.8399,
            "close": 501.89,
            "volume": 63422,
            "datetime": 1713375480000
        },
        {
            "open": 501.89,
            "high": 501.92,
            "low": 501.73,
            "close": 501.76,
            "volume": 96165,
            "datetime": 1713375540000
        },
        {
            "open": 501.765,
            "high": 501.99,
            "low": 501.76,
            "close": 501.885,
            "volume": 56421,
            "datetime": 1713375600000
        },
        {
            "open": 501.88,
            "high": 501.88,
            "low": 501.54,
            "close": 501.59,
            "volume": 122244,
            "datetime": 1713375660000
        },
        {
            "open": 501.598,
            "high": 501.65,
            "low": 501.38,
            "close": 501.45,
            "volume": 178987,
            "datetime": 1713375720000
        },
        {
            "open": 501.4399,
            "high": 501.47,
            "low": 501.2099,
            "close": 501.26,
            "volume": 202160,
            "datetime": 1713375780000
        },
        {
            "open": 501.27,
            "high": 501.68,
            "low": 501.27,
            "close": 501.6699,
            "volume": 100034,
            "datetime": 1713375840000
        },
        {
            "open": 501.66,
            "high": 501.6851,
            "low": 501.25,
            "close": 501.31,
            "volume": 123019,
            "datetime": 1713375900000
        },
        {
            "open": 501.34,
            "high": 501.36,
            "low": 501.07,
            "close": 501.2,
            "volume": 158985,
            "datetime": 1713375960000
        },
        {
            "open": 501.22,
            "high": 501.44,
            "low": 501.21,
            "close": 501.41,
            "volume": 75357,
            "datetime": 1713376020000
        },
        {
            "open": 501.41,
            "high": 501.5928,
            "low": 501.28,
            "close": 501.5928,
            "volume": 40944,
            "datetime": 1713376080000
        },
        {
            "open": 501.6,
            "high": 501.83,
            "low": 501.53,
            "close": 501.83,
            "volume": 66807,
            "datetime": 1713376140000
        },
        {
            "open": 501.845,
            "high": 502.22,
            "low": 501.845,
            "close": 502.01,
            "volume": 158028,
            "datetime": 1713376200000
        },
        {
            "open": 502.0004,
            "high": 502.015,
            "low": 501.78,
            "close": 501.78,
            "volume": 113581,
            "datetime": 1713376260000
        },
        {
            "open": 501.8,
            "high": 501.87,
            "low": 501.42,
            "close": 501.64,
            "volume": 117149,
            "datetime": 1713376320000
        },
        {
            "open": 501.65,
            "high": 501.9899,
            "low": 501.53,
            "close": 501.9899,
            "volume": 97424,
            "datetime": 1713376380000
        },
        {
            "open": 501.96,
            "high": 502.3,
            "low": 501.93,
            "close": 502.12,
            "volume": 145499,
            "datetime": 1713376440000
        },
        {
            "open": 502.11,
            "high": 502.48,
            "low": 502.06,
            "close": 502.45,
            "volume": 161079,
            "datetime": 1713376500000
        },
        {
            "open": 502.45,
            "high": 502.48,
            "low": 502.26,
            "close": 502.44,
            "volume": 157327,
            "datetime": 1713376560000
        },
        {
            "open": 502.44,
            "high": 502.56,
            "low": 502.32,
            "close": 502.41,
            "volume": 144412,
            "datetime": 1713376620000
        },
        {
            "open": 502.43,
            "high": 502.44,
            "low": 502.2,
            "close": 502.23,
            "volume": 106642,
            "datetime": 1713376680000
        },
        {
            "open": 502.24,
            "high": 502.42,
            "low": 502.21,
            "close": 502.4,
            "volume": 89763,
            "datetime": 1713376740000
        },
        {
            "open": 502.41,
            "high": 502.43,
            "low": 501.99,
            "close": 502.0599,
            "volume": 115154,
            "datetime": 1713376800000
        },
        {
            "open": 502.05,
            "high": 502.44,
            "low": 502.04,
            "close": 502.4,
            "volume": 85901,
            "datetime": 1713376860000
        },
        {
            "open": 502.42,
            "high": 502.45,
            "low": 502.33,
            "close": 502.37,
            "volume": 52672,
            "datetime": 1713376920000
        },
        {
            "open": 502.37,
            "high": 502.54,
            "low": 502.21,
            "close": 502.22,
            "volume": 70973,
            "datetime": 1713376980000
        },
        {
            "open": 502.23,
            "high": 502.495,
            "low": 502.23,
            "close": 502.47,
            "volume": 62577,
            "datetime": 1713377040000
        },
        {
            "open": 502.45,
            "high": 502.48,
            "low": 502.2397,
            "close": 502.25,
            "volume": 65221,
            "datetime": 1713377100000
        },
        {
            "open": 502.25,
            "high": 502.33,
            "low": 502.13,
            "close": 502.21,
            "volume": 68297,
            "datetime": 1713377160000
        },
        {
            "open": 502.23,
            "high": 502.484,
            "low": 502.19,
            "close": 502.4313,
            "volume": 74518,
            "datetime": 1713377220000
        },
        {
            "open": 502.43,
            "high": 502.52,
            "low": 502.12,
            "close": 502.205,
            "volume": 99521,
            "datetime": 1713377280000
        },
        {
            "open": 502.21,
            "high": 502.46,
            "low": 502.2,
            "close": 502.35,
            "volume": 63423,
            "datetime": 1713377340000
        },
        {
            "open": 502.31,
            "high": 502.325,
            "low": 502.04,
            "close": 502.15,
            "volume": 75155,
            "datetime": 1713377400000
        },
        {
            "open": 502.14,
            "high": 502.14,
            "low": 501.77,
            "close": 501.88,
            "volume": 200426,
            "datetime": 1713377460000
        },
        {
            "open": 501.885,
            "high": 502.16,
            "low": 501.885,
            "close": 501.9787,
            "volume": 133567,
            "datetime": 1713377520000
        },
        {
            "open": 501.98,
            "high": 502.25,
            "low": 501.98,
            "close": 502.195,
            "volume": 123463,
            "datetime": 1713377580000
        },
        {
            "open": 502.22,
            "high": 502.31,
            "low": 501.85,
            "close": 501.88,
            "volume": 86340,
            "datetime": 1713377640000
        },
        {
            "open": 501.89,
            "high": 501.9596,
            "low": 501.78,
            "close": 501.8009,
            "volume": 98823,
            "datetime": 1713377700000
        },
        {
            "open": 501.82,
            "high": 501.885,
            "low": 501.42,
            "close": 501.43,
            "volume": 168644,
            "datetime": 1713377760000
        },
        {
            "open": 501.425,
            "high": 501.72,
            "low": 501.38,
            "close": 501.69,
            "volume": 114561,
            "datetime": 1713377820000
        },
        {
            "open": 501.695,
            "high": 502.15,
            "low": 501.69,
            "close": 502.1,
            "volume": 143767,
            "datetime": 1713377880000
        },
        {
            "open": 502.075,
            "high": 502.22,
            "low": 501.87,
            "close": 501.97,
            "volume": 105034,
            "datetime": 1713377940000
        },
        {
            "open": 501.955,
            "high": 501.99,
            "low": 501.75,
            "close": 501.75,
            "volume": 97297,
            "datetime": 1713378000000
        },
        {
            "open": 501.74,
            "high": 501.8,
            "low": 501.58,
            "close": 501.78,
            "volume": 97516,
            "datetime": 1713378060000
        },
        {
            "open": 501.76,
            "high": 501.99,
            "low": 501.67,
            "close": 501.88,
            "volume": 112343,
            "datetime": 1713378120000
        },
        {
            "open": 501.88,
            "high": 501.88,
            "low": 501.68,
            "close": 501.73,
            "volume": 57748,
            "datetime": 1713378180000
        },
        {
            "open": 501.75,
            "high": 501.84,
            "low": 501.54,
            "close": 501.83,
            "volume": 69638,
            "datetime": 1713378240000
        },
        {
            "open": 501.83,
            "high": 501.965,
            "low": 501.74,
            "close": 501.82,
            "volume": 63566,
            "datetime": 1713378300000
        },
        {
            "open": 501.82,
            "high": 501.88,
            "low": 501.68,
            "close": 501.7,
            "volume": 81828,
            "datetime": 1713378360000
        },
        {
            "open": 501.71,
            "high": 502.01,
            "low": 501.67,
            "close": 501.9,
            "volume": 47265,
            "datetime": 1713378420000
        },
        {
            "open": 501.92,
            "high": 502.4,
            "low": 501.87,
            "close": 502.29,
            "volume": 105318,
            "datetime": 1713378480000
        },
        {
            "open": 502.28,
            "high": 502.28,
            "low": 501.795,
            "close": 501.86,
            "volume": 69319,
            "datetime": 1713378540000
        },
        {
            "open": 501.87,
            "high": 501.985,
            "low": 501.8,
            "close": 501.84,
            "volume": 50539,
            "datetime": 1713378600000
        },
        {
            "open": 501.85,
            "high": 502.01,
            "low": 501.75,
            "close": 501.96,
            "volume": 65514,
            "datetime": 1713378660000
        },
        {
            "open": 501.95,
            "high": 502.1,
            "low": 501.88,
            "close": 501.97,
            "volume": 82033,
            "datetime": 1713378720000
        },
        {
            "open": 501.96,
            "high": 501.96,
            "low": 501.74,
            "close": 501.84,
            "volume": 85366,
            "datetime": 1713378780000
        },
        {
            "open": 501.84,
            "high": 501.92,
            "low": 501.73,
            "close": 501.86,
            "volume": 52127,
            "datetime": 1713378840000
        },
        {
            "open": 501.86,
            "high": 501.92,
            "low": 501.53,
            "close": 501.81,
            "volume": 82255,
            "datetime": 1713378900000
        },
        {
            "open": 501.81,
            "high": 501.98,
            "low": 501.56,
            "close": 501.66,
            "volume": 77365,
            "datetime": 1713378960000
        },
        {
            "open": 501.66,
            "high": 501.66,
            "low": 501.44,
            "close": 501.575,
            "volume": 69568,
            "datetime": 1713379020000
        },
        {
            "open": 501.58,
            "high": 501.94,
            "low": 501.57,
            "close": 501.88,
            "volume": 74168,
            "datetime": 1713379080000
        },
        {
            "open": 501.9,
            "high": 502.1712,
            "low": 501.85,
            "close": 502.02,
            "volume": 77102,
            "datetime": 1713379140000
        },
        {
            "open": 502.04,
            "high": 502.41,
            "low": 502.02,
            "close": 502.31,
            "volume": 82050,
            "datetime": 1713379200000
        },
        {
            "open": 502.3101,
            "high": 502.91,
            "low": 502.31,
            "close": 502.85,
            "volume": 289063,
            "datetime": 1713379260000
        },
        {
            "open": 502.85,
            "high": 503.61,
            "low": 502.77,
            "close": 503.4904,
            "volume": 360670,
            "datetime": 1713379320000
        },
        {
            "open": 503.48,
            "high": 503.56,
            "low": 503.29,
            "close": 503.35,
            "volume": 216770,
            "datetime": 1713379380000
        },
        {
            "open": 503.34,
            "high": 503.9,
            "low": 503.31,
            "close": 503.87,
            "volume": 180999,
            "datetime": 1713379440000
        },
        {
            "open": 503.86,
            "high": 503.98,
            "low": 503.74,
            "close": 503.94,
            "volume": 207447,
            "datetime": 1713379500000
        },
        {
            "open": 503.93,
            "high": 504.03,
            "low": 503.78,
            "close": 503.88,
            "volume": 171987,
            "datetime": 1713379560000
        },
        {
            "open": 503.86,
            "high": 503.92,
            "low": 503.3199,
            "close": 503.3401,
            "volume": 180558,
            "datetime": 1713379620000
        },
        {
            "open": 503.4,
            "high": 503.42,
            "low": 503.16,
            "close": 503.3001,
            "volume": 179245,
            "datetime": 1713379680000
        },
        {
            "open": 503.31,
            "high": 503.34,
            "low": 502.86,
            "close": 502.95,
            "volume": 179798,
            "datetime": 1713379740000
        },
        {
            "open": 502.98,
            "high": 503.28,
            "low": 502.98,
            "close": 503.21,
            "volume": 129371,
            "datetime": 1713379800000
        },
        {
            "open": 503.21,
            "high": 503.28,
            "low": 502.835,
            "close": 502.88,
            "volume": 103576,
            "datetime": 1713379860000
        },
        {
            "open": 502.8691,
            "high": 502.9101,
            "low": 502.41,
            "close": 502.43,
            "volume": 196390,
            "datetime": 1713379920000
        },
        {
            "open": 502.4351,
            "high": 502.475,
            "low": 501.77,
            "close": 501.83,
            "volume": 238532,
            "datetime": 1713379980000
        },
        {
            "open": 501.84,
            "high": 501.87,
            "low": 501.5,
            "close": 501.7,
            "volume": 202843,
            "datetime": 1713380040000
        },
        {
            "open": 501.71,
            "high": 501.945,
            "low": 501.3,
            "close": 501.44,
            "volume": 224530,
            "datetime": 1713380100000
        },
        {
            "open": 501.45,
            "high": 501.94,
            "low": 501.45,
            "close": 501.66,
            "volume": 154146,
            "datetime": 1713380160000
        },
        {
            "open": 501.625,
            "high": 501.9401,
            "low": 501.58,
            "close": 501.66,
            "volume": 173235,
            "datetime": 1713380220000
        },
        {
            "open": 501.65,
            "high": 501.86,
            "low": 501.53,
            "close": 501.79,
            "volume": 122391,
            "datetime": 1713380280000
        },
        {
            "open": 501.73,
            "high": 501.77,
            "low": 501.37,
            "close": 501.59,
            "volume": 120267,
            "datetime": 1713380340000
        },
        {
            "open": 501.59,
            "high": 501.59,
            "low": 501.095,
            "close": 501.16,
            "volume": 225650,
            "datetime": 1713380400000
        },
        {
            "open": 501.14,
            "high": 501.46,
            "low": 501.01,
            "close": 501.22,
            "volume": 117616,
            "datetime": 1713380460000
        },
        {
            "open": 501.22,
            "high": 501.36,
            "low": 501.02,
            "close": 501.09,
            "volume": 111689,
            "datetime": 1713380520000
        },
        {
            "open": 501.09,
            "high": 501.4,
            "low": 501.09,
            "close": 501.28,
            "volume": 130502,
            "datetime": 1713380580000
        },
        {
            "open": 501.24,
            "high": 501.29,
            "low": 500.89,
            "close": 500.93,
            "volume": 114787,
            "datetime": 1713380640000
        },
        {
            "open": 500.94,
            "high": 501.24,
            "low": 500.94,
            "close": 501.09,
            "volume": 143865,
            "datetime": 1713380700000
        },
        {
            "open": 501.07,
            "high": 501.08,
            "low": 500.75,
            "close": 500.835,
            "volume": 153794,
            "datetime": 1713380760000
        },
        {
            "open": 500.84,
            "high": 501.1003,
            "low": 500.82,
            "close": 500.835,
            "volume": 135185,
            "datetime": 1713380820000
        },
        {
            "open": 500.83,
            "high": 501.13,
            "low": 500.65,
            "close": 500.98,
            "volume": 172227,
            "datetime": 1713380880000
        },
        {
            "open": 500.99,
            "high": 501.04,
            "low": 500.8224,
            "close": 500.93,
            "volume": 105466,
            "datetime": 1713380940000
        },
        {
            "open": 500.9,
            "high": 501.13,
            "low": 500.79,
            "close": 500.85,
            "volume": 80632,
            "datetime": 1713381000000
        },
        {
            "open": 500.825,
            "high": 500.93,
            "low": 500.54,
            "close": 500.54,
            "volume": 136822,
            "datetime": 1713381060000
        },
        {
            "open": 500.61,
            "high": 500.69,
            "low": 500.36,
            "close": 500.56,
            "volume": 196605,
            "datetime": 1713381120000
        },
        {
            "open": 500.56,
            "high": 500.82,
            "low": 500.42,
            "close": 500.82,
            "volume": 116428,
            "datetime": 1713381180000
        },
        {
            "open": 500.835,
            "high": 500.85,
            "low": 500.5298,
            "close": 500.57,
            "volume": 123857,
            "datetime": 1713381240000
        },
        {
            "open": 500.56,
            "high": 501.04,
            "low": 500.52,
            "close": 500.902,
            "volume": 133239,
            "datetime": 1713381300000
        },
        {
            "open": 500.89,
            "high": 501.3,
            "low": 500.8,
            "close": 501.15,
            "volume": 232072,
            "datetime": 1713381360000
        },
        {
            "open": 501.16,
            "high": 501.55,
            "low": 501.05,
            "close": 501.3,
            "volume": 323245,
            "datetime": 1713381420000
        },
        {
            "open": 501.29,
            "high": 501.35,
            "low": 501.08,
            "close": 501.25,
            "volume": 142266,
            "datetime": 1713381480000
        },
        {
            "open": 501.26,
            "high": 501.35,
            "low": 501.13,
            "close": 501.22,
            "volume": 104497,
            "datetime": 1713381540000
        },
        {
            "open": 501.23,
            "high": 501.475,
            "low": 501.11,
            "close": 501.1475,
            "volume": 171362,
            "datetime": 1713381600000
        },
        {
            "open": 501.145,
            "high": 501.16,
            "low": 500.83,
            "close": 500.98,
            "volume": 161821,
            "datetime": 1713381660000
        },
        {
            "open": 500.99,
            "high": 501.1,
            "low": 500.82,
            "close": 500.86,
            "volume": 103147,
            "datetime": 1713381720000
        },
        {
            "open": 500.87,
            "high": 501.26,
            "low": 500.87,
            "close": 501.16,
            "volume": 117537,
            "datetime": 1713381780000
        },
        {
            "open": 501.175,
            "high": 501.24,
            "low": 500.98,
            "close": 501.02,
            "volume": 97629,
            "datetime": 1713381840000
        },
        {
            "open": 501.01,
            "high": 501.12,
            "low": 500.89,
            "close": 500.97,
            "volume": 163601,
            "datetime": 1713381900000
        },
        {
            "open": 501,
            "high": 501.27,
            "low": 500.9,
            "close": 501.01,
            "volume": 156864,
            "datetime": 1713381960000
        },
        {
            "open": 501,
            "high": 501.13,
            "low": 500.78,
            "close": 500.885,
            "volume": 155163,
            "datetime": 1713382020000
        },
        {
            "open": 500.88,
            "high": 500.91,
            "low": 500.65,
            "close": 500.72,
            "volume": 82421,
            "datetime": 1713382080000
        },
        {
            "open": 500.7289,
            "high": 500.8,
            "low": 500.58,
            "close": 500.7197,
            "volume": 90450,
            "datetime": 1713382140000
        },
        {
            "open": 500.71,
            "high": 501.2066,
            "low": 500.71,
            "close": 501.18,
            "volume": 192245,
            "datetime": 1713382200000
        },
        {
            "open": 501.18,
            "high": 501.25,
            "low": 500.99,
            "close": 501.05,
            "volume": 162996,
            "datetime": 1713382260000
        },
        {
            "open": 501.05,
            "high": 501.1,
            "low": 500.68,
            "close": 500.73,
            "volume": 151009,
            "datetime": 1713382320000
        },
        {
            "open": 500.73,
            "high": 501.37,
            "low": 500.72,
            "close": 501.245,
            "volume": 136070,
            "datetime": 1713382380000
        },
        {
            "open": 501.25,
            "high": 501.39,
            "low": 501.14,
            "close": 501.18,
            "volume": 111654,
            "datetime": 1713382440000
        },
        {
            "open": 501.15,
            "high": 501.15,
            "low": 500.95,
            "close": 501.04,
            "volume": 104066,
            "datetime": 1713382500000
        },
        {
            "open": 501.02,
            "high": 501.15,
            "low": 500.96,
            "close": 501.075,
            "volume": 84623,
            "datetime": 1713382560000
        },
        {
            "open": 501.08,
            "high": 501.21,
            "low": 500.93,
            "close": 501.12,
            "volume": 133896,
            "datetime": 1713382620000
        },
        {
            "open": 501.12,
            "high": 501.12,
            "low": 500.47,
            "close": 500.75,
            "volume": 198494,
            "datetime": 1713382680000
        },
        {
            "open": 500.73,
            "high": 500.9183,
            "low": 500.6399,
            "close": 500.715,
            "volume": 129481,
            "datetime": 1713382740000
        },
        {
            "open": 500.715,
            "high": 500.79,
            "low": 500.425,
            "close": 500.54,
            "volume": 167909,
            "datetime": 1713382800000
        },
        {
            "open": 500.545,
            "high": 500.95,
            "low": 500.41,
            "close": 500.855,
            "volume": 216695,
            "datetime": 1713382860000
        },
        {
            "open": 500.86,
            "high": 501.1,
            "low": 500.84,
            "close": 501.07,
            "volume": 254325,
            "datetime": 1713382920000
        },
        {
            "open": 501.1,
            "high": 501.395,
            "low": 500.95,
            "close": 501.27,
            "volume": 153507,
            "datetime": 1713382980000
        },
        {
            "open": 501.27,
            "high": 501.49,
            "low": 501.25,
            "close": 501.3,
            "volume": 145578,
            "datetime": 1713383040000
        },
        {
            "open": 501.31,
            "high": 501.34,
            "low": 501.08,
            "close": 501.23,
            "volume": 223514,
            "datetime": 1713383100000
        },
        {
            "open": 501.24,
            "high": 501.52,
            "low": 501.15,
            "close": 501.49,
            "volume": 132588,
            "datetime": 1713383160000
        },
        {
            "open": 501.49,
            "high": 501.73,
            "low": 501.35,
            "close": 501.545,
            "volume": 215877,
            "datetime": 1713383220000
        },
        {
            "open": 501.545,
            "high": 501.8,
            "low": 501.51,
            "close": 501.62,
            "volume": 168010,
            "datetime": 1713383280000
        },
        {
            "open": 501.62,
            "high": 501.82,
            "low": 501.51,
            "close": 501.63,
            "volume": 157892,
            "datetime": 1713383340000
        },
        {
            "open": 501.61,
            "high": 501.74,
            "low": 501.28,
            "close": 501.3,
            "volume": 360055,
            "datetime": 1713383400000
        },
        {
            "open": 501.31,
            "high": 501.34,
            "low": 500.89,
            "close": 500.95,
            "volume": 311611,
            "datetime": 1713383460000
        },
        {
            "open": 500.965,
            "high": 501.349,
            "low": 500.96,
            "close": 501.29,
            "volume": 221210,
            "datetime": 1713383520000
        },
        {
            "open": 501.3,
            "high": 501.65,
            "low": 501.3,
            "close": 501.545,
            "volume": 181774,
            "datetime": 1713383580000
        },
        {
            "open": 501.53,
            "high": 501.74,
            "low": 501.49,
            "close": 501.63,
            "volume": 289914,
            "datetime": 1713383640000
        },
        {
            "open": 501.61,
            "high": 501.68,
            "low": 501.05,
            "close": 501.125,
            "volume": 369589,
            "datetime": 1713383700000
        },
        {
            "open": 501.13,
            "high": 501.34,
            "low": 501.07,
            "close": 501.11,
            "volume": 257864,
            "datetime": 1713383760000
        },
        {
            "open": 501.12,
            "high": 501.13,
            "low": 500.8,
            "close": 500.985,
            "volume": 337017,
            "datetime": 1713383820000
        },
        {
            "open": 500.99,
            "high": 501.23,
            "low": 500.98,
            "close": 501.19,
            "volume": 503045,
            "datetime": 1713383880000
        },
        {
            "open": 501.18,
            "high": 501.26,
            "low": 500.44,
            "close": 500.5,
            "volume": 2357504,
            "datetime": 1713383940000
        },
        {
            "open": 501.98,
            "high": 502.08,
            "low": 501.57,
            "close": 502.02,
            "volume": 1063112,
            "datetime": 1713447000000
        },
        {
            "open": 502.02,
            "high": 502.05,
            "low": 501.65,
            "close": 501.7117,
            "volume": 148023,
            "datetime": 1713447060000
        },
        {
            "open": 501.7,
            "high": 501.7012,
            "low": 501.375,
            "close": 501.56,
            "volume": 144836,
            "datetime": 1713447120000
        },
        {
            "open": 501.57,
            "high": 501.62,
            "low": 501.1721,
            "close": 501.28,
            "volume": 136008,
            "datetime": 1713447180000
        },
        {
            "open": 501.28,
            "high": 501.4,
            "low": 501.09,
            "close": 501.095,
            "volume": 109105,
            "datetime": 1713447240000
        },
        {
            "open": 501.1,
            "high": 501.1587,
            "low": 500.52,
            "close": 500.56,
            "volume": 243051,
            "datetime": 1713447300000
        },
        {
            "open": 500.55,
            "high": 500.64,
            "low": 500.12,
            "close": 500.14,
            "volume": 274602,
            "datetime": 1713447360000
        },
        {
            "open": 500.13,
            "high": 500.22,
            "low": 499.86,
            "close": 499.9101,
            "volume": 397079,
            "datetime": 1713447420000
        },
        {
            "open": 499.93,
            "high": 500.12,
            "low": 499.92,
            "close": 500.05,
            "volume": 136451,
            "datetime": 1713447480000
        },
        {
            "open": 500.02,
            "high": 500.1101,
            "low": 499.73,
            "close": 499.87,
            "volume": 235765,
            "datetime": 1713447540000
        },
        {
            "open": 499.88,
            "high": 500.12,
            "low": 499.826,
            "close": 500.07,
            "volume": 195307,
            "datetime": 1713447600000
        },
        {
            "open": 500.06,
            "high": 500.3099,
            "low": 499.99,
            "close": 500.12,
            "volume": 257042,
            "datetime": 1713447660000
        },
        {
            "open": 500.13,
            "high": 500.389,
            "low": 500.1,
            "close": 500.21,
            "volume": 173870,
            "datetime": 1713447720000
        },
        {
            "open": 500.22,
            "high": 500.6,
            "low": 500.2,
            "close": 500.485,
            "volume": 183316,
            "datetime": 1713447780000
        },
        {
            "open": 500.49,
            "high": 500.51,
            "low": 500.25,
            "close": 500.3491,
            "volume": 109669,
            "datetime": 1713447840000
        },
        {
            "open": 500.36,
            "high": 500.425,
            "low": 500.25,
            "close": 500.3,
            "volume": 163752,
            "datetime": 1713447900000
        },
        {
            "open": 500.3,
            "high": 500.64,
            "low": 500.24,
            "close": 500.63,
            "volume": 131593,
            "datetime": 1713447960000
        },
        {
            "open": 500.64,
            "high": 500.97,
            "low": 500.59,
            "close": 500.75,
            "volume": 260460,
            "datetime": 1713448020000
        },
        {
            "open": 500.72,
            "high": 500.86,
            "low": 500.43,
            "close": 500.84,
            "volume": 189208,
            "datetime": 1713448080000
        },
        {
            "open": 500.83,
            "high": 501.22,
            "low": 500.75,
            "close": 501.145,
            "volume": 134441,
            "datetime": 1713448140000
        },
        {
            "open": 501.15,
            "high": 501.37,
            "low": 501,
            "close": 501.18,
            "volume": 201677,
            "datetime": 1713448200000
        },
        {
            "open": 501.13,
            "high": 501.2,
            "low": 500.93,
            "close": 500.94,
            "volume": 156017,
            "datetime": 1713448260000
        },
        {
            "open": 500.94,
            "high": 500.98,
            "low": 500.69,
            "close": 500.85,
            "volume": 185955,
            "datetime": 1713448320000
        },
        {
            "open": 500.82,
            "high": 500.95,
            "low": 500.67,
            "close": 500.9,
            "volume": 125307,
            "datetime": 1713448380000
        },
        {
            "open": 500.9,
            "high": 500.9197,
            "low": 500.57,
            "close": 500.68,
            "volume": 66908,
            "datetime": 1713448440000
        },
        {
            "open": 500.67,
            "high": 501.14,
            "low": 500.57,
            "close": 501.09,
            "volume": 129926,
            "datetime": 1713448500000
        },
        {
            "open": 501.1,
            "high": 501.37,
            "low": 501.025,
            "close": 501.31,
            "volume": 143694,
            "datetime": 1713448560000
        },
        {
            "open": 501.315,
            "high": 501.345,
            "low": 500.91,
            "close": 500.97,
            "volume": 106064,
            "datetime": 1713448620000
        },
        {
            "open": 500.98,
            "high": 501.1,
            "low": 500.9679,
            "close": 501.03,
            "volume": 73883,
            "datetime": 1713448680000
        },
        {
            "open": 501.04,
            "high": 501.29,
            "low": 501.04,
            "close": 501.18,
            "volume": 64192,
            "datetime": 1713448740000
        },
        {
            "open": 501.17,
            "high": 501.32,
            "low": 500.955,
            "close": 500.96,
            "volume": 106566,
            "datetime": 1713448800000
        },
        {
            "open": 500.95,
            "high": 501.09,
            "low": 500.83,
            "close": 500.9831,
            "volume": 172244,
            "datetime": 1713448860000
        },
        {
            "open": 500.99,
            "high": 501.06,
            "low": 500.85,
            "close": 500.9,
            "volume": 142792,
            "datetime": 1713448920000
        },
        {
            "open": 500.89,
            "high": 500.93,
            "low": 500.35,
            "close": 500.55,
            "volume": 287975,
            "datetime": 1713448980000
        },
        {
            "open": 500.54,
            "high": 500.95,
            "low": 500.44,
            "close": 500.59,
            "volume": 134889,
            "datetime": 1713449040000
        },
        {
            "open": 500.59,
            "high": 501.04,
            "low": 500.58,
            "close": 500.925,
            "volume": 113947,
            "datetime": 1713449100000
        },
        {
            "open": 500.93,
            "high": 501.29,
            "low": 500.93,
            "close": 501.09,
            "volume": 96623,
            "datetime": 1713449160000
        },
        {
            "open": 501.07,
            "high": 501.12,
            "low": 500.88,
            "close": 500.98,
            "volume": 61624,
            "datetime": 1713449220000
        },
        {
            "open": 500.9511,
            "high": 501.01,
            "low": 500.77,
            "close": 501.01,
            "volume": 75868,
            "datetime": 1713449280000
        },
        {
            "open": 501.01,
            "high": 501.68,
            "low": 500.96,
            "close": 501.62,
            "volume": 176252,
            "datetime": 1713449340000
        },
        {
            "open": 501.6,
            "high": 501.76,
            "low": 501.54,
            "close": 501.66,
            "volume": 147457,
            "datetime": 1713449400000
        },
        {
            "open": 501.66,
            "high": 501.68,
            "low": 501.4103,
            "close": 501.6,
            "volume": 129020,
            "datetime": 1713449460000
        },
        {
            "open": 501.59,
            "high": 501.66,
            "low": 501.27,
            "close": 501.3,
            "volume": 93512,
            "datetime": 1713449520000
        },
        {
            "open": 501.3,
            "high": 501.5712,
            "low": 501.225,
            "close": 501.57,
            "volume": 92682,
            "datetime": 1713449580000
        },
        {
            "open": 501.55,
            "high": 501.72,
            "low": 501.51,
            "close": 501.71,
            "volume": 99129,
            "datetime": 1713449640000
        },
        {
            "open": 501.71,
            "high": 502.02,
            "low": 501.58,
            "close": 501.64,
            "volume": 154751,
            "datetime": 1713449700000
        },
        {
            "open": 501.65,
            "high": 501.84,
            "low": 501.61,
            "close": 501.76,
            "volume": 172669,
            "datetime": 1713449760000
        },
        {
            "open": 501.7693,
            "high": 502.1342,
            "low": 501.69,
            "close": 502,
            "volume": 209636,
            "datetime": 1713449820000
        },
        {
            "open": 502,
            "high": 502.0401,
            "low": 501.71,
            "close": 501.7112,
            "volume": 101418,
            "datetime": 1713449880000
        },
        {
            "open": 501.74,
            "high": 501.93,
            "low": 501.735,
            "close": 501.9,
            "volume": 72293,
            "datetime": 1713449940000
        },
        {
            "open": 501.86,
            "high": 501.87,
            "low": 501.6,
            "close": 501.8577,
            "volume": 97782,
            "datetime": 1713450000000
        },
        {
            "open": 501.86,
            "high": 501.9,
            "low": 501.54,
            "close": 501.805,
            "volume": 181153,
            "datetime": 1713450060000
        },
        {
            "open": 501.81,
            "high": 501.85,
            "low": 501.515,
            "close": 501.65,
            "volume": 100384,
            "datetime": 1713450120000
        },
        {
            "open": 501.65,
            "high": 501.93,
            "low": 501.64,
            "close": 501.65,
            "volume": 110927,
            "datetime": 1713450180000
        },
        {
            "open": 501.649,
            "high": 501.77,
            "low": 501.565,
            "close": 501.69,
            "volume": 82461,
            "datetime": 1713450240000
        },
        {
            "open": 501.675,
            "high": 501.975,
            "low": 501.65,
            "close": 501.85,
            "volume": 77776,
            "datetime": 1713450300000
        },
        {
            "open": 501.84,
            "high": 501.92,
            "low": 501.75,
            "close": 501.8296,
            "volume": 67265,
            "datetime": 1713450360000
        },
        {
            "open": 501.83,
            "high": 502.24,
            "low": 501.72,
            "close": 502.185,
            "volume": 163562,
            "datetime": 1713450420000
        },
        {
            "open": 502.18,
            "high": 502.22,
            "low": 501.76,
            "close": 501.9517,
            "volume": 137515,
            "datetime": 1713450480000
        },
        {
            "open": 501.9418,
            "high": 502.1617,
            "low": 501.94,
            "close": 502.04,
            "volume": 101167,
            "datetime": 1713450540000
        },
        {
            "open": 502.03,
            "high": 502.41,
            "low": 501.99,
            "close": 502.29,
            "volume": 152028,
            "datetime": 1713450600000
        },
        {
            "open": 502.295,
            "high": 502.39,
            "low": 502.14,
            "close": 502.18,
            "volume": 206861,
            "datetime": 1713450660000
        },
        {
            "open": 502.19,
            "high": 502.3268,
            "low": 502.09,
            "close": 502.2,
            "volume": 64062,
            "datetime": 1713450720000
        },
        {
            "open": 502.2,
            "high": 502.31,
            "low": 502.11,
            "close": 502.26,
            "volume": 91839,
            "datetime": 1713450780000
        },
        {
            "open": 502.26,
            "high": 502.55,
            "low": 502.16,
            "close": 502.48,
            "volume": 159278,
            "datetime": 1713450840000
        },
        {
            "open": 502.48,
            "high": 502.63,
            "low": 502.37,
            "close": 502.6,
            "volume": 177301,
            "datetime": 1713450900000
        },
        {
            "open": 502.59,
            "high": 502.97,
            "low": 502.55,
            "close": 502.9101,
            "volume": 189744,
            "datetime": 1713450960000
        },
        {
            "open": 502.92,
            "high": 503.12,
            "low": 502.81,
            "close": 502.92,
            "volume": 179301,
            "datetime": 1713451020000
        },
        {
            "open": 502.91,
            "high": 502.95,
            "low": 502.6,
            "close": 502.625,
            "volume": 183281,
            "datetime": 1713451080000
        },
        {
            "open": 502.62,
            "high": 502.7125,
            "low": 502.501,
            "close": 502.51,
            "volume": 107985,
            "datetime": 1713451140000
        },
        {
            "open": 502.5,
            "high": 502.59,
            "low": 502.3914,
            "close": 502.4501,
            "volume": 157222,
            "datetime": 1713451200000
        },
        {
            "open": 502.48,
            "high": 502.61,
            "low": 502.43,
            "close": 502.56,
            "volume": 62121,
            "datetime": 1713451260000
        },
        {
            "open": 502.55,
            "high": 502.915,
            "low": 502.54,
            "close": 502.89,
            "volume": 95018,
            "datetime": 1713451320000
        },
        {
            "open": 502.895,
            "high": 503.08,
            "low": 502.865,
            "close": 503.01,
            "volume": 98880,
            "datetime": 1713451380000
        },
        {
            "open": 503,
            "high": 503.03,
            "low": 502.87,
            "close": 503,
            "volume": 86195,
            "datetime": 1713451440000
        },
        {
            "open": 502.9801,
            "high": 503.35,
            "low": 502.9801,
            "close": 503.265,
            "volume": 202085,
            "datetime": 1713451500000
        },
        {
            "open": 503.26,
            "high": 503.3,
            "low": 503.01,
            "close": 503.01,
            "volume": 107180,
            "datetime": 1713451560000
        },
        {
            "open": 503.01,
            "high": 503.01,
            "low": 502.73,
            "close": 502.8,
            "volume": 139375,
            "datetime": 1713451620000
        },
        {
            "open": 502.83,
            "high": 503.0688,
            "low": 502.83,
            "close": 503.04,
            "volume": 92900,
            "datetime": 1713451680000
        },
        {
            "open": 503.05,
            "high": 503.3199,
            "low": 502.97,
            "close": 503.13,
            "volume": 113425,
            "datetime": 1713451740000
        },
        {
            "open": 503.125,
            "high": 503.25,
            "low": 502.925,
            "close": 503.22,
            "volume": 172116,
            "datetime": 1713451800000
        },
        {
            "open": 503.24,
            "high": 503.345,
            "low": 503.07,
            "close": 503.12,
            "volume": 90925,
            "datetime": 1713451860000
        },
        {
            "open": 503.1213,
            "high": 503.14,
            "low": 502.83,
            "close": 502.97,
            "volume": 107718,
            "datetime": 1713451920000
        },
        {
            "open": 502.95,
            "high": 503.2,
            "low": 502.95,
            "close": 503.12,
            "volume": 91739,
            "datetime": 1713451980000
        },
        {
            "open": 503.1,
            "high": 503.2,
            "low": 503.02,
            "close": 503.05,
            "volume": 103259,
            "datetime": 1713452040000
        },
        {
            "open": 503.0713,
            "high": 503.53,
            "low": 503.07,
            "close": 503.4701,
            "volume": 130869,
            "datetime": 1713452100000
        },
        {
            "open": 503.485,
            "high": 503.82,
            "low": 503.485,
            "close": 503.685,
            "volume": 256035,
            "datetime": 1713452160000
        },
        {
            "open": 503.68,
            "high": 503.76,
            "low": 503.4437,
            "close": 503.52,
            "volume": 146778,
            "datetime": 1713452220000
        },
        {
            "open": 503.53,
            "high": 503.56,
            "low": 503.078,
            "close": 503.15,
            "volume": 119336,
            "datetime": 1713452280000
        },
        {
            "open": 503.16,
            "high": 503.39,
            "low": 503.16,
            "close": 503.39,
            "volume": 97070,
            "datetime": 1713452340000
        },
        {
            "open": 503.385,
            "high": 503.48,
            "low": 503.3,
            "close": 503.43,
            "volume": 121493,
            "datetime": 1713452400000
        },
        {
            "open": 503.45,
            "high": 503.525,
            "low": 503.31,
            "close": 503.33,
            "volume": 67626,
            "datetime": 1713452460000
        },
        {
            "open": 503.34,
            "high": 503.43,
            "low": 503.215,
            "close": 503.35,
            "volume": 147856,
            "datetime": 1713452520000
        },
        {
            "open": 503.35,
            "high": 503.45,
            "low": 503.25,
            "close": 503.3,
            "volume": 87060,
            "datetime": 1713452580000
        },
        {
            "open": 503.325,
            "high": 503.39,
            "low": 503.0701,
            "close": 503.11,
            "volume": 87273,
            "datetime": 1713452640000
        },
        {
            "open": 503.11,
            "high": 503.43,
            "low": 503.11,
            "close": 503.3151,
            "volume": 73679,
            "datetime": 1713452700000
        },
        {
            "open": 503.31,
            "high": 503.3812,
            "low": 503.22,
            "close": 503.28,
            "volume": 67449,
            "datetime": 1713452760000
        },
        {
            "open": 503.28,
            "high": 503.28,
            "low": 503.12,
            "close": 503.17,
            "volume": 71960,
            "datetime": 1713452820000
        },
        {
            "open": 503.17,
            "high": 503.56,
            "low": 503.17,
            "close": 503.4621,
            "volume": 141568,
            "datetime": 1713452880000
        },
        {
            "open": 503.46,
            "high": 503.5,
            "low": 503.23,
            "close": 503.33,
            "volume": 112470,
            "datetime": 1713452940000
        },
        {
            "open": 503.34,
            "high": 503.665,
            "low": 503.32,
            "close": 503.65,
            "volume": 177053,
            "datetime": 1713453000000
        },
        {
            "open": 503.6499,
            "high": 503.745,
            "low": 503.52,
            "close": 503.685,
            "volume": 99416,
            "datetime": 1713453060000
        },
        {
            "open": 503.69,
            "high": 503.72,
            "low": 503.49,
            "close": 503.57,
            "volume": 73700,
            "datetime": 1713453120000
        },
        {
            "open": 503.57,
            "high": 503.57,
            "low": 503.32,
            "close": 503.47,
            "volume": 88219,
            "datetime": 1713453180000
        },
        {
            "open": 503.46,
            "high": 503.685,
            "low": 503.42,
            "close": 503.66,
            "volume": 42678,
            "datetime": 1713453240000
        },
        {
            "open": 503.66,
            "high": 503.86,
            "low": 503.66,
            "close": 503.71,
            "volume": 149023,
            "datetime": 1713453300000
        },
        {
            "open": 503.72,
            "high": 503.88,
            "low": 503.65,
            "close": 503.82,
            "volume": 80106,
            "datetime": 1713453360000
        },
        {
            "open": 503.8,
            "high": 504.1,
            "low": 503.8,
            "close": 504.08,
            "volume": 194809,
            "datetime": 1713453420000
        },
        {
            "open": 504.08,
            "high": 504.09,
            "low": 503.8,
            "close": 503.81,
            "volume": 107281,
            "datetime": 1713453480000
        },
        {
            "open": 503.8,
            "high": 503.8,
            "low": 503.59,
            "close": 503.62,
            "volume": 114086,
            "datetime": 1713453540000
        },
        {
            "open": 503.63,
            "high": 503.85,
            "low": 503.57,
            "close": 503.84,
            "volume": 70338,
            "datetime": 1713453600000
        },
        {
            "open": 503.85,
            "high": 504.04,
            "low": 503.85,
            "close": 504,
            "volume": 40286,
            "datetime": 1713453660000
        },
        {
            "open": 503.9901,
            "high": 504.13,
            "low": 503.898,
            "close": 503.9,
            "volume": 233382,
            "datetime": 1713453720000
        },
        {
            "open": 503.891,
            "high": 504.05,
            "low": 503.83,
            "close": 503.99,
            "volume": 79902,
            "datetime": 1713453780000
        },
        {
            "open": 503.98,
            "high": 504.03,
            "low": 503.9,
            "close": 504.01,
            "volume": 45405,
            "datetime": 1713453840000
        },
        {
            "open": 504.03,
            "high": 504.03,
            "low": 503.73,
            "close": 503.7769,
            "volume": 96599,
            "datetime": 1713453900000
        },
        {
            "open": 503.77,
            "high": 503.84,
            "low": 503.73,
            "close": 503.8,
            "volume": 70169,
            "datetime": 1713453960000
        },
        {
            "open": 503.7987,
            "high": 503.92,
            "low": 503.7,
            "close": 503.725,
            "volume": 51558,
            "datetime": 1713454020000
        },
        {
            "open": 503.6934,
            "high": 503.8,
            "low": 503.55,
            "close": 503.55,
            "volume": 108672,
            "datetime": 1713454080000
        },
        {
            "open": 503.56,
            "high": 503.6,
            "low": 503.14,
            "close": 503.21,
            "volume": 224374,
            "datetime": 1713454140000
        },
        {
            "open": 503.21,
            "high": 503.39,
            "low": 502.97,
            "close": 503.06,
            "volume": 178392,
            "datetime": 1713454200000
        },
        {
            "open": 503.07,
            "high": 503.3287,
            "low": 503,
            "close": 503.25,
            "volume": 117626,
            "datetime": 1713454260000
        },
        {
            "open": 503.255,
            "high": 503.39,
            "low": 503.24,
            "close": 503.37,
            "volume": 73782,
            "datetime": 1713454320000
        },
        {
            "open": 503.3247,
            "high": 503.369,
            "low": 503.14,
            "close": 503.1801,
            "volume": 47127,
            "datetime": 1713454380000
        },
        {
            "open": 503.18,
            "high": 503.2975,
            "low": 503.1375,
            "close": 503.196,
            "volume": 59864,
            "datetime": 1713454440000
        },
        {
            "open": 503.19,
            "high": 503.34,
            "low": 503.1801,
            "close": 503.2775,
            "volume": 73091,
            "datetime": 1713454500000
        },
        {
            "open": 503.29,
            "high": 503.34,
            "low": 503.0295,
            "close": 503.21,
            "volume": 73462,
            "datetime": 1713454560000
        },
        {
            "open": 503.2,
            "high": 503.31,
            "low": 503.155,
            "close": 503.22,
            "volume": 65234,
            "datetime": 1713454620000
        },
        {
            "open": 503.22,
            "high": 503.2397,
            "low": 502.92,
            "close": 503.03,
            "volume": 84119,
            "datetime": 1713454680000
        },
        {
            "open": 503.05,
            "high": 503.2401,
            "low": 503.045,
            "close": 503.13,
            "volume": 63392,
            "datetime": 1713454740000
        },
        {
            "open": 503.11,
            "high": 503.11,
            "low": 502.74,
            "close": 502.77,
            "volume": 117547,
            "datetime": 1713454800000
        },
        {
            "open": 502.78,
            "high": 503.02,
            "low": 502.75,
            "close": 502.94,
            "volume": 80801,
            "datetime": 1713454860000
        },
        {
            "open": 502.93,
            "high": 502.93,
            "low": 502.77,
            "close": 502.785,
            "volume": 60959,
            "datetime": 1713454920000
        },
        {
            "open": 502.78,
            "high": 502.8953,
            "low": 502.73,
            "close": 502.85,
            "volume": 79121,
            "datetime": 1713454980000
        },
        {
            "open": 502.83,
            "high": 503.07,
            "low": 502.75,
            "close": 503.055,
            "volume": 95995,
            "datetime": 1713455040000
        },
        {
            "open": 503.06,
            "high": 503.06,
            "low": 502.85,
            "close": 502.92,
            "volume": 119990,
            "datetime": 1713455100000
        },
        {
            "open": 502.92,
            "high": 503,
            "low": 502.82,
            "close": 502.85,
            "volume": 45438,
            "datetime": 1713455160000
        },
        {
            "open": 502.86,
            "high": 502.89,
            "low": 502.74,
            "close": 502.79,
            "volume": 40893,
            "datetime": 1713455220000
        },
        {
            "open": 502.79,
            "high": 502.83,
            "low": 502.71,
            "close": 502.785,
            "volume": 75687,
            "datetime": 1713455280000
        },
        {
            "open": 502.79,
            "high": 502.925,
            "low": 502.76,
            "close": 502.839,
            "volume": 58395,
            "datetime": 1713455340000
        },
        {
            "open": 502.83,
            "high": 502.87,
            "low": 502.64,
            "close": 502.85,
            "volume": 79193,
            "datetime": 1713455400000
        },
        {
            "open": 502.83,
            "high": 503.04,
            "low": 502.74,
            "close": 502.8988,
            "volume": 100584,
            "datetime": 1713455460000
        },
        {
            "open": 502.89,
            "high": 502.89,
            "low": 502.68,
            "close": 502.695,
            "volume": 42403,
            "datetime": 1713455520000
        },
        {
            "open": 502.71,
            "high": 502.73,
            "low": 502.63,
            "close": 502.71,
            "volume": 53571,
            "datetime": 1713455580000
        },
        {
            "open": 502.715,
            "high": 502.87,
            "low": 502.68,
            "close": 502.84,
            "volume": 50715,
            "datetime": 1713455640000
        },
        {
            "open": 502.839,
            "high": 502.88,
            "low": 502.73,
            "close": 502.795,
            "volume": 42901,
            "datetime": 1713455700000
        },
        {
            "open": 502.81,
            "high": 503.01,
            "low": 502.78,
            "close": 502.84,
            "volume": 52484,
            "datetime": 1713455760000
        },
        {
            "open": 502.8404,
            "high": 503.05,
            "low": 502.8404,
            "close": 503.03,
            "volume": 56876,
            "datetime": 1713455820000
        },
        {
            "open": 503.04,
            "high": 503.06,
            "low": 502.82,
            "close": 502.86,
            "volume": 42039,
            "datetime": 1713455880000
        },
        {
            "open": 502.84,
            "high": 502.9999,
            "low": 502.82,
            "close": 502.885,
            "volume": 42873,
            "datetime": 1713455940000
        },
        {
            "open": 502.88,
            "high": 503.28,
            "low": 502.8687,
            "close": 503.24,
            "volume": 188485,
            "datetime": 1713456000000
        },
        {
            "open": 503.24,
            "high": 503.34,
            "low": 503.11,
            "close": 503.14,
            "volume": 85856,
            "datetime": 1713456060000
        },
        {
            "open": 503.15,
            "high": 503.27,
            "low": 503.13,
            "close": 503.14,
            "volume": 39542,
            "datetime": 1713456120000
        },
        {
            "open": 503.13,
            "high": 503.15,
            "low": 503,
            "close": 503.015,
            "volume": 57135,
            "datetime": 1713456180000
        },
        {
            "open": 503.0101,
            "high": 503.0101,
            "low": 502.795,
            "close": 502.8104,
            "volume": 74964,
            "datetime": 1713456240000
        },
        {
            "open": 502.81,
            "high": 502.86,
            "low": 502.57,
            "close": 502.76,
            "volume": 102563,
            "datetime": 1713456300000
        },
        {
            "open": 502.755,
            "high": 502.77,
            "low": 502.48,
            "close": 502.52,
            "volume": 118494,
            "datetime": 1713456360000
        },
        {
            "open": 502.535,
            "high": 502.565,
            "low": 502.27,
            "close": 502.35,
            "volume": 124128,
            "datetime": 1713456420000
        },
        {
            "open": 502.35,
            "high": 502.52,
            "low": 502.27,
            "close": 502.3499,
            "volume": 96791,
            "datetime": 1713456480000
        },
        {
            "open": 502.34,
            "high": 502.485,
            "low": 502.26,
            "close": 502.46,
            "volume": 59409,
            "datetime": 1713456540000
        },
        {
            "open": 502.46,
            "high": 502.68,
            "low": 502.43,
            "close": 502.58,
            "volume": 79740,
            "datetime": 1713456600000
        },
        {
            "open": 502.59,
            "high": 502.73,
            "low": 502.56,
            "close": 502.6,
            "volume": 56264,
            "datetime": 1713456660000
        },
        {
            "open": 502.59,
            "high": 502.67,
            "low": 502.27,
            "close": 502.28,
            "volume": 73991,
            "datetime": 1713456720000
        },
        {
            "open": 502.28,
            "high": 502.38,
            "low": 502.275,
            "close": 502.325,
            "volume": 76495,
            "datetime": 1713456780000
        },
        {
            "open": 502.33,
            "high": 502.39,
            "low": 502.28,
            "close": 502.3,
            "volume": 48523,
            "datetime": 1713456840000
        },
        {
            "open": 502.28,
            "high": 502.36,
            "low": 502.23,
            "close": 502.3257,
            "volume": 59792,
            "datetime": 1713456900000
        },
        {
            "open": 502.3,
            "high": 502.435,
            "low": 502.22,
            "close": 502.25,
            "volume": 60521,
            "datetime": 1713456960000
        },
        {
            "open": 502.25,
            "high": 502.64,
            "low": 502.24,
            "close": 502.63,
            "volume": 72487,
            "datetime": 1713457020000
        },
        {
            "open": 502.63,
            "high": 502.6413,
            "low": 502.49,
            "close": 502.61,
            "volume": 46987,
            "datetime": 1713457080000
        },
        {
            "open": 502.61,
            "high": 502.6999,
            "low": 502.585,
            "close": 502.64,
            "volume": 69696,
            "datetime": 1713457140000
        },
        {
            "open": 502.59,
            "high": 502.68,
            "low": 502.49,
            "close": 502.665,
            "volume": 63404,
            "datetime": 1713457200000
        },
        {
            "open": 502.65,
            "high": 502.725,
            "low": 502.44,
            "close": 502.51,
            "volume": 50750,
            "datetime": 1713457260000
        },
        {
            "open": 502.5,
            "high": 502.6001,
            "low": 502.465,
            "close": 502.54,
            "volume": 38842,
            "datetime": 1713457320000
        },
        {
            "open": 502.54,
            "high": 502.54,
            "low": 502.41,
            "close": 502.42,
            "volume": 63714,
            "datetime": 1713457380000
        },
        {
            "open": 502.42,
            "high": 502.52,
            "low": 502.39,
            "close": 502.4475,
            "volume": 48552,
            "datetime": 1713457440000
        },
        {
            "open": 502.44,
            "high": 502.635,
            "low": 502.34,
            "close": 502.565,
            "volume": 68430,
            "datetime": 1713457500000
        },
        {
            "open": 502.575,
            "high": 502.78,
            "low": 502.48,
            "close": 502.5,
            "volume": 79457,
            "datetime": 1713457560000
        },
        {
            "open": 502.5,
            "high": 502.5088,
            "low": 502.31,
            "close": 502.435,
            "volume": 53743,
            "datetime": 1713457620000
        },
        {
            "open": 502.43,
            "high": 502.52,
            "low": 502.36,
            "close": 502.375,
            "volume": 25237,
            "datetime": 1713457680000
        },
        {
            "open": 502.375,
            "high": 502.6324,
            "low": 502.36,
            "close": 502.6324,
            "volume": 26838,
            "datetime": 1713457740000
        },
        {
            "open": 502.62,
            "high": 502.63,
            "low": 502.43,
            "close": 502.47,
            "volume": 59893,
            "datetime": 1713457800000
        },
        {
            "open": 502.47,
            "high": 502.55,
            "low": 502.27,
            "close": 502.35,
            "volume": 59378,
            "datetime": 1713457860000
        },
        {
            "open": 502.35,
            "high": 502.58,
            "low": 502.35,
            "close": 502.49,
            "volume": 81099,
            "datetime": 1713457920000
        },
        {
            "open": 502.5,
            "high": 502.56,
            "low": 502.335,
            "close": 502.335,
            "volume": 64816,
            "datetime": 1713457980000
        },
        {
            "open": 502.34,
            "high": 502.36,
            "low": 501.52,
            "close": 501.65,
            "volume": 266314,
            "datetime": 1713458040000
        },
        {
            "open": 501.64,
            "high": 502.06,
            "low": 501.63,
            "close": 501.99,
            "volume": 187284,
            "datetime": 1713458100000
        },
        {
            "open": 502,
            "high": 502.65,
            "low": 501.986,
            "close": 502.44,
            "volume": 191470,
            "datetime": 1713458160000
        },
        {
            "open": 502.45,
            "high": 502.56,
            "low": 502.315,
            "close": 502.39,
            "volume": 66668,
            "datetime": 1713458220000
        },
        {
            "open": 502.38,
            "high": 502.43,
            "low": 502.19,
            "close": 502.2,
            "volume": 48182,
            "datetime": 1713458280000
        },
        {
            "open": 502.19,
            "high": 502.36,
            "low": 502.17,
            "close": 502.3203,
            "volume": 27224,
            "datetime": 1713458340000
        },
        {
            "open": 502.35,
            "high": 502.4,
            "low": 502.075,
            "close": 502.11,
            "volume": 45389,
            "datetime": 1713458400000
        },
        {
            "open": 502.09,
            "high": 502.09,
            "low": 501.94,
            "close": 502.09,
            "volume": 75625,
            "datetime": 1713458460000
        },
        {
            "open": 502.07,
            "high": 502.07,
            "low": 501.65,
            "close": 501.7133,
            "volume": 127466,
            "datetime": 1713458520000
        },
        {
            "open": 501.72,
            "high": 502,
            "low": 501.72,
            "close": 501.92,
            "volume": 71314,
            "datetime": 1713458580000
        },
        {
            "open": 501.92,
            "high": 501.93,
            "low": 501.755,
            "close": 501.86,
            "volume": 30677,
            "datetime": 1713458640000
        },
        {
            "open": 501.87,
            "high": 501.92,
            "low": 501.675,
            "close": 501.74,
            "volume": 51384,
            "datetime": 1713458700000
        },
        {
            "open": 501.74,
            "high": 501.86,
            "low": 501.36,
            "close": 501.428,
            "volume": 106417,
            "datetime": 1713458760000
        },
        {
            "open": 501.45,
            "high": 501.58,
            "low": 501.15,
            "close": 501.22,
            "volume": 200069,
            "datetime": 1713458820000
        },
        {
            "open": 501.2175,
            "high": 501.28,
            "low": 500.97,
            "close": 501.27,
            "volume": 132856,
            "datetime": 1713458880000
        },
        {
            "open": 501.24,
            "high": 501.34,
            "low": 501.17,
            "close": 501.29,
            "volume": 63532,
            "datetime": 1713458940000
        },
        {
            "open": 501.295,
            "high": 501.41,
            "low": 501.065,
            "close": 501.14,
            "volume": 78429,
            "datetime": 1713459000000
        },
        {
            "open": 501.1499,
            "high": 501.16,
            "low": 500.91,
            "close": 500.9397,
            "volume": 90657,
            "datetime": 1713459060000
        },
        {
            "open": 500.94,
            "high": 501.065,
            "low": 500.66,
            "close": 501.04,
            "volume": 184094,
            "datetime": 1713459120000
        },
        {
            "open": 501.02,
            "high": 501.02,
            "low": 500.86,
            "close": 500.89,
            "volume": 86297,
            "datetime": 1713459180000
        },
        {
            "open": 500.88,
            "high": 500.93,
            "low": 500.72,
            "close": 500.86,
            "volume": 53182,
            "datetime": 1713459240000
        },
        {
            "open": 500.84,
            "high": 501.13,
            "low": 500.82,
            "close": 501.01,
            "volume": 108118,
            "datetime": 1713459300000
        },
        {
            "open": 501,
            "high": 501.11,
            "low": 500.94,
            "close": 501,
            "volume": 75734,
            "datetime": 1713459360000
        },
        {
            "open": 501.01,
            "high": 501.2,
            "low": 500.94,
            "close": 500.99,
            "volume": 119167,
            "datetime": 1713459420000
        },
        {
            "open": 500.99,
            "high": 501.0099,
            "low": 500.785,
            "close": 500.81,
            "volume": 85390,
            "datetime": 1713459480000
        },
        {
            "open": 500.81,
            "high": 500.83,
            "low": 500.62,
            "close": 500.79,
            "volume": 107994,
            "datetime": 1713459540000
        },
        {
            "open": 500.78,
            "high": 501,
            "low": 500.685,
            "close": 500.9793,
            "volume": 119810,
            "datetime": 1713459600000
        },
        {
            "open": 500.97,
            "high": 501.14,
            "low": 500.9,
            "close": 501.13,
            "volume": 104047,
            "datetime": 1713459660000
        },
        {
            "open": 501.12,
            "high": 501.125,
            "low": 500.9,
            "close": 500.94,
            "volume": 59837,
            "datetime": 1713459720000
        },
        {
            "open": 500.95,
            "high": 501.02,
            "low": 500.86,
            "close": 500.94,
            "volume": 77861,
            "datetime": 1713459780000
        },
        {
            "open": 500.9,
            "high": 500.9,
            "low": 500.74,
            "close": 500.76,
            "volume": 77543,
            "datetime": 1713459840000
        },
        {
            "open": 500.75,
            "high": 500.79,
            "low": 500.54,
            "close": 500.7799,
            "volume": 150810,
            "datetime": 1713459900000
        },
        {
            "open": 500.7799,
            "high": 500.8301,
            "low": 500.56,
            "close": 500.56,
            "volume": 107870,
            "datetime": 1713459960000
        },
        {
            "open": 500.55,
            "high": 500.72,
            "low": 500.41,
            "close": 500.59,
            "volume": 226522,
            "datetime": 1713460020000
        },
        {
            "open": 500.58,
            "high": 500.795,
            "low": 500.5201,
            "close": 500.57,
            "volume": 87027,
            "datetime": 1713460080000
        },
        {
            "open": 500.59,
            "high": 500.7934,
            "low": 500.55,
            "close": 500.7934,
            "volume": 47753,
            "datetime": 1713460140000
        },
        {
            "open": 500.8,
            "high": 500.805,
            "low": 500.34,
            "close": 500.3501,
            "volume": 120382,
            "datetime": 1713460200000
        },
        {
            "open": 500.35,
            "high": 500.435,
            "low": 500.02,
            "close": 500.24,
            "volume": 258254,
            "datetime": 1713460260000
        },
        {
            "open": 500.2271,
            "high": 500.37,
            "low": 500.095,
            "close": 500.17,
            "volume": 92555,
            "datetime": 1713460320000
        },
        {
            "open": 500.17,
            "high": 500.275,
            "low": 500.0924,
            "close": 500.1598,
            "volume": 58020,
            "datetime": 1713460380000
        },
        {
            "open": 500.16,
            "high": 500.3,
            "low": 500.1,
            "close": 500.29,
            "volume": 55055,
            "datetime": 1713460440000
        },
        {
            "open": 500.28,
            "high": 500.435,
            "low": 500.115,
            "close": 500.12,
            "volume": 110914,
            "datetime": 1713460500000
        },
        {
            "open": 500.11,
            "high": 500.2,
            "low": 500.02,
            "close": 500.06,
            "volume": 131185,
            "datetime": 1713460560000
        },
        {
            "open": 500.05,
            "high": 500.1096,
            "low": 499.44,
            "close": 499.66,
            "volume": 388864,
            "datetime": 1713460620000
        },
        {
            "open": 499.67,
            "high": 499.685,
            "low": 499.28,
            "close": 499.38,
            "volume": 173452,
            "datetime": 1713460680000
        },
        {
            "open": 499.4,
            "high": 499.71,
            "low": 499.25,
            "close": 499.67,
            "volume": 180599,
            "datetime": 1713460740000
        },
        {
            "open": 499.68,
            "high": 499.925,
            "low": 499.51,
            "close": 499.5299,
            "volume": 195479,
            "datetime": 1713460800000
        },
        {
            "open": 499.53,
            "high": 499.65,
            "low": 499.46,
            "close": 499.595,
            "volume": 138829,
            "datetime": 1713460860000
        },
        {
            "open": 499.595,
            "high": 499.66,
            "low": 499.48,
            "close": 499.53,
            "volume": 105224,
            "datetime": 1713460920000
        },
        {
            "open": 499.51,
            "high": 499.54,
            "low": 499.338,
            "close": 499.45,
            "volume": 140474,
            "datetime": 1713460980000
        },
        {
            "open": 499.44,
            "high": 499.44,
            "low": 498.9,
            "close": 498.92,
            "volume": 217716,
            "datetime": 1713461040000
        },
        {
            "open": 498.93,
            "high": 499.45,
            "low": 498.8701,
            "close": 499.29,
            "volume": 196756,
            "datetime": 1713461100000
        },
        {
            "open": 499.31,
            "high": 499.52,
            "low": 499.26,
            "close": 499.41,
            "volume": 100822,
            "datetime": 1713461160000
        },
        {
            "open": 499.4,
            "high": 499.49,
            "low": 499.33,
            "close": 499.415,
            "volume": 69645,
            "datetime": 1713461220000
        },
        {
            "open": 499.42,
            "high": 499.7,
            "low": 499.27,
            "close": 499.69,
            "volume": 172984,
            "datetime": 1713461280000
        },
        {
            "open": 499.7,
            "high": 500.34,
            "low": 499.7,
            "close": 500.2691,
            "volume": 324335,
            "datetime": 1713461340000
        },
        {
            "open": 500.27,
            "high": 500.32,
            "low": 500,
            "close": 500.15,
            "volume": 154510,
            "datetime": 1713461400000
        },
        {
            "open": 500.15,
            "high": 500.15,
            "low": 499.91,
            "close": 499.93,
            "volume": 103529,
            "datetime": 1713461460000
        },
        {
            "open": 499.94,
            "high": 499.97,
            "low": 499.63,
            "close": 499.76,
            "volume": 126745,
            "datetime": 1713461520000
        },
        {
            "open": 499.73,
            "high": 499.775,
            "low": 499.59,
            "close": 499.67,
            "volume": 70169,
            "datetime": 1713461580000
        },
        {
            "open": 499.6699,
            "high": 499.78,
            "low": 499.62,
            "close": 499.75,
            "volume": 75127,
            "datetime": 1713461640000
        },
        {
            "open": 499.74,
            "high": 499.99,
            "low": 499.68,
            "close": 499.75,
            "volume": 102042,
            "datetime": 1713461700000
        },
        {
            "open": 499.75,
            "high": 499.92,
            "low": 499.71,
            "close": 499.77,
            "volume": 84539,
            "datetime": 1713461760000
        },
        {
            "open": 499.78,
            "high": 499.78,
            "low": 499.4801,
            "close": 499.59,
            "volume": 102696,
            "datetime": 1713461820000
        },
        {
            "open": 499.58,
            "high": 499.59,
            "low": 499.37,
            "close": 499.42,
            "volume": 132762,
            "datetime": 1713461880000
        },
        {
            "open": 499.42,
            "high": 499.58,
            "low": 499.42,
            "close": 499.5399,
            "volume": 97729,
            "datetime": 1713461940000
        },
        {
            "open": 499.54,
            "high": 499.68,
            "low": 499.18,
            "close": 499.23,
            "volume": 121025,
            "datetime": 1713462000000
        },
        {
            "open": 499.215,
            "high": 499.27,
            "low": 498.94,
            "close": 499.14,
            "volume": 210309,
            "datetime": 1713462060000
        },
        {
            "open": 499.13,
            "high": 499.25,
            "low": 499,
            "close": 499.15,
            "volume": 133952,
            "datetime": 1713462120000
        },
        {
            "open": 499.14,
            "high": 499.6999,
            "low": 499.135,
            "close": 499.605,
            "volume": 149858,
            "datetime": 1713462180000
        },
        {
            "open": 499.61,
            "high": 499.8399,
            "low": 499.5807,
            "close": 499.69,
            "volume": 98305,
            "datetime": 1713462240000
        },
        {
            "open": 499.69,
            "high": 500.08,
            "low": 499.61,
            "close": 500.05,
            "volume": 144714,
            "datetime": 1713462300000
        },
        {
            "open": 500.05,
            "high": 500.1,
            "low": 499.79,
            "close": 499.9201,
            "volume": 138091,
            "datetime": 1713462360000
        },
        {
            "open": 499.93,
            "high": 499.99,
            "low": 499.78,
            "close": 499.9496,
            "volume": 75576,
            "datetime": 1713462420000
        },
        {
            "open": 499.97,
            "high": 500.0199,
            "low": 499.66,
            "close": 499.715,
            "volume": 82693,
            "datetime": 1713462480000
        },
        {
            "open": 499.705,
            "high": 499.87,
            "low": 499.61,
            "close": 499.835,
            "volume": 89696,
            "datetime": 1713462540000
        },
        {
            "open": 499.82,
            "high": 499.83,
            "low": 499.57,
            "close": 499.6299,
            "volume": 86565,
            "datetime": 1713462600000
        },
        {
            "open": 499.625,
            "high": 499.78,
            "low": 499.465,
            "close": 499.47,
            "volume": 122749,
            "datetime": 1713462660000
        },
        {
            "open": 499.47,
            "high": 499.47,
            "low": 499.05,
            "close": 499.13,
            "volume": 232682,
            "datetime": 1713462720000
        },
        {
            "open": 499.13,
            "high": 499.4,
            "low": 499.13,
            "close": 499.3,
            "volume": 171792,
            "datetime": 1713462780000
        },
        {
            "open": 499.28,
            "high": 499.39,
            "low": 499.15,
            "close": 499.3132,
            "volume": 76322,
            "datetime": 1713462840000
        },
        {
            "open": 499.32,
            "high": 499.495,
            "low": 499.21,
            "close": 499.21,
            "volume": 98429,
            "datetime": 1713462900000
        },
        {
            "open": 499.22,
            "high": 499.5,
            "low": 499.18,
            "close": 499.44,
            "volume": 85227,
            "datetime": 1713462960000
        },
        {
            "open": 499.44,
            "high": 499.49,
            "low": 499.25,
            "close": 499.3,
            "volume": 90438,
            "datetime": 1713463020000
        },
        {
            "open": 499.27,
            "high": 499.64,
            "low": 499.27,
            "close": 499.5619,
            "volume": 82684,
            "datetime": 1713463080000
        },
        {
            "open": 499.56,
            "high": 499.71,
            "low": 499.56,
            "close": 499.62,
            "volume": 108446,
            "datetime": 1713463140000
        },
        {
            "open": 499.61,
            "high": 499.61,
            "low": 499.41,
            "close": 499.59,
            "volume": 75395,
            "datetime": 1713463200000
        },
        {
            "open": 499.58,
            "high": 499.63,
            "low": 499.4,
            "close": 499.46,
            "volume": 131686,
            "datetime": 1713463260000
        },
        {
            "open": 499.46,
            "high": 499.56,
            "low": 499.3,
            "close": 499.42,
            "volume": 117507,
            "datetime": 1713463320000
        },
        {
            "open": 499.44,
            "high": 499.5498,
            "low": 499.18,
            "close": 499.4,
            "volume": 135104,
            "datetime": 1713463380000
        },
        {
            "open": 499.42,
            "high": 499.5,
            "low": 499.28,
            "close": 499.31,
            "volume": 85610,
            "datetime": 1713463440000
        },
        {
            "open": 499.31,
            "high": 499.385,
            "low": 498.99,
            "close": 499.07,
            "volume": 154781,
            "datetime": 1713463500000
        },
        {
            "open": 499.06,
            "high": 499.295,
            "low": 499.04,
            "close": 499.25,
            "volume": 93412,
            "datetime": 1713463560000
        },
        {
            "open": 499.2252,
            "high": 499.45,
            "low": 499.2231,
            "close": 499.32,
            "volume": 92043,
            "datetime": 1713463620000
        },
        {
            "open": 499.3,
            "high": 499.335,
            "low": 499.05,
            "close": 499.06,
            "volume": 128637,
            "datetime": 1713463680000
        },
        {
            "open": 499.05,
            "high": 499.19,
            "low": 498.86,
            "close": 499.06,
            "volume": 169115,
            "datetime": 1713463740000
        },
        {
            "open": 499.0699,
            "high": 499.18,
            "low": 498.89,
            "close": 498.915,
            "volume": 106398,
            "datetime": 1713463800000
        },
        {
            "open": 498.91,
            "high": 499.06,
            "low": 498.7,
            "close": 498.89,
            "volume": 195154,
            "datetime": 1713463860000
        },
        {
            "open": 498.87,
            "high": 499.01,
            "low": 498.61,
            "close": 498.985,
            "volume": 187619,
            "datetime": 1713463920000
        },
        {
            "open": 499,
            "high": 499.41,
            "low": 498.9,
            "close": 499.37,
            "volume": 174939,
            "datetime": 1713463980000
        },
        {
            "open": 499.37,
            "high": 499.46,
            "low": 499.16,
            "close": 499.175,
            "volume": 141399,
            "datetime": 1713464040000
        },
        {
            "open": 499.17,
            "high": 499.58,
            "low": 499.14,
            "close": 499.55,
            "volume": 133911,
            "datetime": 1713464100000
        },
        {
            "open": 499.54,
            "high": 499.88,
            "low": 499.52,
            "close": 499.7,
            "volume": 160072,
            "datetime": 1713464160000
        },
        {
            "open": 499.68,
            "high": 499.91,
            "low": 499.55,
            "close": 499.87,
            "volume": 118705,
            "datetime": 1713464220000
        },
        {
            "open": 499.8706,
            "high": 499.99,
            "low": 499.8,
            "close": 499.89,
            "volume": 104311,
            "datetime": 1713464280000
        },
        {
            "open": 499.885,
            "high": 500.01,
            "low": 499.74,
            "close": 499.81,
            "volume": 155289,
            "datetime": 1713464340000
        },
        {
            "open": 499.81,
            "high": 499.83,
            "low": 499.64,
            "close": 499.73,
            "volume": 130766,
            "datetime": 1713464400000
        },
        {
            "open": 499.73,
            "high": 500.12,
            "low": 499.72,
            "close": 499.85,
            "volume": 125242,
            "datetime": 1713464460000
        },
        {
            "open": 499.86,
            "high": 500.23,
            "low": 499.85,
            "close": 500,
            "volume": 162276,
            "datetime": 1713464520000
        },
        {
            "open": 500,
            "high": 500.44,
            "low": 500,
            "close": 500.26,
            "volume": 152019,
            "datetime": 1713464580000
        },
        {
            "open": 500.28,
            "high": 500.28,
            "low": 499.99,
            "close": 500.02,
            "volume": 108435,
            "datetime": 1713464640000
        },
        {
            "open": 500.02,
            "high": 500.26,
            "low": 499.87,
            "close": 500.245,
            "volume": 96696,
            "datetime": 1713464700000
        },
        {
            "open": 500.2497,
            "high": 500.39,
            "low": 500.17,
            "close": 500.37,
            "volume": 89838,
            "datetime": 1713464760000
        },
        {
            "open": 500.36,
            "high": 500.37,
            "low": 500.07,
            "close": 500.07,
            "volume": 89432,
            "datetime": 1713464820000
        },
        {
            "open": 500.08,
            "high": 500.08,
            "low": 499.36,
            "close": 499.455,
            "volume": 221857,
            "datetime": 1713464880000
        },
        {
            "open": 499.45,
            "high": 499.56,
            "low": 499.34,
            "close": 499.53,
            "volume": 153421,
            "datetime": 1713464940000
        },
        {
            "open": 499.55,
            "high": 499.595,
            "low": 499.36,
            "close": 499.4,
            "volume": 114471,
            "datetime": 1713465000000
        },
        {
            "open": 499.41,
            "high": 499.41,
            "low": 499.17,
            "close": 499.25,
            "volume": 125832,
            "datetime": 1713465060000
        },
        {
            "open": 499.26,
            "high": 499.27,
            "low": 498.97,
            "close": 499.01,
            "volume": 146132,
            "datetime": 1713465120000
        },
        {
            "open": 499.02,
            "high": 499.09,
            "low": 498.875,
            "close": 498.955,
            "volume": 83043,
            "datetime": 1713465180000
        },
        {
            "open": 498.95,
            "high": 498.97,
            "low": 498.56,
            "close": 498.66,
            "volume": 194021,
            "datetime": 1713465240000
        },
        {
            "open": 498.67,
            "high": 499.09,
            "low": 498.63,
            "close": 498.91,
            "volume": 192279,
            "datetime": 1713465300000
        },
        {
            "open": 498.9,
            "high": 499.03,
            "low": 498.845,
            "close": 498.89,
            "volume": 104363,
            "datetime": 1713465360000
        },
        {
            "open": 498.8889,
            "high": 499.34,
            "low": 498.87,
            "close": 499.19,
            "volume": 139805,
            "datetime": 1713465420000
        },
        {
            "open": 499.18,
            "high": 499.18,
            "low": 498.91,
            "close": 498.94,
            "volume": 120183,
            "datetime": 1713465480000
        },
        {
            "open": 498.94,
            "high": 499.1201,
            "low": 498.92,
            "close": 498.96,
            "volume": 63149,
            "datetime": 1713465540000
        },
        {
            "open": 498.97,
            "high": 499.1,
            "low": 498.9,
            "close": 499.03,
            "volume": 75096,
            "datetime": 1713465600000
        },
        {
            "open": 499.03,
            "high": 499.385,
            "low": 499.02,
            "close": 499.13,
            "volume": 114152,
            "datetime": 1713465660000
        },
        {
            "open": 499.14,
            "high": 499.49,
            "low": 499.035,
            "close": 499.477,
            "volume": 186290,
            "datetime": 1713465720000
        },
        {
            "open": 499.465,
            "high": 499.61,
            "low": 499.36,
            "close": 499.52,
            "volume": 117276,
            "datetime": 1713465780000
        },
        {
            "open": 499.54,
            "high": 499.64,
            "low": 499.44,
            "close": 499.5499,
            "volume": 74862,
            "datetime": 1713465840000
        },
        {
            "open": 499.54,
            "high": 499.54,
            "low": 499.26,
            "close": 499.47,
            "volume": 109407,
            "datetime": 1713465900000
        },
        {
            "open": 499.475,
            "high": 499.68,
            "low": 499.475,
            "close": 499.5899,
            "volume": 98543,
            "datetime": 1713465960000
        },
        {
            "open": 499.54,
            "high": 499.54,
            "low": 499.19,
            "close": 499.31,
            "volume": 134073,
            "datetime": 1713466020000
        },
        {
            "open": 499.36,
            "high": 499.6,
            "low": 499.33,
            "close": 499.52,
            "volume": 67737,
            "datetime": 1713466080000
        },
        {
            "open": 499.53,
            "high": 499.665,
            "low": 499.475,
            "close": 499.59,
            "volume": 59914,
            "datetime": 1713466140000
        },
        {
            "open": 499.5706,
            "high": 499.58,
            "low": 499.2501,
            "close": 499.33,
            "volume": 53048,
            "datetime": 1713466200000
        },
        {
            "open": 499.33,
            "high": 499.34,
            "low": 499,
            "close": 499.22,
            "volume": 185221,
            "datetime": 1713466260000
        },
        {
            "open": 499.2,
            "high": 499.44,
            "low": 499.19,
            "close": 499.23,
            "volume": 57888,
            "datetime": 1713466320000
        },
        {
            "open": 499.22,
            "high": 499.38,
            "low": 499.1,
            "close": 499.34,
            "volume": 64223,
            "datetime": 1713466380000
        },
        {
            "open": 499.33,
            "high": 499.41,
            "low": 499.12,
            "close": 499.19,
            "volume": 55929,
            "datetime": 1713466440000
        },
        {
            "open": 499.21,
            "high": 499.33,
            "low": 499.04,
            "close": 499.1,
            "volume": 86602,
            "datetime": 1713466500000
        },
        {
            "open": 499.1,
            "high": 499.32,
            "low": 499,
            "close": 499.31,
            "volume": 66551,
            "datetime": 1713466560000
        },
        {
            "open": 499.3,
            "high": 499.34,
            "low": 499.08,
            "close": 499.1,
            "volume": 52843,
            "datetime": 1713466620000
        },
        {
            "open": 499.1,
            "high": 499.17,
            "low": 499.02,
            "close": 499.11,
            "volume": 59209,
            "datetime": 1713466680000
        },
        {
            "open": 499.09,
            "high": 499.14,
            "low": 498.855,
            "close": 498.96,
            "volume": 101821,
            "datetime": 1713466740000
        },
        {
            "open": 498.96,
            "high": 499.25,
            "low": 498.96,
            "close": 499.08,
            "volume": 96043,
            "datetime": 1713466800000
        },
        {
            "open": 499.1,
            "high": 499.45,
            "low": 499.08,
            "close": 499.44,
            "volume": 110116,
            "datetime": 1713466860000
        },
        {
            "open": 499.44,
            "high": 499.99,
            "low": 499.43,
            "close": 499.83,
            "volume": 222379,
            "datetime": 1713466920000
        },
        {
            "open": 499.82,
            "high": 499.86,
            "low": 499.51,
            "close": 499.56,
            "volume": 88292,
            "datetime": 1713466980000
        },
        {
            "open": 499.58,
            "high": 499.8,
            "low": 499.58,
            "close": 499.68,
            "volume": 67240,
            "datetime": 1713467040000
        },
        {
            "open": 499.68,
            "high": 499.76,
            "low": 499.53,
            "close": 499.73,
            "volume": 87865,
            "datetime": 1713467100000
        },
        {
            "open": 499.76,
            "high": 499.965,
            "low": 499.61,
            "close": 499.74,
            "volume": 93633,
            "datetime": 1713467160000
        },
        {
            "open": 499.75,
            "high": 500.09,
            "low": 499.73,
            "close": 499.9,
            "volume": 176217,
            "datetime": 1713467220000
        },
        {
            "open": 499.93,
            "high": 500.21,
            "low": 499.8799,
            "close": 499.93,
            "volume": 103913,
            "datetime": 1713467280000
        },
        {
            "open": 499.95,
            "high": 499.98,
            "low": 499.63,
            "close": 499.76,
            "volume": 113080,
            "datetime": 1713467340000
        },
        {
            "open": 499.77,
            "high": 500.05,
            "low": 499.74,
            "close": 500.0335,
            "volume": 127368,
            "datetime": 1713467400000
        },
        {
            "open": 500.04,
            "high": 500.32,
            "low": 500.03,
            "close": 500.1,
            "volume": 232713,
            "datetime": 1713467460000
        },
        {
            "open": 500.11,
            "high": 500.1599,
            "low": 499.69,
            "close": 499.805,
            "volume": 156775,
            "datetime": 1713467520000
        },
        {
            "open": 499.8012,
            "high": 499.84,
            "low": 499.47,
            "close": 499.64,
            "volume": 130652,
            "datetime": 1713467580000
        },
        {
            "open": 499.6434,
            "high": 499.82,
            "low": 499.63,
            "close": 499.63,
            "volume": 88596,
            "datetime": 1713467640000
        },
        {
            "open": 499.65,
            "high": 499.8,
            "low": 499.6088,
            "close": 499.701,
            "volume": 75577,
            "datetime": 1713467700000
        },
        {
            "open": 499.69,
            "high": 499.77,
            "low": 499.45,
            "close": 499.49,
            "volume": 101548,
            "datetime": 1713467760000
        },
        {
            "open": 499.5,
            "high": 499.56,
            "low": 499.27,
            "close": 499.46,
            "volume": 223839,
            "datetime": 1713467820000
        },
        {
            "open": 499.45,
            "high": 499.74,
            "low": 499.4,
            "close": 499.66,
            "volume": 92286,
            "datetime": 1713467880000
        },
        {
            "open": 499.67,
            "high": 499.69,
            "low": 499.485,
            "close": 499.57,
            "volume": 136784,
            "datetime": 1713467940000
        },
        {
            "open": 499.57,
            "high": 499.76,
            "low": 499.49,
            "close": 499.54,
            "volume": 140380,
            "datetime": 1713468000000
        },
        {
            "open": 499.56,
            "high": 499.58,
            "low": 499.35,
            "close": 499.54,
            "volume": 113516,
            "datetime": 1713468060000
        },
        {
            "open": 499.56,
            "high": 499.64,
            "low": 499.31,
            "close": 499.4988,
            "volume": 98403,
            "datetime": 1713468120000
        },
        {
            "open": 499.47,
            "high": 499.49,
            "low": 499.29,
            "close": 499.46,
            "volume": 65800,
            "datetime": 1713468180000
        },
        {
            "open": 499.46,
            "high": 500.1,
            "low": 499.46,
            "close": 499.79,
            "volume": 148536,
            "datetime": 1713468240000
        },
        {
            "open": 499.77,
            "high": 499.84,
            "low": 499.32,
            "close": 499.52,
            "volume": 116553,
            "datetime": 1713468300000
        },
        {
            "open": 499.52,
            "high": 499.87,
            "low": 499.5,
            "close": 499.65,
            "volume": 159607,
            "datetime": 1713468360000
        },
        {
            "open": 499.65,
            "high": 499.97,
            "low": 499.65,
            "close": 499.955,
            "volume": 114453,
            "datetime": 1713468420000
        },
        {
            "open": 499.96,
            "high": 499.96,
            "low": 499.61,
            "close": 499.71,
            "volume": 94205,
            "datetime": 1713468480000
        },
        {
            "open": 499.73,
            "high": 499.8461,
            "low": 499.68,
            "close": 499.79,
            "volume": 87294,
            "datetime": 1713468540000
        },
        {
            "open": 499.8,
            "high": 499.95,
            "low": 499.8,
            "close": 499.86,
            "volume": 120297,
            "datetime": 1713468600000
        },
        {
            "open": 499.89,
            "high": 499.99,
            "low": 499.78,
            "close": 499.87,
            "volume": 123883,
            "datetime": 1713468660000
        },
        {
            "open": 499.86,
            "high": 500.19,
            "low": 499.86,
            "close": 500.045,
            "volume": 237709,
            "datetime": 1713468720000
        },
        {
            "open": 500.05,
            "high": 500.05,
            "low": 499.81,
            "close": 499.99,
            "volume": 113521,
            "datetime": 1713468780000
        },
        {
            "open": 499.99,
            "high": 500.2,
            "low": 499.985,
            "close": 500.18,
            "volume": 127525,
            "datetime": 1713468840000
        },
        {
            "open": 500.16,
            "high": 500.29,
            "low": 500.086,
            "close": 500.255,
            "volume": 138218,
            "datetime": 1713468900000
        },
        {
            "open": 500.255,
            "high": 500.35,
            "low": 500.1801,
            "close": 500.32,
            "volume": 200543,
            "datetime": 1713468960000
        },
        {
            "open": 500.315,
            "high": 500.335,
            "low": 499.91,
            "close": 499.94,
            "volume": 164964,
            "datetime": 1713469020000
        },
        {
            "open": 499.94,
            "high": 500.105,
            "low": 499.8,
            "close": 499.9,
            "volume": 157704,
            "datetime": 1713469080000
        },
        {
            "open": 499.89,
            "high": 499.89,
            "low": 499.72,
            "close": 499.74,
            "volume": 87749,
            "datetime": 1713469140000
        },
        {
            "open": 499.75,
            "high": 499.87,
            "low": 499.67,
            "close": 499.85,
            "volume": 126895,
            "datetime": 1713469200000
        },
        {
            "open": 499.85,
            "high": 499.99,
            "low": 499.85,
            "close": 499.91,
            "volume": 79298,
            "datetime": 1713469260000
        },
        {
            "open": 499.91,
            "high": 500.1286,
            "low": 499.85,
            "close": 500.07,
            "volume": 76834,
            "datetime": 1713469320000
        },
        {
            "open": 500.07,
            "high": 500.33,
            "low": 500.06,
            "close": 500.24,
            "volume": 159795,
            "datetime": 1713469380000
        },
        {
            "open": 500.245,
            "high": 500.325,
            "low": 500.0899,
            "close": 500.2395,
            "volume": 157276,
            "datetime": 1713469440000
        },
        {
            "open": 500.21,
            "high": 500.355,
            "low": 500.01,
            "close": 500.035,
            "volume": 175767,
            "datetime": 1713469500000
        },
        {
            "open": 500.04,
            "high": 500.2,
            "low": 500,
            "close": 500.09,
            "volume": 94051,
            "datetime": 1713469560000
        },
        {
            "open": 500.1155,
            "high": 500.17,
            "low": 500.02,
            "close": 500.12,
            "volume": 91087,
            "datetime": 1713469620000
        },
        {
            "open": 500.145,
            "high": 500.3,
            "low": 500.1,
            "close": 500.23,
            "volume": 128464,
            "datetime": 1713469680000
        },
        {
            "open": 500.24,
            "high": 500.34,
            "low": 500.18,
            "close": 500.27,
            "volume": 182797,
            "datetime": 1713469740000
        },
        {
            "open": 500.26,
            "high": 500.63,
            "low": 500.1799,
            "close": 500.52,
            "volume": 399975,
            "datetime": 1713469800000
        },
        {
            "open": 500.5,
            "high": 500.83,
            "low": 500.35,
            "close": 500.425,
            "volume": 345638,
            "datetime": 1713469860000
        },
        {
            "open": 500.42,
            "high": 500.46,
            "low": 500.08,
            "close": 500.1686,
            "volume": 267597,
            "datetime": 1713469920000
        },
        {
            "open": 500.15,
            "high": 500.185,
            "low": 499.76,
            "close": 499.82,
            "volume": 276229,
            "datetime": 1713469980000
        },
        {
            "open": 499.83,
            "high": 499.86,
            "low": 499.33,
            "close": 499.46,
            "volume": 399328,
            "datetime": 1713470040000
        },
        {
            "open": 499.445,
            "high": 499.5,
            "low": 499.21,
            "close": 499.35,
            "volume": 367220,
            "datetime": 1713470100000
        },
        {
            "open": 499.36,
            "high": 499.63,
            "low": 499.29,
            "close": 499.62,
            "volume": 453062,
            "datetime": 1713470160000
        },
        {
            "open": 499.63,
            "high": 499.64,
            "low": 499.39,
            "close": 499.61,
            "volume": 403470,
            "datetime": 1713470220000
        },
        {
            "open": 499.61,
            "high": 499.6201,
            "low": 499.36,
            "close": 499.375,
            "volume": 600962,
            "datetime": 1713470280000
        },
        {
            "open": 499.37,
            "high": 499.74,
            "low": 499.34,
            "close": 499.48,
            "volume": 1632675,
            "datetime": 1713470340000
        },
        {
            "open": 499.44,
            "high": 499.44,
            "low": 498.925,
            "close": 498.935,
            "volume": 550481,
            "datetime": 1713533400000
        },
        {
            "open": 498.94,
            "high": 499.28,
            "low": 498.6899,
            "close": 499.2699,
            "volume": 225261,
            "datetime": 1713533460000
        },
        {
            "open": 499.265,
            "high": 499.54,
            "low": 499.02,
            "close": 499.51,
            "volume": 334946,
            "datetime": 1713533520000
        },
        {
            "open": 499.52,
            "high": 499.73,
            "low": 499.24,
            "close": 499.69,
            "volume": 187382,
            "datetime": 1713533580000
        },
        {
            "open": 499.68,
            "high": 499.73,
            "low": 499.28,
            "close": 499.33,
            "volume": 155520,
            "datetime": 1713533640000
        },
        {
            "open": 499.33,
            "high": 499.465,
            "low": 499.2101,
            "close": 499.2102,
            "volume": 138181,
            "datetime": 1713533700000
        },
        {
            "open": 499.24,
            "high": 499.34,
            "low": 498.7501,
            "close": 498.83,
            "volume": 201228,
            "datetime": 1713533760000
        },
        {
            "open": 498.84,
            "high": 499.17,
            "low": 498.83,
            "close": 499.11,
            "volume": 213056,
            "datetime": 1713533820000
        },
        {
            "open": 499.0999,
            "high": 499.27,
            "low": 498.91,
            "close": 499.01,
            "volume": 166991,
            "datetime": 1713533880000
        },
        {
            "open": 498.9602,
            "high": 499.2,
            "low": 498.84,
            "close": 499.16,
            "volume": 152149,
            "datetime": 1713533940000
        },
        {
            "open": 499.155,
            "high": 499.44,
            "low": 498.94,
            "close": 498.94,
            "volume": 231208,
            "datetime": 1713534000000
        },
        {
            "open": 498.93,
            "high": 499.68,
            "low": 498.91,
            "close": 499.66,
            "volume": 222045,
            "datetime": 1713534060000
        },
        {
            "open": 499.66,
            "high": 499.8,
            "low": 499.3323,
            "close": 499.78,
            "volume": 134423,
            "datetime": 1713534120000
        },
        {
            "open": 499.75,
            "high": 499.82,
            "low": 499.57,
            "close": 499.82,
            "volume": 123343,
            "datetime": 1713534180000
        },
        {
            "open": 499.82,
            "high": 500.12,
            "low": 499.775,
            "close": 500.01,
            "volume": 326421,
            "datetime": 1713534240000
        },
        {
            "open": 500.019,
            "high": 500.05,
            "low": 499.83,
            "close": 500.02,
            "volume": 156526,
            "datetime": 1713534300000
        },
        {
            "open": 500.02,
            "high": 500.455,
            "low": 500,
            "close": 500.3201,
            "volume": 577448,
            "datetime": 1713534360000
        },
        {
            "open": 500.33,
            "high": 500.34,
            "low": 499.97,
            "close": 500.01,
            "volume": 102971,
            "datetime": 1713534420000
        },
        {
            "open": 500.01,
            "high": 500.01,
            "low": 499.52,
            "close": 499.75,
            "volume": 182638,
            "datetime": 1713534480000
        },
        {
            "open": 499.75,
            "high": 499.82,
            "low": 499.54,
            "close": 499.55,
            "volume": 109720,
            "datetime": 1713534540000
        },
        {
            "open": 499.52,
            "high": 499.6,
            "low": 499.0713,
            "close": 499.1,
            "volume": 172669,
            "datetime": 1713534600000
        },
        {
            "open": 499.11,
            "high": 499.295,
            "low": 498.99,
            "close": 499.12,
            "volume": 112708,
            "datetime": 1713534660000
        },
        {
            "open": 499.08,
            "high": 499.35,
            "low": 498.78,
            "close": 499.23,
            "volume": 175004,
            "datetime": 1713534720000
        },
        {
            "open": 499.27,
            "high": 499.54,
            "low": 499.27,
            "close": 499.39,
            "volume": 213206,
            "datetime": 1713534780000
        },
        {
            "open": 499.35,
            "high": 499.95,
            "low": 499.35,
            "close": 499.84,
            "volume": 132853,
            "datetime": 1713534840000
        },
        {
            "open": 499.81,
            "high": 499.9596,
            "low": 499.71,
            "close": 499.8,
            "volume": 91772,
            "datetime": 1713534900000
        },
        {
            "open": 499.78,
            "high": 499.82,
            "low": 499.59,
            "close": 499.73,
            "volume": 102899,
            "datetime": 1713534960000
        },
        {
            "open": 499.73,
            "high": 500.129,
            "low": 499.62,
            "close": 499.96,
            "volume": 64518,
            "datetime": 1713535020000
        },
        {
            "open": 499.97,
            "high": 500.01,
            "low": 499.86,
            "close": 499.99,
            "volume": 55412,
            "datetime": 1713535080000
        },
        {
            "open": 499.98,
            "high": 500,
            "low": 499.4888,
            "close": 499.64,
            "volume": 126738,
            "datetime": 1713535140000
        },
        {
            "open": 499.62,
            "high": 499.91,
            "low": 499.43,
            "close": 499.83,
            "volume": 135955,
            "datetime": 1713535200000
        },
        {
            "open": 499.87,
            "high": 500.14,
            "low": 499.805,
            "close": 499.8397,
            "volume": 122830,
            "datetime": 1713535260000
        },
        {
            "open": 499.82,
            "high": 499.83,
            "low": 499.59,
            "close": 499.62,
            "volume": 97713,
            "datetime": 1713535320000
        },
        {
            "open": 499.66,
            "high": 499.7,
            "low": 499.4407,
            "close": 499.58,
            "volume": 75809,
            "datetime": 1713535380000
        },
        {
            "open": 499.58,
            "high": 499.665,
            "low": 499.25,
            "close": 499.56,
            "volume": 107007,
            "datetime": 1713535440000
        },
        {
            "open": 499.54,
            "high": 499.67,
            "low": 499.44,
            "close": 499.58,
            "volume": 61860,
            "datetime": 1713535500000
        },
        {
            "open": 499.6,
            "high": 499.89,
            "low": 499.54,
            "close": 499.77,
            "volume": 74063,
            "datetime": 1713535560000
        },
        {
            "open": 499.76,
            "high": 499.88,
            "low": 499.57,
            "close": 499.7559,
            "volume": 167468,
            "datetime": 1713535620000
        },
        {
            "open": 499.76,
            "high": 499.9,
            "low": 499.47,
            "close": 499.4999,
            "volume": 92485,
            "datetime": 1713535680000
        },
        {
            "open": 499.52,
            "high": 499.75,
            "low": 499.39,
            "close": 499.5,
            "volume": 108290,
            "datetime": 1713535740000
        },
        {
            "open": 499.505,
            "high": 499.52,
            "low": 499.06,
            "close": 499.295,
            "volume": 197508,
            "datetime": 1713535800000
        },
        {
            "open": 499.2724,
            "high": 499.41,
            "low": 498.89,
            "close": 498.95,
            "volume": 185634,
            "datetime": 1713535860000
        },
        {
            "open": 498.96,
            "high": 499.35,
            "low": 498.91,
            "close": 499.22,
            "volume": 150400,
            "datetime": 1713535920000
        },
        {
            "open": 499.21,
            "high": 499.27,
            "low": 498.81,
            "close": 498.93,
            "volume": 113063,
            "datetime": 1713535980000
        },
        {
            "open": 498.92,
            "high": 498.97,
            "low": 498.71,
            "close": 498.85,
            "volume": 154530,
            "datetime": 1713536040000
        },
        {
            "open": 498.86,
            "high": 498.9599,
            "low": 498.48,
            "close": 498.529,
            "volume": 218618,
            "datetime": 1713536100000
        },
        {
            "open": 498.53,
            "high": 499.04,
            "low": 498.525,
            "close": 498.87,
            "volume": 187258,
            "datetime": 1713536160000
        },
        {
            "open": 498.87,
            "high": 498.99,
            "low": 498.57,
            "close": 498.615,
            "volume": 173307,
            "datetime": 1713536220000
        },
        {
            "open": 498.63,
            "high": 498.73,
            "low": 498.28,
            "close": 498.55,
            "volume": 245681,
            "datetime": 1713536280000
        },
        {
            "open": 498.55,
            "high": 498.63,
            "low": 498.24,
            "close": 498.3,
            "volume": 161494,
            "datetime": 1713536340000
        },
        {
            "open": 498.27,
            "high": 498.36,
            "low": 498.1,
            "close": 498.3387,
            "volume": 219934,
            "datetime": 1713536400000
        },
        {
            "open": 498.36,
            "high": 498.6744,
            "low": 498.33,
            "close": 498.53,
            "volume": 209077,
            "datetime": 1713536460000
        },
        {
            "open": 498.53,
            "high": 498.79,
            "low": 498.33,
            "close": 498.36,
            "volume": 145517,
            "datetime": 1713536520000
        },
        {
            "open": 498.36,
            "high": 498.55,
            "low": 498.2601,
            "close": 498.39,
            "volume": 176334,
            "datetime": 1713536580000
        },
        {
            "open": 498.41,
            "high": 498.43,
            "low": 497.86,
            "close": 497.9,
            "volume": 259850,
            "datetime": 1713536640000
        },
        {
            "open": 497.91,
            "high": 498.13,
            "low": 497.77,
            "close": 498.01,
            "volume": 206918,
            "datetime": 1713536700000
        },
        {
            "open": 498,
            "high": 498,
            "low": 497.605,
            "close": 497.72,
            "volume": 212106,
            "datetime": 1713536760000
        },
        {
            "open": 497.71,
            "high": 498,
            "low": 497.71,
            "close": 497.9,
            "volume": 141638,
            "datetime": 1713536820000
        },
        {
            "open": 497.9,
            "high": 498.005,
            "low": 497.63,
            "close": 497.74,
            "volume": 330418,
            "datetime": 1713536880000
        },
        {
            "open": 497.74,
            "high": 498.49,
            "low": 497.72,
            "close": 498.46,
            "volume": 344147,
            "datetime": 1713536940000
        },
        {
            "open": 498.45,
            "high": 498.821,
            "low": 498.3187,
            "close": 498.5699,
            "volume": 220302,
            "datetime": 1713537000000
        },
        {
            "open": 498.57,
            "high": 499.4,
            "low": 498.55,
            "close": 498.6501,
            "volume": 326239,
            "datetime": 1713537060000
        },
        {
            "open": 498.65,
            "high": 499,
            "low": 498.4613,
            "close": 498.605,
            "volume": 220380,
            "datetime": 1713537120000
        },
        {
            "open": 498.62,
            "high": 498.685,
            "low": 498.39,
            "close": 498.47,
            "volume": 147556,
            "datetime": 1713537180000
        },
        {
            "open": 498.49,
            "high": 498.53,
            "low": 498.17,
            "close": 498.425,
            "volume": 165924,
            "datetime": 1713537240000
        },
        {
            "open": 498.42,
            "high": 498.44,
            "low": 497.9,
            "close": 498.04,
            "volume": 151251,
            "datetime": 1713537300000
        },
        {
            "open": 498.03,
            "high": 498.19,
            "low": 497.93,
            "close": 498.14,
            "volume": 110944,
            "datetime": 1713537360000
        },
        {
            "open": 498.14,
            "high": 498.16,
            "low": 497.72,
            "close": 497.76,
            "volume": 109057,
            "datetime": 1713537420000
        },
        {
            "open": 497.76,
            "high": 498,
            "low": 497.58,
            "close": 497.65,
            "volume": 165784,
            "datetime": 1713537480000
        },
        {
            "open": 497.64,
            "high": 497.8,
            "low": 497.6,
            "close": 497.73,
            "volume": 118833,
            "datetime": 1713537540000
        },
        {
            "open": 497.73,
            "high": 498.03,
            "low": 497.69,
            "close": 497.84,
            "volume": 204317,
            "datetime": 1713537600000
        },
        {
            "open": 497.84,
            "high": 497.92,
            "low": 497.6612,
            "close": 497.79,
            "volume": 159524,
            "datetime": 1713537660000
        },
        {
            "open": 497.79,
            "high": 498.241,
            "low": 497.79,
            "close": 498.09,
            "volume": 176951,
            "datetime": 1713537720000
        },
        {
            "open": 498.09,
            "high": 498.49,
            "low": 498.09,
            "close": 498.33,
            "volume": 151735,
            "datetime": 1713537780000
        },
        {
            "open": 498.34,
            "high": 498.71,
            "low": 498.3061,
            "close": 498.66,
            "volume": 163587,
            "datetime": 1713537840000
        },
        {
            "open": 498.66,
            "high": 498.9399,
            "low": 498.595,
            "close": 498.88,
            "volume": 186000,
            "datetime": 1713537900000
        },
        {
            "open": 498.88,
            "high": 499.2,
            "low": 498.69,
            "close": 498.7101,
            "volume": 177947,
            "datetime": 1713537960000
        },
        {
            "open": 498.72,
            "high": 498.86,
            "low": 498.51,
            "close": 498.78,
            "volume": 128726,
            "datetime": 1713538020000
        },
        {
            "open": 498.77,
            "high": 498.885,
            "low": 498.62,
            "close": 498.86,
            "volume": 147988,
            "datetime": 1713538080000
        },
        {
            "open": 498.86,
            "high": 498.98,
            "low": 498.66,
            "close": 498.8,
            "volume": 98675,
            "datetime": 1713538140000
        },
        {
            "open": 498.7949,
            "high": 499.05,
            "low": 498.66,
            "close": 498.74,
            "volume": 121886,
            "datetime": 1713538200000
        },
        {
            "open": 498.74,
            "high": 498.9,
            "low": 498.55,
            "close": 498.82,
            "volume": 106349,
            "datetime": 1713538260000
        },
        {
            "open": 498.81,
            "high": 499.01,
            "low": 498.76,
            "close": 498.9,
            "volume": 122164,
            "datetime": 1713538320000
        },
        {
            "open": 498.89,
            "high": 499.13,
            "low": 498.85,
            "close": 499.085,
            "volume": 103222,
            "datetime": 1713538380000
        },
        {
            "open": 499.085,
            "high": 499.27,
            "low": 499,
            "close": 499.03,
            "volume": 189008,
            "datetime": 1713538440000
        },
        {
            "open": 499.02,
            "high": 499.11,
            "low": 498.8299,
            "close": 498.9,
            "volume": 118617,
            "datetime": 1713538500000
        },
        {
            "open": 498.91,
            "high": 499.05,
            "low": 498.69,
            "close": 498.69,
            "volume": 102007,
            "datetime": 1713538560000
        },
        {
            "open": 498.68,
            "high": 498.68,
            "low": 498.355,
            "close": 498.4697,
            "volume": 220053,
            "datetime": 1713538620000
        },
        {
            "open": 498.46,
            "high": 498.675,
            "low": 498.44,
            "close": 498.55,
            "volume": 77168,
            "datetime": 1713538680000
        },
        {
            "open": 498.54,
            "high": 498.77,
            "low": 498.46,
            "close": 498.54,
            "volume": 76247,
            "datetime": 1713538740000
        },
        {
            "open": 498.53,
            "high": 498.535,
            "low": 498.111,
            "close": 498.25,
            "volume": 119618,
            "datetime": 1713538800000
        },
        {
            "open": 498.23,
            "high": 498.41,
            "low": 498.18,
            "close": 498.29,
            "volume": 97146,
            "datetime": 1713538860000
        },
        {
            "open": 498.28,
            "high": 498.43,
            "low": 498.23,
            "close": 498.32,
            "volume": 83775,
            "datetime": 1713538920000
        },
        {
            "open": 498.31,
            "high": 498.31,
            "low": 497.88,
            "close": 498,
            "volume": 171110,
            "datetime": 1713538980000
        },
        {
            "open": 498.009,
            "high": 498.15,
            "low": 497.89,
            "close": 498.045,
            "volume": 136228,
            "datetime": 1713539040000
        },
        {
            "open": 498.05,
            "high": 498.26,
            "low": 497.96,
            "close": 498.24,
            "volume": 68204,
            "datetime": 1713539100000
        },
        {
            "open": 498.235,
            "high": 498.379,
            "low": 497.96,
            "close": 498.35,
            "volume": 83348,
            "datetime": 1713539160000
        },
        {
            "open": 498.35,
            "high": 498.58,
            "low": 498.32,
            "close": 498.52,
            "volume": 120015,
            "datetime": 1713539220000
        },
        {
            "open": 498.52,
            "high": 498.71,
            "low": 498.46,
            "close": 498.57,
            "volume": 114596,
            "datetime": 1713539280000
        },
        {
            "open": 498.55,
            "high": 498.84,
            "low": 498.53,
            "close": 498.81,
            "volume": 125183,
            "datetime": 1713539340000
        },
        {
            "open": 498.82,
            "high": 498.905,
            "low": 498.57,
            "close": 498.59,
            "volume": 101393,
            "datetime": 1713539400000
        },
        {
            "open": 498.575,
            "high": 498.64,
            "low": 498.34,
            "close": 498.46,
            "volume": 93615,
            "datetime": 1713539460000
        },
        {
            "open": 498.455,
            "high": 498.88,
            "low": 498.38,
            "close": 498.84,
            "volume": 130989,
            "datetime": 1713539520000
        },
        {
            "open": 498.82,
            "high": 498.9592,
            "low": 498.62,
            "close": 498.66,
            "volume": 100833,
            "datetime": 1713539580000
        },
        {
            "open": 498.64,
            "high": 498.64,
            "low": 498.33,
            "close": 498.44,
            "volume": 194040,
            "datetime": 1713539640000
        },
        {
            "open": 498.505,
            "high": 498.61,
            "low": 498.26,
            "close": 498.265,
            "volume": 183141,
            "datetime": 1713539700000
        },
        {
            "open": 498.27,
            "high": 498.34,
            "low": 498.05,
            "close": 498.17,
            "volume": 112443,
            "datetime": 1713539760000
        },
        {
            "open": 498.16,
            "high": 498.2,
            "low": 498.04,
            "close": 498.075,
            "volume": 78880,
            "datetime": 1713539820000
        },
        {
            "open": 498.08,
            "high": 498.49,
            "low": 498.08,
            "close": 498.08,
            "volume": 181920,
            "datetime": 1713539880000
        },
        {
            "open": 498.0899,
            "high": 498.09,
            "low": 497.87,
            "close": 497.92,
            "volume": 186448,
            "datetime": 1713539940000
        },
        {
            "open": 497.95,
            "high": 498.08,
            "low": 497.77,
            "close": 497.8,
            "volume": 99136,
            "datetime": 1713540000000
        },
        {
            "open": 497.8,
            "high": 498.03,
            "low": 497.7825,
            "close": 497.86,
            "volume": 124496,
            "datetime": 1713540060000
        },
        {
            "open": 497.83,
            "high": 497.92,
            "low": 497.63,
            "close": 497.8,
            "volume": 109105,
            "datetime": 1713540120000
        },
        {
            "open": 497.81,
            "high": 497.905,
            "low": 497.76,
            "close": 497.7801,
            "volume": 77724,
            "datetime": 1713540180000
        },
        {
            "open": 497.7895,
            "high": 497.94,
            "low": 497.67,
            "close": 497.67,
            "volume": 62440,
            "datetime": 1713540240000
        },
        {
            "open": 497.68,
            "high": 497.83,
            "low": 497.635,
            "close": 497.8099,
            "volume": 109016,
            "datetime": 1713540300000
        },
        {
            "open": 497.8,
            "high": 497.89,
            "low": 497.685,
            "close": 497.715,
            "volume": 104456,
            "datetime": 1713540360000
        },
        {
            "open": 497.71,
            "high": 497.77,
            "low": 497.63,
            "close": 497.65,
            "volume": 78700,
            "datetime": 1713540420000
        },
        {
            "open": 497.67,
            "high": 497.86,
            "low": 497.63,
            "close": 497.64,
            "volume": 112597,
            "datetime": 1713540480000
        },
        {
            "open": 497.63,
            "high": 497.95,
            "low": 497.53,
            "close": 497.87,
            "volume": 183455,
            "datetime": 1713540540000
        },
        {
            "open": 497.86,
            "high": 498.09,
            "low": 497.8,
            "close": 497.9899,
            "volume": 140990,
            "datetime": 1713540600000
        },
        {
            "open": 497.98,
            "high": 497.98,
            "low": 497.6199,
            "close": 497.669,
            "volume": 83471,
            "datetime": 1713540660000
        },
        {
            "open": 497.66,
            "high": 497.83,
            "low": 497.6,
            "close": 497.605,
            "volume": 106588,
            "datetime": 1713540720000
        },
        {
            "open": 497.59,
            "high": 497.61,
            "low": 497.51,
            "close": 497.6,
            "volume": 83007,
            "datetime": 1713540780000
        },
        {
            "open": 497.605,
            "high": 497.74,
            "low": 497.55,
            "close": 497.57,
            "volume": 113196,
            "datetime": 1713540840000
        },
        {
            "open": 497.58,
            "high": 497.8,
            "low": 497.56,
            "close": 497.7796,
            "volume": 106743,
            "datetime": 1713540900000
        },
        {
            "open": 497.7788,
            "high": 497.84,
            "low": 497.25,
            "close": 497.4801,
            "volume": 246227,
            "datetime": 1713540960000
        },
        {
            "open": 497.49,
            "high": 497.97,
            "low": 497.48,
            "close": 497.87,
            "volume": 209762,
            "datetime": 1713541020000
        },
        {
            "open": 497.87,
            "high": 497.92,
            "low": 497.7,
            "close": 497.795,
            "volume": 164195,
            "datetime": 1713541080000
        },
        {
            "open": 497.775,
            "high": 498,
            "low": 497.76,
            "close": 497.98,
            "volume": 139188,
            "datetime": 1713541140000
        },
        {
            "open": 497.98,
            "high": 498.23,
            "low": 497.98,
            "close": 498.0298,
            "volume": 117913,
            "datetime": 1713541200000
        },
        {
            "open": 498.02,
            "high": 498.165,
            "low": 497.84,
            "close": 497.91,
            "volume": 101184,
            "datetime": 1713541260000
        },
        {
            "open": 497.9,
            "high": 498.04,
            "low": 497.605,
            "close": 497.67,
            "volume": 85454,
            "datetime": 1713541320000
        },
        {
            "open": 497.65,
            "high": 497.8,
            "low": 497.6,
            "close": 497.79,
            "volume": 74713,
            "datetime": 1713541380000
        },
        {
            "open": 497.79,
            "high": 497.96,
            "low": 497.71,
            "close": 497.94,
            "volume": 82818,
            "datetime": 1713541440000
        },
        {
            "open": 497.93,
            "high": 498.04,
            "low": 497.8751,
            "close": 498.02,
            "volume": 65990,
            "datetime": 1713541500000
        },
        {
            "open": 498,
            "high": 498.13,
            "low": 497.94,
            "close": 498.06,
            "volume": 69343,
            "datetime": 1713541560000
        },
        {
            "open": 498.06,
            "high": 498.25,
            "low": 498.052,
            "close": 498.22,
            "volume": 110877,
            "datetime": 1713541620000
        },
        {
            "open": 498.215,
            "high": 498.33,
            "low": 498.02,
            "close": 498.15,
            "volume": 125408,
            "datetime": 1713541680000
        },
        {
            "open": 498.16,
            "high": 498.19,
            "low": 497.9101,
            "close": 497.98,
            "volume": 51357,
            "datetime": 1713541740000
        },
        {
            "open": 497.99,
            "high": 498.2,
            "low": 497.98,
            "close": 498.0798,
            "volume": 91412,
            "datetime": 1713541800000
        },
        {
            "open": 498.07,
            "high": 498.13,
            "low": 497.8,
            "close": 497.8,
            "volume": 118803,
            "datetime": 1713541860000
        },
        {
            "open": 497.8,
            "high": 497.97,
            "low": 497.715,
            "close": 497.715,
            "volume": 88382,
            "datetime": 1713541920000
        },
        {
            "open": 497.71,
            "high": 497.815,
            "low": 497.65,
            "close": 497.67,
            "volume": 81919,
            "datetime": 1713541980000
        },
        {
            "open": 497.67,
            "high": 497.75,
            "low": 497.55,
            "close": 497.5891,
            "volume": 147588,
            "datetime": 1713542040000
        },
        {
            "open": 497.59,
            "high": 497.71,
            "low": 497.36,
            "close": 497.37,
            "volume": 101688,
            "datetime": 1713542100000
        },
        {
            "open": 497.37,
            "high": 497.49,
            "low": 497.21,
            "close": 497.37,
            "volume": 111129,
            "datetime": 1713542160000
        },
        {
            "open": 497.3781,
            "high": 497.38,
            "low": 496.98,
            "close": 497.21,
            "volume": 164982,
            "datetime": 1713542220000
        },
        {
            "open": 497.22,
            "high": 497.42,
            "low": 497.2199,
            "close": 497.24,
            "volume": 118459,
            "datetime": 1713542280000
        },
        {
            "open": 497.24,
            "high": 497.25,
            "low": 497.08,
            "close": 497.11,
            "volume": 52274,
            "datetime": 1713542340000
        },
        {
            "open": 497.09,
            "high": 497.57,
            "low": 497.06,
            "close": 497.53,
            "volume": 142563,
            "datetime": 1713542400000
        },
        {
            "open": 497.54,
            "high": 497.77,
            "low": 497.53,
            "close": 497.555,
            "volume": 109316,
            "datetime": 1713542460000
        },
        {
            "open": 497.54,
            "high": 497.59,
            "low": 497.21,
            "close": 497.3201,
            "volume": 93111,
            "datetime": 1713542520000
        },
        {
            "open": 497.325,
            "high": 497.33,
            "low": 497.105,
            "close": 497.14,
            "volume": 64477,
            "datetime": 1713542580000
        },
        {
            "open": 497.13,
            "high": 497.21,
            "low": 496.97,
            "close": 497.07,
            "volume": 78978,
            "datetime": 1713542640000
        },
        {
            "open": 497.08,
            "high": 497.14,
            "low": 496.94,
            "close": 497.08,
            "volume": 116833,
            "datetime": 1713542700000
        },
        {
            "open": 497.06,
            "high": 497.155,
            "low": 496.86,
            "close": 496.901,
            "volume": 79311,
            "datetime": 1713542760000
        },
        {
            "open": 496.9,
            "high": 497,
            "low": 496.6,
            "close": 496.62,
            "volume": 162384,
            "datetime": 1713542820000
        },
        {
            "open": 496.61,
            "high": 496.97,
            "low": 496.52,
            "close": 496.965,
            "volume": 174270,
            "datetime": 1713542880000
        },
        {
            "open": 496.935,
            "high": 497.06,
            "low": 496.855,
            "close": 496.8699,
            "volume": 90122,
            "datetime": 1713542940000
        },
        {
            "open": 496.86,
            "high": 496.91,
            "low": 496.555,
            "close": 496.58,
            "volume": 68971,
            "datetime": 1713543000000
        },
        {
            "open": 496.56,
            "high": 496.63,
            "low": 496.23,
            "close": 496.3,
            "volume": 162123,
            "datetime": 1713543060000
        },
        {
            "open": 496.28,
            "high": 496.41,
            "low": 496.18,
            "close": 496.35,
            "volume": 144734,
            "datetime": 1713543120000
        },
        {
            "open": 496.33,
            "high": 496.33,
            "low": 496.03,
            "close": 496.08,
            "volume": 119581,
            "datetime": 1713543180000
        },
        {
            "open": 496.09,
            "high": 496.19,
            "low": 495.72,
            "close": 495.81,
            "volume": 215236,
            "datetime": 1713543240000
        },
        {
            "open": 495.8,
            "high": 495.975,
            "low": 495.58,
            "close": 495.73,
            "volume": 358540,
            "datetime": 1713543300000
        },
        {
            "open": 495.73,
            "high": 495.98,
            "low": 495.7,
            "close": 495.975,
            "volume": 184377,
            "datetime": 1713543360000
        },
        {
            "open": 495.98,
            "high": 496.04,
            "low": 495.805,
            "close": 495.8197,
            "volume": 231604,
            "datetime": 1713543420000
        },
        {
            "open": 495.82,
            "high": 495.84,
            "low": 495.56,
            "close": 495.68,
            "volume": 157930,
            "datetime": 1713543480000
        },
        {
            "open": 495.66,
            "high": 495.82,
            "low": 495.52,
            "close": 495.54,
            "volume": 143260,
            "datetime": 1713543540000
        },
        {
            "open": 495.54,
            "high": 495.84,
            "low": 495.54,
            "close": 495.74,
            "volume": 159575,
            "datetime": 1713543600000
        },
        {
            "open": 495.71,
            "high": 495.87,
            "low": 495.68,
            "close": 495.72,
            "volume": 146164,
            "datetime": 1713543660000
        },
        {
            "open": 495.71,
            "high": 495.93,
            "low": 495.665,
            "close": 495.8899,
            "volume": 114137,
            "datetime": 1713543720000
        },
        {
            "open": 495.85,
            "high": 495.86,
            "low": 495.57,
            "close": 495.5801,
            "volume": 95291,
            "datetime": 1713543780000
        },
        {
            "open": 495.5899,
            "high": 495.66,
            "low": 495.44,
            "close": 495.515,
            "volume": 189745,
            "datetime": 1713543840000
        },
        {
            "open": 495.52,
            "high": 495.73,
            "low": 495.5,
            "close": 495.705,
            "volume": 139280,
            "datetime": 1713543900000
        },
        {
            "open": 495.71,
            "high": 495.79,
            "low": 495.615,
            "close": 495.705,
            "volume": 142581,
            "datetime": 1713543960000
        },
        {
            "open": 495.71,
            "high": 495.75,
            "low": 495.56,
            "close": 495.72,
            "volume": 109885,
            "datetime": 1713544020000
        },
        {
            "open": 495.71,
            "high": 495.8299,
            "low": 495.58,
            "close": 495.59,
            "volume": 163691,
            "datetime": 1713544080000
        },
        {
            "open": 495.59,
            "high": 495.61,
            "low": 495.19,
            "close": 495.1999,
            "volume": 172569,
            "datetime": 1713544140000
        },
        {
            "open": 495.22,
            "high": 495.64,
            "low": 495.2,
            "close": 495.51,
            "volume": 132180,
            "datetime": 1713544200000
        },
        {
            "open": 495.5,
            "high": 495.5397,
            "low": 495.2476,
            "close": 495.28,
            "volume": 144029,
            "datetime": 1713544260000
        },
        {
            "open": 495.29,
            "high": 495.4,
            "low": 495.2,
            "close": 495.32,
            "volume": 127843,
            "datetime": 1713544320000
        },
        {
            "open": 495.29,
            "high": 495.52,
            "low": 495.25,
            "close": 495.35,
            "volume": 171782,
            "datetime": 1713544380000
        },
        {
            "open": 495.32,
            "high": 495.63,
            "low": 495.305,
            "close": 495.6269,
            "volume": 116278,
            "datetime": 1713544440000
        },
        {
            "open": 495.62,
            "high": 495.7,
            "low": 495.45,
            "close": 495.47,
            "volume": 162575,
            "datetime": 1713544500000
        },
        {
            "open": 495.47,
            "high": 495.59,
            "low": 495.305,
            "close": 495.325,
            "volume": 113726,
            "datetime": 1713544560000
        },
        {
            "open": 495.33,
            "high": 495.41,
            "low": 495.22,
            "close": 495.38,
            "volume": 101530,
            "datetime": 1713544620000
        },
        {
            "open": 495.37,
            "high": 495.47,
            "low": 495.305,
            "close": 495.34,
            "volume": 83805,
            "datetime": 1713544680000
        },
        {
            "open": 495.33,
            "high": 495.39,
            "low": 495.155,
            "close": 495.18,
            "volume": 115805,
            "datetime": 1713544740000
        },
        {
            "open": 495.19,
            "high": 495.2675,
            "low": 495.08,
            "close": 495.21,
            "volume": 125337,
            "datetime": 1713544800000
        },
        {
            "open": 495.19,
            "high": 495.42,
            "low": 495.19,
            "close": 495.41,
            "volume": 109727,
            "datetime": 1713544860000
        },
        {
            "open": 495.42,
            "high": 495.82,
            "low": 495.42,
            "close": 495.745,
            "volume": 256332,
            "datetime": 1713544920000
        },
        {
            "open": 495.75,
            "high": 495.96,
            "low": 495.7101,
            "close": 495.86,
            "volume": 294833,
            "datetime": 1713544980000
        },
        {
            "open": 495.88,
            "high": 496.09,
            "low": 495.87,
            "close": 496.0072,
            "volume": 144474,
            "datetime": 1713545040000
        },
        {
            "open": 496,
            "high": 496.23,
            "low": 495.9,
            "close": 496.19,
            "volume": 171446,
            "datetime": 1713545100000
        },
        {
            "open": 496.19,
            "high": 496.41,
            "low": 496.13,
            "close": 496.23,
            "volume": 146255,
            "datetime": 1713545160000
        },
        {
            "open": 496.23,
            "high": 496.24,
            "low": 495.841,
            "close": 495.9,
            "volume": 119213,
            "datetime": 1713545220000
        },
        {
            "open": 495.895,
            "high": 496.125,
            "low": 495.89,
            "close": 496.05,
            "volume": 92384,
            "datetime": 1713545280000
        },
        {
            "open": 496.04,
            "high": 496.17,
            "low": 496.0204,
            "close": 496.12,
            "volume": 104182,
            "datetime": 1713545340000
        },
        {
            "open": 496.125,
            "high": 496.9,
            "low": 496.11,
            "close": 496.82,
            "volume": 192052,
            "datetime": 1713545400000
        },
        {
            "open": 496.79,
            "high": 496.89,
            "low": 496.74,
            "close": 496.85,
            "volume": 161656,
            "datetime": 1713545460000
        },
        {
            "open": 496.85,
            "high": 497.02,
            "low": 496.8,
            "close": 496.93,
            "volume": 132313,
            "datetime": 1713545520000
        },
        {
            "open": 496.95,
            "high": 497.23,
            "low": 496.93,
            "close": 497.14,
            "volume": 181752,
            "datetime": 1713545580000
        },
        {
            "open": 497.15,
            "high": 497.165,
            "low": 496.43,
            "close": 496.45,
            "volume": 176868,
            "datetime": 1713545640000
        },
        {
            "open": 496.46,
            "high": 496.67,
            "low": 496.46,
            "close": 496.67,
            "volume": 208824,
            "datetime": 1713545700000
        },
        {
            "open": 496.67,
            "high": 496.77,
            "low": 496.54,
            "close": 496.71,
            "volume": 128599,
            "datetime": 1713545760000
        },
        {
            "open": 496.715,
            "high": 496.9699,
            "low": 496.69,
            "close": 496.8887,
            "volume": 91070,
            "datetime": 1713545820000
        },
        {
            "open": 496.89,
            "high": 496.99,
            "low": 496.84,
            "close": 496.88,
            "volume": 38977,
            "datetime": 1713545880000
        },
        {
            "open": 496.86,
            "high": 497.12,
            "low": 496.75,
            "close": 497.1,
            "volume": 152635,
            "datetime": 1713545940000
        },
        {
            "open": 497.09,
            "high": 497.255,
            "low": 496.98,
            "close": 497.205,
            "volume": 113024,
            "datetime": 1713546000000
        },
        {
            "open": 497.2,
            "high": 497.2599,
            "low": 496.91,
            "close": 496.91,
            "volume": 93588,
            "datetime": 1713546060000
        },
        {
            "open": 496.9101,
            "high": 497.13,
            "low": 496.9101,
            "close": 497.04,
            "volume": 60336,
            "datetime": 1713546120000
        },
        {
            "open": 497.06,
            "high": 497.3799,
            "low": 496.88,
            "close": 497.23,
            "volume": 164328,
            "datetime": 1713546180000
        },
        {
            "open": 497.235,
            "high": 497.5,
            "low": 497.21,
            "close": 497.21,
            "volume": 115887,
            "datetime": 1713546240000
        },
        {
            "open": 497.23,
            "high": 497.33,
            "low": 497,
            "close": 497.33,
            "volume": 109639,
            "datetime": 1713546300000
        },
        {
            "open": 497.34,
            "high": 497.455,
            "low": 497.04,
            "close": 497.14,
            "volume": 170890,
            "datetime": 1713546360000
        },
        {
            "open": 497.14,
            "high": 497.255,
            "low": 497.115,
            "close": 497.21,
            "volume": 96464,
            "datetime": 1713546420000
        },
        {
            "open": 497.21,
            "high": 497.4895,
            "low": 497.21,
            "close": 497.2999,
            "volume": 85344,
            "datetime": 1713546480000
        },
        {
            "open": 497.27,
            "high": 497.4399,
            "low": 497.17,
            "close": 497.405,
            "volume": 69797,
            "datetime": 1713546540000
        },
        {
            "open": 497.35,
            "high": 497.56,
            "low": 497.24,
            "close": 497.35,
            "volume": 84681,
            "datetime": 1713546600000
        },
        {
            "open": 497.35,
            "high": 497.36,
            "low": 497.0901,
            "close": 497.21,
            "volume": 168823,
            "datetime": 1713546660000
        },
        {
            "open": 497.2179,
            "high": 497.42,
            "low": 497.11,
            "close": 497.18,
            "volume": 66088,
            "datetime": 1713546720000
        },
        {
            "open": 497.19,
            "high": 497.35,
            "low": 497.165,
            "close": 497.165,
            "volume": 70227,
            "datetime": 1713546780000
        },
        {
            "open": 497.16,
            "high": 497.295,
            "low": 497.15,
            "close": 497.22,
            "volume": 60416,
            "datetime": 1713546840000
        },
        {
            "open": 497.2,
            "high": 497.3,
            "low": 496.88,
            "close": 496.995,
            "volume": 157445,
            "datetime": 1713546900000
        },
        {
            "open": 496.97,
            "high": 497.11,
            "low": 496.86,
            "close": 496.8801,
            "volume": 149216,
            "datetime": 1713546960000
        },
        {
            "open": 496.89,
            "high": 497.45,
            "low": 496.83,
            "close": 497.3,
            "volume": 115660,
            "datetime": 1713547020000
        },
        {
            "open": 497.32,
            "high": 497.74,
            "low": 497.315,
            "close": 497.6001,
            "volume": 201868,
            "datetime": 1713547080000
        },
        {
            "open": 497.6,
            "high": 497.66,
            "low": 497.4004,
            "close": 497.59,
            "volume": 127330,
            "datetime": 1713547140000
        },
        {
            "open": 497.6,
            "high": 497.72,
            "low": 497.45,
            "close": 497.58,
            "volume": 106856,
            "datetime": 1713547200000
        },
        {
            "open": 497.58,
            "high": 497.595,
            "low": 497.22,
            "close": 497.45,
            "volume": 156027,
            "datetime": 1713547260000
        },
        {
            "open": 497.42,
            "high": 497.475,
            "low": 497.04,
            "close": 497.15,
            "volume": 58803,
            "datetime": 1713547320000
        },
        {
            "open": 497.1594,
            "high": 497.31,
            "low": 497.1201,
            "close": 497.17,
            "volume": 73068,
            "datetime": 1713547380000
        },
        {
            "open": 497.18,
            "high": 497.36,
            "low": 497.15,
            "close": 497.27,
            "volume": 39096,
            "datetime": 1713547440000
        },
        {
            "open": 497.27,
            "high": 497.35,
            "low": 497.12,
            "close": 497.3,
            "volume": 54035,
            "datetime": 1713547500000
        },
        {
            "open": 497.305,
            "high": 497.41,
            "low": 497.19,
            "close": 497.325,
            "volume": 56850,
            "datetime": 1713547560000
        },
        {
            "open": 497.32,
            "high": 497.32,
            "low": 496.95,
            "close": 497.1,
            "volume": 74475,
            "datetime": 1713547620000
        },
        {
            "open": 497.09,
            "high": 497.23,
            "low": 496.79,
            "close": 496.83,
            "volume": 100502,
            "datetime": 1713547680000
        },
        {
            "open": 496.83,
            "high": 496.83,
            "low": 496.45,
            "close": 496.5325,
            "volume": 155015,
            "datetime": 1713547740000
        },
        {
            "open": 496.55,
            "high": 497.09,
            "low": 496.51,
            "close": 497.05,
            "volume": 148508,
            "datetime": 1713547800000
        },
        {
            "open": 497.05,
            "high": 497.18,
            "low": 496.83,
            "close": 496.85,
            "volume": 96094,
            "datetime": 1713547860000
        },
        {
            "open": 496.84,
            "high": 496.96,
            "low": 496.68,
            "close": 496.8,
            "volume": 102840,
            "datetime": 1713547920000
        },
        {
            "open": 496.79,
            "high": 496.97,
            "low": 496.78,
            "close": 496.86,
            "volume": 106155,
            "datetime": 1713547980000
        },
        {
            "open": 496.86,
            "high": 497.01,
            "low": 496.81,
            "close": 496.97,
            "volume": 39900,
            "datetime": 1713548040000
        },
        {
            "open": 496.95,
            "high": 497.06,
            "low": 496.71,
            "close": 496.77,
            "volume": 53070,
            "datetime": 1713548100000
        },
        {
            "open": 496.78,
            "high": 496.94,
            "low": 496.7683,
            "close": 496.91,
            "volume": 57021,
            "datetime": 1713548160000
        },
        {
            "open": 496.91,
            "high": 497.1,
            "low": 496.77,
            "close": 496.77,
            "volume": 162575,
            "datetime": 1713548220000
        },
        {
            "open": 496.765,
            "high": 496.85,
            "low": 496.68,
            "close": 496.815,
            "volume": 63152,
            "datetime": 1713548280000
        },
        {
            "open": 496.81,
            "high": 496.84,
            "low": 496.52,
            "close": 496.53,
            "volume": 87649,
            "datetime": 1713548340000
        },
        {
            "open": 496.58,
            "high": 496.91,
            "low": 496.58,
            "close": 496.84,
            "volume": 87055,
            "datetime": 1713548400000
        },
        {
            "open": 496.85,
            "high": 496.86,
            "low": 496.66,
            "close": 496.66,
            "volume": 80856,
            "datetime": 1713548460000
        },
        {
            "open": 496.63,
            "high": 496.98,
            "low": 496.62,
            "close": 496.94,
            "volume": 136582,
            "datetime": 1713548520000
        },
        {
            "open": 496.9404,
            "high": 497.09,
            "low": 496.755,
            "close": 496.81,
            "volume": 76728,
            "datetime": 1713548580000
        },
        {
            "open": 496.79,
            "high": 496.9,
            "low": 496.79,
            "close": 496.82,
            "volume": 34779,
            "datetime": 1713548640000
        },
        {
            "open": 496.85,
            "high": 497.13,
            "low": 496.84,
            "close": 497.06,
            "volume": 110657,
            "datetime": 1713548700000
        },
        {
            "open": 497.06,
            "high": 497.4,
            "low": 496.98,
            "close": 497.3,
            "volume": 158279,
            "datetime": 1713548760000
        },
        {
            "open": 497.3,
            "high": 497.33,
            "low": 497.14,
            "close": 497.25,
            "volume": 74498,
            "datetime": 1713548820000
        },
        {
            "open": 497.24,
            "high": 497.369,
            "low": 497.21,
            "close": 497.35,
            "volume": 55475,
            "datetime": 1713548880000
        },
        {
            "open": 497.33,
            "high": 497.6199,
            "low": 497.29,
            "close": 497.5901,
            "volume": 99540,
            "datetime": 1713548940000
        },
        {
            "open": 497.59,
            "high": 497.88,
            "low": 497.55,
            "close": 497.69,
            "volume": 164578,
            "datetime": 1713549000000
        },
        {
            "open": 497.67,
            "high": 498.0899,
            "low": 497.665,
            "close": 498.0299,
            "volume": 147657,
            "datetime": 1713549060000
        },
        {
            "open": 498.04,
            "high": 498.21,
            "low": 497.875,
            "close": 498.12,
            "volume": 130230,
            "datetime": 1713549120000
        },
        {
            "open": 498.11,
            "high": 498.13,
            "low": 497.98,
            "close": 498.11,
            "volume": 81721,
            "datetime": 1713549180000
        },
        {
            "open": 498.12,
            "high": 498.42,
            "low": 498.05,
            "close": 498.265,
            "volume": 242703,
            "datetime": 1713549240000
        },
        {
            "open": 498.29,
            "high": 498.46,
            "low": 497.93,
            "close": 497.97,
            "volume": 182684,
            "datetime": 1713549300000
        },
        {
            "open": 497.97,
            "high": 498.0799,
            "low": 497.28,
            "close": 497.31,
            "volume": 233368,
            "datetime": 1713549360000
        },
        {
            "open": 497.3,
            "high": 497.31,
            "low": 497.04,
            "close": 497.14,
            "volume": 239943,
            "datetime": 1713549420000
        },
        {
            "open": 497.13,
            "high": 497.47,
            "low": 497.13,
            "close": 497.46,
            "volume": 102085,
            "datetime": 1713549480000
        },
        {
            "open": 497.41,
            "high": 497.57,
            "low": 497.27,
            "close": 497.3,
            "volume": 109514,
            "datetime": 1713549540000
        },
        {
            "open": 497.29,
            "high": 497.3399,
            "low": 496.59,
            "close": 496.71,
            "volume": 254256,
            "datetime": 1713549600000
        },
        {
            "open": 496.72,
            "high": 496.76,
            "low": 496.23,
            "close": 496.42,
            "volume": 131276,
            "datetime": 1713549660000
        },
        {
            "open": 496.43,
            "high": 496.5,
            "low": 496.15,
            "close": 496.29,
            "volume": 148783,
            "datetime": 1713549720000
        },
        {
            "open": 496.31,
            "high": 496.52,
            "low": 496.21,
            "close": 496.24,
            "volume": 137362,
            "datetime": 1713549780000
        },
        {
            "open": 496.25,
            "high": 496.37,
            "low": 496.11,
            "close": 496.31,
            "volume": 157013,
            "datetime": 1713549840000
        },
        {
            "open": 496.32,
            "high": 496.5,
            "low": 496.0699,
            "close": 496.22,
            "volume": 194066,
            "datetime": 1713549900000
        },
        {
            "open": 496.19,
            "high": 496.37,
            "low": 495.9178,
            "close": 495.9299,
            "volume": 155977,
            "datetime": 1713549960000
        },
        {
            "open": 495.93,
            "high": 497.11,
            "low": 495.82,
            "close": 497.11,
            "volume": 368757,
            "datetime": 1713550020000
        },
        {
            "open": 497.12,
            "high": 497.13,
            "low": 496.545,
            "close": 496.59,
            "volume": 127223,
            "datetime": 1713550080000
        },
        {
            "open": 496.5988,
            "high": 496.64,
            "low": 496.35,
            "close": 496.39,
            "volume": 81019,
            "datetime": 1713550140000
        },
        {
            "open": 496.39,
            "high": 496.6701,
            "low": 496.39,
            "close": 496.56,
            "volume": 78525,
            "datetime": 1713550200000
        },
        {
            "open": 496.565,
            "high": 496.68,
            "low": 496.45,
            "close": 496.58,
            "volume": 100414,
            "datetime": 1713550260000
        },
        {
            "open": 496.58,
            "high": 496.8,
            "low": 496.42,
            "close": 496.46,
            "volume": 107653,
            "datetime": 1713550320000
        },
        {
            "open": 496.45,
            "high": 496.69,
            "low": 496.42,
            "close": 496.425,
            "volume": 90399,
            "datetime": 1713550380000
        },
        {
            "open": 496.43,
            "high": 496.5696,
            "low": 496.27,
            "close": 496.28,
            "volume": 128841,
            "datetime": 1713550440000
        },
        {
            "open": 496.27,
            "high": 496.33,
            "low": 495.95,
            "close": 496.09,
            "volume": 164708,
            "datetime": 1713550500000
        },
        {
            "open": 496.08,
            "high": 496.25,
            "low": 495.8,
            "close": 496.21,
            "volume": 211954,
            "datetime": 1713550560000
        },
        {
            "open": 496.22,
            "high": 496.25,
            "low": 496,
            "close": 496.0697,
            "volume": 74082,
            "datetime": 1713550620000
        },
        {
            "open": 496.065,
            "high": 496.415,
            "low": 496.01,
            "close": 496.39,
            "volume": 133637,
            "datetime": 1713550680000
        },
        {
            "open": 496.39,
            "high": 496.6,
            "low": 496.3522,
            "close": 496.49,
            "volume": 134327,
            "datetime": 1713550740000
        },
        {
            "open": 496.5,
            "high": 496.58,
            "low": 496.285,
            "close": 496.31,
            "volume": 90674,
            "datetime": 1713550800000
        },
        {
            "open": 496.2964,
            "high": 496.57,
            "low": 496.25,
            "close": 496.37,
            "volume": 110788,
            "datetime": 1713550860000
        },
        {
            "open": 496.35,
            "high": 496.62,
            "low": 496.29,
            "close": 496.47,
            "volume": 154369,
            "datetime": 1713550920000
        },
        {
            "open": 496.46,
            "high": 496.52,
            "low": 496.29,
            "close": 496.41,
            "volume": 105817,
            "datetime": 1713550980000
        },
        {
            "open": 496.4,
            "high": 496.72,
            "low": 496.38,
            "close": 496.61,
            "volume": 132975,
            "datetime": 1713551040000
        },
        {
            "open": 496.6,
            "high": 496.62,
            "low": 496.19,
            "close": 496.25,
            "volume": 120431,
            "datetime": 1713551100000
        },
        {
            "open": 496.25,
            "high": 496.34,
            "low": 496.12,
            "close": 496.25,
            "volume": 133225,
            "datetime": 1713551160000
        },
        {
            "open": 496.26,
            "high": 496.32,
            "low": 496.13,
            "close": 496.29,
            "volume": 66940,
            "datetime": 1713551220000
        },
        {
            "open": 496.29,
            "high": 496.45,
            "low": 495.875,
            "close": 495.9,
            "volume": 153101,
            "datetime": 1713551280000
        },
        {
            "open": 495.91,
            "high": 496.07,
            "low": 495.46,
            "close": 495.47,
            "volume": 264816,
            "datetime": 1713551340000
        },
        {
            "open": 495.46,
            "high": 495.89,
            "low": 495.43,
            "close": 495.8106,
            "volume": 211031,
            "datetime": 1713551400000
        },
        {
            "open": 495.815,
            "high": 495.9725,
            "low": 495.735,
            "close": 495.8,
            "volume": 246110,
            "datetime": 1713551460000
        },
        {
            "open": 495.77,
            "high": 495.85,
            "low": 495.58,
            "close": 495.74,
            "volume": 87845,
            "datetime": 1713551520000
        },
        {
            "open": 495.73,
            "high": 495.8,
            "low": 495.51,
            "close": 495.68,
            "volume": 67983,
            "datetime": 1713551580000
        },
        {
            "open": 495.67,
            "high": 495.82,
            "low": 495.52,
            "close": 495.73,
            "volume": 74184,
            "datetime": 1713551640000
        },
        {
            "open": 495.75,
            "high": 495.815,
            "low": 495.575,
            "close": 495.81,
            "volume": 59813,
            "datetime": 1713551700000
        },
        {
            "open": 495.8,
            "high": 495.8398,
            "low": 495.47,
            "close": 495.59,
            "volume": 137757,
            "datetime": 1713551760000
        },
        {
            "open": 495.59,
            "high": 495.6,
            "low": 495.18,
            "close": 495.19,
            "volume": 244792,
            "datetime": 1713551820000
        },
        {
            "open": 495.195,
            "high": 495.39,
            "low": 494.88,
            "close": 494.97,
            "volume": 283909,
            "datetime": 1713551880000
        },
        {
            "open": 494.98,
            "high": 495.09,
            "low": 494.79,
            "close": 494.88,
            "volume": 173291,
            "datetime": 1713551940000
        },
        {
            "open": 494.87,
            "high": 494.89,
            "low": 494.46,
            "close": 494.51,
            "volume": 159511,
            "datetime": 1713552000000
        },
        {
            "open": 494.52,
            "high": 494.71,
            "low": 494.46,
            "close": 494.59,
            "volume": 186474,
            "datetime": 1713552060000
        },
        {
            "open": 494.6,
            "high": 494.75,
            "low": 494.46,
            "close": 494.58,
            "volume": 233026,
            "datetime": 1713552120000
        },
        {
            "open": 494.57,
            "high": 494.85,
            "low": 494.51,
            "close": 494.6634,
            "volume": 260873,
            "datetime": 1713552180000
        },
        {
            "open": 494.66,
            "high": 494.69,
            "low": 494.34,
            "close": 494.3499,
            "volume": 207220,
            "datetime": 1713552240000
        },
        {
            "open": 494.34,
            "high": 494.57,
            "low": 494.27,
            "close": 494.355,
            "volume": 220698,
            "datetime": 1713552300000
        },
        {
            "open": 494.35,
            "high": 494.5299,
            "low": 494.13,
            "close": 494.21,
            "volume": 195367,
            "datetime": 1713552360000
        },
        {
            "open": 494.21,
            "high": 494.31,
            "low": 494.02,
            "close": 494.2,
            "volume": 256778,
            "datetime": 1713552420000
        },
        {
            "open": 494.21,
            "high": 494.36,
            "low": 494.06,
            "close": 494.1699,
            "volume": 234255,
            "datetime": 1713552480000
        },
        {
            "open": 494.17,
            "high": 494.87,
            "low": 494.17,
            "close": 494.77,
            "volume": 273068,
            "datetime": 1713552540000
        },
        {
            "open": 494.78,
            "high": 494.9804,
            "low": 494.48,
            "close": 494.52,
            "volume": 183842,
            "datetime": 1713552600000
        },
        {
            "open": 494.49,
            "high": 494.68,
            "low": 494.38,
            "close": 494.57,
            "volume": 174576,
            "datetime": 1713552660000
        },
        {
            "open": 494.54,
            "high": 494.76,
            "low": 494.33,
            "close": 494.535,
            "volume": 139876,
            "datetime": 1713552720000
        },
        {
            "open": 494.53,
            "high": 494.53,
            "low": 494.29,
            "close": 494.45,
            "volume": 132143,
            "datetime": 1713552780000
        },
        {
            "open": 494.46,
            "high": 494.72,
            "low": 494.26,
            "close": 494.52,
            "volume": 150171,
            "datetime": 1713552840000
        },
        {
            "open": 494.5,
            "high": 495.06,
            "low": 494.4113,
            "close": 494.985,
            "volume": 308488,
            "datetime": 1713552900000
        },
        {
            "open": 494.99,
            "high": 495.23,
            "low": 494.89,
            "close": 494.95,
            "volume": 199842,
            "datetime": 1713552960000
        },
        {
            "open": 494.94,
            "high": 495.05,
            "low": 494.58,
            "close": 494.8102,
            "volume": 339794,
            "datetime": 1713553020000
        },
        {
            "open": 494.8,
            "high": 495.07,
            "low": 494.74,
            "close": 494.93,
            "volume": 162018,
            "datetime": 1713553080000
        },
        {
            "open": 494.92,
            "high": 494.96,
            "low": 494.68,
            "close": 494.9197,
            "volume": 146964,
            "datetime": 1713553140000
        },
        {
            "open": 494.91,
            "high": 494.92,
            "low": 494.4,
            "close": 494.69,
            "volume": 215325,
            "datetime": 1713553200000
        },
        {
            "open": 494.69,
            "high": 494.85,
            "low": 494.42,
            "close": 494.66,
            "volume": 146852,
            "datetime": 1713553260000
        },
        {
            "open": 494.67,
            "high": 494.875,
            "low": 494.54,
            "close": 494.74,
            "volume": 151226,
            "datetime": 1713553320000
        },
        {
            "open": 494.74,
            "high": 494.98,
            "low": 494.72,
            "close": 494.9488,
            "volume": 124142,
            "datetime": 1713553380000
        },
        {
            "open": 494.98,
            "high": 495.0201,
            "low": 494.65,
            "close": 494.66,
            "volume": 169349,
            "datetime": 1713553440000
        },
        {
            "open": 494.65,
            "high": 494.8,
            "low": 494.42,
            "close": 494.65,
            "volume": 153661,
            "datetime": 1713553500000
        },
        {
            "open": 494.65,
            "high": 494.67,
            "low": 494.0999,
            "close": 494.495,
            "volume": 273799,
            "datetime": 1713553560000
        },
        {
            "open": 494.51,
            "high": 494.9,
            "low": 494.3947,
            "close": 494.65,
            "volume": 163927,
            "datetime": 1713553620000
        },
        {
            "open": 494.66,
            "high": 494.86,
            "low": 494.47,
            "close": 494.8,
            "volume": 131347,
            "datetime": 1713553680000
        },
        {
            "open": 494.78,
            "high": 495.22,
            "low": 494.69,
            "close": 494.9799,
            "volume": 193214,
            "datetime": 1713553740000
        },
        {
            "open": 494.98,
            "high": 495.02,
            "low": 494.79,
            "close": 494.89,
            "volume": 100422,
            "datetime": 1713553800000
        },
        {
            "open": 494.87,
            "high": 495.06,
            "low": 494.75,
            "close": 494.94,
            "volume": 134430,
            "datetime": 1713553860000
        },
        {
            "open": 494.95,
            "high": 495.18,
            "low": 494.95,
            "close": 495.1437,
            "volume": 116310,
            "datetime": 1713553920000
        },
        {
            "open": 495.15,
            "high": 495.72,
            "low": 495.095,
            "close": 495.58,
            "volume": 294998,
            "datetime": 1713553980000
        },
        {
            "open": 495.59,
            "high": 495.6,
            "low": 495.17,
            "close": 495.41,
            "volume": 158968,
            "datetime": 1713554040000
        },
        {
            "open": 495.36,
            "high": 495.39,
            "low": 494.95,
            "close": 495.02,
            "volume": 134103,
            "datetime": 1713554100000
        },
        {
            "open": 495.01,
            "high": 495.16,
            "low": 494.76,
            "close": 494.76,
            "volume": 166675,
            "datetime": 1713554160000
        },
        {
            "open": 494.77,
            "high": 494.86,
            "low": 494.69,
            "close": 494.82,
            "volume": 160032,
            "datetime": 1713554220000
        },
        {
            "open": 494.81,
            "high": 495.04,
            "low": 494.785,
            "close": 495,
            "volume": 124608,
            "datetime": 1713554280000
        },
        {
            "open": 494.99,
            "high": 495.29,
            "low": 494.88,
            "close": 495.18,
            "volume": 179896,
            "datetime": 1713554340000
        },
        {
            "open": 495.16,
            "high": 495.35,
            "low": 495.13,
            "close": 495.175,
            "volume": 106644,
            "datetime": 1713554400000
        },
        {
            "open": 495.17,
            "high": 495.26,
            "low": 494.94,
            "close": 495.1195,
            "volume": 106501,
            "datetime": 1713554460000
        },
        {
            "open": 495.11,
            "high": 495.34,
            "low": 494.83,
            "close": 495,
            "volume": 112875,
            "datetime": 1713554520000
        },
        {
            "open": 495,
            "high": 495.2,
            "low": 494.92,
            "close": 495.06,
            "volume": 136454,
            "datetime": 1713554580000
        },
        {
            "open": 495.08,
            "high": 495.11,
            "low": 494.8,
            "close": 494.81,
            "volume": 82469,
            "datetime": 1713554640000
        },
        {
            "open": 494.8101,
            "high": 494.96,
            "low": 494.63,
            "close": 494.65,
            "volume": 108657,
            "datetime": 1713554700000
        },
        {
            "open": 494.64,
            "high": 495.15,
            "low": 494.6,
            "close": 495.08,
            "volume": 129505,
            "datetime": 1713554760000
        },
        {
            "open": 495.08,
            "high": 495.24,
            "low": 495,
            "close": 495.18,
            "volume": 114022,
            "datetime": 1713554820000
        },
        {
            "open": 495.17,
            "high": 495.52,
            "low": 495.02,
            "close": 495.37,
            "volume": 177794,
            "datetime": 1713554880000
        },
        {
            "open": 495.38,
            "high": 495.53,
            "low": 495.05,
            "close": 495.08,
            "volume": 146040,
            "datetime": 1713554940000
        },
        {
            "open": 495.08,
            "high": 495.32,
            "low": 495.05,
            "close": 495.16,
            "volume": 83533,
            "datetime": 1713555000000
        },
        {
            "open": 495.18,
            "high": 495.3,
            "low": 494.96,
            "close": 495.03,
            "volume": 116299,
            "datetime": 1713555060000
        },
        {
            "open": 495.0375,
            "high": 495.18,
            "low": 494.8496,
            "close": 495.01,
            "volume": 164179,
            "datetime": 1713555120000
        },
        {
            "open": 495.01,
            "high": 495.075,
            "low": 494.82,
            "close": 495.07,
            "volume": 150476,
            "datetime": 1713555180000
        },
        {
            "open": 495.09,
            "high": 495.25,
            "low": 494.97,
            "close": 495.01,
            "volume": 151740,
            "datetime": 1713555240000
        },
        {
            "open": 495.01,
            "high": 495.1072,
            "low": 494.58,
            "close": 494.61,
            "volume": 182370,
            "datetime": 1713555300000
        },
        {
            "open": 494.6,
            "high": 494.61,
            "low": 494.06,
            "close": 494.3999,
            "volume": 302666,
            "datetime": 1713555360000
        },
        {
            "open": 494.39,
            "high": 495.06,
            "low": 494.375,
            "close": 495.02,
            "volume": 341206,
            "datetime": 1713555420000
        },
        {
            "open": 495.03,
            "high": 495.135,
            "low": 494.7,
            "close": 494.82,
            "volume": 189797,
            "datetime": 1713555480000
        },
        {
            "open": 494.8,
            "high": 494.86,
            "low": 494.37,
            "close": 494.66,
            "volume": 249705,
            "datetime": 1713555540000
        },
        {
            "open": 494.65,
            "high": 494.65,
            "low": 494.28,
            "close": 494.37,
            "volume": 262516,
            "datetime": 1713555600000
        },
        {
            "open": 494.35,
            "high": 494.38,
            "low": 494.04,
            "close": 494.27,
            "volume": 296777,
            "datetime": 1713555660000
        },
        {
            "open": 494.27,
            "high": 494.33,
            "low": 493.92,
            "close": 494.16,
            "volume": 362253,
            "datetime": 1713555720000
        },
        {
            "open": 494.17,
            "high": 494.19,
            "low": 493.96,
            "close": 494.0499,
            "volume": 339477,
            "datetime": 1713555780000
        },
        {
            "open": 494.04,
            "high": 494.56,
            "low": 493.95,
            "close": 494.429,
            "volume": 373806,
            "datetime": 1713555840000
        },
        {
            "open": 494.43,
            "high": 494.54,
            "low": 494.08,
            "close": 494.24,
            "volume": 372071,
            "datetime": 1713555900000
        },
        {
            "open": 494.23,
            "high": 494.33,
            "low": 493.86,
            "close": 494,
            "volume": 417494,
            "datetime": 1713555960000
        },
        {
            "open": 494.035,
            "high": 494.8,
            "low": 493.99,
            "close": 494.71,
            "volume": 477954,
            "datetime": 1713556020000
        },
        {
            "open": 494.73,
            "high": 494.85,
            "low": 494.37,
            "close": 494.53,
            "volume": 325104,
            "datetime": 1713556080000
        },
        {
            "open": 494.56,
            "high": 494.69,
            "low": 494.41,
            "close": 494.54,
            "volume": 199773,
            "datetime": 1713556140000
        },
        {
            "open": 494.55,
            "high": 494.71,
            "low": 494.315,
            "close": 494.645,
            "volume": 450148,
            "datetime": 1713556200000
        },
        {
            "open": 494.65,
            "high": 494.86,
            "low": 494.41,
            "close": 494.475,
            "volume": 401010,
            "datetime": 1713556260000
        },
        {
            "open": 494.48,
            "high": 494.73,
            "low": 494.4712,
            "close": 494.61,
            "volume": 346400,
            "datetime": 1713556320000
        },
        {
            "open": 494.61,
            "high": 494.96,
            "low": 494.49,
            "close": 494.8498,
            "volume": 298950,
            "datetime": 1713556380000
        },
        {
            "open": 494.84,
            "high": 495.11,
            "low": 494.775,
            "close": 495.105,
            "volume": 386212,
            "datetime": 1713556440000
        },
        {
            "open": 495.105,
            "high": 495.18,
            "low": 494.85,
            "close": 495.075,
            "volume": 507291,
            "datetime": 1713556500000
        },
        {
            "open": 495.07,
            "high": 495.18,
            "low": 495.04,
            "close": 495.17,
            "volume": 330287,
            "datetime": 1713556560000
        },
        {
            "open": 495.165,
            "high": 495.33,
            "low": 495.12,
            "close": 495.29,
            "volume": 644941,
            "datetime": 1713556620000
        },
        {
            "open": 495.29,
            "high": 495.32,
            "low": 495.21,
            "close": 495.23,
            "volume": 714499,
            "datetime": 1713556680000
        },
        {
            "open": 495.22,
            "high": 495.52,
            "low": 494.82,
            "close": 495.1,
            "volume": 2460313,
            "datetime": 1713556740000
        }
    ],
    "symbol": "SPY",
    "empty": false
}
