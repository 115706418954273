import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {PlotlyService} from '../../../services/plotly.service';
import testData from '../../../../assets/static/SPY-test-042124.json';
import {surfaceData} from "../../../models/surfaceData";
import {quoteHist_old} from "../../../models/quoteHist_old";
import {candle_old} from "../../../models/candle_old";
import { FormGroup, FormControl } from '@angular/forms';
import {SchwabAuthService} from "../../../services/schwab-auth.service";
import { SchwabDataService } from "../../../services/schwab-data.service";
import {PriceHistoryRequest} from "../../../models/PriceHistoryRequest";

@Component({
	selector: 'app-volume-price-surface',
	templateUrl: './volume-price-surface.component.html',
	styleUrl: './volume-price-surface.component.scss'
})
export class VolumePriceSurfaceComponent implements OnInit {
	constructor(private plot: PlotlyService, private cdRef: ChangeDetectorRef, private schwabAuth: SchwabAuthService, private schwabData: SchwabDataService) {
	}

	testData: any;

	basicData: any;

	basicOptions: any;

	rawQuoteData = new quoteHist_old(testData);
	plotData!: surfaceData;

	progressBarVal: number = 0

	ngOnInit() {
	}

	volumeFlowForm = new FormGroup({
		startDt: new FormControl(''),
		endDt: new FormControl(''),
	});

	testButtonClick() {
		// this.schwabData.getQuote('SPY').subscribe(
		// 	(response) => {
		// 		this.testData = response;
		// 	},
		// 	(error) => {
		// 		console.error('Error getting test data', error);
		// 	}
		// );
		const requestParams: PriceHistoryRequest = {
			symbol: 'SPY',           // Equity symbol
			periodType: 'day',        // Requesting data for each day
			period: 10,               // Last 10 days
			frequencyType: 'minute',  // Data frequency is minute-by-minute
			frequency: 5,             // 5-minute intervals
			//startDate: 1451624400000, // Start date in milliseconds
			//endDate: 1451797200000,   // End date in milliseconds
			//needExtendedHoursData: true, // Include extended hours data
			//needPreviousClose: true    // Include previous close price and date
		};
		this.schwabData.getPriceHistory(requestParams).subscribe(
			(response) => {
				this.testData = response;
			},
			(error) => {
				console.error('Error getting test data', error);
			}
		)
	}
	calculateTestPlot() {
		let testSize = 2996;
		this.plotData = new surfaceData();
		this.plotData.x = new Array(testSize).fill(0);
		for (let i = 0; i < this.plotData.x.length; i++) {
			this.plotData.x[i] = i;
		}
		this.plotData.y = new Array(testSize).fill(0);
		for (let i = 0; i < this.plotData.y.length; i++) {
			this.plotData.y[i] = i;
		}
		this.plotData.gauss = new Array(testSize).fill(new Array(testSize).fill(0));
		let k = 0;
		for (let i = 0; i < this.plotData.gauss.length; i++) {
			for (let j = 0; j < this.plotData.gauss[i].length; j++) {
				this.plotData.gauss[i][j] = k;
				k++;
			}
		}
		console.log('plotData', this.plotData);

		this.plot.plotSurface("Gauss Plot", "plot", this.plotData);
	}
	calculateVolumePlot() {
		this.progressBarVal = 0;
		console.log(this.rawQuoteData);
		this.plotData = new surfaceData();
		this.plotData.x = [...testData.candles.map(c => new Date(c.datetime))];
		this.plotData.y = this.createRangeArray(Math.min(...this.rawQuoteData.candles.map(o => Math.floor(o.low))),
			Math.max(...this.rawQuoteData.candles.map(o => Math.ceil(o.high)), 0), 0.01);
		console.log("x: ", this.plotData.x);
		console.log("y: ", this.plotData.y);
		this.plotData.z = new Array(this.plotData.x.length);//.fill(new Array(this.plotData.y.length).fill(0));
		for (let i: number = 0; i < this.plotData.z.length; i++) {
			this.plotData.z[i] = new Array(this.plotData.y.length).fill(0);
		}
		console.log("z: ", this.plotData.z);
		this.plotData.gauss = new Array(this.plotData.x.length);//.fill(new Array(this.plotData.y.length).fill(0));
		for (let i: number = 0; i < this.plotData.gauss.length; i++) {
			this.plotData.gauss[i] = new Array(this.plotData.y.length).fill(0);
		}
		console.log("initial gauss: ", this.plotData.gauss);


		for (let i = 0; i < this.plotData.x.length; i++) {
			this.plotData.gauss[i] = this.generateGaussianFromArray(this.plotData.y,
				this.rawQuoteData.candles[i],
				0.01);
		}
		console.log("final gauss: ", this.plotData.gauss);

		for (let i = 0; i < this.plotData.x.length; i++) {
			for (let j = 0; j < this.plotData.y.length; j++) {
				this.plotData.z[i][j] = this.plotData.gauss[i][j] * this.rawQuoteData.candles[i].volume;
				if (i > 0) {
					this.plotData.z[i][j] += this.plotData.z[i-1][j];
				}
			}
		}
		// for (let i = 0; i < this.plotData.x.length; i++) {
		// 	let wid = this.rawQuoteData.candles[i].high - this.rawQuoteData.candles[i].low;
		// 	let gaus = this.generateGaussian(wid, 2, 0.01, Math.floor(this.rawQuoteData.candles[i].low), Math.floor(this.rawQuoteData.candles[i].high));
		// 	for (let j = 0; j < this.plotData.y.length; j++) {
		// 		let si = this.plotData.y.findIndex(x => x == this.rawQuoteData.candles[i].low);
		// 		let ei = this.plotData.y.findIndex(x => x == this.rawQuoteData.candles[i].high);
		// 		if (i > 0) {
		// 			this.plotData.z[i][j] = this.plotData.z[i - 1][j] + ((si >= j && ei <= j) ? this.rawQuoteData.candles[i].volume * gaus[j - si] : 0);
		// 		} else {
		// 			this.plotData.z[i][j] = (si >= j && ei <= j) ? this.rawQuoteData.candles[i].volume * gaus[j - si] : 0;
		// 		}
		// 	}
		// 	this.progressBarVal = Math.ceil((i / this.plotData.x.length) * 100);
		// 	console.log("progress", this.progressBarVal);
		// 	//this.cdRef.detectChanges();
		// }
		console.log('plotData', this.plotData);

		//this.plotData.z = this.plotData.gauss;
		this.plot.plotSurface("Gauss Plot", "plot", this.plotData);
		//this.plot.plotLine("Line Plot","plot",this.plotData.y,this.plotData.gauss[1]);
		/////////

		let wid: number = 230;
		let x: number[] = Array.from(Array(wid * 100).keys(), (x) => Number(x / 100));
		//new Array(230).fill(1);
		//console.log("x", x);
		let y: number[] = this.generateGaussian(wid, 2, 0.01, 0, wid);
		//Array.from(Array(5).keys());
		//this.plot.plotLine("Line Plot","plot",x,y);

		const documentStyle = getComputedStyle(document.documentElement);
		const textColor = documentStyle.getPropertyValue('--text-color');
		const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
		const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

		this.basicData = {
			labels: ['Q1', 'Q2', 'Q3', 'Q4'],
			datasets: [
				{
					label: 'Sales',
					data: [540, 325, 702, 620],
					backgroundColor: ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'],
					borderColor: ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
					borderWidth: 1
				}
			]
		};

		this.basicOptions = {
			plugins: {
				legend: {
					labels: {
						color: textColor
					}
				}
			},
			scales: {
				y: {
					beginAtZero: true,
					ticks: {
						color: textColorSecondary
					},
					grid: {
						color: surfaceBorder,
						drawBorder: false
					}
				},
				x: {
					ticks: {
						color: textColorSecondary
					},
					grid: {
						color: surfaceBorder,
						drawBorder: false
					}
				}
			}
		};
	}

	generateGaussian(mean: number = 0, width: number = 1, stepSize: number = 0.01,
					 start: number = 0, end: number = 0): number[] {
		let gauss: number[] = new Array((end - start) / stepSize);
		//console.log("gause size", gauss.length);
		let stdev: number = ((width / 8) / stepSize);
		for (let i = 0; i < ((end - start) / stepSize) - 1; i++) {
			gauss[i] = (1 / (stdev * Math.sqrt(2 * Math.PI))) * Math.pow(Math.E, ((-1 / 2) * Math.pow(((i - ((mean / 2) / stepSize)) / stdev), 2)));
		}
		//console.log("gauss: ", gauss);
		return gauss;
	}
	generateGaussianFromArray(arr: number[] = [], data: candle_old, stepSize: number = 0.01): number[] {
		let gauss: number[] = new Array(arr.length);
		let width: number = data.high - data.low;
		let mean: number = (((((data.high - data.low) / 2) + data.low) / stepSize) - (arr[0] / stepSize));
		let stdev: number = ((width / 8) / stepSize);
		for (let i = 0; i < gauss.length; i++) {
			gauss[i] = (1 / (stdev * Math.sqrt(2 * Math.PI))) * Math.pow(Math.E, ((-1 / 2) * Math.pow(((i - mean) / stdev), 2)));
		}
		//console.log("gauss: ", gauss);
		return gauss;
	}
	createRangeArray(start: number, end: number, step: number = 0.01) {
		const len = Math.floor((end - start) / step)// + 1;
		let arr = new Array(len).fill(1).map((_, idx) => (start + (idx * step)));//.toFixed(2));
		//console.log(arr);
		return arr;
	}

	protected readonly alert = alert;
}
