import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {SchwabAuthService} from "./schwab-auth.service";
import {PriceHistoryRequest} from "../models/PriceHistoryRequest";
import {CandleList} from "../models/CandleList";

@Injectable({
  providedIn: 'root'
})
export class SchwabDataService {
	private apiUrl = 'https://api.schwabapi.com/marketdata/v1/';

	constructor(private http: HttpClient, private authService: SchwabAuthService) { }

	//Get Quote for Symbol API request
	getQuote(symbol : string): Observable<any> {
		const accessToken = this.authService.getAccessTokenFromStorage();
		if (!accessToken) {
			throw new Error('Access token is not available');
		}

		const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);

		return this.http.get<any>(this.apiUrl + symbol + '/quotes', { headers });
	}

	//Get Price History for Symbol API request
	getPriceHistory(request : PriceHistoryRequest): Observable<any> {
		const accessToken = this.authService.getAccessTokenFromStorage();
		if (!accessToken) {
			throw new Error('Access token is not available');
		}

		const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);

		const params = new URLSearchParams();
		Object.keys(request).forEach((key) => {
			if(request[key as keyof PriceHistoryRequest]) {
				params.set(`${key as keyof PriceHistoryRequest}`, `${request[key as keyof PriceHistoryRequest]}`);
			}
		});
		return this.http.get<CandleList>(`${this.apiUrl}pricehistory?${params.toString()}`, { headers });

	}
}
