import {quoteHist_old} from "./quoteHist_old";

export interface surfaceData {
	type: string;
	x: Date[] | number[];
	y: number[];
	z: number[][];
	gauss: number[][];
}

export class surfaceData {
	constructor(data?: surfaceData | null) {
		if (data != null) {
			Object.assign(this, data)
		}
	}

	type: string = 'surface';
	x!: Date[] | number[];
	y!: number[];
	z!: number[][];
	gauss!: number[][];
}
