import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {ActivatedRouteSnapshot, Router} from "@angular/router";
import { Observable, throwError } from 'rxjs';
import {OAuthTokenResponse} from "../models/OAuthTokenResponse";



@Injectable({
  providedIn: 'root'
})
export class SchwabAuthService {
	private clientId: string = 'dvEDxKvDXf5DrWWNSarr3RsEMUeOQyLE';
	private clientSecret: string = 'pZXa307oNbcBRWzv';
	private redirectUri: string = 'https://charlespmcgrath.dev/oauth';
	private authUrl: string = 'https://api.schwabapi.com/v1/oauth/authorize';
	private tokenUrl = 'https://api.schwabapi.com/v1/oauth/token';

	constructor(private http: HttpClient, private router: Router) {
	}

	//Generate the URL for Schwab OAuth authorization
	getAuthUrl(): string {
		const params = new URLSearchParams();
		params.set('response_type', 'code');
		params.set('client_id', this.clientId);
		params.set('scope', 'readonly');
		params.set('redirect_uri', this.redirectUri);
		return `${this.authUrl}?${params.toString()}`;
	}


	//Step 1: Exchange the authorization code for an access token
	getAccessToken(code: string): Observable<any> {

		const headers = new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
			'Authorization': 'Basic ' + btoa(this.clientId + ':' + this.clientSecret),
		});

		const body = new HttpParams()
			.set('grant_type', 'authorization_code')
			.set('code', code)
			.set('redirect_uri', this.redirectUri)
			//.set('client_id', this.clientId)
			//.set('client_secret', this.clientSecret);

		return this.http.post(this.tokenUrl, body.toString(), { headers: headers });
	}

	//Step 2: Handle the response after token exchange and store token
	handleTokenResponse(response: OAuthTokenResponse): void {
		if (response && response.access_token) {
			localStorage.setItem('access_token', response.access_token);
			localStorage.setItem('refresh_token', response.refresh_token);
			localStorage.setItem('expires_at', `${Date.now() + response.expires_in * 1000}`);
		}
	}

	isTokenExpired(): boolean {
		const tokenExpiry = localStorage.getItem('expires_at');
		return !tokenExpiry || Date.now() >= parseInt(tokenExpiry);
	}

	//Get the access token from LocalStorage
	getAccessTokenFromStorage(): string | null {
		const accessToken = localStorage.getItem('access_token');
		if (!accessToken) {
			throw new Error('Access token is not available');
		}
		return accessToken;
	}

	//Step 3: Refresh the access token (if necessary)
	refreshAccessToken(): Observable<any> {
		const refreshToken = localStorage.getItem('refresh_token');
		const headers = new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
			'Authorization': 'Basic ' + btoa(this.clientId + ':' + this.clientSecret),
		});

		const body = new HttpParams()
		.set('grant_type', 'refresh_token')
		.set('refresh_token', refreshToken!)
		//.set('client_id', this.clientId)
		//.set('client_secret', this.clientSecret);

		return this.http.post(this.tokenUrl, body.toString(), { headers: headers });
	}

	logout() {
		localStorage.clear();
		this.router.navigate(['/']);
	}
}
