import { CandleList } from '../../models/CandleList';

/**
 * Calculates the probability curve for interday price movements and ensures all bins are included, even if empty.
 *
 * @param candleList - The CandleList containing the array of Candle objects.
 * @param binSize - The size of each bin for grouping price movements (e.g., 0.01 for 1% bins).
 * @returns A sorted object where the keys are price movement ranges, and the values are probabilities.
 */
export function calculateInterdayProbabilityCurveNoSkip(
	candleList: CandleList,
	binSize: number
): Record<string, number> {
	if (!candleList || candleList.empty || !candleList.candles.length) {
		throw new Error('CandleList is empty or invalid.');
	}

	const priceMovements: number[] = [];
	const bins: Record<string, number> = {};

	// Calculate interday price movements as percentage changes
	for (let i = 1; i < candleList.candles.length; i++) {
		const previousClose = candleList.candles[i - 1].close;
		const previousOpen = candleList.candles[i - 1].open;
		const currentClose = candleList.candles[i].close;

		if (previousOpen && currentClose) {
			const priceChange = ((currentClose - previousOpen) / previousOpen) * 100; // % change
			priceMovements.push(priceChange);
		}
	}

	// Determine the range of bins
	const minMovement = Math.floor(Math.min(...priceMovements) / binSize) * binSize;
	const maxMovement = Math.ceil(Math.max(...priceMovements) / binSize) * binSize;

	// Initialize all bins to 0
	for (let binStart = minMovement; binStart < maxMovement; binStart += binSize) {
		const binLabel = `${binStart.toFixed(2)} to ${(binStart + binSize).toFixed(2)}`;
		bins[binLabel] = 0;
	}

	// Group price movements into bins
	priceMovements.forEach((movement) => {
		const binStart = Math.floor(movement / binSize) * binSize; // Find the bin for the movement
		const binLabel = `${binStart.toFixed(2)} to ${(binStart + binSize).toFixed(2)}`;
		bins[binLabel] = (bins[binLabel] || 0) + 1;
	});

	// Convert counts to probabilities
	const totalMovements = priceMovements.length;
	for (const bin in bins) {
		if (bins.hasOwnProperty(bin)) {
			bins[bin] = bins[bin] / totalMovements; // Normalize to probability
		}
	}

	// Sort bins by numeric range
	const sortedBins = Object.keys(bins)
		.map((bin) => {
			const rangeStart = parseFloat(bin.split(' to ')[0]); // Extract numeric start of the range
			return { bin, rangeStart, probability: bins[bin] };
		})
		.sort((a, b) => a.rangeStart - b.rangeStart); // Sort by range start

	// Convert sorted bins back to a Record<string, number>
	const sortedBinsRecord: Record<string, number> = {};
	sortedBins.forEach(({ bin, probability }) => {
		sortedBinsRecord[bin] = probability;
	});

	return sortedBinsRecord;
}
