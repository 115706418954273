<!--<div class="card">-->
<!--  <p-chart type="bar" [data]="basicData" [options]="basicOptions"></p-chart>-->
<!--</div>-->


<div class="card">
	<p-toolbar>
		<form [formGroup]="volumeFlowForm">
			<div class="p-toolbar-group-start">
				<button pButton icon="pi pi-plus" class="mr-2" (click)="calculateVolumePlot()"></button>

			</div>
			<div class="p-toolbar-group-center">
				<label for="first-name">Start Date: </label>
				<input id="first-name" type="text" formControlName="startDt">

				<label for="last-name">End Date: </label>
				<input id="last-name" type="text" formControlName="endDt">
			</div>
			<div class="p-toolbar-group-end">
				<button pButton icon="pi pi-sign-in" class="mr-2" (click)="testButtonClick()"></button>
			</div>
		</form>
	</p-toolbar>
	<p-progressBar [value]="progressBarVal"></p-progressBar>
</div>
<div class="card">
	<div>Test Data:
		<!--{{testData | json}}-->
		<ngx-json-viewer [json]="testData"></ngx-json-viewer>
	</div>
  <div id="plot"></div>
</div>
