import {candle_old} from "./candle_old";
import {surfaceData} from "./surfaceData";

export interface quoteHist_old {
  candles: candle_old[];
  symbol: string;
  empty: boolean;
}

export class quoteHist_old {
  constructor(data?: quoteHist_old | null) {
    if(data != null) {
      Object.assign(this, data)
    }
  }
  candles!: candle_old[];
  symbol!: string;
  empty!: boolean;
}
