import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppLayoutComponent} from "./layout/app.layout.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {VolumePriceSurfaceComponent} from "./components/charting/volume-price-surface/volume-price-surface.component";
import {AuthComponent} from "./components/auth/auth.component";
import {LocationStrategy, PathLocationStrategy} from "@angular/common";
import {ProbabilitySurfaceComponent} from "./components/charting/probability-surface/probability-surface.component";

const routes: Routes = [
	{
		path: '', component: AppLayoutComponent,
		children: [
			{path: '', component: DashboardComponent},
			{path: 'volume-price-surface', component: VolumePriceSurfaceComponent},
			{path: 'probability-surface', component: ProbabilitySurfaceComponent},
			{path: 'auth', component: AuthComponent},
			{path: 'oauth', component: AuthComponent},
			{path: 'oauth/', component: AuthComponent},
			//{ path: '', loadChildren: () => import('./demo/components/dashboard/dashboard.module').then(m => m.DashboardModule) },
			//{ path: 'uikit', loadChildren: () => import('./demo/components/uikit/uikit.module').then(m => m.UIkitModule) },
			//{ path: 'utilities', loadChildren: () => import('./demo/components/utilities/utilities.module').then(m => m.UtilitiesModule) },
			//{ path: 'documentation', loadChildren: () => import('./demo/components/documentation/documentation.module').then(m => m.DocumentationModule) },
			//{ path: 'blocks', loadChildren: () => import('./demo/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule) },
			//{ path: 'pages', loadChildren: () => import('./demo/components/pages/pages.module').then(m => m.PagesModule) }
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: false })],
	exports: [RouterModule],
	providers: [
		{ provide: LocationStrategy, useClass: PathLocationStrategy }
	]
})
export class AppRoutingModule {
}
